<script>
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import EzButton from '@/components/ui/Button/EzButton';

/**
 *
 * @version 1.0.0
 * @since 3.13.1
 */
export default {
  name: 'index',
  components: {
    VSelectSearch,
    EzButton,
  },
  props: {
    data: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    name: {
      type: String,
      default: '',
      required: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    searchProperty: {
      type: String,
      default: '',
      required: false,
    },
    isFullWidth: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedId: {
      type: Number,
      required: false,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    close() {
      this.$refs.chooseUnit.close();
    },
    addNew() {
      this.$emit('onAddNew');
    },
    edit(item) {
      this.$emit('onEdit', item);
    },
    remove(item) {
      this.$emit('onRemove', item);
    },
    onSelect(item) {
      this.$emit('onSelect', item);
    },
    reset() {
      this.$refs.chooseUnit.reset();
    },
  },
};
</script>

<template>
  <v-select-search
    v-if="data.length"
    ref="chooseUnit"
    :class="['mt-12', 'sales-units', { 'sales-units--first-action': hasActions }] "
    :label="label"
    :placeholder="placeholder"
    :name="name"
    :search-property="searchProperty"
    :data="data"
    :has-clear="false"
    :is-full-width="isFullWidth"
    :scroll-into-view="true"
    :selected="selectedId"
    @selected="onSelect"
    :data-cy="dataCy"
  >
    <template #firstResult v-if="hasActions">
      <ez-button
        @click.stop="addNew"
        class="add-unit"
        type="blue-link"
        :data-cy="`${dataCy}-addNewUnit`"
      >
        <font-awesome-icon icon="plus" />Add New Unit
      </ez-button>
    </template>
    <template #result="{result}">
      <div class="result">
        <div class="result__item">
          {{ result.label }}
          <div class="result__item__actions">
            <font-awesome-icon
              v-if="result.owned && hasActions"
              class="action"
              @click.stop="edit(result)"
              icon="pen"
            />
            <font-awesome-icon
              v-if="result.canBeDeleted && hasActions"
              class="action"
              @click.stop="remove(result)"
              icon="trash"
            />
          </div>
        </div>
      </div>
    </template>
  </v-select-search>
</template>

<style lang="scss" scoped>
.sales-units {
  .add-unit {
    padding: 0;
    width: 100%;
    text-align: left;
  }
  :deep() {
    .select-search__search-wrapper {
      padding: 8px 8px 0 8px;
      border: none;
      margin-bottom: 8px;

      .input-wrapper {
        border: 1px solid $input-border-color;
        border-radius: $input-border-radius;
        padding: 0 10px;

        svg {
          color: $color-gray-6C;
        }
      }
    }
    .select-search__list-wrapper {
      li {
        padding: 0;
      }
    }
  }
  &--first-action {
    :deep() .select-search__list-wrapper {
      li {
        &:first-child {
          padding: 0 12px;
        }
      }
    }
  }
  .result {
    height: 36px;
    width: 100%;
    padding: 6px 12px;

    &__item {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__actions {
        @include font-size(14px);
        color: $color-gray-6C;

        svg {
          & + svg {
            margin-left: 19px;
          }
        }
        .action {
          display: none;
        }
      }
    }
    &:hover {
      .action {
        display: initial;
      }
    }
  }
}
</style>
