<script>
import EzTable from '@/components/ui/Table/EzTable';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import EzButton from '@/components/ui/Button/EzButton';
import EzSpinner from '@/components/ui/Spinner/EzSpinner.vue';
import VTag from '@/components/v3/elements/VTag';
import VTooltipComponent from '@/components/v3/elements/VTooltipComponent';
import VPrice from '@/components/v3/elements/VPrice';
import { mapGetters, mapState } from 'vuex';
import EmptyState from '@/views/common/empty-state/EmptyState';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import VMarketPriceInput from '@/components/v3/patterns/VMarketPriceInput';
import { UNIT_TYPE_FRACTIONAL, DEFAULT_FRACTION_DIGITS } from '@/util/constants';
import i18n from '@/i18n';
import VProductQuantityInventory from '@/components/v3/elements/VProductQuantityInventory';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown.vue';
import { pluralize } from '@/util/utils';
import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';

const pricePerUnitRenderFn = h =>
  h('span', {}, [
    'Price Per Unit',
    h(
      'v-tooltip-component',
      {
        props: {
          content: 'Setting the price here will override the existing pricing.',
          placement: 'right-start',
        },
      },
      [
        h('font-awesome-icon', {
          class: 'ml-4',
          props: {
            icon: 'info-circle',
          },
        }),
      ],
    ),
  ]);

const amountActions = [
  { type: 'setTotalAmount', label: i18n.t('product.totalAmount'), payload: {} },
  { type: 'setTbd', label: i18n.t('product.tbd'), payload: {} },
];

const Event = {
  productSelect: 'productSelect',
  priceChange: 'priceChange',
  quantityChange: 'quantityChange',
  setRegularPrice: 'setRegularPrice',
  setMarketPrice: 'setMarketPrice',
  setTotalAmount: 'setTotalAmount',
  setTbd: 'setTbd',
  totalAmountChanged: 'totalAmountChanged',
  loadMore: 'loadMore',
  onQuantityInvalid: 'onQuantityInvalid',
  addAnotherProductSelect: 'addAnotherProduct',
};

/**
 * @version 1.0.0
 * @since
 *
 */
export default {
  name: 'ProductsSelectList',
  components: {
    VMarketPriceInput,
    VDataWithInfo,
    EzButton,
    EzSpinner,
    EzLoadMore,
    EzEntityInfo,
    VTag,
    EzTable,
    VPrice,
    // eslint-disable-next-line vue/no-unused-components
    VTooltipComponent, // It is used in pricePerUnitRenderFn, but don't tell eslint ;)
    EmptyState,
    VProductQuantityInventory,
    EzButtonDropdown,
    EzMaskInputSimple,
  },
  props: {
    products: {
      required: true,
      type: Array,
    },
    nextId: {
      required: false,
      type: Number,
      default: null,
    },
    showInventory: {
      required: false,
      type: Boolean,
      default: false,
    },
    isOfflineOrder: {
      required: false,
      type: Boolean,
      default: false,
    },
    loadingKey: {
      required: false,
      type: String,
    },
    loadingMoreKey: {
      required: false,
      type: String,
    },
    /**
     * This attribute is used for
     * marking the element when testing with cypress
     */
    dataCy: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      Event,
      pricePerUnitRenderFn,
      amountActions,
      DEFAULT_FRACTION_DIGITS,
      tagHover: false,
      tagPopOver: {
        tags: [],
        top: null,
        left: null,
      },
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('entities/users', ['isDistributor']),
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.loadingKey ? this.getLoading(this.loadingKey) : false;
    },
    isLoadingMore() {
      return this.loadingMoreKey ? this.getLoading(this.loadingMoreKey) : false;
    },
    noProductsFound() {
      return !this.isLoading && !this.isLoadingMore && !this.products?.length;
    },
    me() {
      return this.isDistributor ? this.loggedUser.distributor : null;
    },
    canShowInventory() {
      return this.showInventory && (this.me ? this.me.inventorySettings.tracking : false);
    },
    columns() {
      return [
        'name',
        'tags',
        ...(this.canShowInventory ? ['inventory'] : []),
        'pricePerUnit',
        'unit',
        'quantity',
        'amount',
        'action',
      ];
    },
  },
  methods: {
    rowDataCy(row) {
      if (row.marketPrice) return `${this.dataCy.ROW__MARKET_PRICE_PRODUCT}-${row.id}`;
      if (row.priceUnit) return `${this.dataCy.ROW__PRICE_UNIT_PRODUCT}-${row.id}`;
      return `${this.dataCy.ROW__PRODUCT}-${row.id}`;
    },
    getRowClass(row) {
      return row.isBonus ? 'item-bonus' : null;
    },
    tradeDealTooltipContent(row) {
      return `Buy ${row.deals[0].buy} ${pluralize(row.orderingUnit.label, row.deals[0].buy)}, 
                    get ${row.deals[0].get} ${pluralize(
        row.orderingUnit.label,
        row.deals[0].get,
      )} for ${row.deals[0].price}${row.currency.symbol}`;
    },
    priceError(row) {
      return row.price < 0 && !!row.checked ? 'Price cannot be less than 0.' : '';
    },
    isQuantityInvalid(product) {
      const { checked, inventory, inventoryTracked, quantity, minimumQuantity } = product;

      if (!checked || !this.canShowInventory || !inventoryTracked) return false;
      if (quantity < minimumQuantity && !product.isBonus) return true;

      return inventory < quantity;
    },
    isFractional(orderingUnit) {
      return orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    isToBeDetermined(product) {
      const { priceUnit, marketPrice, price } = product || {};
      return (!!priceUnit && price > 0) || marketPrice;
    },
    onQuantityInvalid(value, id) {
      this.$emit(Event.onQuantityInvalid, value, id);
    },
    tagsShowHover(id, tags) {
      this.tagHover = true;
      this.tagPopOver.tags = tags;
      const { top, left, bottom } = this.$refs[`tag-${id}`].getBoundingClientRect();
      const topParent = this.$refs.productList.getBoundingClientRect().top;
      const leftParent = this.$refs.productList.getBoundingClientRect().left;
      // Adding the height of the element
      this.tagPopOver.top = top - topParent + (bottom - top);
      this.tagPopOver.left = left - leftParent;
    },
    tagsRemoveHover() {
      this.tagHover = false;
      this.tagPopOver = {
        tags: [],
        top: null,
        left: null,
      };
    },
  },
};
</script>
<template>
  <div ref="productList" style="position: relative">
    <div
      class="popover"
      :style="`top: ${tagPopOver.top}px; left: ${tagPopOver.left}px`"
      v-if="tagHover"
    >
      <v-tag
        v-for="tag in tagPopOver.tags"
        :key="tag.id"
        :color="tag.color.colors[0]"
        :backgroundColor="tag.color.colors[1]"
      >
        {{ tag.name }}
      </v-tag>
    </div>
    <ez-table
      class="products-table"
      :loading="isLoading"
      :data="products"
      :headers="{
        name: () => 'Product',
        pricePerUnit: pricePerUnitRenderFn,
        action: () => '',
        inventory: () => 'Stock',
      }"
      :rowDataCy="rowDataCy"
      :columns="columns"
      :columnProps="{
        name: { class: 'product-cell' },
        pricePerUnit: { class: 'medium-cell' },
        unit: { class: 'medium-cell unit-cell' },
        quantity: { class: 'large-cell quantity-cell' },
        amount: { class: 'price-cell' },
        action: { class: 'select-cell ' },
        inventory: { class: 'text-right-cell stock-cell' },
      }"
      :rowCls="getRowClass"
    >
      <template #cell-name="{ row }">
        <div class="cell-name-container">
          <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="row.image">
            <div class="product-info" :title="row.name">
              <span :data-cy="`${dataCy.TEXT__PRODUCT_NAME}-${row.id}`">{{ row.name }}</span>
              <span class="product-info-secondary" :title="row.category | categoryWithParent">
                <template v-if="row.sku">
                  <span :data-cy="`${dataCy.TEXT__PRODUCT_SKU}-${row.id}`">{{ row.sku }}</span>
                  &#8226;
                </template>
                <span :data-cy="`${dataCy.TEXT__PRODUCT_CATEGORY}-${row.id}`">{{
                  row.category | categoryWithParent
                }}</span>
              </span>
            </div>
          </ez-entity-info>
        </div>
      </template>

      <template v-if="canShowInventory" #cell-inventory="{ row }">
        <font-awesome-icon
          v-if="!row.inventoryTracked"
          flip="horizontal"
          :icon="['far', 'eye-slash']"
          v-tooltip="{
            placement: 'top-start',
            content: 'Not Tracking',
            classes: ['tooltip--reset-margin u-text-center mb-2'],
          }"
        />
        <span v-else-if="row.available === undefined">-</span>
        <font-awesome-icon
          v-else-if="!row.available"
          v-tooltip="{
            placement: 'top',
            content: 'Out of stock',
            classes: ['tooltip', 'tooltip--lift-up', 'tooltip--reset-margin'],
          }"
          icon="minus-circle"
          class="text-red"
        />
        <v-product-quantity-inventory
          v-else
          :product="row"
          :canBeInvalid="false"
          warehouseProperty="warehouseInventory"
          isDistributor
          showTotalAsLabel
          inventoryTracking
          :data-cy="`${dataCy.TEXT__PRODUCT_STOCK}-${row.id}`"
        />
      </template>

      <template #cell-pricePerUnit="{ row }">
        <div class="changeable-price">
          <div class="u-flex-center">
            <v-market-price-input
              :data-cy="`${dataCy.INPUT__PRICE}-${row.id}`"
              v-if="row"
              :key="row.id"
              :value="row.price || 0"
              :error="priceError(row)"
              :disabled="!row.checked || row.isBonus"
              :is-market-price="row.marketPrice"
              :is-it-different-from-number="row.marketPrice"
              :precision="row.currency?.fractionDigits ?? DEFAULT_FRACTION_DIGITS"
              :price-prefix="row.currency?.showSymbol ?? true"
              @input="$emit(Event.priceChange, row, $event)"
              @setRegularPrice="$emit(Event.setRegularPrice, row)"
              @setMarketPrice="$emit(Event.setMarketPrice, row)"
              :price-unit="row.priceUnit"
            />
          </div>
        </div>
      </template>

      <template #cell-tags="{ row }">
        <div
          class="tags"
          v-if="row.tags?.length"
          @mouseenter="tagsShowHover(row.id, row.tags)"
          @mouseleave="tagsRemoveHover"
          :ref="`tag-${row.id}`"
        >
          <v-tag
            class="mr-8"
            :color="row.tags[0].color.colors[0]"
            :backgroundColor="row.tags[0].color.colors[1]"
            >{{ row.tags[0].name }}</v-tag
          >
          <span v-if="row.tags?.length > 1">+{{ row.tags?.length - 1 }}</span>
        </div>
        <span v-else>-</span>
      </template>

      <template #cell-unit="{ row, row: { orderingUnit } }">
        <template v-if="orderingUnit">
          <v-data-with-info
            :info="
              orderingUnit.abbreviation
                ? `${orderingUnit.name} ${!!$t(`product.unitInfo.${orderingUnit.type}`) ? '-' : ''}
              ${$t(`product.unitInfo.${orderingUnit.type}`)}`
                : ''
            "
            :show-underline="
              !!$t(`product.unitInfo.${orderingUnit.type}`) || !!orderingUnit.abbreviation
            "
            :data-cy="`${dataCy.TEXT__UNIT}-${row.id}`"
          >
            {{ orderingUnit.label }}
          </v-data-with-info>
        </template>
      </template>

      <template #cell-quantity="{ row, row: { quantity, orderingUnit } }">
        <ez-mask-input-simple
          :data-cy="`${dataCy.INPUT__QUANTITY}-${row.id}`"
          :key="row.id"
          name="quantity"
          formKey=""
          :minValue="0"
          :disabled="row.isBonus"
          :value="quantity"
          :is-invalid="isQuantityInvalid(row)"
          @input="$emit(Event.quantityChange, row, $event)"
          :precision="isFractional(orderingUnit) ? 4 : 0"
          @invalid="onQuantityInvalid($event, row.id)"
          :has-currency="false"
        />
      </template>

      <template
        #cell-amount="{
          row,
          row: { currency, price, quantity, marketPrice, priceUnit, totalToChange, totalPrice },
        }"
      >
        <div
          class="changeable-price"
          v-if="isOfflineOrder && !!priceUnit && !marketPrice && price > 0"
        >
          <div class="u-flex-center">
            <v-market-price-input
              :data-cy="`${dataCy.INPUT__AMOUNT}-${row.id}`"
              class="tbd-amount-input"
              v-if="row"
              :actions="amountActions"
              :key="row.id"
              :value="totalPrice || 0"
              :disabled="!row.checked"
              :is-tbd="!!priceUnit && !marketPrice"
              :is-it-different-from-number="!!priceUnit && !marketPrice && !totalToChange"
              @input="$emit(Event.totalAmountChanged, row, $event)"
              @setTotalAmount="$emit(Event.setTotalAmount, row)"
              @setTbd="$emit(Event.setTbd, row)"
            />
          </div>
        </div>
        <v-price
          v-else
          :price="price * quantity"
          :currency="currency"
          :show-market-price-info="false"
          :is-to-be-determined="isToBeDetermined(row)"
          :data-cy="`${dataCy.TEXT__AMOUNT}-${row.id}`"
        />
        <span
          v-if="row.isBonus"
          class="additional-info additional-info--bonus"
          v-tooltip="{
            content: tradeDealTooltipContent(row),
            classes: ['tooltip--reset-margin'],
          }"
          >Bonus</span
        >
      </template>

      <template #cell-action="{ row, row: { checked } }">
        <div>
          <ez-button
            :data-cy="`${dataCy.BUTTON__SELECT_PRODUCT}-${row.id}`"
            :type="checked ? 'primary' : 'secondary'"
            @click="$emit(Event.productSelect, row)"
          >
            {{ checked ? 'Selected' : 'Select' }}
          </ez-button>
          <ez-button-dropdown v-if="checked" :buttonType="checked ? 'primary' : 'secondary'">
            <template #icon>
              <font-awesome-icon icon="angle-down" />
            </template>
            <template #dropdown>
              <ez-button @click="$emit(Event.addAnotherProductSelect, row)" type="secondary"
                >Add Another</ez-button
              >
            </template>
          </ez-button-dropdown>
        </div>
      </template>
    </ez-table>

    <empty-state v-if="noProductsFound">
      <template #badge><img src="@/assets/search-product-state.svg" alt="" /></template>
      <template #title>No products available</template>
      <template #info>There are currently no available products from this supplier.</template>
    </empty-state>

    <ez-load-more v-if="nextId && !isLoadingMore" @loadMore="$emit(Event.loadMore, nextId)" />

    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>
  </div>
</template>

<style scoped lang="scss">
.popover {
  position: absolute;
  background-color: white;
  margin-top: 10px;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  padding: 16px;
  max-width: 360px;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  overflow: visible;
}

:deep() .products-table {
  tbody {
    tr td {
      overflow: visible;
    }
  }
}

.cell-name-container {
  @extend %flex-center;
  justify-content: flex-start;

  > * {
    margin-right: 12px;
  }
}

.product-info {
  display: flex;
  flex-direction: column;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
}

.product-info-secondary {
  display: block;
}

:deep() .table {
  .sku-cell,
  .category-cell {
    display: none;
  }
  .unit-cell {
    overflow: hidden;
  }

  .stock-cell {
    width: 80px;
  }

  .product-cell {
    width: 340px;
  }

  .price-cell {
    overflow: visible;
  }

  @media (max-width: 1200px) {
    .product-cell {
      width: 240px;
    }
  }

  tbody tr td.select-cell {
    width: 130px;
    .button.button--primary {
      padding-right: 6px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .button-dropdown.button-dropdown--primary {
      padding-left: 0px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      .button.button--primary {
        padding-left: 6px;
        padding-right: 12px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }

  tbody tr.item-bonus {
    background-color: $color-pastel-blue;
    td:first-child {
      padding-left: 8px;
    }
    td:last-child {
      padding-right: 8px;
    }
    .has-border {
      border: 1px solid $color-primary-blue;
    }
    .additional-info--bonus {
      color: $color-gray-6C;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      border-bottom: 2px dotted #a3bbff;
      color: $color-primary-blue;
    }
  }
}

.tbd-amount-input {
  :deep() .mask-input-wrapper .mask-input__input {
    color: $color-gray-25;
  }
}

@media (max-width: 1023px) {
  .product-info-secondary {
    display: none;
  }
}
:deep() .ez-empty-state {
  p {
    @include font-size(14px, 20px);
  }
}
:deep() .tags {
  display: flex;

  .v-tag {
    min-width: 0;
    flex-shrink: 1;
    overflow: hidden;
  }
}

:deep() .mask-input.mark-input--inline {
  .mask-input__input {
    font-weight: normal;
    color: $color-gray-25;
    text-align: center;
  }
}
</style>
