<template>
  <!-- eslint-disable max-len -->
  <div
    :class="[
      'ez-privacy-policy',
      'ez-privacy-policy--padding-modify',
      { 'ez-privacy-policy--padding-modify-public': isPublic },
    ]"
  >
    <p class="subtitle">
      This Privacy Policy governs what we can and cannot do with the information that you provide.
    </p>
    <div class="ez-privacy-policy__title">
      <span>Last updated: 24. October 2024.</span>
    </div>

    <p class="ez-privacy-policy__first-par">
      We at {{ oezPlatformName }}, OrderEZ Pte. Ltd. and our subsidiaries (collectively referred to
      as “the Company”) are committed to protecting your privacy. This Privacy Policy applies across
      all websites that we own and operate and all services we provide, including our online and
      mobile services products, and any other apps or services we may offer (for example, events or
      training). For the purpose of this notice, we’ll just call them our ‘services’. This Privacy
      Policy governs our data collection, processing and usage practices. It also describes your
      choices regarding use, access and correction of your personal information. If you do not agree
      with the data practices described in this Privacy Policy, you should not use our services.
    </p>

    <p>
      When we say 'personal data' we mean identifiable information about you, like your name, email,
      address, telephone number, bank account details, payment information, support queries,
      community comments and so on. If you can’t be identified (for example, when personal data has
      been aggregated and anonymized) then this notice doesn’t apply. Check out our Terms &
      Conditions for more information on how we treat your other data.
    </p>

    <p>
      We may need to update this Privacy Policy from time to time. We will post any Privacy Policy
      changes on this page and, if the changes are significant, we’ll make sure we let you know,
      usually through an email.
    </p>

    <h2>Who are ‘we’?</h2>

    <p>
      When we refer to ‘we’ (or ‘our’ or ‘us’), that means the Company and its wholly-owned
      subsidiaries. Our headquarters are in Singapore but we also operate and have affiliates in
      several countries. We provide an easy-to-use global cloud software platform for small and
      medium-sized businesses.
    </p>

    <p>
      For data protection purposes, when we act as a controller in relation to your personal data,
      our Data Protection Officer is Jeffrey Meese and can be contacted at
      <a :href="`mailto:${oezPrivacyEmail}`">{{ oezPrivacyEmail }}</a> or
      <a :href="`tel:+65 8949 1880`">+65 8949 1880</a>.
    </p>

    <h2>How we collect information</h2>

    <p>
      When you visit our websites or use our services, we collect personal data. We will only use
      and disclose such personal data as described in this Privacy Policy.
    </p>

    <h2>Information you provide to us directly</h2>

    <p>
      When you visit or use some parts of our websites and/or services we might ask you to provide
      personal data to us. For example, we ask for your contact information when you sign up for a
      free trial, respond to a job application or an email offer, download an e-book, participate in
      community forums, join us on social media, take part in training and events, contact us with
      questions or request support. If you don’t want to provide us with personal information, you
      don’t have to, but it might mean you can’t use some parts of our websites or services.
    </p>

    <h2>Information we collect automatically</h2>

    <p>
      While you use our site and services, we collect general information of the sort that web
      browsers, servers and network operators typically make available. These include things like
      your IP address and device type. We also collect information when you navigate through our
      websites and services, including what pages you looked at and what links you clicked on. This
      information helps us troubleshoot problems, understand how you use our site, improve our
      products and services, and help ensure that we continue to provide the best possible
      experience.
    </p>

    <h2>Information we get from third parties</h2>

    <p>
      The majority of information we collect, we collect directly from you. Sometimes we might
      collect personal data about you from other sources, such as publicly available materials or
      trusted third parties like our marketing and research partners. We use this information to
      supplement the personal data we already hold about you, in order to better inform, personalize
      and improve our services, and to validate the personal data you provide.
    </p>

    <p>
      If we don’t collect your personal data, we may be unable to provide you with all our services,
      and some functions and features on our websites may not be available to you.
    </p>

    <h2>Basis for processing Personal Information (EEA visitors only)</h2>

    <p>
      Our basis for collecting and using the personal information described above will depend on the
      personal information concerned and the specific context in which we collect it. However, we
      will normally collect personal information from you only where we have your consent to do so,
      where we need the personal information to perform a contract with you, or where the processing
      is in our legitimate interests and not overridden by your data protection interests or
      fundamental rights and freedoms. In some cases, we may also have a legal obligation to collect
      personal information from you.
    </p>

    <h2>How we use the information we collect</h2>

    <p>
      We collect information to enable you to receive the benefit of our site, platforms, apps,
      products, and services. Subject to this Privacy Policy and your data preferences, we may use
      the information we collect for some or all of the following purposes:
    </p>

    <ul>
      <li>Carry out our obligations and to provide you with agreed products and services;</li>
      <li>Establish, maintain and administer your account;</li>
      <li>
        Maintain, troubleshoot, and improve our site, platforms, apps, products, and services;
      </li>
      <li>Develop new products and services;</li>
      <li>
        If your preferences permit, to provide you with recommendations and personalised products
        and services;
      </li>
      <li>Measure performance of our site, platforms, apps, products, and services;</li>
      <li>
        Secure and protect you, us, our site, platforms, apps, products, and services, and the
        public;
      </li>
      <li>Meeting our legal, regulatory, and tax reporting obligations;</li>
      <li>
        Communicate with you about our site, platforms, apps, products, and services—such as to
        notify of changes and updates, alert you to data or security breaches, and to provide you
        with customer support;
      </li>
      <li>
        With your explicit consent, to create or distribute promotional and marketing material that
        is relevant to you;
      </li>
      <li>For quality assurance and training purposes;</li>
      <li>
        In the case of non-personally identifiable information only - or personal information only
        with your explicit consent - to promote and market ourselves, our products and services, and
        our websites (including any social media pages maintained or operated by us such as
        Facebook, Instagram, Twitter, YouTube etc); and
      </li>
      <li>
        Any other uses identified to you at the time of collecting your personal information or as
        reasonably contemplated by this Privacy Policy and our Terms & Conditions (“the Purposes”).
      </li>
    </ul>

    <h2>How we share information we collect</h2>

    <p>
      Personal information we collect stays within the the Company family, other than in the
      following circumstances:
    </p>

    <ul>
      <li>When you give us explicit consent to share your data;</li>
      <li>
        When we share it with the greater the Company family (i.e. with our affiliated and group
        companies);
      </li>
      <li>
        When we share it with our affiliates, partners, and other trusted organisations we work with
        to provide products and services to you;
      </li>
      <li>
        When we share it with trusted external service providers and data processors such as data
        centres, web hosts, cloud storage and cloud software providers, customer support providers,
        payment processors, debt collectors, accountants, and insurers;
      </li>
      <li>
        When we share it with prospective sellers or {{ $t('global.venues') | lowercase }} of our
        business or assets; or
      </li>
      <li>
        When we share it with regulators and other relevant parties for the purpose of legal or
        contractual compliance, reporting purposes, or when we believe in good faith that disclosure
        is reasonably necessary to protect our property or rights, or the rights of third parties or
        the public at large.
      </li>
    </ul>

    <h2>Your rights</h2>

    <p>
      It’s your personal data and you have certain rights relating to it. You may unsubscribe from
      our marketing communications by following the unsubscribe instructions located on the bottom
      of our emails or by emailing us at
      <a :href="`mailto:${oezPrivacyEmail}`">{{ oezPrivacyEmail }}</a
      >. Your rights include the right to:
    </p>

    <ul>
      <li>
        Know what personal data we hold about you, and to make sure it’s correct and up to date
      </li>
      <li>
        Request a copy of your personal data, or ask us to restrict processing your personal data or
        delete it
      </li>
      <li>Object to our continued processing of your personal data.</li>
      <li>
        You can exercise these rights at any time by sending an email to
        <a :href="`mailto:${oezPrivacyEmail}`">{{ oezPrivacyEmail }}</a> and we will respond to your
        request within a reasonable period after the request is made.
      </li>
      <li>
        We do not typically charge a fee for reasonable requests for access to your personal
        information. However, we may charge a reasonable fee, which will be notified to you before
        we move forward with the request, for time and cost if an extended amount of time is
        required to collate and prepare material for you.
      </li>
      <li>
        If you’re not happy with how we are processing your personal data, please let us know by
        sending an email to <a :href="`mailto:${oezPrivacyEmail}`">{{ oezPrivacyEmail }}</a
        >. We will review and investigate your complaint, and try to get back to you within a
        reasonable time frame. Where appropriate, we will work together with the Singapore Personal
        Data Protection Commission or other relevant authority to address your complaint, and/or
        make improvements to our systems and processes.
      </li>
    </ul>

    <h2>Accuracy</h2>

    <p>
      We take all reasonable steps within our control to ensure that the personal information we
      hold about you is accurate. We also take reasonable steps to ensure that the information is
      complete and up-to-date. However, we also rely on you to advise us of any changes to your
      personal information. You can also change your personal data related to your The Company
      account in the platform under Settings.
    </p>

    <h2>Security</h2>

    <p>
      We are committed to protecting your personal data and have taken steps to ensure that your
      personal information is treated securely and to prevent unauthorised access, collection, use,
      disclosure, copying, modification, disposal or similar risks.
    </p>

    <p>
      Although we aim to create a safe, secure environment by trying to limit access to the site to
      legitimate users, we cannot guarantee that unauthorised parties will not gain access. To the
      extent permitted by applicable law, we expressly exclude any liability arising from any
      unauthorised access to your personal information.
    </p>

    <p>
      We will make every feasible effort to inform the Singapore Personal Data Protection Commission
      or other relevant authority as soon as possible after the occurrence of any significant
      personal data breach which poses a risk to your rights and freedoms as a natural person. We
      will also inform you without undue delay unless the risk to your individual rights and
      freedoms is low—such as if the compromised data was well encrypted.
    </p>

    <p>
      Please contact us at
      <a :href="`mailto:${oezPrivacyEmail}`">{{ oezPrivacyEmail }}</a> immediately if you become
      aware of any unauthorised use of your account by anyone else or any other breach of security.
    </p>

    <h2>Retention</h2>

    <p>
      The length of time we keep your personal data depends on what it is and whether we have an
      ongoing business need to retain it (for example, to provide you with a service you’ve
      requested or to comply with applicable legal, tax or accounting requirements).
    </p>

    <p>
      We’ll retain your personal data as necessary to provide you with our services or for our
      legitimate commercial interests for as long as we have a relationship with you and for a
      period of time afterwards where we have an ongoing legitimate commercial interest in retaining
      it, in accordance with our data retention policies and practices. Following that period, we’ll
      make sure it’s deleted or anonymized.
    </p>

    <h2>International Transfer of Information</h2>

    <p>
      Your information may be processed outside of the country where you live. For individuals in
      the European Economic Area (EEA), this means that your data may be transferred outside of the
      EEA. Regardless of where we use, process, or store your data, we will comply with the
      protections set out in this Privacy Policy and relevant legislation. Where we disclose
      personal data to a third party in another country, we put safeguards in place to ensure your
      personal data remains protected.
    </p>

    <h2>Cookies</h2>

    <p>
      To make this site work properly, we sometimes place small data files called cookies on your
      device. Most big websites do this too. A cookie is a small text file that a website stores on
      a visitor’s computer, and that the visitor’s browser provides to the website each time the
      visitor returns. It enables the website to remember your actions and preferences (such as
      login, language, font size and other display preferences) over a period of time, so you don’t
      have to keep re-entering them whenever you come back to the site or browse from one page to
      another.
    </p>

    <p>
      Our site uses cookies to help us identify you from other users, track your usage of our site,
      and your website access preferences. We do or may use the following cookies:
    </p>

    <ul>
      <li>
        Strictly necessary cookies. These are cookies that are required for the operation of our
        site. They include, for example, cookies that enable you to log into secure areas of our
        site, use a shopping cart or make use of e-billing services.
      </li>
      <li>
        Analytical/performance cookies. They allow us to recognize and count the number of visitors
        and to see how visitors move around our site when they are using it. This helps us to
        improve the way our site works, for example, by ensuring that users are finding what they
        are looking for easily.
      </li>
      <li>
        Functionality cookies. These are used to recognize you when you return to our site. This
        enables us to personalize our content for you, greet you by name and remember your
        preferences (for example, your choice of language or region).
      </li>
      <li>
        Targeting cookies. These cookies record your visit to our site, the pages you have visited
        and the links you have followed. We will use this information to make our site and the
        advertising displayed on it more relevant to your interests. We may also share this
        information with third parties for this purpose.
      </li>
    </ul>

    <p>
      Please note that third parties (including, for example, advertising networks and providers of
      external services like web traffic analysis services) may also use cookies, over which we have
      no control. These cookies are likely to be analytical/performance cookies or targeting
      cookies.
    </p>

    <p>
      You can control and/or delete cookies as you wish - for details, see
      <a target="_blank" href="https://aboutcookies.org/">aboutcookies.org</a>. You can delete all
      cookies that are already on your computer and you can set most browsers to prevent them from
      being placed. If you do this, however, you may have to manually adjust some preferences every
      time you visit a site and some services and functionalities may not work.
    </p>

    <h2>Business transfer</h2>

    <p>
      If we, or substantially all of our assets, were acquired, or in the event that we go out of
      business or enter bankruptcy, user information would be one of the assets that is transferred
      or acquired by a third party. You acknowledge and agree that if such transfers occur, that any
      acquirer of our business may continue to use your personal information as set forth in this
      Privacy Policy.
    </p>

    <h2>Links to third party websites</h2>

    <p>
      Our site may contain links to and from third party websites. If you click on such links, you
      do so at your own risk and subject to whatever privacy policy and/or website terms may govern
      the use of such websites. We have no control over, and are not responsible, nor liable for,
      the content, privacy practices or website terms of such websites or any information you
      provide to them. You should read the privacy policy of these third parties to find out how
      they handle your personal information when you visit their websites.
    </p>

    <h2>How to contact us</h2>

    <p>
      If you have any questions or feedback about this Privacy Policy or our services, please get in
      touch with us at <a :href="`mailto:${oezPrivacyEmail}`">{{ oezPrivacyEmail }}</a
      >.
    </p>
  </div>
</template>

<script>
export default {
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
    oezPrivacyEmail() {
      return process.env.VUE_APP_PRIVACY_EMAIL;
    },
  },
};
</script>

<style scoped lang="scss">
.ez-privacy-policy {
  @extend %paper;

  &--padding-modify {
    padding: 0 0 40px;
  }

  &--padding-modify-public {
    padding: 16px 16px 40px 0;
  }

  p {
    margin: 2rem 0;
    + ul {
      margin-top: -1rem;
    }
  }
  .subtitle {
    margin-bottom: 8px;
  }

  &__title {
    + p {
      margin: 0.5rem 0 2rem;
    }
  }

  h1 {
    margin: 0;
    + p {
      margin-top: 0.5rem;
    }
  }

  h2 {
    display: block;
    margin-top: 2rem;

    + p {
      margin-top: 1rem;
    }
  }

  .ez-privacy-policy__first-par {
    margin-top: 2rem;
  }

  &--public {
    margin-bottom: 2.5em;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      @extend %category-badge;
      max-width: 100%;
    }
  }
  ul {
    @include font-size(14px, 22px);
    color: #8790a3;
    margin: 2em 0;
    li {
      padding-left: 1em;
      + li {
        margin-top: 0.3em;
      }
    }
  }
}
</style>
