<script>
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import VPdfViewer from '@/components/v3/elements/VPdfViewer';
import VImageViewer from '@/components/v3/elements/VImageViewer';
import uuid from 'uuid/v4';

let pdfjs;

(async function () {
  pdfjs = await import('pdfjs-dist/build/pdf');
  const pdfjsWorker = await import('pdfjs-dist/build/pdf.worker.entry');
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
})();

/**
 *
 * @version 1.0.0
 * @since 3.0.0
 */
export default {
  components: {
    VueSlickCarousel,
    VPdfViewer,
    VImageViewer,
  },
  data() {
    return {
      hasLoaded: false,
      sliderData: [],
    };
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  methods: {
    moveTo(index) {
      this.$refs.slider.goTo(index);
    },
  },
  async beforeMount() {
    const data = [];
    const pdfFiles = await Promise.all(
      this.data
        .filter(file => file.type === 'pdf')
        .map(file => pdfjs.getDocument(file.image).promise),
    );

    let pdfIndex = 0;
    this.data.forEach(file => {
      if (file.type !== 'pdf') {
        data.push(file);
      } else {
        const numberOfPages = pdfFiles[pdfIndex].numPages;
        data.push(
          ...Array(numberOfPages)
            .fill(file)
            .map((f, i) => ({ ...f, pageNumber: i + 1, id: uuid() })),
        );
        pdfIndex += 1;
      }
    });

    this.sliderData = data;
  },
};
</script>
<template>
  <div class="slider-wrapper">
    <VueSlickCarousel
      v-if="sliderData?.length"
      ref="slider"
      class="slider"
      :arrows="true"
      :dots="true"
    >
      <div v-for="item in sliderData" :key="item.name" class="pdf-placeholder" target="_blank">
        <v-image-viewer v-if="item && item.type !== 'pdf'" class="image-zoom" :file="item" />
        <v-pdf-viewer
          v-else
          :name="item.name"
          :id="item.id"
          :controls="true"
          :src="item.image"
          :pageNumber="item.pageNumber"
          :showAllPages="false"
        />
      </div>
      <template #prevArrow>
        <div class="custom-arrow custom-arrow--left">
          <img src="@/assets/icons/ic-chevron-left.png" alt="" />
        </div>
      </template>
      <template #nextArrow>
        <div class="custom-arrow custom-arrow--right">
          <img src="@/assets/icons/ic-chevron-right.png" alt="" />
        </div>
      </template>
      <template #customPaging>
        <div class="custom-dot"></div>
      </template>
    </VueSlickCarousel>
    <div class="thumbnails">
      <button
        v-for="(item, loopIndex) in sliderData"
        :key="`${item.name}-${loopIndex}`"
        @click.prevent="moveTo(loopIndex)"
        class="thumbnail"
      >
        <v-pdf-viewer
          v-if="item.type === 'pdf'"
          :name="item.name"
          :src="item.image"
          :pageNumber="item.pageNumber"
          :showAllPages="false"
        />
        <img v-else :src="item.image" :key="item.name" class="thumbnail" />
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.slider {
  :deep() .slick-dots {
    bottom: 20px;

    li {
      width: 8px;
      height: 8px;
    }

    .custom-dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #fff;
      opacity: 0.4;
    }

    .slick-active {
      .custom-dot {
        opacity: 1;
      }
    }
  }
}

.custom-arrow {
  z-index: 100;
  background: #ffffff;
  border: 1px solid #eceef5;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &--left {
    left: 25px;
  }

  &--right {
    right: 25px;
  }

  &::before {
    content: none;
  }
}

.pdf-placeholder {
  overflow-x: auto;
  overflow-y: auto;
  height: 720px;
  border: 1px solid $color-gray-E1;
  border-radius: 3px;
  background-color: $color-gray-E9;

  :deep() .pdf-container {
    border: none;
    border-radius: 0;
    background-color: transparent;
    svg {
      font-size: 120px;
    }
  }
}

.thumbnails {
  display: flex;
  gap: 16px;
  margin-top: 31.98px;
  overflow-x: auto;

  img.thumbnail {
    width: 100%;
  }

  .thumbnail {
    cursor: pointer;
    display: inline-block;
    flex: 0 0 25%;
    min-width: 0;
    max-width: 200px;

    :deep() .pdf-container {
      border: 1px solid $color-gray-E1;
      border-radius: 3px;
      background-color: $color-gray-F5;
    }

    :deep() canvas {
      width: 100%;
    }
  }
}

@media (min-width: 1920px) {
  .pdf-placeholder {
    height: 960px;
    max-height: 80vh;
  }
  .slider-wrapper {
    height: 960px;
    max-height: 80vh;
    display: flex;
    flex-direction: row-reverse;
    gap: 16px;

    .slider {
      flex: 1 1 auto;
      overflow: hidden;
    }

    .thumbnails {
      flex: 1 0 200px;
      height: auto;
      flex-direction: column;
      margin-top: 0;

      .thumbnail {
        flex: 0 0 auto;
        max-width: auto;
        div {
          display: inline-block;
        }
      }
    }
  }
}
</style>
