<script>
import EzButton from '@/components/ui/Button/EzButton';
import { EzModal } from '@/components/ui/Modal';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import EmptyState from '@/views/common/empty-state/EmptyState';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzTable from '@/components/ui/Table/EzTable';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo';
import Product from '@/models/Product';
import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';
import EzAlert from '@/components/ui/Alert/EzAlert';
import EzBadge from '@/components/ui/Badge/EzBadge';
import {
  LOADING_KEY,
  ORDER_STATUS_PENDING,
  ORDER_STATUS_BACKORDER,
  ORDER_STATUS_QUOTE,
  UNIT_TYPE_FRACTIONAL,
  V_PRICE_TYPE,
} from '@/util/constants';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import { debounce, isExcluded, toShowPartiallyAcceptedTabs } from '@/util/utils';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import VPrice from '@/components/v3/elements/VPrice';
import EzFilterList from '@/components/ui/FilterList/EzFilterList';
import EzInput from '@/components/ui/Input/EzInput';
import uuid from 'uuid/v4';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'OrderAddProducts',
  components: {
    EzSpinner,
    EzButton,
    EzModal,
    EmptyState,
    EzLoader,
    EzLoadMore,
    EzCheckbox,
    EzTable,
    EzEntityInfo,
    EzAlert,
    EzBadge,
    VPrice,
    EzFilterList,
    EzInput,
    EzMaskInputSimple,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderId: {
      type: Number,
      required: true,
    },
    distributorId: {
      type: Number,
      required: false,
    },
    existingProductIds: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      submitDisabled: true,
      selectedProducts: [],
      anyWithZeroPrice: false,
      allProducts: [],
      V_PRICE_TYPE,
      commonCy,
      filters: {
        term: null,
      },
      searchResults: null,
      searchResultsMeta: null,
    };
  },
  computed: {
    ...mapState('entities/products', ['meta']),
    ...mapState('entities/users', ['contextId']),
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    ...mapGetters('entities/users', ['isDistributor', 'isVenue']),
    isLoadingMore() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_PRODUCTS,
        LOADING_KEY.LOAD_MORE_PRODUCTS,
        LOADING_KEY.DISTRIBUTOR_FETCH_PRODUCTS_FOR_VENUE,
        LOADING_KEY.SEARCH_FOR_VENUE_PRODUCTS,
        LOADING_KEY.SEARCH_FOR_DISTRIBUTOR_PRODUCTS,
      ]);
    },
    isAnySelected() {
      return this.selectedProducts.length > 0;
    },
    isAnyWithZeroPrice() {
      const withoutMProducts = this.selectedProducts.filter(p => !p.marketPrice);
      return withoutMProducts.some(pr => pr.price < 0);
    },
    excludedProductsIds() {
      return (this.order?.orderedProducts || []).filter(isExcluded).map(prod => prod.productId);
    },
    products() {
      let products = [];

      if (this.isDistributor) {
        products = this.allProducts;
      } else {
        products = Product.query()
          .with('category')
          .with('category.parent')
          .orderBy('name', 'asc')
          .all();
      }

      return products;
    },
    isOrderPending() {
      return this.order.status === ORDER_STATUS_PENDING;
    },
    hasBanner() {
      if (this.isVenue && this.isOrderPending) return false;
      return !!this.selectedProducts.find(p => p.inventoryTracked && p.quantity > p.inventory);
    },
    isDistributorWithPendingOrder() {
      return this.isDistributor && this.isOrderPending;
    },
    isButtonDisabled() {
      // Allow a distributor to add products to a pending order, ignore the hasBanner (inventory) check.
      // For every other case check the hasBanner (inventory).
      return (
        this.submitDisabled ||
        this.anyWithZeroPrice ||
        this.isSomeQuantityInvalid ||
        (!this.isDistributorWithPendingOrder && this.hasBanner)
      );
    },
    numberOfSelectedProducts() {
      return this.selectedProducts.length;
    },
    isLoading() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_ADD_PRODUCTS_TO_SINGLE_ORDER);
    },
    venueId() {
      return this.contextId;
    },
    isSomeQuantityInvalid() {
      return this.selectedProducts.some(pr => pr.quantityInvalid);
    },
    hasInvalidQuantities() {
      return this.selectedProducts.some(p => p.quantityInvalid);
    },
    notEnoughInventory() {
      return this.selectedProducts.some(p => p.quantity > p.customerInventory?.inventoryLevel);
    },
    hasProducts() {
      if (this.searchResults) return !!this.searchResults.length;
      return !!this.products.length;
    },
    currentMeta() {
      return this.searchResultsMeta || this.meta;
    },
    warehouseId() {
      if (
        this.order.status === ORDER_STATUS_PENDING ||
        this.order.status === ORDER_STATUS_BACKORDER ||
        this.order.status === ORDER_STATUS_QUOTE
      ) {
        return null;
      }
      return this.order.fulfillmentWarehouse?.id;
    },
  },
  watch: {
    isAnySelected(val) {
      this.submitDisabled = !val;
    },
    isAnyWithZeroPrice(val) {
      this.anyWithZeroPrice = val;
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'fetchMoreVenueProducts',
      'fetchVenueProducts',
      'distributorFetchProductsForVenue',
      'searchForVenueProducts',
      'searchForDistributorProducts',
    ]),
    ...mapMutations('entities/products', ['UPDATE_META']),
    ...mapActions('entities/orders', [
      'distributorAddProductsToSingleOrder',
      'venueAddProductsToSingleOrder',
    ]),
    rowDataCy(row) {
      if (row.marketPrice)
        return `${commonCy.SINGLE_ORDER.ADD_PRODUCTS_MODAL.ROW__MARKET_PRICE_PRODUCT}-${row.id}`;
      if (row.priceUnit)
        return `${commonCy.SINGLE_ORDER.ADD_PRODUCTS_MODAL.ROW__PRICE_UNIT_PRODUCT}-${row.id}`;
      return `${commonCy.SINGLE_ORDER.ADD_PRODUCTS_MODAL.ROW__PRODUCT}-${row.id}`;
    },
    onCheckboxChange(product, checked) {
      if (this.isVenue && product.customerInventory?.outOfStock) return;
      if (checked) {
        setTimeout(() => {
          const { orderedProducts } = this.order;
          const addIsIncludedFlag = toShowPartiallyAcceptedTabs(orderedProducts);

          const quantity = product.orderingUnitIncrement || 1;
          product.quantity = quantity < 1 ? 1 : quantity;
          this.selectedProducts.push({
            ...product,
            productId: product.id,
            id: uuid(),
            addedInEditMode: true,
            defaultPrice: product.defaultPrice ?? product.price,
            checked: true,
            quantityInvalid: false,
            quantity: product.quantity,
            priceQuantity: product.priceQuantity || product.quantity,
            ...(addIsIncludedFlag && { isIncluded: true }),
          });
        }, 100);
        return;
      }
      this.selectedProducts = this.selectedProducts.filter(prod => prod.id !== product.id);
    },
    onQuantityChange(quantity, id) {
      const product = this.selectedProducts?.find(pr => pr.id === id);
      if (product) {
        if (!product.priceUnit) {
          product.priceQuantity = quantity;
          product.quantity = quantity;
        }
        product.quantity = quantity;
      }
    },
    isOutOfStock({ id }) {
      const product = this.selectedProducts.find(pr => pr.id === id);
      return product.inventoryTracked && product.quantity > product.inventory;
    },
    isChecked(product) {
      return !!this.selectedProducts.find(pr => pr.id === product.id);
    },
    close() {
      this.$refs.modal.close();
    },
    open() {
      this.getProducts();
      this.$refs.modal.open();
      this.$emit('open');
    },
    getProductUnitLabel(product) {
      const { priceUnit, orderingUnit } = product || {};
      return priceUnit?.label || orderingUnit?.label || '';
    },
    isFractional(orderingUnit) {
      return orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    async getProductsDistributor({ order, params }) {
      const { venue } = order;
      const {
        data: { data, meta },
      } = await this.distributorFetchProductsForVenue({ venueId: venue.id, params });

      return { data, meta };
    },
    async getProducts() {
      if (this.isDistributor) {
        const params = {
          sortBy: 'name',
          ...(this.warehouseId ? { warehouseId: this.warehouseId } : {}),
        };
        const { data, meta } = await this.getProductsDistributor({ order: this.order, params });

        this.allProducts = data;
        this.UPDATE_META({ meta });
      } else {
        await this.fetchVenueProducts({
          payload: {
            sortBy: 'name',
            filters: {
              distributorId: this.distributorId,
              ...(this.warehouseId ? { warehouseId: this.warehouseId } : {}),
            },
          },
        });
      }
    },
    onClose() {
      this.$emit('close');
      this.selectedProducts = [];
      this.allProducts = [];
      Product.deleteAll();
    },
    async onLoadMore() {
      const data = {
        sortBy: 'name',
        nextValue: this.meta.nextValue,
      };
      if (this.isDistributor) {
        const params = {
          sortBy: 'name',
          ...(this.warehouseId ? { warehouseId: this.warehouseId } : {}),
          ...this.meta,
        };
        const { data: products, meta } = await this.getProductsDistributor({
          order: this.order,
          params,
        });

        this.allProducts = [...this.allProducts, ...products];
        this.UPDATE_META({ meta });
      } else {
        this.fetchMoreVenueProducts({
          filters: {
            distributorId: this.distributorId,
            ...(this.warehouseId ? { warehouseId: this.warehouseId } : {}),
          },
          ...data,
        });
      }
    },
    loadMore() {
      if (this.searchResultsMeta) this.refresh(true);
      else this.onLoadMore();
    },
    async productsSync() {
      try {
        this.$emit('change', this.selectedProducts);
        this.close();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    onQuantityInvalid(value, id) {
      const foundedPr = this.selectedProducts.find(pr => pr.id === id);
      if (foundedPr) {
        foundedPr.quantityInvalid = value;
      }
    },
    hasInvalidQuantity(product) {
      return this.selectedProducts.find(p => p.id === product.id)?.quantityInvalid;
    },
    resetFilters() {
      Object.keys(this.filters).forEach(key => {
        this.filters[key] = null;
      });
      this.refresh();
    },
    updateFilters: debounce(function deb(filterName, event) {
      if (filterName === 'search') {
        if (this.filters.term === event) return;
        this.searchResultsMeta = null;
        this.filters = {
          ...this.filters,
          term: event,
        };
      }
      this.refresh();
    }, 300),
    async refresh(loadMore = false) {
      if (this.filters.term) {
        const { data: res } = await this.contextAwareSearch();
        this.searchResults = [...(loadMore ? this.searchResults : []), ...res.data].filter(
          p => !this.excludedProductsIds.includes(p.productId),
        );
        this.searchResultsMeta = res.meta;
      } else {
        this.searchResults = null;
        this.searchResultsMeta = null;
      }
    },
    contextAwareSearch() {
      const query = {
        ...this.filters,
        ...(this.searchResultsMeta?.nextId ? { nextId: this.searchResultsMeta.nextId } : {}),
        ...(this.searchResultsMeta?.nextValue
          ? { nextValue: this.searchResultsMeta.nextValue }
          : {}),
        ...(this.isVenue ? { distributorId: this.distributorId } : {}),
        ...(this.warehouseId ? { warehouseId: this.warehouseId } : {}),
        sortBy: 'name',
        limit: 25,
      };

      return this.isVenue
        ? this.searchForVenueProducts({ venueId: this.venueId, query })
        : this.searchForDistributorProducts({ venueId: this.order.venue.id, query });
    },
    productQuantityMaxValue(row) {
      const { customerInventory: { orderMoreThanAvailable, inventoryLevel } = {} } = row;
      return !orderMoreThanAvailable && inventoryLevel !== null
        ? inventoryLevel
        : Number.MAX_SAFE_INTEGER;
    },
  },
};
</script>

<template>
  <EzModal ref="modal" @close="onClose()">
    <div class="modal__inner">
      <button class="modal__close" @click="close()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
          <!-- eslint-disable max-len -->
          <path
            d="M7.758 6.309L13.916.15c.2-.2.524-.2.725 0l.724.725c.2.2.2.524 0 .724L9.207 7.758l6.158 6.158c.2.2.2.524 0 .725l-.724.724c-.2.2-.525.2-.725 0L7.758 9.207l-6.159 6.158c-.2.2-.524.2-.724 0l-.725-.724c-.2-.2-.2-.525 0-.725L6.31 7.758.15 1.599c-.2-.2-.2-.524 0-.724L.875.15c.2-.2.524-.2.724 0L7.758 6.31z"
          />
          <!-- eslint-enable max-len -->
        </svg>
      </button>
      <div class="modal__title">
        <h2>Add Products</h2>
        <p>Select the products you would like to add to this order.</p>

        <ez-filter-list
          class="mt-16"
          :filters="filters"
          @resetFilter="resetFilters"
          @filterUpdated="updateFilters"
        >
          <ez-input
            formKey="filters"
            label="search"
            name="search"
            class="search width-100"
            :value="filters.term"
            placeholder="Search for products"
            :data-cy="commonCy.SINGLE_ORDER.ADD_PRODUCTS_MODAL.INPUT__SEARCH"
          >
            <template #suffix>
              <font-awesome-icon icon="search" />
            </template>
          </ez-input>
        </ez-filter-list>

        <ez-alert
          type="red"
          size="inline"
          variant="disclaimer"
          class="invalid-banner"
          v-if="hasInvalidQuantities"
        >
          <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
          <template #title>Invalid quantity added</template>
          <template v-if="notEnoughInventory"
            >This quantity is currently not available in
            {{ $t('global.distributor') | lowercase }}’s inventory.</template
          >
          <template v-else
            >The final quantity must be divisible with the quantity increment.</template
          >
        </ez-alert>
        <ez-alert type="red" size="inline" class="invalid-banner" v-if="hasBanner">
          <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
          Some of the added products exceed the available quantity.<template
            v-if="isDistributorWithPendingOrder"
          >
            You are still able to add them into this order.</template
          >
        </ez-alert>
      </div>
      <div class="modal__content">
        <ez-table
          v-if="hasProducts"
          :data="[...selectedProducts, ...(searchResults || products)]"
          :columns="[
            'product',
            ...(currentMeta.showAvailability ? ['availability'] : []),
            'quantity',
            'price',
          ]"
          :headers="{
            product: () => 'Product',
            sku: () => 'SKU',
            availability: () => '',
            price: () => 'Price per Unit',
          }"
          :column-props="{
            sku: { class: 'sku-cell' },
            availability: { class: 'extra-small-cell no-side-padding-cell text-right-cell' },
            quantity: { class: 'quantity-cell' },
            price: { class: 'price-small-custom' },
          }"
          @rowClick="onCheckboxChange($event, !isChecked($event))"
          :rowDataCy="rowDataCy"
        >
          <template #cell-product="{ row }">
            <div class="cell-product-container">
              <div @click.stop>
                <ez-checkbox
                  v-if="(isVenue && !row.customerInventory?.outOfStock) || isDistributor"
                  :checked="isChecked(row)"
                  class="cursor-pointer"
                  :key="row.id"
                  @change="onCheckboxChange(row, $event)"
                  :data-cy="commonCy.SINGLE_ORDER.ADD_PRODUCTS_MODAL.INPUT__PRODUCT_CHECKBOX"
                />
              </div>
              <ez-entity-info
                :class="[
                  { 'ml-8': (isVenue && !row.customerInventory?.outOfStock) || isDistributor },
                  { 'space-left': isVenue && row.customerInventory?.outOfStock },
                  { 'invalid-quantity': hasInvalidQuantity(row) },
                ]"
                imgWidth="2rem"
                imgHeight="2rem"
                :imgUrl="row.image"
              >
                <div class="product-info" :title="row.name">
                  <span>{{ row.name }}</span>
                  <div class="product-info-secondary mt-2">
                    {{ getProductUnitLabel(row) }}
                    <span class="ml-2" v-if="getProductUnitLabel(row) && row.sku">
                      &bull; {{ row.sku }}
                    </span>
                  </div>
                </div>
              </ez-entity-info>
            </div>
          </template>
          <template #cell-availability="{ row: { customerInventory } }">
            <font-awesome-icon
              v-if="customerInventory?.outOfStock"
              v-tooltip="{
                placement: 'top',
                content: 'Out of stock',
                classes: ['tooltip', 'tooltip--lift-up'],
              }"
              icon="minus-circle"
              class="text-red"
            />
          </template>
          <template #cell-quantity="{ row, row: { id, minimumQuantity, orderingUnit, quantity } }">
            <div @click.stop>
              <ez-mask-input-simple
                :data-cy="`${commonCy.SINGLE_ORDER.ADD_PRODUCTS_MODAL.BUTTON__QUANTITY}-quantity-${id}`"
                @click.stop
                :disabled="!isChecked({ id })"
                :class="[{ 'out-of-stock': isChecked({ id }) && isOutOfStock({ id }) }]"
                name="quantity"
                formKey=""
                :minValue="isChecked({ id }) ? minimumQuantity : 0"
                :maxValue="productQuantityMaxValue(row)"
                :value="isChecked({ id }) ? quantity ?? minimumQuantity : 0"
                :precision="isFractional(orderingUnit) ? 2 : 0"
                @input="onQuantityChange($event, id)"
                @invalid="onQuantityInvalid($event, id)"
                :has-currency="false"
              />
            </div>
          </template>
          <template #cell-price="{ row }">
            <div class="price">
              <v-price
                :price="row.price || 0"
                :currency="row.currency"
                :is-market-price="row.marketPrice"
                :show-market-price-info="false"
              />
            </div>
          </template>
        </ez-table>
        <empty-state v-else class="empty-state-suppliers">
          <template #badge><img src="@/assets/search-product-state.svg" alt="" /></template>
        </empty-state>

        <div v-if="isLoadingMore" class="u-text-center mt-12">
          <ez-spinner />
        </div>

        <ez-load-more v-if="currentMeta.nextId && !isLoadingMore" @loadMore="loadMore" />
      </div>
      <div class="modal__footer">
        <slot name="footer">
          <div class="products-selected">
            {{ numberOfSelectedProducts ? 'Products selected:' : 'No products selected' }}
            <ez-badge
              class="products-selected--counter"
              type="blue"
              :count="numberOfSelectedProducts"
            />
          </div>
          <div>
            <ez-button
              @click="close()"
              type="link"
              :data-cy="commonCy.SINGLE_ORDER.ADD_PRODUCTS_MODAL.BUTTON__CANCEL"
            >
              Cancel
            </ez-button>
            <ez-button
              @click="productsSync"
              :disabled="isButtonDisabled"
              :is-loading="isLoading"
              :data-cy="commonCy.SINGLE_ORDER.ADD_PRODUCTS_MODAL.BUTTON__CONFIRM"
            >
              Add Products
            </ez-button>
          </div>
        </slot>
      </div>
    </div>
    <ez-loader :show="false">Loading...</ez-loader>
  </EzModal>
</template>

<style lang="scss" scoped>
:deep() .modal {
  height: 100%;

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: px-to-rem(24px) px-to-rem(24px) px-to-rem(16px) px-to-rem(24px);
    max-width: 640px;
    max-height: 100%;
  }

  &__close {
    @extend %button-reset;
    position: absolute;
    top: 25px;
    right: px-to-rem(24px);
    @include font-size(18px);
    cursor: pointer;

    svg {
      fill: $secondary-color;
    }
  }

  &__title {
    margin-bottom: 16px;
    flex-direction: column;
    align-items: flex-start;

    h2 {
      margin: 0;
      @include font-size(20px);
      font-weight: 500;
    }

    p {
      @include font-size(14px);
    }

    .invalid-banner {
      margin: 16px 0 0;
    }
  }

  &__content {
    max-height: 564px;
    overflow: auto;
    padding-right: 8px;
    padding-bottom: 1px;

    .products-merge-list {
      :deep() .floating-bar {
        top: 0;
        margin-bottom: 0;
      }
      :deep() table {
        th,
        td {
          &:last-child {
            padding-right: 16px;
          }
        }
      }
    }

    .price-small-custom {
      width: 100px;
      text-align: right;
      padding-left: 0;
      text-transform: initial;
    }

    :deep() .ez-empty-state {
      &__image {
        max-width: none;
      }
      img {
        width: 256px;
        height: 118px;
      }
    }
    .cell-product-container {
      @extend %flex-center;
      justify-content: flex-start;

      .entity-info {
        min-width: 0;

        &.invalid-quantity {
          .entity-info__preview {
            border-color: $color-primary-red;
          }
        }

        .product-info span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .product-info {
      display: flex;
      flex-direction: column;

      .product-info-secondary {
        display: flex;
        align-items: center;
        @include font-size(12px, 14px);
        font-weight: 600;
        color: $color-gray-25;
        span {
          font-weight: 500;
          color: $color-gray-6C;
        }
      }
    }
    .price {
      @include font-size(16px);
      font-weight: 600;
      color: $color-gray-25;
    }
    .out-of-stock.ez-number-input {
      :deep() .input-group {
        &__input {
          input {
            border-bottom: 1px solid $color-primary-red;
          }
        }
      }
    }

    .quantity-cell {
      width: 130px;
      overflow: visible;

      .status--out_of_stock {
        display: block;
        margin: 0 auto;
        width: max-content;
      }
    }
  }

  &__footer {
    @extend %flex-center;
    justify-content: space-between;
    padding-top: 16px;
    .products-selected {
      @extend %flex-center;
      justify-content: center;
      @include font-size(14px, 20px);
      color: $color-gray-6C;

      &--counter {
        margin: 0 0 0 6px;
      }
    }
  }
  .space-left {
    margin-left: 22px;
  }
}

:deep() .mask-input.mark-input--inline {
  .mask-input__input {
    font-weight: normal;
    color: $color-gray-25;
    text-align: center;
  }
}
</style>
