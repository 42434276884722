<template>
  <div :class="[
    'note',
    { 'note__is-mine': !isResponse },
    ]">
    <div>
      <slot name="avatar"></slot>
    </div>
    <div class="note__message">
      <div class="note__text">
        <slot></slot>
      </div>
      <div class="note__date">
        <slot name="date"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isResponse: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.note {
  @include font-size(14px);
  display: flex;

  + .note {
    margin-top: .75rem;
  }

  &__message {
    width: 100%;
    margin: 0 0 0 .5rem ;
  }

  &__text {
    max-width: 80%;
    padding: .5rem 1rem;
    border-radius: .0625rem .75rem .75rem .75rem;
    background-color: $color-gray-F5;
    white-space: pre-wrap;
    word-break: break-word;
    width: fit-content;
  }

  &__date {
    margin-top: .5rem;
    @include font-size(12px);
    color: $color-gray-6C;
  }

  &__is-mine {
    flex-direction: row-reverse;
    .note__text {
      border-radius: .75rem .0625rem .75rem .75rem;
      margin-left: auto;
    }
    .note__date {
      text-align: right;
    }
    .note__message {
      margin: 0 .5rem 0 0;
    }
  }
}
</style>
