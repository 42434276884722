var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltip),expression:"tooltip"}],class:[
    'v-product-quantity-inventory',
    {
      'dashed-underline': _vm.canShowTooltip,
      'dashed-underline--out-of-stock': _vm.canShowTooltip && _vm.outOfStock && _vm.canBeInvalid,
    },
  ],attrs:{"data-cy":_vm.dataCy}},[_vm._v(" "+_vm._s(_vm.showTotalAsLabel ? _vm.total : _vm.product.quantity)+" ")])
}
var staticRenderFns = []

export { render, staticRenderFns }