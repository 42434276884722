<template>
  <div>
    <ez-table
      class="delivery-table"
      v-if="tableData.length > 0"
      :columns="tableColumns"
      :headers="headers"
      :data="tableData"
      :has-min-height="false"
      disable-hover
      :column-props="{
        totalAmount: { class: 'small-cell price-cell pt-8' },
        additionalItems: { class: 'pt-8'},
      }"
      @rowHover="rowHover">
      <template #cell-additionalItems="{ row }">
        <div class="media-info">
          <v-icon-badge :icon="row.icon" :color="row.color"/>
          <div class="media-info__content">
            <div class="media-info__title">{{ row.title }}</div>
          </div>
        </div>
      </template>
      <template #cell-totalAmount="{row}">
        <div v-if="isRowHovered(row) && isEditable">
          <font-awesome-icon
            :data-cy="`${dataCy}-editDeliveryFee`"
            class="table-icon mr-16"
            v-tooltip="`Edit ${row.title}`"
            @click.stop="edit(row)"
            icon="pen"
          />
          <font-awesome-icon
            :data-cy="`${dataCy}-removeDeliveryFee`"
            class="table-icon"
            v-tooltip="`Remove ${row.title}`"
            icon="trash"
            @click.stop="deleteDeliveryFee(row)"
          />
        </div>
        <span v-else :data-cy="dataCy">{{ row.amount | price }}</span>
      </template>
    </ez-table>
    <div class="button-wrapper" v-if="canAddDeliveryFee">
      <ez-button
        :data-cy="`${dataCy}-addDeliveryFee`"
        class="mt-16"
        type="secondary"
        is-full-width
        @click="addDeliveryFee"
      >
        <font-awesome-icon icon="plus" />
        Add Delivery Fee
      </ez-button>
    </div>
    <div class="button-wrapper" v-if="canAddPriceAdjustment">
      <ez-button
        class="mt-16"
        type="secondary"
        is-full-width
        @click="addPriceAdjustment"
        :data-cy="''"
      >
        <font-awesome-icon icon="plus" />
        Add Price Adjustment
      </ez-button>
    </div>

    <div class="button-wrapper" v-if="canAddTaxAdjustment">
      <ez-button
        class="mt-16"
        type="secondary"
        is-full-width
        @click="addTaxAdjustment"
        :data-cy="''"
      >
        <font-awesome-icon icon="plus" />
        Add Tax Adjustment
      </ez-button>
    </div>

    <ez-form-modal ref="editModal">
      <template #title>Edit {{ editRow.title }}</template>

      <template #content>
        <ez-mask-input :value="editRow.amount || 0" type="input" @input="onPriceChange" />
      </template>

      <template #footer>
        <ez-button @click="close" type="link">Cancel</ez-button>
        <ez-button :disabled="isDisabledSave" @click="save">Save Changes</ez-button>
      </template>
    </ez-form-modal>

    <ez-form-modal ref="editModalPrice">
      <template #title>{{isEditModalPrice ? 'Edit' : 'Add'}} Order {{ editRow.title }}</template>

      <template #content>
        <ez-mask-input
          :value="editRow.amount || 0"
          type="input"
          @input="onPriceAdjustmentChange"
          :data-cy="''"
        />
      </template>

      <template #footer>
        <ez-button
          @click="closePrice"
          type="link"
          :data-cy="''"
        >
          Cancel
        </ez-button>
        <ez-button
          :disabled="!editFieldChanged"
          @click="savePriceAdjustment"
          :data-cy="''"
        >
          Save Changes
        </ez-button>
      </template>
    </ez-form-modal>

    <ez-form-modal ref="editModalTax">
      <template #title>{{isEditModalTax ? 'Edit' : 'Add'}} Order {{ editRow.title }}</template>

      <template #content>
        <ez-mask-input
          :value="editRow.amount || 0"
          type="input"
          @input="onTaxAdjustmentChange"
          :data-cy="''"
        />
      </template>

      <template #footer>
        <ez-button
          @click="closeTax"
          type="link"
          :data-cy="''"
        >
          Cancel
        </ez-button>
        <ez-button
          :disabled="!editFieldChanged"
          @click="saveTax"
          :data-cy="''"
        >
          Save Changes
        </ez-button>
      </template>
    </ez-form-modal>
  </div>
</template>

<script>
/**
 *
 * @version 1.0.0
 * @since 3.10.0
 */

import EzTable from '@/components/ui/Table/EzTable';
import VIconBadge from '@/components/v3/patterns/VIconBadge';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput';
import EzButton from '@/components/ui/Button/EzButton';
import EzFormModal from '@/components/ui/Modal/EzFormModal';
import { mapGetters } from 'vuex';
import { ADDITIONAL_ITEMS } from '@/util/constants';

export default {
  name: 'AdditionalDeliveryFee',
  components: {
    VIconBadge,
    EzFormModal,
    EzButton,
    EzMaskInput,
    EzTable,
  },
  props: {
    editable: {
      type: Boolean,
      required: false,
      default: true,
    },
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
    distributor: {
      type: Object,
      required: false,
      default: () => {},
    },
    /**
     * This attribute is used for
     * marking the element when testing with cypress
     */
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableColumns: ['additionalItems', 'totalAmount'],
      headers: {
        additionalItems: () => '',
        totalAmount: () => '',
      },
      hoveredRow: null,
      editRow: {},
      editFieldChanged: true,
      newValue: 0,
      editableValue: null,
      priceEditableValue: null,
      taxEditableValue: null,
      isDisabledSave: false,
      isEditModal: false,
      isEditModalPrice: false,
      isEditModalTax: false,
      priceAdjustmentAmount: 0,
      taxAdjustmentAmount: 0,
    };
  },
  computed: {
    ...mapGetters('entities/users', ['isDistributor', 'isVenue']),
    deliveryFee() {
      return this.editableValue;
    },
    priceAdjustment() {
      return this.priceEditableValue;
    },
    taxAdjustment() {
      return this.taxEditableValue;
    },
    /**
     * Venue can edit when he is Premium & Order is made by Freemium Supplier
     *
     * @return {boolean}
     */
    canVenueEdit() {
      return this.$permission.isPremium;
    },
    canDistributorEdit() {
      return this.isDistributor;
    },
    canUserEdit() {
      return this.isVenue ? this.canVenueEdit : this.canDistributorEdit;
    },
    isEditable() {
      return this.editable && this.canUserEdit;
    },
    canAddDeliveryFee() {
      const isDeliveryFeeZero = this.deliveryFee === 0;

      return this.isEditable && isDeliveryFeeZero;
    },
    canAddPriceAdjustment() {
      const isPriceAdjustment = this.priceAdjustment === 0;

      return this.isEditable && isPriceAdjustment;
    },
    canAddTaxAdjustment() {
      const isTaxAdjustment = this.taxAdjustment === 0;

      return this.isEditable && isTaxAdjustment;
    },
    deliveryFeeData() {
      const editMsg = '';
      const noEditMsg = '';

      return this.deliveryFee
        ? [
          {
            key: ADDITIONAL_ITEMS.DELIVERY_FEE,
            icon: 'truck',
            title: 'Delivery Fee',
            description: this.canUserEdit ? editMsg : noEditMsg,
            amount: this.deliveryFee,
            color: 'orange',
          },
        ]
        : [];
    },
    priceData() {
      return [
        {
          key: ADDITIONAL_ITEMS.PRICE_ADJUSTMENT,
          icon: 'tag',
          title: 'Price Adjustment',
          description: '',
          amount: this.priceAdjustment,
          color: 'blue',
        },
      ];
    },
    taxData() {
      return [
        {
          key: ADDITIONAL_ITEMS.TAX_ADJUSTMENT,
          icon: 'balance-scale',
          title: 'Tax Adjustment',
          description: '',
          amount: this.taxAdjustment,
          color: 'purple',
        },
      ];
    },
    tableData() {
      return [
        ...this.deliveryFeeData,
        ...(this.priceAdjustment ? this.priceData : []),
        ...(this.taxAdjustment ? this.taxData : []),
      ];
    },
  },
  methods: {
    rowHover(row) {
      this.hoveredRow = row;
    },
    edit(row) {
      this.editRow = row;
      if (row.key === ADDITIONAL_ITEMS.PRICE_ADJUSTMENT) {
        this.isEditModalPrice = true;
        this.$refs.editModalPrice.open();
        return;
      }
      if (row.key === ADDITIONAL_ITEMS.TAX_ADJUSTMENT) {
        this.isEditModalTax = true;
        this.$refs.editModalTax.open();
        return;
      }
      this.isEditModal = true;
      this.$refs.editModal.open();
    },
    close() {
      this.$refs.editModal.close();
      this.isEditModal = false;
      this.editRow = {};
    },
    closePrice() {
      this.$refs.editModalPrice.close();
      this.isEditModalPrice = false;
      this.editRow = {};
    },
    closeTax() {
      this.$refs.editModalTax.close();
      this.isEditModalTax = false;
      this.editRow = {};
    },
    isRowHovered(row) {
      if (this.hoveredRow) {
        return row.id === this.hoveredRow.id;
      }
      return false;
    },
    onPriceChange(val) {
      this.newValue = val;
      this.isDisabledSave = val < 0;
    },
    onPriceAdjustmentChange(val) {
      this.priceAdjustmentAmount = val;
    },
    onTaxAdjustmentChange(val) {
      this.taxAdjustmentAmount = val;
    },
    deleteDeliveryFee(row) {
      this.editRow = row;
      this.$emit('change', { ...this.editRow, amount: 0 });
    },
    addDeliveryFee() {
      this.editRow = {
        key: 'delivery-fee',
        icon: 'truck',
        title: 'Delivery Fee',
        description: 'Additional charge that will be applied to the order.',
        amount: this.deliveryFee,
      };
      this.$refs.editModal.open();
    },
    addPriceAdjustment() {
      this.editRow = {
        key: ADDITIONAL_ITEMS.PRICE_ADJUSTMENT,
        icon: 'tag',
        title: 'Price Adjustment',
        description: 'Included to help account for non-standard tax issues (editable).',
        amount: this.priceAdjustment,
      };
      this.$refs.editModalPrice.open();
    },
    addTaxAdjustment() {
      this.editRow = {
        key: ADDITIONAL_ITEMS.TAX_ADJUSTMENT,
        icon: 'balance-scale',
        title: 'Tax Adjustment',
        description: 'Included to help account for non-standard tax issues (editable).',
        amount: this.taxAdjustment,
      };
      this.$refs.editModalTax.open();
    },
    save() {
      this.$emit('changeDeliveryFee', { ...this.editRow, amount: this.newValue });
      this.editableValue = this.newValue;

      this.close();
    },
    savePriceAdjustment() {
      this.$emit('changeAmountAdjustment', { ...this.editRow, amount: this.priceAdjustmentAmount });
      this.priceEditableValue = this.priceAdjustmentAmount;
      this.closePrice();
    },
    saveTax() {
      this.$emit('changeTaxAdjustment', { ...this.editRow, amount: this.taxAdjustmentAmount });
      this.taxEditableValue = this.taxAdjustmentAmount;
      this.closeTax();
    },
  },
  watch: {
    data(newVal) {
      this.editableValue = newVal.deliveryFee;
      this.priceEditableValue = newVal.amountAdjustment;
      this.taxEditableValue = newVal.taxAdjustment;
    },
  },
  created() {
    this.editableValue = this.data.deliveryFee;
    this.priceEditableValue = this.data.amountAdjustment;
    this.taxEditableValue = this.data.taxAdjustment;
  },
};
</script>

<style lang="scss" scoped>
:deep() .delivery-table {
  thead {
    tr {
      display: none;
    }
  }
  tbody {
    tr {
      td {
        padding: 0;
        border: none;
      }
    }
  }
}
.media-info {
  display: flex;
  align-items: center;

  &__content { margin-left: 8px; }
  &__title {
    color: #252631;
    @include font-size(14px, 20px);
  }
  &__subtitle {
    @include font-size(12px, 14px);
  }
}

.table-icon {
  color: #6C7995;
  cursor: pointer;
}

</style>
