<script>
/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */

import { wizardEmitterMixin } from '@/mixins/wizard';
import EzButton from '@/components/ui/Button/EzButton';
import EzWizard from '@/components/layout/Wizard';
import steps, {
  ONBOARDING_STEP_1, ONBOARDING_STEP_2,
} from '@/views/platform/venue/suppliers/onboarding/steps';
import { mapMutations } from 'vuex';
import Footer from '@/views/platform/venue/suppliers/onboarding/Footer';

export default {
  mixins: [wizardEmitterMixin],
  components: {
    EzButton,
    EzWizard,
    Footer,
  },
  props: {
    supplierId: {
      type: Number,
    },
  },
  data() {
    return {
      currentStep: 0,
      SUPPLIER_STEP_1: ONBOARDING_STEP_1,
      SUPPLIER_STEP_2: ONBOARDING_STEP_2,
      nextDisabled: true,
    };
  },
  computed: {
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === ONBOARDING_STEP_1;
    },
    isLastStep() {
      return this.currentStep === ONBOARDING_STEP_2;
    },
    disableNext() {
      return false;
    },
  },
  methods: {
    onWizardExit() {
      this.CLEAR_ONBOARDING_CONTACTS();
      this.$router.push({ name: 'venue-home' });
    },
    onStepBack(step) {
      if (step !== undefined && step >= ONBOARDING_STEP_1 && step <= ONBOARDING_STEP_2) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= ONBOARDING_STEP_1) {
        return;
      }

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= ONBOARDING_STEP_2) {
        return;
      }
      this.nextDisabled = true;
      this.currentStep += 1;
    },
    ...mapMutations('entities/venues', [
      'CLEAR_ONBOARDING_CONTACTS',
    ]),
  },
};
</script>
<template>
  <ez-wizard
    class="new-supplier"
    @exit="onWizardExit"
    @back="goToPreviousStep">
    <template #title>Account Opening Form</template>
    <template #nav>
      <ul>
        <li :class="{
          'ez-step-active': currentStep === SUPPLIER_STEP_1,
          'ez-step-completed': currentStep > SUPPLIER_STEP_1,
        }">
          1. Contacts
        </li>
      </ul>
    </template>
    <template #actions>
      <ez-button
        :disabled="nextDisabled"
        @click="goToNextStep">{{ nextCta }}</ez-button>
    </template>
    <template #pageTitle>{{ currentStepPageTitle }}</template>
    <template #pageInfo><span v-html="currentStepPageInfo"/></template>
    <component
      :is="currentStepComponent"
      @stepCompleted="onStepCompleted"
      :supplierId="supplierId"
      @stepBack="onStepBack" />
    <Footer
      @accepted="enableNextStep"
      @download="downloadTosPdf"
      :supplier-id="supplierId"
      :hasActions="footerActions" />
  </ez-wizard>
</template>
<style lang="scss" scoped>
  .new-supplier {
    overflow: scroll;
    height: 100%;
  }
</style>
