<script>
import { mapActions, mapGetters } from 'vuex';
import { isDistributor, isVenue, LOADING_KEY } from '@/util/constants';
import EzLoader from '@/components/ui/Loader/EzLoader.vue';
import flash from '@/components/ui/FlashMessage';

import VSelectSearchProductGroup from './VSelectSearchProductGroup.vue';
import RemoveGroupModal from './RemoveGroupModal.vue';
import AddGroupModal from './AddGroupModal.vue';

/**
 * VProductGroupSettings
 * @version 1.0.0
 * @since 3.29.0
 */
export default {
  name: 'VProductGroupSettings',
  props: {
    name: {
      type: String,
      required: true,
    },
    selectedId: {
      type: Number,
      required: false,
    },
    selectOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    updateProductGroups: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    EzLoader,
    VSelectSearchProductGroup,
    RemoveGroupModal,
    AddGroupModal,
  },
  data() {
    return {
      groups: [],
      selected: null,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.CREATE_PRODUCT_GROUP,
        LOADING_KEY.UPDATE_PRODUCT_GROUP,
        LOADING_KEY.DELETE_PRODUCT_GROUP,
      ]);
    },
    fetchProductGroups() {
      if (isDistributor()) return this.distributorFetchProductGroups;
      if (isVenue()) return this.venueFetchProductGroups;
      return this.adminFetchProductGroups;
    },
    createProductGroup() {
      if (isDistributor()) return this.distributorCreateProductGroup;
      if (isVenue()) return this.venueCreateProductGroup;
      return this.adminCreateProductGroup;
    },
    updateProductGroup() {
      if (isDistributor()) return this.distributorUpdateProductGroup;
      if (isVenue()) return this.venueUpdateProductGroup;
      return this.adminUpdateProductGroup;
    },
    deleteProductGroup() {
      if (isDistributor()) return this.distributorDeleteProductGroup;
      if (isVenue()) return this.venueDeleteProductGroup;
      return this.adminDeleteProductGroup;
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'distributorFetchProductGroups',
      'distributorCreateProductGroup',
      'distributorUpdateProductGroup',
      'distributorDeleteProductGroup',
      'venueFetchProductGroups',
      'venueCreateProductGroup',
      'venueUpdateProductGroup',
      'venueDeleteProductGroup',
      'adminFetchProductGroups',
      'adminCreateProductGroup',
      'adminUpdateProductGroup',
      'adminDeleteProductGroup',
    ]),
    async fetchGroups() {
      try {
        const { data } = await this.fetchProductGroups();
        this.groups = data.data;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        flash.error({ title: 'Something went wrong!' });
      }
    },
    onAddGroup() {
      this.$refs.addGroupModal.open();
    },
    onEdit(group) {
      this.$refs.addGroupModal.open(group, true);
    },
    async onAddConfirm({ groupId, name }) {
      const isEdit = !!groupId;
      try {
        if (isEdit) await this.updateProductGroup({ id: groupId, name, formKey: 'createProductGroup' });
        else {
          const { data } = await this.createProductGroup({ name, formKey: 'createProductGroup' });
          this.onSelect(data.data);
        }

        flash.success({ title: `Product group successfully ${isEdit ? 'updated' : 'created'}!` });
        this.$refs.addGroupModal.close();

        await this.fetchGroups();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    onRemove(group) {
      this.$refs.removeGroupModal.open(group);
    },
    async onRemoveConfirm(groupId) {
      try {
        await this.deleteProductGroup({ id: groupId });
        flash.success({ title: 'Product group successfully removed!' });

        if (groupId === this.selected) this.$refs.selectProductGroup.reset();
        this.$refs.removeGroupModal.close();

        await this.fetchGroups();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        flash.error({ title: 'Something went wrong!' });
      }
    },
    onSelect(group) {
      this.selected = group.id;
      this.$emit('onChange', group);
    },
    reset() {
      this.$refs.selectProductGroup.reset();
    },
  },
  async mounted() {
    await this.fetchGroups();
  },
  watch: {
    selectedId: {
      immediate: true,
      handler(val) {
        this.selected = val;
      },
    },
    updateProductGroups: {
      async handler(val) {
        if (val) {
          await this.fetchGroups();
        }
      },
    },
  },
};
</script>

<template>
  <div class="input-group mt-12">
    <v-select-search-product-group
      ref="selectProductGroup"
      :data="groups"
      :name="name"
      :selectedId="selected"
      label="Product Group"
      placeholder="Choose Product Group"
      searchProperty="name"
      :has-actions="!selectOnly"
      @addGroup="onAddGroup"
      @edit="onEdit"
      @remove="onRemove"
      @select="onSelect"
    />
    <remove-group-modal ref="removeGroupModal" @remove="onRemoveConfirm" />
    <add-group-modal ref="addGroupModal" @confirm="onAddConfirm" />
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<style lang="scss" scoped></style>
