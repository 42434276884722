<script lang="js">
/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'VDataWithInfo',
  props: {
    showUnderline: {
      required: false,
      type: Boolean,
      default: () => true,
    },
    /**
     * Info will be shown on hover in default tooltip.
     */
    info: {
      required: false,
      type: String,
      default: () => '',
    },
    /**
     * If tooltip is passed, it will be shown instead of info.
     */
    tooltip: {
      required: false,
      type: Object,
      default: () => null,
    },
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
  },
  computed: {
    defaultTooltip() {
      return {
        content: `${this.info}`,
        classes: ['tooltip-general'],
      };
    },
  },
};
</script>

<template>
  <span
    :class="[{ 'v-data-with-info__underline': showUnderline }, { 'cursor-help': info || tooltip }]"
    v-tooltip="tooltip || defaultTooltip"
    :data-cy="dataCy"
  >
    <slot></slot>
  </span>
</template>

<style scoped lang="scss">
.v-data-with-info__underline {
  border-bottom: 2px dotted rgba($color-gray-6C, .64);
}
</style>
