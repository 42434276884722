<script>
import { mapState, mapActions } from 'vuex';
import Group from '@/models/Group';
import EzOnOff from '@/components/ui/OnOff';
import EzForm from '@/components/ui/Form';
import flash from '@/components/ui/FlashMessage';
import EzInput from '@/components/ui/Input';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  components: {
    EzOnOff,
    EzForm,
    EzInput,
    EzButton,
    EzCheckbox,
  },
  data() {
    return {
      formKey: 'venue-group-settings',
      loadingKeyForm: 'venue-update-group-settings',
      priceChangeNotificationPercent: null,
      shouldUpdateReferencePrice: false,
      override: [],
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    venueGroup() {
      return Group.query().first() || {};
    },
  },
  async created() {
    await this.fetchGroup();
    this.shouldUpdateReferencePrice = this.venueGroup.shouldUpdateReferencePrice || false;
    this.priceChangeNotificationPercent = this.venueGroup.priceChangeNotificationPercent || null;
    this.override = this.venueGroup.groupOverride || [];
  },
  methods: {
    ...mapActions('entities/groups', ['fetchGroup']),
    async saveChanges() {
      try {
        this.$refs.form.onSubmit();
        flash.success({ title: 'Venue group settings successfully updated!' });
      } catch (e) {
        flash.error({ title: 'Something went wrong!' });
      }
    },
    transformer(data) {
      if (!this.shouldUpdateReferencePrice)
        data = { ...data, shouldUpdateReferencePrice: this.shouldUpdateReferencePrice.toString() };
      data = {
        ...data,
        groupOverride: this.override,
      };
      return data;
    },
    onGroupOverrideChange(event, val) {
      if (event) {
        this.override = [...this.override, val];
      } else {
        this.override = this.override.filter(item => item !== val);
      }
    },
  },
};
</script>
<template>
  <div class="sales-settings">
    <ez-form
      :formKey="formKey"
      :loading-key="loadingKeyForm"
      :transformer="transformer"
      action="/venue-group"
      method="patch"
      ref="form"
    >
      <div class="price-change-input">
        <h4 class="price-change-input__title">Default Price Change Notification</h4>
        <div class="price-change-input__info">
          Set the default threshold for notification when a price changes from the Product Reference
          Price.
        </div>
        <div class="u-flex-center price-change-input__box">
          <p class="price-change-input__label">Send a notification if a price changes by</p>
          <ez-input
            :form-key="formKey"
            type="number"
            name="priceChangeNotificationPercent"
            :value="priceChangeNotificationPercent"
            min="0"
            max="100"
            :isTooltipError="true"
          />
          <p class="price-change-input__label">%.</p>
        </div>
        <ez-checkbox
          :checked="override.includes('priceChangeNotificationPercent')"
          label="Override for all outlets"
          @change="onGroupOverrideChange($event, 'priceChangeNotificationPercent')"
        />
      </div>

      <div class="sales-settings__section sales-settings__section--notifications container--small">
        <div class="help-block">
          <h4 class="help-block__title custom-title">Update Product Reference Price</h4>
          <p class="help-block__text mb-0">
            When enabled, the product reference price will update to the price from the latest
            invoice. Otherwise the manually set price will remain the reference price for price
            change notifications.
          </p>
        </div>
        <ez-on-off
          :formKey="formKey"
          name="shouldUpdateReferencePrice"
          :value="shouldUpdateReferencePrice.toString()"
          :checked="shouldUpdateReferencePrice"
          @change="$ev => (shouldUpdateReferencePrice = $ev)"
        />
      </div>
      <ez-checkbox
        class="mt-12"
        :checked="override.includes('shouldUpdateReferencePrice')"
        label="Override for all outlets"
        @change="onGroupOverrideChange($event, 'shouldUpdateReferencePrice')"
      />

      <ez-button class="mt-24" form-type="submit">Save Changes</ez-button>
    </ez-form>
  </div>
</template>

<style lang="scss" scoped>
.sales-settings {
  &__section {
    display: flex;
    align-items: flex-start;

    &--notifications {
      .help-block {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }

    .help-block {
      &__title {
        &.custom-title {
          font-weight: 600;
        }
      }

      &__text {
        @include font-size(14px, 20px);
      }
    }
  }
}
.price-change-input {
  margin-bottom: 1rem;
}

.price-change-input__title {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.price-change-input__info {
  @include font-size(12px);
  color: $color-gray-6C;
  margin: 0;
  line-height: px-to-rem(18px);
}

.price-change-input__box {
  height: 36px;
  margin: 12px 0;

  :deep() .input-group {
    max-width: 56px;
    margin: 0 8px;
    text-align: right;
  }
}
.price-change-input__label {
  @include font-size(14px);
}
</style>
