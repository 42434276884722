<template>
  <ez-form-modal ref="modal" @close="onModalClose">
    <template #title>
      {{ isNewVenue
      ? `Add ${$t('global.venue')}`
      : `Update ${$t('global.venue')}` }}
    </template>
    <template #content>
      <venue-form
        class="venue-form"
        ref="form"
        :method="method"
        :action="action"
        :formKey="formKey"
        :additional-headers="{ loadingKey }"
        :venueId="venueId"
        :venueData="venueData"
        @formValid="(val) => formValid = val"
        @submitSuccess="onSubmitSuccess"
        @submitError="onSubmitError"
      />
    </template>
    <template #footer>
      <ez-button
        type="link"
        formType="button"
        @click="close">Cancel</ez-button>
      <ez-button :is-loading="isLoading" @click="submitForm" :disabled="!formValid">
        {{ isNewVenue
        ? `Add ${$t('global.venue')}`
        : `Update ${$t('global.venue')}` }}
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { EzFormModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import VenueForm from '@/views/admin/venues/Form.vue';
import { LOADING_KEY } from '@/util/constants';

export default {
  components: {
    EzFormModal,
    EzButton,
    VenueForm,
  },
  props: {
    groupId: {
      type: Number,
    },
    venueId: {
      type: Number,
      default: -1,
    },
    venueData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      formValid: false,
      formKey: 'create-venue-form',
      loadingKey: LOADING_KEY.VENUE_GROUP_ADD_VENUE,
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    isAdmin() {
      return localStorage.getItem('role') === 'admin';
    },
    isNewVenue() {
      return this.venueId === -1;
    },
    action() {
      return this.isNewVenue ? this.createAction : this.updateAction;
    },
    createAction() {
      if (this.isAdmin) {
        return `/admin/venue-groups/${this.groupId}/venues`;
      }
      if (this.loggedUser.group?.id !== this.groupId) {
        return `venue-groups/${this.groupId}/venues`;
      }

      return '/venue-group/venues';
    },
    updateAction() {
      if (this.isAdmin) {
        return `/admin/venues/${this.venueId}`;
      }

      if (this.loggedUser.group?.id !== this.groupId) {
        return `venue-groups/${this.groupId}/venues/${this.venueId}`;
      }

      return `/venues/${this.venueId}`;
    },
    method() {
      return this.isNewVenue ? 'post' : 'patch';
    },
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.VENUE_GROUP_ADD_VENUE);
    },
  },
  methods: {
    ...mapMutations('errors', ['CLEAR_ERRORS']),
    async open() {
      this.$refs.modal.open();

      await this.$refs.form.initialFormState();
      this.$refs.form.validateForm();
    },
    close() {
      this.$refs.modal.close();
    },
    submitForm() {
      this.$refs.form.submitForm();
    },
    onModalClose() {
      this.CLEAR_ERRORS({ key: this.formKey });
      this.$refs.form.resetForm();
      this.$emit('close');
    },
    onSubmitSuccess(response) {
      this.$emit('submitSuccess', response);
    },
    onSubmitError(response) {
      this.$emit('submitError', response);
    },
  },
};
</script>

<style scoped lang="scss">
@mixin title() {
  @include font-size(12px, 14px);
  text-transform: uppercase;
  color: $color-gray-6C;
  font-weight: 500;
}
  :deep() .address-form {
    legend {
      @include title;
    }
  }
  .venue-form {
    :deep() h4 {
      @include title;
    }
  }
</style>
