<script>
import EzAddressForm from '@/views/common/address/Form';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzForm from '@/components/ui/Form/EzForm';
import EzInput from '@/components/ui/Input/EzInput';
import EzWorkingHours from '@/components/ui/WorkingHours/EzWorkingHours';
import { falsy } from '@/util/utils';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  components: {
    EzAddressForm,
    EzCheckbox,
    EzForm,
    EzInput,
    EzWorkingHours,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    venueId: {
      type: Number,
      required: false,
      default: -1,
    },
    additionalData: {
      type: Object,
      default: () => {},
    },
    submitType: {
      type: String,
      default: 'urlencoded',
    },
    prefilledName: {
      type: String,
      default: '',
    },
    additionalHeaders: {
      type: Object,
      required: false,
      default: null,
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      billingAddressEnabled: true,
      formValid: false,
      requiredValues: {
        name: null,
        deliveryAddress: null,
        country: null,
        city: null,
      },
      billingAddressRequiredValues: {
        billingAddress: null,
        billingCountry: null,
        billingCity: null,
      },
      copyOfRequiredValues: {},
      copyOfRequiredFields: {},
    };
  },
  computed: {
    venue() {
      return this.data || {};
    },
    hasVenue() {
      return Object.keys(this.data).length;
    },
    workingHours() {
      return this.venue.workingHours || {};
    },
    isBillingChecked() {
      return this.venue.billingAddress ? Object.keys(this.venue.billingAddress).length > 0 : false;
    },
    requiredAddressFields() {
      return {
        street: 'required',
        city: 'required',
        country: 'required',
      };
    },
  },
  watch: {
    formValid() {
      this.$emit('formValid', this.formValid);
    },
    billingAddressEnabled(val) {
      if (!val) {
        this.copyOfRequiredFields = {
          ...this.requiredValues,
          ...this.billingAddressRequiredValues,
        };
      } else {
        this.copyOfRequiredFields = this.requiredValues;

        this.copyOfRequiredValues.billingAddress = null;
        this.copyOfRequiredValues.billingCity = null;
        this.copyOfRequiredValues.billingCountry = null;
      }
      this.validateForm();
    },
  },
  methods: {
    resetForm() {
      this.$refs.venueForm.reset();
      this.$refs.secondaryContact.reset();
      this.$refs.deliveryAddress.reset();
      if (!this.billingAddressEnabled) this.$refs.billingAddress.reset();
      this.$refs.workingHours.reset();
    },
    // eslint-disable-next-line consistent-return
    updateFormState(name, value) {
      this.copyOfRequiredValues[name] = value;
      this.validateForm();
    },
    initialFormState() {
      this.copyOfRequiredValues = {
        name: this.venue.name,
        deliveryAddress: (this.venue.shippingAddress || {}).street,
        country: (this.venue.shippingAddress || {}).country,
        city: (this.venue.shippingAddress || {}).city,
        ...!this.billingAddressEnabled ? {
          billingAddress: (this.venue.billingAddress || {}).street,
          billingCity: (this.venue.billingAddress || {}).city,
          billingCountry: (this.venue.billingAddress || {}).country,
        } : {},
      };
    },
    validateForm() {
      this.formValid = true;
      Object.keys(this.copyOfRequiredFields).forEach((key) => {
        if (falsy(this.copyOfRequiredValues[key])) this.formValid = false;
      });
    },
    submitForm() {
      this.$refs.venueForm.onSubmit();
    },
    onSubmitSuccess(response) {
      this.$emit('submitSuccess', response);
      if (!this.venue.id || this.venue.id === -1) this.resetForm();
    },
    onSubmitError(response) {
      this.$emit('submitError', response);
    },
  },
  mounted() {
    this.billingAddressEnabled = !this.isBillingChecked;
    if (this.venue.shippingAddress) {
      this.initialFormState();
      this.copyOfRequiredFields = this.copyOfRequiredValues;
      this.validateForm();
    } else {
      this.copyOfRequiredFields = this.requiredValues;
      this.initialFormState();
      this.validateForm();
    }
  },
};
</script>
<template>
  <ez-form
    :formKey="formKey"
    :action="action"
    :method="method"
    :submitType="submitType"
    :additionalData="additionalData"
    :additionalHeaders="additionalHeaders"
    @success="onSubmitSuccess"
    @error="onSubmitError"
    ref="venueForm"
    class="venue-form"
  >

    <slot name="prepend"/>

    <ez-input
      :disabled="disabled"
      :formKey="formKey"
      :value="venue.name"
      :placeholder="`${$t('global.venue')} Name`"
      name="name"
      @onChange="updateFormState('name', $event)"
      :label="`${$t('global.venue')} name*`"
    />

    <ez-input
      :disabled="disabled"
      :formKey="formKey"
      :value="venue.legalName"
      name="legalName"
      label="Legal Business Name"
      placeholder="Enter Your Legal Business Name"
      class="mt-12"
    />

    <ez-input
      :disabled="disabled"
      :formKey="formKey"
      :value="venue.companyRegistrationNumber"
      name="companyRegistrationNumber"
      label="Company Registration Number / Tax ID"
      placeholder="Enter Your Company Registration Number / Tax ID"
      class="mt-12"
    />

    <ez-input
      :disabled="disabled"
      :formKey="formKey"
      :value="venue.customInfo"
      name="customInfo"
      label="Additional Info"
      placeholder="Additional Info"
      class="mt-12"
    />

    <hr  class="name-separator">

    <ez-address-form
      :disabled="disabled"
      ref="deliveryAddress"
      :formKey="formKey"
      :fieldValidators="requiredAddressFields"
      @onChange="updateFormState('deliveryAddress', $event)"
      @city="updateFormState('city', $event)"
      @country="updateFormState('country', $event)"
      :address="venue.shippingAddress || {}"
      name="shippingAddress"
    >
      <template #legend>Delivery Address</template>
    </ez-address-form>

    <ez-checkbox
      :disabled="disabled"
      :formKey="formKey"
      :checked="billingAddressEnabled"
      class="billing-checkbox"
      name="notification"
      label="Billing Address same as Delivery Address"
      value="BILLING_ADDRESS"
      @change="value => billingAddressEnabled = value" />

    <ez-address-form
      :disabled="disabled"
      ref="billingAddress"
      v-if="!billingAddressEnabled"
      :formKey="formKey"
      :address="venue.billingAddress || {}"
      :fieldValidators="requiredAddressFields"
      @onChange="updateFormState('billingAddress', $event)"
      @city="updateFormState('billingCity', $event)"
      @country="updateFormState('billingCountry', $event)"
      class="billing-address-form"
      name="billingAddress"
    >
      <template #legend>Billing Address</template>
    </ez-address-form>

    <hr class="address-separator">

    <h4>Working hours</h4>
    <ez-working-hours
      :disabled="disabled"
      ref="workingHours"
      :form-key="formKey"
      name-from="workingHours[from]"
      name-to="workingHours[to]"
      :value-from="workingHours.from || ''"
      :value-to="workingHours.to || ''"
    />
    <ez-input
      :disabled="disabled"
      :formKey="formKey"
      placeholder="Instructions"
      name="instruction"
      :value="venue.instruction"
      label="Delivery Instructions"
      class="instruction-input"
    />
    <template #buttons>
      <slot name="buttons"></slot>
    </template>
  </ez-form>
</template>
<style lang="scss" scoped>
  .venue-form {
    max-width: 26rem;
    .ez-image-upload {
      margin-bottom: 1rem;
    }
    > .input-group + .input-group {
      margin-top: .75rem;
    }
    .ez-contact {
      margin-top: .75rem;
    }
    .address-separator,
    .name-separator {
      border: 0;
      border-top: 1px dashed #DEE1E4;
      margin: 1.25rem 0;
    }
    .billing-checkbox {
      margin-top: 1rem;
      :deep() .input-group__label {
        color: #6C7995;
      }
    }
    .billing-address-form {
      margin-top: 1.5rem;
    }
    h4 {
      @include font-size(12px);
      color: #6C7995;
    }
    .instruction-input {
      margin-top: 20px;
    }
  }
</style>
