<template>
  <v-select-search
    v-if="productGroupsData.length"
    ref="select"
    class="input-group product-group-filter"
    :name="name"
    :label="label"
    :selected="selected"
    :data="productGroupsData"
    :placeholder="`All Product Groups`"
    :searchPlaceholder="`Select Product Group`"
    :isFullWidth="isFullWidth"
    align-left
    :data-cy="dataCy"
    @selected="onChange"
  />
</template>

<script>
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';

export default {
  name: 'EzProductGroupFilter',
  components: {
    VSelectSearch,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    productGroups: {
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: [Number, String],
      required: false,
    },
    label: {
      type: String,
    },
    firstItemText: {
      type: String,
      required: false,
      default: 'All Product Groups',
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    productGroupsData() {
      return this.productGroups || [];
    },
  },
  methods: {
    onChange(value) {
      this.$emit('change', value);
    },
    reset() {
      this.$refs.select.reset();
    },
  },
};
</script>

<style scoped lang="scss">
.select-search {
  color: $color-gray-6C;
}
:deep() .select-search__wrapper {
  height: 2.25rem;

  .select-search__dropdown {
    color: $color-gray-25;
    line-height: 2.25rem;
  }

  .select-search__item.clear {
    .clear-btn.default-list {
      height: 31px;
    }
  }
  .select-search__list {
    max-height: 244px;
  }
}
</style>
