<template>
  <div class="shopping-cart__note">
    <ez-button
      v-if="!showTextarea"
      type="secondary"
      @click="openTextarea"
      :data-cy="`${outletCy.SHOPPING_CART.BUTTON__SUPPLIER_LEAVE_NOTE}-${record.distributor.id}`"
    >
      <font-awesome-icon icon="comment"/> Leave a Note
    </ez-button>
    <div v-if="showTextarea">
     <div class="shopping-cart__note-header">
       <label :for="`message-${record.distributor.id}`">Note</label>
       <button
        type="button"
        @click="removeMessage"
        :data-cy="`${outletCy.SHOPPING_CART.BUTTON__SUPPLIER_REMOVE_NOTE}-${record.distributor.id}`"
      >
         <font-awesome-icon icon="times"/> Remove
       </button>
     </div>
      <textarea
        ref="textarea"
        :placeholder="'Can we have these delivered by the end of the month? Thanks!'"
        name=""
        :id="`message-${record.distributor.id}`"
        @input="onMessageUpdate($event, record)"
        :value="record.message"
        rows="2"
        :data-cy="`${outletCy.SHOPPING_CART.TEXTAREA_SUPPLIER_NOTE}-${record.distributor.id}`"
      />
    </div>
  </div>
</template>

<script>/**
 * ShoppingCartNote
 * @version 1.0.0
 * @since 2.0.0
 */
import { mapMutations } from 'vuex';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import EzButton from '@/components/ui/Button';

export default {
  components: {
    EzButton,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showTextarea: false,
      outletCy,
    };
  },
  methods: {
    ...mapMutations('cart', [
      'UPDATE_DISTRIBUTOR_MESSAGE',
    ]),
    removeMessage() {
      this.UPDATE_DISTRIBUTOR_MESSAGE({ distributorId: this.record.distributor.id, message: '' });
    },
    onMessageUpdate({ target: { value } }, { distributor: { id } }) {
      this.UPDATE_DISTRIBUTOR_MESSAGE({ distributorId: id, message: value });
    },
    openTextarea() {
      this.showTextarea = true;
      this.$nextTick(() => this.$refs.textarea.focus());
    },
  },
  watch: {
    record: {
      handler(val) {
        this.showTextarea = !!val.message;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.shopping-cart__note {
  flex: 1 1 70%;
  max-width: 70%;

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: px-to-rem(6px);

    label,
    button { @extend %gray-label; }
  }

  textarea {
    @include font-size(14px);
    resize: none;
    width: 100%;
    border: 1px solid $color-gray-E9;
    border-radius: .1875rem;
    padding: 1em;
  }
}
</style>
