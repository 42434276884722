<script>
import EzButton from '@/components/ui/Button/EzButton';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'index',
  components: {
    EzButton,
  },
  props: {
    navigateTo: {
      type: String,
      required: false,
      default: `${process.env.VUE_APP_UPGRADE_LINK}`,
    },
  },
  computed: {
    hasBackgroundImage() {
      return !!this.$slots.background;
    },
  },
  methods: {
    upgradeToPremium() {
      window.open(this.navigateTo, '_blank');
    },
  },
};
</script>

<template>
  <div class="background-parent">
    <div class="bcg-img" v-if="hasBackgroundImage">
      <slot name="background"></slot>
    </div>
    <div class="upgrade-to-premium">
      <img src="@/assets/upgrade-to-premium.svg" alt="">
      <h1><slot name="title"></slot></h1>
      <p><slot name="description"></slot></p>
      <ez-button class="mt-16" @click="upgradeToPremium">
        <slot name="cta-copy">Upgrade to Premium</slot>
      </ez-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.background-parent {
  display: grid;
  grid-template: 1fr / 1fr;
  place-items: center;
  width: 100%;
  margin-top: 24px;
  & > * {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
  }
  .bcg-img {
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .upgrade-to-premium {
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: auto;
    h1 {
      @include font-size(18px, 24px);
      margin: 24px 0 8px 0;
      max-width: 400px;
    }
    p {
      margin: 0;
      color: $color-gray-6C;
      @include font-size(14px, 20px);
      max-width: 400px;
    }
  }
}
</style>
