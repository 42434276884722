<template>
  <div class="standing-orders">
    <div class="top-space" v-if="hasOrders">
      <ez-table
        :headers="headers"
        :columns="['distributor', 'scheduledAt', 'interval']"
        :columnProps="{
              scheduledAt: { class: 'date-time-cell' },
            }"
        :data="orders"
        @rowClick="openDrawerAndLoadOrder">

        <template #cell-distributor="{ row: { distributor } }">
          <v-distributor-entity-info v-if="distributor" :distributor="distributor" />
        </template>
        <template #cell-scheduledAt="{ row }">
          {{ row.scheduledAt | dateTime }}
        </template>
        <template #cell-interval="{ row }">
          {{ row.interval }} {{ 'day' | pluralize(row.interval)}}
        </template>
      </ez-table>

      <ez-load-more v-if="meta.nextId && !isLoadingMore" @loadMore="onLoadMoreClick"/>

      <!-- Drawer -->

      <ez-drawer ref="orderDrawer" class="order-drawer" @onClose="resetDrawerRoute">
        <template #title>
          <h2>Order Request</h2>
        </template>
        <template #info-message v-if="checkIsMpOrToBeDetermined && hasMpInOrder">
          <div class="mx-24 mb-16">
            <ez-alert variant="disclaimer" class="m-0" size="big">
              <template #icon>
                <font-awesome-icon icon="exclamation-circle" />
              </template>
              <p v-if="hasMpInOrder && hasTbdInOrder">
                This order contains products with market prices and fractional units which may affect
                the total amount (incl. subtotal and taxes) based on the actual delivered quantity.
              </p>
              <p v-if="hasMpInOrder && !hasTbdInOrder">
                Product with market price is included in this order. Market prices affect the total
                amount, subtotal and taxes.
              </p>
            </ez-alert>
          </div>
        </template>
        <template #content>

          <order-container>
            <template #distributor>
              <ez-entity-info
                v-if="selectedOrder.distributor"
                :imgUrl="selectedOrder.distributor.logo"
                imgBorderRadius="50%">
                <span :data-cy="commonCy.SINGLE_ORDER.STANDING_ORDER.TEXT__SUPPLIER_NAME">
                  {{ selectedOrder.distributor.name }}
                </span>
                <ez-button
                  type="secondary"
                  formType="button"
                  @click="showDistributorInfo"
                  :data-cy="commonCy.SINGLE_ORDER.STANDING_ORDER.BUTTON__SUPPLIER_INFO"
                >
                  <font-awesome-icon icon="info-circle"/>
                  <span>{{ $t('global.distributor') }} Info</span>
                </ez-button>
              </ez-entity-info>
            </template>

            <template #orderCreated>
              <span :data-cy="commonCy.SINGLE_ORDER.STANDING_ORDER.TEXT__CREATED_DATE">
                {{ selectedOrder.createdAt | date }}
              </span>
            </template>
            <template #repeat>
              <span :data-cy="commonCy.SINGLE_ORDER.STANDING_ORDER.TEXT__INTERVAL">
                {{ selectedOrder.interval }} {{ 'day' | pluralize(selectedOrder.interval)}}
              </span>
            </template>
            <template #nextOrder>
              <span :data-cy="commonCy.SINGLE_ORDER.STANDING_ORDER.TEXT__NEXT_DATE">
                {{ selectedOrder.scheduledAt | date }}
              </span>
            </template>

            <template>
              <component
                :is="orderTabs"
                :order="selectedOrder"
                :enableEdit="false"></component>
            </template>
          </order-container>
        </template>
        <template #footer>
          <ez-button
            formType="button"
            type="red"
            @click="openStopOrderModal"
            :data-cy="commonCy.SINGLE_ORDER.MANAGE_ORDER.BUTTON__STOP_ORDER"
          >
            Stop Order
          </ez-button>
          <ez-simple-dropdown
            placeholder="Manage Order"
            direction="up"
            class="manage-standing-order"
          >
            <template #dropdown>
              <div class="dropdown-actions">
                <div class="dropdown-actions__label">ORDER STATUS</div>
                <component
                  @actionClicked="openReorder"
                  :is="actionsComponent"
                  type="link"
                  :data-cy="commonCy.SINGLE_ORDER.MANAGE_ORDER.BUTTON__REORDER"
                >
                  Reorder
                </component>
                <div class="dropdown-actions__label">ORDER DETAILS</div>
                <component
                  @actionClicked="openEditIntervalModal"
                  :is="actionsComponent"
                  type="link"
                  :data-cy="commonCy.SINGLE_ORDER.MANAGE_ORDER.BUTTON__EDIT_INTERVAL"
                >
                  Edit Interval
                </component>
              </div>
            </template>
          </ez-simple-dropdown>

          <div class="order-total-price">
            <v-subtotal-info
              :item-list="itemList"
              :delivery-fee="deliveryFee"
              :tax="tax"
              :is-tbd="isSomeTbd"
            />
          </div>
        </template>
      </ez-drawer>
      <div v-if="isLoadingMore" class="u-text-center mt-12">
        <ez-spinner/>
      </div>

      <reorder @success="closeOrderDrawer" :order="selectedOrder" ref="reorder" />

      <!-- Distributor info modal -->
      <ez-info-modal
        ref="infoModal"
        v-if="selectedOrder.distributor"
        :data="selectedOrder.distributor">
        <template #title>{{ $t('global.distributor') }} Info</template>
      </ez-info-modal>

      <ez-confirmation-modal ref="stopOrderModal" type="red">
        <template #title>Stop the Order?</template>
        <template #content>
          <p>If you stop the order you will no longer receive this order in regular intervals.
            If you already have this order in your Order History,
            you can still reorder it from there.</p>
        </template>
        <template #footer>
          <ez-button
            @click="closeStopOrderModal"
            type="link"
            :data-cy="commonCy.SINGLE_ORDER.STANDING_ORDER.STOP_ORDER_MODAL.BUTTON__CANCEL"
          >
            Cancel
          </ez-button>
          <ez-button
            @click="confirmStopOrder"
            type="red"
            :is-loading="isLoadingStop"
            :data-cy="commonCy.SINGLE_ORDER.STANDING_ORDER.STOP_ORDER_MODAL.BUTTON__STOP"
          >
            Stop Order
          </ez-button>
        </template>
      </ez-confirmation-modal>

      <ez-form-modal ref="editIntervalModal">
        <template #title>Edit Interval</template>
        <template #content>
          <ez-form
            :action="`/venues/${contextId}/future-orders/${orderId}`"
            method="patch"
            :additional-headers="{ loadingKey: updateIntervalKey }"
            :formKey="updateIntervalKey"
            @success="onIntervalUpdate"
            ref="updateIntervalForm"
          >
            <ez-input
              :formKey="updateIntervalKey"
              name="interval"
              label="Number of days between two orders"
              placeholder="Enter Number of Days Between Two Orders"
              :value="selectedOrder.interval"
              :data-cy="commonCy.SINGLE_ORDER.STANDING_ORDER.EDIT_INTERVAL_MODAL.INPUT__INTERVAL"
            />
          </ez-form>
        </template>
        <template #footer>
          <ez-button
            type="link"
            @click="closeEditIntervalModal"
            :data-cy="commonCy.SINGLE_ORDER.STANDING_ORDER.EDIT_INTERVAL_MODAL.BUTTON__CANCEL"
          >
            Cancel
          </ez-button>
          <ez-button
            formType="button"
            @click="updateInterval"
            :is-loading="isLoadingButton"
            :data-cy="commonCy.SINGLE_ORDER.STANDING_ORDER.EDIT_INTERVAL_MODAL.BUTTON__SAVE"
          >
            Save Changes
          </ez-button>
        </template>
      </ez-form-modal>
    </div>
    <div class="top-space" v-else-if="!isLoading">
      <empty-state>
        <template #badge>
          <img src="@/assets/no-standing-scheduled-state.svg" alt="" />
        </template>

        <template #title>No standing orders</template>
        <template #info>
          Standing orders will appear here once they are created.
        </template>
      </empty-state>
    </div>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import flash from '@/components/ui/FlashMessage';
import VDistributorEntityInfo from '@/components/v3/patterns/VDistributorEntityInfo';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzTable from '@/components/ui/Table';
import EzLoadMore from '@/components/ui/LoadMore';
import EmptyState from '@/views/common/empty-state';
import StatusBadge from '@/views/common/status-badge';
import EzButton, { EzButtonGroup } from '@/components/ui/Button';
import EzDrawer from '@/components/ui/Drawer';
import EzInput from '@/components/ui/Input';
import { SingleOrder } from '@/views/common/orders';
import { EzInfoModal, EzConfirmationModal, EzFormModal } from '@/components/ui/Modal';
import { DefaultTabs } from '@/views/common/orders/tabs';
import OrderContainer from '@/views/common/orders/OrderContainer.vue';
import EzForm from '@/components/ui/Form';

import EzLoader from '@/components/ui/Loader/EzLoader';
import EzSpinner from '@/components/ui/Spinner';
import { LOADING_KEY } from '@/util/constants';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import VSubtotalInfo from '@/components/v3/patterns/VSubtotalInfo';
import EzAlert from '@/components/ui/Alert/EzAlert';
import { isDeclined } from '@/util/utils';
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown';
import ButtonAction from '@/views/platform/distributor/crm/ButtonAction';
import Reorder from '@/views/platform/venue/orders/actions/Reorder.vue';

export default {
  components: {
    VSubtotalInfo,
    EzLoader,
    EzForm,
    VDistributorEntityInfo,
    EzEntityInfo,
    EzLoadMore,
    EzTable,
    EmptyState,
    EzButton,
    EzInfoModal,
    EzDrawer,
    EzInput,
    SingleOrder,
    StatusBadge,
    OrderContainer,
    EzConfirmationModal,
    EzFormModal,
    EzSpinner,
    EzAlert,
    EzButtonGroup,
    EzSimpleDropdown,
    Reorder,
  },
  props: {
    orderId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      headers: {
        distributor: () => this.$t('global.distributor'),
        scheduledAt: () => 'Next Order Date',
        interval: () => 'Repeat every',
      },
      updateIntervalKey: LOADING_KEY.UPDATE_INTERVAL,
      orders: [],
      meta: {},
      selectedOrder: {},
      commonCy,
    };
  },
  computed: {
    actionsComponent() {
      return ButtonAction;
    },
    ...mapGetters('loading', ['getLoading']),
    ...mapState('entities/users', ['context', 'contextId']),
    isLoading() {
      return this.getLoading(LOADING_KEY.FETCH_ORDERS)
          || this.getLoading(LOADING_KEY.FETCH_ORDER);
    },
    isLoadingStop() {
      return this.getLoading(LOADING_KEY.CANCEL_ORDER);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.FETCH_MORE_ORDERS);
    },
    isLoadingButton() {
      return this.getLoading(LOADING_KEY.UPDATE_INTERVAL);
    },
    hasOrders() {
      return this.orders.length;
    },
    orderTabs() {
      return DefaultTabs;
    },
    // Props for VSubtotalInfo - Start
    itemList() {
      return this.selectedOrder.orderedProducts;
    },
    deliveryFee() {
      return this.selectedOrder.deliveryFee;
    },
    tax() {
      return this.selectedOrder.tax;
    },
    isSomeTbd() {
      return (this.selectedOrder.orderedProducts || []).filter(pr => !isDeclined(pr))
        .some(pr => pr.marketPrice || (!pr.priceQuantity && pr.price > 0));
    },
    // Props for VSubtotalInfo - End
    checkIsMpOrToBeDetermined() {
      return this.selectedOrder?.orderedProducts?.some(pr => (pr.variableAmount || pr.marketPrice));
    },
    hasMpInOrder() {
      const products = this.selectedOrder?.orderedProducts;
      return products?.some(pr => pr.marketPrice);
    },
    hasTbdInOrder() {
      const products = this.selectedOrder?.orderedProducts;
      return products?.some(pr => pr.variableAmount);
    },
  },
  methods: {
    ...mapActions('entities/orders', [
      'fetchVenueOrder',
      'fetchVenueOrders',
      'cancelFutureOrder',
    ]),
    ...mapMutations('entities/orders', ['SET_SELECTED_SINGLE_ORDER']),
    onRowClick($event) {
      this.$emit('rowClick', $event);
    },
    async onLoadMoreClick() {
      await this.fetchOrders(LOADING_KEY.FETCH_MORE_ORDERS);
      this.$emit('loadMore');
    },

    // METHODS: Drawer
    openOrderDrawer() {
      this.$refs.orderDrawer.open();
    },
    closeOrderDrawer() {
      this.$refs.orderDrawer.close();
    },
    resetDrawerRoute() {
      this.$router.replace({ name: 'venue-orders-standing' });
    },
    openReorder() {
      this.$refs.reorder.open();
    },
    async openDrawerAndLoadOrder({ id }) {
      try {
        const { data } = await this.fetchVenueOrder({ id, futureOrder: true });
        this.selectedOrder = data.data;

        this.$router.push({ name: 'venue-orders-standing', params: { id } });
        this.openOrderDrawer();
      } catch (e) {
        this.resetDrawerRoute();
      }
    },
    async loadDrawerOrder({ id }) {
      try {
        const { data } = await this.fetchVenueOrder({ id, futureOrder: true });
        this.selectedOrder = data.data;
        this.openOrderDrawer();
      } catch (e) {
        this.resetDrawerRoute();
      }
    },
    showDistributorInfo() {
      this.$refs.infoModal.open();
    },
    closeStopOrderModal() {
      this.$refs.stopOrderModal.close();
    },
    openStopOrderModal() {
      this.$refs.stopOrderModal.open();
    },
    openEditIntervalModal() {
      this.$refs.editIntervalModal.open();
    },
    closeEditIntervalModal() {
      this.$refs.editIntervalModal.close();
    },
    async confirmStopOrder() {
      await this.cancelFutureOrder({ id: this.orderId });

      this.orders = [];
      this.meta = {};
      await this.fetchOrders();

      this.closeStopOrderModal();
      this.closeOrderDrawer();

      flash.success({ title: 'Standing order has been successfully stopped' });
    },
    async onIntervalUpdate() {
      this.orders = [];
      this.meta = {};
      await this.fetchOrders();

      this.closeEditIntervalModal();
      flash.success({ title: 'Order interval successfully updated!' });
    },
    updateInterval() {
      this.$refs.updateIntervalForm.onSubmit();
    },
    async fetchOrders(loadingKey = LOADING_KEY.FETCH_ORDERS) {
      const { data, meta } = await this.fetchVenueOrders({
        futureOrder: true,
        orderType: 'standing',
        loadingKey,
        ...(this.meta?.nextId && { nextId: this.meta.nextId }),
        ...(this.meta?.nextValue && { nextValue: this.meta.nextValue }),
      });
      this.orders = [...this.orders, ...data];
      this.meta = meta;
    },
  },
  watch: {
    selectedOrder(value) {
      this.SET_SELECTED_SINGLE_ORDER(value);
    },
  },
  beforeMount() {
    this.$emit('updateFilter', {});
  },
  async created() {
    await this.fetchOrders();
    if (this.orderId) await this.loadDrawerOrder({ id: this.orderId });
  },
};
</script>

<style scoped lang="scss">
:deep() .loader {
  @extend %whole-screen;
}

.standing-orders {
  position: relative;
  min-height: $loading-min-height;
}
  :deep() .ez-empty-state {
    img {
      width: 160px;
      height: 108px;
    }
  }

:deep() .manage-standing-order {
  display: inline-block;
  width: auto;
  min-width: 140px;
  margin-left: 1rem;

  .dropdown-actions {
    &__btn {
      padding: 0 0 0 8px;
      width: 100%;
      text-align: left;
      font-weight: 400;
      color: $color-gray-25;
    }

    &__label {
      @include font-size(11px, 14px);
      letter-spacing: .15px;
      color: $color-gray-6C;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 8px 0 0 8px;
      font-weight: 600;
    }
  }
}
</style>
