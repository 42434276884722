<script>
import { LOADING_KEY, VENUE_REPORTS_DATE_FILTER_DEFAULT } from '@/util/constants';
import { mapActions, mapState, mapGetters } from 'vuex';
import EzTable from '@/components/ui/Table';
import EzEntityInfo from '@/components/ui/EntityInfo';
import VDistributorEntityInfo from '@/components/v3/patterns/VDistributorEntityInfo';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import VPrice from '@/components/v3/elements/VPrice';
import EzFilterList from '@/components/ui/FilterList';
import VFilterDropdown from '@/components/v3/patterns/VFilterDropdown';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index';
import EzCategoryFilter from '@/components/ui/Filter/Category.vue';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import EzInput from '@/components/ui/Input';
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown';
import EzButton from '@/components/ui/Button';
import EzProductGroupFilter from '@/components/ui/Filter/ProductGroup.vue';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import EmptyState from '@/views/common/empty-state';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzLoadMore from '@/components/ui/LoadMore';
import EzDrawer from '@/components/ui/Drawer';
import VBadge from '@/components/v3/elements/VBadge';
import VStatsBox from '@/components/v3/elements/VStatsBox';
import VStatsBoxes from '@/components/v3/elements/VStatsBoxes';
import VLabel from '@/components/v3/elements/VLabel';
import { debounce } from '@/util/utils';
import downloadAttachment from '@/util/downloadAttachment';
import dayjs from 'dayjs';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';

const FilterArea = {
  List: 'list',
  Dropdown: 'dropdown',
};

export default {
  components: {
    EzTable,
    EzEntityInfo,
    VDistributorEntityInfo,
    VDataWithInfo,
    VPrice,
    EzFilterList,
    VFilterDropdown,
    VDatePicker,
    EzCategoryFilter,
    VSelectSearch,
    EzInput,
    EzProductGroupFilter,
    EzSimpleDropdown,
    EzButton,
    EzSpinner,
    EmptyState,
    EzLoader,
    EzLoadMore,
    EzDrawer,
    VBadge,
    VLabel,
    VStatsBox,
    VStatsBoxes,
    EzCheckbox,
  },
  props: {
    reportId: {
      type: Number,
      required: false,
    },
    onlyWithChange: {
      type: Boolean,
      required: false,
      default: false,
    },
    from: {
      required: false,
      default: null,
    },
    to: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      productsPriceChanges: [],
      meta: {},
      FilterArea,
      dropdownFilters: {
        onlyWithChange: false,
        range: {
          from: dayjs().startOf('M').valueOf(),
          to: dayjs().endOf('M').valueOf(),
        },
      },
      listFilters: {
        term: null,
        range: {
          from: dayjs().startOf('M').valueOf(),
          to: dayjs().endOf('M').valueOf(),
        },
      },
      distributors: [],
      categories: [],
      productGroups: [],
      range: {
        start: dayjs().startOf('M').valueOf(),
        end: dayjs().endOf('M').valueOf(),
      },
      defaultRangeName: 'This Month',
      prevDatesLabels: {},
      selectedProduct: {},
      selectedProductOrders: [],
      selectedProductOrdersMeta: {},
      inititalFilters: true,
    };
  },
  computed: {
    ...mapState('entities/users', ['context', 'contextId', 'loggedUser']),
    ...mapGetters('loading', ['getLoading']),
    groupViewMode() {
      if (this.loggedUser.context === 'venue-group') return true;
      return false;
    },
    isLoadingMore() {
      return (
        this.getLoading(LOADING_KEY.VENUE_FETCH_MORE_REPORTS_PRICE_CHANGES) ||
        this.getLoading(LOADING_KEY.VENUE_FETCH_SINGLE_PRODUCT_REPORTS_PRICE_CHANGES) ||
        this.getLoading(LOADING_KEY.VENUE_FETCH_SINGLE_PRODUCT_REPORTS_ORDERS)
      );
    },
    isOrdersLoadingMore() {
      return this.getLoading(LOADING_KEY.VENUE_FETCH_MORE_SINGLE_PRODUCT_REPORTS_ORDERS);
    },
    isLoading() {
      return (
        this.getLoading(LOADING_KEY.VENUE_FETCH_REPORTS_PRICE_CHANGES) ||
        this.getLoading(LOADING_KEY.VENUE_FETCH_DISTRIBUTOR_FILTER_OPTIONS) ||
        this.getLoading(LOADING_KEY.FETCH_PRODUCT_GROUPS) ||
        this.getLoading(LOADING_KEY.VENUE_FETCH_CATEGORIES_FILTER)
      );
    },
    distributorFilter() {
      return this.distributors;
    },
    filters() {
      return { ...this.listFilters, ...this.dropdownFilters };
    },
    hasFilters() {
      return Object.entries(this.filters).reduce(
        (prevFilter, [, value]) => prevFilter || !!value,
        false,
      );
    },
    isNotAllTime() {
      // If default range name is 'All Time'
      return !!this.defaultRangeName && this.defaultRangeName !== 'All Time';
    },
  },
  methods: {
    resetDrawerRoute() {
      this.$router.replace({ name: 'venue-reports-invoices' });
    },
    ...mapActions('entities/venues', [
      'venueFetchReportsPriceChanges',
      'venueFetchReportsPriceChangesTotal',
      'venueFetchDistributorFilterOptions',
      'venueDownloadProductsReportCSV',
      'venueFetchSingleProductReportPriceChanges',
      'venueFetchSingleProductReportOrders',
    ]),
    ...mapActions('entities/products', ['venueFetchProductGroups']),
    ...mapActions('entities/categories', ['venueFetchCategoriesNew']),
    async fetchProductsPriceChanges(
      query = {},
      loadingKey = LOADING_KEY.VENUE_FETCH_REPORTS_PRICE_CHANGES,
    ) {
      const { data } = await this.venueFetchReportsPriceChanges({
        ...(this.meta.nextId && { nextId: this.meta.nextId }),
        ...(this.meta.nextValue && { nextValue: this.meta.nextValue }),
        ...query,
        loadingKey,
        type: 'invoice',
      });
      if (loadingKey === LOADING_KEY.VENUE_FETCH_REPORTS_PRICE_CHANGES) {
        this.productsPriceChanges = data.data;
        const { data: totalAmountData } = await this.venueFetchReportsPriceChangesTotal({
          type: 'invoice',
          ...(this.inititalFilters ? { from: dayjs().startOf('M').valueOf() } : {}),
          ...(this.inititalFilters ? { to: dayjs().endOf('M').valueOf() } : {}),
          ...query,
        });
        this.$emit('updateStats', {
          filters: this.inititalFilters,
          totalAmount: totalAmountData.data,
        });
      } else {
        this.productsPriceChanges = [...this.productsPriceChanges, ...data.data];
      }
      this.meta = data.meta;
    },
    async onLoadMoreOrdersClick() {
      const { data } = await this.venueFetchSingleProductReportOrders({
        productId: this.selectedProduct.id,
        ...this.selectedProductOrdersMeta,
        ...(this.isNotAllTime && { from: dayjs(this.dropdownFilters.range?.from).valueOf() }),
        ...(this.isNotAllTime && { to: dayjs(this.dropdownFilters.range?.to).valueOf() }),
        loadingKey: LOADING_KEY.VENUE_FETCH_MORE_SINGLE_PRODUCT_REPORTS_ORDERS,
      });

      this.selectedProductOrders = [...this.selectedProductOrders, ...data.data];
      this.selectedProductOrdersMeta = data.meta;
    },
    async onLoadMoreClick() {
      await this.fetchProductsPriceChanges(
        {
          ...this.filters,
          ...(this.isNotAllTime && { from: dayjs(this.dropdownFilters.range?.from).valueOf() }),
          ...(this.isNotAllTime && { to: dayjs(this.dropdownFilters.range?.to).valueOf() }),
        },
        LOADING_KEY.VENUE_FETCH_MORE_REPORTS_PRICE_CHANGES,
      );
    },
    async fetchDistributors() {
      const { data } = await this.venueFetchDistributorFilterOptions({
        venueId: this.contextId,
        query: { for: 'products' },
      });
      this.distributors = data.data;
    },
    async fetchCategoriesFilter() {
      const {
        data: { data },
      } = await this.venueFetchCategoriesNew();
      this.categories = data;
    },
    async fetchProductGroups() {
      const {
        data: { data },
      } = await this.venueFetchProductGroups();
      this.productGroups = [{ id: null, name: 'All Product Groups' }, ...data];
    },
    async openDrawerAndLoadProduct({ id }) {
      try {
        const [{ data: productData }, { data: ordersData }] = await Promise.all([
          this.venueFetchSingleProductReportPriceChanges({
            productId: id,
            ...(this.isNotAllTime && { from: dayjs(this.dropdownFilters.range?.from).valueOf() }),
            ...(this.isNotAllTime && { to: dayjs(this.dropdownFilters.range?.to).valueOf() }),
            type: 'invoice',
          }),
          this.venueFetchSingleProductReportOrders({
            productId: id,
            ...(this.isNotAllTime && { from: dayjs(this.dropdownFilters.range?.from).valueOf() }),
            ...(this.isNotAllTime && { to: dayjs(this.dropdownFilters.range?.to).valueOf() }),
            type: 'invoice',
          }),
        ]);
        this.selectedProduct = productData.data;
        this.selectedProductOrders = ordersData.data;
        this.selectedProductOrdersMeta = ordersData.meta;
        await this.$router.push({
          name: 'venue-reports-invoices',
          params: { id },
        });
        this.$refs.productDrawer.open();
      } catch (e) {
        this.resetDrawerRoute();
        console.error(e);
      }
    },
    async loadDrawerProduct({ id }) {
      try {
        const [{ data: productData }, { data: ordersData }] = await Promise.all([
          this.venueFetchSingleProductReportPriceChanges({
            productId: id,
            ...(this.isNotAllTime && { from: dayjs(this.dropdownFilters.range?.from).valueOf() }),
            ...(this.isNotAllTime && { to: dayjs(this.dropdownFilters.range?.to).valueOf() }),
            type: 'invoice',
          }),
          this.venueFetchSingleProductReportOrders({
            productId: id,
            ...(this.isNotAllTime && { from: dayjs(this.dropdownFilters.range?.from).valueOf() }),
            ...(this.isNotAllTime && { to: dayjs(this.dropdownFilters.range?.to).valueOf() }),
            type: 'invoice',
          }),
        ]);
        this.selectedProduct = productData.data;
        this.selectedProductOrders = ordersData.data;
        this.selectedProductOrdersMeta = ordersData.meta;
        this.$refs.productDrawer.open();
      } catch (e) {
        this.resetDrawerRoute();
        console.error(e);
      }
    },
    resetDateLabelsIfNeeded() {
      const { defaultRangeName } = this.prevDatesLabels;
      const sameOrderLabel = defaultRangeName === this.defaultRangeName;

      if (!sameOrderLabel) this.changeRangeName(defaultRangeName);
    },
    resetDateFiltersIfNeeded() {
      const { date } = this.dropdownFilters;

      if (date) {
        const sameOrderDate = date.start === this.range.start && date.end === this.range.end;
        if (!sameOrderDate) this.range = { ...date };
      }
    },
    changeRangeName(rangeName) {
      this.defaultRangeName = rangeName;
    },
    filterClosed() {
      if (!this.dropdownFilters.range?.from && !this.dropdownFilters.range?.to) {
        this.defaultRangeName = VENUE_REPORTS_DATE_FILTER_DEFAULT.name;
      }
      this.resetDateLabelsIfNeeded();
      this.resetDateFiltersIfNeeded();
    },
    updateFilters: debounce(async function deb(area, event) {
      if (area === FilterArea.List) {
        const [filterName, value] = event;
        if (filterName === 'date') {
          this.listFilters = {
            ...this.listFilters,
            ...(this.isNotAllTime
              ? {
                  range: {
                    from: dayjs(this.range?.start).valueOf(),
                    to: dayjs(this.range?.end).valueOf(),
                  },
                }
              : { range: null }),
          };
          this.dropdownFilters = {
            ...this.dropdownFilters,
            ...(this.isNotAllTime
              ? {
                  range: {
                    from: dayjs(this.range?.start).valueOf(),
                    to: dayjs(this.range?.end).valueOf(),
                  },
                }
              : { range: null }),
          };
        } else {
          this.listFilters = { ...this.listFilters, [filterName]: value };
        }
      } else if (area === FilterArea.Dropdown) {
        this.inititalFilters = false;
        this.dropdownFilters = {
          ...this.dropdownFilters,
          ...(typeof event.categoryId === 'object' &&
            event.categoryId !== null && { categoryId: event.categoryId.id }),
          ...(typeof event.distributorId === 'object' &&
            event.distributorId !== null && { distributorId: event.distributorId.id }),
          ...(typeof event.productGroupId === 'object' &&
            event.productGroupId !== null && { productGroupId: event.productGroupId.id }),
          ...(this.isNotAllTime
            ? {
                range: {
                  from: dayjs(this.range?.start).valueOf(),
                  to: dayjs(this.range?.end).valueOf(),
                },
              }
            : { range: null }),
          onlyWithChange: event.onlyWithChange,
        };
        this.listFilters = {
          ...this.listFilters,
          ...(this.isNotAllTime
            ? {
                range: {
                  from: dayjs(this.range?.start).valueOf(),
                  to: dayjs(this.range?.end).valueOf(),
                },
              }
            : { range: null }),
        };
        if (event.distributorId === undefined) delete this.dropdownFilters.distributorId;

        this.range = {
          start: dayjs(this.dropdownFilters.range?.start || this.range?.start).valueOf(),
          end: dayjs(this.dropdownFilters.range?.end || this.range?.end).valueOf(),
        };
        this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());
      }

      this.productsPriceChanges = [];
      this.meta = {
        nextId: null,
        nextValue: null,
        ...(this.listFilters.term && { term: this.listFilters.term }),
      };
      await this.fetchProductsPriceChanges({
        ...this.filters,
        ...(this.isNotAllTime && { from: dayjs(this.dropdownFilters.range?.from).valueOf() }),
        ...(this.isNotAllTime && { to: dayjs(this.dropdownFilters.range?.to).valueOf() }),
      });
    }, 300),
    async resetFilters(area) {
      if (area === FilterArea.List) {
        this.listFilters = { term: null };
        this.dropdownFilters.range = null;
        this.range = {
          start: VENUE_REPORTS_DATE_FILTER_DEFAULT.from,
          end: VENUE_REPORTS_DATE_FILTER_DEFAULT.to,
        };
        this.defaultRangeName = VENUE_REPORTS_DATE_FILTER_DEFAULT.name;
      } else if (area === FilterArea.Dropdown) {
        this.inititalFilters = true;
        this.dropdownFilters = {};
        this.listFilters = { term: null };
        this.range = {
          start: VENUE_REPORTS_DATE_FILTER_DEFAULT.from,
          end: VENUE_REPORTS_DATE_FILTER_DEFAULT.to,
        };
        this.defaultRangeName = VENUE_REPORTS_DATE_FILTER_DEFAULT.name;
      }

      this.$nextTick(() => this.$refs.filtersGroup.syncLocalFilters());

      this.productsPriceChanges = [];
      this.meta = {};
      await this.fetchProductsPriceChanges(this.filters);
    },
    async exportCSV() {
      const { data, headers } = await this.venueDownloadProductsReportCSV({
        ...this.filters,
        ...(this.isNotAllTime && { from: dayjs(this.dropdownFilters.range?.from).valueOf() }),
        ...(this.isNotAllTime && { to: dayjs(this.dropdownFilters.range?.to).valueOf() }),
        type: 'invoice',
      });
      const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
      const name = Array.isArray(filename) ? decodeURI(filename[1]) : 'template.xlsx';
      downloadAttachment(data, name);
    },
    openOrder(row) {
      if (this.groupViewMode) return;
      const routeData = this.$router.resolve({
        name: 'venue-pending-updates',
        params: {
          id: row.order.id,
        },
      });
      window.open(routeData.href, '_blank');
    },
  },
  async created() {
    if (this.onlyWithChange) {
      this.inititalFilters = false;
      this.dropdownFilters = {
        ...this.dropdownFilters,
        onlyWithChange: this.onlyWithChange,
      };
    }
    if (this.from && this.to) {
      this.dropdownFilters = {
        ...this.dropdownFilters,

        range: {
          from: this.from,
          to: this.to,
        },
      };
      this.listFilters = {
        ...this.listFilters,
        range: {
          from: this.from,
          to: this.to,
        },
      };
      this.range = {
        start: this.from,
        end: this.to,
      };
      this.defaultRangeName = `${dayjs(this.range.start).format('DD MMM')} - ${dayjs(
        this.range.end,
      ).format('DD MMM')}`;
    }
    await Promise.all([
      this.fetchProductsPriceChanges({
        ...this.filters,
        ...(this.isNotAllTime && { from: dayjs(this.dropdownFilters.range?.from).valueOf() }),
        ...(this.isNotAllTime && { to: dayjs(this.dropdownFilters.range?.to).valueOf() }),
      }),
      this.fetchDistributors(),
      this.fetchCategoriesFilter(),
      this.fetchProductGroups(),
    ]);
    if (this.reportId) {
      await this.loadDrawerProduct({ id: this.reportId });
    }
  },
  watch: {
    filters: {
      immediate: true,
      handler() {
        this.prevDatesLabels = {
          defaultRangeName: this.defaultRangeName,
        };
      },
    },
  },
};
</script>

<template>
  <div>
    <div class="all-products__actions mt-24">
      <ez-filter-list
        :filters="listFilters"
        @filterUpdated="(...$event) => updateFilters(FilterArea.List, $event)"
        @resetFilter="resetFilters(FilterArea.List)"
        class="mr-16"
      >
        <ez-input
          formKey="filters"
          label="search"
          name="term"
          class="search"
          placeholder="Search for a Product"
        >
          <template #prefix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
        <v-date-picker
          class="calendar ml-16"
          name="date"
          label="Date"
          v-model="range"
          hide-label
          formKey="filters"
          range-mode
          with-predefined-ranges
          :number-of-calendars="2"
          :select-predefined-range="defaultRangeName"
          @rangeNameChange="changeRangeName"
          displayEndOfRange
        />
      </ez-filter-list>
      <v-filter-dropdown
        :filters="dropdownFilters"
        @filterUpdated="$event => updateFilters(FilterArea.Dropdown, $event)"
        @resetFilters="resetFilters(FilterArea.Dropdown)"
        @filterClosed="filterClosed"
        ref="filtersGroup"
      >
        <template #firstRow>
          <ez-category-filter
            name="categoryId"
            label="Category"
            :categories="categories"
            :selected="dropdownFilters.categoryId"
            isFullWidth
          />
          <ez-product-group-filter
            name="productGroupId"
            label="Product Group"
            :productGroups="productGroups"
            :selected="dropdownFilters.productGroupId"
            isFullWidth
          />
          <v-select-search
            class="select-supplier"
            :selected="dropdownFilters.distributorId"
            formKey="filters"
            name="distributorId"
            :label="`${$t('global.distributor')}`"
            :data="distributorFilter"
            :placeholder="`All ${$t('global.distributors')}`"
            :searchPlaceholder="`Select ${$t('global.distributor')}`"
            align-left
            is-full-width
          >
            <template #result="{ result }">
              <v-distributor-entity-info :distributor="result" />
            </template>
          </v-select-search>
          <v-date-picker
            class="calendar"
            name="date"
            label="Date"
            v-model="range"
            formKey="filters"
            range-mode
            with-predefined-ranges
            :number-of-calendars="2"
            :select-predefined-range="defaultRangeName"
            @rangeNameChange="changeRangeName"
            displayEndOfRange
            is-full-width
          />
        </template>
        <template #secondRow>
          <ez-checkbox
            class="status-checkbox mt-12"
            :checked="dropdownFilters.onlyWithChange"
            key="onlyWithChange"
            formKey="filters"
            name="onlyWithChange"
            label="Price change"
          />
        </template>
      </v-filter-dropdown>
      <ez-simple-dropdown
        v-if="productsPriceChanges.length"
        class="export-dropdown"
        placeholder="Export"
        :expandOnClick="true"
        buttonType="secondary"
      >
        <template>
          <span>Export</span>
        </template>
        <template #dropdown>
          <ez-button type="link" formType="button" customClass="upload-csv" @click="exportCSV">
            Export CSV
          </ez-button>
        </template>
      </ez-simple-dropdown>
    </div>
    <ez-table
      class="mt-24"
      v-if="productsPriceChanges.length && !isLoading"
      :headers="{
        name: () => 'Product',
        distributor: () => `${$t('global.distributor').toLowerCase()}`,
        orderingUnit: () => 'Unit',
        reportQuantity: () => 'Quantity',
        newPrice: () => 'Most Recent Price',
        reportAmount: () => 'Subtotal',
        priceChange: () => 'Price Change',
      }"
      :columns="[
        'name',
        'distributor',
        'unit',
        'quantity',
        'averagePrice',
        'newPrice',
        'reportAmount',
        'priceChange',
      ]"
      :columnProps="{
        priceChange: { class: 'price-cell price-change-cell' },
        newPrice: { class: 'price-cell' },
        reportAmount: { class: 'price-cell' },
        averagePrice: { class: 'price-cell' },
        quantity: { class: 'quantity-small-cell' },
        unit: { class: 'small-cell' },
      }"
      :data="productsPriceChanges"
      @rowClick="openDrawerAndLoadProduct"
    >
      <template #cell-name="{ row }">
        <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="row.image">
          <div class="product-info" :title="row.name">
            <span>{{ row.name }}</span>
            <span class="product-info-secondary">
              <span class="product-info-secondary--category">
                {{ row.productGroup?.name ?? 'Unassigned' }}
              </span>
            </span>
          </div>
        </ez-entity-info>
      </template>
      <template #cell-distributor="{ row: { distributor } }">
        <v-distributor-entity-info :distributor="distributor" />
      </template>
      <template #cell-unit="{ row: { orderingUnit } }">
        <template v-if="orderingUnit">
          <v-data-with-info
            :info="
              orderingUnit.abbreviation
                ? `${orderingUnit.name} ${!!$t(`product.unitInfo.${orderingUnit.type}`) ? '-' : ''}
            ${$t(`product.unitInfo.${orderingUnit.type}`)}`
                : ''
            "
            :show-underline="
              !!$t(`product.unitInfo.${orderingUnit.type}`) || !!orderingUnit.abbreviation
            "
          >
            {{ orderingUnit.label }}
          </v-data-with-info>
        </template>
      </template>
      <template #cell-quantity="{ row: { reportQuantity } }">
        {{ reportQuantity }}
      </template>
      <template #cell-averagePrice="{ row: { averagePrice } }">
        <v-price :price="averagePrice || 0" />
      </template>
      <template #cell-newPrice="{ row: { newPrice } }">
        <v-price :price="newPrice || 0" />
      </template>
      <template #cell-reportAmount="{ row: { reportAmount } }">
        <v-price :price="reportAmount || 0" />
      </template>
      <template #cell-priceChange="{ row: { priceChangeAmount, priceChangePercent } }">
        <div class="price-change__amount">
          <span v-if="priceChangeAmount > 0" class="price-red">+</span>
          <v-price
            :class="{ 'price-green': priceChangeAmount < 0, 'price-red': priceChangeAmount > 0 }"
            :price="priceChangeAmount"
          />
        </div>
        <span class="percent">{{ priceChangeAmount > 0 ? '+' : '' }}{{ priceChangePercent }}%</span>
      </template>
    </ez-table>
    <template v-if="!productsPriceChanges.length">
      <empty-state class="empty-state-center" v-if="hasFilters">
        <template #badge><img src="@/assets/no-products-search-state.svg" alt="" /></template>
        <template #title>No products match this search</template>
        <template #info>Try with a different search.</template>
      </empty-state>
      <empty-state class="empty-state-center" v-else>
        <template #badge><img src="@/assets/no-product-empty-state.svg" alt="" /></template>
        <template #title>No products listed</template>
        <!-- eslint-disable-next-line -->
      </empty-state>
    </template>
    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>
    <ez-loader :show="isLoading">Loading...</ez-loader>
    <ez-load-more v-if="meta.nextId && !isLoadingMore" @loadMore="onLoadMoreClick"></ez-load-more>
    <ez-drawer ref="productDrawer" @onClose="resetDrawerRoute">
      <template #title>
        <v-badge>{{ selectedProduct.productGroup?.name || 'Unassigned' }}</v-badge>
      </template>
      <template #content>
        <div class="item-drawer__container">
          <header class="item-drawer__header pb-16 ml-24 mr-24">
            <div>
              <h2 class="product-title drawer-header__text--ellipses" :title="selectedProduct.name">
                {{ selectedProduct.name }}
              </h2>
              <div class="product-price mb-16">
                <v-price
                  flex-row
                  :price="selectedProduct.price || 0"
                  :unit="selectedProduct.orderingUnit.label"
                />
              </div>
            </div>
            <div>
              <img
                :src="selectedProduct.image"
                class="product-image"
                :alt="`${selectedProduct.name} product image`"
              />
            </div>
          </header>
          <div class="contact-info__item" v-if="selectedProduct.sku">
            <v-label as="small">SKU Number</v-label>
            <div
              class="contact-info__value drawer-header__text--ellipses"
              :title="selectedProduct.sku"
            >
              {{ selectedProduct.sku }}
            </div>
          </div>

          <v-label as="small" class="stats-label mt-16 mb-8 ml-24 mr-24"
            >Stats for {{ defaultRangeName }}</v-label
          >
          <v-stats-boxes class="mb-16 ml-24 mr-24 stats-boxes--large">
            <v-stats-box>
              <template #label>Units Bought</template>
              <template #value>{{ selectedProduct.reportQuantity | formatNumber }}</template>
            </v-stats-box>
            <v-stats-box>
              <template #label>Average Price</template>
              <template #value>{{ selectedProduct.averagePrice | price }}</template>
            </v-stats-box>
            <v-stats-box>
              <template #label>Minimum Price</template>
              <template #value>{{ selectedProduct.minPrice | price }}</template>
            </v-stats-box>
            <v-stats-box>
              <template #label>Maximum Price</template>
              <template #value>{{ selectedProduct.maxPrice | price }}</template>
            </v-stats-box>
            <v-stats-box>
              <template #label>Latest Price</template>
              <template #value>{{ selectedProduct.newPrice | price }}</template>
            </v-stats-box>
          </v-stats-boxes>

          <div class="drawer-table-container ml-24 mr-24 pb-24">
            <h3 class="table-title mb-4 drawer-header__text--ellipses">Invoice History</h3>
            <v-label as="small" class="stats-label mb-16">{{ defaultRangeName }}</v-label>
            <ez-table
              :data="selectedProductOrders"
              :columns="['orderNumber', 'orderedAt', 'quantity', 'price', 'priceChange']"
              :headers="{
                orderNumber: () => 'Invoice No.',
                orderedAt: () => 'Date',
              }"
              :columnProps="{
                priceChange: { class: 'price-cell price-change-cell' },
                price: { class: 'price-cell' },
                orderNumber: { class: 'order-number-cell' },
                orderedAt: { class: 'order-date-cell' },
                quantity: { class: 'quantity-cell quantity-small-cell' },
              }"
              @rowClick="openOrder"
            >
              <template #cell-orderNumber="{ row }">
                <div
                  class="invoice-number"
                  v-tooltip="{
                    placement: 'end',
                    content: row.order.invoiceNumber || '',
                    classes: ['tooltip-general', 'tooltip--lift-up'],
                  }"
                >
                  {{ row.order.invoiceNumber || '-' }}
                </div>
                <div
                  v-if="groupViewMode"
                  class="percent"
                  v-tooltip="{
                    placement: 'end',
                    content: row.order.venue.name,
                    classes: ['tooltip-general', 'tooltip--lift-up'],
                  }"
                >
                  {{ row.order.venue.name }}
                </div>
              </template>
              <template #cell-orderedAt="{ row }">
                <div>{{ row.order.orderedAt | date }}</div>
              </template>
              <template #cell-quantity="{ row }">
                {{ row.quantity }}
              </template>
              <template #cell-price="{ row: { price, totalPrice } }">
                <v-price :price="price" />
                <div class="percent">Total: {{ totalPrice | price }}</div>
              </template>
              <template #cell-priceChange="{ row: { priceChangeAmount, priceChangePercent } }">
                <div class="price-change__amount">
                  <span v-if="priceChangeAmount > 0" class="price-red">+</span>
                  <v-price
                    :class="{
                      'price-green': priceChangeAmount < 0,
                      'price-red': priceChangeAmount > 0,
                    }"
                    :price="priceChangeAmount"
                  />
                </div>
                <span class="percent"
                  >{{ priceChangeAmount > 0 ? '+' : '' }}{{ priceChangePercent }}%</span
                >
              </template>
            </ez-table>
            <div v-if="isOrdersLoadingMore" class="u-text-center mt-12">
              <ez-spinner />
            </div>
            <ez-load-more
              v-if="
                !isOrdersLoadingMore &&
                selectedProductOrdersMeta.nextId &&
                selectedProductOrdersMeta.nextValue
              "
              @loadMore="onLoadMoreOrdersClick"
            />
          </div>
        </div>
      </template>
    </ez-drawer>
  </div>
</template>

<style scoped lang="scss">
.all-products__actions {
  display: flex;

  :deep() .dropdown-filters {
    .input-group {
      margin: 0;
    }

    .filters-area__row:not(:first-child) {
      justify-content: start;
    }
  }

  :deep() .ez-filter-list {
    &__items {
      margin: 0;
    }
  }
}

.status-checkbox {
  @include font-size(14px, 20px);
}

.select-supplier {
  :deep() .select-search__trigger {
    width: 100%;
    color: $color-gray-6C;
  }

  :deep() .select-search__list {
    min-height: 20rem;
  }
}

.calendar {
  :deep() .date-picker {
    .ez-simple-dropdown__display-container {
      width: 100%;
    }
    .placeholder {
      color: $color-gray-6C;
    }
  }
}
.product-info {
  display: flex;
  flex-direction: column;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .product-info-secondary {
    &--category {
      color: $color-gray-25;
      font-weight: 600;
    }
  }
}

.price-change-cell {
  .price-change__amount {
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .price-green {
    :deep() .v-price__price {
      color: $color-primary-green;
    }
  }

  .price-red {
    color: $color-primary-red;
    line-height: 22px;
    :deep() .v-price__price {
      color: $color-primary-red;
    }
  }
}

.percent {
  @extend %text-overflow-ellipsis;
  color: $color-gray-6C;
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
  letter-spacing: -0.25px;
  &.has-tooltip {
    display: block;
  }
}

@media (max-width: 1023px) {
  .percent {
    margin-left: 12px;
  }
}

:deep() .export-dropdown {
  margin-left: auto;
  width: auto;
  display: inline-block;

  .ez-simple-dropdown__label {
    margin-right: 16px;
  }
  .ez-simple-dropdown__dropdown {
    width: max-content;
  }
}

.item-drawer {
  &__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #dee1e4;
  }
}

.product-image {
  @include size(120px);
  object-fit: cover;
  object-position: center;
  border: 1px solid #dee1e4;
  border-radius: 6px;
}

.product-title {
  margin: 0;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}

.product-price {
  margin-top: 8px;
  color: $color-primary-blue;
  font-size: 18px;
  letter-spacing: -0.5px;
  line-height: 24px;

  :deep() .v-price__price {
    font-size: 18px;
    letter-spacing: -0.5px;
    line-height: 24px;
    font-weight: 500;
  }

  :deep() .v-price__unit--row {
    font-weight: 400;
  }
}

.contact-info__item {
  margin-left: 24px;
  margin-right: 24px;
  padding: 16px 0px;
  border-bottom: 1px dashed #dee1e4;

  :deep() .label {
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 4px;
  }

  :deep() .contact-info__value {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
}

.stats-label {
  line-height: 18px;
}

:deep() .stats-box__value {
  font-size: 20px;
  line-height: 28px;
}

.table-title {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: -0.5px;
}

:deep() .table tbody tr td.order-number-cell {
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  color: $color-gray-25;
  .invoice-number {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    &.has-tooltip {
      display: block;
    }
  }
}

:deep() .table .quantity-small-cell {
  width: 90px;
}

:deep() .product-group-filter .ez-select__display-container {
  height: 2.25rem;
}

:deep() .empty-state-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

:deep() .calendar .date-picker .placeholder {
  min-width: 128px;
}
</style>
