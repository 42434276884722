<script>
import { pluralize } from '@/util/utils';
import { TAX_CALCULATION_OPTIONS } from '@/util/constants';

/**
 * InvitationInfo
 * @version 1.0.0
 * @since 2.3.0
 */

export default {
  props: {
    distributor: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  computed: {
    workingHours() {
      return this.distributor.workingHours || {};
    },
    deliveryPeriodCopy() {
      return `Delivery ${pluralize('Day', this.distributor.deliveryPeriodCount)}`;
    },
    taxCalculationLabel() {
      return TAX_CALCULATION_OPTIONS.find(
        option => option.value === this.distributor.taxCalculation,
      )?.name;
    },
  },
};
</script>
<template>
  <div class="info-container" v-if="distributor">
    <div class="overview-item mt-16" v-if="distributor.customInfo">
      <div class="ez-info-panel__contact">
        <div class="info info--vertical">
          <small>Additional Info</small>
          <span>{{ distributor.customInfo }} </span>
        </div>
      </div>
    </div>
    <div class="overview-item address-overview" v-if="distributor.address">
      <h3>Addresses</h3>
      <div class="ez-info-panel__contact">
        <div class="info info--vertical">
          <small>Street Address</small>
          <span>{{ distributor.address.street }} </span>
        </div>
      </div>
      <div class="ez-info-panel__contact">
        <div class="info info--vertical">
          <small>City</small>
          <span>{{ distributor.address.city }} </span>
        </div>
      </div>
      <div v-if="distributor.address.zipCode" class="ez-info-panel__contact">
        <div class="info info--vertical">
          <small>Zip Code</small>
          <span>{{ distributor.address.zipCode }} </span>
        </div>
      </div>
      <div class="ez-info-panel__contact">
        <div class="info info--vertical">
          <small>Country</small>
          <span>{{ distributor.address.country }} </span>
        </div>
      </div>
      <div v-if="distributor.address.state" class="ez-info-panel__contact">
        <div class="info info--vertical">
          <small>State</small>
          <span>{{ distributor.address.state }} </span>
        </div>
      </div>
    </div>
    <div class="overview-item">
      <h3>Tax Rate Applicable</h3>
      <div class="info info--vertical">
        <small>Tax Calculation</small>
        <div>
          <span>{{ taxCalculationLabel }}</span>
        </div>
      </div>
      <div v-if="distributor.tax" class="info info--vertical">
        <small>Tax Rate</small>
        <div>
          <span>{{ distributor.tax }}%</span>
        </div>
      </div>
    </div>
    <div v-if="workingHours.to" class="overview-item">
      <h3>Cut-Off Time</h3>
      <div class="info info--vertical">
        <small>Orders Received By</small>
        <div>
          <span>{{ workingHours.to }}</span>
        </div>
      </div>
      <div v-if="distributor.deliveryPeriodCount && deliveryPeriodCopy" class="info info--vertical">
        <small>Will Be Delivered In</small>
        <div>
          <span>{{ distributor.deliveryPeriodCount }} {{ deliveryPeriodCopy }}</span>
        </div>
      </div>
      <div class="info info--vertical">
        <small>Delivery Days</small>
        <div>
          <span>{{
            distributor.deliveryDays?.length
              ? distributor.deliveryDays.map(day => day).join(', ')
              : 'None'
          }}</span>
        </div>
      </div>
    </div>
    <div class="overview-item">
      <h3>Minimum Order Quantity</h3>
      <div class="info info--vertical">
        <small>Minimum order amount:</small>
        <div>{{ distributor.minimumOrderAmount | price }}</div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.info {
  small {
    @include font-size(12px, 14px);
    color: $label-color;
    font-weight: 600;
    margin-bottom: $spacing-08;
  }
  div {
    :not(:last-child) {
      @include font-size(14px, 16px);
      color: #2b2d3e;
      margin-bottom: $spacing-16;
    }
  }
}

.address-overview {
  .ez-info-panel__contact {
    &:not(:last-child) {
      span {
        @include font-size(14px, 16px);
        color: #2b2d3e;
        margin-bottom: $spacing-16;
      }
    }
  }
}

.address {
  @include separator('both');
}
.overview-item {
  @include separator('bottom');
}

h3 {
  @include font-size(14px, 16px);
  margin: 0 0 $spacing-16;
  padding: 0;
  color: $color-gray-25;
  font-weight: 500;
}
</style>
