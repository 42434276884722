<script>
/**
 * NewSupplierProducts
 * @version 1.0.0
 * @since 2.3.0
 */

import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import EzProductModal from '@/views/common/products/new-product-supplier/EzProductModal';
import flash from '@/components/ui/FlashMessage';
import confirmation from '@/components/v3/patterns/VConfirmation/control';
import { EzCsvUploadModal } from '@/components/ui/Modal';
import { EzButtonGroup } from '@/components/ui/Button';
import ProposePriceModal from '@/views/platform/venue/suppliers/all/ProposePriceModal';
import EzButton from '@/components/ui/Button/EzButton';
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown';
import EzTable from '@/components/ui/Table/EzTable';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import EmptyState from '@/views/common/empty-state/EmptyState';
import VStatusDropdown from '@/components/v3/patterns/VStatusDropdown/index';
import EzEntityInfo from '@/components/ui/EntityInfo';
import { falsy } from '@/util/utils';
import { LOADING_KEY } from '@/util/constants';
import VPrice from '@/components/v3/elements/VPrice';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';

export default {
  mixins: [wizardListenerMixin],
  components: {
    EzProductModal,
    EzCsvUploadModal,
    EzButtonGroup,
    ProposePriceModal,
    EzButton,
    EzSimpleDropdown,
    EzTable,
    EzLoadMore,
    EmptyState,
    VStatusDropdown,
    EzEntityInfo,
    VPrice,
    VDataWithInfo,
  },
  data() {
    return {
      headers: {
        name: () => 'Product',
        price: () => 'Price per unit',
        actions: () => '',
      },
      products: [],
      selectedProduct: {},
      meta: {},
      formKey: 'new-supplier-product',
    };
  },
  computed: {
    ...mapState('entities/distributors', ['draftSupplier']),
    ...mapState('entities/users', ['context', 'contextId']),
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.VENUE_HIDE_UNHIDE_PRODUCTS);
    },
    venueId() {
      return this.contextId;
    },
    distributorId() {
      return this.draftSupplier.id;
    },
    action() {
      return `/venue/distributors/${this.distributorId}/products`;
    },
    columns() {
      return ['name', 'visibility', 'unit', 'pricePerUnit', 'actions'];
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'venueFetchNewDistributorProduct',
      'venueRemoveNewDistributorProduct',
      'venueUploadDistributorProducts',
      'venueHideAllDistributorProduct',
      'venueUnhideAllDistributorProduct',
      'venueFetchNewDistributorProducts',
      'venueHideDistributorProduct',
      'venueUnhideDistributorProduct',
    ]),
    ...mapActions('entities/categories', ['fetchCategoriesByVenueId']),
    ...mapMutations('entities/products', [
      'NEW_DISTRIBUTOR_PRODUCTS_REMOVE',
      'NEW_DISTRIBUTOR_PRODUCTS_UPDATE',
    ]),
    onNextStep() {
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    proposePrice(product) {
      this.selectedProduct = product;
      this.$refs.modal.open();
    },
    newProduct() {
      this.selectedProduct = {};
      this.openEditModal();
    },
    closeEditModal() {
      this.$refs.productModal.close();
      this.selectedProduct = {};
    },
    openEditModal() {
      this.$refs.productModal.open();
    },
    async openSingleProduct(product) {
      const { editable, id } = product;
      if (!editable) return;
      const { data } = await this.venueFetchNewDistributorProduct({
        venueId: this.venueId,
        distributorId: this.distributorId,
        productId: id,
      });
      this.selectedProduct = data.data;
      this.openEditModal();
    },
    onModalSuccess() {
      flash.success({
        title: `Product successfully ${falsy(this.selectedProduct) ? 'added' : 'updated'}.`,
      });
      this.selectedProduct = {};
      this.refresh();
      this.closeEditModal();
    },
    onPriceChange() {
      flash.success({
        title: 'New price successfully set',
        message: `You have set a new price for ${this.selectedProduct.name}`,
      });
      this.refresh();
    },
    async removeProduct({ id }) {
      const { close, canceled } = await confirmation.alert({
        title: this.$t('product.removeProductAlertTitle'),
        message: this.$t('product.removeProductAlertMessage'),
      });
      if (canceled) return;
      close();

      try {
        await this.venueRemoveNewDistributorProduct({
          venueId: this.venueId,
          distributorId: this.distributorId,
          productId: id,
        });
        flash.success({ title: 'Product successfully removed.' });
        await this.refresh();
      } catch (e) {
        flash.error({ title: 'Something went wrong' });
      }
    },
    fetchNewDistributorProducts() {
      return this.venueFetchNewDistributorProducts({
        distributorId: this.distributorId,
        query: {
          ...(this.meta.nextId ? { nextId: this.meta.nextId, limit: '20' } : { limit: '20' }),
        },
      });
    },
    async refresh() {
      this.meta = {};
      const { data } = await this.fetchNewDistributorProducts();
      this.products = data.data;
      this.meta = data.meta;
    },
    async loadMore() {
      const { data } = await this.fetchNewDistributorProducts();
      this.products = [...this.products, ...data.data];
      this.meta = data.meta;
    },
    onLoadMore() {
      this.loadMore();
    },
    uploadCSVModal() {
      this.$refs.csvUploadModal.open();
    },
    uploadCSVAction(data) {
      return this.venueUploadDistributorProducts({
        venueId: this.venueId,
        distributorId: this.distributorId,
        data,
      });
    },
    onUploadFinished() {
      this.refresh();
    },
    updateProductAvailability(venue) {
      const { id, isHidden } = venue;
      const data = {
        productId: id,
        venueId: this.venueId,
        distributorId: this.distributorId,
      };
      return isHidden
        ? this.venueUnhideDistributorProduct(data)
        : this.venueHideDistributorProduct(data);
    },
    async updateVisibility(venue) {
      try {
        await this.updateProductAvailability(venue);
        this.refresh();
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
    async bulkUpdateProductVisibility(isHidden) {
      try {
        const data = {
          venueId: this.venueId,
          distributorId: this.distributorId,
        };
        await (isHidden
          ? this.venueHideAllDistributorProduct(data)
          : this.venueUnhideAllDistributorProduct(data));
        this.refresh();
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
    selectAll() {
      this.bulkUpdateProductVisibility(false);
    },
    deselectAll() {
      this.bulkUpdateProductVisibility(true);
    },
  },
  async mounted() {
    await this.fetchCategoriesByVenueId(this.venueId);
    await this.refresh();
    this.enableNextStep();
  },
};
</script>

<template>
  <div>
    <ez-product-modal
      ref="productModal"
      :formKey="formKey"
      :formAction="action"
      :distributorId="distributorId"
      :product="selectedProduct"
      @success="onModalSuccess"
    />

    <ez-csv-upload-modal
      ref="csvUploadModal"
      :uploadCSV="uploadCSVAction"
      :onUploadFinished="onUploadFinished"
    />

    <propose-price-modal
      ref="modal"
      :venueId="venueId"
      :distributorId="distributorId"
      :product="selectedProduct"
      @success="onPriceChange"
    >
      <template #title> Set New Pricing </template>
      <template #text>
        <p>Please type in the price you would like to set for {{ selectedProduct.name }}.</p>
      </template>
      <template #confirm> Set New Price </template>
    </propose-price-modal>

    <ez-button-group class="header mb-24">
      <ez-simple-dropdown placeholder="Set Visibility">
        <template #dropdown>
          <div class="dropdown-actions">
            <ez-button :disabled="!products.length" type="link" @click="selectAll">
              <font-awesome-icon icon="eye" class="mr-8" /><span>Show All</span>
            </ez-button>
            <ez-button :disabled="!products.length" type="link" @click="deselectAll">
              <font-awesome-icon icon="eye-slash" class="mr-8" /><span>Hide All</span>
            </ez-button>
          </div>
        </template>
      </ez-simple-dropdown>
      <span class="ez-button-group__line"></span>

      <ez-button type="secondary" @click="uploadCSVModal"> Upload CSV </ez-button>
      <ez-button @click="newProduct">
        <span>Add New Product</span>
      </ez-button>
    </ez-button-group>

    <ez-table
      class="products-table"
      v-if="products.length"
      :data="products"
      :headers="headers"
      :loading="isLoading"
      :columns="columns"
      :removeButton="true"
      @rowClick="openSingleProduct"
      @removeItem="removeProduct"
      :columnProps="{
        unit: { class: 'medium-cell' },
        pricePerUnit: { class: 'price-cell' },
        actions: { class: 'actions-cell' },
        visibility: { class: 'visibility-cell' },
      }"
    >
      <template #cell-name="{ row }">
        <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="row.image">
          <div class="product-info" :title="row.name">
            <span>{{ row.name }}</span>
            <span class="product-info-secondary">
              {{ row.sku }} &#8226; {{ row.category | categoryWithParent }}
            </span>
          </div>
        </ez-entity-info>
      </template>
      <template #cell-visibility="{ row }">
        <v-status-dropdown
          @change="updateVisibility(row)"
          :data="$helpers.getVisibilityOptions(row.isHidden)"
        />
      </template>
      <template #cell-unit="{ row: { orderingUnit } }">
        <template v-if="orderingUnit">
          <v-data-with-info
            :info="orderingUnit.abbreviation ? `${orderingUnit.name}` : ''"
            :show-underline="!!orderingUnit.abbreviation"
          >
            {{ orderingUnit.label }}
          </v-data-with-info>
        </template>
      </template>
      <template #cell-pricePerUnit="{ row: { price, priceUnit, marketPrice } }">
        <v-price
          :price="price || 0"
          :is-market-price="marketPrice"
          :show-market-price-info="false"
          :unit="priceUnit && priceUnit.label"
        />
      </template>
      <template #cell-actions="{ row }">
        <ez-button v-if="row.proposedPrice" type="green">Price proposed</ez-button>
        <ez-button v-else type="secondary" @click.stop="proposePrice(row)" :stop-propagation="true">
          Set Price
        </ez-button>
      </template>
    </ez-table>
    <template v-else>
      <empty-state class="empty-state-center">
        <template #badge> <img src="@/assets/no-product-empty-state.svg" alt="" /></template>
        <template #title>No products listed</template>
      </empty-state>
    </template>
    <ez-load-more v-if="meta.nextId" @loadMore="onLoadMore" />
    <ez-button class="mt-24" type="secondary" formType="button" isFullWidth @click="newProduct">
      <font-awesome-icon icon="plus" />
      <slot name="add-new-button">Add New Product</slot>
    </ez-button>
  </div>
</template>

<style lang="scss" scoped>
.header {
  justify-content: flex-end;
  :deep() .dropdown-actions {
    .button {
      width: 100%;
    }
  }
}

:deep() .actions-cell {
  width: 170px;
}

:deep() .products-table {
  tbody tr td {
    overflow: visible;
  }
}

.product-info {
  display: flex;
  flex-direction: column;
}

.product-info--secondary {
  display: block;
}

:deep() .table {
  .sku-cell,
  .category-cell {
    display: none;
  }
  tbody tr td {
    line-height: 1.5;
  }
}

:deep() .visibility-cell {
  width: 100px;
}

:deep() .loader {
  position: fixed;
}
</style>
