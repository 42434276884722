<template>
  <ez-wizard fullWidth @exit="onWizardExit">
    <template #title>
      {{ type || '' }}{{ `${type && noteLabel ? ' - ' : ''}` }}{{ noteLabel || '' }}
    </template>
    <template #actions>
      <ez-button class="p-0" type="link" @click="onRemovePendingUpdate">
        <font-awesome-icon icon="trash" />
      </ez-button>
    </template>
    <div class="processing-wrapper">
      <div class="processing-pdf">
        <FileSlider v-if="invoice?.invoices?.length" :data="invoice.invoices" />
      </div>
      <div class="processing-content">
        <div>
          <div v-if="!isInvoiceCompleted" class="processing-actions">
            <ez-button
              type="secondary"
              :class="{ selected: note === 'missing_page' }"
              @click="selectNote('missing_page')"
            >
              Missing Page
            </ez-button>
            <ez-button
              type="secondary"
              :disabled="isInvoiceCompleted"
              :class="{ selected: note === 'blurry_image' }"
              @click="selectNote('blurry_image')"
            >
              Blur Invoice
            </ez-button>
            <ez-button-dropdown
              buttonType="secondary"
              :class="{ selected: note === 'not_valid' || note === 'duplicate' }"
              @click="selectNote('not_valid')"
            >
              <template>Not Valid</template>
              <template #icon>
                <font-awesome-icon icon="angle-down" />
              </template>
              <template #dropdown>
                <ez-button
                  @click="selectSubNote('not_valid_delivery_order', 'not_valid')"
                  type="secondary"
                  :class="{ selected: subNoteKey === 'not_valid_delivery_order' }"
                  >Delivery Order</ez-button
                >
                <ez-button
                  @click="selectSubNote('not_valid_unknown_document', 'not_valid')"
                  type="secondary"
                  :class="{ selected: subNoteKey === 'not_valid_unknown_document' }"
                  >Unknown Document</ez-button
                >
                <ez-button
                  @click="selectSubNote('not_valid_unsupported_language', 'not_valid')"
                  type="secondary"
                  :class="{ selected: subNoteKey === 'not_valid_unsupported_language' }"
                  >Unsupported Language</ez-button
                >
                <ez-button
                  @click="selectSubNote('not_valid_math_error', 'not_valid')"
                  :class="{ selected: subNoteKey === 'not_valid_math_error' }"
                  type="secondary"
                  >Supplier Math Error</ez-button
                >
                <ez-button
                  @click="selectSubNote('not_valid_duplicate', 'not_valid')"
                  :class="{
                    selected: subNoteKey === 'not_valid_duplicate' || note === 'duplicate',
                  }"
                  type="secondary"
                  >Duplicate</ez-button
                >
                <ez-button
                  v-if="invoice?.type === 'order'"
                  @click="selectSubNote('not_valid_wrong_order', 'not_valid')"
                  type="secondary"
                  :class="{ selected: subNoteKey === 'not_valid_wrong_order' }"
                  >Uploaded to wrong order</ez-button
                >
              </template>
            </ez-button-dropdown>
            <ez-button-dropdown
              buttonType="secondary"
              @click="selectNote('missing_info')"
              :class="{ selected: note === 'missing_info' }"
            >
              <template>Missing Info</template>
              <template #icon>
                <font-awesome-icon icon="angle-down" />
              </template>
              <template #dropdown>
                <ez-button
                  @click="selectSubNote('missing_info_invoice_number', 'missing_info')"
                  :class="{ selected: subNoteKey === 'missing_info_invoice_number' }"
                  type="secondary"
                  >Invoice Number</ez-button
                >
                <ez-button
                  @click="selectSubNote('missing_info_product_details', 'missing_info')"
                  :class="{ selected: subNoteKey === 'missing_info_product_details' }"
                  type="secondary"
                  >Product Details</ez-button
                >
                <ez-button
                  @click="selectSubNote('missing_info_prices', 'missing_info')"
                  :class="{ selected: subNoteKey === 'missing_info_prices' }"
                  type="secondary"
                  >Prices</ez-button
                >
                <ez-button
                  @click="selectSubNote('missing_info_unreadable', 'missing_info')"
                  :class="{ selected: subNoteKey === 'missing_info_unreadable' }"
                  type="secondary"
                  >Unreadable</ez-button
                >
              </template>
            </ez-button-dropdown>
            <ez-button v-if="sendToClearingEnabled" @click="sendToClearing">
              Send to Clearing
            </ez-button>
            <ez-button :disabled="isCompleting" @click="completePendingInvoice">Complete</ez-button>
          </div>
        </div>
        <div class="processing-form">
          <InvoiceDetails
            @editSupplierStart="() => (editSupplier = true)"
            @editSupplierStop="() => (editSupplier = false)"
            :venueId="venueId"
            :invoice="invoice"
          />
          <SelectedProducts v-show="!editSupplier" :invoice="invoice" :venueId="venueId" />
          <AvailableProducts
            :venueId="venueId"
            :invoice="invoice"
            @productCreated="selectQuantity"
            v-show="!editSupplier"
          />
          <InvoiceCalculation :invoice="invoice" />
          <ez-confirmation-modal ref="deleteModal" type="red">
            <template #title>Remove Invoice?</template>
            <template #content>
              <p>This invoice will be deleted from the list.</p>
            </template>
            <template #footer>
              <ez-button @click="closeDeleteModal" type="link">Cancel</ez-button>
              <ez-button @click="deleteInvoice" type="red">Remove Invoice</ez-button>
            </template>
          </ez-confirmation-modal>
        </div>
      </div>
    </div>
  </ez-wizard>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  ADMIN_INVOICE_PROCESSING_STATUS_ACTION_NEEDED,
  ADMIN_INVOICE_PROCESSING_STATUS_PROCESSED,
  INVOICE_PROCESSING_STATUS_DRAFT,
  LOADING_KEY,
} from '@/util/constants';
import EzWizard from '@/components/layout/Wizard.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown.vue';
import FileSlider from '@/components/ui/FileSlider/FileSlider';
import flash from '@/components/ui/FlashMessage';
import uuid from 'uuid/v4';
import dayjs from 'dayjs';
import { EzConfirmationModal } from '@/components/ui/Modal';
import InvoiceDetails from './InvoiceDetails';
import SelectedProducts from './SelectedProducts';
import AvailableProducts from './AvailableProducts';
import InvoiceCalculation from './InvoiceCalculation';

export default {
  components: {
    EzWizard,
    EzButton,
    FileSlider,
    InvoiceDetails,
    SelectedProducts,
    AvailableProducts,
    InvoiceCalculation,
    EzConfirmationModal,
    EzButtonDropdown,
  },
  props: {
    invoiceId: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      invoice: {},
      note: null,
      noteLabel: null,
      type: null,
      subNoteKey: null,
      editSupplier: false,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    ...mapState('entities/users', ['contextId', 'loggedUser']),
    venueId() {
      return this.invoice?.venue?.id ?? this.contextId;
    },
    groupViewMode() {
      if (this.loggedUser.context === 'venue-group') return true;
      return false;
    },
    isInvoiceCompleted() {
      return this.invoice.processingStatus === ADMIN_INVOICE_PROCESSING_STATUS_PROCESSED;
    },
    sendToClearingEnabled() {
      let canProcess = false;
      if (this.groupViewMode) {
        canProcess =
          this.loggedUser?.venues.find(v => v.id === this.invoice?.venue?.id)?.canProcessInvoices ||
          false;
      } else {
        canProcess =
          this.loggedUser?.venues.find(v => v.id === this.contextId).canProcessInvoices || false;
      }
      return (
        canProcess &&
        ((this.invoice.noteKey === 'out_of_invoices' &&
          this.invoice.processingStatus === ADMIN_INVOICE_PROCESSING_STATUS_ACTION_NEEDED) ||
          this.invoice.processingStatus === INVOICE_PROCESSING_STATUS_DRAFT)
      );
    },
    productsToSubmit() {
      return this.invoice.order.products.map(product => ({
        id: product.productId || product.id,
        quantity: product.quantity || 0,
        price: product.price,
        tax: product.tax || 0,
        ...(product.productId ? { orderedProductId: product.id } : {}),
      }));
    },
    isCompleting() {
      return this.getLoading(LOADING_KEY.ADMIN_COMPLETE_PENDING_INVOICE);
    },
  },
  methods: {
    ...mapActions('entities/orders', ['venueCompletePendingInvoice', 'venueInvoiceSendToClearing']),
    ...mapActions('entities/distributors', ['distributorGetSinglePendingUpdate']),
    ...mapActions('entities/venues', ['outletGetPendingInvoices', 'outletDeletePendingInvoice']),
    onWizardExit() {
      this.$router.push({ name: 'venue-pending-updates' });
    },
    onRemovePendingUpdate() {
      this.openDeleteModal();
    },
    openDeleteModal() {
      this.$refs.deleteModal.open();
    },
    async sendToClearing() {
      try {
        await this.venueInvoiceSendToClearing({
          invoiceId: this.invoiceId,
          venueId: this.venueId,
        });
        flash.success({ title: 'Pending invoice successfully sent to clearing' });
        this.$router.push({ name: 'venue-pending-updates' });
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
    closeDeleteModal() {
      this.$refs.deleteModal.close();
    },
    async deleteInvoice() {
      try {
        await this.outletDeletePendingInvoice({
          venueId: this.venueId,
          pendingId: this.invoiceId,
        });
        this.closeDeleteModal();
        flash.success({
          title: 'Invoice successfully deleted!',
        });
        this.$router.push({ name: 'venue-pending-updates' });
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
    selectNote(note) {
      if (this.note === note || (this.note === 'duplicate' && note === 'not_valid')) {
        this.note = null;
        this.subNoteKey = null;
      } else this.note = note;
    },
    selectSubNote(subNote, note) {
      if (
        this.subNoteKey === subNote ||
        (this.note === 'duplicate' && subNote === 'not_valid_duplicate')
      ) {
        this.subNoteKey = null;
        this.note = null;
      } else {
        this.subNoteKey = subNote;
        this.note = note;
      }
    },
    async completePendingInvoice() {
      try {
        await this.venueCompletePendingInvoice({
          body: {
            ...this.invoice,
            ...(this.note ? { note: this.note } : {}),
            ...(this.subNoteKey ? { subNote: this.subNoteKey } : {}),
            addToTraining: false,
            order: {
              ...this.invoice.order,
              products: this.productsToSubmit,
              distributorId: this.invoice.order?.distributor?.id,
              orderedAt: this.invoice.order.orderedAt
                ? dayjs(this.invoice.order.orderedAt).format('YYYY-MM-DD')
                : null,
              ...(this.invoice.order.tax === null
                ? { tax: this.invoice.order?.distributor?.tax || 0 }
                : {}),
            },
          },
          invoiceId: this.invoiceId,
          venueId: this.venueId,
          formKey: 'pending-invoice',
        });
        flash.success({ title: 'Pending invoice successfully submitted' });
        if (this.note === 'missing_page') {
          this.$router.push({
            name: 'buyer-orders-merge-pending-update',
            params: { id: this.invoiceId },
          });
          return;
        }
        this.$router.push({ name: 'venue-pending-updates' });
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
    async selectQuantity() {
      await this.$nextTick();
      const quantityInputs = document.querySelectorAll('.quantity__input input');
      const quantityInput = quantityInputs[quantityInputs.length - 1];
      if (quantityInput) quantityInput.focus();
    },
    setupInvoice(data) {
      this.noteLabel = data.note;
      delete data.note;
      if (!data.order) {
        return {
          // Merge from API
          ...data,
          // Add defaults
          order: {
            products: [],
            taxCalculation: data?.order?.distributor?.taxCalculation || 'exclusive',
            orderDiscountAmount: 0,
            amountAdjustment: 0,
            deliveryFee: 0,
            taxAdjustment: 0,
            distributor: {},
            tax: null,
          },
        };
      }
      data.order.products = [];
      if (data.order.orderedProducts?.length) {
        data.order.orderedProducts.forEach(product => {
          let totalAmount = product.totalPrice;
          if (data.order.taxCalculation === 'exclusive') {
            if (product.tax === null) totalAmount += totalAmount * (data.order.tax / 100 || 0);
            else totalAmount += totalAmount * (product.tax / 100 || 0);
          }
          data.order.products.push({
            internalId: uuid(),
            ...product,
            totalAmount,
            totalPrice: product.quantity * product.price,
          });
        });
      }
      data.order = {
        taxCalculation: data?.order?.distributor?.taxCalculation || 'exclusive',
        orderDiscountAmount: 0,
        amountAdjustment: 0,
        deliveryFee: 0,
        taxAdjustment: 0,
        distributor: {},
        tax: null,
        ...data.order,
      };
      return data;
    },
  },
  async beforeMount() {
    this.invoice = this.setupInvoice({});
    const { data } = await this.distributorGetSinglePendingUpdate({
      venueId: this.venueId,
      pendingId: this.$route.params.id,
    });
    this.invoice = this.setupInvoice(data.data);
    this.note = data.data.noteKey;
    this.subNoteKey = data.data.subNoteKey;
    if (data.data.type === 'invoice') this.type = 'Invoice';
    else if (data.data.type === 'order') this.type = 'Order';
    else if (data.data.type === 'credit_note') this.type = 'Credit Note';
  },
};
</script>

<style scoped lang="scss">
$purple-inner-color: #eedff4;
$purple-text-color: #7833a2;

:deep() .ez-wizard__main {
  max-width: none;
  padding: 32px 60px;

  .ez-wizard__page-header {
    margin: 0;
  }
}

.processing-wrapper {
  display: flex;
  align-items: flex-start;
}

.processing-pdf {
  position: sticky;
  top: 80px;
  flex: 1 1 100%;
  min-width: 576px;
  margin-right: 48px;
}

.processing-content {
  max-width: 980px;
  flex: 1 1 100%;
}

:deep() .customer-note {
  input {
    border-radius: 1px 12px 12px 12px;
    border-color: $purple-inner-color;
    background-color: $purple-inner-color;
    color: $purple-text-color;

    &::placeholder {
      color: $purple-text-color;
    }
  }

  .input-group__prefix {
    color: $purple-text-color;
  }

  .input-group__input input:not(:read-only):focus {
    border-color: $purple-text-color;
  }

  .input-group__input--has-prefix:focus-within .input-group__prefix {
    color: $purple-text-color;
    border-color: $purple-text-color;
  }
}

.processing-actions {
  margin-bottom: 32px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  row-gap: 12px;

  :deep() .button {
    min-width: 146px;
    margin-left: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.button--secondary {
      border: 2px solid $color-gray-F5;
      &.selected {
        border: 2px solid $color-primary-blue;
      }
    }
  }

  :deep() .button-dropdown.button-dropdown--secondary {
    border: 2px solid $color-gray-F5;
    &.selected {
      border: 2px solid $color-primary-blue;
    }
    margin-left: 32px;
    .trigger {
      padding-left: 6px;
    }
    .button-dropdown__toggle-container .button {
      min-height: 32px;
    }
    .button {
      min-width: initial;
      margin-left: 0;
      height: 32px;
      line-height: 32px;
      padding-right: 6px;
    }
    .button.trigger {
      padding-right: 12px;
    }

    .button-dropdown__dropdown {
      .button.button--secondary {
        min-width: initial;
        margin-left: 0;
        justify-content: flex-start;
        border: none;
        background-color: #fff;

        &:hover {
          background-color: #f5f6fa;
        }
        &.selected {
          color: $color-primary-blue;
        }
      }
    }
  }
  :deep() .button-dropdown button.button--secondary {
    border: none;
  }
}

.processing-form {
  padding: 24px 40px;
  border-radius: 3px;
  border: 1px dashed #e1e5ed;
}

:deep() .pdf-placeholder {
  height: 560px;
}

@media (min-width: 1920px) {
  :deep() .pdf-placeholder {
    height: 960px;
  }
}

.dropdown-button {
  :deep() .button.button--primary {
    padding-right: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: initial;
    display: inline-block;
  }
  :deep() .button-dropdown.button-dropdown--primary {
    padding-left: 0px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .button.button--primary {
      padding-left: 6px;
      padding-right: 12px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      margin-left: 0;
    }
    .button.button--secondary {
      min-width: initial;
      margin-left: 0;
      justify-content: flex-start;
      border: none;
      background-color: #fff;

      &:hover {
        background-color: #f5f6fa;
      }
    }
  }
}
</style>
