var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('paper',{staticClass:"distributor",scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('ez-button',{attrs:{"type":"link","formType":"button","data-cy":_vm.outletCy.ORDERS.EXISTING_OFFLINE_ORDER.OVERVIEW.BUTTON__EDIT_SUPPLIER},on:{"click":_vm.editDistributor}},[_c('font-awesome-icon',{attrs:{"icon":"pen"}}),_c('span',[_vm._v("Edit")])],1)]},proxy:true}])},[_c('ez-entity-info',{attrs:{"imgWidth":"4rem","imgHeight":"4rem","imgBorderRadius":"50%","img-url":_vm.distributor.logo}},[_vm._v(" "+_vm._s(_vm.distributor.name)+" ")]),_c('hr'),_c('div',{staticClass:"info info--vertical"},[_c('small',[_vm._v("Order Date")]),_c('span',[_vm._v(_vm._s(_vm._f("date")(_vm.offlineOrder.date)))])])],1),_c('paper',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Products ("+_vm._s(_vm.offlineOrder.selectedProducts.length)+") ")]},proxy:true},{key:"actions",fn:function(){return [_c('ez-button',{attrs:{"type":"link","formType":"button","data-cy":_vm.outletCy.ORDERS.EXISTING_OFFLINE_ORDER.OVERVIEW.BUTTON__EDIT_PRODUCTS},on:{"click":_vm.editProducts}},[_c('font-awesome-icon',{attrs:{"icon":"pen"}}),_c('span',[_vm._v("Edit")])],1)]},proxy:true}])},[_c('div',{staticClass:"products-table"},[_c('ez-table',{attrs:{"data":_vm.offlineOrder.selectedProducts,"headers":false,"columns":['name', 'quantity', 'amount'],"columnProps":{
          quantity: { class: 'quantity-cell' },
          amount: { class: 'price-cell no-padding-cell' },
        }},scopedSlots:_vm._u([{key:"cell-name",fn:function({ row }){return [_c('v-product-entity-info',{attrs:{"product":row}})]}},{key:"cell-quantity",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.isFractional(row.orderingUnit) ? row.quantity.toFixed(2) : row.quantity)+" ")]}},{key:"cell-amount",fn:function({ row, row: { price, quantity, currency, totalPrice, totalToChange } }){return [_c('v-price',{attrs:{"price":totalToChange ? totalPrice : (price * quantity),"currency":currency,"show-market-price-info":false,"is-to-be-determined":_vm.isToBeDetermined(row) && !totalToChange}})]}}])})],1),_c('div',{staticClass:"total"},[_c('strong',{staticClass:"total__label"},[_vm._v(" Total (incl. taxes) "),_c('v-subtotal-info',{staticClass:"ml-4",attrs:{"item-list":_vm.itemList,"delivery-fee":_vm.deliveryFee,"tax":_vm.tax,"show-total":false,"is-tbd":_vm.isSomeTbd}})],1),_c('div',{staticClass:"total__amount"},[_c('v-price',{attrs:{"price":_vm.total,"currency":_vm.currencySymbol,"show-market-price-info":false,"is-to-be-determined":_vm.isSomeTbd}})],1)])]),_c('paper',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Additional Details")]},proxy:true}])},[_c('v-additional-delivery-fee',{attrs:{"distributor":_vm.distributor,"data":{
        deliveryFee: _vm.deliveryFee,
        amountAdjustment: _vm.amountAdjustment,
        taxAdjustment: _vm.taxAdjustment,
      },"data-cy":_vm.outletCy.ORDERS.EXISTING_OFFLINE_ORDER.OVERVIEW.BUTTON__DELIVERY_FEE},on:{"changeDeliveryFee":_vm.editDeliveryFee,"changeAmountAdjustment":_vm.editAmountAdjustment,"changeTaxAdjustment":_vm.editTaxAdjustment}})],1),(!_vm.$permission.isFree)?_c('paper',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Invoice")]},proxy:true}],null,false,2467515180)},[_c('ez-multiple-image-upload',{ref:"imageUpload",staticClass:"invoice-upload",attrs:{"name":"invoice","form-key":"upload-invoice","card-type":"","accept":"image/jpeg,image/png,application/pdf","number-of-files":5,"value":_vm.offlineOrder.receipts,"data-cy":_vm.outletCy.ORDERS.EXISTING_OFFLINE_ORDER.OVERVIEW.BUTTON__UPLOAD_INVOICE},on:{"change":_vm.imageUploadChange},scopedSlots:_vm._u([{key:"label",fn:function(){return undefined},proxy:true},{key:"cta",fn:function(){return [_vm._v("Upload Invoice")]},proxy:true}],null,false,502310367)})],1):_vm._e(),_c('ez-loader',{attrs:{"show":_vm.loading}},[_vm._v("Loading...")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }