<script>
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import { getContextId, debounce } from '@/util/utils';
import { LOADING_KEY, UNIT_TYPE_PACK } from '@/util/constants';

import SinglePage from '@/views/admin/SinglePage.vue';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzLoadMore from '@/components/ui/LoadMore';
import EzSpinner from '@/components/ui/Spinner';
import EzButton from '@/components/ui/Button';
import EzTable from '@/components/ui/Table';
import VAccountOwnerEntityInfo from '@/components/v3/patterns/VAccountOwnerEntityInfo';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import EzFilterList from '@/components/ui/FilterList';
import VFilterDropdown from '@/components/v3/patterns/VFilterDropdown';
import EzSelect from '@/components/ui/Select/EzSelect.vue';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import EzProductGroupFilter from '@/components/ui/Filter/ProductGroup.vue';
import EzCategoryFilter from '@/components/ui/Filter/Category.vue';
import VDistributorEntityInfo from '@/components/v3/patterns/VDistributorEntityInfo';
import VTag from '@/components/v3/elements/VTag';
import VPrice from '@/components/v3/elements/VPrice';
import TotalIcon from '@/views/common/orders/total-icon';
import downloadAttachment from '@/util/downloadAttachment';
import EzLoader from '@/components/ui/Loader/EzLoader.vue';

const FilterArea = {
  List: 'list',
  Dropdown: 'dropdown',
};

/**
 * Stocktake
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'Stocktake',
  props: {
    stocktakeId: {
      type: Number,
      required: true,
    },
  },
  components: {
    VDataWithInfo,
    SinglePage,
    EzEntityInfo,
    EzLoadMore,
    EzSpinner,
    EzButton,
    EzTable,
    VAccountOwnerEntityInfo,
    VFilterDropdown,
    EzFilterList,
    EzSelect,
    VSelectSearch,
    EzCategoryFilter,
    EzProductGroupFilter,
    VDistributorEntityInfo,
    VTag,
    VPrice,
    TotalIcon,
    EzLoader,
  },
  data() {
    return {
      stocktake: {},
      items: [],
      meta: { page: 0 },
      dayjs,
      packUnitType: UNIT_TYPE_PACK,
      dropdownFilters: {},
      listFilters: {},
      inventoryLocations: [],
      productGroups: [],
      distributors: [],
      categories: [],
      FilterArea,
      totalAmount: null,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([LOADING_KEY.VENUE_FETCH_STOCKTAKES]);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.VENUE_FETCH_MORE_STOCKTAKES);
    },
    isLoadingExport() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_EXPORT_STOCKTAKE);
    },
    venueId() {
      return getContextId();
    },
    productGroupsFilter() {
      return [{ id: null, name: 'All Product Groups' }, ...this.productGroups];
    },
    distributorFilter() {
      return this.distributors;
    },
    inventoryLocationsFilter() {
      return [{ id: null, name: 'All Locations' }, ...this.inventoryLocations];
    },
    note() {
      if (this.dropdownFilters.locationId) {
        return (
          this.stocktake.locationStocktakes.filter(
            item => item.location.id === this.dropdownFilters.locationId,
          )[0]?.note || null
        );
      }
      return this.stocktake.note;
    },
    users() {
      if (this.dropdownFilters.locationId) {
        return (
          this.stocktake.locationStocktakes.filter(
            item => item.location.id === this.dropdownFilters.locationId,
          )[0]?.users || []
        );
      }
      return this.stocktake.users;
    },
    isCompleted() {
      return this.stocktake.status === 'completed';
    },
  },
  methods: {
    ...mapActions('entities/venues', [
      'venueFetchStocktake',
      'venueFetchStocktakeItems',
      'fetchVenueLocations',
      'venueFetchDistributorFilterOptions',
      'venueFetchStocktakeTotalAmount',
      'venueExportSingleStocktake',
    ]),
    ...mapActions('entities/products', ['venueFetchProductGroups']),
    ...mapActions('entities/categories', ['venueFetchCategoriesNew']),
    async fetchStocktake() {
      const { data } = await this.venueFetchStocktake({
        venueId: this.venueId,
        stocktakeId: this.stocktakeId,
      });
      this.stocktake = data.data;
    },
    async fetchItems(loadingKey = LOADING_KEY.VENUE_FETCH_STOCKTAKES) {
      const { data } = await this.venueFetchStocktakeItems({
        stocktakeId: this.stocktakeId,
        venueId: this.venueId,
        loadingKey,
        query: { page: this.meta.page + 1, ...this.dropdownFilters },
      });
      if (loadingKey === LOADING_KEY.VENUE_FETCH_STOCKTAKES) {
        this.items = data.data;
        const { data: totalAmount } = await this.venueFetchStocktakeTotalAmount({
          stocktakeId: this.stocktakeId,
          venueId: this.venueId,
          query: this.dropdownFilters,
        });
        this.totalAmount = totalAmount.data;
      } else {
        this.items = [...this.items, ...data.data];
      }
      this.meta = data.meta;
    },
    async exportStocktake() {
      const { data, headers } = await this.venueExportSingleStocktake({
        stocktakeId: this.stocktakeId,
        venueId: this.venueId,
      });
      const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
      const name = Array.isArray(filename) ? decodeURI(filename[1]) : 'template.xlsx';
      downloadAttachment(data, name);
    },
    async loadMore() {
      await this.fetchItems(LOADING_KEY.VENUE_FETCH_MORE_STOCKTAKES);
    },
    async fetchLocations() {
      const {
        data: { data },
      } = await this.fetchVenueLocations({ venueId: this.venueId });
      this.inventoryLocations = data;
    },
    async fetchProductGroups() {
      const {
        data: { data },
      } = await this.venueFetchProductGroups();
      this.productGroups = data;
    },
    async fetchDistributors() {
      const { data } = await this.venueFetchDistributorFilterOptions({
        venueId: this.venueId,
        query: { for: 'products' },
      });
      this.distributors = data.data;
    },
    async fetchCategoriesFilter() {
      const {
        data: { data },
      } = await this.venueFetchCategoriesNew();
      this.categories = data;
    },
    updateFilters: debounce(async function deb(area, event) {
      if (area === FilterArea.List && event[0] !== 'locationId') {
        const [filterName, value] = event;
        this.listFilters = { ...this.listFilters, [filterName]: value };
      } else if (area === FilterArea.Dropdown || event[0] === 'locationId') {
        this.dropdownFilters = {
          ...this.dropdownFilters,
          ...(typeof event.categoryId === 'object' &&
            event.categoryId !== null && { categoryId: event.categoryId.id }),
          ...(typeof event.distributorId === 'object' &&
            event.distributorId !== null && { distributorId: event.distributorId.id }),
          ...(typeof event.productGroupId === 'object' &&
            event.productGroupId !== null && { productGroupId: event.productGroupId.id }),
          ...(typeof event.locationId === 'object' &&
            event.locationId !== null && { locationId: event.locationId.id }),
          ...(event[0] === 'locationId' && { locationId: event[1].id }),
        };
        if (event.distributorId === undefined) delete this.dropdownFilters.distributorId;
        this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());
      }
      this.meta = {
        page: 0,
      };
      await this.fetchItems();
    }, 300),
    async resetFilters(area) {
      if (area === FilterArea.List) {
        Object.keys(this.listFilters).forEach(key => {
          this.listFilters[key] = null;
        });
      } else if (area === FilterArea.Dropdown) {
        this.dropdownFilters = {};
      }
      this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());
      this.meta = { page: 0 };
      await this.fetchItems();
    },
    getInitials(user) {
      return (user || '')
        .match(/(\b\S)?/g)
        .join('')
        .toUpperCase();
    },
    positions(row) {
      return row.locations.filter(item => item.location.id === this.dropdownFilters.locationId)[0]
        ?.positions;
    },
    getLocation(row) {
      if (row.locations.length === 1) return row.locations[0].location.name;
      return 'Multiple';
    },
    tooltip(row) {
      const locations = row.locations.map(
        l => `
        <div class="inventory-item">
          <strong class="inventory-item__label">
            ${l.location.name}:
          </strong>
          <span class="inventory-item__number">
            ${l.actualInventory}
          </span>
        </div>`,
      );

      const total = `
        <div class="inventory-item inventory-item--total">
          <strong class="inventory-item__label">
            Total:
          </strong>
          <span class="inventory-item__number">
            ${row.actualInventory}
          </span>
        </div>`;

      const tooltipContent = `
        <div class="v-product-quantity-inventory__tooltip">
          ${locations.join('')}
          ${total}
        </div>`;

      return {
        html: true,
        placement: 'end',
        classes: ['tooltip--reset-margin', 'm-8'],
        content: tooltipContent,
      };
    },
  },
  async created() {
    await Promise.all([
      this.fetchStocktake(),
      this.fetchItems(),
      this.fetchLocations(),
      this.fetchProductGroups(),
      this.fetchCategoriesFilter(),
      this.fetchDistributors(),
    ]);
  },
};
</script>

<template>
  <div>
    <single-page v-if="!isLoading">
      <template #breadcrumbs>
        <template>
          <router-link :to="{ name: 'venue-stock-stocktake' }">
            <font-awesome-icon icon="arrow-left" /> Back to Counts
          </router-link>
        </template>
      </template>
      <template #title>
        <h1 class="header__title">Count</h1>
        <p class="header__createdAt">
          {{ stocktake.createdAt | dateTime }}
        </p>
      </template>
      <template #actions>
        <ez-button
          v-if="isCompleted"
          type="secondary"
          :isLoading="isLoadingExport"
          @click="exportStocktake"
        >
          Export CSV
        </ez-button>
      </template>
      <template>
        <div class="subheader">
          <div class="subheader__section">
            <span class="subheader__label">Note</span>
            <p class="subheader__text">
              {{ note || '-' }}
            </p>
          </div>
          <div class="subheader__section">
            <span class="subheader__label">Users</span>
            <div class="subheader__users">
              <v-account-owner-entity-info
                v-for="(user, idx) in users"
                :key="idx"
                class="subheader__users__user"
                :account-owner="{
                  initials: getInitials(user),
                  name: user,
                  id: idx,
                  colors: ['#6C7995', '#ECEEF5'],
                }"
                :to-show-name="false"
              />
            </div>
          </div>
          <div class="subheader__section order-header__stat order-header__stat--amount">
            <span class="mr-12">{{ totalAmount | price }}</span>
            <total-icon />
          </div>
        </div>

        <div class="stocktake__actions mb-24">
          <ez-filter-list
            :filters="listFilters"
            @filterUpdated="(...$event) => updateFilters(FilterArea.List, $event)"
            @resetFilter="resetFilters(FilterArea.List)"
            class="mr-16"
          >
            <ez-select
              class="input-group locations-filter"
              name="locationId"
              :value="dropdownFilters.locationId"
              :selected="dropdownFilters.locationId"
              :options="inventoryLocationsFilter"
            />
          </ez-filter-list>
          <v-filter-dropdown
            :filters="dropdownFilters"
            @filterUpdated="$event => updateFilters(FilterArea.Dropdown, $event)"
            @resetFilters="resetFilters(FilterArea.Dropdown)"
            ref="filtersGroup"
          >
            <template #firstRow>
              <ez-product-group-filter
                name="productGroupId"
                label="Product Group"
                :productGroups="productGroupsFilter"
                :selected="dropdownFilters.productGroupId"
                isFullWidth
              />
              <v-select-search
                class="select-supplier"
                :selected="dropdownFilters.distributorId"
                formKey="filters"
                name="distributorId"
                :label="`${$t('global.distributor')}`"
                :data="distributorFilter"
                :placeholder="`All ${$t('global.distributors')}`"
                :searchPlaceholder="`Select ${$t('global.distributor')}`"
                align-left
                is-full-width
              >
                <template #result="{ result }">
                  <v-distributor-entity-info :distributor="result" />
                </template>
              </v-select-search>
              <ez-category-filter
                name="categoryId"
                label="Category"
                :categories="categories"
                :selected="dropdownFilters.categoryId"
                isFullWidth
              />
              <ez-select
                class="input-group product-group-filter"
                name="locationId"
                :value="dropdownFilters.locationId"
                :selected="dropdownFilters.locationId"
                label="Location"
                :options="inventoryLocationsFilter"
                isFullWidth
              />
            </template>
          </v-filter-dropdown>
          <ez-button-dropdown
            v-if="false"
            :expandOnClick="true"
            buttonType="secondary"
            :showToggleIcon="false"
          >
            <template>
              <span>Import/Export CSV</span>
            </template>
            <template #dropdown>
              <ez-button
                type="link"
                formType="button"
                customClass="upload-csv"
                @click="uploadCSVModal"
              >
                Upload CSV
              </ez-button>
              <ez-button type="link" formType="button" customClass="upload-csv" @click="exportCSV">
                Export CSV
              </ez-button>
            </template>
          </ez-button-dropdown>
        </div>

        <ez-table
          :data="items"
          :loading="isLoading"
          :columns="[
            'name',
            'unit',
            'countingOrder',
            'priorInventory',
            ...(!dropdownFilters.locationId ? ['addedInventory'] : []),
            'actualInventory',
            'inventoryChange',
            'value',
            ...(!dropdownFilters.locationId ? ['valueChange'] : []),
            'totalValue',
          ]"
          :headers="{
            name: () => 'Product',
            countingOrder: () => `${dropdownFilters.locationId ? 'Counting Order' : 'Location'}`,
            priorInventory: () => 'Prior',
            value: () => 'Per Unit',
            addedInventory: () => 'Additions',
            actualInventory: () => 'Current',
            inventoryChange: () => 'Change',
            valueChange: () => 'Change',
            totalValue: () => 'Total',
          }"
          :columnProps="{
            name: { class: 'name-cell' },
            countingOrder: { class: 'medium-cell counting-cell' },
            priorInventory: { class: 'small-cell' },
            actualInventory: { class: 'small-cell overflow-cell' },
            addedInventory: { class: 'additions-cell' },
            inventoryChange: { class: 'small-cell difference-cell' },
            note: { class: 'large-cell note-cell' },
            unit: { class: 'medium-cell unit-cell' },
            value: { class: 'value-cell price-cell' },
            valueChange: { class: 'value-cell price-cell' },
            totalValue: { class: 'price-cell' },
          }"
          disableHover
        >
          <template #cell-name="{ row }">
            <ez-entity-info :imgUrl="row.image">
              <div class="product-info" :title="row.name">
                <span class="product-info__name">{{ row.name }}</span>
                <span class="product-info__sku">{{ row.sku ? row.sku : '-' }}</span>
              </div>
            </ez-entity-info>
          </template>
          <template #cell-unit="{ row }">
            <template v-if="row.orderingUnit">
              <v-data-with-info
                :info="
                  row.orderingUnit.type === packUnitType
                    ? 'The quantity of pack unit products is being managed per single unit.'
                    : ''
                "
                :show-underline="row.orderingUnit.type === packUnitType"
                >{{ row.orderingUnit.label }}</v-data-with-info
              >
            </template>
          </template>
          <template #cell-countingOrder="{ row }">
            <div v-if="dropdownFilters.locationId">
              <div>
                <v-tag
                  color="#983BBD"
                  backgroundColor="#EEDFF4"
                  class="ml-4"
                  v-for="pos in positions(row)"
                  :key="pos"
                  >{{ pos }}</v-tag
                >
              </div>
            </div>
            <span v-else>{{ getLocation(row) }}</span>
          </template>
          <template #cell-inventoryChange="{ row: { inventoryChange } }">
            <span
              :class="[
                'bold-text',
                'difference',
                { 'difference--green': inventoryChange > 0 },
                { 'difference--red': inventoryChange < 0 },
              ]"
              >{{ inventoryChange > 0 ? '+' : '' }}{{ inventoryChange }}</span
            >
          </template>
          <template #cell-actualInventory="{ row }">
            <span
              v-if="!dropdownFilters.locationId"
              v-tooltip="tooltip(row)"
              :class="[
                'v-product-quantity-inventory',
                {
                  'dashed-underline': true,
                },
              ]"
            >
              {{ row.actualInventory }}
            </span>
            <span v-else>{{ row.actualInventory }}</span>
          </template>
          <template #cell-value="{ row: { value } }">
            <v-price :price="value || 0" />
          </template>
          <template #cell-valueChange="{ row: { valueChange } }">
            <v-price :price="valueChange || 0" />
          </template>
          <template #cell-totalValue="{ row: { totalValue } }">
            <v-price :price="totalValue || 0" />
          </template>
        </ez-table>

        <div v-if="isLoadingMore" class="u-text-center mt-12">
          <ez-spinner />
        </div>

        <ez-load-more v-if="!isLoadingMore && meta.lastPage > meta.page" @loadMore="loadMore" />
      </template>
    </single-page>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<style lang="scss" scoped>
.header {
  margin-bottom: 12px;

  &__title {
    @include font-size(24px, 32px);
    font-weight: 500;
    margin: 0;
  }

  &__createdAt {
    margin: 4px 0 0;
    color: $color-gray-6C;
  }
}

.subheader {
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;

  &__section {
    &:nth-child(2) {
      margin-left: 40px;
    }
  }

  &__total {
    display: flex;
    align-items: center;
    margin-left: auto;

    &__amount {
      @include font-size(20px, 28px);
      color: $color-gray-25;
      font-weight: 600;
      margin-right: 12px;
    }

    &__icon {
      @extend %flex-center;
      justify-content: center;
      color: $color-white;
      background-color: $color-pastel-orange;
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 50%;

      &__inner {
        @include font-size(11px);
        @extend %flex-center;
        justify-content: center;
        background-color: $color-primary-orange;
        border-radius: 50%;
        width: 1.875rem;
        height: 1.875rem;
      }
    }
  }

  &__label {
    @include font-size(12px, 18px);
    color: $color-gray-6C;
    font-weight: 600;
    margin-bottom: 4px;
  }

  &__text {
    margin: 0;
    width: 400px;
  }

  &__users {
    display: flex;
    align-items: center;

    &__user {
      border: 2px solid white;
      border-radius: 50%;

      &:not(:first-child) {
        margin-left: -8px;
      }
    }
  }
}

.stocktake__actions {
  display: flex;

  .select-supplier {
    :deep() .select-search__trigger {
      width: 100%;
      color: $color-gray-6C;
    }
  }
  :deep() .ez-select__dropdown {
    width: max-content;
  }
  :deep() .ez-select__display-container {
    height: 36px;
    svg {
      color: $color-gray-6C;
    }
  }

  .locations-filter {
    width: auto;
    :deep() .ez-select__display {
      padding-right: 12px;
    }
  }

  :deep() .dropdown-filters {
    .input-group {
      margin: 0;
    }

    .filters-area__row:not(:first-child) {
      justify-content: start;
    }
  }

  :deep() .ez-filter-list {
    &__items {
      margin: 0;
    }
  }

  .button-dropdown {
    margin-left: auto;
  }
}

.product-info {
  display: flex;
  flex-direction: column;

  &__name {
    color: $color-gray-25;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__sku {
    @include font-size(12px, 14px);
    color: $color-gray-6C;
    font-weight: 500;
  }
}
.unit-cell {
  overflow: hidden;
}
.additions-cell {
  width: 90px;
}
.difference-cell {
  .bold-text {
    font-weight: 600;
    color: $color-gray-25;
  }

  .difference {
    &--green {
      color: $color-primary-green;
    }

    &--red {
      color: $color-primary-red;
    }
  }
}

:deep() .table {
  tbody tr td.note-cell {
    overflow: visible;

    .dashed-underline {
      @include font-size(14px);
      font-weight: 400;
      border-bottom: 2px dotted rgba($color-gray-6C, 0.64);
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  tbody tr td.overflow-cell {
    overflow: visible;
  }

  th.price-cell.value-cell {
    text-align: left;
  }

  td.price-cell.value-cell {
    color: $color-gray-6C;
    font-weight: normal;
    text-align: left;

    .v-price--column {
      align-items: flex-start;
    }
  }
}

.v-product-quantity-inventory {
  &.dashed-underline {
    border-bottom: 2px dotted rgba($color-gray-6C, 0.64);
    @include font-size(14px);

    &--out-of-stock {
      border-color: rgba($color-primary-red, 0.64);
    }
  }

  &__tooltip {
    @include font-size(12px);

    .inventory-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 144px;
      margin-bottom: 6px;

      &--total {
        padding-top: 6px;
        border-top: 1px solid rgba(#fff, 0.2);
        margin-bottom: 0;
      }

      &__number {
        margin-left: 10px;
      }
    }
  }
}

.order-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button {
    margin-left: auto;
  }

  &__title {
    display: flex;
    h1 {
      @include font-size(24px);
      font-weight: 500;
      margin: 0;
    }

    small {
      @include font-size(12px);
      font-weight: 500;
      margin-top: 0.25rem;
      color: $color-gray-6C;
    }
  }

  &__stats {
    display: flex;
    align-items: center;
  }

  &__stat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 0.75em;
    margin-left: auto;
    align-self: center;

    small {
      @include font-size(12px);
      font-weight: 500;
      letter-spacing: 0.25px;
      color: $color-gray-6C;
    }

    span {
      @include font-size(20px);
      color: $color-gray-25;
      font-weight: 500;
    }

    &--amount {
      small {
        text-transform: uppercase;
      }
    }
  }
}

@media (max-width: 1440px) {
  .name-cell {
    width: 300px;
  }
}

@media (max-width: 1200px) {
  .unit-cell {
    display: none;
  }
}

@media (max-width: 1024px) {
  .counting-cell {
    display: none;
  }
  .additions-cell {
    display: none;
  }
}
</style>
