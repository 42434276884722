<script>
/**
 * VTag
 * @version 1.0.0
 * @since
 */

export default {
  props: {
    backgroundColor: {
      required: true,
      type: String,
    },
    color: {
      required: true,
      type: String,
    },
    canRemove: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      return {
        '--tag-color': this.color,
        '--tag-background-color': this.backgroundColor,
      };
    },
  },
};
</script>

<template>
  <span :style=style class="v-tag" v-on="$listeners">
    <span><slot/></span>
    <button
      v-if="canRemove"
    >
      <font-awesome-icon style="cursor: pointer;" icon="times" class="mr-0"/>
    </button>
  </span>
</template>

<style lang="scss" scoped>
.v-tag {
  display: inline-block;
  white-space: nowrap;
  @include font-size(11px);
  padding: 1px 6px;
  color: var(--tag-color);
  background-color: var(--tag-background-color);
  font-weight: 500;
  line-height: 14px;
  border-radius: 2px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  letter-spacing: 0.15px;
  cursor: pointer;

  button {
    margin-left: 6px;
  }
}
</style>
