<script>
/**
 * Integrations
 * @version 1.0.0
 * @since 2.4.0
 */

import VStatusBadge from '@/components/v3/elements/VStatusBadge/index';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { INTEGRATION_STATUSES, INTEGRATIONS_TYPES, LOADING_KEY } from '@/util/constants';
import logoXero from '@/assets/logo-xero.png';
import logoHubDoc from '@/assets/logo-hubdoc.svg';
import logoQB from '@/assets/logo-qb.png';
import VSwitchTabs from '@/components/v3/patterns/VSwitchTabs';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo';
import { EzTab } from '@/components/ui/Tabs';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import EzLoader from '@/components/ui/Loader/EzLoader';

export default {
  components: {
    VStatusBadge,
    EzEntityInfo,
    VSwitchTabs,
    EzTab,
    EzLoadMore,
    EzLoader,
  },
  data() {
    return {
      connectedData: [],
      connectedMeta: {},
      availableData: [],
      availableMeta: {},
      INTEGRATION_STATUSES,
    };
  },
  computed: {
    ...mapGetters('entities/users', [
      'getVenues',
      'isVenueXeroConnected',
      'isVenueHubDocConnected',
      'isVenueQuickBooksConnected',
    ]),
    ...mapGetters('entities/venues', [
      'isSelectedIntegrationConnected',
      'isSelectedIntegrationNeedToUpdate',
    ]),
    venueId() {
      return this.$route.params.id;
    },
    venue() {
      return this.getVenues.find(v => v.id === +this.venueId);
    },
    isXeroConnected() {
      return this.isVenueXeroConnected(this.venueId);
    },
    isQuickBooksConnected() {
      return this.isVenueQuickBooksConnected(this.venueId);
    },
    isHubDocConnected() {
      return this.isVenueHubDocConnected(this.venueId);
    },
    /**
     * If not connected navigate to not connected route.
     * If connected navigate to xero account ingo route.
     */
    xeroIntegrationRoute() {
      return this.isXeroConnected ? 'venue-xero-account' : 'venue-xero-not-connected';
    },
    quickBooksIntegrationRoute() {
      return this.isQuickBooksConnected
        ? 'venue-quick-books-account'
        : 'venue-quick-books-not-connected';
    },
    trayIntegrationRoute() {
      return { name: 'venue-tray-connect-or-enable', params: { id: this.venueId } };
    },
    integrations() {
      return [
        {
          icon: logoXero,
          title: this.isXeroConnected ? this.venue?.xero?.tenantName : this.$t('xero.name'),
          status: this.status(this.isXeroConnected),
          route: { name: this.xeroIntegrationRoute, params: { id: this.venueId } },
        },
        {
          icon: logoHubDoc,
          title: this.$t('hubDoc.name'),
          status: this.status(this.isHubDocConnected),
          route: { name: 'venue-hub-doc-account', params: { id: this.venueId } },
        },
        {
          icon: logoQB,
          title: this.isQuickBooksConnected
            ? this.venue?.quickBooks?.name
            : this.$t('quickBooks.name'),
          status: this.status(this.isQuickBooksConnected),
          route: { name: this.quickBooksIntegrationRoute, params: { id: this.venueId } },
        },
      ];
    },
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([LOADING_KEY.VENUE_GET_INTEGRATIONS]);
    },
  },
  methods: {
    ...mapActions('entities/venues', [
      'fetchVenue',
      'venueFetchAvailableIntegrations',
      'venueFetchConnectedIntegrations',
    ]),
    ...mapMutations('entities/venues', ['SET_SELECTED_INTEGRATION', 'CLEAR_SELECTED_INTEGRATION']),
    ...mapActions('entities/users', ['initVenue']),
    status(data) {
      return data ? INTEGRATION_STATUSES.connected : INTEGRATION_STATUSES.notConnected;
    },
    isConnected(data) {
      return data === INTEGRATION_STATUSES.connected;
    },
    isNotEnabled(data) {
      return data === INTEGRATION_STATUSES.notEnabled;
    },
    isUpdateAvailable(data) {
      return data === INTEGRATION_STATUSES.updateNeeded;
    },
    isTray(type) {
      return type === INTEGRATIONS_TYPES.default;
    },
    async fetchAvailableIntegrations() {
      const { data } = await this.venueFetchAvailableIntegrations({
        venueId: this.venueId,
        query: this.availableMeta?.hasNextPage ? this.availableMeta?.endCursor : '',
      });
      this.availableData = [
        ...this.availableData,
        ...this.integrations.filter(int => !this.isConnected(int.status)),
        ...data.data,
      ];
      this.availableMeta = data?.meta;
    },
    async fetchConnectedIntegrations() {
      const { data: connected } = await this.venueFetchConnectedIntegrations({
        venueId: this.venueId,
        query: this.connectedMeta?.hasNextPage ? this.connectedMeta?.endCursor : '',
      });
      this.connectedData = [
        ...this.connectedData,
        ...this.integrations.filter(int => this.isConnected(int.status)),
        ...connected.data,
      ];
      this.connectedMeta = connected?.meta;
    },
    whichRoute(item) {
      if (item.route) {
        return item.route;
      }
      if (this.isTray(item.type)) {
        return this.trayIntegrationRoute;
      }
      return '';
    },
    async navigate(integration) {
      await this.SET_SELECTED_INTEGRATION(integration);
      const route = this.whichRoute(integration);
      await this.$router.push(route);
    },
  },
  async created() {
    if (this.venueId) {
      this.fetchVenue(this.venueId);
    }
    await this.initVenue();
    this.availableData = this.integrations.filter(int => !this.isConnected(int.status));
    this.connectedData = this.integrations.filter(int => this.isConnected(int.status));
  },
};
</script>
<template>
  <div class="container--small">
    <div class="help-block mb-16">
      <h4 class="help-block__title">Connect to Other Platforms</h4>
      <p class="help-block__text">
        Connect to other platforms to keep track of and manage your inventory or invoices.
      </p>
    </div>
    <v-switch-tabs :defaultTabIndex="0">
      <ez-tab title="Connected" hash="tab-1">
        <div class="integrations-list">
          <div
            v-for="(connected, ind) in connectedData"
            :key="`${connected.title}${ind}`"
            class="integrations-list__item"
            @click="navigate(connected)"
          >
            <ez-entity-info :img-url="connected.icon" img-border-radius="50%">
              <div class="info-wrapper">
                <span class="ml-4 name">{{ connected.title }}</span>
              </div>
            </ez-entity-info>
            <div class="integrations-list__actions">
              <v-status-badge type="green" v-if="isConnected(connected.status)">
                {{ $t('integrations.actions.allSet') }}
              </v-status-badge>
              <v-status-badge type="orange" v-if="isNotEnabled(connected.status)">
                {{ $t('integrations.actions.actionNeeded') }}
              </v-status-badge>
              <v-status-badge type="blue" v-if="isUpdateAvailable(connected.status)">
                {{ $t('integrations.actions.actionUpdate') }}
              </v-status-badge>
            </div>
          </div>
          <ez-load-more
            v-if="connectedMeta && connectedMeta.hasNextPage && !isLoading"
            @loadMore="fetchConnectedIntegrations"
          />
        </div>
      </ez-tab>
      <ez-tab title="Available" hash="tab-2">
        <div class="integrations-list">
          <div
            v-for="(available, ind) in availableData"
            :key="`${available.title}${ind}`"
            class="integrations-list__item"
            @click="navigate(available)"
          >
            <ez-entity-info :img-url="available.icon" img-border-radius="50%">
              <div class="info-wrapper">
                <span class="ml-4 name name--available">{{ available.title }}</span>
              </div>
            </ez-entity-info>
          </div>
          <ez-load-more
            v-if="availableMeta && availableMeta.hasNextPage && !isLoading"
            @loadMore="fetchAvailableIntegrations"
          />
        </div>
      </ez-tab>
    </v-switch-tabs>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>
<style lang="scss" scoped>
.integrations-list {
  margin-top: 36px;
  .label {
    @include font-size(12px, 16px);
    font-weight: bold;
    color: $color-gray-6C;
    margin: 24px 0 16px 0;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    border-bottom: 1px solid #e9ebf2;
    text-decoration: none;
    cursor: pointer;
    .info-wrapper {
      display: flex;
      flex-direction: column;
      color: $color-gray-25;
      width: 100%;
      .name {
        @include font-size(14px, 22px);
        font-weight: bold;
        max-width: 200px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-x: hidden;
        &--available {
          max-width: 100%;
        }
      }
    }
  }

  &__text {
    @extend %flex-center;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    img {
      border: 1px solid #e1e5ed;
      border-radius: 50%;
    }
  }
}

.xero-description p + p {
  margin-top: 32px;
}
</style>
