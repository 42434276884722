var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"u-flex-row u-flex-center"},[(_vm.accountOwner)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: _vm.toShowName ? '' : _vm.content,
      classes: ['tooltip-general'],
      placement: 'start',
    }),expression:"{\n      content: toShowName ? '' : content,\n      classes: ['tooltip-general'],\n      placement: 'start',\n    }"}],staticClass:"account-owner__badge",style:({
      color: _vm.color,
      backgroundColor: _vm.backgroundColor,
    })},[(_vm.accountOwner.initials)?_c('span',{attrs:{"data-cy":`${_vm.dataCy}-initials`}},[_vm._v(" "+_vm._s(_vm.accountOwner.initials)+" ")]):_c('span',{staticClass:"account-owner__user-icon"},[_c('font-awesome-icon',{attrs:{"icon":"user"}})],1)]):_vm._e(),(_vm.toShowName)?_vm._t("name",function(){return [_c('span',{staticClass:"account-owner__name ml-4",attrs:{"data-cy":`${_vm.dataCy}-name`}},[_vm._v(_vm._s(_vm.accountOwner.name))])]}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }