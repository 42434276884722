<script>
import EzButton from '@/components/ui/Button/EzButton';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  components: {
    EzButton,
  },
  data() {
    return {
      commonCy,
    };
  },
  props: {
    disabled: {
      required: false,
      type: Boolean,
    },
    type: {
      required: false,
      type: String,
      default: 'primary',
    },
  },
  computed: {
    isPrimary() {
      return this.type === 'primary';
    },
  },
};
</script>
<template>
  <ez-button
    :type="type"
    :disabled="disabled"
    @click="$emit('actionClicked')"
    :data-cy="commonCy.SINGLE_ORDER.MANAGE_ORDER.BUTTON__APPROVE"
  >
    {{ isPrimary ? 'Approve Order' : 'Approve' }}
  </ez-button>
</template>
<style lang="scss" scoped></style>
