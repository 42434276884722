<script>
import {
  Abandon,
  Accept,
  Approve,
  Complete,
  Decline,
  Dispute,
  Reorder,
  Resolve,
  ScheduleForDelivery,
  Receive,
  Cancel,
  EditOrder,
  AddCreditNote,
  ConvertToOrder,
  CreateFromDraft,
} from '@/views/common/orders/orderActions/index';
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown';
import { EzButtonGroup } from '@/components/ui/Button';
import EzButton from '@/components/ui/Button/EzButton';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import { ORDER_STATUS_QUOTE } from '@/util/constants';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  components: {
    EzButton,
    EzSimpleDropdown,
    EzButtonGroup,
  },
  props: {
    hasGreaterThenTwoActions: {
      required: true,
      type: Boolean,
    },
    order: {
      type: Object,
      required: true,
    },
    areAllProductsDeclined: {
      type: Boolean,
      required: true,
    },
    areSomeProductsDeclined: {
      type: Boolean,
      required: true,
    },
    editingMode: {
      type: Boolean,
      required: false,
    },
    toDisableSaveChanges: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * Forced disabled actions.
     * This is an array of objects, each object must have
     * btnType (look at the key in array of actions in response from BE)
     * and value (true or false)
     */
    forceDisable: {
      type: Array, // [{ btnType: 'schedule_delivery', value: true }]
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      firstAction: [],
      restActions: [],
      commonCy,
    };
  },
  computed: {
    actionsComponent() {
      return {
        abandon: Abandon,
        complete: Complete,
        dispute: Dispute,
        reorder: Reorder,
        resolve_dispute: Resolve,
        decline: Decline,
        approve: Approve,
        accept: Accept,
        schedule_delivery: ScheduleForDelivery,
        cancel: Cancel,
        edit: EditOrder,
        add_credit_note: AddCreditNote,
        convert_to_order: ConvertToOrder,
        receive: Receive,
        create_from_draft: CreateFromDraft,
      };
    },
    hasLabel() {
      return this.restActions.length > 2;
    },
    isQuoteStatus() {
      return this.order.status === ORDER_STATUS_QUOTE;
    },
    hasCopyLink() {
      return this.order.copyUrl;
    },
  },
  methods: {
    initActions() {
      if (this.hasGreaterThenTwoActions) {
        const [first, ...rest] = this.order.actions;
        this.firstAction = first;
        this.restActions = rest;
        return;
      }
      const { actions } = this.order;
      this.restActions = actions;
    },
    isActionDisabled(action) {
      const { isPartial } = this.order?.actions?.find(aObj => aObj.key === action) || {};
      return (!isPartial && this.areSomeProductsDeclined) || this.areAllProductsDeclined;
    },
    onActionClicked(action) {
      if (action === 'resolve_dispute') {
        this.$emit('resolve');
      } else if (action === 'schedule_delivery') {
        this.$emit('schedule');
      } else if (action === 'add_credit_note') {
        this.$emit('addCreditNote');
      } else if (action === 'convert_to_order') {
        this.$emit('convertToOrder');
      } else if (action === 'create_from_draft') {
        this.$emit('createFromDraft');
      } else {
        this.$emit(action);
      }
    },
    isForceDisabled(actionKey) {
      const tst = this.forceDisable.find(fd => fd.btnType === actionKey);
      const val = tst?.value;
      return tst ? val : false;
    },
    onLinkClicked() {
      this.$emit('copyToClipboard');
    },
  },
  mounted() {
    this.initActions();
  },
};
</script>
<template>
  <!-- Editing mode buttons -->
  <ez-button-group v-if="editingMode">
    <ez-button
      :disabled="toDisableSaveChanges"
      @click="$emit('saveEdit')"
      :data-cy="commonCy.SINGLE_ORDER.BUTTON__EDIT_SAVE"
    >
      Save Changes
    </ez-button>
    <ez-button
      type="secondary"
      @click="$emit('discardEdit')"
      :data-cy="commonCy.SINGLE_ORDER.BUTTON__EDIT_DISCARD"
    >
      Discard
    </ez-button>
  </ez-button-group>
  <ez-button-group v-else>
    <template v-if="!hasGreaterThenTwoActions">
      <component
        @actionClicked="onActionClicked(action.key)"
        :disabled="isActionDisabled(action.key) || isForceDisabled(action.key)"
        v-for="(action, ind) in restActions"
        :type="ind > 0 ? 'secondary' : 'primary'"
        :key="action.key"
        :is="actionsComponent[action.key]"
        :is-quote="isQuoteStatus"
      />
    </template>
    <template v-else>
      <component
        @actionClicked="onActionClicked(firstAction.key)"
        :disabled="isActionDisabled(firstAction.key) || isForceDisabled(firstAction.key)"
        :is="actionsComponent[firstAction.key]"
        :is-quote="isQuoteStatus"
      />
      <ez-simple-dropdown
        :data-cy="commonCy.SINGLE_ORDER.BUTTON__MANAGE_ORDER"
        placeholder="Manage"
        direction="up"
        class="manage-orders"
      >
        <template #dropdown>
          <div class="dropdown-actions">
            <div v-if="hasLabel || isQuoteStatus" class="dropdown-actions__label">STATUS</div>
            <component
              @actionClicked="onActionClicked(action.key)"
              :disabled="isActionDisabled(action.key) || isForceDisabled(action.key)"
              v-for="action in restActions"
              :is="actionsComponent[action.key]"
              type="link"
              :key="action.key"
              :is-quote="isQuoteStatus"
            />
          </div>
        </template>
      </ez-simple-dropdown>
    </template>
    <ez-button v-if="hasCopyLink" type="rounded" @click="onLinkClicked">
      <font-awesome-icon icon="link" />
    </ez-button>
  </ez-button-group>
</template>
<style lang="scss" scoped>
.manage-orders {
  display: inline-block;
  width: auto;

  :deep() .ez-simple-dropdown {
    &__label {
      padding-right: 15px;
    }

    &__dropdown {
      min-width: 192px;
      width: fit-content;
    }
  }

  .dropdown-actions {
    width: 100%;

    &__btn {
      text-align: left;
      padding: 0 8px;
      opacity: 0.8;
      width: 100%;
      font-weight: 400;
      white-space: nowrap;

      &--complete {
        color: $primary-color;
      }

      &:disabled {
        background-color: transparent;
        opacity: 0.48;
        color: #6c7995;

        &:hover {
          opacity: 0.48;
        }
      }

      &--decline,
      &--dispute {
        color: $color-primary-red;

        &:disabled {
          color: $color-primary-red;
        }
      }

      &:hover {
        opacity: 1;
      }
    }
    &__label {
      @include font-size(11px, 14px);
      letter-spacing: 0.15px;
      color: $color-gray-6C;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 8px 8px 0;
      font-weight: 600;
    }
  }

  :deep() .ez-simple-dropdown {
    &__label {
      color: #6c7995;
    }
  }
}
</style>
