<script>
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown';
import StatusBadge from '@/views/common/status-badge/StatusBadge';

/**
 * Status Dropdown
 * @version 1.0.0
 * @since 2.4.0
 */

export default {
  components: {
    StatusBadge,
    EzSimpleDropdown,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
      required: true,
    },
    direction: {
      type: String,
      default: 'down',
      required: false,
      validator: value => ['up', 'down'].indexOf(value) !== -1,
    },
  },
  computed: {
    active() {
      return this.data.find(d => d.selected) || {};
    },
  },
  methods: {
    click(status) {
      if (!status.selected) {
        this.$emit('change', `${status.name}`);
      }
    },
  },
};
</script>
<template>
  <ez-simple-dropdown class="status-dropdown" :direction="direction" :stop-propagation="true">
    <template #display>
      <status-badge :status="active.name || 'assign'">
        <template #postfix><font-awesome-icon icon="angle-down" /></template>
      </status-badge>
    </template>
    <template #dropdown>
      <ul>
        <li v-for="status in data" :key="status.id" @click="click(status)">
          <status-badge :status="status.name" />
        </li>
      </ul>
    </template>
  </ez-simple-dropdown>
</template>
<style lang="scss" scoped>
// Reset the dropdown properties until we have a renderless dropdown.
.status-dropdown {
  line-height: 1;
  padding: 0;

  :deep() .ez-simple-dropdown__display-container {
    display: inline-block;
    padding: 0;
    background-color: transparent !important;
  }

  ul {
    @extend %ul-reset;
  }

  li {
    padding: 10px 8px;
  }
}
</style>
