<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button/EzButton';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzInput from '@/components/ui/Input';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput';
import RemoveGroupModal from '@/components/v3/elements/VProductGroupSettings/RemoveGroupModal.vue';
import AddGroupModal from '@/components/v3/elements/VProductGroupSettings/AddGroupModal.vue';
import VSelectSearchProductGroup from '@/components/v3/elements/VProductGroupSettings/VSelectSearchProductGroup.vue';
import flash from '@/components/ui/FlashMessage';
import { LOADING_KEY } from '@/util/constants';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'EzProductsUpdateModal',
  components: {
    EzConfirmationModal,
    EzButton,
    EzDropdown,
    EzCheckbox,
    EzInput,
    EzMaskInput,
    RemoveGroupModal,
    AddGroupModal,
    VSelectSearchProductGroup,
  },
  props: {
    productGroups: {
      type: Array,
      required: true,
      default: () => [],
    },
    hasNotifyPriceChange: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasVisibility: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasTracking: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasPricePerUnit: {
      type: Boolean,
      required: false,
      default: true,
    },
    numberOfProducts: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formKey: 'updateProducts',
      isProductGroupChecked: false,
      isNotifyPriceChangeChecked: false,
      isVisibilityChecked: false,
      isTrackingChecked: false,
      isPricePerUnitChecked: false,
      isMostRecentPriceChecked: false,
      productGroup: null,
      visibility: null,
      pricePerUnit: null,
      notifyPriceChange: null,
      visibilityOptions: [
        {
          id: 1,
          name: 'Visible',
          value: true,
        },
        {
          id: 2,
          name: 'Hidden',
          value: false,
        },
      ],
      trackingOptions: [
        {
          id: 1,
          name: 'Tracked',
          value: true,
        },
        {
          id: 2,
          name: 'Not Tracked',
          value: false,
        },
        {
          id: 3,
          name: 'Unassigned',
          value: null,
        },
      ],
      visibilityOptionsGroupView: [
        {
          id: 1,
          name: 'All',
          value: true,
        },
        {
          id: 2,
          name: 'None',
          value: false,
        },
      ],
      config: {},
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['getLoading']),
    isBulkUpdateLoading() {
      return this.getLoading(LOADING_KEY.VENUE_BULK_UPDATE_SUPPLIERS_PRODUCTS);
    },
    isDisabled() {
      return (
        !this.isProductGroupChecked &&
        !(this.hasNotifyPriceChange && this.isNotifyPriceChangeChecked) &&
        !(this.hasVisibility && this.isVisibilityChecked) &&
        !(this.hasTracking && this.isTrackingChecked) &&
        !(this.hasPricePerUnit && this.isPricePerUnitChecked)
      );
    },
    productGroupsOptions() {
      return [{ id: null, name: 'Unassigned' }, ...this.productGroups];
    },
    groupViewMode() {
      if (this.loggedUser.context === 'venue-group') return true;
      return false;
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'venueCreateProductGroup',
      'venueUpdateProductGroup',
      'venueDeleteProductGroup',
    ]),
    onClose() {
      this.isProductGroupChecked = false;
      this.isNotifyPriceChangeChecked = false;
      this.isVisibilityChecked = false;
      this.isTrackingChecked = false;
      this.isPricePerUnitChecked = false;
      this.isMostRecentPriceChecked = false;
      this.productGroup = null;
      this.visibility = null;
      this.pricePerUnit = null;
      this.notifyPriceChange = null;
      this.config = {};
      this.$refs.productGroupsDropdown.reset();
    },
    open() {
      this.$refs.updateModal.open();
    },
    close() {
      this.$refs.updateModal.close();
    },
    onNotifyCheckboxChange(ev) {
      this.isNotifyPriceChangeChecked = ev;
      if (!ev) {
        this.notifyPriceChange = null;
        delete this.config.priceChangeNotificationPercent;
      }
    },
    onVisibilityCheckboxChange(ev) {
      this.isVisibilityChecked = ev;
      if (!ev) {
        this.$refs.visibilityDropdown.reset();
        delete this.config.visibility;
      }
    },
    onTrackingCheckboxChange(ev) {
      this.isTrackingChecked = ev;
      if (!ev) {
        this.$refs.trackDropdown.reset();
        delete this.config.venueInventoryTracked;
      }
    },
    onVisibilityDropdownChange(ev) {
      this.visibility = ev;
    },
    onTrackingDropdownChange(ev) {
      this.venueInventoryTracked = ev;
    },
    onPriceCheckboxChange(ev) {
      this.isPricePerUnitChecked = ev;
    },
    onMostRecentPriceCheckboxChange(ev) {
      this.isMostRecentPriceChecked = ev;
    },
    toggleProductGroup(ev) {
      this.isProductGroupChecked = ev;
      if (!ev) {
        this.$refs.productGroupsDropdown.reset();
        this.productGroup = null;
        delete this.config.productGroupId;
      }
    },
    onNotifyPriceInputChange(val) {
      this.notifyPriceChange = val;
    },
    onPricePerUnitInputChange(val) {
      this.pricePerUnit = val;
    },
    productGroupChanged(ev) {
      this.productGroup = ev;
      this.config = {
        ...this.config,
        productGroupId: this.productGroup.id,
      };
    },
    update() {
      this.config = {
        ...this.config,
        ...(this.isProductGroupChecked && this.productGroup
          ? { productGroupId: this.productGroup.id }
          : {}),
        ...(this.isNotifyPriceChangeChecked && this.notifyPriceChange
          ? { priceChangeNotificationPercent: this.notifyPriceChange }
          : {}),
        ...(this.isVisibilityChecked && this.visibility
          ? { visibility: this.visibility.value }
          : {}),
        ...(this.isTrackingChecked && this.venueInventoryTracked
          ? { venueInventoryTracked: this.venueInventoryTracked.value }
          : {}),
        ...(this.isPricePerUnitChecked && !this.isMostRecentPriceChecked && this.pricePerUnit
          ? { price: this.pricePerUnit, mostRecentPrice: false }
          : {}),
        ...(this.isPricePerUnitChecked && this.isMostRecentPriceChecked
          ? { mostRecentPrice: true }
          : {}),
      };
      this.$emit('update', this.config);
    },
    onAddGroup() {
      this.$refs.addGroupModal.open();
    },
    onEdit(group) {
      this.$refs.addGroupModal.open(group, true);
    },
    onRemove(group) {
      this.$refs.removeGroupModal.open(group);
    },
    async onAddConfirm({ groupId, name }) {
      const isEdit = !!groupId;
      try {
        if (isEdit) {
          const { data } = await this.venueUpdateProductGroup({
            id: groupId,
            name,
            formKey: 'createProductGroup',
          });
          this.$emit('editProductGroup', data.data);
          if (this.productGroup?.id === groupId) this.productGroup.name = name;
        } else {
          const { data } = await this.venueCreateProductGroup({
            name,
            formKey: 'createProductGroup',
          });
          this.$emit('addProductGroup', data.data);
          this.productGroup = data.data;
        }

        flash.success({ title: `Product group successfully ${isEdit ? 'updated' : 'created'}!` });
        this.$refs.addGroupModal.close();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        flash.error({ title: 'Something went wrong!' });
      }
    },
    async onRemoveConfirm(groupId) {
      try {
        await this.venueDeleteProductGroup({ id: groupId });
        if (this.productGroup?.id === groupId) {
          this.productGroup = null;
          this.$refs.productGroupsDropdown.reset();
          delete this.config.productGroupId;
        }
        this.$emit('removeProductGroup', groupId);

        flash.success({ title: 'Product group successfully removed!' });
        this.$refs.removeGroupModal.close();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        flash.error({ title: 'Something went wrong!' });
      }
    },
  },
};
</script>

<template>
  <div>
    <ez-confirmation-modal @close="onClose" ref="updateModal" :icon="null">
      <template #title>Edit product{{ numberOfProducts > 1 ? 's' : '' }}?</template>
      <template #content>
        <p>
          Choose what information you would like to edit for selected product{{
            numberOfProducts > 1 ? 's' : ''
          }}.
        </p>
        <hr />
        <ez-checkbox
          label="Product Group"
          class="cursor-pointer mb-12"
          :checked="isProductGroupChecked"
          @change="toggleProductGroup"
        />
        <v-select-search-product-group
          ref="productGroupsDropdown"
          class="mb-20"
          :data="productGroupsOptions"
          placeholder="Select Group"
          :has-actions="$permission.has('manageProductGroups')"
          searchProperty="name"
          :selectedId="productGroup?.id"
          @select="productGroupChanged"
          @addGroup="onAddGroup"
          @edit="onEdit"
          @remove="onRemove"
          isFullWidth
          :disabled="!isProductGroupChecked"
          :formKey="formKey"
        />
        <ez-checkbox
          v-if="hasNotifyPriceChange"
          label="Notify Price Change"
          class="cursor-pointer mb-12"
          :checked="isNotifyPriceChangeChecked"
          @change="onNotifyCheckboxChange"
        />
        <ez-input
          :disabled="!(hasNotifyPriceChange && isNotifyPriceChangeChecked)"
          :formKey="formKey"
          name="notifyPriceChange"
          :value="notifyPriceChange"
          @onChange="onNotifyPriceInputChange"
          type="number"
          min="0"
          max="100"
          class="mb-20"
        >
          <template #prefix>%</template>
        </ez-input>
        <ez-checkbox
          v-if="hasVisibility"
          label="Visibility"
          class="cursor-pointer mb-12"
          :checked="isVisibilityChecked"
          @change="onVisibilityCheckboxChange"
        />
        <ez-dropdown
          ref="visibilityDropdown"
          :disabled="!(hasVisibility && isVisibilityChecked)"
          name="visibility"
          class="mb-20"
          :data="groupViewMode ? visibilityOptionsGroupView : visibilityOptions"
          is-full-width
          :formKey="formKey"
          placeholder="Select Visibility"
          @change="onVisibilityDropdownChange"
        />
        <ez-checkbox
          v-if="hasPricePerUnit"
          label="Price per Unit"
          class="cursor-pointer mb-12"
          :checked="isPricePerUnitChecked"
          @change="onPriceCheckboxChange"
        />
        <ez-mask-input
          :form-key="formKey"
          name="pricePerUnit"
          class="mask-input mb-8"
          :value="pricePerUnit || 0"
          type="input"
          @input="onPricePerUnitInputChange"
          :disabled="!(hasPricePerUnit && isPricePerUnitChecked) || isMostRecentPriceChecked"
        />
        <ez-checkbox
          :disabled="!(hasPricePerUnit && isPricePerUnitChecked)"
          label="Update to most recent purchase price"
          class="cursor-pointer mb-20"
          :checked="isMostRecentPriceChecked"
          @change="onMostRecentPriceCheckboxChange"
        />
        <ez-checkbox
          v-if="hasTracking"
          label="Track"
          class="cursor-pointer mb-12"
          :checked="isTrackingChecked"
          @change="onTrackingCheckboxChange"
        />
        <ez-dropdown
          v-if="hasTracking"
          ref="trackDropdown"
          :disabled="!(hasTracking && isTrackingChecked)"
          name="track"
          class="mb-20"
          :data="trackingOptions"
          is-full-width
          :formKey="formKey"
          placeholder="Select Tracking"
          @change="onTrackingDropdownChange"
        />
      </template>
      <template #footer>
        <ez-button @click="close" type="link">Cancel</ez-button>
        <ez-button :disabled="isDisabled" @click="update" :is-loading="isBulkUpdateLoading"
          >Update Products</ez-button
        >
      </template>
    </ez-confirmation-modal>
    <remove-group-modal ref="removeGroupModal" @remove="onRemoveConfirm" />
    <add-group-modal ref="addGroupModal" @confirm="onAddConfirm" />
  </div>
</template>

<style lang="scss" scoped>
.mb-20 {
  margin-bottom: 20px;
}

:deep() .v-money.mask-input__input {
  color: $color-gray-6C;
  font-weight: normal;
}

:deep()
  .select-search.select-search--disabled
  .select-search__wrapper--disabled
  .select-search__trigger
  span {
  color: $input-disabled-color;
}
</style>
