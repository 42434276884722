<template>
  <ez-confirmation-modal ref="resolveModal">
    <template #title>
      <template v-if="order.notMatching">Resolve Order?</template>
      <template v-else>Resolve Dispute?</template>
    </template>
    <template #content>
      <p v-if="order.notMatching">
        Make sure you have settled this order in accordance with the invoice attached.
      </p>
      <p v-else>
        When you resolve the dispute, you will not be able to open it again. Make sure you have
        settled everything about this order with the {{ $t('global.distributor') }} before resolving
        it.
      </p>
    </template>
    <template #footer>
      <ez-button
        @click="close"
        type="link"
        :data-cy="outletCy.ORDERS.SINGLE_ORDER.RESOLVE_ORDER_MODAL.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        @click="resolve"
        :is-loading="isLoadingResolve"
        :data-cy="outletCy.ORDERS.SINGLE_ORDER.RESOLVE_ORDER_MODAL.BUTTON__RESOLVE"
      >
        Resolve
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import flash from '@/components/ui/FlashMessage';
import { LOADING_KEY } from '@/util/constants';

/**
 * ResolveOrder
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    EzButton,
    EzConfirmationModal,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      outletCy,
    };
  },
  computed: {
    excludedProductsIds() {
      return (this.order.orderedProducts || []).filter(p => p.isDeclined).map(a => a.id);
    },
    ...mapGetters('loading', ['getLoading']),
    isLoadingResolve() {
      return this.getLoading(LOADING_KEY.RESOLVE_DISPUTE);
    },
  },
  methods: {
    ...mapActions('entities/orders', ['resolveDispute']),
    open() { this.$refs.resolveModal.open(); },
    close() { this.$refs.resolveModal.close(); },
    async resolve() {
      await this.resolveDispute({
        id: this.order.id,
        resolveData: {
          declinedProducts: this.excludedProductsIds,
        },
      });
      this.close();
      this.$emit('success');

      const { href } = this.$router.resolve({ name: 'venue-orders-history', params: { id: this.order.id } });

      if (this.order.notMatching) {
        flash.success({
          title: 'Order resolved.',
          message: 'You have successfully resolved the completed order.',
          ctas: `<a href="${href}">Go to Order Overview</a>`,
        });
      } else {
        flash.success({
          title: 'Dispute resolved!',
          message: 'Great! You can go to order overview to see detailed info about the order.',
          ctas: `<a href="${href}">Go to Order Overview</a>`,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
