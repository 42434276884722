<script>
import EzTable from '@/components/ui/Table/EzTable';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo';
import EzButton from '@/components/ui/Button/EzButton';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';

/**
 *
 * @version 1.0.0
 * @since 3.16.0
 */
export default {
  name: 'index',
  components: {
    EzTable,
    EzEntityInfo,
    EzButton,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      commonCy,
    };
  },
  methods: {
    goToExternalLink(item) {
      window.open(item.url, '_blank');
    },
  },
};
</script>

<template>
  <div class="linked-platforms">
    <ez-table
      :data="data"
      :columns="['orderNumber', 'name', 'actions']"
      :headers="{
        orderNumber: () => 'Order No.',
        name: () => 'External Platforms',
        actions: () => '',
      }"
      :columnProps="{
        orderNumber: { class: 'medium-cell' },
        actions: { class: 'large-cell' },
      }"
      disable-hover
    >
      <template #cell-orderNumber="{ row }">
        <span class="order-number">{{ row.orderNumber }}</span>
      </template>

      <template #cell-name="{ row }">
        <ez-entity-info
          img-width="2rem"
          img-height="2rem"
          img-border-radius="50%"
          :imgUrl="row.logo"
        >
          <div class="integration-info" :title="row.name">
            <span>{{ row.name }}</span>
          </div>
        </ez-entity-info>
      </template>

      <template #cell-actions="{ row }">
        <ez-button
          type="secondary"
          @click="goToExternalLink(row)"
          :data-cy="commonCy.SINGLE_ORDER.BUTTON__VIEW_ORDER"
        >
          View Order
        </ez-button>
      </template>
    </ez-table>
  </div>
</template>

<style lang="scss" scoped>
.linked-platforms {
  .integration-info {
    span {
      color: $color-gray-25;
      @include font-size(14px, 22px);
    }
  }
  .order-number {
    @include font-size(14px, 20px);
  }
}
</style>
