<template>
  <div
    :class="{ 'image-wrapper': true, 'image-wrapper--controls': controls }"
    :id="`ID${uuid}`"
    @dblclick="onDoubleClick"
    @wheel.prevent="zoomOnScroll"
    @mousedown="moveDocument"
  >
    <img :ref="file.name" :src="file.image" :alt="file.name" />
    <div class="controls" v-if="controls">
      <ez-button type="secondary" @click.prevent="zoomIn">+</ez-button>
      <ez-button type="secondary" @click.prevent="zoomOut" :disabled="scale <= 0.25">-</ez-button>
      <ez-button type="secondary" @click.prevent="rotateBy(-90)">
        <font-awesome-icon icon="undo"></font-awesome-icon>
      </ez-button>
      <ez-button type="secondary" @click.prevent="rotateBy(+90)">
        <font-awesome-icon icon="undo"></font-awesome-icon>
      </ez-button>
    </div>
  </div>
</template>

<script>
import EzButton from '@/components/ui/Button/EzButton.vue';
import uuid from 'uuid/v4';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'VImageViewer',
  components: {
    EzButton,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    controls: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      scale: 1,
      rotate: 0,
      translate: [0, 0, 0],
      zoomedIn: false,
      left: 0,
      top: 0,
      uuid: '',
    };
  },
  methods: {
    zoom(zoomBy) {
      if ((this.scale > 0.2 && zoomBy < 0) || zoomBy > 0) this.scale += zoomBy;
      const image = this.$refs[this.file.name];
      image.style.transformOrigin = 'top left';
      image.style.transform = `scale(${this.scale}) rotate(${
        this.rotate
      }deg) translate3d(${this.translate.join(',')})`;
    },
    rotateBy(deg) {
      const image = this.$refs[this.file.name];
      this.rotate += deg;
      if (this.rotate > 360) this.rotate -= 360;
      if (this.rotate < -360) this.rotate += 360;
      if (this.rotate === 90 || this.rotate === -270) {
        this.translate[0] = 0;
        this.translate[1] = '-100%';
      }
      if (this.rotate === 180 || this.rotate === -180) {
        this.translate[0] = '-100%';
        this.translate[1] = '-100%';
      }
      if (this.rotate === 270 || this.rotate === -90) {
        this.translate[0] = '-100%';
        this.translate[1] = 0;
      }
      if (this.rotate === 360 || this.rotate === 0 || this.rotate === -360) {
        this.translate[0] = 0;
        this.translate[1] = 0;
      }

      image.style.transformOrigin = 'top left';
      image.style.transform = `scale(${this.scale}) rotate(${
        this.rotate
      }deg) translate3d(${this.translate.join(',')})`;
    },
    zoomIn() {
      this.zoom(0.25);
    },
    zoomOut() {
      this.zoom(-0.25);
    },
    onDoubleClick() {
      if (this.controls) {
        if (!this.zoomedIn) this.zoom(0.5);
        else this.zoom(-0.5);
        this.zoomedIn = !this.zoomedIn;
      }
    },
    zoomOnScroll(event) {
      if (this.controls) {
        if (event.deltaY < 0) this.zoom(0.05);
        else if (event.deltaY > 0) this.zoom(-0.05);
      }
    },
    mouseMoveWhilstDown() {
      const target = document.querySelector(`#ID${this.uuid}`);
      if (target) {
        const endMove = () => {
          target.removeEventListener('mousemove', this.moveDocument);
          target.removeEventListener('mouseup', endMove);
        };
        target.addEventListener('mousedown', event => {
          event.stopPropagation();
          event.preventDefault();
          target.addEventListener('mousemove', this.moveDocument);
          target.addEventListener('mouseup', endMove);
        });
      }
    },
    moveDocument(event) {
      event.preventDefault();
      event.stopPropagation();
      const scrollable = document.querySelector(`#ID${this.uuid}`);
      if (scrollable) {
        scrollable.scrollBy({
          top: -event.movementY,
          left: -event.movementX,
        });
      }
    },
  },
  mounted() {
    if (this.controls) this.mouseMoveWhilstDown();
  },
  created() {
    this.uuid = uuid();
  },
};
</script>

<style scoped lang="scss">
.image-wrapper {
  overflow-x: auto;
  overflow-y: auto;
  height: 100%;

  &--controls {
    padding-top: 44px;
  }

  img {
    width: 100%;
    cursor: move;
  }
}

.controls {
  display: block;
  position: absolute;
  top: 4px;
  padding-left: 4px;

  button svg {
    margin-right: 0;
  }
  button:nth-child(4) {
    transform: rotateY(180deg);
  }

  :deep() button.button {
    border-radius: 0;
    &:first-child,
    &:last-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
}
</style>
