import store from '@/store';
import products from '@/store/modules/products';
import flash from '@/components/ui/FlashMessage';
import { isAdmin } from '@/util/constants';

const { state, getters } = store;
const { actions } = products;

async function getStoreData() {
  const isDistributor = getters['entities/users/isDistributor'];
  const isVenue = getters['entities/users/isVenue'];
  const { context, entities } = state;
  const { contextId } = entities.users;

  return { isDistributor, isVenue, context, contextId };
}

function successEditMessage() {
  flash.success({
    title: 'Unit successfully edited',
  });
}

function successCreateMessage() {
  flash.success({
    title: 'Unit successfully added',
  });
}

function successDeleteMessage() {
  flash.success({
    title: 'Unit successfully deleted',
  });
}

export async function editUnit(data) {
  const { isDistributor, isVenue, context, contextId } = await getStoreData();
  try {
    let res;
    if (isDistributor) {
      res = await actions.distributorUpdateUnit(context, data);
    } else if (isVenue) {
      res = await actions.venueUpdateUnit(context, { ...data, venueId: contextId });
    } else if (isAdmin()) {
      res = await actions.adminUpdateUnit(context, data);
    } else {
      throw new Error('Fn: editUnit - Not implemented for this context.');
    }
    successEditMessage();
    return { message: 'success', code: res?.status };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return { message: 'error', code: e?.status };
  }
}

export async function createUnit(data) {
  const { isDistributor, isVenue, context, contextId } = await getStoreData();
  try {
    let res;
    if (isDistributor) {
      res = await actions.distributorCreateUnit(context, data);
    } else if (isVenue) {
      res = await actions.venueCreateUnit(context, { ...data, venueId: contextId });
    } else if (isAdmin()) {
      res = await actions.adminCreateUnit(context, data);
    } else {
      throw new Error('Fn: createUnit - Not implemented for this context.');
    }
    successCreateMessage();
    return { message: 'success', code: res?.status };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return { message: 'error', code: e?.status };
  }
}

export async function deleteUnit(data) {
  const { isDistributor, isVenue, context, contextId } = await getStoreData();
  try {
    if (isDistributor) {
      await actions.distributorDeleteUnit(context, data);
    } else if (isVenue) {
      await actions.venueDeleteUnit(context, { ...data, venueId: contextId });
    } else if (isAdmin()) {
      await actions.adminDeleteUnit(context, data);
    } else {
      throw new Error('Fn: deleteUnit - Not implemented for this context.');
    }
    successDeleteMessage();
    return { message: 'success' };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    return e;
  }
}
