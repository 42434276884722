<template>
  <ez-confirmation-modal ref="declineOrder" type="red">
    <template #title>Decline Order?</template>
    <template #content>
      <p>Order will be declined completely.</p>
    </template>
    <template #footer>
      <ez-button @click="close" type="link">Cancel</ez-button>
      <ez-button @click="decline" type="red" :is-loading="isLoading">Decline Order</ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import flash from '@/components/ui/FlashMessage';
import { LOADING_KEY } from '@/util/constants';
/**
 * Decline Order
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    EzConfirmationModal,
    EzButton,
  },
  props: {
    venueId: {
      type: Number,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formKey: 'decline',
    };
  },
  computed: {
    action() { return `venues/${this.venueId}/orders/${this.orderId}/dispute`; },
    hasNotes() {
      return (this.order.notes || [])
        .filter(n => !!n.message || (n.images || []).length).length;
    },
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.DECLINE_ORDER);
    },
  },
  methods: {
    ...mapActions('entities/orders', ['declineEntireOrder']),
    ...mapActions('entities/users', ['updateVenueContext']),
    open() { this.$refs.declineOrder.open(); },
    close() { this.$refs.declineOrder.close(); },
    async decline() {
      await this.declineEntireOrder({ id: this.order.id });
      this.close();
      this.$emit('success');

      flash.error({
        title: 'Order declined.',
      });
      this.updateVenueContext();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
