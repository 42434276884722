<script>
/**
   * index
   * @version 1.0.0
   * @since
   */

export default {};
</script>
<template>
  <div class="stats-box">
    <div class="stats-box__label"><slot name="label"/></div>
    <div class="stats-box__value"><slot name="value"/></div>
  </div>
</template>
<style lang="scss" scoped>
.stats-box {
  padding: 18px 12px;
  background-color: #F5F6FA;
  border-radius: $border-radius;
  text-align: center;

  &__label {
    font-size: 12px;
    letter-spacing: -0.25px;
    line-height: 14px;
    margin-bottom: 8px;
  }

  &__value {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: -1px;
  }
}
</style>
