<script>
/**
 * NewDistributorUsers
 * @version 1.0.0
 * @since 2.3.0
 */

import { mapActions, mapMutations, mapState } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import EzUserList from '@/views/common/users';
import User from '@/models/User';
import flash from '@/components/ui/FlashMessage';
import NewSupplierUserModal from '@/views/platform/venue/suppliers/new/NewSupplierUserModal';

export default {
  mixins: [wizardListenerMixin],
  components: {
    EzUserList,
    NewSupplierUserModal,
  },
  data() {
    return {
      formKey: 'distributor-user',
      formKeyAdmin: 'distributor-user-admin',
      roles: [],
      selectedRoleId: null,
      selectedUserId: null,
      allowGroupPermissionsInternal: false,
      formValid: false,
      users: [],
    };
  },
  computed: {
    ...mapState('entities/users', ['context', 'contextId']),
    ...mapState('entities/distributors', ['draftSupplier', 'draftSupplierAdmin']),
    adminUserId() {
      return this.draftSupplierAdmin.id;
    },
    user() {
      return User.query().with('venue').find(this.selectedUserId) || {};
    },
    adminUser() {
      return User.query().find(this.adminUserId) || {};
    },
    formAction() {
      let url = `/venue/distributors/${this.draftSupplier.id}/users`;
      url = this.adminUserId !== -1 ? `${url}/${this.adminUserId}` : url;

      return url;
    },
    formActionModal() {
      const url = `/venue/distributors/${this.draftSupplier.id}/users`;
      return url;
    },
  },
  methods: {
    ...mapActions('entities/users', ['initDistributor', 'fetchUsers', 'removeUser']),
    ...mapActions('entities/distributors', ['venueFetchDistributorUsers']),
    ...mapMutations('entities/distributors', ['UPDATE_DRAFT_ADMIN']),
    onNextStep() {
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    openModal() {
      this.$refs.userModal.open();
    },
    async onUserClick({ id }) {
      this.fetchUsers({
        route: `/venue/distributors/${this.draftSupplier.id}/users/${id}`,
        groupId: this.draftSupplier.id,
        distributorId: this.draftSupplier.id,
      }).then(() => {
        this.selectedUserId = id;
        this.openModal();
      });
    },
    async onSubmitSuccess({ data, isUpdate }) {
      await User.insertOrUpdate({ data: { ...data.data, groupId: this.draftSupplier.id } });
      flash.success({ title: `User successfully ${isUpdate ? 'updated' : 'added'}.` });
      await this.fetchDistributorUsers();
      this.closeModal();
    },
    closeModal() {
      this.$refs.userModal.close();
    },
    onAddUser() {
      this.selectedUserId = -1;
      this.openModal();
    },
    async fetchDistributorUsers() {
      const {
        data: { data },
      } = await this.venueFetchDistributorUsers({
        venueId: this.contextId,
        id: this.draftSupplier.id,
      });
      this.users = data.filter(usr => usr.type === 'owner' || usr.type === 'subscriber');
    },
    async onRemoveUser({ id }) {
      await this.removeUser({
        route: `/venue/distributors/${this.draftSupplier.id}/users/${id}`,
        id,
      });
      await this.fetchDistributorUsers();
    },
  },
  mounted() {
    this.fetchDistributorUsers();
    this.enableNextStep();
  },
};
</script>

<template>
  <div>
    <ez-user-list
      :users="users"
      :columns="['name', 'email']"
      :columnProps="{
        venue: { class: 'venue-cell' },
        role: { class: 'role-cell' },
      }"
      :headers="{
        accountName: () => 'Venue',
        venue: () => $t('global.venue'),
      }"
      @addUser="onAddUser"
      @userClick="onUserClick"
      @removeUser="onRemoveUser"
    >
      <new-supplier-user-modal
        entity="Contact"
        ref="userModal"
        :formKey="formKey"
        :formAction="formActionModal"
        :user="user"
        :isEmailRequired="false"
        @success="onSubmitSuccess"
      >
      </new-supplier-user-modal>
      <template #cta>Add Contact</template>
    </ez-user-list>
  </div>
</template>

<style scoped lang="scss">
.ordering-fields {
  :deep() form {
    display: flex;
  }
  &__field {
    flex: 1 1 100%;
    &--first {
      padding-right: 8px;
    }
    &--last {
      padding-left: 8px;
    }
  }
}
</style>
