<template>
  <div>
    <ez-table
      :columns="columns"
      :data="products"
      :headers="productHeaders"
      :rowCls="getRowClass"
      :columnProps="{
        sku: {class: 'medium-cell sku-cell'},
        quantity: { class: 'small-cell quantity-cell' },
        price: { class: 'small-cell price-cell' },
        action: { class: 'medium-cell action-cell' },
      }"
      :rowDataCy="(row) => `${commonCy.SINGLE_ORDER.ROW__EXCLUDED_PRODUCT}-${row.id}`"
      @rowHover="rowHover"
    >
      <template #cell-product="{ row }">
        <ez-entity-info :imgUrl="row.image">
          <div class="product-info" :title="row.name">
            <span>{{ row.name }}</span>
            <span class="product-info-secondary">
              <v-price
                flex-row
                :price="row.price || 0"
                :currency="order.currency"
                :is-market-price="row.marketPrice"
                :show-market-price-long="true"
                :show-market-price-info="false"
                :unit="getProductUnitLabel(row)"
                :type="V_PRICE_TYPE.SECONDARY"
              />
              <span v-if="row.sku">&bull; {{ row.sku }}</span>
            </span>
          </div>
        </ez-entity-info>
      </template>
      <template #cell-sku="{ row }">
        {{ row.sku }}
      </template>
      <template #cell-price="{ row }">
        <div class="actions-wrapper">
          <span v-if="!isRowHovered(row)">
            <v-price
              :price="row.price * row.priceQuantity"
              :currency="order.currency"
              :is-market-price="row.marketPrice"
              :show-market-price-info="false"
              :is-to-be-determined="isToBeDetermined(row)"
            />
          </span>
          <font-awesome-icon
            v-tooltip="{
              content: 'Edit details',
              classes: ['tooltip-general']
            }"
            v-if="isRowHovered(row) && canEditProduct"
            @click.stop="open(row)"
            icon="pen"
          />
          <template v-if="enableEdit && !row.canBeDeleted">
            <div class="minus">
              <font-awesome-icon
                v-tooltip="{
                  content: 'Exclude from order',
                  classes: ['tooltip-general']
                }"
                @click.stop="rowClick(row)"
                class="ml-8"
                v-if="enableEdit"
                icon="minus-circle" />
            </div>
          </template>
          <template v-if="enableEdit && row.canBeDeleted">
            <div class="minus">
              <font-awesome-icon
                v-tooltip="{
                  content: 'Remove from order',
                  classes: ['tooltip-general']
                }"
                @click.stop="deleteProduct(row)"
                class="ml-12"
                v-if="enableEdit"
                icon="trash" />
            </div>
          </template>
          <font-awesome-icon
            class="declined-minus ml-8"
            v-if="hasDeclinedIcon"
            icon="minus-circle" />
        </div>
      </template>
      <template #cell-quantity="{ row: { quantity } }">
        {{ quantity }}
      </template>
    </ez-table>

    <edit-details-modal
      @editedProduct="() => $emit('editedProduct')"
      :order="order"
      :product="selectedProduct"
      :is-public-order="isPublicOrder"
      :type="type"
      :token="token"
      ref="editDetails" />
  </div>
</template>

<script>
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzTable from '@/components/ui/Table';
import { mapActions, mapGetters, mapState } from 'vuex';
import { ORDER_STATUS_NEEDS_APPROVAL, V_PRICE_TYPE } from '@/util/constants';
import EditDetailsModal from '@/views/common/orders/products/EditDetailsModal';
import flash from '@/components/ui/FlashMessage';
import VPrice from '@/components/v3/elements/VPrice';

export default {
  components: {
    EzEntityInfo,
    EzTable,
    EditDetailsModal,
    VPrice,
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    enableEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasDeclinedIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    order: {
      type: Object,
      required: true,
    },
    isPublicOrder: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
    token: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      productHeaders: {
        price: h => h('div', { style: { textAlign: 'right' } }, 'Total amount'),
        sku: () => 'SKU',
      },
      hoveredRow: null,
      ORDER_STATUS_NEEDS_APPROVAL,
      selectedProduct: {},
      V_PRICE_TYPE,
      commonCy,
    };
  },
  computed: {
    ...mapGetters('entities/users', ['isDistributor', 'isVenue']),
    ...mapState('entities/users', ['loggedUser']),
    ...mapState('entities/users', [
      'contextId',
    ]),
    me() {
      return this.loggedUser.distributor;
    },
    columns() {
      return ['product', 'sku', 'quantity', 'price'];
    },
    canEditProduct() {
      return (this.enableEdit
        || (this.isVenue && (this.order.status === ORDER_STATUS_NEEDS_APPROVAL)));
    },
  },
  methods: {
    ...mapActions('entities/orders', [
      'distributorDeleteProductsFromSingleOrder',
      'venueDeleteProductsFromSingleOrder',
    ]),
    rowClick(item) {
      this.$emit('productClick', item);
    },
    getRowClass(row) {
      if (!this.enableEdit) {
        return null;
      }

      return row.isDeclined ? 'item-excluded' : null;
    },
    rowHover(row) {
      this.hoveredRow = row;
    },
    isRowHovered(row) {
      if (this.canEditProduct && this.hoveredRow) {
        return row.id === this.hoveredRow.id;
      }
      return false;
    },
    open(row) {
      this.selectedProduct = row;
      this.$refs.editDetails.open();
    },
    isToBeDetermined(product) {
      const { priceQuantity, price, marketPrice } = product || {};
      return (!priceQuantity && price > 0) || marketPrice;
    },
    getProductUnitLabel(product) {
      const { priceUnit, orderingUnit } = product || {};
      return priceUnit?.label || orderingUnit?.label || '';
    },
    async deleteProduct(product) {
      const config = {
        ...this.token && { 'X-Public-Token': this.token },
      };
      try {
        if (this.isDistributor) {
          await this.distributorDeleteProductsFromSingleOrder({
            orderId: this.order.id,
            productId: product.id,
            config,
          });
        } else {
          await this.venueDeleteProductsFromSingleOrder({
            venueId: this.contextId,
            orderId: this.order.id,
            productId: product.id,
            config,
          });
        }
        this.$emit('productRemoved');
        flash.success({
          title: 'Successfully removed product from order!',
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
  },
};
</script>

<style scoped lang="scss">
$icon-excluded-color: #e84d60;
$excluded-background-color: #f0f4f9;
$text-color: #252631;

.table {
  thead th,
  tbody tr td {
    &.action-cell {
      width: 42px;
      text-align: center;
      padding-left: 0;
      padding-right: 0 !important;
    }
  }

  thead {
    th {
      &.quantity-cell {
        text-align: right;
      }
    }
  }
  tbody {
    tr {
      .minus {
        display: none;
      }
      svg {
        outline: none;
      }
      &.item-excluded {
        background-color: $excluded-background-color;
        text-decoration: line-through;
        opacity: 0.6;
        &:hover {
          opacity: 0.8;
        }

        .price-cell {
          color: #8387a0;
        }

        .minus {
          display: flex;
          svg {
            color: $icon-excluded-color;
          }
        }
      }
      &:not(.item-excluded):hover {
        .minus {
          display: flex;
        }
      }

      td {
        &.price-cell {
          color: $text-color;
          text-align: right;
        }
        &.quantity-cell {
          text-align: right;
        }
      }
      .declined-minus {
        display: flex;
        color: $icon-excluded-color;
        opacity: 0.24;
      }
    }
  }
  .tax-label {
    cursor: pointer;
    color: #6c7995;
    @include font-size(12px);
    font-weight: 600;
  }

  .tax {
    min-width: 164px;
    &__item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;

      &--total {
        padding-top: 6px;
        border-top: 1px solid rgba(#fff, 0.2);
      }
    }
  }
  .actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #6c7995;

    span {
      color: $text-color;
    }
  }
}
.product-info {
  display: flex;
  flex-direction: column;
  .product-info-secondary {
    display: flex;
    margin-top: 2px;
    span {
      font-weight: 600;
      color: $text-color
    }
  }
}

:deep() .table {
  .sku-cell {
    display: none;
  }
}

.button-wrapper {
  padding: 0 1.5rem;
}
</style>
