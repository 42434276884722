<script>
import EzButton from '@/components/ui/Button/EzButton';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';

/**
 *
 * @version 1.0.0
 * @since 3.29.0
 */
export default {
  components: {
    EzButton,
  },
  data() {
    return {
      commonCy,
    };
  },
  props: {
    disabled: {
      required: false,
      type: Boolean,
    },
    type: {
      required: false,
      type: String,
      default: 'primary',
    },
  },
};
</script>
<template>
  <!--TODO: Add required dataCy attr -->
  <ez-button
      :type="type"
      :disabled="disabled"
      @click="$emit('actionClicked')"
      :data-cy="''"
  >
    Convert to Order
  </ez-button>
</template>
<style lang="scss" scoped></style>
