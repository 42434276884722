<template>
  <ez-confirmation-modal @close="onClose" ref="receiveModal">
    <template #title>Receive Order?</template>
    <template #content>
      <p>Please review before confirming that you received this order.</p>

      <ez-textarea
        :placeholder="`Leave a note. (Optional)`"
        formKey="note"
        name="message"
        label="Note"
        class="mt-16"
        :value="note"
        @onChange="onNoteChange"
      />
    </template>
    <template #footer>
      <ez-button @click="close" type="link">Cancel</ez-button>
      <ez-button @click="receiveOrder" :is-loading="isLoading">Submit</ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import flash from '@/components/ui/FlashMessage';
import { LOADING_KEY } from '@/util/constants';
import EzTextarea from '@/components/ui/Textarea/EzTextarea';
/**
 * ApproveOrder
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    EzConfirmationModal,
    EzButton,
    EzTextarea,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      note: '',
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.RECEIVE_ORDER);
    },
    excludedProductsIds() {
      return (this.order.orderedProducts || []).filter(p => p.isDeclined).map(a => a.id);
    },
  },
  methods: {
    ...mapActions('entities/orders', ['venueOrderAction']),
    ...mapActions('entities/users', ['updateVenueContext']),
    open() {
      this.$refs.receiveModal.open();
    },
    close() {
      this.note = '';
      this.$refs.receiveModal.close();
    },
    onClose() {
      this.note = '';
    },
    onNoteChange(ev) {
      this.note = ev;
    },
    async receiveOrder() {
      await this.venueOrderAction({
        id: this.order.id,
        action: 'receive',
        message: this.note,
        declinedProducts: this.excludedProductsIds,
        loadingKey: LOADING_KEY.RECEIVE_ORDER,
      });
      this.close();
      this.$emit('success');

      flash.success({
        title: 'Order received.',
        message: 'You have successfully received the order.',
      });
      this.updateVenueContext();
    },
  },
};
</script>

<style lang="scss" scoped></style>
