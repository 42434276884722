<template>
  <iframe
    :src="`${chartbrewUrl}?theme=light`"
    allowTransparency="true"
    height="800px"
    width="100%"
    frameborder="0"
    style="background-color: #ffffff"
    class="mt-24"
  >
  </iframe>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('entities/users', [
      'context',
    ]),
    chartbrewUrl() {
      return this.context.chartbrewUrl;
    },
  },
};
</script>

<style>
</style>
