<template>
  <div class="venue-list">
    <ez-table
      :data="venues"
      :columns="['name', ...(canDelete ? ['actions'] : [])]"
      :columnProps="{
        name: { class: 'cell-name' },
      }"
      :headers="{
        name: () => `${$t('global.venue')} name`,
        email: () => 'Email address',
        registeredAt: () => 'Date registered',
        status: () => 'Status',
        actions: () => '',
      }"
      :removeButton="canDelete"
      @removeItem="onRemoveItem"
      @rowClick="openVenue"
    >
      <template #cell-name="{ row }">
        <span :title="row.name">{{ row.name }}</span>
      </template>
    </ez-table>
    <div v-if="isLoadingMoreVenues" class="u-text-center mt-12">
      <ez-spinner />
    </div>
    <ez-load-more v-if="hasMoreVenues" @loadMore="fetchMoreVenues" />
    <ez-button
      v-if="$permission.isPremium && canCreate"
      type="secondary"
      form-type="button"
      isFullWidth
      @click="addVenue"
      :disabled="createDisabled"
    >
      <font-awesome-icon icon="plus" />
      Add {{ $t('global.venue') | lowercase }}
    </ez-button>
    <ez-button
      v-else-if="$permission.isPremium"
      type="secondary"
      form-type="button"
      isFullWidth
      @click="requestOutlets"
    >
      <font-awesome-icon icon="plus" />
      Request additional {{ $t('global.venues') | lowercase }}
    </ez-button>
    <venue-modal
      ref="createModal"
      :groupId="groupId"
      :venueId="selectedVenueId"
      :venueData="selectedVenue"
      @submitSuccess="onCreateFormSubmit"
      @close="onCreateModalClose"
    />
    <slot></slot>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { isAdmin } from '@/util/constants';
import flash from '@/components/ui/FlashMessage';
import EzButton from '@/components/ui/Button';
import EzTable from '@/components/ui/Table';
import VenueModal from '@/views/common/venues/Modal.vue';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore.vue';
import EzSpinner from '@/components/ui/Spinner/EzSpinner.vue';

export default {
  components: {
    EzButton,
    EzTable,
    VenueModal,
    EzLoadMore,
    EzSpinner,
  },
  props: {
    groupId: {
      type: Number,
      required: true,
    },
    editInModal: {
      type: Boolean,
      default: false,
    },
    venues: {
      type: Array,
      required: true,
    },
    canDelete: {
      required: false,
      type: Boolean,
      default: false,
    },
    canCreate: {
      required: false,
      type: Boolean,
      default: true,
    },
    createDisabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    hasMoreVenues: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectedVenueId: -1,
      selectedVenue: {},
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['getLoading']),
    isNewVenue() {
      return this.selectedVenueId === -1;
    },
    isLoadingMoreVenues() {
      return this.getLoading('fetch-more-venues-for-group');
    },
  },
  methods: {
    ...mapActions('entities/venues', ['fetchAdminVenueNew', 'fetchVenueNew']),
    openModal() {
      this.$refs.createModal.open();
    },
    closeModal() {
      this.$refs.createModal.close();
    },
    onRemoveItem(data) {
      this.$emit('removeItem', data);
    },
    onCreateFormSubmit({ data }) {
      flash.success({
        title: `${this.$t('global.venue')} successfully ${this.isNewVenue ? 'added' : 'updated'}!`,
      });

      this.closeModal();
      this.$emit('venueCreated', data.data);
    },
    onCreateModalClose() {
      this.selectedVenueId = -1;
      this.selectedVenue = {};
    },
    addVenue() {
      this.openModal();
      this.selectedVenueId = -1;
      this.selectedVenue = {};
    },
    requestOutlets() {
      window.open('https://www.foodrazor.com/pricing', '_blank');
    },
    async openVenue(row) {
      if (this.editInModal) {
        this.selectedVenueId = row.id;

        if (isAdmin()) {
          const { data } = await this.fetchAdminVenueNew({ venueId: row.id });
          this.selectedVenue = data.data;
        } else if (this.groupId !== this.loggedUser.group?.id) {
          const { data } = await this.fetchVenueNew({ groupId: this.groupId, venueId: row.id });
          this.selectedVenue = data.data;
        } else {
          this.selectedVenue = row;
        }

        this.openModal();
      }

      this.$emit('rowClick', row);
    },
    fetchMoreVenues() {
      this.$emit('loadMore');
    },
  },
};
</script>

<style scoped lang="scss">
.venue-list {
  .table {
    td.cell-name {
      color: $color-gray-25;
    }
  }

  :deep() .table {
    td {
      height: 56px;
    }
  }

  .button--secondary {
    margin-top: 0.75rem;
  }
}
</style>
