<template>
    <div class="ez-pending-banner" v-if="pendingCount">
      <font-awesome-icon icon="info-circle" />
      <div class="ez-pending-banner__message">{{ message }}</div>
      <ez-button
        :data-cy="supplierCy.ORDERS.GENERAL.BUTTON__PENDING_ORDERS"
        type="link"
        formType="button"
        @click="onViewOrdersClick">View Pending Orders</ez-button>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EzButton from '@/components/ui/Button';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';

export default {
  components: {
    EzButton,
  },
  data() {
    return {
      supplierCy,
    };
  },
  computed: {
    message() {
      const { pendingCount } = this;

      return pendingCount > 1
        ? `There are ${pendingCount} pending orders. You can see them by clicking on the button on the right.`
        : `There is ${pendingCount} pending order. You can see it by clicking on the button on the right.`;
    },
    ...mapGetters({
      pendingCount: 'entities/orders/getPendingOrdersCount',
    }),
  },
  methods: {
    onViewOrdersClick() {
      this.$emit('viewPending');
    },
  },
};
</script>

<style scoped lang="scss">
  .ez-pending-banner {
    @include font-size(14px);
    @extend %flex-center;
    justify-content: center;
    color: $color-primary-orange;
    display: flex;
    padding: .75rem;
    background-color: $yellow-bg;
    border-radius: .1875em;
    svg {
      @include font-size(16px);
      color: $color-primary-orange;
    }
    &__message {
      font-weight: 400;
      margin: 0 1rem 0 .75rem;
    }
    .button {
      color: $color-primary-orange;
      background-color: $color-white;
    }
  }
</style>
