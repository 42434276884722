<script>
import EzButton from '@/components/ui/Button/EzButton';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo';
import SinglePage from '@/views/admin/SinglePage';
import EzPremiumBadge from '@/components/ui/PremiumBadge/PremiumBadge';
import { mapActions, mapMutations, mapState } from 'vuex';
import confirmation from '@/components/v3/patterns/VConfirmation/control';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  components: {
    EzButton,
    EzButtonDropdown,
    EzEntityInfo,
    SinglePage,
    EzPremiumBadge,
  },
  props: {
    supplierId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('entities/users', ['contextId']),
    ...mapState('entities/distributors', ['selectedSupplier']),
    distributor() {
      return this.selectedSupplier;
    },
    isPremium() {
      return this.$helpers.isPremium(this.distributor.accountType);
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'venueFetchSupplierSingle',
      'venueDisconnectDistributor',
    ]),
    ...mapMutations('entities/distributors', ['UPDATE_SELECTED_SUPPLIER']),
    async getSingleDistributor() {
      const { data } = await this.venueFetchSupplierSingle({
        id: this.supplierId,
      });
      this.UPDATE_SELECTED_SUPPLIER(data.data);
    },
    async disconnect() {
      const supplier = this.$t('global.distributor').toLowerCase();
      const suppliers = this.$t('global.distributors').toLowerCase();
      const { close, canceled } = await confirmation.confirm({
        title: `Disconnect from this ${this.$t('global.distributor')}?`,
        message: `When disconnected, you won’t be able to see this ${supplier}’s products nor
        will you be able to order from them. You won’t see this ${supplier} in your list of
        ${suppliers} as well.`,
        buttons: {
          confirm: 'Disconnect',
        },
      });
      if (canceled) return;
      close();

      this.venueDisconnectDistributor({
        venueId: this.contextId,
        distributorId: this.supplierId,
      });

      await this.$router.push({
        name: 'venue-suppliers',
        params: {
          flash: {
            type: 'success',
            title: `${this.$t('global.distributor')} successfully disconnected!`,
          },
        },
      });
    },
  },
  mounted() {
    this.getSingleDistributor();
  },
};
</script>
<template>
  <single-page>
    <template #breadcrumbs>
      <router-link :to="{ name: 'venue-all-suppliers' }">
        <font-awesome-icon icon="arrow-left" />
        <span>Back to {{ $t('global.distributors') }}</span>
      </router-link>
    </template>

    <template #title>
      <ez-entity-info
        v-if="distributor"
        imgBorderRadius="50%"
        imgWidth="2rem"
        imgHeight="2rem"
        :imgUrl="distributor.logo"
      >
        <h1>
          {{ distributor.name
          }}<ez-premium-badge v-if="$helpers.isPremium(distributor.accountType)" />
        </h1>
      </ez-entity-info>
    </template>
    <template #actions>
      <ez-button-dropdown buttonType="secondary">
        <template #icon>
          <font-awesome-icon icon="ellipsis-h" />
        </template>
        <template #dropdown>
          <ez-button type="red-link" @click="disconnect"> Disconnect </ez-button>
        </template>
      </ez-button-dropdown>
    </template>
    <template #navigation>
      <ul>
        <li>
          <router-link :to="{ name: 'buyer-single-info' }">
            {{ $t('global.distributor') }} Info
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'buyer-single-products' }"> Products </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'buyer-single-users' }"> Contacts </router-link>
        </li>
      </ul>
    </template>
  </single-page>
</template>
<style lang="scss" scoped>
:deep() .loader {
  position: fixed;
}
</style>
