<script>
import dayjs from 'dayjs';
import { EzSimpleDropdown } from '@/components/ui/Dropdown';
import EzButton from '@/components/ui/Button/EzButton';
import FilterBody from '@/components/v3/patterns/VFilterDropdown/FilterBody';
import VLabel from '@/components/v3/elements/VLabel';
import { pluralize } from '@/util/utils';

/**
 *
 * @version 1.0.0
 * @since 3.13.0
 */
export default {
  name: 'index',
  components: {
    EzSimpleDropdown,
    EzButton,
    FilterBody,
    VLabel,
  },
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    filters: {
      required: true,
      type: Object,
    },
    /**
     * This attribute is used for
     * marking the element when testing with cypress
     */
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
    defaultDateFilters: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      localFilters: {},
    };
  },
  computed: {
    hasFilter() {
      return Object.values(this.filters).some(x => x === '' || x);
    },
    showFirstRow() {
      return !!this.$slots.firstRow;
    },
    showSecondRow() {
      return !!this.$slots.secondRow;
    },
    showThirdRow() {
      return !!this.$slots.thirdRow;
    },
    showFourthRow() {
      return !!this.$slots.fourthRow;
    },
    secondRowLabel() {
      return !!this.$slots.secondRowLabel;
    },
    thirdRowLabel() {
      return !!this.$slots.thirdRowLabel;
    },
    fourthRowLabel() {
      return !!this.$slots.fourthRowLabel;
    },
    numberOfFilters() {
      return Object.values(this.filters).filter(x => x === '' || x).length;
    },
    btnCopy() {
      return this.numberOfFilters
        ? `${this.numberOfFilters} Active ${pluralize('Filter', this.numberOfFilters)}`
        : 'Filters';
    },
  },
  methods: {
    closeFilterDropdown() {
      this.$refs.filterDropdown.close();
    },
    onClose() {
      this.$emit('filterClosed');
    },
    onOpen() {
      /**
       * on dropdown open, sync applied filters to local Object
       * in this way we have latest applied filters after apply btn was clicked
       */
      this.localFilters = this.filters;
    },
    resetFiltersEmit() {
      this.$emit('resetFilters');
    },
    resetFilters() {
      this.$refs.filterBody.reset();
    },
    applyFilters() {
      /**
       * When filter value is changed.
       * @event filterUpdated
       * @type Object
       */
      this.$emit('filterUpdated', this.localFilters);
    },
    onFilterUpdated(name, event) {
      /**
       * Since the following fields can have a value that's an object,
       * we gotta cover an edge case in order to determine
       * whether they're different from the default state.
       */
      if (['scheduled', 'month', 'venueId', 'distributorId', 'range'].includes(name)) {
        const isSameStart = dayjs(event.start).valueOf() === this.defaultDateFilters?.[name]?.from;
        const isSameEnd = dayjs(event.end).valueOf() === this.defaultDateFilters?.[name]?.to;

        if ((isSameStart && isSameEnd) || event.reset) {
          const { [name]: removed, ...rest } = this.localFilters;
          this.localFilters = { ...rest };
          return;
        }
      }

      this.localFilters = { ...this.localFilters, [name]: event };
    },
    syncLocalFilters() {
      this.localFilters = this.filters;
    },
  },
};
</script>

<template>
  <ez-simple-dropdown
    :label="label ? label : ''"
    direction="down"
    :class="['filters', { 'filters--wider': numberOfFilters }]"
    ref="filterDropdown"
    @close="onClose"
    @open="onOpen"
    :data-cy="dataCy"
  >
    <template #display>
      <div class="placeholder">
        <font-awesome-icon icon="sliders-h" />
        <span class="pl-12 filters-btn">{{ btnCopy }}</span>
      </div>
    </template>
    <template #dropdown>
      <div class="dropdown-filters" @click.stop>
        <div class="filters-area">
          <filter-body
            ref="filterBody"
            @filterUpdated="onFilterUpdated"
            @resetFilter="resetFiltersEmit"
          >
            <div class="filters-area__row" v-if="showFirstRow"><slot name="firstRow"></slot></div>
            <v-label v-if="secondRowLabel" class="status-filter-label mt-12 mb-12">
              <slot name="secondRowLabel"></slot>
            </v-label>
            <div class="filters-area__row" v-if="showSecondRow">
              <slot name="secondRow"></slot>
            </div>
            <hr v-if="showThirdRow" />
            <v-label v-if="thirdRowLabel" class="status-filter-label mt-12 mb-12">
              <slot name="thirdRowLabel"></slot>
            </v-label>
            <div class="filters-area__row" v-if="showThirdRow">
              <slot name="thirdRow"></slot>
            </div>
            <hr v-if="showFourthRow" />
            <v-label v-if="fourthRowLabel" class="status-filter-label mt-12 mb-12">
              <slot name="fourthRowLabel"></slot>
            </v-label>
            <div class="filters-area__row" v-if="showFourthRow">
              <slot name="fourthRow"></slot>
            </div>
          </filter-body>
        </div>
        <div class="actions-area">
          <div>
            <ez-button
              v-if="hasFilter"
              type="link"
              class="reset-button"
              @click="resetFilters"
              :data-cy="dataCy ? `${dataCy}-clearFilters` : ''"
            >
              <span>Clear Filters</span>
            </ez-button>
          </div>
          <div>
            <ez-button
              :data-cy="dataCy ? `${dataCy}-cancelFilters` : ''"
              @click="closeFilterDropdown"
              type="link"
              class="reset-button"
            >
              <span>Cancel</span>
            </ez-button>
            <ez-button :data-cy="dataCy ? `${dataCy}-applyFilters` : ''" @click="applyFilters">
              Apply
            </ez-button>
          </div>
        </div>
      </div>
    </template>
  </ez-simple-dropdown>
</template>

<style lang="scss" scoped>
.ez-simple-dropdown.filters {
  width: 92px;
  height: 36px;

  &.filters--wider {
    width: 152px;
  }
  .placeholder {
    display: flex;
    align-items: center;

    .filters-btn {
      @include font-size(14px, 20px);
      color: $color-gray-6C;
    }
  }

  :deep() > .ez-simple-dropdown {
    &__display-container {
      height: 100%;
    }

    &__dropdown-container {
      width: 520px;

      .ez-simple-dropdown__dropdown {
        padding: 16px;
        overflow: initial;
        max-height: initial;
      }
    }
  }
}
.dropdown-filters {
  .filters-area {
    padding-bottom: 16px;

    &__row {
      &:first-child {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px 12px;
      }

      &:not(:first-child) {
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 12px 12px;
      }
    }

    :deep() .status-filter-label {
      line-height: 14px;
    }
  }

  .actions-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
    border-top: 1px dashed #e1e5ed;
  }
}
</style>
