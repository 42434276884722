<script>
/**
 * TimelineBadge
 * @version 1.0.0
 * @since
 */

import {
  ORDER_STATUS_ACCEPTED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_COMPLETED_AFTER_DISPUTE,
  ORDER_STATUS_DECLINED,
  ORDER_STATUS_IN_DISPUTE,
  ORDER_STATUS_PARTIALLY_ACCEPTED,
  ORDER_STATUS_PENDING,
  ORDER_STATUS_SHIPPED,
  ORDER_STATUS_VENUE_DRAFT,
  ORDER_STATUS_DISTRIBUTOR_DRAFT,
  ActivityType,
  ACTIVITY_ORDER,
  ExpenseType,
  EDITED_ORDER_PRODUCTS,
  ADDED_ORDER_PRODUCTS,
  REMOVED_ORDER_PRODUCTS,
  DELIVERY_FEE_UPDATE,
  DELIVERY_DATE_UPDATE,
  ORDER_STATUS_NOT_MATCHING,
  CREDIT_NOTE_HISTORY_LOG,
} from '@/util/constants';

export default {
  props: {
    status: {
      type: String,
      required: true,
      default: '',
    },
  },
  computed: {
    icon() {
      switch (this.status) {
        case EDITED_ORDER_PRODUCTS:
          return 'pen';
        case ADDED_ORDER_PRODUCTS:
          return 'plus';
        case REMOVED_ORDER_PRODUCTS:
          return 'trash';
        case ORDER_STATUS_ACCEPTED:
          return 'check-circle';
        case ORDER_STATUS_COMPLETED:
          return 'check-circle';
        case ORDER_STATUS_COMPLETED_AFTER_DISPUTE:
          return 'check-circle';
        case ORDER_STATUS_PARTIALLY_ACCEPTED:
          return 'check-circle';
        case ORDER_STATUS_SHIPPED:
        case DELIVERY_FEE_UPDATE:
          return 'truck';
        case DELIVERY_DATE_UPDATE:
          return 'calendar-alt';
        case ORDER_STATUS_PENDING:
        case ACTIVITY_ORDER:
        case ORDER_STATUS_VENUE_DRAFT:
        case ORDER_STATUS_DISTRIBUTOR_DRAFT:
          return 'list-alt';
        case ORDER_STATUS_IN_DISPUTE:
        case ORDER_STATUS_NOT_MATCHING:
          return 'exclamation-circle';
        case ORDER_STATUS_DECLINED:
          return 'minus-circle';
        case ActivityType.CALL:
          return 'phone-alt';
        case ActivityType.MEETING:
          return 'comment';
        case ActivityType.CHECK_IN:
          return 'coffee';
        case ActivityType.TODO:
          return 'tasks';
        case ExpenseType.BUSINESS_DEVELOPMENT:
          return 'briefcase';
        case ExpenseType.OTHER:
          return 'money-bill-wave';
        case ExpenseType.TRAVEL:
          return 'plane';
        case CREDIT_NOTE_HISTORY_LOG:
          return 'file-invoice-dollar';
        default:
          return 'bell';
      }
    },
  },
};
</script>
<template>
  <div :class="`timeline-badge timeline-badge--${status}`">
    <font-awesome-icon :icon="icon" />
  </div>
</template>
<style lang="scss" scoped>
.timeline-badge {
  position: relative;
  @extend %flex-center;
  flex-shrink: 0;
  justify-content: center;
  @include size(32px);
  border-radius: 50%;
  border: 1px solid #e9ebf2;
  background-color: #f5f6fa;
  color: #6c7994;

  &--completed,
  &--in_dispute,
  &--not_matching,
  &--pending {
    border: 0;
  }

  &--completed,
  &--added,
  &--completed_after_dispute,
  &--call {
    color: #1ba975;
    background-color: #daf1e9;
  }

  &--in_dispute,
  &--not_matching,
  &--removed {
    color: #f73731;
    background-color: #fedfde;
  }

  &--declined {
    color: #f73731;
  }

  &--out_for_delivery {
    color: #ef9b13;
  }

  &--accepted {
    color: #1ba975;
  }

  &--partially_accepted {
    color: #4d7cfe;
  }

  &--pending,
  &--order,
  &--credit_note {
    color: #ef9b13;
    background-color: #fef6da;
  }

  &--meeting,
  &--delivery_fee_update,
  &--requested_delivery_date_update {
    color: #fc773b;
    background-color: #fbe7c7;
  }

  &--check_in {
    color: #983bbd;
    background-color: #eedff4;
  }

  &--todo,
  &--business_development,
  &--product_update {
    background-color: #e2eaff;
    color: #4d7cfe;
  }
  &--other {
    color: #6c7995;
    background-color: #eceef5;
  }
  &--travel {
    color: #f8c61b;
    background-color: #fef6da;
  }
}
</style>
