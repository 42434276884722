<script>

/**
 * Connect to Distributor
 *
 * @version 1.0.0
 * @since 2.4.0
 */

import { wizardEmitterMixin } from '@/mixins/wizard';
import EzButton from '@/components/ui/Button/EzButton';
import EzWizard from '@/components/layout/Wizard';
import steps, {
  SUPPLIER_STEP_1, SUPPLIER_STEP_2,
} from '@/views/platform/venue/suppliers/connect/steps';
import { mapMutations } from 'vuex';

export default {
  mixins: [wizardEmitterMixin],
  components: {
    EzButton,
    EzWizard,
  },
  props: {
    supplierId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentStep: 0,
      SUPPLIER_STEP_1,
      SUPPLIER_STEP_2,
      nextDisabled: true,
    };
  },
  computed: {
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === SUPPLIER_STEP_1;
    },
    isLastStep() {
      return this.currentStep === SUPPLIER_STEP_2;
    },
    isFullWidth() {
      return this.currentStep === SUPPLIER_STEP_1 || this.currentStep === SUPPLIER_STEP_2;
    },
    disableNext() {
      return false;
    },
  },
  methods: {
    onWizardExit() {
      this.CLEAR_DRAFT();
      this.$router.push({ name: 'venue-suppliers' });
    },
    onStepBack(step) {
      if (step !== undefined && step >= SUPPLIER_STEP_1 && !this.isLastStep) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= SUPPLIER_STEP_1) {
        return;
      }

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.isLastStep) {
        return;
      }
      this.nextDisabled = true;
      this.currentStep += 1;
    },
    ...mapMutations('entities/distributors', [
      'CLEAR_DRAFT',
    ]),
  },
};
</script>
<template>
  <ez-wizard
    class="new-supplier"
    :fullWidth="isFullWidth"
    @exit="onWizardExit"
    @back="goToPreviousStep">
    <template #title>Connect New {{ $t('global.distributor') }}</template>
    <template #nav>
      <ul>
        <li :class="{
          'ez-step-active': currentStep === SUPPLIER_STEP_1,
          'ez-step-completed': currentStep > SUPPLIER_STEP_1,
        }">
          1. Contacts
        </li>
        <li :class="{
          'ez-step-active': currentStep === SUPPLIER_STEP_2,
          'ez-step-completed': currentStep > SUPPLIER_STEP_2,
        }">
          2. Products
        </li>
      </ul>
    </template>
    <template #actions>
      <ez-button
        :disabled="nextDisabled"
        @click="goToNextStep">{{ nextCta }}</ez-button>
    </template>
    <template #prevStep>
      <ez-button
        v-if="currentStep > SUPPLIER_STEP_1 && !isLastStep"
        type="link"
        formType="button"
        @click="goToPreviousStep">
        <font-awesome-icon icon="arrow-left"/>
        <span>Back</span>
      </ez-button>
    </template>
    <template #pageTitle>{{ currentStepPageTitle }}</template>
    <template #pageInfo>{{ currentStepPageInfo }}</template>
    <component
      :supplierId="supplierId"
      :is="currentStepComponent"
      @stepCompleted="onStepCompleted"
      @stepBack="onStepBack"/>
  </ez-wizard>
</template>
<style lang="scss" scoped>
:deep() .loader {
  @extend %whole-screen;
}

.new-supplier {
  overflow: scroll;
  height: 100%;
}
</style>
