<script>
import { mapMutations } from 'vuex';
import { mask } from 'vue-the-mask';
import { titleCase } from 'change-case';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import uuid from 'uuid/v4';

dayjs.extend(customParseFormat);

/**
 * EzDateInput
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  directives: { mask },
  name: 'EzDateInput',
  model: {
    prop: 'value',
    event: 'onChange',
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: Object,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    min: {
      type: Object,
      required: false,
    },
    max: {
      type: Object,
      required: false,
    },
    mask: {
      type: String,
      required: false,
      default: '##/##/####',
    },
    dateFormat: {
      type: String,
      required: false,
      default: 'dd/MM/yyyy',
    },
    errorMsg: {
      type: String,
      default: '',
    },
    tooltip: {
      required: false,
      type: String,
    },
    isTooltipError: {
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      id: null,
      model: this.value,
      displayDate: '',
      dayjs,
    };
  },
  computed: {
    /**
     * - D and DD that represent the day of a year (1, 2, ..., 365, 366) are often confused with d and dd that represent the day of a month (1, 2, ..., 31).
     * - YY and YYYY that represent the local week-numbering year (44, 01, 00, 17) are often confused with yy and yyyy that represent the calendar year.
     *
     * For detailed explanation, please refer to:
     * @see {@link https://github.com/date-fns/date-fns/blob/main/docs/unicodeTokens.md#popular-mistakes}
     */
    properDateFormat() {
      return this.dateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd');
    },
    validators() {
      return [
        `date_format:${this.properDateFormat}`,
        ...(this.required ? ['required'] : []),
        ...(this.min ? [`after:${this.formatDate(this.min)}`] : []),
        ...(this.max ? [`before:${this.formatDate(this.max)}`] : []),
      ].join('|');
    },
    isInvalid() {
      return !!(this.hasClientError || this.hasUserError || this.hasServerErrorMessage);
    },
    serverError() {
      return this.$store.getters['errors/getError'](this.formKey, this.name);
    },
    clientError() {
      const veeValidateErrorMsg = this.errors.first(this.name);
      const dateErrorMsg = this.formatErrorMsgDate(veeValidateErrorMsg);

      return dateErrorMsg || veeValidateErrorMsg;
    },
    hasServerErrorMessage() {
      if (!this.serverError || typeof this.serverError !== 'object') return false;
      return Object.prototype.hasOwnProperty.call(this.serverError, 'message');
    },
    hasUserError() {
      return !!this.errorMsg;
    },
    hasClientError() {
      return !!this.clientError;
    },
    tooltipError() {
      if (!this.isTooltipError) return {};
      const errorMsg = [];

      if (this.hasUserError) errorMsg.push(`<div>${this.errorMsg}</div>`);
      if (this.hasClientError) errorMsg.push(`<div>${this.clientError}</div>`);
      if (this.hasServerErrorMessage) errorMsg.push(`<div>${this.serverError.message}</div>`);

      return {
        content: errorMsg.join('\n'),
        classes: ['tooltip--reset-margin', 'mb-8'],
        show: true,
        autoHide: false,
      };
    },
  },
  methods: {
    ...mapMutations('errors', ['CLEAR_ERROR']),
    formatDate(date) {
      return dayjs(date).format(this.dateFormat.toUpperCase());
    },
    focus() {
      this.$refs[this.id].focus();
    },
    onChange(e) {
      const { value } = e.target;
      const date = dayjs(value, this.dateFormat.toUpperCase());

      this.displayDate = value;

      if (dayjs(date).isValid()) {
        this.model = date;
        this.$emit('onChange', date);
      } else {
        this.$emit('onChange', null);
      }

      if (this.hasServerErrorMessage) {
        this.CLEAR_ERROR({
          formKey: this.formKey,
          field: this.name,
        });
      }
    },
    reset() {
      this.displayDate = '';
      this.model = null;
    },
    async clearErrors() {
      await this.$nextTick();
      this.errors.clear();

      await this.$validator.reset();
      this.CLEAR_ERROR({ formKey: this.formKey, field: this.name });
    },
    formatErrorMsgDate(errorMsg) {
      if (!errorMsg) return null;

      const dateFormat = this.dateFormat.replace('YYYY', 'yyyy').replace('DD', 'dd');

      return errorMsg
        .replace(dateFormat, dateFormat.toUpperCase())
        .replace(this.name, this.label || titleCase(this.name));
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.model = val;
        this.displayDate = this.formatDate(val);
      },
    },
    isInvalid: {
      immediate: true,
      handler(val) {
        this.$emit('invalid', val);
      },
    },
  },
  mounted() {
    this.id = uuid();
  },
};
</script>

<template>
  <div
    :class="['input-group', { disabled }, { error: serverError || clientError || hasUserError }]"
    v-tooltip="tooltipError"
  >
    <label v-if="label" class="input-group__label" :for="id">{{ label }}</label>
    <div class="input-group__input">
      <input
        :ref="id"
        :id="id"
        :name="name"
        :placeholder="placeholder"
        :disabled="disabled"
        :required="required"
        :title="tooltip || (disabled && 'You might not have permission to edit this field.')"
        :value="displayDate"
        @input="onChange"
        v-validate="validators"
        v-mask="mask"
        data-vv-delay="1000"
        :data-cy="dataCy"
      />
      <div class="input-group__icon">
        <slot name="icon"></slot>
      </div>
    </div>
    <div
      v-if="(hasClientError && !isTooltipError) || (hasUserError && !isTooltipError)"
      class="input-group__error"
    >
      {{ errorMsg || clientError }}
    </div>
    <div v-if="hasServerErrorMessage && !isTooltipError" class="input-group__error">
      {{ serverError.message }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.input-group {
  display: block;
  text-align: left;

  &__label {
    display: block;
    margin-bottom: 0.375rem;
    color: $label-color;
    @include font-size($label-font-size, $label-line-height);
    font-weight: 500;
    text-transform: capitalize;
  }

  &__input {
    display: flex;
    position: relative;
    height: $input-height;
    border-radius: $input-border-radius;
    background-color: #ffffff;
    @include font-size(14px);

    input {
      @extend %input-reset;
      width: 100%;
      padding: 0 $input-padding-x;
      border-radius: $input-border-radius;
      border: 1px solid $input-border-color;
      color: $input-color;
      font-weight: 400;
      outline: none;

      &::placeholder {
        color: $input-placeholder-color;
      }

      &:not(:read-only):focus {
        border: $input-focus-border;
      }

      &:not(:read-only):focus,
      &::placeholder {
        margin-left: -1px;
      }

      &:read-only,
      &:disabled {
        background-color: $input-disabled-bg-color;
        border: 0;

        &::placeholder {
          color: $input-disabled-color;
        }
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  &__icon {
    color: $label-color;
    opacity: .48;

    position: absolute;
    min-width: 18px;
    top: 1px;
    right: 1px;
    line-height: calc(#{$input-height} - 2px);
  }

  &__error {
    margin-top: 0.5rem;
    color: $input-border-error-color;
    @include font-size(12px);
  }

  &.disabled {
    .input-group {
      &__input {
        background-color: $input-disabled-bg-color;

        input {
          color: $input-disabled-color;
        }

        input::placeholder {
          color: $input-disabled-color;
        }
      }
    }
  }

  &.error {
    .input-group {
      &__input {
        &,
        input {
          border-color: $input-border-error-color;
        }
      }

      &__label {
        color: $input-border-error-color;
      }
    }
  }
}
</style>
