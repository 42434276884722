<template>
  <ez-form
    :formKey="formKey"
    :loading-key="loadingKey"
    :additional-headers="additionalHeaders"
    :action="action"
    :method="method"
    :submitType="submitType"
    :additionalData="additionalData"
    @success="onSubmitSuccess"
    @error="onSubmitError"
    ref="venueForm"
    class="venue-form"
  >
    <slot name="prepend" />

    <ez-input
      :formKey="formKey"
      :value="name"
      :placeholder="`${$t('global.venue')} Name`"
      name="name"
      @onChange="updateFormState('name', $event)"
      :label="`${$t('global.venue')} name*`"
    />

    <ez-input
      :formKey="formKey"
      :value="venue.legalName"
      name="legalName"
      label="Legal Business Name"
      placeholder="Enter Your Legal Business Name"
      class="mt-12"
    />

    <ez-input
      :formKey="formKey"
      :value="venue.companyRegistrationNumber"
      name="companyRegistrationNumber"
      label="Company Registration Number / Tax ID"
      placeholder="Enter Your Company Registration Number / Tax ID"
      class="mt-12"
    />

    <ez-input
      :formKey="formKey"
      :value="customInfo"
      name="customInfo"
      label="Additional Info"
      placeholder="Additional Info"
      class="mt-12"
    />

    <slot name="primaryContact" />

    <hr class="address-separator" />

    <ez-address-form
      ref="deliveryAddress"
      :formKey="formKey"
      :fieldValidators="requiredAddressFields"
      :address="venue.shippingAddress || {}"
      name="shippingAddress"
    >
      <template #legend>Delivery Address</template>
    </ez-address-form>

    <ez-checkbox
      :formKey="formKey"
      :checked="billingAddressEnabled"
      class="billing-checkbox"
      name="notification"
      label="Billing Address same as Delivery Address"
      value="BILLING_ADDRESS"
      @change="value => (billingAddressEnabled = value)"
    />

    <ez-address-form
      ref="billingAddress"
      v-if="!billingAddressEnabled"
      :fieldValidators="requiredAddressFields"
      :formKey="formKey"
      :address="venue.billingAddress || {}"
      class="billing-address-form"
      name="billingAddress"
    >
      <template #legend>Billing Address</template>
    </ez-address-form>

    <hr class="address-separator" />

    <h4>Working Hours</h4>
    <v-select-search
      v-if="timeZones.length"
      class="width-100"
      align-left
      is-full-width
      name="timezone"
      label="Time Zone"
      :data="timeZones"
      value-property="name"
      placeholder="Select Time Zone"
      searchPlaceholder="Search Time Zone"
      :selected="selectedTimeZone"
      :has-clear="false"
    />

    <ez-working-hours
      ref="workingHours"
      :form-key="formKey"
      name-from="workingHours[from]"
      name-to="workingHours[to]"
      :value-from="workingHours.from || ''"
      :value-to="workingHours.to || ''"
      class="working-hours"
    />
    <ez-input
      :formKey="formKey"
      placeholder="Instructions"
      name="instruction"
      :value="venue.instruction"
      label="Delivery Instructions"
      class="instruction-input"
    />

    <slot name="prependBottom" />

    <template #buttons>
      <slot name="buttons"></slot>
    </template>
  </ez-form>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import { isAdmin, LOADING_KEY } from '@/util/constants';
import { falsy } from '@/util/utils';
import EzWorkingHours from '@/components/ui/WorkingHours';
import EzCheckbox from '@/components/ui/Checkbox';
import EzInput from '@/components/ui/Input';
import EzForm from '@/components/ui/Form';
import EzAddressForm from '@/views/common/address';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';

export default {
  components: {
    EzWorkingHours,
    EzCheckbox,
    EzInput,
    EzForm,
    EzAddressForm,
    VSelectSearch,
  },
  props: {
    loadingKey: {
      required: false,
      type: String,
    },
    formKey: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    venueId: {
      type: Number,
      required: false,
      default: -1,
    },
    venueData: {
      type: Object,
      required: false,
      default: () => {},
    },
    additionalData: {
      type: Object,
      default: () => {},
    },
    additionalHeaders: {
      type: Object,
      required: false,
      default: () => {},
    },
    submitType: {
      type: String,
      default: 'urlencoded',
    },
    prefilledName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      billingAddressEnabled: true,
      formValid: false,
      timeZones: [],
      fetchedVenue: null,
      requiredValues: {
        name: null,
      },
      billingAddressRequiredValues: {},
      copyOfRequiredValues: {},
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    venue() {
      return this.venueData || this.fetchedVenue || {};
    },
    name() {
      return this.venue.name ? this.venue.name : this.prefilledName;
    },
    customInfo() {
      return this.venue.customInfo;
    },
    workingHours() {
      return this.venue.workingHours || {};
    },
    isAdmin() {
      return isAdmin();
    },
    requiredAddressFields() {
      return {};
    },
    selectedTimeZone() {
      return (
        this.venue?.timezone ||
        (!this.isAdmin
          ? (this.loggedUser?.venues && this.loggedUser?.venues[0]?.timezone) ||
            this.loggedUser?.distributor?.timezone
          : this.timeZones.find(zone => zone.name === 'Asia/Singapore').name)
      );
    },
    isBillingChecked() {
      return this.venue.billingAddress ? Object.keys(this.venue.billingAddress).length > 0 : false;
    },
  },
  watch: {
    venue(value) {
      this.billingAddressEnabled = !value.billingAddress;
    },
    async billingAddressEnabled(val) {
      if (!val) {
        this.copyOfRequiredValues = {
          ...this.copyOfRequiredValues,
          ...this.billingAddressRequiredValues,
        };
        if (this.venueId !== -1) await this.initialFormState();
      } else {
        this.copyOfRequiredValues = this.requiredValues;
        if (this.venueId !== -1) await this.initialFormState();
      }
      this.validateForm();
    },
    formValid() {
      this.$emit('formValid', this.formValid);
    },
  },
  methods: {
    ...mapMutations('loading', ['SET_LOADING', 'CLEAR_LOADING']),
    resetForm() {
      this.$refs.venueForm.reset();
      this.$refs.deliveryAddress.reset();
      // eslint-disable-next-line no-unused-expressions
      !this.billingAddressEnabled && this.$refs.billingAddress.reset();
      this.$refs.workingHours.reset();
    },
    // eslint-disable-next-line consistent-return
    updateFormState(name, value) {
      this.copyOfRequiredValues[name] = value;
      this.validateForm();
    },
    async initialFormState() {
      await this.$nextTick();

      this.copyOfRequiredValues = {
        name: this.name,
      };
    },
    validateForm() {
      this.formValid = true;
      Object.keys(this.copyOfRequiredValues).forEach(key => {
        if (falsy(this.copyOfRequiredValues[key])) this.formValid = false;
      });
    },
    submitForm() {
      this.$refs.venueForm.onSubmit();
    },
    onSubmitSuccess(response) {
      this.$emit('submitSuccess', response);
      if (!this.venue.id || this.venue.id === -1) this.resetForm();
    },
    onSubmitError(response) {
      this.$emit('submitError', response);
    },
    ...mapActions('entities/venues', ['fetchAdminVenueNew', 'fetchVenue']),
    ...mapActions('entities/distributors', ['fetchTimeZones']),
    ...mapActions('entities/users', ['adminFetchCurrencies', 'fetchCurrencies']),
    async setTimeZones() {
      this.timeZones = await this.fetchTimeZones();
    },
    async initVenue() {
      const { data } = isAdmin()
        ? await this.fetchAdminVenueNew({ venueId: this.venueId })
        : await this.fetchVenue(this.venueId);
      this.fetchedVenue = data.data;
    },
  },
  async created() {
    this.SET_LOADING({ [LOADING_KEY.JOKER]: true });

    if (this.venueId !== -1) {
      if (falsy(this.venue)) {
        await this.initVenue();
      }
      await this.initialFormState();
    } else {
      this.requiredValues.name = this.prefilledName;
      this.copyOfRequiredValues = this.requiredValues;
    }
    this.validateForm();
    await this.setTimeZones();

    this.billingAddressEnabled = !this.isBillingChecked;
    this.CLEAR_LOADING({ key: LOADING_KEY.JOKER });
  },
};
</script>

<style scoped lang="scss">
:deep() .select-search {
  .select-search__list {
    max-height: 280px;
  }

  .select-search__item {
    height: 42px;
    display: flex;
    align-items: center;
  }
}

.venue-form {
  max-width: 26rem;
  .ez-image-upload {
    margin-bottom: 1rem;
  }
  > .input-group + .input-group {
    margin-top: 0.75rem;
  }
  .ez-contact {
    margin-top: 0.75rem;
  }
  .address-separator,
  .name-separator {
    border: 0;
    border-top: 1px dashed #dee1e4;
    margin: 1.25rem 0;
  }
  .billing-checkbox {
    margin-top: 1rem;
    :deep() .input-group__label {
      color: #6c7995;
    }
  }
  .billing-address-form {
    margin-top: 1.5rem;
  }
  h4 {
    @include font-size(14px);
    font-weight: 400;
    padding: 0;
    margin-bottom: 16px;
  }
  .instruction-input {
    margin-top: 20px;
  }
  .working-hours {
    margin-top: 20px;
  }
}
</style>
