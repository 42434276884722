var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"u-flex-row"},[_c('div',{staticClass:"mp-price-wrapper"},[(!_vm.isItDifferentFromNumber)?_c('ez-mask-input',{staticClass:"v-market-price-input__input",attrs:{"form-key":_vm.formKey,"name":_vm.name,"label":_vm.label,"value":_vm.value,"disabled":_vm.disabled,"error":_vm.error,"type":_vm.type,"currency":_vm.currency,"price-prefix":_vm.pricePrefix,"custom-prefix":_vm.customPrefix,"min-value":_vm.minValue,"max-value":_vm.maxValue,"precision":_vm.precision,"masked":_vm.masked,"is-invalid":_vm.isInvalid,"data-cy":`${_vm.dataCy}-input`,"allow-negative-value":_vm.allowNegativePrice},on:{"input":function($event){return _vm.$emit('input', $event)},"invalid":function($event){return _vm.$emit('invalid', $event)}}}):_vm._e(),(_vm.isItDifferentFromNumber)?_c('div',{class:[
        'width-100',
        'v-market-price-input__mp-input',
        { 'v-market-price-input__mp-input--disabled': _vm.disabled },
      ]},[_c('v-price',{staticClass:"v-market-price-input__mp-input__mp",attrs:{"price":_vm.value,"is-market-price":_vm.isMarketPrice,"show-market-price-info":false,"show-market-price-long":false,"is-to-be-determined":_vm.isTbd,"data-cy":`${_vm.dataCy}-marketPrice`}}),(_vm.pricePrefix)?_c('span',[_vm._v(" "+_vm._s(_vm.currencySymbol)+" ")]):_vm._e()],1):_vm._e(),(_vm.priceUnit && !_vm.isMarketPrice)?_c('span',{staticClass:"mt-2 mp-price-wrapper__unit",attrs:{"title":_vm.priceUnit.label}},[_vm._v(" "+_vm._s(`/ ${_vm.priceUnit.label}`)+" ")]):_vm._e()],1),_c('ez-simple-dropdown',{staticClass:"v-market-price-input__dropdown mt-2",attrs:{"disabled":_vm.disabled,"is-full-width":false,"data-cy":`${_vm.dataCy}-dropdown`},on:{"open":function($event){_vm.isOpen = true},"close":function($event){_vm.isOpen = false}},scopedSlots:_vm._u([{key:"display",fn:function(){return [_c('font-awesome-icon',{class:[
          'ml-12',
          { 'v-market-price-input__icon--is-open': _vm.isOpen },
          { 'v-market-price-input__icon--is-closed': !_vm.isOpen },
        ],attrs:{"icon":"angle-down"}})]},proxy:true},{key:"dropdown",fn:function(){return [_c('ul',_vm._l((_vm.actions),function({ type, label, payload }){return _c('li',{key:type,on:{"click":function($event){return _vm.$emit(type, payload)}}},[_c('ez-button',{staticClass:"v-market-price-input__btn width-100",attrs:{"data-cy":`${_vm.dataCy}-action-${type}`,"type":"link"}},[_vm._v(" "+_vm._s(label)+" ")])],1)}),0)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }