var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"productList",staticStyle:{"position":"relative"}},[(_vm.tagHover)?_c('div',{staticClass:"popover",style:(`top: ${_vm.tagPopOver.top}px; left: ${_vm.tagPopOver.left}px`)},_vm._l((_vm.tagPopOver.tags),function(tag){return _c('v-tag',{key:tag.id,attrs:{"color":tag.color.colors[0],"backgroundColor":tag.color.colors[1]}},[_vm._v(" "+_vm._s(tag.name)+" ")])}),1):_vm._e(),_c('ez-table',{staticClass:"products-table",attrs:{"loading":_vm.isLoading,"data":_vm.products,"headers":{
      name: () => 'Product',
      pricePerUnit: _vm.pricePerUnitRenderFn,
      action: () => '',
      inventory: () => 'Stock',
    },"rowDataCy":_vm.rowDataCy,"columns":_vm.columns,"columnProps":{
      name: { class: 'product-cell' },
      pricePerUnit: { class: 'medium-cell' },
      unit: { class: 'medium-cell unit-cell' },
      quantity: { class: 'large-cell quantity-cell' },
      amount: { class: 'price-cell' },
      action: { class: 'select-cell ' },
      inventory: { class: 'text-right-cell stock-cell' },
    },"rowCls":_vm.getRowClass},scopedSlots:_vm._u([{key:"cell-name",fn:function({ row }){return [_c('div',{staticClass:"cell-name-container"},[_c('ez-entity-info',{attrs:{"imgWidth":"2rem","imgHeight":"2rem","imgUrl":row.image}},[_c('div',{staticClass:"product-info",attrs:{"title":row.name}},[_c('span',{attrs:{"data-cy":`${_vm.dataCy.TEXT__PRODUCT_NAME}-${row.id}`}},[_vm._v(_vm._s(row.name))]),_c('span',{staticClass:"product-info-secondary",attrs:{"title":_vm._f("categoryWithParent")(row.category)}},[(row.sku)?[_c('span',{attrs:{"data-cy":`${_vm.dataCy.TEXT__PRODUCT_SKU}-${row.id}`}},[_vm._v(_vm._s(row.sku))]),_vm._v(" • ")]:_vm._e(),_c('span',{attrs:{"data-cy":`${_vm.dataCy.TEXT__PRODUCT_CATEGORY}-${row.id}`}},[_vm._v(_vm._s(_vm._f("categoryWithParent")(row.category)))])],2)])])],1)]}},(_vm.canShowInventory)?{key:"cell-inventory",fn:function({ row }){return [(!row.inventoryTracked)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: 'top-start',
          content: 'Not Tracking',
          classes: ['tooltip--reset-margin u-text-center mb-2'],
        }),expression:"{\n          placement: 'top-start',\n          content: 'Not Tracking',\n          classes: ['tooltip--reset-margin u-text-center mb-2'],\n        }"}],attrs:{"flip":"horizontal","icon":['far', 'eye-slash']}}):(row.available === undefined)?_c('span',[_vm._v("-")]):(!row.available)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: 'top',
          content: 'Out of stock',
          classes: ['tooltip', 'tooltip--lift-up', 'tooltip--reset-margin'],
        }),expression:"{\n          placement: 'top',\n          content: 'Out of stock',\n          classes: ['tooltip', 'tooltip--lift-up', 'tooltip--reset-margin'],\n        }"}],staticClass:"text-red",attrs:{"icon":"minus-circle"}}):_c('v-product-quantity-inventory',{attrs:{"product":row,"canBeInvalid":false,"warehouseProperty":"warehouseInventory","isDistributor":"","showTotalAsLabel":"","inventoryTracking":"","data-cy":`${_vm.dataCy.TEXT__PRODUCT_STOCK}-${row.id}`}})]}}:null,{key:"cell-pricePerUnit",fn:function({ row }){return [_c('div',{staticClass:"changeable-price"},[_c('div',{staticClass:"u-flex-center"},[(row)?_c('v-market-price-input',{key:row.id,attrs:{"data-cy":`${_vm.dataCy.INPUT__PRICE}-${row.id}`,"value":row.price || 0,"error":_vm.priceError(row),"disabled":!row.checked || row.isBonus,"is-market-price":row.marketPrice,"is-it-different-from-number":row.marketPrice,"precision":row.currency?.fractionDigits ?? _vm.DEFAULT_FRACTION_DIGITS,"price-prefix":row.currency?.showSymbol ?? true,"price-unit":row.priceUnit},on:{"input":function($event){return _vm.$emit(_vm.Event.priceChange, row, $event)},"setRegularPrice":function($event){return _vm.$emit(_vm.Event.setRegularPrice, row)},"setMarketPrice":function($event){return _vm.$emit(_vm.Event.setMarketPrice, row)}}}):_vm._e()],1)])]}},{key:"cell-tags",fn:function({ row }){return [(row.tags?.length)?_c('div',{ref:`tag-${row.id}`,staticClass:"tags",on:{"mouseenter":function($event){return _vm.tagsShowHover(row.id, row.tags)},"mouseleave":_vm.tagsRemoveHover}},[_c('v-tag',{staticClass:"mr-8",attrs:{"color":row.tags[0].color.colors[0],"backgroundColor":row.tags[0].color.colors[1]}},[_vm._v(_vm._s(row.tags[0].name))]),(row.tags?.length > 1)?_c('span',[_vm._v("+"+_vm._s(row.tags?.length - 1))]):_vm._e()],1):_c('span',[_vm._v("-")])]}},{key:"cell-unit",fn:function({ row, row: { orderingUnit } }){return [(orderingUnit)?[_c('v-data-with-info',{attrs:{"info":orderingUnit.abbreviation
              ? `${orderingUnit.name} ${!!_vm.$t(`product.unitInfo.${orderingUnit.type}`) ? '-' : ''}
            ${_vm.$t(`product.unitInfo.${orderingUnit.type}`)}`
              : '',"show-underline":!!_vm.$t(`product.unitInfo.${orderingUnit.type}`) || !!orderingUnit.abbreviation,"data-cy":`${_vm.dataCy.TEXT__UNIT}-${row.id}`}},[_vm._v(" "+_vm._s(orderingUnit.label)+" ")])]:_vm._e()]}},{key:"cell-quantity",fn:function({ row, row: { quantity, orderingUnit } }){return [_c('ez-mask-input-simple',{key:row.id,attrs:{"data-cy":`${_vm.dataCy.INPUT__QUANTITY}-${row.id}`,"name":"quantity","formKey":"","minValue":0,"disabled":row.isBonus,"value":quantity,"is-invalid":_vm.isQuantityInvalid(row),"precision":_vm.isFractional(orderingUnit) ? 4 : 0,"has-currency":false},on:{"input":function($event){return _vm.$emit(_vm.Event.quantityChange, row, $event)},"invalid":function($event){return _vm.onQuantityInvalid($event, row.id)}}})]}},{key:"cell-amount",fn:function({
        row,
        row: { currency, price, quantity, marketPrice, priceUnit, totalToChange, totalPrice },
      }){return [(_vm.isOfflineOrder && !!priceUnit && !marketPrice && price > 0)?_c('div',{staticClass:"changeable-price"},[_c('div',{staticClass:"u-flex-center"},[(row)?_c('v-market-price-input',{key:row.id,staticClass:"tbd-amount-input",attrs:{"data-cy":`${_vm.dataCy.INPUT__AMOUNT}-${row.id}`,"actions":_vm.amountActions,"value":totalPrice || 0,"disabled":!row.checked,"is-tbd":!!priceUnit && !marketPrice,"is-it-different-from-number":!!priceUnit && !marketPrice && !totalToChange},on:{"input":function($event){return _vm.$emit(_vm.Event.totalAmountChanged, row, $event)},"setTotalAmount":function($event){return _vm.$emit(_vm.Event.setTotalAmount, row)},"setTbd":function($event){return _vm.$emit(_vm.Event.setTbd, row)}}}):_vm._e()],1)]):_c('v-price',{attrs:{"price":price * quantity,"currency":currency,"show-market-price-info":false,"is-to-be-determined":_vm.isToBeDetermined(row),"data-cy":`${_vm.dataCy.TEXT__AMOUNT}-${row.id}`}}),(row.isBonus)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          content: _vm.tradeDealTooltipContent(row),
          classes: ['tooltip--reset-margin'],
        }),expression:"{\n          content: tradeDealTooltipContent(row),\n          classes: ['tooltip--reset-margin'],\n        }"}],staticClass:"additional-info additional-info--bonus"},[_vm._v("Bonus")]):_vm._e()]}},{key:"cell-action",fn:function({ row, row: { checked } }){return [_c('div',[_c('ez-button',{attrs:{"data-cy":`${_vm.dataCy.BUTTON__SELECT_PRODUCT}-${row.id}`,"type":checked ? 'primary' : 'secondary'},on:{"click":function($event){return _vm.$emit(_vm.Event.productSelect, row)}}},[_vm._v(" "+_vm._s(checked ? 'Selected' : 'Select')+" ")]),(checked)?_c('ez-button-dropdown',{attrs:{"buttonType":checked ? 'primary' : 'secondary'},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"angle-down"}})]},proxy:true},{key:"dropdown",fn:function(){return [_c('ez-button',{attrs:{"type":"secondary"},on:{"click":function($event){return _vm.$emit(_vm.Event.addAnotherProductSelect, row)}}},[_vm._v("Add Another")])]},proxy:true}],null,true)}):_vm._e()],1)]}}],null,true)}),(_vm.noProductsFound)?_c('empty-state',{scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/search-product-state.svg"),"alt":""}})]},proxy:true},{key:"title",fn:function(){return [_vm._v("No products available")]},proxy:true},{key:"info",fn:function(){return [_vm._v("There are currently no available products from this supplier.")]},proxy:true}],null,false,3709493502)}):_vm._e(),(_vm.nextId && !_vm.isLoadingMore)?_c('ez-load-more',{on:{"loadMore":function($event){return _vm.$emit(_vm.Event.loadMore, _vm.nextId)}}}):_vm._e(),(_vm.isLoadingMore)?_c('div',{staticClass:"u-text-center mt-12"},[_c('ez-spinner')],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }