var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ez-wizard',{staticClass:"new-supplier",attrs:{"is-last-step":_vm.isLastStep},on:{"exit":_vm.onWizardExit,"back":_vm.goToPreviousStep},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Account Opening Form")]},proxy:true},{key:"nav",fn:function(){return [_c('ul',[_c('li',{class:{
        'ez-step-active': _vm.currentStep === _vm.SUPPLIER_STEP_1,
        'ez-step-completed': _vm.currentStep > _vm.SUPPLIER_STEP_1,
      }},[_vm._v(" 1. Account ")]),_c('li',{class:{
        'ez-step-active': _vm.currentStep === _vm.SUPPLIER_STEP_2,
        'ez-step-completed': _vm.currentStep > _vm.SUPPLIER_STEP_2,
      }},[_vm._v(" 2. "+_vm._s(_vm.$t('global.venue'))+" Info ")]),_c('li',{class:{
        'ez-step-active': _vm.currentStep === _vm.SUPPLIER_STEP_3,
        'ez-step-completed': _vm.currentStep > _vm.SUPPLIER_STEP_3,
      }},[_vm._v(" 3. Contacts ")])])]},proxy:true},{key:"actions",fn:function(){return [_c('ez-button',{attrs:{"disabled":_vm.nextDisabled},on:{"click":_vm.goToNextStep}},[_vm._v(_vm._s(_vm.nextCta))])]},proxy:true},{key:"prevStep",fn:function(){return [(_vm.currentStep > _vm.SUPPLIER_STEP_2 && _vm.currentStep < _vm.SUPPLIER_STEP_4)?_c('ez-button',{attrs:{"type":"link","formType":"button"},on:{"click":_vm.goToPreviousStep}},[_c('font-awesome-icon',{attrs:{"icon":"arrow-left"}}),_c('span',[_vm._v("Back")])],1):_vm._e()]},proxy:true},{key:"pageTitle",fn:function(){return [_vm._v(_vm._s((_vm.isFirstStep && !_vm.isNewOutlet) ? `Check ${_vm.$t('global.venue').toLowerCase()} info` : _vm.currentStepPageTitle)+" ")]},proxy:true},{key:"pageInfo",fn:function(){return [_c('span',{domProps:{"innerHTML":_vm._s((_vm.isFirstStep && !_vm.isNewOutlet)
      ? `Check if all the info entered by the supplier is correct. If not, <br> you can update it
        right away and continue to the next step.`
      : _vm.currentStepPageInfo)}})]},proxy:true}])},[_c(_vm.currentStepComponent,{tag:"component",attrs:{"supplierId":_vm.supplierId},on:{"stepCompleted":_vm.onStepCompleted,"stepBack":_vm.onStepBack}}),_c('footer',{attrs:{"supplier-id":_vm.supplierId,"hasActions":_vm.footerActions},on:{"accepted":_vm.enableNextStep,"download":_vm.downloadTosPdf}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }