<script>
import { wizardListenerMixin } from '@/mixins/wizard';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import VenueInfoForm from '@/views/platform/venue/suppliers/onboarding-from-public/VenueInfoForm';
import EzImageUpload from '@/components/ui/ImageUpload';
import bus from '@/views/public/bus';
import flash from '@/components/ui/FlashMessage';
import { resetState } from '@/store';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  mixins: [wizardListenerMixin],
  components: {
    VenueInfoForm,
    EzImageUpload,
  },
  props: {
    supplierId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      formKey: 'venue-settings',
      imgModified: false,
      isEdit: false,
    };
  },
  computed: {
    ...mapState('entities/venues', [
      'publicOnboarding',
      'publicToken',
    ]),
    ...mapGetters('defaultImages', [
      'getDefaultImage',
    ]),
    ...mapState('entities/users', [
      'loggedUser',
    ]),
    defaultImage() {
      return this.getDefaultImage('venue');
    },
    isNewOutlet() {
      return this.publicOnboarding.venue?.targetAccount && this.publicOnboarding.venue?.editable;
    },
    isDisabled() {
      return !this.isNewOutlet && !this.isEdit;
    },
    venueId() {
      return this.publicOnboarding.venue.id;
    },
    action() {
      return `/venues/${this.venueId}`;
    },
    method() {
      return 'patch';
    },
    config() {
      return {
        headers: {
          'X-Public-Token': this.publicToken,
        },
      };
    },
    isFromLogin() {
      return this.publicOnboarding.login;
    },
  },
  methods: {
    onNextStep() {
      if (!this.isDisabled) {
        this.$refs.form.submitForm();
        return;
      }
      this.$emit('stepCompleted');
    },
    onSubmitSuccess({ data }) {
      this.UPDATE_PUBLIC_ONBOARDING({
        venue: data.data,
      });
      this.$emit('stepCompleted');
    },
    isFormValid(val) {
      if (val) this.enableNextStep();
      else this.disableNextStep();
    },
    async addVenueToGroup() {
      if (this.isFromLogin) {
        if (this.loggedUser.scope === 'venue-group'
          && (
            this.publicOnboarding.venue.canBeClaimed
            || (this.loggedUser.group.id === this.publicOnboarding.venue.groupId)
          )
        ) {
          try {
            await this.venueAddVenueToGroup({
              venueId: this.venueId,
              config: this.config,
            });
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
          }
        } else {
          flash.error({
            title: `Unable to claim this ${this.$t('global.venue')}`,
            message: `This ${this.$t('global.venue')} cannot be claimed by this account. You will be signed out, but you can try with another account.`,
            delay: 7000,
          });
          this.signOut();
        }
      }
    },
    signOut() {
      this.managerLogout()
        .finally(() => {
          localStorage.clear();
          resetState();
          this.$router.replace({ name: 'platform-login' });
        });
    },
    enableInputs() {
      this.isEdit = true;
    },
    ...mapMutations('entities/venues', [
      'UPDATE_PUBLIC_ONBOARDING',
    ]),
    ...mapActions('defaultImages', ['fetchDefaultImages']),
    ...mapActions('entities/venues', ['venueAddVenueToGroup']),
    ...mapActions('entities/users', [
      'managerLogout',
    ]),
  },
  created() {
    this.addVenueToGroup();
    this.fetchDefaultImages();
    this.disableFooterActions();
    bus.$on('editExistingOutlet', this.enableInputs);
    if (this.$route.query.edit) this.isEdit = true;
    if (this.isDisabled) {
      this.enableNextStep();
    }
  },
};
</script>
<template>
  <div class="new-venue-form">
    <venue-info-form
      :disabled="isDisabled"
      ref="form"
      :method="method"
      :action="action"
      :data="publicOnboarding.venue"
      submitType="multipart"
      @formValid="isFormValid"
      @submitSuccess="onSubmitSuccess"
      :formKey="formKey">
      <template #prepend>
        <ez-image-upload
          :disabled="isDisabled"
          class="image-uploader"
          :formKey="formKey"
          :isRound="true"
          :previewUrl="publicOnboarding.venue.logo || defaultImage"
          :columnMode="true"
          name="logo">
          <template #addImage>
            <span>Add {{ $t('global.venue') }} Logo</span>
          </template>
          <template #removeImage>
            <font-awesome-icon icon="times"/>
            <span>Remove Logo</span>
          </template>
        </ez-image-upload>
      </template>
    </venue-info-form>
  </div>
</template>
<style lang="scss" scoped>
  .new-venue-form {
    display: flex;
    justify-content: center;
  }
</style>
