<template>
  <group-form
    :group="group"
    @submitSuccess="onSubmitSuccess"
    formAction="/venue-group">
    <template #buttons>
      <ez-button form-type="submit">Save Changes</ez-button>
    </template>
  </group-form>
</template>

<script>
import { mapActions } from 'vuex';
import EzButton from '@/components/ui/Button';
import GroupForm from '@/views/common/venue-groups/Form.vue';
import Group from '@/models/Group';
import flash from '@/components/ui/FlashMessage';

export default {
  components: {
    EzButton,
    GroupForm,
  },
  computed: {
    group() {
      return Group.query().first() || {};
    },
    formAction() {
      return 'venue-group';
    },
  },
  methods: {
    onSubmitSuccess() {
      flash.success({
        title: `${this.$t('global.venue')} group successfully updated!`,
      });
    },
    ...mapActions('entities/groups', [
      'fetchGroup',
    ]),
  },
  created() {
    this.fetchGroup();
  },
};
</script>

<style scoped lang="scss">
.ez-form {
  max-width: 26rem;
  .button {
    margin-top: 1.5rem;
  }
}
</style>
