<template>
  <venue-form
    ref="form"
    method="patch"
    :action="action"
    :formKey="formKey"
    :additional-data="{ ...primaryUser }"
    :additional-headers="{ loadingKey }"
    :venueId="venueId"
    @submitSuccess="onSubmitSuccess"
    :venue-data="venue"
    @submitError="onSubmitError"
  >
    <template #primaryContact>
      <div>
        <primary-contact-dropdown
          :primary-selected="primarySelectedUser"
          :options="users"
          class="mt-12"
          label="Primary Contact"
          name="primaryUserId"
          :form-key="formKey"
          @onSelect="primaryContactChanged"
        />
        <div class="contact-info mt-8">
          <p class="contact-info__txt">
            User needs to have a phone number in order to be able to be set as a primary contact.
          </p>
        </div>
      </div>
    </template>
    <template #buttons>
      <ez-button form-type="submit">Save Changes</ez-button>
    </template>
  </venue-form>
</template>

<script>
import EzButton from '@/components/ui/Button';
import VenueForm from '@/views/admin/venues/Form.vue';
import Venue from '@/models/Venue';
import flash from '@/components/ui/FlashMessage';
import User from '@/models/User';
import { mapActions } from 'vuex';
import { LOADING_KEY } from '@/util/constants';
import PrimaryContactDropdown from '@/views/common/users/PrimaryContactDropdown';

export default {
  components: {
    EzButton,
    VenueForm,
    PrimaryContactDropdown,
  },
  props: {
    venueId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formKey: 'venue-settings',
      loadingKey: LOADING_KEY.VENUE_GROUP_EDIT_VENUE,
      primaryUser: {
        primaryUserId: '',
      },
    };
  },
  computed: {
    action() {
      return `/venues/${this.venueId}`;
    },
    venue() {
      return Venue.find(this.venueId) || {};
    },
    users() {
      return User.query().all();
    },
    primarySelectedId() {
      return this.venue?.primaryUserId;
    },
    primarySelectedUser() {
      return User.query().find(this.primarySelectedId) || {};
    },
  },
  methods: {
    ...mapActions('entities/users', [
      'fetchVenueUsers',
    ]),
    ...mapActions('entities/venues', ['fetchVenue']),
    onSubmitSuccess({ data: { data } }) {
      Venue.update(data);
      flash.success({
        title: this.$t('venues.groupSettings.venueInfo.flashMessages.success'),
      });
    },
    onSubmitError() {},
    primaryContactChanged(ev) {
      this.primaryUser.primaryUserId = ev.id;
    },
  },
  async created() {
    await this.fetchVenueUsers();
    await this.fetchVenue(this.venueId);
  },
};
</script>

<style scoped lang="scss">
.venue-form {
  :deep() .button.button--primary {
    margin-top: 1.5rem;
  }
}
.contact-info {
  &__txt {
    @include font-size(12px);
    color: $color-gray-6C;
    margin: 0;
    line-height: px-to-rem(18px);
  }
}
</style>
