<template>
  <div class="notes">
    <ez-image-modal ref="imageModal" />
    <note
      v-for="note in notes"
      :key="note.id"
      :isResponse="note.isResponse">
      <template #avatar>
        <ez-entity-info
          v-if="!!note.sender"
          imgWidth="2rem"
          imgHeight="2rem"
          :imgUrl="note.sender.logo || defaultImage"
          imgBorderRadius="50%">
        </ez-entity-info>
      </template>
      <template #date>{{ note.createdAt|date }}</template>
      <div>{{ note.message }}</div>
      <div v-if="note.images.length > 0" class="note__images">
        <div
          class="note__image"
          v-for="(image, id) in note.images"
          :key="id" @click="openImage(image)">
          <img :src="image" :alt="`note-${id}`">
        </div>
      </div>
    </note>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { EzImageModal } from '@/components/ui/Modal';
import EzEntityInfo from '@/components/ui/EntityInfo';
import {
  ORDER_STATUS_ACCEPTED,
  ORDER_STATUS_PARTIALLY_ACCEPTED,
  ORDER_STATUS_DECLINED,
} from '@/util/constants';
import Note from './Note.vue';

export default {
  name: 'EzNotes',
  components: {
    EzImageModal,
    EzEntityInfo,
    Note,
  },
  computed: {
    ...mapGetters('defaultImages', [
      'getDefaultImage',
    ]),
    defaultImage() {
      return this.getDefaultImage('distributor');
    },
    notes() {
      const { order } = this;
      const isVenue = localStorage.getItem('role') === 'venue';
      const isDistributor = localStorage.getItem('role') === 'distributor';
      const isAdmin = localStorage.getItem('role') === 'admin';
      return (order.notes || [])
        .map(note => ({
          ...note,
          sender: note.distributorId ? order.distributor : order.venue,
          // eslint-disable-next-line
          isResponse: (isVenue && !!note.distributorId) || (isDistributor && !!note.venueId) || (isAdmin && !!note.distributorId),
        }));
    },
    resolvedDate() {
      if ([ORDER_STATUS_ACCEPTED, ORDER_STATUS_PARTIALLY_ACCEPTED].includes(this.order.status)) {
        return this.order.acceptedAt;
      }

      if (this.order.status === ORDER_STATUS_DECLINED) {
        return this.order.declinedAt;
      }

      return null;
    },
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openImage(image) {
      this.$refs.imageModal.open(image);
    },
  },
};
</script>
<style lang="scss" scoped>
.note__images {
  @extend %flex-center;
  margin-top: px-to-rem(8px);
}

.note__image {
  @include size(40px);
  border: 1px solid #DEE1E4;
  border-radius: $border-radius;
  overflow: hidden;
  cursor: pointer;

  img {
    @include size(100%);
    object-fit: cover;
  }
  & + & { margin-left: px-to-rem(8px); }
}
</style>
