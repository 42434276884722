<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';

import { LOADING_KEY } from '@/util/constants';

import { EzMultipleImageUpload } from '@/components/ui/ImageUpload';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import EzAlert from '@/components/ui/Alert';
import EzForm from '@/components/ui/Form';
import flash from '@/components/ui/FlashMessage';

/**
 * UploadInvoice
 * @version 1.0.0
 * @since 3.26.0
 */

export default {
  name: 'UploadInvoice',
  components: {
    EzMultipleImageUpload,
    EzConfirmationModal,
    EzButton,
    EzAlert,
    EzForm,
  },
  props: {
    fileType: {
      type: String,
      required: false,
      default: 'invoice',
      validator: value => ['invoice', 'credit_note', ''].includes(value),
    },
  },
  data() {
    return {
      imageData: null,
      hasInvoice: false,
      invoicesRequested: false,
      LOADING_KEY,
      outletCy,
      dropBoxActive: false,
    };
  },
  computed: {
    ...mapState('entities/users', ['context', 'contextId']),
    ...mapGetters('loading', ['getLoading']),
    formAction() {
      return `venues/${this.contextId}/pending-invoices`;
    },
    isLoading() {
      return this.getLoading(LOADING_KEY.LOG_OFFLINE_ORDER);
    },
    requestInvoicesLoading() {
      return this.getLoading(LOADING_KEY.VENUE_REQUEST_MORE_INVOICES);
    },
  },
  methods: {
    ...mapActions('entities/orders', ['venueRequestMoreInvoices']),
    open() {
      this.$refs.uploadInvoiceModal.open();
    },
    close() {
      this.$refs.uploadInvoiceModal.close();
      this.$emit('uploadFinished');
    },
    reset() {
      this.imageData = null;
      this.$refs.imageUpload.reset();
    },
    closeAndReset() {
      this.$refs.uploadInvoiceModal.close();
      this.reset();
    },
    submitInvoice() {
      this.$refs.uploadInvoiceForm.onSubmit();
      this.$refs.uploadInvoiceModal.close();
    },
    async requestInvoices() {
      try {
        const { data } = await this.venueRequestMoreInvoices({ venueId: this.contextId });
        this.invoicesRequested = true;
        if (data.data.redirectUrl) {
          window.open(data.data.redirectUrl, '_blank');
        }
      } catch (err) {
        console.error(err);
      }
    },
    imagesUploaded(formData) {
      this.hasInvoice = formData.get('invoices');
      this.imageData = formData;
    },
    async addFile(e) {
      this.dropBoxActive = false;
      if (!e.dataTransfer.files) return;
      this.$refs.imageUpload.handleExternalFileUpload(e);
    },
    clearDropBoxActiveClass(ev) {
      if (ev.target.classList.value === 'drop-box-active') {
        this.dropBoxActive = false;
      }
    },
    confirmSuccess() {
      this.reset();
      flash.success({
        title: 'Invoice uploaded!',
        message: 'You have successfully uploaded a new invoice.',
      });
      this.$emit('success');
    },
  },
};
</script>

<template>
  <ez-confirmation-modal ref="uploadInvoiceModal" @close="reset" :icon="null">
    <template #title>{{
      `Upload ${fileType !== 'credit_note' ? 'Invoice' : 'Credit Note'}`
    }}</template>
    <template #content>
      <div
        @drop.prevent="addFile"
        @dragover.prevent="() => (dropBoxActive = true)"
        :class="{ 'drop-box-active': dropBoxActive }"
        @dragleave="clearDropBoxActiveClass"
        @dragend="() => (dropBoxActive = false)"
      >
        <ez-alert variant="disclaimer" size="big" v-if="context?.pendingInvoicesRemaining === 0">
          <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
          <p>
            You have run out of invoice automations. You can buy more or upload and process your
            invoices manually.
          </p>
          <ez-button
            type="blue-link"
            class="orders__alert--request-invoices mt-8"
            @click="requestInvoices"
            :isLoading="requestInvoicesLoading"
            :disabled="invoicesRequested"
          >
            Buy more invoices
          </ez-button>
        </ez-alert>
        <p v-if="fileType !== 'credit_note'">
          Have an Invoice without an Order?<br />Upload it here or forward PDF or image invoice to
          <a :href="`mailto:${context.invoiceEmail}`">{{ context.invoiceEmail }}</a
          >.
        </p>
        <p v-else>Have a Credit Note?<br />Upload it here.</p>
        <p>
          Drag and drop up to 20 invoices at a time. <br />
          After uploading, track processing status in the Invoices tab.
        </p>
        <hr />
        <ez-form
          ref="uploadInvoiceForm"
          method="post"
          submit-type="multipart"
          form-key="invoiceUpload"
          @success="confirmSuccess"
          :action="formAction"
          :additional-data="imageData"
          :additional-headers="{ loadingKey: LOADING_KEY.LOG_OFFLINE_ORDER }"
        >
          <ez-multiple-image-upload
            ref="imageUpload"
            name="invoices"
            accept="image/jpeg,image/png,application/pdf"
            class="upload-component"
            form-key="invoiceUpload"
            card-type
            @change="imagesUploaded"
            :number-of-files="20"
            :data-cy="outletCy.ORDERS.GENERAL.NEW_OFFLINE_ORDER.LABEL__MODAL_UPLOAD_INVOICE"
          >
            <template #label>{{
              `${fileType !== 'credit_note' ? 'Invoices' : 'Credit Notes'}`
            }}</template>
            <template #cta>{{
              `Upload ${fileType !== 'credit_note' ? 'Invoice' : 'Credit Note'}`
            }}</template>
          </ez-multiple-image-upload>
          <input v-if="fileType !== ''" type="hidden" name="type" :value="fileType" />
        </ez-form>
      </div>
    </template>
    <template #footer>
      <ez-button
        type="link"
        @click="closeAndReset"
        :data-cy="outletCy.ORDERS.GENERAL.NEW_OFFLINE_ORDER.BUTTON__MODAL_CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        @click="submitInvoice"
        :disabled="!hasInvoice"
        :is-loading="isLoading"
        :data-cy="outletCy.ORDERS.GENERAL.NEW_OFFLINE_ORDER.BUTTON__MODAL_CONFIRM"
      >
        Upload
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<style lang="scss" scoped>
.drop-box-active {
  border: 2px dashed $color-gray-6C;
  border-radius: 4px;
  position: relative;
  &::after {
    position: absolute;
    content: '';
    background-color: rgba(#6c7995, 0.3);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
.upload-component {
  :deep() .preview {
    &__images {
      display: flex;
      flex-direction: column;
    }
  }

  :deep() .preview__image + .preview__image {
    margin-left: 0;
  }
}

.orders__alert--request-invoices {
  padding: 0;
  height: auto;
  line-height: inherit;

  &:disabled {
    background-color: unset;
    color: $color-primary-blue;
    cursor: not-allowed;
  }
}
</style>
