<script>
import { mapActions, mapGetters } from 'vuex/dist/vuex.common';
import { getContextId, debounce } from '@/util/utils';
import VFilterDropdown from '@/components/v3/patterns/VFilterDropdown';
import EzButton from '@/components/ui/Button';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index';
import EzTable from '@/components/ui/Table/EzTable.vue';
import VAccountOwnerEntityInfo from '@/components/v3/patterns/VAccountOwnerEntityInfo';
import StatusBadge from '@/views/common/status-badge';
import EzSelect from '@/components/ui/Select/EzSelect.vue';
import EmptyState from '@/views/common/empty-state';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore.vue';
import EzSpinner from '@/components/ui/Spinner/EzSpinner.vue';
import flash from '@/components/ui/FlashMessage';
import {
  INVOICE_EXPORT_STATUS_READY,
  INVOICE_EXPORT_STATUS_ERROR,
  INVOICE_EXPORT_STATUS_COMPLETED,
  OUTLET_STOCKTAKE_DATE_FILTER_DEFAULT,
  LOADING_KEY,
} from '@/util/constants';
import dayjs from 'dayjs';
import EzLoader from '@/components/ui/Loader/EzLoader.vue';
import EzFilterList from '@/components/ui/FilterList/EzFilterList.vue';

export default {
  components: {
    VFilterDropdown,
    EzButton,
    VDatePicker,
    EzTable,
    VAccountOwnerEntityInfo,
    StatusBadge,
    EzSelect,
    EmptyState,
    EzLoadMore,
    EzSpinner,
    EzLoader,
    EzFilterList,
  },
  data() {
    return {
      stocktakes: [],
      users: [],
      meta: {},
      INVOICE_EXPORT_STATUS_READY,
      INVOICE_EXPORT_STATUS_ERROR,
      INVOICE_EXPORT_STATUS_COMPLETED,
      stockDateRangeFilterName: OUTLET_STOCKTAKE_DATE_FILTER_DEFAULT.name,
      stockDateRange: {
        start: OUTLET_STOCKTAKE_DATE_FILTER_DEFAULT.from,
        end: OUTLET_STOCKTAKE_DATE_FILTER_DEFAULT.to,
      },
      filters: {},
      exportStatuses: [
        { id: null, name: 'All Status' },
        { id: 'ready', name: 'Ready' },
        { id: 'completed', name: 'Completed' },
        { id: 'error', name: 'Error' },
      ],
      processingStatus: [
        { id: null, name: 'All Status' },
        { id: 'open', name: 'Open' },
        { id: 'completed', name: 'Completed' },
      ],
      OUTLET_STOCKTAKE_DATE_FILTER_DEFAULT,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    venueId() {
      return getContextId();
    },
    isLoading() {
      return (
        this.getLoading(LOADING_KEY.VENUE_FETCH_STOCKTAKES) ||
        this.getLoading(LOADING_KEY.VENUE_FETCH_STOCKTAKE_USERS)
      );
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.VENUE_FETCH_MORE_STOCKTAKES);
    },
  },
  methods: {
    ...mapActions('entities/venues', [
      'venueFetchStocktakes',
      'venueFetchStocktakeUsers',
      'venueStartStocktake',
      'exportStocktakeToAccountingIntegration',
    ]),
    async fetchVenueStocktakes(loadingKey = LOADING_KEY.VENUE_FETCH_STOCKTAKES) {
      const query = {
        ...this.filters,
        ...(this.filters.stockDate
          ? { from: dayjs(this.filters.stockDate.start).valueOf() }
          : null),
        ...(this.filters.stockDate ? { to: dayjs(this.filters.stockDate.end).valueOf() } : null),
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
      };
      if (query.stockDate) delete query.stockDate;
      const { data } = await this.venueFetchStocktakes({
        venueId: this.venueId,
        query,
        loadingKey,
      });
      if (loadingKey === LOADING_KEY.VENUE_FETCH_STOCKTAKES) this.stocktakes = data.data;
      else if (loadingKey === LOADING_KEY.VENUE_FETCH_MORE_STOCKTAKES)
        this.stocktakes = [...this.stocktakes, ...data.data];

      this.meta = data.meta;
      this.$emit('stocktakeCountUpdate', this.meta.totalCount);
    },
    async fetchVenueStocktakeUsers() {
      const { data } = await this.venueFetchStocktakeUsers({ venueId: this.venueId });
      this.users = [
        { id: null, name: 'All Users' },
        ...data.data.map(item => ({ id: item.name, ...item })),
      ];
    },
    updateFilters: debounce(async function deb(event, eventName) {
      if (event === 'stockDate') {
        event = {
          stockDate: eventName,
        };
      } else if (event === 'exportStatus') {
        event = {
          exportStatus: eventName,
        };
      }
      this.filters = {
        ...this.filters,
        ...(typeof event.stockDate === 'object' &&
          event.stockDate !== null && { stockDate: event.stockDate }),
        ...(typeof event.user === 'object' && event.user !== null && { user: event.user.id }),
        ...(typeof event.status === 'object' &&
          event.status !== null && { status: event.status.id }),
        ...(typeof event.exportStatus === 'object' &&
          event.exportStatus !== null && { exportStatus: event.exportStatus.id }),
      };
      this.stockDateRange = {
        start: dayjs(this.filters.stocktake?.start || this.stockDateRange?.start).valueOf(),
        end: dayjs(this.filters.stocktake?.end || this.stockDateRange?.end).valueOf(),
      };
      this.stocktakes = [];
      this.meta = {};
      await this.fetchVenueStocktakes();
    }, 300),
    async resetFilters(isList = false) {
      if (isList) {
        const updatedFilters = { ...this.filters };
        delete updatedFilters.stockDate;
        delete updatedFilters.exportStatus;
        this.filters = { ...this.updateFilters };
      } else {
        this.filters = {};
      }
      this.stockDateRangeFilterName = this.OUTLET_STOCKTAKE_DATE_FILTER_DEFAULT.name;
      this.stockDateRange = {
        start: this.OUTLET_STOCKTAKE_DATE_FILTER_DEFAULT.from,
        end: this.OUTLET_STOCKTAKE_DATE_FILTER_DEFAULT.to,
      };
      this.meta = {};
      this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());
      await this.fetchVenueStocktakes();
    },
    async loadMore() {
      await this.fetchVenueStocktakes(LOADING_KEY.VENUE_FETCH_MORE_STOCKTAKES);
    },
    async startStocktake() {
      const { data } = await this.venueStartStocktake({ venueId: this.venueId });
      this.$router.push({
        name: 'venue-conduct-stocktake',
        params: { id: data.data.id },
      });
    },
    openStocktake(row) {
      if (row.status !== 'completed') {
        this.$router.push({
          name: 'venue-conduct-stocktake',
          params: { id: row.id },
        });
      } else if (row.status === 'completed') {
        this.$router.push({
          name: 'venue-stocktake-single',
          params: { id: row.id },
        });
      }
    },
    async exportStocktake(id) {
      const stocktakeIndex = this.stocktakes.findIndex(item => item.id === id);
      try {
        if (stocktakeIndex !== -1) {
          this.stocktakes[stocktakeIndex].exportStatus = 'loading';
        }
        await this.exportStocktakeToAccountingIntegration({
          venueId: this.venueId,
          stocktakeId: id,
        });
        if (stocktakeIndex !== -1) {
          this.stocktakes[stocktakeIndex].exportStatus = 'completed';
        }
        flash.success({
          title: 'Stocktake successfully exported!',
        });
      } catch (e) {
        if (stocktakeIndex !== -1) {
          this.stocktakes[stocktakeIndex].exportStatus = 'error';
        }
        flash.error({
          title: 'Something went wrong',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
  },
  async created() {
    await Promise.all([this.fetchVenueStocktakes(), this.fetchVenueStocktakeUsers()]);
  },
};
</script>

<template>
  <div class="stocktakes">
    <div class="stocktakes__actions mt-24">
      <div class="filters-wrapper">
        <ez-filter-list
          :filters="filters"
          @filterUpdated="updateFilters"
          @resetFilter="resetFilters(true)"
        >
          <v-date-picker
            name="stockDate"
            label="Date"
            v-model="stockDateRange"
            :numberOfCalendars="2"
            :selectPredefinedRange="stockDateRangeFilterName"
            rangeMode
            withPredefinedRanges
            isAllTimeInFuture
            class="filter__date"
            :displayEndOfRange="true"
            hide-label
          />
          <ez-select
            class="ml-16"
            name="exportStatus"
            :value="filters.exportStatus"
            :selected="filters.exportStatus"
            :options="exportStatuses"
          />
        </ez-filter-list>
        <v-filter-dropdown
          :filters="filters"
          @filterUpdated="updateFilters"
          @resetFilters="resetFilters"
          ref="filtersGroup"
          class="ml-16"
        >
          <template #firstRow>
            <v-date-picker
              name="stockDate"
              label="Date"
              v-model="stockDateRange"
              :numberOfCalendars="2"
              :selectPredefinedRange="stockDateRangeFilterName"
              rangeMode
              withPredefinedRanges
              isAllTimeInFuture
              isFullWidth
              class="filter__date"
              :displayEndOfRange="true"
            />
            <ez-select
              name="user"
              :value="filters.user"
              :selected="filters.user"
              label="Users"
              :options="users"
              isFullWidth
            />
            <ez-select
              name="status"
              :value="filters.status"
              :selected="filters.status"
              label="Status"
              :options="processingStatus"
              isFullWidth
            />
            <ez-select
              name="exportStatus"
              :value="filters.exportStatus"
              :selected="filters.exportStatus"
              label="Export Status"
              :options="exportStatuses"
              isFullWidth
            />
          </template>
        </v-filter-dropdown>
      </div>
      <ez-button @click="startStocktake">Start Count</ez-button>
    </div>
    <ez-table
      class="mt-24"
      v-if="stocktakes.length"
      :data="stocktakes"
      :headers="{
        completedLocations: () => 'Locations Completed',
        date: () => 'Date & Time',
      }"
      :columns="[
        'users',
        'completedLocations',
        'date',
        'dueDate',
        'status',
        ...($permission.has('exportAccounting') ? ['exportStatus'] : []),
        'note',
      ]"
      :columnProps="{
        users: { class: 'small-cell' },
        exportStatus: { class: 'medium-cell' },
        status: { class: 'medium-cell' },
        dueDate: { class: 'medium-cell' },
        date: { class: 'extra-large-cell' },
      }"
      @rowClick="openStocktake"
    >
      <template #cell-users="{ row: { user } }">
        <v-account-owner-entity-info :account-owner="user" :to-show-name="false" />
      </template>
      <template #cell-completedLocations="{ row }">
        <div class="stocktake-locations" v-if="row.completedLocations.length">
          <v-account-owner-entity-info
            v-for="location in row.completedLocations"
            :key="location.id"
            class="stocktake-locations__location"
            :account-owner="{
              ...location,
              colors: ['#6C7995', '#ECEEF5'],
            }"
            :to-show-name="false"
          />
        </div>
        <span v-else>-</span>
      </template>
      <template #cell-date="{ row: { createdAt, completedAt, overdue } }">
        <span :class="{ overdue: overdue }">{{ createdAt | date }} - {{ completedAt | date }}</span>
      </template>
      <template #cell-dueDate="{ row: { dueDate } }">
        <span v-if="dueDate">
          {{ dueDate | date }}
        </span>
        <span v-else>-</span>
      </template>
      <template #cell-status="{ row }">
        <status-badge :status="row.status || 'draft'"></status-badge>
      </template>
      <template #cell-exportStatus="{ row }">
        <img
          v-if="row.exportStatus === INVOICE_EXPORT_STATUS_READY"
          src="@/assets/icons/ic_export-ready.svg"
          alt="Blue cloud upload icon - export ready"
          v-tooltip="{
            placement: 'top-end',
            content: 'Export Ready',
            classes: ['tooltip-general', 'tooltip--lift-up'],
          }"
          @click.stop="exportStocktake(row.id)"
        />
        <img
          v-else-if="row.exportStatus === INVOICE_EXPORT_STATUS_COMPLETED"
          src="@/assets/icons/ic_export-completed.svg"
          alt="Green cloud upload icon - export completed"
          v-tooltip="{
            placement: 'top-end',
            content: 'Export Completed',
            classes: ['tooltip-general', 'tooltip--lift-up'],
          }"
        />
        <img
          v-else-if="row.exportStatus === INVOICE_EXPORT_STATUS_ERROR"
          src="@/assets/icons/ic_export-error.svg"
          alt="Red cloud upload icon - export error"
          v-tooltip="{
            placement: 'top-end',
            content: 'Export Error',
            classes: ['tooltip-general', 'tooltip--lift-up'],
          }"
          @click.stop="exportStocktake(row.id)"
        />
        <img
          v-else-if="row.exportStatus === 'loading'"
          src="@/assets/icons/ic_export-loading.svg"
          alt="Blue cloud upload icon - export loading"
          class="cursor-help"
          v-tooltip="{
            placement: 'top-end',
            content: 'Export Loading',
            classes: ['tooltip-general', 'tooltip--lift-up'],
          }"
        />
        <span v-else>-</span>
      </template>
      <template #cell-note="{ row: { note } }">
        <span v-if="note">{{ note }}</span>
        <span v-else>-</span>
      </template>
    </ez-table>
    <ez-loader :show="isLoading" />
    <empty-state v-if="!stocktakes.length && !isLoading && !isLoadingMore">
      <template #badge><img src="@/assets/no-products.svg" alt="" /></template>
      <template #title>No stocktakes yet</template>
      <template #info>Once you conduct stocktakes, they will show up here.</template>
    </empty-state>

    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more v-if="!isLoadingMore && meta.nextId" @loadMore="loadMore" />
  </div>
</template>

<style lang="scss" scoped>
.stocktakes__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;

  :deep() .ez-select__display-container {
    height: 36px;
  }

  .filters-wrapper {
    display: flex;
    :deep() .ez-filter-list__items .filter__date {
      .placeholder {
        min-width: 128px;
      }
    }
    :deep() .ez-select {
      width: auto;
      min-width: 128px;
    }
  }
}
.filter__date {
  :deep() .date-picker {
    .placeholder {
      color: $color-gray-6C;
    }
  }
}

.stocktake-locations {
  display: flex;
  align-items: center;

  &__location {
    border: 2px solid white;
    border-radius: 50%;

    &:not(:first-child) {
      margin-left: -8px;
    }
  }
}

.overdue {
  color: $color-primary-red;
}
</style>
