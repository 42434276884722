<script>
import EzButton from '@/components/ui/Button/EzButton';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'AddProducts',
  components: {
    EzButton,
  },
  props: {
    disabled: {
      required: false,
      type: Boolean,
    },
    type: {
      required: false,
      type: String,
      default: 'primary',
    },
    isQuote: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      commonCy,
    };
  },
  computed: {
    isLink() {
      return this.type === 'link';
    },
  },
};
</script>

<template>
  <div :class="{ 'btn-wrapper': isLink }">
    <div class="label" v-if="isLink">DETAILS</div>
    <ez-button
      class="dropdown-actions__btn dropdown-actions__btn--add"
      :type="type"
      :disabled="disabled"
      @click="$emit('actionClicked')"
      :data-cy="commonCy.SINGLE_ORDER.MANAGE_ORDER.BUTTON__EDIT"
    >
      Edit
    </ez-button>
  </div>
</template>

<style lang="scss" scoped>
.btn-wrapper {
  .dropdown-actions {
    &__btn {
      text-align: left;
      padding: 0 0 0 8px;
      opacity: .8;
      width: 100%;
      font-weight: 400;

      &--add {
        color: $primary-color;
      }

      &:disabled {
        background-color: transparent;
        opacity: .48;
        color: #6C7995;

        &:hover {
          opacity: .48;
        }
      }

      &:hover {
        opacity: 1;
      }
    }
  }
}
.label {
  @include font-size(11px, 14px);
  letter-spacing: .15px;
  color: $color-gray-6C;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 8px 0 0 8px;
  font-weight: 600;
}
</style>
