<template>
  <div class="scheduled-order">
    <div class="top-space" v-if="hasOrders">
      <ez-table
        :headers="headers"
        :columns="['distributor', 'scheduledAt']"
        :columnProps="{
              scheduledAt: { class: 'date-time-cell' },
            }"
        :data="orders"
        @rowClick="openDrawerAndLoadOrder">

        <template #cell-distributor="{ row: { distributor } }">
          <ez-entity-info v-if="distributor" :imgUrl="distributor.logo" imgBorderRadius="50%">
            <div class="distributor-info__name">{{ distributor.name }}</div>
          </ez-entity-info>
        </template>
        <template #cell-scheduledAt="{ row }">
          <span :class="{ 'upcoming': row.upcoming }">
            {{ row.scheduledAt | dateTime }}
          </span>
        </template>
      </ez-table>

      <ez-load-more v-if="meta.nextId && !isLoadingMore" @loadMore="onLoadMoreClick"/>

      <!-- Drawer -->

      <ez-drawer ref="orderDrawer" class="order-drawer" @onClose="resetDrawerRoute">
        <template #title>
          <h2>Order Request</h2>
        </template>
        <template #content>

          <order-container>
            <template #distributor>
              <ez-entity-info
                v-if="selectedOrder.distributor"
                :imgUrl="selectedOrder.distributor.logo"
                imgBorderRadius="50%">
                <span>{{ selectedOrder.distributor.name }}</span>
                <ez-button
                  type="secondary"
                  formType="button"
                  @click="showDistributorInfo">
                  <font-awesome-icon icon="info-circle"/>
                  <span>{{ $t('global.distributor') }} Info</span>
                </ez-button>
              </ez-entity-info>
            </template>

            <template #orderCreated>{{ selectedOrder.createdAt|date }}</template>
            <template #scheduled>{{ selectedOrder.scheduledAt|date }}</template>

            <template>
              <component
                :editing-mode="false"
                :is="orderTabs"
                :order="selectedOrder"
                :enableEdit="false"></component>
            </template>
          </order-container>
        </template>
        <template #footer>
          <ez-button
            formType="button"
            type="red"
            @click="openCancelOrderModal">Cancel Order</ez-button>
          <ez-button
            formType="button"
            type="secondary"
            @click="openEditDateModal">Edit Date</ez-button>
          <div class="order-total-price">
            <v-subtotal-info :item-list="itemList" :delivery-fee="deliveryFee" :tax="tax" />
          </div>
        </template>
      </ez-drawer>
      <div v-if="isLoadingMore" class="u-text-center mt-12">
        <ez-spinner />
      </div>

      <!-- Distributor info modal -->
      <ez-info-modal
        ref="infoModal"
        v-if="selectedOrder.distributor"
        :data="selectedOrder.distributor">
        <template #title>{{ $t('global.distributor') }} Info</template>
      </ez-info-modal>

      <ez-confirmation-modal ref="cancelOrderModal" type="red">
        <template #title>Cancel Order?</template>
        <template #content>
          <p>Are you sure you want to cancel this order?</p>
        </template>
        <template #footer>
          <ez-button @click="closeCancelOrderModal" type="link">Discard</ez-button>
          <ez-button
            @click="confirmCancelOrder"
            type="red"
            :is-loading="isLoadingStop"
          >
            Cancel Order
          </ez-button>
        </template>
      </ez-confirmation-modal>

      <ez-form-modal ref="editDateModal">
        <template #title>Edit Scheduled Date</template>
        <template #content>
          <ez-input
            :min="tomorrowDate"
            :formKey="updateIntervalKey"
            @onChange="(newDate) => date = newDate"
            type="date"
            name="scheduledAt"
            label="Date"
            :value="date"/>
          <ez-input
            :formKey="updateIntervalKey"
            @onChange="(newTime) => time = newTime"
            type="time"
            name="scheduledAt"
            label="Time"
            :value="time"/>
        </template>
        <template #footer>
          <ez-button type="link" @click="closeEditDateModal">Cancel</ez-button>
          <ez-button
            :is-loading="isLoadingButton"
            formType="button"
            @click="updateInterval"
          >
            Save Changes
          </ez-button>
        </template>
      </ez-form-modal>
    </div>
    <div class="top-space" v-else-if="!isLoading">
      <empty-state>
        <template #badge>
          <img src="@/assets/no-standing-scheduled-state.svg" alt="" />
        </template>
        <template #title>No scheduled orders</template>
        <template #info>
          Scheduled orders will appear here once they are created.
        </template>
      </empty-state>
    </div>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import flash from '@/components/ui/FlashMessage';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzTable from '@/components/ui/Table';
import EzLoadMore from '@/components/ui/LoadMore';
import EmptyState from '@/views/common/empty-state';
import StatusBadge from '@/views/common/status-badge';
import EzButton from '@/components/ui/Button';
import EzDrawer from '@/components/ui/Drawer';
import EzInput from '@/components/ui/Input';
import { SingleOrder } from '@/views/common/orders';
import { EzInfoModal, EzConfirmationModal, EzFormModal } from '@/components/ui/Modal';
import { DefaultTabs } from '@/views/common/orders/tabs';
import OrderContainer from '@/views/common/orders/OrderContainer.vue';
import EzForm from '@/components/ui/Form';
import EzTax from '@/components/ui/TaxPricing';

import Order from '@/models/Order';
import EzLoader from '@/components/ui/Loader';
import EzSpinner from '@/components/ui/Spinner';
import { LOADING_KEY } from '@/util/constants';
import VSubtotalInfo from '@/components/v3/patterns/VSubtotalInfo';

export default {
  components: {
    VSubtotalInfo,
    EzForm,
    EzEntityInfo,
    EzLoadMore,
    EzTable,
    EmptyState,
    EzButton,
    EzInfoModal,
    EzDrawer,
    EzInput,
    SingleOrder,
    StatusBadge,
    OrderContainer,
    EzConfirmationModal,
    EzFormModal,
    EzTax,
    EzLoader,
    EzSpinner,
  },
  props: {
    orderId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      headers: {
        distributor: () => this.$t('global.distributor'),
        scheduledAt: () => 'Next Order Date',
        interval: () => 'Repeat every',
      },
      updateIntervalKey: LOADING_KEY.UPDATE_INTERVAL,
      date: '',
      time: '',
      tomorrowDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
    };
  },
  computed: {
    ...mapGetters({
      orders: 'entities/orders/getFutureOrders',
    }),
    ...mapGetters('loading', ['getLoading']),
    ...mapState('entities/orders', ['meta']),
    ...mapState('entities/users', ['context']),
    isLoading() {
      return this.getLoading(LOADING_KEY.FETCH_ORDERS)
          || this.getLoading(LOADING_KEY.FETCH_ORDER);
    },
    isLoadingButton() {
      return this.getLoading(LOADING_KEY.UPDATE_INTERVAL);
    },
    isLoadingStop() {
      return this.getLoading(LOADING_KEY.CANCEL_ORDER);
    },
    isLoadingMore() {
      return this.getLoading('load-more-orders');
    },
    dateTime() {
      return `${this.date} ${this.time}`;
    },
    hasOrders() {
      return this.orders.length;
    },
    selectedOrder() {
      const order = Order.query()
        .with('distributor')
        .with('venue')
        .find(this.orderId);

      return order || {
        distributor: {},
        orderedProducts: [],
      };
    },
    orderTabs() {
      return DefaultTabs;
    },
    // Props for VSubtotalInfo - Start
    itemList() {
      return this.selectedOrder.orderedProducts;
    },
    deliveryFee() {
      return this.selectedOrder.deliveryFee;
    },
    tax() {
      return this.selectedOrder.tax;
    },
    // Props for VSubtotalInfo - End
  },
  methods: {
    ...mapActions('entities/orders', [
      'fetchVenueOrder',
      'fetchVenueOrders',
      'fetchMoreVenueOrders',
      'cancelFutureOrder',
      'patchOrderDateTime',
    ]),
    ...mapMutations('entities/orders', ['SET_SELECTED_SINGLE_ORDER']),
    onRowClick($event) {
      this.$emit('rowClick', $event);
    },
    onLoadMoreClick() {
      this.fetchMoreVenueOrders({ futureOrder: true, orderType: 'scheduled' });
      this.$emit('loadMore');
    },

    // METHODS: Drawer
    openOrderDrawer() {
      this.$refs.orderDrawer.open();
    },
    closeOrderDrawer() {
      this.$refs.orderDrawer.close();
    },
    resetDrawerRoute() {
      this.$router.replace({ name: 'venue-orders-scheduled' });
    },
    openDrawerAndLoadOrder({ id }) {
      this.fetchVenueOrder({ id, futureOrder: true })
        .then(() => {
          this.$router.push({
            name: 'venue-orders-scheduled',
            params: { id },
          });
          this.openOrderDrawer();
        })
        .catch(() => {
          this.resetDrawerRoute();
        });
    },
    loadDrawerOrder({ id }) {
      this.fetchVenueOrder({ id, futureOrder: true })
        .then(() => {
          this.openOrderDrawer();
        })
        .catch(() => {
          this.resetDrawerRoute();
        });
    },

    showDistributorInfo() {
      this.$refs.infoModal.open();
    },
    closeCancelOrderModal() {
      this.$refs.cancelOrderModal.close();
    },
    openCancelOrderModal() {
      this.$refs.cancelOrderModal.open();
    },
    openEditDateModal() {
      this.$refs.editDateModal.open();
    },
    closeEditDateModal() {
      this.$refs.editDateModal.close();
    },
    async confirmCancelOrder() {
      await this.cancelFutureOrder({ id: this.orderId });
      Order.delete(this.orderId);

      this.closeCancelOrderModal();
      this.closeOrderDrawer();

      flash.success({
        title: 'Scheduled order has been successfully stopped',
      });
    },
    async updateInterval() {
      const dateTimeTimeZone = dayjs(this.dateTime).format();
      const { data } = await this.patchOrderDateTime({
        orderId: this.orderId,
        dateTime: dateTimeTimeZone,
      });
      Order.update(data);
      this.closeEditDateModal();
      flash.success({ title: 'Scheduled date successfully updated!' });
    },
  },
  beforeMount() {
    // Clear order before the component is mounted.
    // This will prevent flashing of old orders, from previous tabs.
    Order.deleteAll();
    this.$emit('updateFilter', {});
  },
  async mounted() {
    await this.fetchVenueOrders({ futureOrder: true, orderType: 'scheduled' });
    const { orderId } = this;

    if (orderId) {
      this.loadDrawerOrder({ id: orderId });
    }
  },
  watch: {
    selectedOrder(value) {
      if (value.id) {
        this.date = dayjs(this.selectedOrder.scheduledAt)
          .format('YYYY-MM-DD');
        this.time = dayjs(this.selectedOrder.scheduledAt)
          .format('HH:mm');
      }
      this.SET_SELECTED_SINGLE_ORDER(value);
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .loader {
  @extend %whole-screen;
}

.scheduled-order {
  position: relative;
  min-height: $loading-min-height;
}
.upcoming { color: $color-primary-blue; }
.input-group + .input-group { margin-top: px-to-rem(16px); }
 :deep() .ez-empty-state {
   img {
     width: 160px;
     height: 108px;
   }
 }
</style>
