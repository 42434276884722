var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"linked-platforms"},[_c('ez-table',{attrs:{"data":_vm.data,"columns":['orderNumber', 'name', 'actions'],"headers":{
      orderNumber: () => 'Order No.',
      name: () => 'External Platforms',
      actions: () => '',
    },"columnProps":{
      orderNumber: { class: 'medium-cell' },
      actions: { class: 'large-cell' },
    },"disable-hover":""},scopedSlots:_vm._u([{key:"cell-orderNumber",fn:function({ row }){return [_c('span',{staticClass:"order-number"},[_vm._v(_vm._s(row.orderNumber))])]}},{key:"cell-name",fn:function({ row }){return [_c('ez-entity-info',{attrs:{"img-width":"2rem","img-height":"2rem","img-border-radius":"50%","imgUrl":row.logo}},[_c('div',{staticClass:"integration-info",attrs:{"title":row.name}},[_c('span',[_vm._v(_vm._s(row.name))])])])]}},{key:"cell-actions",fn:function({ row }){return [_c('ez-button',{attrs:{"type":"secondary","data-cy":_vm.commonCy.SINGLE_ORDER.BUTTON__VIEW_ORDER},on:{"click":function($event){return _vm.goToExternalLink(row)}}},[_vm._v(" View Order ")])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }