<script>
import SupplierInfo from '@/views/platform/venue/suppliers/all/SupplierInfo';
import { mapActions, mapState } from 'vuex';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  components: {
    SupplierInfo,
  },
  props: {
    supplierId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      distributor: {},
    };
  },
  computed: {
    ...mapState('entities/users', [
      'context',
      'contextId',
    ]),
  },
  methods: {
    ...mapActions('entities/distributors', [
      'venueFetchSupplierSingle',
    ]),
    async getSingleDistributor() {
      const { data } = await this.venueFetchSupplierSingle({
        id: this.supplierId,
      });
      this.distributor = data.data;
    },
  },
  mounted() {
    this.getSingleDistributor();
  },
};
</script>
<template>
  <supplier-info :distributor="distributor" ></supplier-info>
</template>
<style lang="scss" scoped></style>
