<script>
import EzButton from '@/components/ui/Button/EzButton';
import { EzFormModal } from '@/components/ui/Modal';
import { DEFAULT_FRACTION_DIGITS, LOADING_KEY, UNIT_TYPE_FRACTIONAL } from '@/util/constants';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import { clone, debounce, falsy, getCurrency, isPremium } from '@/util/utils';
import EzTextarea from '@/components/ui/Textarea/';
import { mapActions, mapGetters, mapState } from 'vuex';
import { isDistributor, isVenue } from '@/mixins/permissions/utils';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import VPrice from '@/components/v3/elements/VPrice/index.vue';
import VMarketPriceInput from '@/components/v3/patterns/VMarketPriceInput/index.vue';
import {
  DISCOUNT_TYPE,
  getDiscountedPrice,
} from '@/views/platform/distributor/orders/new/overview/utils';
import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';

const Event = {
  setPercentType: 'setPercentType',
  setAmountType: 'setAmountType',
};

/**
 *
 * @version 1.0.0
 * @since 3.6.0
 */
export default {
  name: 'EditDetailsModal',
  components: {
    VMarketPriceInput,
    VPrice,
    EzButton,
    EzFormModal,
    EzTextarea,
    EzMaskInput,
    EzCheckbox,
    EzMaskInputSimple,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    canEditPrice: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPublicOrder: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSimpleOrder: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      formKey: 'editProductDetailsInOrder',
      loadingKey: LOADING_KEY.EDIT_PRODUCT_DETAILS_FROM_ORDER,
      price: 0,
      note: '',
      priceQuantity: 1,
      quantity: 1,
      token: null,
      isPriceInFocus: false,
      marketPrice: false,
      orderingUnit: null,
      priceUnit: null,
      orderingUnitIncrement: 1,
      isQuantityInvalid: false,
      isOpen: false,
      commonCy,
      Event,
      DISCOUNT_TYPE,
      productCopy: {},
      discountIsInvalid: false,
      initialValues: {},
    };
  },
  computed: {
    ...mapState('entities/users', ['contextId']),
    ...mapGetters('entities/users', ['isDistributor', 'isVenue']),
    ...mapGetters('loading', ['getLoading']),
    tooltipMp() {
      return {
        html: true,
        classes: ['tooltip-general'],
        content: 'Used for products whose price depends on the current price on the market.',
      };
    },
    isLoading() {
      return this.getLoading(this.loadingKey);
    },
    currencySymbol() {
      const { symbol } = getCurrency() || {};
      return this.order?.currency?.symbol || symbol;
    },
    fractionDigits() {
      return this.order?.currency?.fractionDigits || DEFAULT_FRACTION_DIGITS;
    },
    money() {
      return {
        decimal: '.',
        thousands: ',',
        prefix: this.currencySymbol,
        suffix: '',
        precision: 2,
        masked: false,
        productCopy: {},
      };
    },
    isDisabled() {
      const samePrice =
        this.product.price === this.productCopy.price &&
        this.marketPrice === this.product.marketPrice;
      const sameQuantity =
        this.quantity === this.product.quantity &&
        this.priceQuantity === this.product.priceQuantity;

      const invalidPrice = !this.marketPrice && this.price < 0;
      const invalidQuantity = this.isQuantityInvalid;

      return (samePrice && sameQuantity) || (this.canEditPrice && invalidPrice) || invalidQuantity;
    },
    unitLabel() {
      return this.orderingUnit?.label;
    },
    priceUnitLabel() {
      return this.priceUnit?.label;
    },
    isFractional() {
      return this.orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    isFractionalPriceUnit() {
      return this.priceUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    productQuantityMaxValue() {
      const { customerInventory: { orderMoreThanAvailable, inventoryLevel } = {} } = this.product;
      return !orderMoreThanAvailable && inventoryLevel !== null
        ? inventoryLevel
        : Number.MAX_SAFE_INTEGER;
    },
    isDistributorPremium() {
      return this.isVenue && isPremium(this.order?.distributor?.accountType);
    },
  },
  methods: {
    ...mapActions('entities/orders', [
      'fetchProductPriceVenue',
      'fetchProductPriceSupplier',
      'fetchProductPriceVenuePublic',
      'fetchProductPriceSupplierPublic',
    ]),
    open() {
      this.$refs.editDetails.open();
    },
    close() {
      this.$refs.editDetails.close();
    },
    async onModalOpen() {
      this.isOpen = true;

      await this.$nextTick();
      this.price = this.product.price;
      this.priceQuantity = this.product.priceQuantity || 0;
      this.quantity = this.product.quantity;
      this.note = this.product.note;
    },
    async onModalClose() {
      this.isOpen = false;

      await this.$nextTick();
      this.price = 0;
      this.priceQuantity = 0;
      this.quantity = 0;
      this.note = '';
      this.$emit('close');
    },
    submitForm() {
      const {
        price,
        discountValue,
        discountType,
        discountAmount,
        deals,
        isDuplicate,
        isBonus,
        bonusId,
      } = this.productCopy;
      const isDiff = this.priceQuantity > 0 || this.marketPrice !== this.product.marketPrice;
      const totalPrice = isDiff ? { totalPrice: price * this.priceQuantity } : {};
      this.$emit('change', {
        id: this.product.id,
        productId: this.product.productId,
        quantity: this.quantity,
        priceQuantity: this.priceQuantity,
        marketPrice: this.marketPrice,
        note: this.note,
        isDuplicate: isDuplicate ?? false,
        isBonus: isBonus ?? false,
        price,
        discountValue,
        discountType,
        discountAmount,
        deals,
        bonusId,
        ...totalPrice,
      });
      this.close();
    },
    onPriceChange: debounce(function deb(newPrice) {
      this.price = newPrice;

      if (this.initialValues.price !== newPrice && !this.productCopy.discountEdited) {
        this.productCopy = {
          ...this.productCopy,
          discountType: DISCOUNT_TYPE.amount,
          discountValue: this.productCopy.defaultPrice - newPrice,
          price: newPrice,
        };
        this.productCopy.discountAmount = this.getPerUnitDiscount({
          defaultPrice: this.productCopy.defaultPrice,
          discountValue: this.productCopy.discountValue,
          discountType: this.productCopy.discountType,
        });
      } else if (this.initialValues.price === newPrice) {
        this.productCopy = this.initialValues;
      }

      this.productCopy.discountEdited = false;

      if (!this.isPriceInFocus) {
        return;
      }

      if (falsy(this.productCopy) || !Number.isFinite(this.productCopy.price)) {
        return;
      }

      if (this.productCopy.price === newPrice) {
        return;
      }

      this.product.isPriceEdited = true;

      this.$emit('change', {
        id: this.product.id,
        isPriceEdited: true,
      });
    }, 300),
    onQuantityChange: debounce(async function deb(quantity) {
      if (this.quantity === quantity || !this.isOpen) return;

      if (!this.priceUnit) {
        this.priceQuantity = quantity;
        this.quantity = quantity;
      }
      this.quantity = quantity;

      if (this.product.isPriceEdited || !this.product.id || !this.product.productId) {
        return;
      }

      this.isPriceInFocus = false;

      // TODO: Make abstraction for this if/else chain
      if (this.isVenue) {
        const { productId } = this.product;
        const {
          data: {
            data: { pricePerUnit, marketPrice },
          },
        } = await this.fetchProductPriceVenue({
          productId,
          quantity,
        });

        if (!Number.isFinite(pricePerUnit)) {
          return;
        }

        this.price = pricePerUnit;
        this.marketPrice = marketPrice;
      } else if (this.isDistributor) {
        const { productId } = this.product;
        const { venue } = this.order;

        const {
          data: {
            data: { pricePerUnit, marketPrice, defaultMarketPrice, discountType, discountValue },
          },
        } = await this.fetchProductPriceSupplier({
          venueId: venue.id,
          productId,
          quantity,
        });

        if (!Number.isFinite(pricePerUnit)) {
          return;
        }

        if (marketPrice || defaultMarketPrice) return;

        this.price = pricePerUnit;
        this.productCopy = {
          ...this.productCopy,
          price: pricePerUnit,
          discountType,
          discountValue,
        };
      } else if (this.isPublicOrder && isDistributor(this.type)) {
        const { id: productId } = this.product;
        const { id: orderId } = this.order;

        const {
          data: {
            data: { pricePerUnit, marketPrice },
          },
        } = await this.fetchProductPriceSupplierPublic({
          productId,
          orderId,
          quantity,
          token: this.token,
        });

        if (!Number.isFinite(pricePerUnit)) {
          return;
        }

        this.price = pricePerUnit;
        this.marketPrice = marketPrice;
      } else if (this.isPublicOrder && isVenue(this.type)) {
        const { id: productId } = this.product;
        const { id: orderId } = this.order;

        const {
          data: {
            data: { pricePerUnit, marketPrice },
          },
        } = await this.fetchProductPriceVenuePublic({
          productId,
          orderId,
          quantity,
          token: this.token,
        });

        if (!Number.isFinite(pricePerUnit)) {
          return;
        }

        this.price = pricePerUnit;
        this.marketPrice = marketPrice;
      }
    }, 300),
    onPriceQuantityChange(val) {
      this.priceQuantity = Number(val);
    },
    onMarketPriceChange(val) {
      this.marketPrice = val;
      if (this.marketPrice) {
        this.price = 0;
        this.productCopy = {
          ...this.productCopy,
          defaultMarketPrice: val,
          marketPrice: val,
          price: 0,
        };
      } else {
        this.productCopy = {
          ...this.productCopy,
          defaultMarketPrice: val,
          marketPrice: val,
        };
        this.productCopy.price = getDiscountedPrice(this.productCopy);
        this.price = this.product.price;
      }
    },
    onQuantityInvalid(val) {
      this.isQuantityInvalid = val;
    },
    getPerUnitDiscount({ defaultPrice, discountValue, discountType }) {
      const isPercent = discountType === DISCOUNT_TYPE.percent;

      const percentageDiscount = (defaultPrice * discountValue) / 100;
      const amountDiscount = discountValue;

      return isPercent ? percentageDiscount : amountDiscount;
    },
    onDiscountInput(val, productCopy) {
      const updatedProduct = {
        ...productCopy,
        discountValue: val,
        defaultMarketPrice: false,
        marketPrice: false,
        discountEdited: true,
      };
      updatedProduct.price = getDiscountedPrice(updatedProduct);
      updatedProduct.discountAmount = this.getPerUnitDiscount({
        defaultPrice: updatedProduct.defaultPrice,
        discountValue: val,
        discountType: updatedProduct.discountType,
      });

      this.productCopy = updatedProduct;
    },
    updateDiscountType(type, productCopy) {
      const isPercent = type === Event.setPercentType;
      this.productCopy = {
        ...productCopy,
        discountType: isPercent ? DISCOUNT_TYPE.percent : DISCOUNT_TYPE.amount,
        discountValue: 0,
        price: productCopy.defaultPrice,
      };
    },
    customPrefix(product) {
      if (product.discountType === DISCOUNT_TYPE.amount) return this.currencySymbol;
      return '%';
    },
    precision(product) {
      if (product.discountType === DISCOUNT_TYPE.amount) return this.fractionDigits;
      return 0;
    },
    maxValue(product) {
      return product.discountType === DISCOUNT_TYPE.amount ? product.defaultPrice || 0 : 100;
    },
    minValue(product) {
      return product.discountType === DISCOUNT_TYPE.amount ? Number.MIN_SAFE_INTEGER : -100;
    },
    isInvalidDiscount(val) {
      this.discountIsInvalid = val;
    },
  },
  watch: {
    product(val) {
      this.price = val.defaultPrice || 0;
      this.orderingUnitIncrement = val.orderingUnitIncrement;
      this.priceQuantity = val.priceQuantity || 0;
      this.quantity = val.quantity;
      this.note = val.note;
      this.marketPrice = val.marketPrice;
      this.orderingUnit = val.orderingUnit;
      this.priceUnit = val.priceUnit;
      this.productCopy = clone({
        ...val,
        ...(val.discountType ?? { discountType: DISCOUNT_TYPE.percent }),
        ...(val.discountValue ? { discountValue: val.discountValue } : 0),
        discountEdited: false,
      });
      this.initialValues = this.productCopy;
    },
  },
  created() {
    const { token } = this.$route.query;
    this.token = token;
  },
  mounted() {
    // eslint-disable-next-line no-unused-expressions
    this.$refs?.money?.$el?.addEventListener('focus', () => {
      this.isPriceInFocus = true;
    });
  },
};
</script>

<template>
  <ez-form-modal @close="onModalClose" @open="onModalOpen" ref="editDetails">
    <template #title>
      <slot name="title">Edit Details</slot>
    </template>
    <template #content>
      <ez-mask-input-simple
        v-if="isOpen"
        :data-cy="commonCy.SINGLE_ORDER.EDIT_PRODUCT_MODAL.BUTTON__QUANTITY"
        name="quantity"
        label="Quantity"
        :formKey="formKey"
        :maxValue="productQuantityMaxValue"
        :minValue="0"
        :value="product?.customerInventory?.outOfStock ? 0 : quantity"
        :precision="isFractional ? 2 : 0"
        @input="onQuantityChange"
        @invalid="onQuantityInvalid($event)"
        :has-currency="false"
      >
        <template #suffix>{{ unitLabel }}</template>
      </ez-mask-input-simple>

      <template v-if="!isSimpleOrder">
        <ez-mask-input
          ref="money"
          label="Price per unit"
          :form-key="formKey"
          name="price"
          class="mask-input mt-12"
          :value="productCopy.price || 0"
          type="input"
          @input="onPriceChange"
          :disabled="marketPrice || (isDistributorPremium && !order.offline)"
          :allow-negative-value="false"
          :data-cy="commonCy.SINGLE_ORDER.EDIT_PRODUCT_MODAL.INPUT__PRICE_PER_UNIT"
        >
          <template #suffix>/ {{ priceUnit ? priceUnitLabel : unitLabel }}</template>
        </ez-mask-input>
        <div class="market-price mt-12">
          <ez-checkbox
            :form-key="formKey"
            name="marketPrice"
            label="Market Price"
            @change="onMarketPriceChange"
            :checked="marketPrice"
            :disabled="!canEditPrice"
            :data-cy="commonCy.SINGLE_ORDER.EDIT_PRODUCT_MODAL.INPUT__MARKET_PRICE"
          />
          <font-awesome-icon
            class="info-circle cursor-help ml-8"
            icon="info-circle"
            v-tooltip="tooltipMp"
          />
        </div>
      </template>

      <ez-mask-input
        v-if="priceUnit && !isSimpleOrder"
        label="Total Unit Quantity"
        :form-key="formKey"
        name="unitQuantity"
        class="mask-input mt-12"
        :value="priceQuantity"
        :precision="isFractionalPriceUnit ? 2 : 0"
        :price-prefix="false"
        type="input"
        @input="onPriceQuantityChange"
        :allow-negative-value="false"
        :data-cy="commonCy.SINGLE_ORDER.EDIT_PRODUCT_MODAL.INPUT__TOTAL_UNIT_QUANTITY"
      >
        <template #suffix>{{ priceUnitLabel }}</template>
      </ez-mask-input>

      <hr v-if="!isSimpleOrder" />
      <div class="product-discount" v-if="!isSimpleOrder">
        <label for="discount">Product Discount</label>
        <div id="discount" class="discount-wrapper">
          <div class="item mt-12">
            <div class="item__name">Regular Price</div>
            <div class="item__value item__value--light">
              <v-price
                :price="productCopy?.defaultPrice ?? 0"
                :showMarketPriceInfo="false"
                :isToBeDetermined="false"
                :data-cy="''"
              />
            </div>
          </div>
          <div class="item mt-8">
            <div class="item__name">Discount</div>
            <div class="item__value">
              <v-market-price-input
                :value="productCopy.discountValue ?? 0"
                :disabled="marketPrice || (isDistributorPremium && !order.offline)"
                :customPrefix="customPrefix(productCopy)"
                :precision="precision(productCopy)"
                allowNegativePrice
                :maxValue="maxValue(productCopy)"
                :min-value="minValue(productCopy)"
                @input="onDiscountInput($event, productCopy)"
                @invalid="isInvalidDiscount($event)"
                :is-invalid="discountIsInvalid"
                @setPercentType="updateDiscountType(Event.setPercentType, productCopy)"
                @setAmountType="updateDiscountType(Event.setAmountType, productCopy)"
                :actions="[
                  { type: Event.setPercentType, label: '%' },
                  { type: Event.setAmountType, label: currencySymbol },
                ]"
              />
            </div>
          </div>
          <div class="item mt-8">
            <div class="item__name">New Price</div>
            <div class="item__value">
              <v-price
                :price="productCopy?.price ?? 0"
                :showMarketPriceInfo="false"
                :isToBeDetermined="false"
                :data-cy="''"
              />
            </div>
          </div>
        </div>
      </div>
      <hr />

      <ez-textarea
        :value="note"
        @onChange="val => (note = val)"
        class="mt-12"
        :form-key="formKey"
        placeholder="Leave a Note"
        name="message"
        label="Note"
        :data-cy="commonCy.SINGLE_ORDER.EDIT_PRODUCT_MODAL.TEXTAREA__NOTE"
      />
    </template>
    <template #footer>
      <ez-button
        type="link"
        formType="button"
        @click="close"
        :data-cy="commonCy.SINGLE_ORDER.EDIT_PRODUCT_MODAL.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        :disabled="isDisabled || discountIsInvalid"
        :is-loading="isLoading"
        formType="button"
        @click="submitForm"
        :data-cy="commonCy.SINGLE_ORDER.EDIT_PRODUCT_MODAL.BUTTON__SAVE"
      >
        Save Changes
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped>
.market-price {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include font-size(14px, 20px);
  :deep() .input-group {
    height: auto;
  }
}
.info-circle {
  color: #6c7995;
  @include font-size(16px);
}
:deep() .mask-input-wrapper .mask-input.mark-input--inline {
  width: 100%;
  display: flex;

  .mask-input__input {
    font-weight: normal;
    color: $color-gray-25;
    text-align: center;
  }
}

.product-discount {
  display: flex;
  flex-direction: column;
  line-height: 1.9;

  label {
    @include font-size(12px);
    color: $color-gray-6C;
  }

  .discount-wrapper {
    display: flex;
    flex-direction: inherit;

    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__name {
        color: $color-gray-25;
        @include font-size(12px);
        font-weight: 500;
      }

      &__value {
        &--light {
          color: $color-gray-6C;
          :deep() {
            span {
              font-weight: 500;
            }
          }
        }
        :deep() {
          .mark-input__error {
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
