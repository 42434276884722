var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.show)?_c('div',{attrs:{"data-cy":_vm.dataCy}},[(_vm.showHeader)?_c('div',{staticClass:"help-block"},[_c('hr'),_c('h4',{staticClass:"help-block__title"},[_vm._v("Inventory Management")]),_c('p',{staticClass:"help-block__text"},[_vm._v("Select products for delivery, and insert their order quantity.")])]):_vm._e(),(_vm.invalid)?_c('ez-alert',{attrs:{"type":"red","size":"inline","variant":"disclaimer","data-cy":_vm.errorDataCy},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"exclamation-circle"}})]},proxy:true}],null,false,3502554247)},[[(_vm.productsMissing.length)?[_vm._v(" These products don’t have stock in the selected warehouse: "),_c('ul',{staticClass:"missing-products-list m-0 pl-0"},_vm._l((_vm.productsMissing),function({ id, name }){return _c('li',{key:id},[_vm._v("- "+_vm._s(name))])}),0)]:(_vm.productsShortStock.length)?[_vm._v(" Some products don’t have enough stock to fulfil from this warehouse. ")]:(_vm.productQuotasNotMatched)?[_vm._v(" To confirm the action, exact ordered quantity for all products must be chosen. ")]:_vm._e()]],2):_vm._e(),_c('div',{class:[
      'inventory-items-table--container',
      { 'inventory-items-table--container--overflow-visible': _vm.inventoryItems.length < 4 },
    ]},[_c('ez-table',{staticClass:"inventory-items-table",attrs:{"loading":_vm.loading,"data":_vm.inventoryItems,"columns":['product', 'expiresSoon', 'usedInventory'],"columnProps":{
        product: { class: 'product-cell' },
        expiresSoon: { class: 'indicator-cell extra-small-cell no-side-padding-cell' },
        usedInventory: { class: 'quantity-cell medium-cell' },
      },"headers":{
        expiresSoon: () => '',
        usedInventory: () => 'Quantity',
      },"rowDataCy":row => `${_vm.supplierCy.ORDERS.ADVANCED_INVENTORY_MANAGEMENT.ROW__PRODUCT}-${row.id}`},scopedSlots:_vm._u([{key:"cell-product",fn:function({ row, row: { product } }){return [_c('div',{staticClass:"cell-product-container"},[_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('ez-checkbox',{staticClass:"cursor-pointer mr-8",attrs:{"checked":_vm.isSelected(row.id),"data-cy":_vm.supplierCy.ORDERS.ADVANCED_INVENTORY_MANAGEMENT.INPUT__CHECKBOX},on:{"change":function($event){return _vm.onCheckboxChange(row, $event)}}})],1),_c('ez-entity-info',{attrs:{"imgWidth":"2rem","imgHeight":"2rem","imgUrl":product.image,"imgTooltip":_vm.imgTooltip(row),"imgDataCy":_vm.supplierCy.ORDERS.ADVANCED_INVENTORY_MANAGEMENT.IMG__PRODUCT}},[_c('div',{staticClass:"product-info",attrs:{"title":product.name}},[_c('span',{staticClass:"product-info__primary"},[_vm._v(_vm._s(product.name))]),_c('span',{staticClass:"product-info__secondary"},[(row.expiryDate)?_c('span',{staticClass:"product-info__secondary__expiryDate"},[_vm._v(" "+_vm._s(_vm._f("date")(row.expiryDate))+" ")]):_vm._e(),(row.expiryDate && product.sku)?_c('span',[_vm._v(" • ")]):_vm._e(),(product.sku)?_c('span',[_vm._v(" "+_vm._s(product.sku)+" ")]):_vm._e()])])])],1)]}},{key:"cell-expiresSoon",fn:function({ row: { expiresSoon } }){return [(expiresSoon)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            placement: 'start',
            content: 'This batch is about to expire soon.',
            classes: ['tooltip-general'],
          }),expression:"{\n            placement: 'start',\n            content: 'This batch is about to expire soon.',\n            classes: ['tooltip-general'],\n          }"}],attrs:{"icon":"calendar-alt"}}):_vm._e()]}},{key:"cell-usedInventory",fn:function({ row }){return [_c('ez-mask-input-simple',{attrs:{"formKey":"","name":"usedInventory","minValue":0,"value":_vm.findSelected(row.id, 'usedInventory'),"precision":_vm.isFractional(row) ? 2 : 0,"isInvalid":_vm.isInvalid(row),"maxValue":_vm.maxValue(row),"data-cy":_vm.supplierCy.ORDERS.ADVANCED_INVENTORY_MANAGEMENT.INPUT__QUANTITY,"has-currency":false},on:{"input":function($event){return _vm.onQuantityChange(row, $event)},"invalid":function($event){return _vm.onQuantityInvalid(row, $event)},"click":function($event){$event.stopPropagation();}}})]}}],null,false,3745551937)}),(!_vm.inventoryItems.length)?_c('empty-state',{scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/no-product-empty-state.svg"),"alt":""}})]},proxy:true},{key:"title",fn:function(){return [_vm._v("No products listed")]},proxy:true},{key:"info",fn:function(){return [_vm._v("This warehouse has no products that can fulfill this order.")]},proxy:true}],null,false,2422236942)}):_vm._e()],1),(!_vm.loadingMore && _vm.meta.lastPage > _vm.meta.page)?_c('ez-load-more',{on:{"loadMore":_vm.fetchMoreInventoryItems}}):_vm._e(),(_vm.loadingMore)?_c('div',{staticClass:"u-text-center mt-12"},[_c('ez-spinner')],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }