<script>
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import EzButton from '@/components/ui/Button/EzButton';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  components: {
    EzButton,
  },
  props: {
    disabled: {
      required: false,
      type: Boolean,
    },
    type: {
      required: false,
      type: String,
      default: 'primary',
    },
  },
  data() {
    return {
      commonCy,
    };
  },
};
</script>
<template>
  <ez-button
    class="dropdown-actions__btn dropdown-actions__btn--complete"
    :type="type"
    :disabled="disabled"
    @click="$emit('actionClicked')"
    :data-cy="commonCy.SINGLE_ORDER.BUTTON__REORDER"
  >
    Reorder
  </ez-button>
</template>
<style lang="scss" scoped></style>
