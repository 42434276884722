var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-wrapper"},[_c('ez-form',{ref:"form",attrs:{"action":_vm.action,"form-key":_vm.formKey,"transformer":_vm.transformer,"method":"patch"},on:{"success":_vm.onSubmitSuccess,"error":_vm.onSubmitError},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('ez-button',{staticClass:"mt-24",attrs:{"form-type":"submit"}},[_vm._v("Save Changes")])]},proxy:true}])},[_c('h4',{staticClass:"section-title mt-0 mb-4"},[_vm._v("Stocktake Time")]),_c('div',{staticClass:"section-description mt-0 mb-16"},[_vm._v(" Select the assigned time of day for stocktake ")]),_c('ez-radio-button',{staticClass:"radio-btn",attrs:{"formKey":"venue-stocktake","name":"stocktakeTime","label":"End of Day","data":_vm.stocktakeTime,"value":"end_of_day"},on:{"change":function($event){return _vm.updateStocktakeTime('end_of_day')}}}),_c('div',{staticClass:"radio-info"},[_vm._v("After day's invoices and orders")]),_c('ez-radio-button',{staticClass:"radio-btn mt-16",attrs:{"formKey":"venue-stocktake","name":"stocktakeTime","label":"Beginning of Day","data":_vm.stocktakeTime,"value":"beginning_of_day"},on:{"change":function($event){return _vm.updateStocktakeTime('beginning_of_day')}}}),_c('div',{staticClass:"radio-info"},[_vm._v("Before day's invoices and orders")]),_c('hr'),_c('h4',{staticClass:"section-title mt-0 mb-4"},[_vm._v("Locations")]),_c('p',{staticClass:"section-description mt-0 mb-24"},[_vm._v("Edit or add stocktake locations.")]),(_vm.locations.length)?_c('ez-table',{attrs:{"data":_vm.locations,"columns":['name'],"headers":{ name: () => 'Location Name' }},on:{"rowHover":_vm.rowHover},scopedSlots:_vm._u([{key:"cell-name",fn:function({ row }){return [_c('div',{staticClass:"actions-wrapper"},[_c('span',[_vm._v(_vm._s(row.name))]),_c('div',[(_vm.isRowHovered(row))?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: 'Edit location',
                classes: ['tooltip-general'],
              }),expression:"{\n                content: 'Edit location',\n                classes: ['tooltip-general'],\n              }"}],on:{"click":function($event){$event.stopPropagation();return _vm.editLocation(row)}}},[_c('font-awesome-icon',{attrs:{"icon":"pen"}})],1):_vm._e(),(_vm.isRowHovered(row))?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: 'Delete location',
                classes: ['tooltip-general'],
              }),expression:"{\n                content: 'Delete location',\n                classes: ['tooltip-general'],\n              }"}],staticClass:"ml-12",on:{"click":function($event){$event.stopPropagation();return _vm.deleteLocation(row)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash"}})],1):_vm._e()])])]}}],null,false,704631484)}):_vm._e(),_c('ez-button',{staticClass:"mt-16",attrs:{"type":"secondary","isFullWidth":""},on:{"click":_vm.openLocationModal}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" Add Location ")],1),_c('ez-form-modal',{ref:"modal",on:{"close":_vm.onClose},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.isEdit ? 'Edit ' : 'Add ')+"Location")]},proxy:true},{key:"content",fn:function(){return [_c('form',{staticClass:"input",on:{"submit":function($event){$event.preventDefault();}}},[_c('ez-input',{attrs:{"name":"locationName","label":"Name","value":_vm.locationName,"placeholder":"Enter Location Name","formKey":"create-update-location"},on:{"onChange":function($event){_vm.locationName = $event}}})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('ez-button',{attrs:{"type":"link","formType":"button"},on:{"click":_vm.onClose}},[_vm._v("Cancel")]),_c('ez-button',{attrs:{"disabled":!_vm.locationName,"type":"primary"},on:{"click":_vm.addOrEditLocation}},[_vm._v(_vm._s(_vm.isEdit ? 'Save Changes' : 'Add Location'))])]},proxy:true}])}),_c('ez-confirmation-modal',{ref:"deleteModal",attrs:{"type":"red"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Remove Location?")]},proxy:true},{key:"content",fn:function(){return [_c('p',[_vm._v("This location will be deleted from the list.")])]},proxy:true},{key:"footer",fn:function(){return [_c('ez-button',{attrs:{"type":"link"},on:{"click":_vm.closeDeleteModal}},[_vm._v("Cancel")]),_c('ez-button',{attrs:{"type":"red"},on:{"click":_vm.confirmDeleteLocation}},[_vm._v("Remove Location")])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }