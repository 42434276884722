<template>
  <buttons-actions
    @dispute="$emit('dispute')"
    @resolve="$emit('resolve')"
    @complete="$emit('complete')"
    @reorder="$emit('reorder')"
    @receive="$emit('receive')"
    @createFromDraft="$emit('createFromDraft')"
    @cancel="$emit('decline')"
    @approve="$emit('approve')"
    @abandon="$emit('abandon')"
    @edit="$emit('edit')"
    @saveEdit="$emit('saveEdit')"
    @discardEdit="$emit('discardEdit')"
    @copyToClipboard="$emit('copyToClipboard')"
    :order="order"
    :editing-mode="editingMode"
    :are-all-products-declined="areAllIncludedProductsDeclined"
    :are-some-products-declined="areSomeProductsDeclined"
    :has-greater-then-two-actions="hasGreaterThenTwoActions"
    :to-disable-save-changes="toDisableSaveChanges"
  />
</template>

<script>
import ButtonsActions from '@/views/common/orders/orderActions/ButtonsActions';
import { areAllProductsDeclined, areSomeProductsDeclined, isIncluded } from '@/util/utils';

/**
 * Simple component to show buttons based on the order status.
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    ButtonsActions,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    editingMode: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    products() {
      return this.order?.orderedProducts || [];
    },
    hasGreaterThenTwoActions() {
      return this.order?.actions?.length > 2;
    },
    areAllIncludedProductsDeclined() {
      const included = this.products.filter(isIncluded);
      return areAllProductsDeclined(included);
    },
    areSomeProductsDeclined() {
      return areSomeProductsDeclined(this.products);
    },
    toDisableSaveChanges() {
      return !this.products.length;
    },
  },
};
</script>
