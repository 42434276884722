<script>
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import EzButton from '@/components/ui/Button/EzButton';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';

/**
 * VSelectSearchProductGroup
 * @version 1.0.0
 * @since 3.29.0
 */
export default {
  name: 'VSelectSearchProductGroup',
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },
    name: {
      type: String,
      default: '',
      required: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    placeholder: {
      type: String,
      default: '',
      required: false,
    },
    searchProperty: {
      type: String,
      default: '',
      required: false,
    },
    isFullWidth: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasActions: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedId: {
      type: Number,
      required: false,
    },
    stopPropagation: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * Align dropdown to left.
     * Default value is right.
     */
    alignLeft: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    EzButton,
    VSelectSearch,
  },
  data() {
    return {
      commonCy,
    };
  },
  methods: {
    close() {
      this.$refs.chooseGroup.close();
    },
    addNew() {
      this.$emit('addGroup');
    },
    open() {
      this.$emit('open');
    },
    onClose() {
      this.$emit('onClose');
    },
    edit(item) {
      this.$emit('edit', item);
    },
    remove(item) {
      this.$emit('remove', item);
    },
    onSelect(item) {
      this.$emit('select', item);
    },
    reset() {
      this.$refs.chooseGroup.reset();
    },
  },
};
</script>

<template>
  <v-select-search
    ref="chooseGroup"
    :data="data"
    :name="name"
    :selected="selectedId"
    :label="label"
    :placeholder="placeholder"
    :searchProperty="searchProperty"
    :isFullWidth="isFullWidth"
    :hasClear="false"
    scrollIntoView
    :stopPropagation="stopPropagation"
    @open="open"
    @close="onClose"
    @selected="onSelect"
    :class="['product-group', { 'product-group--first-action': hasActions }]"
    :data-cy="commonCy.PRODUCTS.PRODUCT_GROUP.BUTTON__PRODUCT_GROUP"
    :alignLeft="alignLeft"
    :disable="disabled"
  >
    <template #firstResult v-if="hasActions">
      <ez-button
        @click.stop="addNew"
        class="add-unit"
        type="blue-link"
        :data-cy="commonCy.PRODUCTS.PRODUCT_GROUP.BUTTON__ADD_NEW_GROUP"
      >
        <font-awesome-icon icon="plus" /> Add New Group
      </ez-button>
    </template>
    <template #result="{ result }">
      <div class="result">
        <div class="result__item">
          <span :data-cy="`${commonCy.PRODUCTS.PRODUCT_GROUP.TEXT__RESULT}-${result.id}`">
            {{ result.name }}
          </span>

          <div class="result__item__actions">
            <ez-button
              type="link"
              v-if="hasActions && result.id"
              @click.stop="edit(result)"
              class="action"
              :data-cy="`${commonCy.PRODUCTS.PRODUCT_GROUP.BUTTON__EDIT}-${result.id}`"
            >
              <font-awesome-icon icon="pen" />
            </ez-button>
            <ez-button
              type="link"
              v-if="result.canBeDeleted && hasActions"
              @click.stop="remove(result)"
              class="action"
              :data-cy="`${commonCy.PRODUCTS.PRODUCT_GROUP.BUTTON__REMOVE}-${result.id}`"
            >
              <font-awesome-icon icon="trash" />
            </ez-button>
          </div>
        </div>
      </div>
    </template>
  </v-select-search>
</template>

<style lang="scss" scoped>
.product-group {
  .add-unit {
    padding: 0;
    width: 100%;
    text-align: left;
  }

  :deep() {
    .select-search {
      &__trigger {
        @include font-size(14px, 16px);

        span:not(.select-search__value) {
          color: $color-gray-6C;
        }
      }

      &__search-wrapper {
        padding: 8px 8px 0 8px;
        border: none;
        margin-bottom: 8px;

        .input-wrapper {
          @include font-size(14px, 16px);
          border: 1px solid $input-border-color;
          border-radius: $input-border-radius;
          padding: 0 10px;

          svg {
            color: $color-gray-6C;
          }
        }
      }

      &__list-wrapper {
        li {
          padding: 0;
        }
      }
    }

    label {
      color: $color-gray-6C;
    }
  }

  &--first-action {
    :deep() .select-search__list-wrapper {
      li {
        &:first-child {
          padding: 0 12px;
        }
      }
    }
  }

  .result {
    height: 36px;
    width: 100%;
    padding: 6px 12px;

    &__item {
      @include font-size(14px, 16px);
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__actions {
        @include font-size(14px);
        color: $color-gray-6C;

        .action {
          display: none;
          padding: 0;
          width: 36px;
          height: 36px;
        }
      }
    }

    &:hover {
      .action {
        display: initial;
      }
    }
  }
}
</style>
