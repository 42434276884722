export const ONBOARDING_STEP_1 = 0;
export const ONBOARDING_STEP_2 = 1;

const steps = {
  [ONBOARDING_STEP_1]: {
    component: () => import(/* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/onboarding/Contacts.vue'),
    title: 'Please add your contacts',
    pageTitle: 'Please add your contacts',
    pageInfo: 'Choose from your existing users or add your new finance and ordering contacts.',
    nextCta: 'Go To Overview',
  },
  [ONBOARDING_STEP_2]: {
    component: () => import(/* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/onboarding/Overview.vue'),
    title: 'Overview',
    pageTitle: 'Overview',
    pageInfo: 'Before you complete the form and connect with the supplier, check the information below to make sure you’ve entered everything correctly.',
    nextCta: 'Complete and Connect',
  },
};

export default steps;
