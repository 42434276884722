<script>
/**
 * Timeline
 * @version 1.0.0
 * @since
 */
import dayjs from 'dayjs';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import VEntityInfo from '@/components/v3/elements/VEntityInfo';
import TimelineBadge from '@/views/common/orders/history-log/TimelineBadge';
import {
  ADDED_ORDER_PRODUCTS,
  CREDIT_NOTE_HISTORY_LOG,
  DELIVERY_DATE_UPDATE,
  DELIVERY_FEE_UPDATE,
  EDITED_ORDER_PRODUCTS,
  REMOVED_ORDER_PRODUCTS,
} from '@/util/constants';
import { getCurrency, formatPrice, getDateFormat } from '@/util/utils';
import VPrice from '@/components/v3/elements/VPrice';

export default {
  components: {
    TimelineBadge,
    VEntityInfo,
    VPrice,
  },
  props: {
    history: {
      type: Array,
      required: true,
      default: () => [],
    },
    currency: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      dayjs,
      to: '→',
      formatPrice,
      commonCy,
    };
  },
  computed: {
    /**
     * Return grouped results by day.
     */
    historyGrouped() {
      const output = {};
      let key;
      this.history.forEach(item => {
        key = new Date(item.createdAt).setHours(0, 0, 0, 0);
        (output[key] = output[key] || []).push(item);
      });
      return output;
    },
    returnedCurrency() {
      return this.currency || getCurrency();
    },
  },
  methods: {
    isNumber(val) {
      const num = Number(val);
      return Number.isFinite(num);
    },
    formatPriceCustom(val, currency) {
      if (this.isNumber(val)) {
        return formatPrice(val, currency);
      }
      return val;
    },
    dateLabel(date) {
      const d = dayjs(+date);
      const today = dayjs().endOf();
      const yesterday = dayjs().subtract(1, 'day').endOf('day');
      if (d.isSame(today, 'day')) return 'today';
      if (d.isSame(yesterday, 'day')) return 'yesterday';
      return d.format(`dddd, ${getDateFormat()}`);
    },
    orderLink(order) {
      const { id } = order;
      const { href } = this.$router.resolve({
        name: 'distributor-orders-all',
        params: { id },
      });
      return href;
    },
    activityLink(expense) {
      const { id } = expense;
      const { href } = this.$router.resolve({
        name: 'distributor-account-check-in-single',
        params: { id },
      });
      return href;
    },
    creditNoteLink(creditNote) {
      const { id } = creditNote;
      const { href } = this.$router.resolve({
        name: 'distributor-credit-notes',
        params: { id },
      });
      return href;
    },
    eventLink(event) {
      const { order, expense, creditNote } = event;
      if (order) return this.orderLink(order);
      if (expense) return this.activityLink(expense);
      if (creditNote) return this.creditNoteLink(creditNote);
      return null;
    },
    attributes(event) {
      return {
        is: event.order || event.expense || event.creditNote ? 'a' : 'div',
        href: this.eventLink(event),
        target: this.eventLink(event) ? '_blank' : null,
      };
    },
    eventStatusBasedOnType(event) {
      return event.type === EDITED_ORDER_PRODUCTS ||
        event.type === DELIVERY_FEE_UPDATE ||
        event.type === DELIVERY_DATE_UPDATE ||
        event.type === CREDIT_NOTE_HISTORY_LOG
        ? event.type
        : event.status;
    },
    eventStatusBasedOnAction(event) {
      return event.metadata?.action;
    },
    isAction(event) {
      const act = event.metadata?.action;
      return (
        event.type === 'product_update' &&
        (act === ADDED_ORDER_PRODUCTS || act === REMOVED_ORDER_PRODUCTS)
      );
    },
    isPriceChanged(type) {
      return type === 'price';
    },
    isAddedOrDeleted(type) {
      return type === 'added' || type === 'removed';
    },
    isQuantityEdited(type) {
      return type === 'quantity';
    },
    isDeliveryFeeEdited(type) {
      return type === DELIVERY_FEE_UPDATE;
    },
    isDeliveryOnEdited(type) {
      return type === DELIVERY_DATE_UPDATE;
    },
  },
};
</script>
<template>
  <div class="timeline">
    <div class="timeline__group" v-for="(item, key) in historyGrouped" :key="key">
      <h3 class="timeline__title">{{ dateLabel(key) | uppercase }}</h3>
      <ul class="timeline__list">
        <li
          class="timeline__item"
          v-for="(event, idx) in item"
          :key="event.id"
          :data-cy="`${commonCy.SINGLE_ORDER.ROW__HISTORY_LOG}-${event.type}-${idx}`"
        >
          <componenet v-bind="attributes(event)" class="timeline__item__a">
            <timeline-badge
              class="mr-8"
              :status="
                isAction(event)
                  ? eventStatusBasedOnAction(event)
                  : eventStatusBasedOnType(event) || event.type
              "
            />
            <v-entity-info :imageUrl="event.logo" imageBorderRadius="50%" :imageHasBorder="true">
              <template>
                <div v-if="event.log" class="ml-12" v-html="event.log"></div>
                <div v-if="event.text" class="ml-12">
                  <div v-html="event.text"></div>
                  <div v-if="event.order" class="timeline__item--order u-flex-center">
                    <div v-if="event.order.amountWithTax" class="timeline__item--order__spent">
                      <v-price
                        :price="event.order.amountWithTax"
                        :currency="returnedCurrency"
                        :show-market-price-info="false"
                        :is-to-be-determined="event.order.tbd"
                      />
                    </div>
                    <span
                      v-if="event.order.amountWithTax && event.order.orderNumber"
                      class="mx-2 timeline__item__dot"
                    >
                      &bull;
                    </span>
                    <span class="timeline__item--order__number--link">
                      #{{ event.order.orderNumber }}
                    </span>
                    <font-awesome-layers
                      v-if="event.order.status === 'completed'"
                      class="timeline__item__order-icon mx-4"
                      v-tooltip="{
                        content: 'Completed',
                        classes: ['tooltip-general'],
                        placement: 'top',
                      }"
                    >
                      <font-awesome-icon class="timeline__item__order-icon__circle" icon="circle" />
                      <font-awesome-icon
                        class="timeline__item__order-icon__check"
                        icon="check"
                        transform="shrink-6"
                      />
                    </font-awesome-layers>
                  </div>

                  <div v-if="event.creditNote" class="timeline__item--order u-flex-center">
                    <div v-if="event.creditNote.amount" class="timeline__item--order__spent">
                      <v-price
                        :price="event.creditNote.amount"
                        :currency="returnedCurrency"
                        :show-market-price-info="false"
                      />
                    </div>
                    <span
                      v-if="event.creditNote.amount && event.creditNote.creditNoteNumber"
                      class="mx-2 timeline__item__dot"
                    >
                      &bull;
                    </span>
                    <span
                      v-if="event.creditNote.creditNoteNumber"
                      class="timeline__item--order__number--link"
                    >
                      #{{ event.creditNote.creditNoteNumber }}
                    </span>
                  </div>

                  <div v-if="event.expense" class="timeline__item--expense u-flex-center">
                    <span v-if="event.expense.amount" class="timeline__item--expense__spent">
                      {{ formatPrice(event.expense.amount, returnedCurrency) }}
                    </span>
                    <span
                      v-if="event.expense.amount && event.expense.note"
                      class="timeline__item__dot mx-2"
                    >
                      &bull;
                    </span>
                    <span
                      class="text-color--gray tooltip-note"
                      v-tooltip="{
                        content: `${event.expense.note}`,
                        classes: ['tooltip-general'],
                        placement: 'top',
                      }"
                    >
                      {{ event.expense.note }}
                    </span>
                  </div>
                </div>
                <div v-if="event.metadata" class="ml-12">
                  <div v-if="event.metadata" class="timeline__item--expense u-flex-center">
                    <span
                      v-if="isAddedOrDeleted(event.metadata.action)"
                      class="timeline__item--expense__spent"
                    >
                      <span class="quantity mr-2 mt-2">Quantity:</span>
                      {{
                        event.metadata.action === 'added'
                          ? event.metadata.newValue
                          : event.metadata.oldValue
                      }}
                    </span>
                    <span
                      v-if="isPriceChanged(event.metadata.type)"
                      class="timeline__item--expense__spent"
                    >
                      <span :class="{ mp: !isNumber(event.metadata.oldValue) }">
                        {{ formatPriceCustom(event.metadata.oldValue, returnedCurrency) }}
                      </span>
                      {{ event.metadata.oldValue ? to : '' }}
                      <span :class="{ mp: !isNumber(event.metadata.newValue) }">
                        {{ formatPriceCustom(event.metadata.newValue, returnedCurrency) }}
                      </span>
                    </span>
                    <span
                      v-if="isQuantityEdited(event.metadata.type)"
                      class="timeline__item--expense__spent"
                    >
                      {{ event.metadata.oldValue }}
                      {{ event.metadata.oldValue ? to : '' }}
                      {{ event.metadata.newValue }}
                    </span>
                    <span
                      v-if="isDeliveryFeeEdited(event.type)"
                      class="timeline__item--expense__spent"
                    >
                      {{ formatPrice(event.metadata.oldValue, returnedCurrency) }}
                      {{ to }}
                      {{ formatPrice(event.metadata.newValue, returnedCurrency) }}
                    </span>
                    <span
                      v-if="isDeliveryOnEdited(event.type)"
                      class="timeline__item--expense__spent"
                    >
                      <template v-if="event.metadata.oldValue">
                        {{ dayjs(event.metadata.oldValue).valueOf() | date }}
                        {{ to }}
                      </template>
                      {{ dayjs(event.metadata.newValue).valueOf() | date }}
                    </span>
                    <span v-if="event.message" class="timeline__item__dot mx-2"> &bull; </span>
                    <span
                      class="text-color--gray tooltip-note"
                      v-tooltip="{
                        content: `${event.message}`,
                        classes: ['tooltip-general'],
                        placement: 'top',
                      }"
                    >
                      {{ event.message }}
                    </span>
                  </div>
                </div>
              </template>
            </v-entity-info>
            <time class="timeline__date" :datetime="event.createdAt | htmlDateTime">
              {{ event.createdAt | time }}
            </time>
          </componenet>
        </li>
      </ul>
    </div>
  </div>
</template>
<style lang="scss" scoped>
:deep() .entity-info {
  overflow: hidden;
  flex: 0 1 auto;
  padding-right: 12px;

  .entity-info__info {
    margin: 0;
  }
}

.timeline {
  padding: 24px 0;
  &__list {
    @extend %ul-reset;
    position: relative;
    padding-top: 16px;
    &::before {
      content: '';
      @include absolute(left 15px top 0);
      height: 100%;
      width: 1px;
      background-color: #dde1eb;
    }
  }
  &__title {
    margin: 0 0 4px;
    color: #6c7995;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.25px;
    line-height: 14px;
  }
  &__item {
    @extend %flex-center;
    font-size: 14px;

    & + & {
      margin-top: 16px;
    }

    &--expense {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__a {
      text-decoration: none;
      width: 100%;
      display: flex;
    }
  }
  &__date {
    margin-left: auto;
    color: #6c7995;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.25px;
  }
}
.timeline__item--order {
  font-size: 12px;
  :deep() .v-price__price {
    @include font-size(12px);
  }
  &__number--link {
    text-decoration: none;
    color: $color-gray-6C;
  }
  &__spent {
    font-weight: 600;
  }
}

.timeline__item__dot {
  color: $color-gray-6C;
}

.timeline__item__order-icon {
  font-size: 16px;
}

.timeline__item__order-icon__circle {
  color: $color-pastel-green;
}

.timeline__item__order-icon__check {
  color: $color-primary-green;
}

.timeline__item--expense {
  @include font-size(12px, 14px);
}

.timeline__item--expense__spent {
  font-weight: 600;
  .quantity {
    color: $color-gray-6C;
    font-weight: 500;
  }
  .mp {
    color: $color-primary-orange-red;
  }
}

.timeline__group + .timeline__group {
  margin-top: 12px;
}

.tooltip-note {
  display: initial;
}
</style>
