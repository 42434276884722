<script>
/**
 *
 * @version 1.0.0
 * @since 2.3.0
 */

import { mapActions, mapState, mapGetters } from 'vuex';
import EzTable from '@/components/ui/Table';
import EzEntityInfo from '@/components/ui/EntityInfo';
import { EzCsvUploadModal } from '@/components/ui/Modal';
import EzButton, { EzButtonGroup } from '@/components/ui/Button';
import ProposePriceModal from '@/views/platform/venue/suppliers/all/ProposePriceModal';
import EzProductModal from '@/views/common/products/new-product-supplier/EzProductModal';
import EzCategoryFilter from '@/components/ui/Filter/Category.vue';
import EzInput from '@/components/ui/Input';
import EzFilterList from '@/components/ui/FilterList';
import flash from '@/components/ui/FlashMessage';
import { falsy, debounce, clone } from '@/util/utils';
import confirmation from '@/components/v3/patterns/VConfirmation/control';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import EmptyState from '@/views/common/empty-state/EmptyState';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown';
import downloadAttachment from '@/util/downloadAttachment';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzSpinner from '@/components/ui/Spinner';
import { LOADING_KEY, SELECT_DESELECT_ALL } from '@/util/constants';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import VPrice from '@/components/v3/elements/VPrice';
import VFilterDropdown from '@/components/v3/patterns/VFilterDropdown';
import EzProductGroupFilter from '@/components/ui/Filter/ProductGroup.vue';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import VBadge from '@/components/v3/elements/VBadge';
import VSelectSearchProductGroup from '@/components/v3/elements/VProductGroupSettings/VSelectSearchProductGroup.vue';
import EzProductsUpdateModal from '@/components/ui/Modal/EzProductsUpdateModal.vue';
import RemoveGroupModal from '@/components/v3/elements/VProductGroupSettings/RemoveGroupModal.vue';
import AddGroupModal from '@/components/v3/elements/VProductGroupSettings/AddGroupModal.vue';
import FooterForUpdate from '@/views/platform/venue/xero/settings/FooterForUpdate';
import StatusBadge from '@/views/common/status-badge/StatusBadge.vue';
import VLocationBadge from '@/components/v3/elements/VLocationBadge';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal.vue';
import SelectLocationsModal from './SelectLocationsModal.vue';

const FilterArea = {
  List: 'list',
  Dropdown: 'dropdown',
};

export default {
  components: {
    EzCsvUploadModal,
    EzButtonGroup,
    ProposePriceModal,
    EzProductModal,
    EzButton,
    EzTable,
    EzCategoryFilter,
    EzInput,
    EzFilterList,
    EzLoadMore,
    EmptyState,
    EzButtonDropdown,
    EzEntityInfo,
    EzLoader,
    EzSpinner,
    VDataWithInfo,
    VPrice,
    VFilterDropdown,
    EzProductGroupFilter,
    EzCheckbox,
    VBadge,
    VSelectSearchProductGroup,
    EzProductsUpdateModal,
    RemoveGroupModal,
    AddGroupModal,
    FooterForUpdate,
    StatusBadge,
    VLocationBadge,
    SelectLocationsModal,
    EzConfirmationModal,
  },
  props: {
    supplierId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      headers: {
        orderingUnit: () => 'Unit',
        priceChangeNotification: () => '% Notify',
        isHidden: () => 'Visibility',
        price: () => 'Price per unit',
        actions: () => '',
      },
      FilterArea,
      products: [],
      selectedProduct: {},
      meta: {},
      dropdownFilters: {},
      listFilters: { term: null },
      listOfProductIds: [],
      categories: [],
      productGroups: [],
      selected: false,
      currentProductId: null,
      updateProductGroups: false,
      selectedProductLocations: [],
      productId: null,
      updateData: null,
      productsBulkUpdate: false,
      isOpened: null,
    };
  },
  computed: {
    ...mapState('entities/distributors', ['selectedSupplier']),
    ...mapState('entities/users', ['contextId', 'loggedUser']),
    ...mapGetters('loading', ['getLoading']),
    ...mapGetters('entities/users', ['getVenues']),
    groupViewMode() {
      if (this.loggedUser.context === 'venue-group') return true;
      return false;
    },
    venues() {
      return this.getVenues;
    },
    venueId() {
      return this.contextId;
    },
    distributor() {
      return this.selectedSupplier || {};
    },
    isPremium() {
      return this.$helpers.isPremium(this.distributor.accountType);
    },
    isEditable() {
      return this.distributor.editable;
    },
    editAction() {
      return `/venue/distributors/${this.supplierId}/products`;
    },
    columns() {
      return [
        'name',
        'orderingUnit',
        'productGroup',
        'priceChangeNotification',
        'isHidden',
        'price',
        ...(this.$permission.isPremium && this.hasManageDistributorPermission ? ['actions'] : []), // If you are premium you can always set/propose a price
      ];
    },
    productGroupsFilter() {
      return [
        { id: null, name: 'All Product Groups' },
        { id: '', name: 'Unassigned' },
        ...this.productGroups,
      ];
    },
    filters() {
      return { ...this.listFilters, ...this.dropdownFilters };
    },
    hasFilters() {
      return Object.entries(this.filters).reduce(
        (prevFilter, [, value]) => prevFilter || !!value,
        false,
      );
    },
    loading() {
      return (
        this.getLoading(LOADING_KEY.VENUE_HIDE_UNHIDE_PRODUCTS) ||
        this.getLoading(LOADING_KEY.VENUE_SUPPLIER_PRODUCTS)
      );
    },
    singleHideUnhideLoading() {
      return (
        this.getLoading(LOADING_KEY.VENUE_HIDE_UNHIDE_DISTRIBUTOR_PRODUCT) ||
        this.getLoading(LOADING_KEY.VENUE_UPDATE_SUPPLIER_PRODUCT) ||
        this.getLoading(LOADING_KEY.FETCH_PRODUCT_GROUPS)
      );
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.VENUE_SUPPLIER_PRODUCTS_MORE);
    },
    exportLoading() {
      return this.getLoading(LOADING_KEY.VENUE_EXPORT_PRODUCTS);
    },
    bulkActionProductsCount() {
      return this.selected
        ? this.meta.totalCount - this.listOfProductIds.length
        : this.listOfProductIds.length;
    },
    areAllSelected() {
      return this.bulkActionProductsCount === this.meta.totalCount;
    },
    hasManageDistributorPermission() {
      return this.$permission.has('manageDistributor');
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'venueFetchNewDistributorProducts',
      'venueFetchNewDistributorProduct',
      'venueRemoveNewDistributorProduct',
      'venueUploadDistributorProducts',
      'venueHideDistributorProduct',
      'venueUnhideDistributorProduct',
      'venueHideAllDistributorProduct',
      'venueUnhideAllDistributorProduct',
      'venueFetchProductGroups',
      'venueBulkUpdateSupplierProducts',
      'venueUpdateSuppliersProduct',
      'venueCreateProductGroup',
      'venueUpdateProductGroup',
      'venueDeleteProductGroup',
    ]),
    ...mapActions('entities/categories', ['fetchCategoriesByVenueId']),
    ...mapActions('entities/venues', ['outletDownloadProductsCSV']),
    nameCheckbox(h) {
      return h('span', { class: 'u-flex-center select-all-checkbox' }, [
        h(
          'ez-checkbox',
          {
            class: 'mr-8',
            props: {
              label: 'Product',
              indeterminate: this.bulkActionProductsCount > 0 && !this.areAllSelected,
              checked: this.areAllSelected,
            },
            on: {
              change: event => {
                this.selectDeselectAll(event);
              },
            },
          },
          [],
        ),
      ]);
    },
    isProductSelected(product) {
      return (
        (this.selected && this.listOfProductIds.findIndex(el => el.productId === product.id) < 0) ||
        (!this.selected && this.listOfProductIds.findIndex(el => el.productId === product.id) >= 0)
      );
    },
    onCheckboxChange(product, checked) {
      if (this.selected !== checked) this.listOfProductIds.push({ productId: product.id });
      else
        this.listOfProductIds = this.listOfProductIds.filter(item => item.productId !== product.id);
    },
    async selectDeselectAll(selected) {
      this.selected = selected;
      this.listOfProductIds = [];
    },
    resetSelectedProducts() {
      this.selected = false;
      this.listOfProductIds = [];
    },
    editSelectedProducts() {
      this.$refs.productsUpdateModal.open();
    },
    editSelectedProductsVisibility(allSelected = false) {
      if (allSelected) {
        this.selectedProductLocations = this.venues.map(item => item.id);
      } else {
        this.selectedProductLocations = [];
      }
      this.productId = null;
      this.productsBulkUpdate = true;
      this.$refs.locationsModal.open();
    },
    proposePrice(product) {
      this.selectedProduct = product;
      this.$refs.modal.open();
    },
    newProduct() {
      this.updateProductGroups = true;
      this.selectedProduct = {};
      this.openEditModal();
    },
    closeEditModal() {
      this.$refs.productModal.close();
      if (this.updateProductGroups) this.updateProductGroups = false;
    },
    openEditModal() {
      this.$refs.productModal.open();
    },
    async openSingleProduct(product) {
      const { editable, id } = product;
      if (!editable) return;
      const { data } = await this.venueFetchNewDistributorProduct({
        venueId: this.venueId,
        distributorId: this.supplierId,
        productId: id,
      });
      this.selectedProduct = data.data;
      this.openEditModal();
    },
    onModalSuccess() {
      flash.success({
        title: `Product successfully ${falsy(this.selectedProduct) ? 'added' : 'updated'}.`,
      });
      this.selectedProduct = {};
      this.refresh();
      this.closeEditModal();
    },
    resetFilters(area) {
      if (area === FilterArea.List) {
        Object.keys(this.listFilters).forEach(key => {
          this.listFilters[key] = null;
        });
      } else if (area === FilterArea.Dropdown) {
        this.dropdownFilters = {};
        this.visible = false;
        this.hidden = false;
      }
      this.resetSelectedProducts();
      this.$nextTick(() => this.$refs.filtersGroup.syncLocalFilters());
      this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());

      this.allSuplierProducts = [];
      this.meta = {};
      this.refresh();
    },
    updateFilters: debounce(async function deb(area, event) {
      if (area === FilterArea.List) {
        const [filterName, value] = event;
        this.listFilters = { ...this.listFilters, [filterName]: value };
      } else if (area === FilterArea.Dropdown) {
        this.dropdownFilters = {
          ...this.dropdownFilters,
          ...(typeof event.categoryId === 'object' &&
            event.categoryId !== null && { categoryId: event.categoryId.id }),
          ...(typeof event.distributorId === 'object' &&
            event.distributorId !== null && { distributorId: event.distributorId.id }),
          ...(typeof event.productGroupId === 'object' &&
            event.productGroupId !== null && { productGroupId: event.productGroupId.id }),
          ...(event.visible !== undefined && { visible: event.visible }),
          ...(event.hidden !== undefined && { hidden: event.hidden }),
        };
        if (event.distributorId === undefined) delete this.dropdownFilters.distributorId;
        this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());
      }
      this.resetSelectedProducts();
      this.products = [];
      this.meta = {
        nextId: null,
        nextValue: null,
        ...(this.listFilters.term && { term: this.listFilters.term }),
      };
      this.refresh();
    }, 300),
    onPriceChange(newPrice) {
      flash.success({
        title: `New Price successfully ${this.isPremium ? 'propose' : 'set'}`,
        message: `You have ${
          this.isPremium ? 'propose' : 'set'
        } a new price of ${this.$helpers.formatPrice(newPrice)} for ${this.selectedProduct.name}`,
      });
      this.refresh();
    },
    async removeProduct({ id }) {
      const { close, canceled } = await confirmation.alert({
        title: this.$t('product.removeProductAlertTitle'),
        message: this.$t('product.removeProductAlertMessage'),
      });
      if (canceled) return;
      close();

      await this.venueRemoveNewDistributorProduct({
        venueId: this.venueId,
        distributorId: this.supplierId,
        productId: id,
      });
      flash.success({ title: 'Product successfully removed.' });
      await this.refresh();
    },
    fetchNewDistributorProducts(loadingKey) {
      const queryParams = {
        ...(this.meta.nextId ? { nextId: this.meta.nextId } : {}),
        ...(this.meta.nextValue ? { nextValue: this.meta.nextValue } : {}),
        ...this.filters,
        ...(this.filters?.visible !== this.filters?.hidden && this.filters?.visible
          ? { visibility: true }
          : {}),
        ...(this.filters?.visible !== this.filters?.hidden && this.filters?.hidden
          ? { visibility: false }
          : {}),
        sortBy: 'name',
        limit: '20',
      };
      return this.venueFetchNewDistributorProducts({
        distributorId: this.supplierId,
        query: queryParams,
        ...(loadingKey && { loadingKey }),
      });
    },
    async refresh(loadingKey = null) {
      this.meta = {};
      const {
        data: { data, meta },
      } = await this.fetchNewDistributorProducts(loadingKey);
      this.products = data;
      this.meta = meta;
    },
    async loadMore() {
      const { data } = await this.fetchNewDistributorProducts(
        LOADING_KEY.VENUE_SUPPLIER_PRODUCTS_MORE,
      );
      this.products = [...this.products, ...data.data];
      this.meta = data.meta;
    },
    onLoadMore() {
      this.loadMore();
    },
    uploadCSVModal() {
      this.$refs.csvUploadModal.open();
    },
    uploadCSVAction(data) {
      return this.venueUploadDistributorProducts({
        distributorId: this.supplierId,
        data,
      });
    },
    onUploadFinished() {
      this.refresh();
    },
    async exportCSV() {
      const { data, headers } = await this.outletDownloadProductsCSV({
        venueId: this.venueId,
        distributorId: this.supplierId,
      });
      const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
      const name = Array.isArray(filename) ? decodeURI(filename[1]) : 'Products.xlsx';
      downloadAttachment(data, name);
    },
    updateProductAvailability(venue) {
      const { id, isHidden } = venue;
      const data = {
        productId: id,
        venueId: this.venueId,
        distributorId: this.supplierId,
      };
      return isHidden
        ? this.venueUnhideDistributorProduct(data)
        : this.venueHideDistributorProduct(data);
    },
    async onSelectProductGroup(productGroup, productId) {
      try {
        const { data } = await this.venueUpdateSuppliersProduct({
          productId,
          productGroupId: productGroup.id,
        });
        const productIndex = this.products.findIndex(item => item.id === data.data.id);
        this.products[productIndex].productGroup = clone(data.data.productGroup);
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
    async updateVisibility(venue) {
      try {
        await this.updateProductAvailability(venue);
        venue.isHidden = !venue.isHidden;
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
    async fetchProductGroups() {
      const {
        data: { data },
      } = await this.venueFetchProductGroups();
      this.productGroups = data;
    },
    async fetchCategories() {
      const { data } = await this.fetchCategoriesByVenueId(this.venueId);
      this.categories = data;
    },
    async bulkUpdateProductAction(updateData) {
      await this.venueBulkUpdateSupplierProducts({
        distributorId: this.distributor.id,
        config: {
          ...updateData,
          state: this.selected
            ? SELECT_DESELECT_ALL.selectedAll
            : SELECT_DESELECT_ALL.deselectedAll,
          items: this.listOfProductIds,
        },
        ...this.filters,
        ...(this.filters?.visible !== this.filters?.hidden && this.filters?.visible
          ? { visibility: true }
          : {}),
        ...(this.filters?.visible !== this.filters?.hidden && this.filters?.hidden
          ? { visibility: false }
          : {}),
      });
    },
    async bulkUpdateProducts(updateData) {
      if (this.groupViewMode) {
        this.$refs.bulkUpdateConfirmation.open();
        this.updateData = updateData;
      } else {
        await this.bulkUpdateProductAction(updateData);
        this.$refs.productsUpdateModal.close();
        this.refresh();
      }
    },
    onAddGroup(id) {
      this.currentProductId = id;
      this.$refs.addGroupModal.open();
    },
    onEdit(group) {
      this.$refs.addGroupModal.open(group, true);
    },
    onRemove(group) {
      this.$refs.removeGroupModal.open(group);
    },
    async onAddConfirm({ groupId, name }) {
      const isEdit = !!groupId;
      try {
        if (isEdit) {
          const { data } = await this.venueUpdateProductGroup({
            id: groupId,
            name,
            formKey: 'createProductGroup',
          });
          const productsWithGroup = this.products.filter(
            item => item.productGroup?.id === data.data.id,
          );
          productsWithGroup.forEach(product => {
            const productIndex = this.products.findIndex(item => item.id === product.id);
            this.products[productIndex].productGroup = clone(data.data);
          });
        } else {
          const { data } = await this.venueCreateProductGroup({
            name,
            formKey: 'createProductGroup',
          });
          const currentProductIndex = this.products.findIndex(
            item => item.id === this.currentProductId,
          );
          this.products[currentProductIndex].productGroup = clone(data.data);
        }

        flash.success({ title: `Product group successfully ${isEdit ? 'updated' : 'created'}!` });
        this.$refs.addGroupModal.close();

        await this.fetchProductGroups();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    async onRemoveConfirm(groupId) {
      try {
        await this.venueDeleteProductGroup({ id: groupId });
        const productsWithGroup = this.products.filter(item => item.productGroup?.id === groupId);
        productsWithGroup.forEach(product => {
          const productIndex = this.products.findIndex(item => item.id === product.id);
          this.products[productIndex].productGroup = null;
        });
        flash.success({ title: 'Product group successfully removed!' });

        this.$refs.removeGroupModal.close();

        await this.fetchProductGroups();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        flash.error({ title: 'Something went wrong!' });
      }
    },
    addProductGroup(productGroup) {
      this.productGroups = [...this.productGroups, productGroup];
    },
    editProductGroup(productGroup) {
      const productGroupIndex = this.productGroups.findIndex(item => item.id === productGroup.id);
      this.productGroups[productGroupIndex].name = productGroup.name;

      const productsWithGroup = this.products.filter(
        item => item.productGroup?.id === productGroup.id,
      );
      productsWithGroup.forEach(product => {
        const productIndex = this.products.findIndex(item => item.id === product.id);
        this.products[productIndex].productGroup = clone(productGroup);
      });
    },
    removeProductGroup(groupId) {
      this.productGroups = this.productGroups.filter(item => item.id !== groupId);
      const productsWithGroup = this.products.filter(item => item.productGroup?.id === groupId);
      productsWithGroup.forEach(product => {
        const productIndex = this.products.findIndex(item => item.id === product.id);
        this.products[productIndex].productGroup = null;
      });
    },
    openLocationModal(product) {
      this.selectedProductLocations = product.visibleForVenueIdsFromGroup;
      this.productId = product.id;
      this.productsBulkUpdate = false;
      this.$refs.locationsModal.open();
    },
    updateProductLocations(val) {
      const index = this.products.findIndex(item => item.id === this.productId);
      if (index !== -1) {
        this.products[index].visibleForVenueIdsFromGroup = val;
        if (val.length === this.venues.length) {
          this.products[index].visibleForAllFromGroup = true;
        } else {
          this.products[index].visibleForAllFromGroup = false;
        }
      }
    },
    bulkUpdateProductsLocations(val) {
      this.updateData = val;
      this.$refs.bulkUpdateConfirmation.open();
    },
    async bulkUpdateProductsLocationsAction(val) {
      try {
        await this.venueBulkUpdateSupplierProducts({
          distributorId: this.distributor.id,
          config: {
            visibleVenueIdsFromGroup: val,
            state: this.selected
              ? SELECT_DESELECT_ALL.selectedAll
              : SELECT_DESELECT_ALL.deselectedAll,
            items: this.listOfProductIds,
          },
          ...this.filters,
          ...(this.filters?.visible !== this.filters?.hidden && this.filters?.visible
            ? { visibility: true }
            : {}),
          ...(this.filters?.visible !== this.filters?.hidden && this.filters?.hidden
            ? { visibility: false }
            : {}),
        });
        flash.success({
          title: "Product's availability successfully updated",
        });
        this.productsBulkUpdate = false;
        this.$refs.locationsModal.close();
        this.refresh();
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
    onProductLocationsClose() {
      this.selectedProductLocations = [];
      this.productId = null;
    },
    async onConfirm() {
      if (!this.productsBulkUpdate) {
        await this.bulkUpdateProductAction(this.updateData);
        this.$refs.productsUpdateModal.close();
        this.$refs.bulkUpdateConfirmation.close();
        this.updateData = null;
        this.refresh();
      } else {
        await this.bulkUpdateProductsLocationsAction(this.updateData);
        this.$refs.bulkUpdateConfirmation.close();
      }
    },
    closeIfOpened(selectRef) {
      if (this.isOpened && this.isOpened === selectRef) {
        this.$refs[this.isOpened].close();
        this.isOpened = null;
      } else {
        this.isOpened = selectRef;
      }
    },
    close() {
      this.isOpened = null;
    },
  },
  async created() {
    await Promise.all([this.fetchCategories(), this.fetchProductGroups(), this.refresh()]);
  },
};
</script>
<template>
  <div>
    <ez-product-modal
      class="product-modal"
      ref="productModal"
      formKey="product-edit-modal"
      :formAction="editAction"
      :distributorId="supplierId"
      :product="selectedProduct"
      :updateProductGroups="updateProductGroups"
      @success="onModalSuccess"
      @close="() => (updateProductGroups = false)"
      @error="() => (updateProductGroups = false)"
    />

    <ez-csv-upload-modal
      ref="csvUploadModal"
      :for-distributor="true"
      :uploadCSV="uploadCSVAction"
      :onUploadFinished="onUploadFinished"
    />

    <propose-price-modal
      ref="modal"
      :venueId="venueId"
      :distributorId="supplierId"
      :product="selectedProduct"
      @success="onPriceChange"
      :has-market-price="!isPremium"
      can-set-zero-price
    >
      <template #title> {{ isPremium ? 'Propose' : 'Set' }} New Pricing </template>
      <template #text>
        <p>
          Please type in the price you would like to {{ isPremium ? 'propose' : 'set' }} for
          {{ selectedProduct.name }}.
        </p>
      </template>
      <template #confirm> {{ isPremium ? 'Propose' : 'Set' }} New Price </template>
    </propose-price-modal>

    <div class="all-products__actions mt-24">
      <ez-filter-list
        :filters="listFilters"
        @filterUpdated="(...$event) => updateFilters(FilterArea.List, $event)"
        @resetFilter="resetFilters(FilterArea.List)"
        class="mr-16"
      >
        <ez-input
          formKey="filters"
          label="search"
          name="term"
          class="search"
          placeholder="Search for a Product"
        >
          <template #prefix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
      </ez-filter-list>
      <v-filter-dropdown
        :filters="dropdownFilters"
        @filterUpdated="$event => updateFilters(FilterArea.Dropdown, $event)"
        @resetFilters="resetFilters(FilterArea.Dropdown)"
        ref="filtersGroup"
      >
        <template #firstRow>
          <ez-product-group-filter
            name="productGroupId"
            label="Product Group"
            :productGroups="productGroupsFilter"
            :selected="dropdownFilters.productGroupId"
            isFullWidth
          />
          <ez-category-filter
            name="categoryId"
            label="Category"
            :categories="categories"
            :selected="dropdownFilters.categoryId"
            isFullWidth
          />
        </template>
        <template #secondRowLabel>Visibility</template>
        <template #secondRow>
          <ez-checkbox
            class="status-checkbox"
            :checked="dropdownFilters.visible"
            key="visible"
            formKey="filters"
            name="visible"
            label="Visible"
          />
          <ez-checkbox
            class="status-checkbox"
            :checked="dropdownFilters.hidden"
            key="hidden"
            formKey="filters"
            name="hidden"
            label="Hidden"
          />
        </template>
      </v-filter-dropdown>

      <ez-button-group>
        <ez-button-dropdown
          v-if="!isPremium && !(groupViewMode && !loggedUser.groupViewFeaturesEnabled)"
          :showToggleIcon="false"
          :expandOnClick="true"
          buttonType="secondary"
        >
          <template>
            <span>Import/Export CSV</span>
          </template>
          <template #dropdown>
            <ez-button
              type="link"
              formType="button"
              customClass="upload-csv"
              @click="uploadCSVModal"
            >
              Upload CSV
            </ez-button>
            <ez-button type="link" formType="button" customClass="upload-csv" @click="exportCSV">
              Export CSV
            </ez-button>
          </template>
        </ez-button-dropdown>
        <ez-button @click="newProduct" v-if="!isPremium">
          <span>Add New Product</span>
        </ez-button>
      </ez-button-group>
    </div>

    <ez-table
      class="products-table mt-24"
      v-if="products.length"
      :data="products"
      :headers="{
        ...(this.hasManageDistributorPermission
          ? { name: this.nameCheckbox }
          : { name: () => 'Product' }),
        ...headers,
      }"
      :columns="columns"
      :removeButton="isEditable"
      @rowClick="openSingleProduct"
      @removeItem="removeProduct"
      :columnProps="{
        priceChangeNotification: { class: 'small-cell' },
        orderingUnit: { class: 'medium-cell' },
        productGroup: { class: 'large-cell product-group-cell' },
        isHidden: { class: 'visibility-cell' },
        price: { class: 'price-cell' },
        actions: { class: 'actions-cell' },
      }"
      :loading="singleHideUnhideLoading"
    >
      <template #cell-name="{ row }">
        <div class="u-flex-row u-flex-center">
          <ez-checkbox
            v-if="hasManageDistributorPermission"
            class="cursor-pointer mr-8"
            :key="row.id"
            :checked="isProductSelected(row)"
            stopPropagation
            @change="onCheckboxChange(row, $event)"
          />
          <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="row.image">
            <div class="product-info" :title="row.name">
              <span>{{ row.name }}</span>
              <span class="product-info-secondary">
                <span class="product-info-secondary--category">
                  {{ row.category | categoryWithParent }}
                </span>
                <span v-if="row.sku" class="product-info-secondary--sku">
                  &#8226; {{ row.sku }}
                </span>
              </span>
            </div>
          </ez-entity-info>
        </div>
      </template>
      <template #cell-isHidden="{ row }">
        <div v-if="groupViewMode" class="location-wrapper" @click.stop="openLocationModal(row)">
          <v-location-badge v-if="row.visibleForAllFromGroup" type="green">All</v-location-badge>
          <v-location-badge v-else-if="row.visibleForVenueIdsFromGroup.length" type="yellow"
            >Some</v-location-badge
          >
          <v-location-badge v-else type="red">None</v-location-badge>
          <font-awesome-icon icon="pen" class="location-edit"></font-awesome-icon>
        </div>
        <ez-button
          v-else-if="hasManageDistributorPermission"
          type="link"
          @click.stop="updateVisibility(row)"
        >
          <status-badge :status="row.isHidden ? 'hidden' : 'visible'" />
        </ez-button>
        <status-badge v-else :status="row.isHidden ? 'hidden' : 'visible'" />
      </template>
      <template #cell-orderingUnit="{ row: { orderingUnit } }">
        <template v-if="orderingUnit">
          <v-data-with-info
            :info="
              orderingUnit.abbreviation
                ? `${orderingUnit.name} ${!!$t(`product.unitInfo.${orderingUnit.type}`) ? '-' : ''}
                ${$t(`product.unitInfo.${orderingUnit.type}`)}`
                : ''
            "
            :show-underline="
              !!$t(`product.unitInfo.${orderingUnit.type}`) || !!orderingUnit.abbreviation
            "
          >
            {{ orderingUnit.label }}
          </v-data-with-info>
        </template>
      </template>
      <template #cell-productGroup="{ row: { productGroup, id } }">
        <v-select-search-product-group
          v-if="$permission.has('assignProductGroups')"
          :class="{ 'has-group': productGroup?.id !== undefined }"
          :ref="`selectProductGroup-${id}`"
          :data="productGroups"
          :selectedId="productGroup?.id"
          name="productGroup"
          placeholder="Select Group"
          searchProperty="name"
          :has-actions="$permission.has('manageProductGroups')"
          @select="event => onSelectProductGroup(event, id)"
          @addGroup="onAddGroup(id)"
          @edit="onEdit"
          @remove="onRemove"
          alignLeft
          :stopPropagation="true"
          @open="closeIfOpened(`selectProductGroup-${id}`)"
          @onClose="close(`selectProductGroup-${id}`)"
        />
        <v-badge v-else>Unassigned</v-badge>
      </template>
      <template
        #cell-priceChangeNotification="{
          row: {
            priceChangeNotification: { percent, isDefault },
          },
        }"
      >
        <span v-if="!percent && isDefault">-</span>
        <v-data-with-info v-else info="Default value" :show-underline="isDefault">
          {{ percent }}%
        </v-data-with-info>
      </template>
      <template #cell-price="{ row: { price, priceUnit, marketPrice } }">
        <v-price
          :price="price || 0"
          :unit="priceUnit && priceUnit.label"
          :is-market-price="marketPrice"
        />
      </template>
      <template #cell-actions="{ row }">
        <ez-button v-if="row.proposedPrice !== null" type="green">Price proposed</ez-button>
        <ez-button v-else type="secondary" @click.stop="proposePrice(row)" :stop-propagation="true">
          <template v-if="isPremium && hasManageDistributorPermission">Propose a price</template>
          <template v-else-if="hasManageDistributorPermission">Set Price</template>
        </ez-button>
      </template>
    </ez-table>
    <template v-else-if="!isLoadingMore">
      <empty-state class="empty-state-center" v-if="hasFilters">
        <template #badge> <img src="@/assets/no-products-search-state.svg" alt="" /></template>
        <template #title>No products match this search</template>
        <template #info>Try with a different search.</template>
      </empty-state>
      <empty-state class="empty-state-center" v-else>
        <template #badge> <img src="@/assets/no-product-empty-state.svg" alt="" /></template>
        <template #title>No products listed</template>
      </empty-state>
    </template>
    <ez-load-more v-if="!isLoadingMore && meta.nextId" @loadMore="onLoadMore" />
    <ez-loader :show="loading || exportLoading">Loading...</ez-loader>
    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>
    <ez-products-update-modal
      v-if="hasManageDistributorPermission"
      ref="productsUpdateModal"
      :productGroups="productGroups"
      hasNotifyPriceChange
      hasVisibility
      hasPricePerUnit
      :numberOfProducts="bulkActionProductsCount"
      @update="bulkUpdateProducts"
      @addProductGroup="addProductGroup"
      @editProductGroup="editProductGroup"
      @removeProductGroup="removeProductGroup"
    />
    <remove-group-modal ref="removeGroupModal" @remove="onRemoveConfirm" />
    <add-group-modal ref="addGroupModal" @confirm="onAddConfirm" />
    <footer-for-update
      v-if="bulkActionProductsCount"
      entity="Products"
      :hasSecondaryAction="groupViewMode"
      secondaryActionEntity="Visibility"
      :items-count="bulkActionProductsCount"
      @footerAction="editSelectedProducts"
      @footerSecondaryAction="editSelectedProductsVisibility"
    />
    <select-locations-modal
      ref="locationsModal"
      :locations="selectedProductLocations"
      :productId="productId"
      :products-bulk-update="productsBulkUpdate"
      @productsBulkUpdate="bulkUpdateProductsLocations"
      @success="updateProductLocations"
      @close="onProductLocationsClose"
    />
    <ez-confirmation-modal ref="bulkUpdateConfirmation" type="red" icon="question">
      <template #title>Confirm changes</template>
      <template #content>
        <p>Your changes will affect <strong>all outlets</strong>.</p>
      </template>
      <template #footer>
        <ez-button @click="() => $refs.bulkUpdateConfirmation.close()" type="link"
          >Cancel</ez-button
        >
        <ez-button @click="onConfirm" type="red">Confirm</ez-button>
      </template>
    </ez-confirmation-modal>
  </div>
</template>

<style lang="scss" scoped>
:deep() .actions-cell {
  width: 170px;
}

.bulk-actions {
  .button {
    width: 100%;
  }
}

:deep() .products-table {
  tbody tr td {
    overflow: visible;
  }
}

:deep() .product-modal {
  .modal {
    height: 100%;

    &__inner {
      display: flex;
      flex-direction: column;
      max-height: 100%;
    }
  }
}

.product-info {
  display: flex;
  flex-direction: column;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .product-info-secondary {
    &--category {
      color: $color-gray-25;
      font-weight: 600;
    }
  }
}

:deep() .table {
  .visibility-cell {
    width: 100px;
    overflow: visible;

    .button {
      padding-left: 0;
    }
    .location-edit {
      display: none;
    }

    &:hover {
      cursor: pointer;
      .location-edit {
        display: inline-block;
      }
      .location-badge {
        display: none;
      }
    }
  }

  .product-group-cell {
    overflow: visible;

    .select-search__wrapper {
      .select-search__value {
        color: $color-gray-6C;
      }
      .select-search__trigger {
        display: inline-block;
        font-size: 11px;
        font-size: 0.6875rem;
        max-width: 100%;
        padding: 0 6px;
        color: #6c7995;
        font-weight: 500;
        line-height: 1.2;
        border: 1px solid #eceef5;
        background-color: #eceef5;
        border-radius: 2px;
        text-transform: uppercase;
        overflow-x: hidden;
        text-overflow: ellipsis;
        height: auto;
        min-width: auto;
        width: auto;
      }

      &.focused {
        border: 1px solid #4d7cfe;
        background-color: #fff;
      }

      span {
        margin-right: 6px;
      }

      .select-search__dropdown {
        width: 320px;
      }
    }

    .has-group .select-search__wrapper .select-search__trigger {
      background-color: #fff;
    }
  }
}
:deep() table.table.table--remove-button tbody tr td {
  line-height: 1;
}
.all-products__actions {
  display: flex;

  :deep() .dropdown-filters {
    .input-group {
      margin: 0;
    }

    .filters-area__row:not(:first-child) {
      justify-content: start;
    }
  }

  :deep() .ez-filter-list {
    &__items {
      margin: 0;
    }
  }

  .ez-button-group {
    margin-left: auto;
  }
}

:deep() .button--blue-link {
  background-color: #fff;
}
:deep() .empty-state-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.status-checkbox {
  @include font-size(14px, 20px);
}

.select-all-checkbox {
  .input-group {
    @include font-size(12px);
  }
}
</style>
