<script>
import { wizardListenerMixin } from '@/mixins/wizard';
import DistributorForm from '@/views/admin/distributors/Form';
import { mapActions, mapMutations, mapState } from 'vuex';
import Distributor from '@/models/Distributor';
import { LOADING_KEY } from '@/util/constants';

/**
 * NewSupplierInfo
 * @version 1.0.0
 * @since 2.3.0
 */
export default {
  mixins: [wizardListenerMixin],
  components: {
    DistributorForm,
  },
  props: {
    prefilledName: {
      type: String,
    },
    venueId: {
      type: String,
    },
  },
  data() {
    return {
      formKey: 'new-distributor',
      loadingKey: LOADING_KEY.VENUE_CREATE_SUPPLIER,
    };
  },
  computed: {
    ...mapState('entities/users', [
      'context',
      'contextId',
    ]),
    distributorId() {
      return this.draftSupplier.id;
    },
    isNewDistributor() {
      return this.distributorId === -1;
    },
    method() {
      return this.isNewDistributor ? 'post' : 'patch';
    },
    action() {
      return this.isNewDistributor ? this.createAction : this.updateAction;
    },
    createAction() { return '/venue/distributors'; },
    updateAction() { return `/venue/distributors/${this.distributorId}`; },

    ...mapState('entities/distributors', [
      'draftSupplier',
    ]),
  },
  methods: {
    ...mapActions('entities/distributors', ['venueAddSupplier']),
    onSubmitSuccess({ data }) {
      Distributor.insertOrUpdate({ data: data.data });
      // Cache value because it will change after the UPDATE_DRAFT_SUPPLIER
      const isNew = this.isNewDistributor;

      this.UPDATE_DRAFT_SUPPLIER(data.data);
      if (isNew) this.connectVenueAndDistributor();

      this.$emit('stepCompleted');
    },
    async connectVenueAndDistributor() {
      await this.venueAddSupplier({
        id: this.contextId,
        distributorId: this.distributorId,
      });
    },
    onNextStep() {
      this.$refs.form.submitForm();
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    formValid(valid) {
      if (valid) this.enableNextStep();
      else this.disableNextStep();
    },
    ...mapMutations('entities/distributors', [
      'UPDATE_DRAFT_SUPPLIER',
    ]),
  },
};
</script>
<template>
  <distributor-form
    ref="form"
    :formKey="formKey"
    :loadingKey="loadingKey"
    :action="action"
    :avatarColumnMode="true"
    :isSupplier="true"
    submitType="multipart"
    :distributorId="distributorId"
    :method="method"
    :prefilledName="prefilledName"
    @submitSuccess="onSubmitSuccess"
    @formValid="formValid"
    class="distributor-info"
    hide-currency-section
    hide-notification-settings
    hide-dormant-notification
  />
</template>
<style lang="scss" scoped></style>
