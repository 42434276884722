<script>
/**
 * Suppliers
 * @version 1.0.0
 * @since 2.3.0
 */

import EzNav from '@/components/ui/Nav';
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    EzNav,
  },
  data() {
    return {
      headerInfo: null,
    };
  },
  computed: {
    ...mapState('entities/users', ['context']),
    ...mapState('entities/distributors', ['distributorCount']),
    canSeeConnectionRequest() {
      return this.context.invitationsCount > 0 && !this.$permission.isFree;
    },
  },
  methods: {
    ...mapActions('entities/users', ['updateVenueContext']),
    updateHeaderInfo(info) {
      this.headerInfo = info;
    },
  },
  mounted() {
    this.updateVenueContext();
  },
};
</script>
<template>
  <div class="venue-suppliers">
    <header>
      <h1 v-if="['venue-all-suppliers-products'].includes($route.name)">Products</h1>
      <h1 v-else>{{ $t('global.distributors') }}</h1>
      <span v-if="!headerInfo">{{ distributorCount }} connected</span>
      <span v-else>{{ headerInfo.productCount }} products</span>
    </header>
    <ez-nav>
      <router-view @updateHeaderInfo="updateHeaderInfo" />
    </ez-nav>
  </div>
</template>
<style lang="scss" scoped>
.venue-suppliers {
  @include platform-tab-content();
  .ez-load-more {
    margin-top: $spacing-16;
  }
}
:deep() .navigation .navigation__nav {
  display: none;
}
</style>
