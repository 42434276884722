<template>
  <transition name="drawer-close">
    <div class="drawer-wrapper" v-if="isShown">
      <div class="drawer__backdrop" @click="close"></div>
      <aside class="drawer">
        <header>
          <div class="drawer__top">
            <h3 class="drawer__title">
              <!-- @slot Used to display the title of the drawer. -->
              <slot name="title"></slot>
            </h3>
            <!-- @slot Used to display the status badge -->
            <slot name="status"></slot>
            <button class="drawer__close-button" @click="close">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16">
                <!-- eslint-disable-next-line max-len -->
                <path d="M7.758 6.309L13.916.15c.2-.2.524-.2.725 0l.724.725c.2.2.2.524 0 .724L9.207 7.758l6.158 6.158c.2.2.2.524 0 .725l-.724.724c-.2.2-.525.2-.725 0L7.758 9.207l-6.159 6.158c-.2.2-.524.2-.724 0l-.725-.724c-.2-.2-.2-.525 0-.725L6.31 7.758.15 1.599c-.2-.2-.2-.524 0-.724L.875.15c.2-.2.524-.2.724 0L7.758 6.31z"/>
              </svg>
            </button>
          </div>
          <div class="drawer__header">
            <!-- @slot Used for the header section. -->
            <slot name="header"></slot>
          </div>
        </header>
        <div class="drawer__content">
          <!-- @slot Used to display content, it is scrollable. -->
          <slot name="info-message"></slot>
          <slot name="content"></slot>
        </div>
        <footer class="drawer__footer" v-if="hasFooter">
          <!-- @slot Used to display the footer, which is stuck at the bottom -->
          <slot name="footer"></slot>
        </footer>
      </aside>
    </div>
  </transition>
</template>

<script>
/**
 * Drawer component will transition from the right side and display additional content.
 * The drawer will be fixed positioned above the content with an overlay.
 * @since 1.0.0
 */
export default {
  data() {
    return {
      isShown: false,
    };
  },
  computed: {
    hasFooter() {
      return !!this.$slots.footer;
    },
  },
  methods: {
    /**
     * Toggle drawer visibility.
     * @public
     */
    toggle() {
      this.isShown = !this.isShown;
    },
    /**
     * Open the drawer.
     * @public
     */
    open() {
      this.isShown = true;
      this.$emit('onOpen');
    },
    /**
     * Close the drawer.
     * @public
     */
    close() {
      this.isShown = false;
      this.$emit('onClose');
    },
  },
  watch: {
    isShown(val) {
      window.Intercom('update', {
        hide_default_launcher: val,
      });
    },
  },
};
</script>

<style scoped lang="scss">
$drawer-overlay-color:            rgba(#121E3D, .7);
$drawer-bg-color:                 #FFFFFF;

$drawer-header-bg-color:          #252631;
$drawer-header-color:             #8790A3;
$drawer-header-font-size:         14px;
$drawer-padding-y:                1.2em;
$drawer-padding-x:                1.7em;

$actions-height:                  4em;

.drawer-wrapper {
  @include font-size(16px);
  @include fixed(0);
  @include z-index('drawer');
  display: flex;
  justify-content: flex-end;
  background-color: $drawer-overlay-color;
}

.drawer-form {
  :deep() &__actions {
    @include font-size(16px);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #FFFFFF;
    box-shadow: inset 0 1px 0 0 #E9ECF0, 0 -2px 10px 0 rgba(35,31,32,0.12);
    text-align: right;
    height: $actions-height;
    padding: 0 1.5em;

    .remove-action {
      margin-right: auto;
    }
    .button--primary {
      flex-basis: 12em;
    }
  }
  .drawer {
    @include font-size(16px);
    position: relative;
    padding-bottom: $actions-height;
  }
}

.drawer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 42.5rem;
  background-color: $drawer-bg-color;
  &__backdrop { flex: 1 1 auto; }
  &__top,
  &__header {
    color: $drawer-header-color;
  }
  &__top {
    @extend %flex-center;
    padding: $drawer-padding-y $drawer-padding-x;
    @include font-size($drawer-header-font-size);
  }
  &__title {
    @extend %flex-center;
    flex: 1 1 auto;
    margin: 0;
    font-size: inherit;
    font-weight: 600;
  }
  &__close-button {
    margin-left: auto;
    cursor: pointer;
    svg {
      fill: $color-gray-6C;
    }
  }
  &__content {
    overflow-y: auto;
    flex: 1 1 auto;
  }
  &__footer {
    padding: .75rem 1.5rem;
    text-align: right;

    :deep() .button + .export-button { margin-left: 1em; }
    :deep() > .button + .button { margin-left: 1em;}
  }
}

.drawer-close-enter-active,
.drawer-close-leave-active {
  transition: transform .3s ease-out, opacity .3s ease-out;

  .drawer {
    transition: transform .3s ease-out;
  }
}
.drawer-close-enter-to, .drawer-close-leave {
  .drawer-wrapper {
    opacity: 1;
  }
  .drawer {
    transform: translate3d(0, 0, 0);
  }
}
.drawer-close-enter, .drawer-close-leave-to {
  .drawer-wrapper {
    opacity: 0;
  }
  .drawer {
    transform: translate3d(100%, 0, 0);
  }
}
</style>
