<script>
import { mapState } from 'vuex';
import TotalIcon from '@/views/common/orders/total-icon';

export default {
  components: {
    TotalIcon,
  },
  data() {
    return {
      totalLabel: '',
      totalAmount: '',
    };
  },
  computed: {
    ...mapState('entities/users', ['context']),
  },
  methods: {
    updateTotal(total) {
      if (total.filters) {
        this.totalLabel = `Total ${
          ['venue-reports-invoices'].includes(this.$route.name) ? 'invoiced' : 'ordered'
        } this month`;
      } else {
        this.totalLabel = 'Total for selected filters';
      }
      this.totalAmount = total.totalAmount;
    },
  },
};
</script>

<template>
  <div>
    <header class="header">
      <h1 v-if="['venue-reports-invoices'].includes($route.name)">Invoice Reports</h1>
      <h1 v-else-if="['venue-reports-orders'].includes($route.name)">Order Reports</h1>
      <h1 v-else>Chartbrew Reports</h1>
      <div
        v-if="['venue-reports-invoices', 'venue-reports-orders'].includes($route.name)"
        class="order-header__group"
      >
        <div class="order-header__stats">
          <div class="order-header__stat order-header__stat--amount">
            <small>{{ totalLabel }}</small>
            <span>{{ totalAmount | price }}</span>
          </div>
          <total-icon icon="money-bill-wave" variant="green" />
        </div>
      </div>
    </header>
    <router-view @updateStats="updateTotal" />
  </div>
</template>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .order-header__group {
    display: flex;
    flex-direction: row-reverse;
    .order-header__stats {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        padding-left: 16px;
        border-left: 1px solid #eceef5;
      }

      &:not(:first-child) {
        padding-right: 16px;
      }

      .order-header__stat {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: flex-end;
        margin-right: 0.75em;

        small {
          @include font-size(12px);
          font-weight: 500;
          letter-spacing: 0.25px;
          color: $color-gray-6C;
        }

        span {
          @include font-size(20px);
          color: $color-gray-25;
          font-weight: 500;
        }
      }
    }
  }
}

h1 {
  @include font-size(24px);
  margin: 0;
  font-weight: 500;
  line-height: 40px;
}

small {
  @include font-size(12px);
  font-weight: 500;
  margin-top: 0.25rem;
  color: $color-gray-6C;
}
:deep() .navigation .navigation__nav {
  display: none;
}
</style>
