<template>
  <!-- eslint-disable max-len -->
  <div :class="[
    'ez-terms-and-conditions',
    'ez-terms-and-conditions--padding-modify',
    {'ez-terms-and-conditions--padding-modify-public': isPublic},
  ]">
    <p>The following Terms and Conditions govern our relationship with our clients</p>

    <ol>
      <li>
        <h2>These Terms and Conditions Are Legally Binding</h2>
        <p>OrderEZ Pte. Ltd. (UEN 201908100C) (the “Company”) is the owner and operator of the www.OrderEZ.co, www.foodrazor.com, and www.foodrazor.co services (“the Service”). Your use of the Service is expressly conditioned on your acceptance of these Terms and Conditions (“Terms and Conditions”). By using the Service, you signify that you unconditionally agree and accept to be legally bound by these Terms and Conditions. No other terms or conditions of any sort in any document, writing or other communication whatsoever made by you to  or its employees, representatives or agents in relation to the Service shall be applicable to or binding on the Company.</p>
      </li>
      <li>
        <h2>Changes To This Agreement Or The Services</h2>
        <p>The Company reserves the right to alter these Terms and Conditions at any time. Such changes will be effective and legally binding on you when posted on our website for the Service or when we use reasonable efforts to notify you by other means. Please review these Terms and Conditions periodically for changes. Your continued use of this Service after amended Terms and Conditions is posted on this website or is otherwise brought to your attention signify that you agree and accept to be legally bound by the changes.</p>
      </li>
      <li>
        <h2>Grants of Licenses to Use</h2>
        <ul class="alpha-enumeration">
          <li>
            <p>The Company hereby grants to you a limited, non-exclusive, non-transferable right to use the Service provided that you agree to the following:</p>
          <ul>
            <li>
              <p>The Company may, at its sole and absolute discretion, without prior notice to you and without liability, reject, prevent, discontinue or suspend your account(s) and/or your use of the Service if, at its sole discretion, the Company is of the opinion that your use does not meet the Company’s specifications or guidelines, or which the Company may consider to be inappropriate, illegal or harmful to its interests. If the Company exercises its absolute discretion under this paragraph 3.1(a), it shall have no liability to refund any portion of your pre-paid subscriptions, if applicable. Without limiting or waiving any right against you that the Company may have under the law or these Terms and Conditions in relation to any inappropriate, illegal or harmful use, the Company reserves the right to deduct any costs, damages or expenses that it may have incurred in relation to such inappropriate, illegal or harmful use from your unused pre-paid subscriptions, if any, or to claim the same from you by any other means.</p>
            </li>
            <li>
              <p>You agree to comply with any policies or guidelines that the Company may introduce or amend from time to time. the Company’s policies or guidelines may relate to technical specifications, privacy, acceptable content, and any other matter, as the same may be amended by the Company, at its absolute discretion, from time to time.</p>
            </li>
            <li>
              <p>You agree that you will not provide access to or furnish any part of the Service to any third party; modify, disrupt or interfere with the Service, supporting servers, or networks either manually or through the use of scripts, viruses, or worms; reproduce, duplicate, copy, deconstruct, sell, trade or resell the Service; or excessively overload the systems that the Company uses to provide the Service.</p>
            </li>
            <li>
              <p>The Company may, at its sole and absolute discretion and without prior notice to you, amend, suspend, vary or withdraw the Service, these Terms and Conditions and/or its website at any time and from time to time.</p>
            </li>
            <li>
              <p>You shall be solely responsible for your use of the Service and shall indemnify the Company for, and hold the Company harmless from, all loss, costs, and damages incurred by the Company in connection with any claims by third parties against the Company in relation to your use of the Service and in connection with your breach of these Terms and Conditions or any policies or guidelines that the Company may introduce from time to time.</p>
            </li>
            <li>
              <p>You hereby represent, warrant and covenant to the Company that at all times, (a) you have all rights, consents, licences and permits that may be necessary or desirable for you to have in order to use the materials, graphics, logos, videos, data or information you input into the Service (where applicable) (“Your Content”) in the manner and the purpose for which you use Your Content; (b) all of Your Content comply with all applicable laws and regulations and do not violate the rights (including, but not limited to, intellectual property rights) of any third party.</p>
            </li>
            <li>
              <p>You represent and warrant to the Company that you have the full corporate rights, power and authority to agree to these Terms and Conditions and perform the obligations agreed by you, and that doing so does not and will not violate any agreement or obligation to which you are bound, or any applicable law, rule or regulation.</p>
            </li>
            <li>
              <p>You hereby agree that the Company may, but is not obliged to, use your name and refer to you as a user of the Service in any publicity, press release, advertising or marketing material, throughout the world and using any form of media. If you wish to use or display the Company’s trademarks or mention the Company in any publication, website, press release or in any other manner, you must obtain the prior written consent of the Company.</p>
            </li>
            <li>
              <p>You agree that you are solely responsible to obtain or provide any hardware or software that you may need to gain access to and use the Service, and for any damage or loss that you suffer as a result of the operation of such hardware or software, including incompatibility with any platforms used by the Company in providing the Service.</p>
            </li>
            <li>
              <p>You agree that the Company is the sole owner of and has the right to use any feedback you provide on the Service in any way whatsoever including to improve existing or create new products and services.</p>
            </li>
          </ul>
          </li>
          <li>
            <p>The Company reserves all rights in the Service not granted to you expressly in these Terms and Conditions.</p>
          </li>
          <li>
            <p>You hereby agree to grant the Company a worldwide, royalty-free, non-exclusive license to publish, alter, adapt, compile, host and use (a) Your Content (including, without limitation, to reproduce in a material form, publish, perform, communicate to the public, and to make an adaptation of Your Content) for the purpose of providing you with the Service, and, (b) if the Company provides any community forum for users of the Service to exchange user tips and publish their comments and opinions, any comments, opinions and other material you upload or publish on such a forum.</p>
          </li>
        </ul>
      </li>
      <li>
        <h2>Payment Terms</h2>
        <ul class="alpha-enumeration">
          <li>
            <p>By creating an account and subscribing for the Service or renewing your subscription for the Service, you indicate you agree to the charges imposed by:</p>
          </li>
          <li>
            <p>The Company for the particular services or products you have requested. You agree to comply with any payment terms that the Company may impose from time to time. You may be required to pay the Company in advance and/or an annual, half yearly, quarterly or monthly basis to use the Service. Your subscription may be renewed automatically on or close to its expiry at the subscription rates applicable at the time of renewal. However, even if a facility to renew your subscription automatically exists, it is solely your responsibility, and not that of the Company, to ensure that your subscription is renewed prior to its expiry, failing which your ability to use the Service shall expire automatically. The Company may not give you prior notice of an automatic renewal. If the Company is unable to effect this automatic renewal for any reason whatsoever, your subscription will expire automatically. For subscribers choosing to pay by credit card, subscription fees for renewal may be charged to the credit card on record unless the Company is notified of a change or cancellation in writing using the tools or means made available by the Company.</p>
          </li>
          <li>
            <p>All sums payable by you to the Company may be quoted exclusive of any goods and services tax, or any other taxes that may be levied on the subscription. As such, you agree that The Company may charge you and/or the credit card on record for any taxes or charges that The Company is required by any applicable law or regulation to levy on the subscription or charges.</p>
          </li>
        </ul>
      </li>
      <li>
        <h2>Term and Termination</h2>
        <ul class="alpha-enumeration">
          <li>
            <p>The Company shall have the right to terminate its relationship with you, without prior notice, if you breach or fail to comply with any provision of these Terms and Conditions. This Agreement and your ability to use the Service shall also automatically terminate upon the expiry of your subscription.</p>
          </li>
          <li>
            <p>After termination, the Company may, at its sole discretion, maintain any data you have uploaded into its systems. However, you agree that other than any obligations expressly stated in these terms and Conditions, the Company has no responsibility to you to maintain your user account or any of your data after termination and you shall hold the Company harmless and free from any fees, damages, or claims you may suffer in relation to the same or any claims or actions you may have or face in relation to the same.</p>
          </li>
          <li>
            <p>If termination is due to your failure to renew your subscription on a timely basis, you may reactive your account and access your data if renewal under such circumstances are enabled by the Company. To do so you may have to pay any subscription fees or charges that may be imposed by the Company, including reactivation fees (if any), within any period that may be stated by the Company, failing which the Company may permanently remove and delete your user account(s) and any data you have uploaded into its systems.</p>
          </li>
        </ul>
      </li>
      <li>
        <div>
        <h2>Your Data, the Company’s Data, the Storage and Transmission of Your Data and the the Company Privacy Policy</h2>
        </div>
        <ul class="alpha-enumeration">
          <li>
            <p>You or your customers, partners or associates, whichever may be applicable, are the owner of any data you upload into the Service (“Your Data”). You hereby agree that the Company is the owner of all data other than Your Data, including any system generated data generated by the Service or any data compiled from data inputed into the Service by all users of the Service on an aggregate basis (“The Company’s Data”). The Company may use all of the Company’s Data in any way it chooses including to improve or adapt its services, or to create or design new products and services.</p>
          </li>
          <li>
            <p>You acknowledge and agree that the nature of the Internet is international and that the Company has your express consent to store and provide access to your personal or confidential information, and that of your users(s) and customers, in and from Singapore, and to transmit and deliver such information via the Internet which may involve its transmission across multiple jurisdictions.</p>
          </li>
          <li>
            <p>The Company Privacy Policy, as may be amended or updated from time to time, is incorporated into and form part of these Terms and Conditions.</p>
          </li>
        </ul>
      </li>
      <li>
        <h2>Your account(s) and Password(s)</h2>
        <ul class="alpha-enumeration">
          <li>
            <p>The responsibility to keep secure and confidential any password(s) and user ID(s) the Company may issue to you to access the Service, rests solely with you and the employees, representatives and agents of yours that are entrusted with the same. If you become aware of any unauthorized access to your account(s), or any misuse of your password(s) and user ID(s) you must activate the process put in place to disable your account(s) and/or re-issue new password(s) or user ID(s) as soon as possible.</p>
          </li>
          <li>
            <p>You agree that you are solely responsible for the actions and omissions of the person(s) you nominate as user(s) or administrator(s) of your account(s) for the Service. You also agree that The Company may accept instructions and requests from and communicate with such person(s) until and unless The Company receives notification that such person(s) are no longer your user(s) or administrator(s), and such instructions, requests and communications are valid and legally binding on you. In addition, you are responsible for any and all use of your account(s) by any persons who are in possession of your user ID(s) or password(s).</p>
          </li>
        </ul>
      </li>
      <li>
        <h2>Your indemnity in Favour Of the Company</h2>
        <p>If the Company and/or any of their affiliates (collectively the “Indemnified Parties”) take any action to enforce any of the Terms and Conditions, you agree to indemnify and hold harmless the Indemnified, their affiliates, and their respective officers, directors, employees and agents from and against any and all claims, losses, costs, expenses, causes of action or demands, including reasonable legal and accounting fees, arising out of and relating to your use of the Service, and any and all breaches by you of these Terms and Conditions.</p>
      </li>
      <li>
        <h2>No Warranties</h2>
        <p>UNLESS EXPRESSLY PROVIDED HEREIN, TO THE FULLEST EXTENT PERMITTED BY LAW, THE COMPANY MAKES NO WARRANTY OR REPRESENTATION OF ANY KIND REGARDING ITS WEBSITE, THE SERVICE, THE PRODUCTS OR SERVICES AVAILABLE ON THIS WEBSITE AND/OR ANY MATERIALS PROVIDED ON THIS WEBSITE, ALL OF WHICH ARE PROVIDED ON AN “AS IS WHERE IS” BASIS. THE COMPANY DOES NOT WARRANT THE ACCURACY, COMPLETENESS, CURRENCY OR RELIABILITY OF ANY OF THE CONTENT OR DATA FOUND ON THE SERVICE, OR THIS WEBSITE. THE COMPANY EXPRESSLY DISCLAIMS ALL WARRANTIES, AND TERMS AND CONDITIONS IN RELATION TO THE SERVICE, INCLUDING ALL IMPLIED WARRANTIES AS TO MERCHANTABILITY, SATISFACTORY QUALITY, FITNESS FOR A GENERAL OR PARTICULAR PURPOSE AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS, AND THOSE ARISING BY STATUTE OR OTHERWISE IN LAW OR FROM A COURSE OF DEALING OR USAGE OF TRADE TO THE FULLEST EXTENT PERMITTED BY THE LAWS OF THE REPUBLIC OF SINGAPORE.
          THE COMPANY DOES NOT WARRANT THAT THE SERVICE, THIS WEBSITE, ITS SERVERS OR ANY E-MAIL SENT FROM THE COMPANY IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
          FOR THE AVOIDANCE OF DOUBT, THE COMPANY DOES NOT GUARANTEE THE EFFECTIVENESS OF THE SERVICE.
          THE COMPANY TAKES NO RESPONSIBILITY FOR THE SECURITY, CONFIDENTIALITY OR PRIVACY OF THE COMMUNICATIONS AND/OR DATA TRANSMITTED OVER THE INTERNET AND DOES NOT WARRANT (AND EXPRESSLY EXCLUDES ANY AND ALL EXPRESS OR IMPLIED WARRANTIES) THAT THE SERVICE WILL BE WITHOUT FAILURE, DELAY, INTERRUPTION, ERROR OR LOSS OF CONTENT, DATA OR INFORMATION. IN ADDITION, THE COMPANY SHALL NOT BE LIABLE FOR ANY COMPATIBILITY ISSUES PERTAINING TO CUSTOMERS’ COMPUTERS, APPLICATIONS OR OTHER SOFTWARE ON ANY COMPUTERS USING THE SERVICE.</p>
      </li>
      <li>
        <h2>Limitation of Liability</h2>
        <p>TO THE FULLEST EXTENT PERMITTED BY LAW IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY INJURY, LOSS, CLAIM, DAMAGE, OR ANY SPECIAL, EXEMPLARY, PUNITIVE, INDIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND OR FOR ANY LOST PROFITS OR LOST SAVINGS, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE),
          EQUITY, STRICT LIABILITY, STATUTE OR OTHERWISE, WHICH ARISES OUT OF OR IS IN ANY WAY CONTENT FOUND HEREIN, (II) ANY FAILURE OR DELAY (INCLUDING, BUT NOT LIMITED TO THE USE OF OR INABILITY TO USE ANY COMPONENT OF THE SERVICE OR THIS SITE), OR (III) THE PERFORMANCE OR NON PERFORMANCE BY THE COMPANY EVEN IF THE THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES TO SUCH PARTIES OR ANY OTHER PARTY.</p>
        <p>If, notwithstanding the foregoing, the Company should be found liable for any loss or damage which arises out of or is in any way connected with any of the above described functions or uses of this site, the Service or its content, to the full extent permitted by law the combined aggregate liability of the Company shall in no event exceed the value of the monthly subscription for the Service paid by you or S$1,000.00, whichever is lower. Further, the Company’s liability to you in contract, tort, negligence, strict liability, under statute or otherwise will be reduced to the extent, if any, to which you contributed to the loss or damage.</p>
      </li>
      <li>
        <h2>General Provisions</h2>
        <p>These Terms and Conditions are governed by the laws of the Republic of Singapore. The application of the United Nations Convention on Contracts for the International Sale of Goods is hereby expressly excluded. The parties agree to submit to the exclusive jurisdiction of the courts of the Republic of Singapore. You may not assign your rights hereunder to any third party without the prior written consent of The Company. No joint venture, partnership, employment, or agency relationship exists between you and the Company. A person who is not a party to this Agreement shall have no rights under the Contracts (Rights of Third Parties) Act (Cap. 53B) of Singapore to enforce any term of this Agreement. If any provision of these Terms and Conditions is found invalid or unenforceable by any Singapore court, the remaining provisions will remain valid and enforceable, and the unenforceable provisions will be deemed modified to the extent necessary to make them enforceable. These Terms and Conditions constitute the entire understanding and agreement between the Company and you and supersedes any and all prior understandings and/or agreements between the parties in relation to the Service. No purported waiver by the Company of any of its rights hereunder or under any applicable law or regulation shall be valid unless made in writing by an authorised representative of the Company. No change, amendment or modification of any provision of these Terms and Conditions will be valid unless set forth in writing and mutually agreed to by the parties.</p>
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
};
</script>


<style scoped lang="scss">
  $ul-alpha-indentation : px-to-rem(26px);
  $ul-roman-indentation: 1.5rem;

  @mixin counter-enumeration($counter, $counter-type, $ul-indentation)
  {
    list-style-position: outside;
    list-style-type: none;
    > li {
      counter-increment: $counter;
      position: relative;
      &:before {
        @include absolute(top 0);
        left: -$ul-indentation;
        content: counter($counter, $counter-type);
        line-height: 1.6;
        color: #8790A3;
      }

      &first-child {
        counter-reset: $counter;
      }
    }
  }

  .ez-terms-and-conditions {
    @extend %paper;
    &--padding-modify {
      padding: 16px 0 40px;
    }
    &--padding-modify-public {
      padding: 16px 16px 40px 0;
    }
    &--public {
      margin-bottom: 2.5em;
    }
    ol {
      margin-top: 2.5em;
      padding: 0 16px;
      list-style-position: outside;

      li {
        @include font-size(18px);
      }


      > li {
        padding-left: .5rem;
        margin-bottom: 2rem;
        > ul {
          padding-inline-start: 0;
        }
        > p {
          margin-left: -1.5rem;
        }
      }

      ul {
        padding-inline-start: $ul-alpha-indentation;
        &.alpha-enumeration {
          @include counter-enumeration(alphacounter, upper-alpha, $ul-alpha-indentation);
          li {
            @include font-size(14px);
          }

          ul {
            @include counter-enumeration(romancounter, upper-roman, $ul-roman-indentation);
            > li {
              &:before {
                transform: translateX(-100%);
              }
            }
          }
        }
      }
    }
  }
</style>
