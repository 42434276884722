<template>
  <div :class="['total-icon', `total-icon--${variant}`, { 'total-icon--biggerIcon': biggerIcon }]">
    <div class="total-icon__inner">
      <font-awesome-icon :icon="icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TotalAmountIcon',
  props: {
    biggerIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: 'money-bill-wave',
    },
    variant: {
      type: String,
      required: false,
      default: 'green',
      validator: v => ['yellow', 'green', 'purple', 'blue'].includes(v),
    },
  },
};
</script>

<style scoped lang="scss">
$yellow-inner-color: #FEF6DA;
$yellow-background-color: #EF9B13;

$green-inner-color: rgba(#1ba975, 0.24);
$green-background-color: #1ba975;

$purple-inner-color: #eedff4;
$purple-background-color: #983bbd;

$blue-background-color: #4D7CFE;
$blue-inner-color: #E2EAFF;

.total-icon {
  @extend %flex-center;
  justify-content: center;
  color: $color-white;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;

  &__inner {
    @include font-size(11px);
    @extend %flex-center;
    justify-content: center;
    border-radius: 50%;
    width: 1.875rem;
    height: 1.875rem;
  }

  &--biggerIcon {
    .total-icon__inner {
      @include font-size(15px);
    }
  }

  &--yellow {
    background-color: $yellow-inner-color;
    .total-icon__inner {
      background-color: $yellow-background-color;
    }
  }

  &--green {
    background-color: $green-inner-color;
    .total-icon__inner {
      background-color: $green-background-color;
    }
  }

  &--purple {
    background-color: $purple-inner-color;
    .total-icon__inner {
      background-color: $purple-background-color;
    }
  }

  &--blue {
    background-color: $blue-inner-color;
    .total-icon__inner {
      background-color: $blue-background-color;
    }
  }
}
</style>
