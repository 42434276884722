<template>
  <page>
    <template #breadcrumbs>
      <router-link :to="{ name: 'platform-home' }">
        <font-awesome-icon icon="arrow-left"/>
        Back to Home
      </router-link>
    </template>
    <template #title>
      <h1>My Settings</h1>
    </template>
    <ez-form
      :formKey="formKey"
      :action="changeMeAction"
      @success="onEditSuccess"
      method="patch">
      <fieldset>
        <legend>Main info</legend>
        <ez-input
          :formKey="formKey"
          :value="loggedUser.name"
          name="name"
          placeholder="Enter User Name"
          label="Name"></ez-input>
        <ez-input
          :formKey="formKey"
          :value="loggedUser.email"
          name="email"
          label="Email Address"
          class="email-field"
          disabled>
            <template #suffix>
              <ez-button
                type="link"
                formType="button"
                @click="openChangeEmailModal">Change</ez-button>
            </template>
        </ez-input>
      </fieldset>
      <div class="separator"></div>
      <fieldset>
        <legend>Password</legend>
        <ez-button
          formType="button"
          type="secondary"
          @click="openChangePasswordModal">Change Password</ez-button>
        <div class="form-help">
          <font-awesome-icon icon="info-circle"/>
          <p>Note: It's a good idea to use a strong password that you're not using elsewhere.</p>
        </div>
      </fieldset>

      <ez-button form-type="submit">Save Changes</ez-button>
    </ez-form>
    <ez-change-email
      ref="changeEmail"
      :formAction="changeEmailAction"
    />
    <ez-change-password
      ref="changePassword"
      :formAction="changePasswordAction"
    />
  </page>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import EzButton from '@/components/ui/Button';
import EzForm from '@/components/ui/Form';
import EzInput from '@/components/ui/Input';
import Page from '@/views/admin/SinglePage.vue';
import { EzChangeEmail, EzChangePassword } from '@/views/platform/user-settings';
import flash from '@/components/ui/FlashMessage';
import { LOADING_KEY } from '@/util/constants';

export default {
  components: {
    EzButton,
    EzForm,
    EzInput,
    Page,
    EzChangeEmail,
    EzChangePassword,
  },
  data() {
    return {
      formKey: 'user-settings',
      loadingKey: LOADING_KEY.VENUE_USER_SAVE_SETTINGS,
      changeMeAction: '/me',
      changeEmailAction: '/me/email',
      changePasswordAction: '/me/password',
    };
  },
  computed: {
    ...mapState('entities/users', [
      'loggedUser',
    ]),
  },
  methods: {
    ...mapMutations('entities/users', [
      'SET_LOGGED_USER',
    ]),
    openChangeEmailModal() {
      this.$refs.changeEmail.open();
    },
    openChangePasswordModal() {
      this.$refs.changePassword.open();
    },
    onEditSuccess(response) {
      const { data: { data } } = response;
      this.SET_LOGGED_USER(data);
      localStorage.setItem('loggedUser', JSON.stringify(data));
      flash.success({
        title: this.$t('user.settings.flashMessages.success'),
      });
    },
  },
};
</script>

<style scoped lang="scss">
$help-color: #B9BFCD;

.admin-single-page {
  h1 {
    @include font-size(24px, 32px);
    font-weight: 500;
    margin: 0 0 .5rem 0;
    letter-spacing: -1px;
  }
  .ez-form {
    max-width: 26rem;
    :deep() .input-group {
      + .input-group {
        margin-top: .75rem;
      }
      &.disabled {
        .input-group__input {
          background-color: $color-white;
        }
      }
    }
    fieldset {
      border: 0;
      padding: 0;
      legend {
        @include font-size(14px, 16px);
        color: $color-gray-25;
        font-weight: 400;
        padding: 0;
        margin-bottom: 1rem;
      }
    }
    .separator {
      @include separator();
    }
    .form-help {
      display: flex;
      margin-top: 1rem;
      p {
        color: $color-gray-6C;
        margin: 0 0 0 .375rem;
      }
      svg {
        margin-top: 4px;
        color: $help-color;
      }
    }
    .button.button--primary {
      margin-top: 1.5rem;
    }
    .email-field {
      :deep() .input-group {
        &__suffix {
          border: 0;
        }
        &__input--has-suffix input {
          padding-right: 5rem;
        }
      }
      .button {
        @include font-size(12px, 14px);
        font-weight: 400;
      }
    }
  }
}
</style>
