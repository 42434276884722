<script>
/**
 * Status Badge
 * @version 1.0.0
 * @since
 */

export default {
  props: {
    type: {
      type: String,
      required: false,
    },
  },
};
</script>
<template>
  <span :class="['status', type]"><slot/></span>
</template>
<style lang="scss" scoped>
.status {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 2px;
  @include font-size(11px);
  font-weight: 600;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;

  &.orange {
    background-color: rgba($color-primary-orange, .16);
    color: $color-primary-orange;
  }
  &.purple {
    background-color: rgba($color-primary-purple, .16);
    color: $color-primary-purple;
  }
  &.green {
    background-color: rgba($color-primary-green, .16);
    color: $color-primary-green;
  }
  &.red {
    background-color: rgba($color-primary-red, .16);
    color: $color-primary-red;
  }
  &.gray {
    background-color: rgba($color-gray-6C, .16);
    color: #6C7995;
  }
  &.blue {
    background-color: rgba($color-pastel-blue, 1);
    color: $color-primary-blue;
  }
}
</style>
