<script>
import EzNav from '@/components/ui/Nav/EzNav';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'Index',
  components: {
    EzNav,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    role() {
      // role can be 'venue' or 'distributor', based on that we create
      // T&C and Privacy Policy route name
      return localStorage.getItem('role');
    },
    termsAndConditions() {
      if (this.isPublic) {
        return 'terms-and-conditions';
      }
      return `${this.role}-terms-and-conditions`;
    },
    privacyPolicy() {
      if (this.isPublic) {
        return 'privacy-policy';
      }
      return `${this.role}-privacy-policy`;
    },
  },
};
</script>

<template>
  <div class="legal">
    <header :class="{
      'mt-24': isPublic,
      'ml-16': isPublic,
    }">
      <h1>Legal</h1>
    </header>
    <ez-nav :class="[
      'mt-12',
      {'ml-16': isPublic}
    ]">
      <template #nav>
        <ul>
          <li>
            <router-link :to="{ name: termsAndConditions }">
              Terms & Conditions
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: privacyPolicy }">
              Privacy Policy
            </router-link>
          </li>
        </ul>
      </template>
      <router-view />
    </ez-nav>
  </div>
</template>

<style lang="scss" scoped>
.legal {
  max-width: 768px;
  margin: 0 auto;
  @include platform-tab-content();
}
</style>
