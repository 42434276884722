<template>
  <div class="height-100">
    <tab-layout :class="{ 'desktop-screen': !isFreemium }" :disabled="isTrialExpired">
      <template #banner>
        <ez-alert v-if="banner" customClass="venue-banner">
          <component :is="'style'">{{ css }}</component>
          <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
          <div class="venue-banner__message">{{ banner.text }}</div>
          <template #cta v-if="banner.cta">
            <ez-button type="link" @click="onBannerCTAClick">
              {{ banner.cta }}
            </ez-button>
            <router-link
              v-if="isTrialExpired"
              :to="{ name: 'manage-accounts' }"
              class="banner__link ml-8"
            >
              Switch to a different account
            </router-link>
            <ez-button
              v-if="isTrialExpired"
              :data-cy="commonCy.ACCOUNT_SETTINGS.BUTTON__SIGN_OUT"
              type="link"
              formType="button"
              class="banner__link sign-out"
              @click="onLogoutClick"
            >
              Sign Out
              <font-awesome-icon icon="sign-out-alt" />
            </ez-button>
          </template>
        </ez-alert>
      </template>

      <template #nav>
        <ul class="nav">
          <li v-if="$permission.has('seeOverview') && !$permission.isFree">
            <router-link
              :data-cy="outletCy.MAIN_NAVIGATION.LINK__PRODUCTS"
              :to="{ name: 'venue-home' }"
            >
              Overview
            </router-link>
          </li>
          <li v-if="!groupViewMode">
            <div class="sub-menu">
              <router-link
                :data-cy="outletCy.MAIN_NAVIGATION.LINK__ORDERS"
                :to="{ name: 'venue-orders' }"
              >
                Orders
              </router-link>
            </div>
            <ul class="sub-menu__dropdown">
              <li>
                <router-link
                  :to="{ name: 'venue-orders-history' }"
                  :data-cy="outletCy.ORDERS.GENERAL.LINK__ORDER_HISTORY"
                >
                  History
                </router-link>
                <ez-badge type="purple" :count="context.actionsCount || 0" />
              </li>
              <li v-if="$permission.has('createOrder') && $permission.has('seeProducts')">
                <router-link :to="{ name: 'venue-orders-products' }"> Ordering </router-link>
              </li>
              <li v-if="$permission.has('seeFutureOrders')">
                <router-link
                  :to="{ name: 'venue-orders-standing' }"
                  :data-cy="outletCy.ORDERS.GENERAL.LINK__STANDING_ORDERS"
                >
                  Automated Orders
                </router-link>
              </li>
            </ul>
          </li>
          <li v-if="$permission.has('seePendingInvoices') && !$permission.isFree">
            <router-link
              :to="{ name: 'venue-pending-updates' }"
              :data-cy="outletCy.ORDERS.GENERAL.LINK__PENDING_UPDATES"
            >
              Invoices
            </router-link>
            <ez-badge type="error-red" :count="pendingInvoicesCount || 0" />
          </li>
          <li v-if="$permission.has('seeSuppliers')">
            <template v-if="$permission.isLite || $permission.isPremium">
              <div class="sub-menu">
                <router-link
                  :data-cy="outletCy.MAIN_NAVIGATION.LINK__SUPPLIERS"
                  :to="{ name: 'venue-suppliers' }"
                >
                  Catalog
                </router-link>
                <ez-badge :count="supplierNotificationNumber" type="yellow" />
              </div>
              <ul class="sub-menu__dropdown">
                <li>
                  <router-link :to="{ name: 'venue-all-suppliers-products' }">
                    Products
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'venue-all-suppliers' }">
                    {{ $t('global.distributors') }}
                  </router-link>
                </li>
                <li v-if="canSeeConnectionRequest">
                  <router-link :to="{ name: 'venue-connection-requests' }">
                    {{ $t('distributors.connectionRequestsTab') }}
                  </router-link>
                  <EzBadge :count="context.invitationsCount || 0" type="yellow" />
                </li>
              </ul>
            </template>
            <template v-else>
              <router-link
                :data-cy="outletCy.MAIN_NAVIGATION.LINK__SUPPLIERS"
                :to="{ name: 'venue-all-suppliers-freemium' }"
              >
                {{ $t('global.distributors') }}
              </router-link>
              <status-badge class="ml-4" status="premium" />
            </template>
          </li>
          <li v-if="isInventoryEnabled && hasManageInventoryAndConductStocktake">
            <div class="sub-menu">
              <router-link :to="{ name: 'venue-stock' }">Inventory</router-link>
            </div>
            <ul class="sub-menu__dropdown">
              <li>
                <router-link
                  v-if="$permission.has('manageInventory')"
                  :to="{ name: 'venue-stock-all' }"
                >
                  Products
                </router-link>
              </li>
              <li :class="{ 'ml-0': !$permission.has('manageInventory') }">
                <router-link
                  v-if="$permission.has('conductStocktake')"
                  :to="{ name: 'venue-stock-stocktake' }"
                >
                  Counts
                </router-link>
              </li>
            </ul>
          </li>
          <li v-else-if="isInventoryEnabled && hasManageInventory">
            <router-link :to="{ name: 'venue-stock-all' }">Inventory</router-link>
          </li>
          <li v-else-if="isInventoryEnabled && hasConductStocktake">
            <router-link :to="{ name: 'venue-stock-stocktake' }">Inventory</router-link>
          </li>
          <li v-if="!$permission.isFree && $permission.has('seeReports')">
            <div class="sub-menu">
              <router-link :to="{ name: 'venue-reports' }">Reports</router-link>
            </div>
            <ul class="sub-menu__dropdown">
              <li>
                <router-link :to="{ name: 'venue-reports-invoices' }">Invoice Reports </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'venue-reports-orders' }">Order Reports </router-link>
              </li>
              <li v-if="context.chartbrewUrl">
                <router-link :to="{ name: 'venue-reports-chartbrew' }">
                  Chartbrew Reports
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </template>
      <template #cart>
        <button
          :data-cy="outletCy.MAIN_NAVIGATION.BUTTON__CART"
          v-if="$permission.has('createOrder')"
          class="cart"
          @click="openCart"
        >
          <font-awesome-icon icon="shopping-cart" />
          <ez-badge type="blue" :count="itemsCount" />
        </button>
      </template>
      <template #accountMenu>
        <account-menu />
      </template>
      <router-view :key="contextId"></router-view>
    </tab-layout>
    <div
      :class="{
        'mobile-screen': !isFreemium,
        'mobile-screen__premium': !isTrial,
      }"
    >
      <div v-if="isTrial">
        <img
          class="mb-32 mobile-screen__logo"
          v-if="oezPlatformName === 'Foodrazor'"
          src="@/assets/Foodrazor-logo.svg"
          alt="Foodrazor logo"
        />
        <img v-else src="@/assets/logo-dark.svg" alt="EZ logo" class="mb-32 mobile-screen__logo" />
        <p class="mt-0 mb-12 mobile-screen__title">Get your trial <span>started</span></p>
        <div class="mb-16 mobile-screen__subtitle">
          <img src="@/assets/number-1.svg" alt="Number 1" />
          <p class="ml-12">Invoices</p>
        </div>
        <img src="@/assets/invoices.png" alt="Stack of invoices" class="mb-12" />
        <p class="mobile-screen__desc mb-0">
          Upload some invoices to get started on your free trial
        </p>
        <ez-form
          ref="uploadInvoiceForm"
          method="post"
          submit-type="multipart"
          form-key="invoiceUploadTrial"
          @success="confirmSuccess"
          :action="formAction"
          :additional-data="imageData"
          :additional-headers="{ loadingKey: LOADING_KEY.LOG_OFFLINE_ORDER }"
        >
          <ez-multiple-image-upload
            ref="imageUpload"
            name="invoices"
            accept="image/jpeg,image/png,application/pdf"
            class="upload-component"
            form-key="invoiceUploadTrial"
            card-type
            @change="imagesUploaded"
            :number-of-files="20"
            :data-cy="outletCy.ORDERS.GENERAL.NEW_OFFLINE_ORDER.LABEL__MODAL_UPLOAD_INVOICE"
          >
            <template #cta>Upload</template>
          </ez-multiple-image-upload>
          <input type="hidden" name="type" value="invoice" />
          <ez-loader :show="isUploadLoading">Loading...</ez-loader>
        </ez-form>
        <hr />
        <div class="mb-16 mobile-screen__subtitle">
          <img src="@/assets/number-2.svg" alt="Number 2" />
          <p class="ml-12">Desktop App</p>
        </div>
        <img
          src="@/assets/desktop.png"
          alt="A man sitting and looking at his desktop"
          class="mb-12"
        />
        <p class="mobile-screen__desc mb-0">
          For the full Foodrazor experience log in on your computer
        </p>
        <hr />
        <div class="mobile-screen__subtitle">
          <img src="@/assets/number-3.svg" alt="Number 3" />
          <p class="ml-12">Mobile App</p>
        </div>
        <img
          src="@/assets/mobile.png"
          alt="Phone screens showing the application"
          class="mb-12 width-100"
        />
        <p class="mobile-screen__desc mb-16">Download our apps on iOS or Android</p>
        <div class="mobile-screen__download-apps">
          <a
            href="https://play.google.com/store/apps/details?id=co.foodrazor.android&pcampaignid=web_share"
          >
            <img src="@/assets/google-play.svg" alt="Download the application from Google Play" />
          </a>
          <a href="https://apps.apple.com/us/app/foodrazor/id6504676705">
            <img
              src="@/assets/app-store.svg"
              alt="Download the application from Apple App Store"
              class="ml-24"
            />
          </a>
        </div>
        <hr class="mb-4" />
        <ez-button type="link" formType="button" @click="onLogoutClick">
          Sign Out
          <font-awesome-icon icon="sign-out-alt" class="ml-4" />
        </ez-button>
      </div>
      <div v-else class="width-100">
        <div class="mobile-header">
          <img
            class="mobile-screen__logo"
            v-if="oezPlatformName === 'Foodrazor'"
            src="@/assets/Foodrazor-logo.svg"
            alt="Foodrazor logo"
          />
          <img v-else src="@/assets/logo-dark.svg" alt="EZ logo" class="mobile-screen__logo" />
          <account-menu />
        </div>
        <div v-if="!uploadActive" class="content">
          <p class="mb-16 heading">Overview</p>
          <p class="subheading mb-32">Select which action you’d like to take.</p>
          <ez-banner-button
            class="mb-16"
            color="yellow"
            icon="upload"
            @click="mobileUploadInvoice('credit_note')"
            >Upload Credit Note</ez-banner-button
          >
          <ez-banner-button
            class="mb-16"
            color="orange"
            icon="upload"
            @click="mobileUploadInvoice('invoice')"
            >Upload Invoice Page</ez-banner-button
          >
          <ez-banner-button
            v-if="$permission.has('conductStocktake')"
            class="mb-16"
            color="blue"
            icon="clipboard-check"
            @click="mobileConductStocktake"
            >Stocktake</ez-banner-button
          >
          <hr />
          <ez-banner-button class="mb-16" color="purple" icon="desktop" @click="goToFeaturePage"
            >Feature Availability by Mobile, Web & Apps</ez-banner-button
          >
        </div>
        <div v-else-if="uploadActive" class="content">
          <p class="mb-16 heading">
            {{ `Upload ${fileType !== 'credit_note' ? 'Invoice' : 'Credit Note'}` }}
          </p>
          <p class="subheading mb-32">
            {{
              `Have an ${
                fileType !== 'credit_note' ? 'Invoice' : 'Credit Note'
              } without an Order? Upload it here.`
            }}
          </p>
          <ez-form
            ref="uploadInvoiceFormMobile"
            method="post"
            submit-type="multipart"
            form-key="invoiceUploadMobile"
            @success="confirmSuccess"
            :action="formAction"
            :additional-data="imageData"
            :additional-headers="{ loadingKey: LOADING_KEY.LOG_OFFLINE_ORDER }"
          >
            <ez-multiple-image-upload
              ref="imageUpload"
              name="invoices"
              accept="image/jpeg,image/png,application/pdf"
              class="upload-component"
              form-key="invoiceUploadMobile"
              card-type
              @change="imagesUploadedStep"
              :number-of-files="20"
              :data-cy="outletCy.ORDERS.GENERAL.NEW_OFFLINE_ORDER.LABEL__MODAL_UPLOAD_INVOICE"
            >
              <template #cta>{{
                `Add ${fileType !== 'credit_note' ? 'Invoices' : 'Credit Notes'} `
              }}</template>
            </ez-multiple-image-upload>
            <input v-if="fileType !== ''" type="hidden" name="type" :value="fileType" />
            <ez-loader :show="isUploadLoading">Loading...</ez-loader>
            <ez-button
              class="width-100"
              form-type="submit"
              @click="submitMobileInvoice"
              :disabled="!hasInvoice"
              :is-loading="isUploadLoading"
            >
              Upload
            </ez-button>
          </ez-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import AccountMenu from '@/views/platform/venue/AccountMenu.vue';
import TabLayout from '@/components/layout/Tab.vue';
import EzBadge from '@/components/ui/Badge';
import EzBannerButton from '@/components/ui/Button/EzBannerButton.vue';
import StatusBadge from '@/views/common/status-badge/StatusBadge';
import EzAlert from '@/components/ui/Alert/EzAlert.vue';
import EzButton from '@/components/ui/Button';
import { resetState } from '@/store';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import { ACCOUNT_STATUS_TRIAL_EXPIRED, LOADING_KEY } from '@/util/constants';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import { EzMultipleImageUpload } from '@/components/ui/ImageUpload';
import { getContextId } from '@/util/utils';
import EzForm from '@/components/ui/Form';
import flash from '@/components/ui/FlashMessage';
import EzLoader from '@/components/ui/Loader';

export default {
  components: {
    AccountMenu,
    TabLayout,
    EzBadge,
    StatusBadge,
    EzAlert,
    EzButton,
    EzMultipleImageUpload,
    EzForm,
    EzLoader,
    EzBannerButton,
  },
  data() {
    return {
      outletCy,
      commonCy,
      imageData: null,
      LOADING_KEY,
      uploadActive: false,
      hasInvoice: false,
      fileType: '',
    };
  },
  computed: {
    pendingCount() {
      return this.me.pendingCount || 0;
    },
    ...mapGetters('loading', ['getLoading']),
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
    venueId() {
      return getContextId();
    },
    isUploadLoading() {
      return this.getLoading(LOADING_KEY.LOG_OFFLINE_ORDER);
    },
    formAction() {
      return `venues/${this.contextId}/pending-invoices`;
    },
    ...mapGetters({
      itemsCount: 'cart/getItemCount',
    }),
    ...mapState('entities/users', ['context', 'contextId', 'loggedUser']),
    groupViewMode() {
      if (this.loggedUser.context === 'venue-group') return true;
      return false;
    },
    isInventoryEnabled() {
      return this.context?.features?.inventory || false;
    },
    canSeeConnectionRequest() {
      return this.context?.invitationsCount > 0 && !this.$permission.isFree;
    },
    hasManageInventory() {
      return this.$permission.has('manageInventory');
    },
    hasConductStocktake() {
      return this.$permission.has('conductStocktake');
    },
    hasManageInventoryAndConductStocktake() {
      return this.$permission.has('manageInventory') && this.$permission.has('conductStocktake');
    },
    banner() {
      return this.context?.banner || null;
    },
    isTrialExpired() {
      return this.context?.subscriptionStatus === ACCOUNT_STATUS_TRIAL_EXPIRED;
    },
    isTrial() {
      return this.context?.isTrial ?? false;
    },
    isFreemium() {
      return this.context?.accountType === 'free' ?? false;
    },
    css() {
      return `:root {
        --banner-primary-color: ${this.banner.primaryColor};
        --banner-secondary-color: ${this.banner.secondaryColor};
      }`;
    },
    supplierNotificationNumber() {
      return this.context?.invitationsCount || 0;
    },
    pendingInvoicesCount() {
      if (!this.groupViewMode) {
        return this.context.pendingInvoicesCount;
      }
      return this.loggedUser.venues.reduce((sum, v) => sum + v.pendingInvoicesCount || 0, 0);
    },
  },
  methods: {
    openCart() {
      this.$router.push({
        name: 'venue-shopping-cart',
      });
    },
    ...mapActions('entities/users', ['initVenue', 'managerLogout']),
    ...mapActions('entities/venues', ['venueStartStocktake']),
    onBannerCTAClick() {
      if (this.banner.path.startsWith('https')) {
        window.open(this.banner.path, '_blank');
      } else {
        this.$router.push(this.banner.path);
      }
    },
    onLogoutClick() {
      this.managerLogout().finally(() => {
        localStorage.clear();
        resetState();
        window.Intercom('shutdown');
        this.$router.replace({ name: 'platform-login' });
      });
    },
    async imagesUploaded(formData) {
      this.hasInvoice = formData.get('invoices');
      this.imageData = formData;
      await this.$nextTick();
      this.$refs.uploadInvoiceForm.onSubmit();
    },
    async imagesUploadedStep(formData) {
      this.hasInvoice = formData.get('invoices');
      this.imageData = formData;
    },
    reset() {
      this.imageData = null;
      this.$refs.imageUpload.reset();
      this.uploadActive = false;
      this.fileType = '';
    },
    confirmSuccess() {
      this.reset();
      flash.success({
        title: 'Invoice successfully uploaded',
      });
    },
    submitMobileInvoice() {
      this.$refs.uploadInvoiceFormMobile.onSubmit();
    },
    mobileUploadInvoice(type) {
      this.fileType = type;
      this.uploadActive = true;
    },
    async mobileConductStocktake() {
      const { data } = await this.venueStartStocktake({ venueId: this.venueId });
      this.$router.push({
        name: 'venue-conduct-stocktake',
        params: { id: data.data.id },
      });
    },
    goToFeaturePage() {
      this.$router.push({ name: 'venue-features-list' });
    },
  },
  created() {
    this.initVenue();
  },
};
</script>

<style scoped lang="scss">
$tab-text-color: #8790a3;
$active-tab-border-color: #4d7cfe;
$item-count-color: #ffffff;

:deep() div.alert.alert--banner.venue-banner {
  margin-top: 0;
  margin-bottom: 0;
  background-color: var(--banner-secondary-color);
  color: var(--banner-primary-color);

  &__message,
  .button,
  svg {
    color: var(--banner-primary-color);
  }

  .button {
    background-color: $color-white;
  }

  .banner__link {
    background: transparent;
    color: var(--banner-primary-color);
    font-weight: 500;
    text-decoration: none;
  }
  .sign-out {
    position: absolute;
    right: 0;
  }
}

.layout-tab__navigation {
  .cart {
    @extend %button-reset;
    @extend %flex-center;
    margin-right: 0.375rem;
    outline: 0;
    background: none;
    cursor: pointer;

    :deep() .badge {
      flex-shrink: 0;
      margin-right: 0;
    }
  }

  .sub-menu {
    display: flex;
    align-items: center;
    height: 100%;
    a {
      height: 100%;
    }
    &__dropdown {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 54px;
      margin: 0;
      margin-left: -16px;
      padding: 8px 16px;
      z-index: 100;
      background-color: #fff;
      border-radius: 3px;
      border: 1px solid #eceef5;
      border-top: none;
      height: fit-content;
      width: max-content;

      &:hover {
        display: flex;
      }

      li {
        margin-left: 0;
        a {
          padding: 8px 0;
        }
      }
    }
    &:hover + .sub-menu__dropdown {
      display: flex;
    }
  }
}

.mobile-screen {
  display: none;
}

@media (max-width: 768px) {
  .mobile-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    text-align: center;

    hr {
      width: 100%;
    }

    &__logo {
      height: 40px;
    }

    &__title {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      letter-spacing: 0.15px;
      span {
        color: $color-primary-blue;
      }
    }

    &__subtitle {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 500;
    }

    &__desc {
      font-size: 12px;
      line-height: 1.5;
      color: $color-gray-6C;
    }

    &__download-apps {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 32px;
      }
    }

    :deep() .ez-form {
      width: 100%;
    }

    .upload-component {
      :deep() .preview__button label {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-primary-blue;
        color: white;
        font-size: 16px;
        line-height: 1.5;
        padding: 12px 16px;
        font-weight: 500;
        height: auto;
        svg {
          display: none;
        }
      }

      :deep() .preview__images {
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
      }
    }
    &__premium {
      padding: 0;
      letter-spacing: 0.15px;

      .mobile-header {
        padding: 16px 24px;
        border-bottom: 1px solid #eceef5;

        display: flex;
        align-items: center;
        justify-content: space-between;

        :deep() .button-dropdown__dropdown {
          max-height: 250px;
          text-align: left;
        }

        :deep() .button-dropdown {
          .button-dropdown__toggle-container {
            .button {
              padding: 0;
            }
            .trigger {
              display: none;
            }
            .entity-info__text {
              display: none;
            }
          }
        }
      }
      .content {
        padding: 32px 24px;

        .upload-component {
          :deep() .preview__button label {
            display: flex;
            margin-bottom: 16px;
            color: $color-gray-6C;
            background-color: #f5f6fa;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            line-height: 1.5;
            padding: 12px 16px;
            font-weight: 500;
            height: auto;
            svg {
              display: block;
            }
          }
        }

        .heading {
          font-size: 20px;
          line-height: 1.4;
          font-weight: 600;
        }

        .subheading {
          font-size: 14px;
          line-height: 1.4;
          font-weight: 400;
          color: $color-gray-6C;
        }
      }
    }
  }
  :deep() .layout-tab.desktop-screen {
    display: none;
  }
}
</style>
