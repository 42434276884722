<script>

const Event = {
  click: 'click',
};

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'VCategoryEntityInfo',
  props: {
    category: {
      required: true,
      type: Object,
    },
    isChild: {
      required: true,
      type: Boolean,
    },
    name: {
      required: false,
      type: String,
    },
  },
  methods: {
    onClick() {
      this.$emit(Event.click, this.category.id);
    },
  },
};
</script>
<template>
  <div
    :class="[
      'category-entity',
      {'category-entity__parent': !isChild},
      {'category-entity__child': isChild},
    ]"
    @click="onClick"
  >
    <span>
      {{ category.name }}
    </span>
  </div>
</template>

<style scoped lang="scss">
.category-entity {
  color: $color-gray-25;
}

.category-entity__parent {
  font-weight: bold;
}

.category-entity__child {
  margin-left: 12px !important;
}
</style>
