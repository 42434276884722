<template>
  <div>
    <header class="products-header">
      <div class="products-header__main">
        <h1>History</h1>
        <span>{{ `${meta.totalCount || 0} in total` }}</span>
      </div>
      <div class="products-header__valuation-wrapper">
        <div v-if="context.pendingInvoicesLimit !== 0" class="products-header__valuation">
          <div class="products-header__valuation__amount-wrapper">
            <span
              v-if="context.pendingInvoicesLimit === null"
              class="products-header__valuation__amount"
            >
              &infin;
            </span>
            <span v-else class="products-header__valuation__amount">
              {{ stats.pendingInvoicesRemaining }}
            </span>
            <small>Invoice pages remaining</small>
          </div>
          <total-icon
            icon="file-invoice-dollar"
            variant="blue"
            :biggerIcon="true"
            v-tooltip="{
              classes: ['tooltip--reset-margin', 'u-text-center'],
              content: 'Number of remaining invoices',
            }"
          />
        </div>
        <div class="products-header__valuation">
          <div class="products-header__valuation__amount-wrapper">
            <span class="products-header__valuation__amount">
              {{ meta.totalAmount | price }}
            </span>
            <small>{{ totalLabel }}</small>
          </div>
          <span class="products-header__valuation__icon">
            <span class="products-header__valuation__icon__inner">
              <font-awesome-icon icon="money-bill-wave" />
            </span>
          </span>
        </div>
      </div>
    </header>
    <order-list
      :columns="[
        'invoiceNumber',
        'distributor',
        ...(groupViewMode ? ['venue'] : []),
        'invoiceDate',
        'requestedBy',
        'processingStatus',
        ...(hasAccountingIntegration && $permission.has('exportAccounting')
          ? ['exportStatus']
          : []),
        'amount',
      ]"
      :headers="{
        invoiceNumber: () => 'Invoice No.',
        distributor: () => $t('global.distributor'),
        venue: () => 'Location',
        processingStatus: () => 'Status',
        requestedBy: () => 'Created By',
      }"
      :filters="filters"
      :hasStats="false"
      :is-loading-more="isLoadingMore"
      :orders="orders"
      @loadMore="onLoadMoreClick"
      @rowClick="openDrawerAndLoadOrder"
      @exportOrder="exportOrder"
      @openIntegrationMapping="openAccountingIntegration"
      :removeButton="true"
      @removeItem="onRemovePendingUpdate"
      show-invoice-number
      is-invoice
    >
      <template #filtersGroup>
        <div class="filters-grp">
          <ez-filter-list
            :filters="listFilters"
            @filterUpdated="updateListFilters"
            @resetFilter="resetListFilters"
            class="mr-16"
          >
            <ez-input
              formKey="filters"
              name="invoiceNumber"
              class="search"
              placeholder="Enter Invoice Number"
              :value="listFilters.invoiceNumber"
              label="Invoice Number"
              :data-cy="outletCy.ORDERS.GENERAL.INPUT__FILTER_SEARCH"
            >
              <template #suffix>
                <font-awesome-icon icon="search" />
              </template>
            </ez-input>
            <ez-dropdown
              class="ml-16"
              name="listStatus"
              formKey="filters"
              :data="[
                { id: null, name: 'All Statuses', selectable: true },
                {
                  id: 'processingStatus',
                  name: 'Invoice Status',
                  children: invoiceStatusFilter,
                },
                {
                  id: 'exportStatus',
                  name: 'Export Status',
                  children: exportStatusFilter,
                },
              ]"
              :selected="
                filters.exportStatus?.length + filters.processingStatus?.length > 1
                  ? ''
                  : listFilters.listStatus
              "
              :placeholder="
                filters.exportStatus?.length + filters.processingStatus?.length > 1
                  ? 'Multiple'
                  : 'All Statuses'
              "
              parents-not-selectable
            />
            <v-date-picker
              ref="calendar"
              class="calendar ml-16"
              name="scheduled"
              label="Delivery Date"
              hide-label
              v-model="scheduledRange"
              formKey="filters"
              is-all-time-in-future
              range-mode
              with-predefined-ranges
              :number-of-calendars="2"
              :select-predefined-range="defaultRangeNameScheduled"
              :what-date-to-display="today"
              @rangeNameChange="changeScheduledRangeName"
              :data-cy="outletCy.ORDERS.GENERAL.BUTTON__DELIVERY_DATE"
            />
          </ez-filter-list>
          <v-filter-dropdown
            :data-cy="outletCy.ORDERS.GENERAL.BUTTON_FILTERS"
            :filters="initialStatusFilters"
            :default-date-filters="defaultDateFilters"
            @filterUpdated="updateFilters"
            @resetFilters="resetFilters"
            @filterClosed="filterClosed"
            ref="filtersGroup"
          >
            <template #firstRow>
              <v-select-search
                class="select-supplier"
                formKey="filter-orders"
                :selected="initialStatusFilters.distributorId"
                name="distributorId"
                :label="`${$t('global.distributor')}`"
                :data="distributorFilter"
                :placeholder="`All ${$t('global.distributors')}`"
                :searchPlaceholder="`Select ${$t('global.distributor')}`"
                align-left
              >
                <template #result="{ result }">
                  <v-distributor-entity-info :distributor="result" />
                </template>
              </v-select-search>

              <v-date-picker
                ref="calendar"
                class="calendar"
                name="scheduled"
                label="Invoice Date"
                v-model="scheduledRange"
                formKey="filters"
                is-all-time-in-future
                range-mode
                with-predefined-ranges
                :number-of-calendars="2"
                :select-predefined-range="defaultRangeNameScheduled"
                :what-date-to-display="today"
                @rangeNameChange="changeScheduledRangeName"
                :data-cy="outletCy.ORDERS.GENERAL.BUTTON__DELIVERY_DATE"
              />
            </template>
            <template #secondRowLabel>Invoice Status</template>
            <template #secondRow>
              <ez-checkbox
                v-for="status in invoiceStatusFilter"
                class="status-checkbox"
                :checked="initialStatusFilters[status.id]"
                :key="status.id"
                formKey="filters"
                :name="status.id"
                :label="status.name"
              />
            </template>
            <template #thirdRowLabel>Export Status</template>
            <template #thirdRow>
              <ez-checkbox
                v-for="status in exportStatusFilter"
                class="status-checkbox"
                :checked="initialStatusFilters[status.id]"
                :key="status.id"
                formKey="filters"
                :name="status.id"
                :label="status.name"
              />
            </template>
          </v-filter-dropdown>
        </div>
      </template>
      <template #actions>
        <div class="orders-actions">
          <export-orders
            :data-cy="outletCy.ORDERS.GENERAL.BUTTON__EXPORT_ORDERS"
            v-if="filters || (hasAccountingIntegration && $permission.has('exportAccounting'))"
            :orders="orders"
            is-invoice
            :action="exportOrdersAction"
            :filters="filters"
            :number-of-applied-filters="numberOfAppliedFilters"
            :exportToAccounting="hasAccountingIntegration && $permission.has('exportAccounting')"
            @exportOrder="exportAllOrders"
            :export-to-accounting-disabled="meta.accountingExportInProgress || exportStarted"
          />

          <div
            class="dropdown-button ml-16"
            v-if="$permission.has('createOrder') && !$permission.isFree && !groupViewMode"
          >
            <ez-button
              @click="openUploadInvoice('invoice')"
              :data-cy="outletCy.ORDERS.GENERAL.BUTTON__LOG_OFFLINE_ORDER"
            >
              Upload Invoice
            </ez-button>
            <ez-button-dropdown buttonType="primary">
              <template #icon>
                <font-awesome-icon icon="angle-down" />
              </template>
              <template #dropdown>
                <ez-button
                  type="secondary"
                  @click="openUploadInvoice('credit_note')"
                  :data-cy="outletCy.ORDERS.GENERAL.BUTTON__LOG_OFFLINE_ORDER"
                >
                  Upload Credit Note
                </ez-button>
              </template>
            </ez-button-dropdown>
          </div>
        </div>
      </template>
      <template #banner>
        <ez-alert type="red" v-if="pendingInvoicesCount > 0">
          <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
          <template>{{ alertMessage }}</template>
          <template #cta>
            <ez-button
              :data-cy="outletCy.ORDERS.GENERAL.BUTTON__NEEDS_APPROVAL_BANNER"
              type="red-link"
              @click="filterApproval"
            >
              View Invoices
            </ez-button>
          </template>
        </ez-alert>
      </template>

      <ez-drawer ref="orderDrawer" class="order-drawer" @onClose="resetDrawerRoute">
        <template #title>
          <h2>Order #{{ selectedOrder.orderNumber }}</h2>
          <status-badge :status="selectedOrder.status || 'pending'"></status-badge>
        </template>

        <!--   Info messages // Start   -->

        <template #info-message>
          <template v-if="editingMode">
            <div class="mx-24 mb-16">
              <ez-alert variant="disclaimer" class="m-0" size="big">
                <template #icon>
                  <font-awesome-icon icon="exclamation-circle" />
                </template>
                <template #title>
                  <span>Edit mode active</span>
                </template>
                <p>
                  Edit the existing {{ !isPublicOrder ? 'or add new' : '' }} products to this order.
                  Once you save the changes, the {{ venueOrDistributor }} will be notified.
                </p>
              </ez-alert>
            </div>
          </template>
          <template v-if="checkIsMpOrToBeDetermined && hasMpInOrder && !editingMode">
            <div class="mx-24 mb-16">
              <ez-alert variant="disclaimer" class="m-0" size="big">
                <template #icon>
                  <font-awesome-icon icon="exclamation-circle" />
                </template>
                <p v-if="hasMpInOrder && hasTbdInOrder">
                  This order contains products with market prices and fractional units which may
                  affect the total amount (incl. subtotal and taxes) based on the actual delivered
                  quantity.
                </p>
                <p v-if="hasMpInOrder && !hasTbdInOrder">
                  Product with market price is included in this order. Market prices affect the
                  total amount, subtotal and taxes.
                </p>
              </ez-alert>
            </div>
          </template>
          <template
            v-if="
              selectedOrder.status === ORDER_STATUS_PENDING &&
              selectedOrder.afterWorkingHours &&
              !editingMode
            "
          >
            <div class="info-message-wrapper">
              <div class="info">
                <div class="column-left">
                  <font-awesome-icon icon="info-circle" />
                </div>
                <div class="column-right">
                  This order has been placed after work hours. The
                  {{ $t('global.distributor') }} will process it on the next working day.
                </div>
              </div>
            </div>
          </template>
          <template
            v-if="
              selectedOrder.canCreateXeroBill && !editingMode && $permission.has('exportAccounting')
            "
          >
            <div class="info-message-wrapper">
              <div class="info">
                <div class="column-left">
                  <font-awesome-icon icon="info-circle" />
                </div>
                <div class="column-right">
                  There was a problem creating your bill. You can try creating it again.<br />
                  <ez-button
                    :disabled="loading"
                    @click="createBill"
                    :class="['create-invoice', { 'create-invoice--disabled': loading }]"
                    type="blue-link"
                  >
                    Create Bill
                  </ez-button>
                </div>
              </div>
            </div>
          </template>

          <template v-if="hasIntegrationWarnings && $permission.has('exportAccounting')">
            <div
              v-for="(warning, idx) in selectedOrder.integrationWarnings"
              :key="idx"
              class="mx-24 mb-16"
            >
              <ez-alert variant="disclaimer" size="big">
                <template #icon>
                  <font-awesome-icon icon="info-circle" />
                </template>
                <p>{{ warning.text }}</p>
                <ez-button
                  v-if="warning.url"
                  type="blue-link"
                  class="orders__alert-retry"
                  @click="onIntegrationWarningCTA(warning.url)"
                >
                  {{ warning.cta }}
                </ez-button>
              </ez-alert>
            </div>
          </template>
        </template>

        <!--   Info messages // End   -->

        <template #content>
          <single-order
            :order="selectedOrder"
            :enableEdit="canEditProducts"
            :editingMode="editingMode"
            :hasDeclinedIcon="selectedOrder.status === ORDER_STATUS_DECLINED"
            :can-edit-invoice-date="canEditInvoiceDate"
            :can-edit-invoice-number="canEditInvoiceNumber"
            hide-order-date
            hide-delivery-date
            hide-due-date
            show-invoice-date
            @editInvoiceDate="onEditInvoiceDate"
            @editInvoiceNumber="onEditInvoiceNumber"
            @invoiceUploaded="uploadedInvoice"
            @editedProduct="editedProduct"
            @productRemoved="editedProduct"
          >
            <template #distributor>
              <div class="u-flex-space" v-if="selectedOrder.distributor">
                <v-distributor-entity-info
                  class="entity-info-custom"
                  :distributor="selectedOrder.distributor"
                />
                <ez-button-group>
                  <export-button
                    class="export-btn"
                    direction="down"
                    v-if="originalSelectedOrder.exportTypes?.length"
                    :order="selectedOrder"
                    :venueId="selectedOrder.venueId ?? null"
                    :exportToAccounting="
                      orderHasAccountingIntegration(selectedOrder) &&
                      $permission.has('exportAccounting')
                    "
                    @exportOrder="exportSingleOrder"
                    :openPDF="true"
                  />
                  <ez-button type="secondary" formType="button" @click="showDistributorInfo">
                    <font-awesome-icon icon="info-circle" />
                    <span>{{ $t('global.distributor') }} Info</span>
                  </ez-button>
                </ez-button-group>
              </div>
            </template>
          </single-order>
          <ez-loader :show="creatingBill">Creating Bill...</ez-loader>
        </template>
        <template #footer>
          <template v-if="shouldDisplayFooter">
            <button-switch
              :order="selectedOrder"
              :editing-mode="editingMode"
              @dispute="openDispute"
              @resolve="openResolve"
              @complete="openConfirm"
              @reorder="openReorder"
              @decline="openDecline"
              @abandon="openAbandon"
              @approve="openApprove"
              @saveEdit="saveEdit"
              @discardEdit="discardEdit"
              @copyToClipboard="copyToClipboard"
              @edit="edit"
            />
            <div v-if="areTaxValuesNumbers" class="order-total-price">
              <v-subtotal-info
                :item-list="itemList"
                :delivery-fee="deliveryFee"
                :delivery-fee-taxable="deliveryFeeTaxable"
                :amount-adjustment="amountAdjustment"
                :tax-adjustment="taxAdjustment"
                :tax="tax"
                :is-tbd="isSomeTbd"
                :discount="orderDiscountAmount"
                :taxCalculation="taxCalculation"
              />
            </div>
          </template>
        </template>
      </ez-drawer>
      <div v-if="isLoading" class="u-text-center mt-12">
        <ez-spinner />
      </div>

      <ez-info-modal
        ref="infoModal"
        v-if="selectedOrder.distributor"
        :data="selectedOrder.distributor"
      >
        <template #title>{{ $t('global.distributor') }} Info</template>
      </ez-info-modal>

      <open-dispute
        @success="closeOrderDrawerAndRefresh"
        :venueId="selectedOrder.venueId ?? contextId"
        :orderId="selectedOrder.id"
        ref="dispute"
      />
      <approve-order @success="closeOrderDrawerAndRefresh" :order="selectedOrder" ref="approve" />
      <resolve-order @success="closeOrderDrawerAndRefresh" :order="selectedOrder" ref="resolve" />
      <confirm-order
        @success="closeOrderDrawerAndRefresh"
        :action="`/venues/${selectedOrder.venueId ?? contextId}/orders/${
          selectedOrder.id
        }/complete`"
        :order="selectedOrder"
        :isPublicOrder="isPublicOrder"
        ref="confirm"
      />
      <reorder @success="closeOrderDrawerAndRefresh" :order="selectedOrder" ref="reorder" />
      <decline-order
        @success="closeOrderDrawerAndRefresh"
        :order="selectedOrder"
        :venueId="selectedOrder.venueId ?? contextId"
        ref="decline"
      />
      <ez-confirmation-modal ref="abandon" icon="question">
        <template #title>Cancel Order?</template>
        <template #content>
          <p>Are you sure you want to cancel this order?</p>
          <ez-textarea
            form-key="cancelOrder"
            placeholder="Leave a Note"
            name="message"
            label="Note"
            class="mt-8"
            @onChange="val => (cancelNote = val)"
            :data-cy="commonCy.SINGLE_ORDER.CANCEL_ORDER_MODAL.TEXTAREA__NOTE"
          />
        </template>
        <template #footer>
          <ez-button type="link" @click="closeAbandonModal">Discard</ez-button>
          <ez-button @click="confirmAbandonOrder" :is-loading="isLoadingAbandon">
            Cancel Order
          </ez-button>
        </template>
      </ez-confirmation-modal>
      <ez-confirmation-modal ref="deleteModal" type="red">
        <template #title>Remove Invoice?</template>
        <template #content>
          <p>This invoice will be deleted from the list.</p>
        </template>
        <template #footer>
          <ez-button @click="closeDeleteModal" type="link">Cancel</ez-button>
          <ez-button @click="deletePendingUpdate" type="red">Remove Invoice</ez-button>
        </template>
      </ez-confirmation-modal>

      <upload-invoice :fileType="fileType" ref="uploadInvoice" @success="invoicesUploaded" />

      <ez-confirmation-modal ref="invoiceDateModal">
        <template #title>Edit Invoice Date</template>
        <template #content>
          <div class="mt-16">
            <v-date-picker
              class="calendar mt-8"
              :data-cy="commonCy.SINGLE_ORDER.EDIT_DELIVERY_DATE_MODAL.INPUT__DELIVERY_ON"
              name="invoiceDate"
              label="Invoice Date"
              v-model="deliveryOn"
              form-key="filters"
              @dateChange="updateDeliveryOn"
            />
          </div>
        </template>
        <template #footer>
          <ez-button @click="closeDeliveryOnModal" type="link">Cancel</ez-button>
          <ez-button @click="saveDeliveryOnDate"> Save Changes </ez-button>
        </template>
      </ez-confirmation-modal>
      <ez-confirmation-modal ref="invoiceNumberEditModal">
        <template #title>Edit Invoice Number</template>
        <template #content>
          <div class="mt-16">
            <ez-input
              formKey="order-reference-number"
              name="invoiceNumber"
              label="Invoice Number"
              placeholder="Enter Invoice Number"
              :value="invoiceNumber ? invoiceNumber : selectedOrder.invoiceNumber"
              class="mt-16"
              @onChange="val => (invoiceNumber = val)"
            />
          </div>
        </template>
        <template #footer>
          <ez-button type="link" @click="closeInvoiceNumberModal"> Cancel </ez-button>
          <ez-button @click="saveInvoiceNumber"> Save Changes </ez-button>
        </template>
      </ez-confirmation-modal>

      <ez-loader :show="loading || (isSwitchingAccount && !creatingBill)">Loading...</ez-loader>
    </order-list>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { resetState } from '@/store';
import httpService from '@/api/http';
import EzButton, { EzButtonGroup } from '@/components/ui/Button';
import EzDrawer from '@/components/ui/Drawer';
import VDistributorEntityInfo from '@/components/v3/patterns/VDistributorEntityInfo';
import { EzConfirmationModal, EzInfoModal } from '@/components/ui/Modal';
import { SingleOrder, OrderList } from '@/views/common/orders';
import StatusBadge from '@/views/common/status-badge';
import {
  DATE_INPUT_FORMAT,
  DELIVERY_PERIODS_MAPPED,
  LOADING_KEY,
  ORDER_DATE_FILTER_DEFAULT,
  OUTLET_ORDER_SCHEDULED_DATE_FILTER_DEFAULT as ORDER_SCHEDULED_DATE_FILTER_DEFAULT,
  ORDER_STATUS_ACCEPTED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_COMPLETED_AFTER_DISPUTE,
  ORDER_STATUS_DECLINED,
  ORDER_STATUS_PARTIALLY_ACCEPTED,
  ORDER_STATUS_PENDING,
  ORDER_STATUS_SHIPPED,
  PARTIALLY_ACCEPTABLE,
  INVOICE_EXPORT_STATUS_READY,
  invoiceProcessingStatusLabels,
  invoiceExportStatusLabels,
  INVOICE_PROCESSING_STATUS_PROCESSED,
  INVOICE_PROCESSING_STATUS_ACTION_NEEDED,
  INVOICE_PROCESSING_STATUS_DRAFT,
  INVOICE_PROCESSING_STATUS_PROCESSING,
} from '@/util/constants';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import EzAlert from '@/components/ui/Alert';
import ButtonSwitch from '@/views/platform/venue/orders/ButtonSwitch.vue';
import OpenDispute from '@/views/platform/venue/orders/actions/OpenDispute.vue';
import ApproveOrder from '@/views/platform/venue/orders/actions/ApproveOrder.vue';
import ResolveOrder from '@/views/platform/venue/orders/actions/ResolveOrder.vue';
import ConfirmOrder from '@/views/common/orders/actions/ConfirmOrder.vue';
import Reorder from '@/views/platform/venue/orders/actions/Reorder.vue';
import DeclineOrder from '@/views/platform/venue/orders/actions/DeclineOrder.vue';
import UploadInvoice from '@/views/platform/venue/orders/actions/UploadInvoice.vue';
import flash from '@/components/ui/FlashMessage';
import ExportButton from '@/views/common/orders/ExportButton.vue';
import { venueXeroCreateBill } from '@/api/endpoints/xero';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index';
import dayjs from 'dayjs';
import ExportOrders from '@/views/common/orders/ExportOrders';
import EzSpinner from '@/components/ui/Spinner';
import EzInput from '@/components/ui/Input';
import { canVenueEditDeliveryFee, isDeclined, isExcluded, isPremium, debounce } from '@/util/utils';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import EzLoader from '@/components/ui/Loader/EzLoader';
import VSubtotalInfo from '@/components/v3/patterns/VSubtotalInfo';
import VFilterDropdown from '@/components/v3/patterns/VFilterDropdown';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import { isVenue } from '@/mixins/permissions/utils';
import EzTextarea from '@/components/ui/Textarea/EzTextarea';
import { addTotalPriceCustom } from '@/util/utilsFinCalculator';
import EzFilterList from '@/components/ui/FilterList/EzFilterList.vue';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown.vue';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown.vue';
import TotalIcon from '@/views/common/orders/total-icon';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

export default {
  components: {
    EzTextarea,
    VSubtotalInfo,
    VSelectSearch,
    EzLoader,
    ExportButton,
    EzButtonGroup,
    DeclineOrder,
    UploadInvoice,
    ConfirmOrder,
    ResolveOrder,
    EzAlert,
    EzButton,
    EzDrawer,
    VDistributorEntityInfo,
    EzInfoModal,
    SingleOrder,
    StatusBadge,
    OrderList,
    ButtonSwitch,
    OpenDispute,
    ApproveOrder,
    Reorder,
    VDatePicker,
    ExportOrders,
    EzConfirmationModal,
    EzSpinner,
    EzInput,
    VFilterDropdown,
    EzCheckbox,
    EzFilterList,
    EzDropdown,
    EzButtonDropdown,
    TotalIcon,
  },
  props: {
    orderId: {
      type: Number,
      required: false,
    },
    isPublicOrder: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      ORDER_STATUS_DECLINED,
      ORDER_STATUS_PENDING,
      INVOICE_EXPORT_STATUS_READY,
      selectedForDelete: null,
      commonCy,
      listFilters: {
        invoiceNumber: null,
        listStatus: INVOICE_PROCESSING_STATUS_PROCESSED,
      },
      filters: {
        distributorId: null,
        processingStatus: [INVOICE_PROCESSING_STATUS_PROCESSED],
      },
      defaultRangeName: ORDER_DATE_FILTER_DEFAULT.name,
      range: {
        start: ORDER_DATE_FILTER_DEFAULT.from,
        end: ORDER_DATE_FILTER_DEFAULT.to,
      },
      loading: false,
      editingMode: false,
      initialStatusFilters: { processed: true },
      scheduledRange: {
        start: ORDER_SCHEDULED_DATE_FILTER_DEFAULT.from,
        end: ORDER_SCHEDULED_DATE_FILTER_DEFAULT.to,
      },
      defaultRangeNameScheduled: ORDER_SCHEDULED_DATE_FILTER_DEFAULT.name,
      deliveryPeriodMapped: DELIVERY_PERIODS_MAPPED,
      today: new Date(),
      deliveryOn: null,
      invoiceNumber: null,
      deliveryOnNote: '',
      cancelNote: '',
      outletCy,
      prevDatesLabels: {},
      approvalFilterActive: false,
      orders: [],
      originalSelectedOrder: {},
      distributors: [],
      isSwitchingAccount: false,
      exportStarted: false,
      fileType: 'invoice',
      stats: {},
    };
  },
  computed: {
    ...mapState('entities/users', ['context', 'contextId', 'loggedUser']),
    ...mapState('entities/orders', ['meta', 'singleOrder']),
    ...mapGetters('entities/users', [
      'isVenueXeroConnected',
      'isVenueQuickBooksConnected',
      'isVenue',
      'isDistributor',
    ]),
    ...mapGetters('loading', ['getLoading']),
    groupViewMode() {
      if (this.loggedUser.context === 'venue-group') return true;
      return false;
    },
    pendingInvoicesCount() {
      if (!this.groupViewMode) {
        return this.context.pendingInvoicesCount;
      }
      return this.loggedUser.venues.reduce((sum, v) => sum + v.pendingInvoicesCount || 0, 0);
    },
    hasAccountingIntegration() {
      let hasIntegration = false;
      if (!this.groupViewMode) {
        hasIntegration =
          this.isVenue &&
          (this.isVenueXeroConnected(this.contextId) ||
            this.isVenueQuickBooksConnected(this.contextId));
      } else {
        hasIntegration =
          this.isVenue &&
          this.loggedUser.venues.some(
            item => this.isVenueXeroConnected(item.id) || this.isVenueQuickBooksConnected(item.id),
          );
      }
      return hasIntegration;
    },
    totalLabel() {
      const appliedFilters = Object.entries(this.filters).filter(([key, val]) => {
        /**
         * Checks if the current values for date filters are the same as the default ones.
         * If not equal, that means the filter has changed and should be accounted for.
         */
        if (['scheduledFrom', 'scheduledTo'].includes(key)) {
          const filter = key.startsWith('scheduled') ? 'scheduled' : 'month';
          const property = key.split('scheduled').filter(Boolean)[0]; // 'from' | 'to'
          return val && val !== this.defaultDateFilters?.[filter]?.[property];
        }

        if (Array.isArray(val)) return !!val.length;
        return !!val;
      });

      return appliedFilters.length || 'scheduledFrom' in this.filters
        ? 'Total for selected filters'
        : 'Total invoiced this month';
    },
    hasIntegrationWarnings() {
      return !!this.selectedOrder.integrationWarnings?.length;
    },
    areTaxValuesNumbers() {
      const { tax, amount, amountWithTax } = this.selectedOrder;
      return Number.isFinite(tax) && Number.isFinite(amount) && Number.isFinite(amountWithTax);
    },
    venueOrDistributor() {
      let str = '';

      if (this.isPublicOrder) {
        str = this.type === 'venue' ? this.$t('global.distributor') : this.$t('global.venue');
      } else {
        str = this.isDistributor ? this.$t('global.venue') : this.$t('global.distributor');
      }

      return str.toLocaleLowerCase();
    },
    isLoading() {
      return this.getLoading('load-more-orders');
    },
    isLoadingAbandon() {
      return this.getLoading(LOADING_KEY.ABANDON_ORDER);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.FETCH_MORE_ORDERS);
    },
    creatingBill() {
      return this.getLoading(LOADING_KEY.XERO_CREATE_BILL);
    },
    canEditProducts() {
      return PARTIALLY_ACCEPTABLE.includes(this.selectedOrder.status);
    },
    canEditDeliveryFee() {
      return canVenueEditDeliveryFee({
        order: this.selectedOrder,
        permission: this.$permission,
      });
    },
    canEditInvoiceNumber() {
      const { editConfig } = this.selectedOrder;

      return this.editingMode && editConfig?.invoiceNumber;
    },
    canExport() {
      return [
        ORDER_STATUS_COMPLETED_AFTER_DISPUTE,
        ORDER_STATUS_COMPLETED,
        ORDER_STATUS_ACCEPTED,
        ORDER_STATUS_PARTIALLY_ACCEPTED,
        ORDER_STATUS_SHIPPED,
      ].includes(this.selectedOrder.status);
    },
    canEditInvoiceDate() {
      const { editConfig } = this.selectedOrder;

      return this.editingMode && editConfig?.invoiceDate;
    },
    alertMessage() {
      const approvalCount = this.pendingInvoicesCount;

      return approvalCount > 1
        ? `There are ${approvalCount} invoices with action needed status. You can see them by clicking on the button on the right.`
        : `There is ${approvalCount} invoice with action needed status. You can see it by clicking on the button on the right.`;
    },
    invoiceStatusFilter() {
      return Object.entries(invoiceProcessingStatusLabels).map(([id, name]) => ({ id, name }));
    },
    exportStatusFilter() {
      return Object.entries(invoiceExportStatusLabels).map(([id, name]) => ({
        id: `export_${id}`,
        name,
      }));
    },
    distributorFilter() {
      return this.distributors;
    },
    monthFilter() {
      return [
        {
          id: null,
          name: 'All Times',
        },
        ...this.$helpers.generateMonthsToDate(this.meta.firstOrderedAt),
      ];
    },
    exportOrdersAction() {
      return `venue/pending-invoices`;
    },
    isVenuePremium() {
      if (this.isPublicOrder && isVenue(this.type)) {
        const {
          venue: { accountType },
        } = this.singleOrder;

        return isPremium(accountType);
      }
      return this.isVenue && this.$permission.isPremium;
    },
    selectedOrder() {
      return this.singleOrder;
    },
    // Props for VSubtotalInfo - Start
    itemList() {
      return this.selectedOrder.orderedProducts
        .filter(prod => !isExcluded(prod) && !isDeclined(prod))
        .map(item =>
          addTotalPriceCustom({
            item,
            multiplicand: 'priceQuantity',
            checkPriceUnits: false,
          }),
        );
    },
    deliveryFee() {
      return this.selectedOrder.deliveryFee;
    },
    deliveryFeeTaxable() {
      return this.selectedOrder.deliveryFeeWithoutTax;
    },
    amountAdjustment() {
      return this.selectedOrder.amountAdjustment;
    },
    taxAdjustment() {
      return this.selectedOrder.taxAdjustment;
    },
    tax() {
      return this.selectedOrder.tax;
    },
    taxCalculation() {
      return this.selectedOrder.taxCalculation;
    },
    isSomeTbd() {
      return (this.selectedOrder.orderedProducts || [])
        .filter(pr => !isDeclined(pr))
        .some(pr => pr.marketPrice || (!pr.priceQuantity && pr.price > 0));
    },
    orderDiscountAmount() {
      return this.selectedOrder?.orderDiscountAmount;
    },
    // Props for VSubtotalInfo - End
    checkIsMpOrToBeDetermined() {
      return this.selectedOrder?.orderedProducts?.some(pr => pr.variableAmount || pr.marketPrice);
    },
    hasMpInOrder() {
      const products = this.selectedOrder?.orderedProducts;
      return products?.some(pr => pr.marketPrice);
    },
    hasTbdInOrder() {
      const products = this.selectedOrder?.orderedProducts;
      return products?.some(pr => pr.variableAmount);
    },
    hasActions() {
      return !!this.selectedOrder?.actions?.length;
    },
    shouldDisplayFooter() {
      return this.hasActions || this.areTaxValuesNumbers;
    },
    defaultDateFilters() {
      return {
        month: {
          from: dayjs(ORDER_DATE_FILTER_DEFAULT.from).valueOf(),
          to: dayjs(ORDER_DATE_FILTER_DEFAULT.to).valueOf(),
        },
        scheduled: {
          from: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.from).valueOf(),
          to: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.to).valueOf(),
        },
      };
    },
    numberOfAppliedFilters() {
      /**
       * Default filters for outlet are valid and we want to enable 'Export Orders'
       */
      const appliedFilters = Object.values(this.initialStatusFilters).filter(
        x => x === null || x === '' || x,
      ).length;

      /**
       * If user selects 'All Time' for 'Delivery Date' from filters dropdown,
       * we want the 'Export Orders' disabled if no other filters are active.
       */
      const isAllTimeScheduled =
        this.initialStatusFilters.scheduled && this.defaultRangeNameScheduled === 'All Time';
      if (isAllTimeScheduled && appliedFilters === 1) return 0;

      return appliedFilters;
    },
  },
  methods: {
    ...mapMutations('entities/orders', [
      'SET_SELECTED_SINGLE_ORDER',
      'UPDATE_SELECTED_SINGLE_ORDER',
      'UPDATE_META',
    ]),
    ...mapActions('entities/orders', [
      'fetchVenueOrder',
      'fetchVenueInvoices',
      'fetchVenueInvoicesHistoryTotal',
      'venueAbandonOrder',
      'venueSaveOrderEdit',
      'exportToAccountingIntegration',
      'bulkExportInvoicesToAccountingIntegration',
    ]),
    ...mapActions('entities/venues', [
      'venueFetchDeliveryDate',
      'venueFetchDistributorFilterOptions',
      'outletDeletePendingInvoice',
      'venueFetchOverviewStatistics',
    ]),
    ...mapActions('entities/users', ['ownerCheck']),
    openDispute() {
      this.$refs.dispute.open();
    },
    openApprove() {
      this.$refs.approve.open();
    },
    openResolve() {
      this.$refs.resolve.open();
    },
    openConfirm() {
      this.$refs.confirm.open();
    },
    openReorder() {
      this.$refs.reorder.open();
    },
    openDecline() {
      this.$refs.decline.open();
    },
    openAbandon() {
      this.$refs.abandon.open();
    },
    openUploadInvoice(type) {
      this.fileType = type;
      this.$refs.uploadInvoice.open();
    },
    async invoicesUploaded() {
      await this.refresh({ filters: { ...this.listFilters, ...this.filters } });
    },
    setRangeToAllTime() {
      // Change the range to all time.
      this.range = {
        start: dayjs('1970-01-02'),
        end: dayjs().endOf('d'),
      };
    },
    setScheduledRangeToAllTime() {
      this.scheduledRange = {
        start: dayjs('1970-01-02'),
        end: dayjs().endOf('d'),
      };
    },
    orderHasAccountingIntegration(order) {
      return (
        this.isVenue &&
        (this.isVenueXeroConnected(order.venueId) || this.isVenueQuickBooksConnected(order.venueId))
      );
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.selectedOrder.copyUrl || '');
      flash.success({
        title: 'Link copied!',
        message: 'The link has been copied successfully.',
      });
    },
    filterApproval() {
      const filters = {
        [INVOICE_PROCESSING_STATUS_ACTION_NEEDED]: true,
      };

      this.updateFilters(filters, true);
    },
    resetDrawerRoute() {
      this.editingMode = false;
      this.$router.replace({ name: 'venue-pending-updates' });
    },
    resetFilters() {
      this.initialStatusFilters = {};
      this.approvalFilterActive = false;
      this.listFilters = {
        invoiceNumber: this.listFilters.invoiceNumber,
      };
      Object.keys(this.filters).forEach(key => {
        this.filters[key] = null;
      });
      delete this.filters.to;
      delete this.filters.from;

      this.range = {
        start: dayjs(ORDER_DATE_FILTER_DEFAULT.from).valueOf(),
        end: dayjs(ORDER_DATE_FILTER_DEFAULT.to).valueOf(),
      };
      this.scheduledRange = {
        start: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.from).valueOf(),
        end: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.to).valueOf(),
      };
      this.defaultRangeName = ORDER_DATE_FILTER_DEFAULT.name;
      this.defaultRangeNameScheduled = ORDER_SCHEDULED_DATE_FILTER_DEFAULT.name;
      localStorage.removeItem('invoiceFilters');

      this.$nextTick(() => this.$refs.filtersGroup.syncLocalFilters());
      this.$emit('updateFilter', this.filters);

      this.orders = [];
      this.refresh({ filters: this.filters });
    },
    updateFilters(filters, approvalFilter = false) {
      this.initialStatusFilters = filters;
      this.approvalFilterActive = approvalFilter;

      const invoiceFiltersKeys = Object.keys(invoiceProcessingStatusLabels); // Invoice processing status labels
      const exportFiltersKeys = Object.keys(invoiceExportStatusLabels); // Invoice processing status labels
      // looking for selected status filters
      const invoiceStatusFilters = [];
      const exportStatusFilters = [];
      Object.keys(filters).forEach(key => {
        if (invoiceFiltersKeys.includes(key) && filters[key]) {
          invoiceStatusFilters.push(key);
          this.listFilters = {
            ...this.listFilters,
            listStatus: key,
          };
        } else if (
          key.includes('export') &&
          exportFiltersKeys.includes(key.slice(7)) &&
          filters[key]
        ) {
          exportStatusFilters.push(key.slice(7));
          this.listFilters = {
            ...this.listFilters,
            listStatus: key,
          };
        }
      });

      const isAllTimeScheduled =
        !!this.defaultRangeNameScheduled && // If default range name is 'All Time'
        this.defaultRangeNameScheduled !== 'All Time';

      this.filters = {
        ...this.filters,
        distributorId: filters.distributorId?.id,
        from: isAllTimeScheduled ? dayjs(this.scheduledRange?.start).valueOf() : null,
        to: isAllTimeScheduled ? dayjs(this.scheduledRange?.end).valueOf() : null,
        processingStatus: [...invoiceStatusFilters],
        exportStatus: [...exportStatusFilters],
      };
      if (filters.distributorId === undefined) delete this.filters.distributorId;

      this.range = {
        start: dayjs(filters.month?.start || this.range?.start).valueOf(),
        end: dayjs(filters.month?.end || this.range?.end).valueOf(),
      };
      this.scheduledRange = {
        start: dayjs(filters.scheduled?.start || this.scheduledRange?.start).valueOf(),
        end: dayjs(filters.scheduled?.end || this.scheduledRange?.end).valueOf(),
      };
      localStorage.setItem(
        'invoiceFilters',
        JSON.stringify({
          filters: this.filters,
          initialStatusFilters: this.initialStatusFilters,
          scheduledRange: this.scheduledRange,
          defaultRangeNameScheduled: this.defaultRangeNameScheduled,
        }),
      );

      this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());
      this.$emit('updateFilter', this.filters);

      this.orders = [];
      this.refresh({ filters: this.filters });
    },
    resetListFilters() {
      this.listFilters = {};
      this.listFilters.invoiceNumber = null;
      delete this.filters.to;
      delete this.filters.from;
      delete this.filters.event;
      delete this.filters.listStatus;
      delete this.filters.processingStatus;
      delete this.filters.exportStatus;
      this.initialStatusFilters = {};
      this.initialStatusFilters = this.filters;
      this.scheduledRange = {
        start: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.from).valueOf(),
        end: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.to).valueOf(),
      };
      this.defaultRangeNameScheduled = ORDER_SCHEDULED_DATE_FILTER_DEFAULT.name;
      this.filters = {
        ...this.filters,
        ...this.listFilters,
      };
      localStorage.setItem(
        'invoiceFilters',
        JSON.stringify({
          filters: this.filters,
          initialStatusFilters: this.initialStatusFilters,
          scheduledRange: this.scheduledRange,
          defaultRangeNameScheduled: this.defaultRangeNameScheduled,
        }),
      );
      this.$emit('updateFilter', this.filters);

      this.orders = [];
      this.refresh({ filters: this.filters });
    },
    updateListFilters: debounce(function deb(name, event) {
      if (typeof event !== 'object' && this.listFilters.invoiceNumber === event) return;
      if (typeof event !== 'object') this.listFilters.invoiceNumber = event;
      else if (Object.keys(event).length === 0) {
        if (this.listFilters.event === undefined) this.resetListFilters();
        else {
          delete this.listFilters.listStatus;
          delete this.filters.listStatus;
          delete this.filters.processingStatus;
          delete this.filters.exportStatus;
          this.initialStatusFilters = {};
          this.initialStatusFilters = this.filters;
          delete this.initialStatusFilters.scheduledTo;
          delete this.initialStatusFilters.scheduledFrom;
          delete this.initialStatusFilters.to;
          delete this.initialStatusFilters.from;
          this.filters = {
            ...this.filters,
            ...this.listFilters,
          };
          this.$emit('updateFilter', this.filters);

          this.orders = [];
          this.refresh({ filters: this.filters });
        }
        return;
      } else {
        this.listFilters = {
          ...this.listFilters,
          ...(event.id ? { listStatus: event.id } : { event }),
        };
        if (event.id) {
          this.invoiceStatusFilter.forEach(item => delete this.initialStatusFilters[item.id]);
          this.exportStatusFilter.forEach(item => delete this.initialStatusFilters[item.id]);
        }
        this.initialStatusFilters = {
          ...this.initialStatusFilters,
          ...(event.id ? { [event.id]: true } : { event }),
        };
        const invoiceFiltersKeys = Object.keys(invoiceProcessingStatusLabels); // Invoice processing status labels
        const exportFiltersKeys = Object.keys(invoiceExportStatusLabels); // Invoice processing status labels
        // looking for selected status filters
        const invoiceStatusFilters = [];
        const exportStatusFilters = [];
        if (event.id?.includes('export') && exportFiltersKeys.includes(event.id.slice(7))) {
          exportStatusFilters.push(event.id.slice(7));
        } else if (invoiceFiltersKeys.includes(event.id)) {
          invoiceStatusFilters.push(event.id);
        }
        const isAllTimeScheduled =
          !!this.defaultRangeNameScheduled && // If default range name is 'All Time'
          this.defaultRangeNameScheduled !== 'All Time';
        this.filters = {
          ...this.filters,
          from: isAllTimeScheduled ? dayjs(this.scheduledRange?.start).valueOf() : null,
          to: isAllTimeScheduled ? dayjs(this.scheduledRange?.end).valueOf() : null,
          ...(event.id ? { processingStatus: [...invoiceStatusFilters] } : {}),
          ...(event.id ? { exportStatus: [...exportStatusFilters] } : {}),
        };
        this.scheduledRange = {
          start: dayjs(event.scheduled?.start || this.scheduledRange?.start).valueOf(),
          end: dayjs(event.scheduled?.end || this.scheduledRange?.end).valueOf(),
        };
      }

      this.filters = {
        ...this.filters,
        ...this.listFilters,
      };

      localStorage.setItem(
        'invoiceFilters',
        JSON.stringify({
          filters: this.filters,
          initialStatusFilters: this.initialStatusFilters,
          scheduledRange: this.scheduledRange,
          defaultRangeNameScheduled: this.defaultRangeNameScheduled,
        }),
      );

      this.orders = [];
      this.refresh({ filters: this.filters });
    }, 300),
    filterClosed() {
      if (!this.filters.from && !this.filters.to) {
        this.defaultRangeName = ORDER_DATE_FILTER_DEFAULT.name;
      }
      this.resetDateLabelsIfNeeded();
      this.resetDateFiltersIfNeeded();
    },
    resetDateLabelsIfNeeded() {
      const { defaultRangeNameScheduled } = this.prevDatesLabels;
      const sameDeliveryLabel = defaultRangeNameScheduled === this.defaultRangeNameScheduled;

      if (!sameDeliveryLabel) this.changeScheduledRangeName(defaultRangeNameScheduled);
    },
    resetDateFiltersIfNeeded() {
      const { month, scheduled } = this.initialStatusFilters;

      if (month) {
        const sameOrderDate = month.start === this.range.start && month.end === this.range.end;
        if (!sameOrderDate) this.range = { ...month };
      }

      if (scheduled) {
        const sameScheduledDate =
          scheduled.start === this.scheduledRange.start &&
          scheduled.end === this.scheduledRange.end;
        if (!sameScheduledDate) this.scheduledRange = { ...scheduled };
      }
    },
    changeScheduledRangeName(rangeName) {
      this.defaultRangeNameScheduled = rangeName;
    },
    openOrderDrawer() {
      this.$refs.orderDrawer.open();
    },
    async closeOrderDrawerAndRefresh() {
      this.orders = [];
      await this.refresh({ filters: { ...this.listFilters, ...this.filters } });
      this.closeOrderDrawer();
    },
    closeOrderDrawer() {
      this.$refs.orderDrawer.close();
    },
    closeAbandonModal() {
      this.$refs.abandon.close();
    },
    async confirmAbandonOrder() {
      await this.venueAbandonOrder({
        orderId: this.selectedOrder.id,
        venueId: this.selectedOrder.venueId ?? this.contextId,
        cancelNote: this.cancelNote,
      });

      this.orders = [];
      await this.refresh({ filters: { ...this.listFilters, ...this.filters } });

      this.closeAbandonModal();
      this.closeOrderDrawer();

      const { href } = this.$router.resolve({
        name: 'venue-pending-updates',
        params: { id: this.selectedOrder.id },
      });
      flash.success({
        title: 'Order successfully cancelled.',
        message: 'You can see the order overview in Order Details.',
        ctas: `<a href="${href}">View Order Details</a>`,
      });
    },
    async openDrawerAndLoadOrder({ id, noteKey, completedOrderId, processingStatus }) {
      if (processingStatus === INVOICE_PROCESSING_STATUS_PROCESSED) {
        try {
          const { data } = await this.fetchVenueOrder({ id: completedOrderId });
          this.originalSelectedOrder = data.data;

          this.$router.push({
            name: 'venue-pending-updates',
            params: { id: completedOrderId },
          });

          this.openOrderDrawer();
        } catch (e) {
          this.resetDrawerRoute();
        }
      } else if (
        processingStatus === INVOICE_PROCESSING_STATUS_ACTION_NEEDED ||
        processingStatus === INVOICE_PROCESSING_STATUS_DRAFT ||
        processingStatus === INVOICE_PROCESSING_STATUS_PROCESSING
      ) {
        let routeData;
        if (noteKey === 'missing_page') {
          routeData = this.$router.resolve({
            name: 'buyer-orders-merge-pending-update',
            params: { id },
          });
        } else {
          routeData = this.$router.resolve({
            name: 'buyer-orders-new-pending-update',
            params: { id },
          });
        }
        window.open(routeData.href, '_blank');
      }
    },
    async loadDrawerOrder({ id }) {
      const token = this.$route.query?.token;
      if (token) {
        try {
          const { data: accountOwnerData } = await this.ownerCheck({
            data: {
              entity: 'order',
              entityId: id,
              scope: 'venue',
            },
          });
          if (accountOwnerData.data.id === this.loggedUser.id) {
            const { data } = await this.fetchVenueOrder({ id });
            const orderIndex = this.orders.findIndex(item => item.id === id);
            if (orderIndex !== -1) {
              this.orders[orderIndex].exportStatus = data.data.exportStatus;
            }
            this.originalSelectedOrder = data.data;

            this.openOrderDrawer();
          } else {
            this.isSwitchingAccount = true;
            const { data } = await httpService.put(`/accounts/switch/${accountOwnerData.data.id}`);
            const { platform } = data.data;
            if (process.env.VUE_APP_PLATFORM_TITLE.toLowerCase() === platform) {
              await httpService.delete('/manager/oauth');
              localStorage.clear();
              resetState();
            }
            this.isSwitchingAccount = false;
            window.location.href = `${data.data.url}/login?token=${data.data.token}&redirectTo=venue-pending-updates&orderId=${id}`;
          }
        } catch (e) {
          this.resetDrawerRoute();
        }
      } else {
        try {
          const { data } = await this.fetchVenueOrder({ id });
          this.originalSelectedOrder = data.data;
          const orderIndex = this.orders.findIndex(item => item.id === id);
          if (orderIndex !== -1) {
            this.orders[orderIndex].exportStatus = data.data.exportStatus;
          }

          this.openOrderDrawer();
        } catch (e) {
          this.resetDrawerRoute();
        }
      }
    },
    editedProduct() {
      this.loadDrawerOrder({ id: this.orderId });
    },
    onEditInvoiceDate() {
      this.$refs.invoiceDateModal.open();
    },
    onEditInvoiceNumber() {
      this.$refs.invoiceNumberEditModal.open();
    },
    saveDeliveryOnDate() {
      this.UPDATE_SELECTED_SINGLE_ORDER({
        ...this.selectedOrder,
        invoiceDate: dayjs(this.deliveryOn.format(DATE_INPUT_FORMAT)).format('YYYY-MM-DD'),
        isInvoiceDateEdited: true,
      });
      this.$refs.invoiceDateModal.close();
    },
    updateDeliveryOn(date) {
      this.deliveryOn = date;
    },
    closeDeliveryOnModal() {
      this.$refs.invoiceDateModal.close();
    },
    saveInvoiceNumber() {
      this.UPDATE_SELECTED_SINGLE_ORDER({
        ...this.selectedOrder,
        invoiceNumber: this.invoiceNumber,
        isInvoiceNumberEdited: true,
      });
      this.$refs.invoiceNumberEditModal.close();
    },
    closeInvoiceNumberModal() {
      this.$refs.invoiceNumberEditModal.close();
      this.invoiceNumber = null;
    },
    async onLoadMoreClick() {
      await this.refresh(
        {
          filters: this.filters,
          page: this.meta.page + 1,
        },
        LOADING_KEY.FETCH_MORE_ORDERS,
      );
    },
    showDistributorInfo() {
      this.$refs.infoModal.open();
    },
    async createBill() {
      this.loading = true;
      try {
        await venueXeroCreateBill(this.selectedOrder.venueId ?? this.contextId, this.orderId);
        flash.success({
          title: 'Bill successfully created!',
        });
        this.loadDrawerOrder({ id: this.orderId });
        this.loading = false;
      } catch (e) {
        this.loading = false;
        flash.error({
          title: e.response.data.error.message || 'Something went wrong!',
        });
      }
    },
    async refresh(query = {}, loadingKey = LOADING_KEY.FETCH_ORDERS) {
      this.exportStarted = false;
      const { data } = await this.fetchVenueInvoices({
        ...query,
        loadingKey,
      });
      if (loadingKey === LOADING_KEY.FETCH_ORDERS) {
        this.orders = data;
        const { data: total } = await this.fetchVenueInvoicesHistoryTotal({
          query: {
            from: dayjs().startOf('M').valueOf(),
            to: dayjs().endOf('M').valueOf(),
            ...query.filters,
          } ?? {
            from: dayjs().startOf('M').valueOf(),
            to: dayjs().endOf('M').valueOf(),
          },
        });
        this.UPDATE_META({ meta: { ...this.meta, totalAmount: total.data } });
      } else {
        this.orders = [...this.orders, ...data];
      }
    },
    uploadedInvoice() {
      this.loadDrawerOrder({ id: this.orderId });
    },
    edit() {
      this.editingMode = true;
    },
    async saveEdit() {
      try {
        this.loading = true;
        if (!this.canEditDeliveryFee) delete this.singleOrder.deliveryFee;

        await this.venueSaveOrderEdit({
          order: this.singleOrder,
          venueId: this.singleOrder.venueId ?? this.contextId,
        });
        this.orders = [];
        await this.refresh({ filters: { ...this.listFilters, ...this.filters } });
        this.editingMode = false;

        const message = `You have successfully edited the order. ${this.$t(
          'global.distributor',
        )} will be notified about the change.`;
        flash.success({
          title: 'Order successfully edited.',
          ...(!this.isVenuePremium ? { message } : {}),
        });
      } finally {
        this.loading = false;
      }
    },
    discardEdit() {
      this.editingMode = false;
      this.SET_SELECTED_SINGLE_ORDER(this.originalSelectedOrder);
    },
    async fetchDistributors() {
      const { data } = await this.venueFetchDistributorFilterOptions({
        venueId: this.contextId,
        query: { for: 'orders' },
      });
      this.distributors = data.data;
    },
    async fetchStats() {
      const { data } = await this.venueFetchOverviewStatistics();
      this.stats = data.data;
    },
    async onIntegrationWarningCTA(url) {
      this.loading = true;

      try {
        await httpService.put(url);
        await this.loadDrawerOrder({ id: this.orderId });

        this.loading = false;
        flash.success({ title: 'Action completed successfully!' });
      } catch (e) {
        this.loading = false;
        // eslint-disable-next-line no-console
        console.error(e);
        flash.error({
          title: 'Something went wrong!',
          message: e.response.data.error.message,
        });
      }
    },
    async exportOrder(id) {
      try {
        const orderIndex = this.orders.findIndex(item => item.completedOrderId === id);
        if (orderIndex !== -1) {
          this.orders[orderIndex].exportStatus = 'loading';
        }
        await this.exportToAccountingIntegration({
          venueId: this.orders[orderIndex].venue.id ?? this.contextId,
          orderId: this.orders[orderIndex].completedOrderId,
        });
        if (orderIndex !== -1) {
          this.orders[orderIndex].exportStatus = 'completed';
        }
        flash.success({
          title: 'Order successfully exported!',
        });
      } catch (e) {
        if (e.response.status === 400) {
          const orderIndex = this.orders.findIndex(item => item.completedOrderId === id);
          if (orderIndex !== -1) {
            this.orders[orderIndex].exportStatus = 'error';
          }
        }
      }
    },
    async exportSingleOrder() {
      this.exportOrder(this.selectedOrder.id);
    },
    async exportAllOrders() {
      try {
        const { distributorId, from, to, processingStatus, exportStatus } = {
          ...this.listFilters,
          ...this.filters,
        };
        const queryParams = [
          ...(from ? [`from=${from}`] : []),
          ...(to ? [`to=${to}`] : []),
          ...(distributorId ? [`distributorId=${distributorId}`] : []),
          ...(processingStatus ? processingStatus.map(st => `processingStatus[]=${st}`) : []),
          ...(exportStatus ? exportStatus.map(st => `exportStatus[]=${st}`) : []),
          ...(distributorId ? [`distributorId=${distributorId}`] : []),
        ].join('&');
        this.exportStarted = true;
        await this.bulkExportInvoicesToAccountingIntegration({
          query: queryParams,
        });
        flash.success({
          title: 'The export has started.',
          message: 'You will receive a notification when it is complete.',
        });
      } catch (e) {
        flash.error({ title: e.response.data.error.message || 'Something went wrong!' });
      }
    },
    async openAccountingIntegration(order) {
      if (this.isVenue) {
        const routerData = this.$router.resolve({
          name: 'venue-all-suppliers-products',
          query: {
            productGroup: 'unassigned',
            supplierId: order.distributor.id,
            orderId: order.completedOrderId,
          },
        });
        window.open(routerData.href, '_blank');
      }
    },
    onRemovePendingUpdate(pendingUpdate) {
      this.selectedForDelete = pendingUpdate;
      this.openDeleteModal();
    },
    openDeleteModal() {
      this.$refs.deleteModal.open();
    },
    closeDeleteModal() {
      this.$refs.deleteModal.close();
      this.selectedForDelete = null;
    },
    async deletePendingUpdate() {
      try {
        const { id } = this.selectedForDelete;
        await this.outletDeletePendingInvoice({
          venueId: this.contextId,
          pendingId: id,
        });
        await this.refresh({ filters: { ...this.listFilters, ...this.filters } });
        this.closeDeleteModal();
        flash.success({
          title: 'Invoice successfully deleted!',
        });
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
  },
  watch: {
    originalSelectedOrder(newVal) {
      this.SET_SELECTED_SINGLE_ORDER(newVal);
    },
    selectedOrder(order) {
      if (!order.invoiceDate) {
        return;
      }
      this.deliveryOn = dayjs(order.invoiceDate, 'YYYY-MM-DD');
      this.deliveryOn = dayjs(this.deliveryOn).local();
    },
    filters: {
      immediate: true,
      handler() {
        this.prevDatesLabels = {
          defaultRangeName: this.defaultRangeName,
          defaultRangeNameScheduled: this.defaultRangeNameScheduled,
        };
      },
    },
  },
  async created() {
    if (localStorage.getItem('invoiceFilters')) {
      this.filters = JSON.parse(localStorage.getItem('invoiceFilters'))?.filters;
      this.initialStatusFilters = JSON.parse(
        localStorage.getItem('invoiceFilters'),
      )?.initialStatusFilters;
      (this.filters.processingStatus ?? []).forEach(item => {
        this.listFilters.listStatus = item;
      });
      if (this.filters.invoiceNumber) this.listFilters.invoiceNumber = this.filters.invoiceNumber;
      (this.filters.exportStatus ?? []).forEach(item => {
        this.listFilters.listStatus = `export_${item}`;
      });
      this.scheduledRange = JSON.parse(localStorage.getItem('invoiceFilters'))?.scheduledRange;
      this.defaultRangeNameScheduled = JSON.parse(
        localStorage.getItem('invoiceFilters'),
      )?.defaultRangeNameScheduled;
      if (!this.filters.processingStatus?.length && !this.filters.exportStatus?.length)
        delete this.listFilters.listStatus;
    }
    if (this.$route.query.processingStatus && this.$route.query.processingStatus.length) {
      if (this.$route.query.processingStatus.isArray) {
        this.$route.query.processingStatus.forEach(item => {
          this.initialStatusFilters = {};
          this.initialStatusFilters[item] = true;
          this.listFilters.listStatus = item;
        });
        this.filters.processingStatus = this.this.$route.query.status;
      } else {
        this.initialStatusFilters = {};
        this.initialStatusFilters[this.$route.query.processingStatus] = true;
        this.filters.processingStatus = [this.$route.query.processingStatus];
        this.listFilters.listStatus = this.$route.query.processingStatus;
      }
    }
    if (this.$route.query.rangeName === 'All Time') {
      this.scheduledRange = {};
      this.defaultRangeNameScheduled = this.$route.query.rangeName;

      this.filters = {
        ...this.filters,
        from: null,
        to: null,
      };

      this.initialStatusFilters = {
        ...this.initialStatusFilters,
        scheduled: {
          start: this.filters.from,
          end: this.filters.to,
        },
      };
    }
    await Promise.all([
      // Initial filters that should be applied.
      this.refresh({ filters: { ...this.listFilters, ...this.filters } }),
      this.fetchDistributors(),
      this.fetchStats(),
    ]);
    if (this.orderId) await this.loadDrawerOrder({ id: this.orderId });
  },
};
</script>

<style scoped lang="scss">
.filters-grp {
  display: flex;
}
:deep() .loader {
  @extend %whole-screen;
}

:deep() .drawer {
  @extend %order-drawer;
}

:deep() .icon-text {
  color: inherit;
}

:deep() .select-search {
  color: $color-gray-6C;

  &__list {
    max-height: 244px;
  }
}

.orders__alert-retry {
  padding: 0;
  height: 25px;
}

.orders__alert-retry-group {
  & + & {
    margin-left: 25px;
  }
}
.info-message-wrapper {
  padding: 0 24px 0;
  margin-bottom: px-to-rem(16px);
  display: flex;
  justify-content: flex-start;
  .info {
    padding: 12px;
    background-color: $yellow-bg;
    border-radius: 3px;
    display: flex;
    width: 100%;
    @include font-size(14px);
    .column-left {
      margin-right: 10px;
      color: $color-primary-orange;
    }
    .column-right {
      color: $color-gray-6C;
      .create-invoice {
        padding: 0;
        height: 25px;
        &--disabled {
          color: $color-primary-blue;
          cursor: not-allowed;
        }
      }
    }
  }
}

.orders-actions {
  display: flex;
  flex-shrink: 0;
}

.export-btn {
  :deep() .ez-simple-dropdown {
    &__label {
      color: $color-gray-6C;
    }
  }
}

.calendar {
  :deep() .date-picker {
    .placeholder {
      color: $color-gray-6C;
    }
  }
}

.search {
  min-width: 168px;
}

.log-offline {
  &--custom-width {
    :deep() .button-dropdown {
      &__toggle-container {
        .button span {
          margin-right: 0 !important;
          padding-right: 15px;
        }
      }

      &__dropdown {
        width: 280px;
      }
    }
  }
  .upgrade-premium {
    width: 392px;
  }
}

.offline-order-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px 15px;
  color: $color-gray-25;
  @include font-size(14px, 24px);
  cursor: pointer;
  &:hover {
    background-color: #f5f6fa;
  }
  &__icon {
    color: $color-gray-6C;
  }
  &__info {
    &__text {
      color: $color-gray-6C;
      @include font-size(12px, 18px);
      margin: 0;
    }
  }
}
.entity-info-custom {
  :deep() .entity-info {
    &__text {
      max-width: 80%;
    }
  }
}
.calendar {
  :deep() .date-picker {
    .ez-simple-dropdown__display-container {
      width: 100%;
      .placeholder {
        width: 100%;
        color: $color-gray-6C;
      }
    }
  }
}
.select-supplier {
  :deep() .select-search__trigger {
    width: 100%;
  }
}
.status-checkbox {
  @include font-size(14px, 20px);
}

:deep() .export-button.ez-simple-dropdown {
  width: fit-content;
}

:deep() .export-button .ez-simple-dropdown__dropdown {
  min-width: max-content;
}

:deep() .ez-filter-list .ez-filter-list__items .ez-dropdown {
  width: auto;
  min-width: 128px;
  .ez-dropdown__options ul {
    min-width: 100%;
    width: max-content;
  }
}
:deep() .calendar .date-picker .placeholder {
  min-width: 128px;
}

.products-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__main {
    h1 {
      @include font-size(24px);
      margin: 0;
      font-weight: 500;
    }
    > span {
      @include font-size(12px);
      color: $color-gray-6C;
    }
  }
  &__valuation-wrapper {
    display: flex;
  }

  &__valuation {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      padding-left: 16px;
      border-left: 1px solid #eceef5;
    }

    &:not(:last-child) {
      padding-right: 16px;
    }

    &__amount-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
      margin-right: 12px;

      small {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.25px;
        color: #6c7995;
      }
    }

    &__amount {
      @include font-size(20px, 28px);
      color: $color-gray-25;
      font-weight: 600;
    }

    &__icon {
      @extend %flex-center;
      justify-content: center;
      color: $color-white;
      background-color: $color-pastel-green;
      width: 40px;
      height: 40px;
      border-radius: 50%;

      &__inner {
        @include font-size(11px);
        @extend %flex-center;
        justify-content: center;
        background-color: $color-primary-green;
        border-radius: 50%;
        width: 30px;
        height: 30px;
      }
    }
  }
}

:deep() .table {
  &.table--remove-button tbody tr td {
    height: 56px;
    line-height: 1.2;
  }
  &.table--remove-button tbody tr:hover {
    td:last-child span {
      height: 0;
    }
  }
  tbody tr td.distributor-cell {
    line-height: 1.2;
  }
}

:deep() .ez-dropdown .ez-dropdown__options div:not(.ez-dropdown__item) .ez-dropdown__item-label {
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: $color-gray-6C;
  font-weight: 600;
  margin: 4px 8px 0px 14px;
  text-transform: uppercase;
}

:deep() .button.button--red-link {
  background: white;
}

.dropdown-button {
  :deep() .button.button--primary {
    padding-right: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: initial;
    display: inline-block;
  }
  :deep() .button-dropdown.button-dropdown--primary {
    padding-left: 0px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .button.button--primary {
      padding-left: 6px;
      padding-right: 12px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      margin-left: 0;
    }
    .button.button--secondary {
      min-width: initial;
      margin-left: 0;
      justify-content: flex-start;
      border: none;
      background-color: #fff;

      &:hover {
        background-color: #f5f6fa;
      }
    }
  }
}
</style>
