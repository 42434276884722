<template>
  <div :class="['input-group', 'ez-radio-button', { disabled }]">
    <label
      :for="id"
      :class="[
        'ez-radio-button__label-container',
        {'ez-radio-button__label-container--right': labelPosition === 'right'},
        {'ez-radio-button__label-container--left': labelPosition === 'left'},
      ]"
      :data-cy="dataCy"
    >
      <input
        type="radio"
        :id="id"
        :value="value"
        :name="name"
        :disabled="disabled"
        v-model="internalData"
        @change="onChange"
      >
      <span class="ez-radio-button__label">{{ label }}</span>
      <span class="ez-radio-button__indicator"></span>
    </label>
  </div>
</template>

<script>
import uuid from 'uuid/v4';

export default {
  props: {
    formKey: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
    },
    labelPosition: {
      required: false,
      default: 'right',
      type: String,
      validator: value => ['left', 'right'].includes(value),
    },
    value: {
      type: [String, Number],
      required: false,
      default: '',
    },
    data: {
      type: [String, Number, Boolean],
      required: true,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      id: '',
      internalData: this.data,
    };
  },
  watch: {
    data(newVal) {
      this.internalData = newVal;
    },
  },
  methods: {
    onChange($event) {
      this.$emit('change', $event.target.value);
    },
  },

  mounted() {
    this.id = uuid();
  },
};
</script>

<style scoped lang="scss">
$input-border-color: #DEE1E4;

.ez-radio-button {
  @extend %flex-center;

  &.disabled {
    .ez-radio-button {
      &__indicator {
        opacity: 0.5;
      }

      &__label-container {
        cursor: not-allowed;
      }
    }
  }

  &__label {
    @extend %gray-label;
    @include font-size(14px, 16px);
  }
  &__label-container {
    @extend %flex-center;
    &--left {
      .ez-radio-button__label {
        margin-right: .375rem;
      }
    }
    &--right {
      flex-direction: row-reverse;
      .ez-radio-button__label {
        margin-left: .375rem;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
  input[type='radio'] {
    margin: 0;
    display: none;
    position: absolute;
    left: -999999;
    ~ .ez-radio-button__indicator {
      background-color: $color-white;
      border: 1px solid $input-border-color;
      border-radius: 50%;
      display: inline-block;
      width: .875rem;
      height: .875rem;
      position: relative;
    }
    &:checked {
      ~ .ez-radio-button__label {
        color: $color-gray-25;
      }
      ~ .ez-radio-button__indicator {
        &:after {
          position: absolute;
          content: '';
          width: .5em;
          height: .5em;
          border-radius: 50%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: $color-primary-blue;
        }
      }
    }
  }
}
</style>
