<script>
import EzButton from '@/components/ui/Button/EzButton';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 *
 * We use this component only to render group of buttons in ButtonActions component, this component
 * use props to define the type of button and copy txt, also emit event when the button is clicked.
 */
export default {
  components: {
    EzButton,
  },
  props: {
    disabled: {
      required: false,
      type: Boolean,
    },
    type: {
      required: false,
      type: String,
      default: 'primary',
    },
  },
};
</script>
<template>
  <ez-button
    class="dropdown-actions__btn"
    :type="type"
    @click="$emit('actionClicked')"
  >
    <slot></slot>
  </ez-button>
</template>
<style lang="scss" scoped></style>
