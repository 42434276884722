<script>
import { getCurrency } from '@/util/utils';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput';
import AddUnitModal from '@/components/v3/patterns/VUnitsSettings/AddUnitModal';
import RemoveUnitModal from '@/components/v3/patterns/VUnitsSettings/RemoveUnitModal';
import VSelectSearchUnit from '@/components/v3/patterns/VSelectSearchUnit';
import { LOADING_KEY, UNIT_TYPE_FRACTIONAL, UNIT_TYPE_PACK } from '@/util/constants';
import { mapGetters, mapMutations } from 'vuex';
import EzLoader from '@/components/ui/Loader/EzLoader';

/**
 *
 * @version 1.0.0
 * @since 3.13.1
 */
export default {
  name: 'index',
  components: {
    EzCheckbox,
    EzMaskInput,
    AddUnitModal,
    RemoveUnitModal,
    VSelectSearchUnit,
    EzLoader,
  },
  props: {
    product: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    formKey: {
      type: String,
      required: true,
      default: '',
    },
    units: {
      type: Array,
      required: true,
      default: () => [],
    },
    allowNegativePrice: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCogs: {
      type: Boolean,
      required: false,
      default: true,
    },
    dataCy: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      marketPrice: false,
      unitToDelete: {},
      unitToUpdate: {},
      isUnitDiff: false,
      selectedUnit: {},
      selectedPriceUnit: {},
      quantityIncrement: 1,
      customizable: false,
      price: 0,
      cogs: 0,
      minimumQuantity: 1,
      returnToDefault: false,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    ...mapGetters('entities/users', ['isDistributor']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_CREATE_UNIT,
        LOADING_KEY.DISTRIBUTOR_DELETE_UNIT,
        LOADING_KEY.DISTRIBUTOR_UPDATE_UNIT,
      ]);
    },
    currencySymbol() {
      const { currency } = this.product || {};
      const { symbol } = currency || getCurrency() || {};
      return symbol;
    },
    isTradeGecko() {
      return (this.product?.importType === 'trade_gecko') && this.isTradeGeckoConnected;
    },
    canEditPrice() {
      return this.$permission.has('editProductPricing');
    },
    tooltipMp() {
      return {
        html: true,
        classes: ['tooltip-general'],
        content: 'Used for products whose price depends on the current price on the market.',
      };
    },
    tooltipPriceUnitDiff() {
      return {
        html: true,
        classes: ['tooltip-general'],
        content: 'Example: Product is ordered by piece, price formed by pound.',
      };
    },
    fractionalUnits() {
      return this.units.filter(un => un.type === UNIT_TYPE_FRACTIONAL);
    },
    isDecimalValues() {
      return this.isFractionalSelected ? 2 : 0;
    },
    isFractionalSelected() {
      return this.selectedUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    isPackUnitSelected() {
      return this.selectedUnit?.type === UNIT_TYPE_PACK;
    },
    data() {
      return {
        ...(this.price >= 0 && { price: this.price }),
        ...((this.cogs >= 0 && this.isDistributor) && { cogs: this.cogs }),
        ...{ marketPrice: this.marketPrice },
        ...(this.selectedUnit && { orderingUnitId: this.selectedUnit?.id }),
        ...{ priceUnitId: this.selectedPriceUnit?.id || this.selectedUnit?.id },
        ...((!this.isPackUnitSelected && this.quantityIncrement) && { orderingUnitIncrement: this.quantityIncrement }),
        ...((!this.isPackUnitSelected && this.minimumQuantity) && { minimumQuantity: this.minimumQuantity }),
      };
    },
    selectedUnitId() {
      return this.selectedUnit?.id;
    },
    selectedPriceUnitId() {
      return this.selectedPriceUnit?.id;
    },
    isPriceDisabled() {
      return (this.isDistributor ? (this.isTradeGecko || !this.canEditPrice) : false) || this.marketPrice;
    },
    hasDataCy() {
      return !!Object.keys(this.dataCy).length;
    },
  },
  methods: {
    ...mapMutations('errors', [
      'CLEAR_ERRORS',
    ]),
    reset() {
      this.$refs.chooseUnit.reset();
      this.marketPrice = false;
      this.isUnitDiff = false;
      this.selectedUnit = {};
      this.selectedPriceUnit = {};
      this.quantityIncrement = 1;
      this.price = 0;
      this.minimumQuantity = 1;
      this.CLEAR_ERRORS({ key: this.formKey });
    },
    onMarketPriceChange(val) {
      this.marketPrice = val;
      this.updateUnits();
    },
    onUnitDiff(val) {
      this.isUnitDiff = val;
      this.selectedPriceUnit = !val ? this.selectedUnit : {};
      this.updateUnits();
    },
    openUnitModal() {
      this.$refs.addUnitModal.open();
    },
    editUnit(unit) {
      if (!unit.owned) return;
      this.unitToUpdate = unit;
      this.openUnitModal();
    },
    onUnitModalClose() {
      this.unitToUpdate = {};
    },
    deleteUnit(unit) {
      this.unitToDelete = unit;
      this.$refs.removeUnit.open();
    },
    onRemoveClose() {
      if (this.selectedUnit?.id === this.unitToDelete?.id) {
        this.$refs.chooseUnit.reset();
        this.preSelectData(this.product);
        this.updateUnits();
      }
      this.unitToDelete = {};
    },
    selectUnit(unit) {
      this.selectedUnit = unit;
      if (this.isFractionalSelected) {
        this.quantityIncrement = 0.01;
        this.minimumQuantity = 0.01;
      } else {
        this.quantityIncrement = 1.00;
        this.minimumQuantity = 1.00;
      }
      if (!this.isUnitDiff) this.selectedPriceUnit = this.selectedUnit;
      this.updateUnits();
    },
    selectPriceUnit(unit) {
      this.selectedPriceUnit = unit;
      this.updateUnits();
    },
    updatePrice(val) {
      this.price = val;
      this.updateUnits();
    },
    updateCogs(val) {
      this.cogs = val;
      this.updateUnits();
    },
    onQuantityIncrementChange(val) {
      this.quantityIncrement = val;
      this.updateUnits();
    },
    onMinimumQuantityChange(val) {
      this.minimumQuantity = Number(val);
      this.updateUnits();
    },
    updateUnits() {
      this.$emit('unitsUpdated', this.data);
    },
    onUnitChange() {
      this.$emit('onUnitChange');
    },
    preSelectData(product) {
      this.price = product?.price || null;
      this.cogs = product?.cogs || null;
      this.marketPrice = product?.marketPrice || false;
      this.selectedUnit = product?.orderingUnit || {};
      this.selectedPriceUnit = product?.priceUnit || null;
      if (this.selectedPriceUnit) {
        this.isUnitDiff = true;
      }
      this.minimumQuantity = product?.minimumQuantity || 1;
      this.quantityIncrement = product?.orderingUnitIncrement || 1;
    },
    dataCyAttr(property) {
      if (!this.hasDataCy) return undefined;
      if (!Object.prototype.hasOwnProperty.call(this.dataCy, property)) return undefined;
      return this.dataCy[property];
    },
  },
  watch: {
    data(val) {
      const {
        price,
        orderingUnitId,
        orderingUnitIncrement,
        minimumQuantity,
        priceUnitId,
      } = val;

      const isRealDiffSelected = priceUnitId !== this.selectedUnit.id;
      if (
        (!this.isPriceDisabled && price < 0)
        || !orderingUnitId
        || (!this.isPackUnitSelected && !orderingUnitIncrement)
        || (this.isUnitDiff && !isRealDiffSelected)
        || (!this.isPackUnitSelected && !minimumQuantity)
      ) {
        this.$emit('disabled', true);
      } else {
        this.$emit('disabled', false);
      }
    },
    async product(val) {
      await this.$nextTick(() => {
        this.preSelectData(val);
      });
    },
  },
  created() {
    this.preSelectData(this.product);
  },
  beforeDestroy() {
    this.reset();
  },
};
</script>

<template>
  <div class="price-unit">
    <p class="section-title">Price & Unit</p>
    <ez-mask-input
      :form-key="formKey"
      name="price"
      label="Price*"
      class="mask-input"
      :value="price || 0"
      type="input"
      @input="updatePrice"
      :disabled="isPriceDisabled"
      :allow-negative-value="allowNegativePrice"
      :data-cy="dataCyAttr('price')"
    />
    <div class="market-price mt-12">
      <ez-checkbox
        :form-key="formKey"
        name="marketPrice"
        label="Market Price"
        @change="onMarketPriceChange"
        :checked="marketPrice"
        :data-cy="dataCyAttr('marketPrice')"
      />
      <font-awesome-icon
        class="info-circle cursor-help ml-8"
        icon="info-circle"
        v-tooltip="tooltipMp"
      />
    </div>
    <ez-mask-input
      v-if="isDistributor && showCogs"
      :form-key="formKey"
      name="cogs"
      label="Cost of Goods Sold (COGS)"
      class="mask-input mt-12"
      :value="cogs || 0"
      type="input"
      @input="updateCogs"
      :allow-negative-value="allowNegativePrice"
      :data-cy="dataCyAttr('cogs')"
    />
    <v-select-search-unit
      ref="chooseUnit"
      :data="units"
      label="Sales Unit*"
      placeholder="Choose Unit"
      name="orderingUnitId"
      search-property="label"
      has-actions
      @onEdit="editUnit"
      @onRemove="deleteUnit"
      @onAddNew="openUnitModal"
      @onSelect="selectUnit"
      :selected-id="selectedUnitId"
      :data-cy="dataCyAttr('orderingUnitId')"
    />
    <div class="market-price mt-12">
      <ez-checkbox
        :form-key="formKey"
        name="priceUnit"
        label="Price unit different from Sales unit"
        @change="onUnitDiff"
        :checked="isUnitDiff"
        :data-cy="dataCyAttr('priceUnit')"
      />
      <font-awesome-icon
        class="info-circle cursor-help ml-8"
        icon="info-circle"
        v-tooltip="tooltipPriceUnitDiff"
      />
    </div>
    <v-select-search-unit
      v-if="isUnitDiff"
      :data="fractionalUnits"
      label="Price Unit*"
      placeholder="Choose Unit"
      name="priceUnitId"
      search-property="label"
      :selected-id="selectedPriceUnitId"
      @onSelect="selectPriceUnit"
      :data-cy="dataCyAttr('priceUnitId')"
    />
    <template v-if="!isPackUnitSelected">
      <ez-mask-input
        ref="quantityIncrementDecimal"
        label="Quantity Increment*"
        :form-key="formKey"
        :precision="isDecimalValues"
        name="orderingQuantityIncrement"
        class="mask-input mt-12"
        :disabled="!selectedUnitId"
        :value="quantityIncrement"
        type="input"
        :price-prefix="false"
        @input="onQuantityIncrementChange"
        :data-cy="dataCyAttr('orderingQuantityIncrement')"
      />
      <ez-mask-input
        ref="minQuantityDecimal"
        label="Minimum Quantity"
        name="minimumQuantity"
        :form-key="formKey"
        :precision="isDecimalValues"
        :disabled="!selectedUnitId"
        class="mask-input mt-12"
        :value="minimumQuantity"
        type="input"
        :price-prefix="false"
        @input="onMinimumQuantityChange"
        :data-cy="dataCyAttr('minimumQuantity')"
      />
    </template>
    <add-unit-modal
      ref="addUnitModal"
      :unit="unitToUpdate"
      @onClose="onUnitModalClose"
      @success="onUnitChange"
    />
    <remove-unit-modal
      ref="removeUnit"
      @onClose="onRemoveClose"
      @success="() => $emit('successDeleteUnit')"
      :unit="unitToDelete"
    />
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>

<style lang="scss" scoped>
.price-unit {
  .market-price {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    :deep() .input-group {
      height: auto;
    }
  }
  .info-circle {
    color: #6C7995;
  }
  .mask-input {
    :deep() .mask-input__input {
      font-weight: normal;
    }
  }
}
</style>
