import i18n from '@/i18n';

export const VENUE_STEP_1 = 0;
export const VENUE_STEP_2 = 1;
export const VENUE_STEP_3 = 2;

const steps = {
  [VENUE_STEP_1]: {
    component: () => import(/* webpackChunkName: "venue" */ '@/views/platform/venue/xero/setup/AccountSetup'),
    title: i18n.t('xero.wizzard.venue.step1.title'),
    pageTitle: i18n.t('xero.wizzard.venue.step1.pageTitle'),
    pageInfo: i18n.t('xero.wizzard.venue.step1.pageInfo', { platform: process.env.VUE_APP_PLATFORM_TITLE }),
    nextCta: i18n.t('global.wizzard.nextStep'),
  },
  [VENUE_STEP_2]: {
    component: () => import(/* webpackChunkName: "venue" */ '@/views/platform/venue/xero/setup/DistributorSync'),
    title: i18n.t('xero.wizzard.venue.step2.title'),
    pageTitle: i18n.t('xero.wizzard.venue.step2.pageTitle'),
    pageInfo: i18n.t('xero.wizzard.venue.step2.pageInfo', { platform: process.env.VUE_APP_PLATFORM_TITLE }),
    nextCta: i18n.t('global.wizzard.goToOverview'),
  },
  [VENUE_STEP_3]: {
    component: () => import(/* webpackChunkName: "venue" */ '@/views/platform/venue/xero/setup/Overview'),
    title: i18n.t('xero.wizzard.venue.overview.title'),
    pageTitle: i18n.t('xero.wizzard.venue.overview.pageTitle'),
    pageInfo: i18n.t('xero.wizzard.venue.overview.pageInfo'),
    nextCta: i18n.t('global.wizzard.finish'),
  },
};

export default steps;
