<script>
/**
 * NewSupplierOverview
 * @version 1.0.0
 * @since 2.3.0
 */

import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import EzButton from '@/components/ui/Button/EzButton';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo';
import Paper from '@/components/layout/WizardOverviewPaper';
import { wizardListenerMixin } from '@/mixins/wizard';
import User from '@/models/User';
import { pluralize } from '@/util/utils';
import { TAX_CALCULATION_OPTIONS } from '@/util/constants';
import VPrice from '@/components/v3/elements/VPrice';
import { SUPPLIER_STEP_1, SUPPLIER_STEP_2, SUPPLIER_STEP_3 } from './steps';

export default {
  mixins: [wizardListenerMixin],
  components: {
    EzButton,
    EzEntityInfo,
    Paper,
    VPrice,
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    ...mapState('entities/users', ['contextId']),
    ...mapState('entities/distributors', ['draftSupplier', 'draftSupplierAdmin']),
    ...mapGetters('entities/products', ['getNewDistributorProduct', 'getNewDistributorProducts']),
    distributor() {
      return this.draftSupplier;
    },
    users() {
      return User.query().where('groupId', this.distributor.id).all();
    },
    workingHours() {
      return this.distributor.workingHours || {};
    },
    tax() {
      return this.distributor.tax;
    },
    minimumOrderAmount() {
      return this.distributor.minimumOrderAmount;
    },
    hasNotifications() {
      const { distributor } = this;
      return distributor.email || distributor.smsNumber || distributor.whatsAppNumber;
    },
    deliveryPeriodCopy() {
      return `Delivery ${pluralize('Delivery', this.distributor.deliveryPeriodCount)}`;
    },
    taxCalculationLabel() {
      return TAX_CALCULATION_OPTIONS.find(
        option => option.value === this.distributor.taxCalculation,
      ).name;
    },
  },
  methods: {
    editDistributor() {
      this.$emit('stepBack', SUPPLIER_STEP_1);
    },
    editUsers() {
      this.$emit('stepBack', SUPPLIER_STEP_2);
    },
    editProducts() {
      this.$emit('stepBack', SUPPLIER_STEP_3);
    },
    async onNextStep() {
      this.CLEAR_DRAFT();
      await this.$router.push({
        name: 'venue-suppliers',
        params: {
          flash: {
            title: `${this.$t('global.distributor')} created!`,
            type: 'success',
          },
        },
      });
    },
    ...mapActions('entities/products', ['venueFetchNewDistributorProducts']),
    ...mapMutations('entities/distributors', ['CLEAR_DRAFT']),
  },
  async mounted() {
    this.enableNextStep();
    const { data } = await this.venueFetchNewDistributorProducts({
      distributorId: this.distributor.id,
      query: { limit: '1500' },
    });
    this.products = data.data;
  },
};
</script>
<template>
  <div>
    <paper>
      <template #actions>
        <ez-button type="link" formType="button" @click="editDistributor">
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <ez-entity-info
        imgWidth="4rem"
        imgHeight="4rem"
        imgBorderRadius="50%"
        :imgUrl="draftSupplier.logo"
        >{{ draftSupplier.name }}</ez-entity-info
      >
    </paper>
    <paper>
      <template #title>Main info</template>
      <template #actions>
        <ez-button type="link" formType="button" @click="editDistributor">
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>

      <div class="overview-item">
        <div v-if="distributor.customInfo" class="info info--vertical">
          <small>Additional Info</small>
          <span>{{ distributor.customInfo }} </span>
        </div>
      </div>

      <div class="overview-item">
        <h3>Addresses</h3>
        <div v-if="distributor.address.street" class="ez-info-panel__contact">
          <div class="info info--vertical">
            <small>Street Address</small>
            <span>{{ distributor.address.street }} </span>
          </div>
        </div>
        <div v-if="distributor.address.city" class="ez-info-panel__contact">
          <div class="info info--vertical">
            <small>City</small>
            <span>{{ distributor.address.city }} </span>
          </div>
        </div>
        <div v-if="distributor.address.zipCode" class="ez-info-panel__contact">
          <div class="info info--vertical">
            <small>Zip Code</small>
            <span>{{ distributor.address.zipCode }} </span>
          </div>
        </div>
        <div v-if="distributor.address.country" class="ez-info-panel__contact">
          <div class="info info--vertical">
            <small>Country</small>
            <span>{{ distributor.address.country }} </span>
          </div>
        </div>
        <div v-if="distributor.address.state" class="ez-info-panel__contact">
          <div class="info info--vertical">
            <small>State</small>
            <span>{{ distributor.address.state }} </span>
          </div>
        </div>
      </div>
      <div class="overview-item">
        <h3>Tax Rate Applicable</h3>
        <div class="info info--vertical">
          <small>Tax Calculation</small>
          <div>
            <span>{{ taxCalculationLabel }}</span>
          </div>
        </div>
        <div v-if="tax" class="info info--vertical">
          <small>Tax</small>
          <div>
            <span>{{ tax }}%</span>
          </div>
        </div>
      </div>
      <div v-if="workingHours" class="overview-item">
        <h3>Cut-Off Time</h3>
        <div class="info info--vertical">
          <small>Orders Received By</small>
          <div>
            <span>{{ workingHours.to }}</span>
          </div>
        </div>
        <div class="info info--vertical">
          <small>Will Be Delivered In</small>
          <div>
            <span>{{ distributor.deliveryPeriodCount }} {{ deliveryPeriodCopy }}</span>
          </div>
        </div>
        <div class="info info--vertical">
          <small>Delivery Days</small>
          <div>
            <span>{{
              distributor.deliveryDays?.length
                ? distributor.deliveryDays.map(day => day).join(', ')
                : 'None'
            }}</span>
          </div>
        </div>
        <div class="info info--vertical">
          <small>Time Zone</small>
          <span>{{ distributor.timezone }}</span>
        </div>
      </div>
      <div class="overview-item" v-if="minimumOrderAmount">
        <div v-if="minimumOrderAmount" class="info info--vertical">
          <small>Minimum order amount:</small>
          <div>{{ minimumOrderAmount | price }}</div>
        </div>
      </div>
      <div v-if="hasNotifications" class="info info--vertical">
        <h3>Receive Notifications Via:</h3>
        <template v-if="distributor.email">
          <small>Email</small>
          <span>{{ distributor.email }}</span>
        </template>
        <template v-if="distributor.smsNumber">
          <small>SMS</small>
          <span>{{ distributor.smsNumber }}</span>
        </template>
        <template v-if="distributor.whatsAppNumber">
          <small>WhatsApp</small>
          <span>{{ distributor.whatsAppNumber }}</span>
        </template>
      </div>
    </paper>
    <paper>
      <template #title>Users</template>
      <template #actions>
        <ez-button type="link" formType="button" @click="editUsers">
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <div v-for="user in users" :key="user.id" class="info info--horizontal">
        <span>{{ user.name }}</span>
        <small>{{ user.role ? user.role.name : '' }}</small>
      </div>
    </paper>
    <paper>
      <template #title>Products</template>
      <template #actions>
        <ez-button type="link" formType="button" @click="editProducts">
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <div v-for="product in products" :key="product.id" class="info info--horizontal">
        <div class="product-info">
          <img class="products-img" :src="product.image" alt="" />
          <span>{{ product.name }}</span>
        </div>
        <v-price
          :price="product.price || 0"
          :is-market-price="product.marketPrice"
          :show-market-price-info="false"
          :unit="product.priceUnit && product.priceUnit.label"
        />
      </div>
    </paper>
  </div>
</template>
<style lang="scss" scoped>
:deep() .entity-info {
  flex-direction: column;
  align-items: center;
  &__text {
    margin: 0.75rem 0 0;
    justify-content: center;
  }
}

.product-info {
  display: flex;
  align-items: center;
  .products-img {
    width: 28px;
    height: 28px;
    border: 1px solid #dee1e4;
    border-radius: 4px;
    margin-right: 8px;
  }
}

.address {
  @include separator('both');
}
.overview-item {
  &:not(:last-child) {
    @include separator('bottom');
  }

  .ez-info-panel__contact + .ez-info-panel__contact {
    margin-top: 1rem;
  }
}

h3 {
  @include font-size(12px, 14px);
  margin: 0 0 16px;
  padding: 0;
  color: #6c7995;
  font-weight: 500;
  text-transform: uppercase;
}
</style>
