var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{class:[
    { 'v-price--column': !_vm.flexRow },
    { 'v-price--row': _vm.flexRow },
    { [`v-price-${_vm.type}--column`]: !_vm.flexRow },
    { [`v-price-${_vm.type}--row`]: !_vm.flexRow },
  ]},[(!(_vm.isMarketPrice || _vm.isToBeDetermined))?_c('span',{class:['v-price__price', `v-price-${_vm.type}__price`],attrs:{"data-cy":_vm.dataCy}},[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.price, _vm.currency))+" ")]):_vm._e(),(_vm.isMarketPrice)?[_c('span',{class:[
        { 'v-price__market-price--column': !_vm.flexRow },
        { 'v-price__market-price--row': _vm.flexRow },
        { [`v-price-${_vm.type}__market-price--column`]: !_vm.flexRow },
        { [`v-price-${_vm.type}__market-price--row`]: _vm.flexRow },
      ],attrs:{"data-cy":_vm.dataCy}},[_vm._v(" - ")])]:_vm._e(),(_vm.isToBeDetermined && !_vm.isMarketPrice)?_c('span',{class:['v-price__to-be-determined', `v-price-${_vm.type}__to-be-determined`],attrs:{"data-cy":_vm.dataCy}},[_vm._v(" "+_vm._s(_vm.$t('global.tbd'))+" ")]):_vm._e(),(_vm.showUnitLabel)?_c('span',{class:[
      { 'v-price__unit--column': !_vm.flexRow },
      { 'v-price__unit--row': _vm.flexRow },
      { [`v-price-${_vm.type}__unit--column`]: !_vm.flexRow },
      { [`v-price-${_vm.type}__unit--row`]: _vm.flexRow },
    ],attrs:{"data-cy":`${_vm.dataCy}-unitLabel`}},[_vm._v(" "+_vm._s(_vm.unitLabel)+" ")]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }