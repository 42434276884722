var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legal"},[_c('header',{class:{
    'mt-24': _vm.isPublic,
    'ml-16': _vm.isPublic,
  }},[_c('h1',[_vm._v("Legal")])]),_c('ez-nav',{class:[
    'mt-12',
    {'ml-16': _vm.isPublic}
  ],scopedSlots:_vm._u([{key:"nav",fn:function(){return [_c('ul',[_c('li',[_c('router-link',{attrs:{"to":{ name: _vm.termsAndConditions }}},[_vm._v(" Terms & Conditions ")])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: _vm.privacyPolicy }}},[_vm._v(" Privacy Policy ")])],1)])]},proxy:true}])},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }