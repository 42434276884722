<template>
  <ez-confirmation-modal ref="approveModal" icon="question" @close="onClose">
    <template #title>Confirm Delivery?</template>
    <template #content>
      <div class="title-wrapper">
        <p>
          Make sure you have confirmed everything from the order list is delivered and in
          appropriate condition.
        </p>
        <hr />
      </div>

      <div class="wrapper-content">
        <template v-if="shouldShowWarehouseDropdown">
          <ez-select
            ref="select"
            name="warehouseId"
            label="Fulfill Order From"
            :selected="warehouseId"
            :value="warehouseId"
            :options="warehouses"
            @change="onWarehouseSelectChange"
            isFullWidth
            :data-cy="commonCy.SINGLE_ORDER.COMPLETE_ORDER_MODAL.BUTTON__WAREHOUSE"
          />
          <hr />
        </template>

        <ez-form
          ref="disputeForm"
          @success="confirmSuccess"
          method="patch"
          submitType="multipart"
          :action="action"
          :additional-data="additionalData"
          :additional-headers="{ ...additionalHeaders, loadingKey }"
          :form-key="formKey"
        >
          <div class="upload-wrapper">
            <h5 class="invoice-match-order__title">Does the invoice match the order?</h5>
            <ez-radio-button
              value="false"
              label="Yes"
              :data="notMatching"
              :form-key="formKey"
              name="notMatching"
              @change="updatenotMatching"
              class="invoice-match-order__radio"
              :data-cy="commonCy.SINGLE_ORDER.COMPLETE_ORDER_MODAL.LABEL__INVOICE_MATCH_ORDER_YES"
            />
            <ez-radio-button
              value="true"
              label="No"
              :data="notMatching"
              :form-key="formKey"
              name="notMatching"
              @change="updatenotMatching"
              class="invoice-match-order__radio"
              :data-cy="commonCy.SINGLE_ORDER.COMPLETE_ORDER_MODAL.LABEL__INVOICE_MATCH_ORDER_NO"
            />
            <hr />
            <ez-multiple-image-upload
              class="upload-component"
              ref="imageUpload"
              accept="image/jpeg,image/png,application/pdf"
              :form-key="formKey"
              :note="false"
              card-type
              :number-of-files="5"
              name="invoice"
              @change="imagesUploaded"
              :data-cy="commonCy.SINGLE_ORDER.COMPLETE_ORDER_MODAL.LABEL__UPLOAD_INVOICES"
              :custom-error="invoiceError"
            >
              <template #label>Invoices</template>
              <template #cta>Upload Invoice</template>
            </ez-multiple-image-upload>
            <ez-multiple-image-upload
              class="upload-component"
              accept="image/jpeg,image/png"
              :number-of-files="5"
              :note="false"
              :formKey="formKey"
              card-type
              name="images"
              ref="notesUpload"
              @change="notesImagesUploaded"
              :data-cy="commonCy.SINGLE_ORDER.COMPLETE_ORDER_MODAL.LABEL__UPLOAD_IMAGES"
            >
              <template #label>Images</template>
              <template #cta>Upload Image</template>
            </ez-multiple-image-upload>
          </div>
          <ez-textarea
            placeholder="Leave a Note"
            :formKey="formKey"
            name="message"
            label="Note"
            class="mt-16"
            :data-cy="commonCy.SINGLE_ORDER.COMPLETE_ORDER_MODAL.TEXTAREA__NOTE"
          />
        </ez-form>

        <advanced-inventory-management
          v-if="!isDistributorProvinoConnected && shouldShowAdvancedOptions"
          :show="advancedOptions"
          :order="order"
          :warehouseId="warehouseId"
          @invalid="onInventoryManagementInvalid"
          @onChange="onInventoryManagementChange"
          :data-cy="commonCy.SINGLE_ORDER.COMPLETE_ORDER_MODAL.CONTAINER__ADVANCED_OPTIONS_CONTAINER"
          :error-data-cy="commonCy.SINGLE_ORDER.COMPLETE_ORDER_MODAL.TEXT__ADVANCED_OPTIONS_ERROR"
        />
      </div>
    </template>
    <template #footer>
      <ez-button
        v-if="!isDistributorProvinoConnected && shouldShowAdvancedOptions"
        type="blue-link"
        class="advanced-options-btn"
        @click="showAdvancedOptions"
        :data-cy="commonCy.SINGLE_ORDER.COMPLETE_ORDER_MODAL.BUTTON__ADVANCED_OPTIONS"
      >
        Advanced Options
        <font-awesome-icon class="ml-8" v-if="advancedOptions" icon="angle-up" />
        <font-awesome-icon class="ml-8" v-else icon="angle-down" />
      </ez-button>
      <ez-button
        @click="close"
        type="link"
        :data-cy="commonCy.SINGLE_ORDER.COMPLETE_ORDER_MODAL.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        class="confirm-delivery"
        @click="confirm"
        :is-loading="isLoadingConfirm"
        :data-cy="commonCy.SINGLE_ORDER.COMPLETE_ORDER_MODAL.BUTTON__CONFIRM"
        :disabled="isDisabled"
      >
        Confirm Delivery
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<script>
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import { EzMultipleImageUpload } from '@/components/ui/ImageUpload';
import EzForm from '@/components/ui/Form';
import Order from '@/models/Order';
import flash from '@/components/ui/FlashMessage';
import EzTextarea from '@/components/ui/Textarea/EzTextarea';
import { mapActions, mapGetters, mapState } from 'vuex';
import { LOADING_KEY } from '@/util/constants';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import EzRadioButton from '@/components/ui/RadioButton';
import AdvancedInventoryManagement from '@/views/platform/distributor/orders/AdvancedInventoryManagement';
import EzSelect from '@/components/ui/Select';

/**
 * Confirm Order
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    EzMultipleImageUpload,
    EzConfirmationModal,
    EzButton,
    EzForm,
    EzTextarea,
    EzRadioButton,
    AdvancedInventoryManagement,
    EzSelect,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: false,
    },
    isPublicOrder: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      formKey: 'confirm-order-form',
      imageData: [],
      notesImages: [],
      notMatching: '',
      notesImagesLength: 0,
      loadingKey: LOADING_KEY.COMPLETE_ORDER,
      additionalData: null,
      commonCy,
      invoiceError: {},
      advancedOptions: false,
      inventoryItems: [],
      warehouses: [],
      warehouseId: null,
      confirmDeliveryDisabled: true,
    };
  },
  computed: {
    ...mapState('entities/orders', ['singleOrder']),
    ...mapGetters('entities/users', ['isDistributor', 'isDistributorProvinoConnected']),
    ...mapGetters('loading', ['getLoading']),
    isLoadingConfirm() {
      return this.getLoading(LOADING_KEY.COMPLETE_ORDER);
    },
    additionalHeaders() {
      return this.token ? { 'X-Public-Token': this.token } : {};
    },
    excludedProductsIds() {
      return (this.order.orderedProducts || []).filter(p => p.isDeclined).map(a => a.id);
    },
    isEmpty() {
      return this.notesImagesLength === 0;
    },
    hasUploadedInvoices() {
      return !!this.imageData.length;
    },
    isDisabled() {
      return (
        this.isLoadingConfirm
        || this.shouldShowError
        || !this.notMatching
        || this.confirmDeliveryDisabled
      );
    },
    shouldShowError() {
      if (!this.notMatching) return false;
      return this.isNotMatching && !this.hasUploadedInvoices;
    },
    isNotMatching() {
      return this.notMatching === 'true';
    },
    trackingInventory() {
      if (!this.isDistributor) return false;
      return this.singleOrder.distributor?.inventorySettings?.tracking;
    },
    hasTrackedProducts() {
      return !!this.filterProducts(this.order.orderedProducts).length;
    },
    shouldShowWarehouseDropdown() {
      if (!this.trackingInventory || !this.hasTrackedProducts) return false;
      return this.warehouses.length && !this.isDistributorProvinoConnected;
    },
    shouldShowAdvancedOptions() {
      if (!this.trackingInventory || !this.hasTrackedProducts) return false;
      return this.order.orderedProducts;
    },
  },
  methods: {
    async open() {
      if (!this.shouldShowAdvancedOptions) {
        this.confirmDeliveryDisabled = false;
      }
      if (this.isDistributor) this.notMatching = 'false';
      await this.initWarehouses();
      this.$refs.approveModal.open();
    },
    close() {
      this.$refs.approveModal.close();
    },
    onClose() {
      this.reset();
    },
    imagesUploaded(formData) {
      this.imageData = [...formData.values()];
    },
    notesImagesUploaded(formData) {
      this.notesImages = [...formData.values()];
      this.notesImagesLength = this.notesImages.length;
    },
    updatenotMatching(e) {
      this.notMatching = e;
    },
    confirmSuccess({ data }) {
      /**
       * Introduced because the values are reset upon closing the modal
       * and 'isNotMatching' always returns false.
       */
      const temp = this.isNotMatching;

      Order.update(data);
      this.close();
      this.$emit('success');

      if (temp) {
        flash.error({
          title: 'Order marked as incomplete',
          message: 'You have successfully uploaded the invoice and marked the order as incomplete.',
        });
      } else {
        flash.success({
          title: 'Order confirmed.',
          message: 'You have successfully confirmed the order.',
        });
      }
    },
    reset() {
      this.advancedOptions = false;
      this.notMatching = '';
      this.imageData = [];
      this.$refs.imageUpload.reset();
      this.notesImages = [];
      this.$refs.notesUpload.reset();
      this.notesImagesLength = 0;
      this.$refs.disputeForm.reset();
    },
    setInvoiceError(message) {
      this.invoiceError = { ...(message ? { message } : {}) };
    },
    appendData() {
      const formData = new FormData();
      this.excludedProductsIds.forEach(p => formData.append('declinedProducts[]', p));
      this.imageData.forEach(file => formData.append('invoice', file));
      this.notesImages.forEach(file => formData.append('images', file));

      if (this.trackingInventory) {
        formData.append('warehouseId', this.warehouseId);
        this.inventoryItems.forEach((ii, idx) => {
          formData.append(`inventoryItems[${idx}][itemId]`, ii.itemId);
          formData.append(`inventoryItems[${idx}][quantity]`, ii.quantity);
        });
      }

      this.additionalData = formData;
    },
    async confirm() {
      await this.appendData();
      this.$refs.disputeForm.onSubmit();
    },
    ...mapActions('entities/distributors', ['distributorFetchWarehouses']),
    async initWarehouses() {
      if (!this.trackingInventory) return;

      if (!this.warehouses.length) {
        const { data } = await this.distributorFetchWarehouses();
        this.warehouses = [{ id: null, name: 'Select Warehouse' }, ...data.data];
      }

      const orderWarehouseId = this.order.fulfillmentWarehouse?.id || null;
      const primaryWarehouseId = this.warehouses.find(w => w.primary)?.id || null;
      this.warehouseId = orderWarehouseId || primaryWarehouseId;
    },
    showAdvancedOptions() {
      this.advancedOptions = !this.advancedOptions;
    },
    onWarehouseSelectChange(val) {
      if (this.warehouseId === val.id) return;
      this.warehouseId = val.id;
      this.inventoryItems = [];
    },
    onInventoryManagementChange(items) {
      this.inventoryItems = items.map(p => ({ itemId: p.id, quantity: p.usedInventory }));
    },
    onInventoryManagementInvalid(val) {
      this.advancedOptions = val;
      this.confirmDeliveryDisabled = val;
    },
    filterProducts(products) {
      return products?.filter((p) => {
        const declined = p.isDeclined ?? false;
        const included = p.isIncluded ?? true;
        return p.inventoryTracked && included && !declined;
      }) || [];
    },
  },
  mounted() {
    if (
      !this.trackingInventory
      || !this.hasTrackedProducts
      || (this.isDistributor && this.isDistributorProvinoConnected)
    ) {
      this.confirmDeliveryDisabled = false;
    }
  },
  watch: {
    shouldShowError: {
      immediate: true,
      handler(val) {
        this.setInvoiceError(val ? 'Please upload invoice in order to continue' : null);
      },
    },
    inventoryItems(val) {
      if (val.length) return;

      const products = this.filterProducts(this.order.orderedProducts);
      if (!products.length) return;

      this.confirmDeliveryDisabled = true;
    },
  },
};
</script>

<style scoped lang="scss">
:deep() .modal__inner {
  padding-right: 0;
}
:deep() .modal__footer {
  padding-right: 24px;
}
.title-wrapper {
  padding-right: 24px;
}
.wrapper-content {
  max-height: 560px;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 24px;
}
.upload-wrapper {
  display: flex;
  flex-direction: column;

  :deep() .preview__images {
    flex-direction: column;
    .preview__image + .preview__image {
      margin-left: 0;
    }
  }
}

:deep() .invoice-match-order {
  &__title {
    @include font-size(14px, 22px);
    margin: 4px 0 16px;
  }

  &__radio {
    & ~ .invoice-match-order__radio {
      margin-top: 16px;
    }

    .ez-radio-button__label {
      @include font-size(14px, 20px);
      color: $color-gray-25;
    }
  }
}

:deep() .confirm-delivery[disabled] {
  background-color: #a3bbff;
}

.advanced-options-btn {
  margin-right: auto;
  padding-left: 0;
}
</style>
