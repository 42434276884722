<script>
import VUpgradeToPremium from '@/components/v3/patterns/VUpgradeToPremium';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'FreemiumSuppliers',
  components: {
    VUpgradeToPremium,
  },
};
</script>

<template>
  <div class="freemium-suppliers">
    <header>
      <h1>{{ $t('global.distributors') }}</h1>
      <span>No connected {{ $t('global.distributors') }}</span>
    </header>
    <v-upgrade-to-premium>
      <template #background>
        <img src="@/assets/suppliers-upgrade-to-premium.svg" alt="upgrade-to-premium-background" />
      </template>
      <template #title>Looking for a full-featured procurement solution? </template>
      <template #description>
        Foodrazor connects all your {{ $t('global.distributors') | lowercase }} and
        the products you buy and delivers price change notifications,
        inventory management, automated invoice processing, approval workflows, and accounting integrations.
      </template>
    </v-upgrade-to-premium>
  </div>
</template>

<style lang="scss" scoped>
.freemium-suppliers {
  @include platform-tab-content();
}
</style>
