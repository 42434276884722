<script>
import VEntityInfo from '@/components/v3/elements/VEntityInfo';

export default {
  name: 'VProductEntityInfo',
  components: {
    VEntityInfo,
  },
  props: {
    product: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    /**
     * A rare case when the request is not sent and UI is initialized.
     * Most often happens on IE 11.
     * @return {*|{}}
     */
    entity() {
      return this.product || {};
    },
  },
};
</script>
<template>
  <v-entity-info class="v-product-entity-info" :imageUrl="entity.image">
    <template>
      <span :data-cy="dataCy">{{ entity.name }}</span>
    </template>
  </v-entity-info>
</template>
<style scoped lang="scss">
.v-product-entity-info {
  @include font-size(14px, 20px);
}
</style>
