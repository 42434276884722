<script>
/**
 * Select Locations Modal
 * @version 1.0.0
 * @since 2.3.0
 */

import { mapActions, mapGetters } from 'vuex';
import { EzConfirmationModal } from '@/components/ui/Modal';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import EzToggle from '@/components/ui/Toggle';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzButton from '@/components/ui/Button';
import EzInput from '@/components/ui/Input';
import { debounce } from '@/util/utils';
import { LOADING_KEY } from '@/util/constants';
import flash from '@/components/ui/FlashMessage';

export default {
  components: {
    EzConfirmationModal,
    VVenueEntityInfo,
    EzToggle,
    EzCheckbox,
    EzButton,
    EzInput,
  },
  props: {
    locations: {
      type: Array,
      required: true,
      default: () => [],
    },
    supplierId: {
      type: Number,
      required: false,
      default: null,
    },
    productId: {
      type: Number,
      required: false,
      default: null,
    },
    suppliers: {
      type: Array,
      required: false,
      default: () => [],
    },
    productsBulkUpdate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      term: '',
      selectedLocations: [],
      venuesListExpanded: false,
    };
  },
  computed: {
    ...mapGetters('entities/users', ['getVenues']),
    ...mapGetters('loading', ['getLoading']),
    venues() {
      return this.getVenues;
    },
    isLoading() {
      return this.getLoading(LOADING_KEY.VENUE_UPDATE_SUPPLIER_AVAILABILITY);
    },
    numberOfLocationsToDisplay() {
      if (this.filteredLocations.length - 5 > 3) return 5;
      return this.filteredLocations.length;
    },
    areAllSelected() {
      const unselected = this.filteredLocations.filter(
        item => this.selectedLocations.indexOf(item.id) === -1,
      ).length;
      return unselected === 0 && this.filteredLocations.length > 0;
    },
    filteredLocationsToDisplay() {
      if (!this.venuesListExpanded) {
        return this.filteredLocations.slice(0, this.numberOfLocationsToDisplay);
      }
      return this.filteredLocations;
    },
    filteredLocations() {
      const filtered = this.venues.filter(item =>
        item.name.toLowerCase().includes(this.term.toLowerCase()),
      );
      const filteredSelected = filtered.filter(
        item => this.selectedLocations.indexOf(item.id) >= 0,
      );
      filteredSelected.sort((a, b) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        if (textA < textB) return -1;
        if (textA > textB) return 1;
        return 0;
      });
      const filteredUnselected = filtered.filter(
        item => this.selectedLocations.indexOf(item.id) === -1,
      );
      filteredUnselected.sort((a, b) => {
        const textA = a.name.toUpperCase();
        const textB = b.name.toUpperCase();
        if (textA < textB) return -1;
        if (textA > textB) return 1;
        return 0;
      });
      return [...filteredSelected, ...filteredUnselected];
    },
    expandButtonCopy() {
      return this.venuesListExpanded ? 'Collapse List' : 'Show more locations';
    },
    expandButtonIcon() {
      return this.venuesListExpanded ? 'angle-up' : 'angle-down';
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'venueUpdateSupplierAvailability',
      'venueUpdateSuppliersAvailability',
    ]),
    ...mapActions('entities/products', ['venueUpdateProductAvailability']),
    onSearch: debounce(function deb(term) {
      if (term !== this.term) {
        this.term = term;
      }
    }, 300),
    onSelectAll(event) {
      if (event) {
        this.selectedLocations = this.filteredLocations.map(item => item.id);
      } else {
        const uselectedLocationIds = this.filteredLocations.map(item => item.id);
        this.selectedLocations = this.selectedLocations.filter(
          item => uselectedLocationIds.indexOf(item) === -1,
        );
      }
    },
    toggleSelectedVenues(venue) {
      if (this.selectedLocations.indexOf(venue.id) === -1) this.selectedLocations.push(venue.id);
      else this.selectedLocations = this.selectedLocations.filter(item => item !== venue.id);
    },
    toggleListExpanded() {
      this.venuesListExpanded = !this.venuesListExpanded;
    },
    open() {
      this.$refs.locationsModal.open();
    },
    reset() {
      this.selectedLocations = [];
      this.term = '';
      this.venuesListExpanded = false;
      this.$emit('close');
    },
    close() {
      this.$refs.locationsModal.close();
      this.$emit('close');
      this.reset();
    },
    async saveChanges() {
      try {
        if (this.supplierId) {
          await this.venueUpdateSupplierAvailability({
            id: this.supplierId,
            locationIds: this.selectedLocations,
          });
          flash.success({
            title: "Supplier's availability successfully updated",
          });
        } else if (this.productId) {
          await this.venueUpdateProductAvailability({
            id: this.productId,
            locationIds: this.selectedLocations,
          });
          flash.success({
            title: "Product's availability successfully updated",
          });
        } else if (this.suppliers.length && !this.supplierId) {
          await this.venueUpdateSuppliersAvailability({
            distributorIds: this.suppliers,
            locationIds: this.selectedLocations,
          });
          flash.success({
            title: "Supplier's availability successfully updated",
          });
        } else if (this.productsBulkUpdate) {
          this.$emit('productsBulkUpdate', this.selectedLocations);
        }
        if (!this.productsBulkUpdate) {
          this.$emit('success', this.selectedLocations);
          this.close();
        }
      } catch (e) {
        flash.error({ title: 'Something went wrong!' });
      }
    },
  },
  watch: {
    locations: {
      handler(val) {
        this.locations = val;
        if (this.locations) this.locations.forEach(item => this.selectedLocations.push(+item));
      },
      deep: true,
    },
    hasErrors(val) {
      if (val) this.changeTab();
    },
  },
};
</script>

<template>
  <ez-confirmation-modal @close="reset" ref="locationsModal" :icon="null">
    <template #title>
      <slot name="title">Update Availability</slot>
    </template>
    <template #content>
      <ez-input
        formKey=""
        name="search"
        class="search mt-16"
        placeholder="Search for an outlet"
        label="Outlets"
        :value="term"
        @onChange="onSearch"
      >
        <template #suffix>
          <font-awesome-icon icon="search" />
        </template>
      </ez-input>
      <ez-checkbox
        class="mt-24"
        label="Select All"
        :checked="areAllSelected"
        @change="onSelectAll($event)"
      />
      <ul class="ez-special-tier-form__venue-list">
        <li v-for="venue in filteredLocationsToDisplay" :key="venue.id">
          <div class="ez-special-tier-form__venue-list-item">
            <v-venue-entity-info class="" :venue="venue" :title="venue.name" />
            <ez-toggle
              class="outlet-toggle ml-12"
              @toggleState="toggleSelectedVenues(venue)"
              :isActive="selectedLocations.includes(venue.id)"
            >
              <template #active><span>Selected</span></template>
              <template #inactive><span>Select</span></template>
            </ez-toggle>
          </div>
        </li>
      </ul>
      <ez-button
        v-if="filteredLocations.length > numberOfLocationsToDisplay"
        class="u-flex-h-center mt-16"
        @click="toggleListExpanded"
        isFullWidth
        type="link"
        formType="button"
      >
        <span>{{ expandButtonCopy }}</span>
        <font-awesome-icon class="ml-8" :icon="expandButtonIcon" />
      </ez-button>
    </template>
    <template #footer>
      <ez-button type="link" formType="button" @click="close">Cancel</ez-button>
      <ez-button formType="button" @click="saveChanges" :is-loading="isLoading">
        Save Changes
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<style lang="scss" scoped>
.ez-special-tier-form {
  .search-input {
    margin-bottom: $spacing-12;

    &__icon {
      margin-left: $spacing-12;
      color: $color-gray-6C;
    }
  }

  &__venue-list-item :deep() {
    margin-top: $spacing-08;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    width: 100%;
  }

  &__venue-list {
    @extend %ul-reset;
  }
}

:deep() .outlet-toggle.ez-toggle .ez-toggle__button {
  padding: 10px 12px;
  font-weight: normal;
  height: 36px;
  width: 108px;
  margin: 0 auto;
}
</style>
