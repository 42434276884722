<script>
import httpService from '@/api/http';

import EzAutocomplete from '@/components/ui/Autocomplete/EzAutocomplete.vue';
import EzPremiumBadge from '@/components/ui/PremiumBadge/PremiumBadge';
import StatusBadge from '@/views/common/status-badge/StatusBadge.vue';
import EzInput from '@/components/ui/Input/EzInput.vue';
import EzDateInput from '@/components/ui/DateInput';
import VDistributorEntityInfo from '@/components/v3/patterns/VDistributorEntityInfo';
import EzTable from '@/components/ui/Table';
import EzButton from '@/components/ui/Button';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal.vue';

import { debounce } from '@/util/utils';
import { ADMIN_INVOICE_PROCESSING_STATUS_PROCESSED } from '@/util/constants';
import dayjs from 'dayjs';
import { mapActions } from 'vuex/dist/vuex.common.js';
import flash from '@/components/ui/FlashMessage';

export default {
  components: {
    EzInput,
    EzAutocomplete,
    EzPremiumBadge,
    StatusBadge,
    EzDateInput,
    VDistributorEntityInfo,
    EzTable,
    EzButton,
    EzConfirmationModal,
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
    venueId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isNewAddedSupplier: false,
      dayjs,
      suggestedMatches: [],
      editSupplierMode: false,
      supplierAlternative: {},
      newSupplierName: '',
    };
  },
  methods: {
    ...mapActions('entities/distributors', ['fetchVenueDistributorSuggestedMatches']),
    // Setters
    onInvoiceNumberChange(val) {
      this.invoice.order.invoiceNumber = val;
    },
    updateDate(val) {
      this.invoice.order.orderedAt = val;
    },
    onTaxChange(val) {
      this.invoice.order.tax = Number(val);
    },

    // Logic
    async onSupplierSelect(supplier) {
      if (supplier.first) {
        this.isNewAddedSupplier = true;
        const { data } = await httpService.post(`/venues/${this.venueId}/distributors`, {
          name: supplier.query,
        });
        const { data: suggestions } = await this.fetchVenueDistributorSuggestedMatches({
          venueId: this.venueId,
          term: supplier.query,
        });
        this.suggestedMatches = suggestions.data;
        this.invoice.order.distributor = data.data;
      } else {
        if (supplier.selected.isNew) {
          const { data: suggestions } = await this.fetchVenueDistributorSuggestedMatches({
            venueId: this.venueId,
            term: supplier.selected.name,
          });
          this.suggestedMatches = suggestions.data;
        } else {
          this.isNewAddedSupplier = false;
        }
        this.invoice.order.distributor = supplier.selected;
      }
    },

    async onDistributorSelect(supplier) {
      this.supplierAlternative = supplier.selected;
      await this.$nextTick();
      this.$refs.confirmSwitchModal.open();
      await this.$nextTick();
      const confirmButton = document.querySelector(
        '.confirm-modal .modal__footer .button--primary',
      );
      if (confirmButton) confirmButton.focus();
    },

    removeSelectedSupplier() {
      this.invoice.order.distributor = {};
      this.invoice.order.products = [];
      this.invoice.order.tax = null;
    },
    editSelectedSupplier() {
      this.editSupplierMode = true;
      this.newSupplierName = this.invoice.order.distributor.name;
      this.$emit('editSupplierStart');
    },
    confirmSwitch() {
      this.invoice.order.distributor = this.supplierAlternative;
      this.$refs.confirmSwitchModal.close();
      this.editSupplierMode = false;
      this.$emit('editSupplierStop');
      this.suggestedMatches = [];
      this.invoice.order.products = [];
      this.isNewAddedSupplier = false;
      this.supplierAlternative = {};
    },
    close() {
      this.$refs.confirmSwitchModal.close();
      this.$refs.supplierName.reset();
      this.$refs.supplierName.clearErrors();
    },
    cancelEdit() {
      this.editSupplierMode = false;
      this.$emit('editSupplierStop');
      this.$refs.supplierName.reset();
      this.$refs.supplierName.clearErrors();
    },
    setUpdatedSupplierName(val) {
      this.newSupplierName = val;
    },
    async updateSupplierName() {
      if (this.newSupplierName !== this.invoice.order.distributor.name) {
        try {
          const { data } = await httpService.patch(
            `/venues/${this.venueId}/distributors/${this.invoice.order.distributor.id}`,
            {
              name: this.newSupplierName,
            },
            {
              headers: {
                formKey: 'edit-supplier',
              },
            },
          );
          this.invoice.order.distributor = data.data;
          this.suggestedMatches = [];
          if (data.data.isNew) {
            const { data: suggestions } = await this.fetchVenueDistributorSuggestedMatches({
              venueId: this.venueId,
              term: data.data.name,
            });
            this.suggestedMatches = suggestions.data;
            this.newSupplierName = '';
          }
          flash.success({ title: 'Supplier successfully edited' });
          this.editSupplierMode = false;
          this.$emit('editSupplierStop');
        } catch (e) {
          flash.error({
            title: 'Something went wrong!',
          });
        }
      } else {
        this.editSupplierMode = false;
        this.$emit('editSupplierStop');
      }
    },
    async checkForDuplicate(val) {
      if (this.invoice?.validationErrors?.invoiceNumber?.[0]) {
        this.invoice.validationErrors.invoiceNumber = [];
      }
      if (
        this.invoice.order?.distributor?.id &&
        this.invoice.processingStatus !== ADMIN_INVOICE_PROCESSING_STATUS_PROCESSED
      ) {
        try {
          await httpService.put(
            `/venues/${this.venueId}/pending-invoices/${this.invoice.id}/invoice-number`,
            {
              distributorId: this.invoice.order.distributor.id,
              invoiceNumber: val,
            },
            {
              headers: {
                formKey: 'pending-invoice',
              },
            },
          );
        } catch (e) {
          console.error(e);
        }
      }
    },
  },
  watch: {
    'invoice.order.distributor': {
      async handler() {
        if (this.invoice?.validationErrors?.invoiceNumber?.[0]) {
          this.invoice.validationErrors.invoiceNumber = [];
        }
        if (this.$refs.invoiceNumberInput) this.$refs.invoiceNumberInput.clearErrors();
        if (this.invoice?.order?.distributor?.id) {
          const { data: suggestions } = await this.fetchVenueDistributorSuggestedMatches({
            venueId: this.venueId,
            term: this.invoice.order.distributor.name,
          });
          this.suggestedMatches = suggestions.data;
          await this.checkForDuplicate(this.invoice.order.invoiceNumber);
        }
      },
      immediate: true,
    },
    'invoice.order.invoiceNumber': {
      handler: debounce(async function deb(val) {
        await this.checkForDuplicate(val);
      }, 800),
    },
  },
  created() {
    // define a CMD + S handler
    function focusSupplier(e) {
      const supplierInput = document.querySelector('#supplierInput input');
      if (supplierInput) {
        if (e.metaKey && e.keyCode === 83) {
          e.preventDefault();
          supplierInput.focus();
        } else if (e.altKey && e.keyCode === 83) {
          e.preventDefault();
          supplierInput.focus();
        }
      }
    }
    document.addEventListener('keydown', focusSupplier, false);
  },
};
</script>
<template>
  <div class="processing-details">
    <div class="processing-details__supplier">
      <div class="supplier-input">
        <ez-autocomplete
          v-if="!invoice?.order?.distributor?.id"
          id="supplierInput"
          ref="autocomplete"
          :placeholder="`Enter ${$t('global.distributor')} Name`"
          :source="`/venues/${venueId}/search/distributors`"
          :hasConnectedParam="false"
          :requestParams="{
            venueId: venueId,
            sameCurrency: 'true',
            fromGroup: true,
            includeTemp: true,
          }"
          searchProperty="name"
          :label="`${$t('global.distributor')}`"
          @selected="onSupplierSelect"
        >
          <template #firstResult="{ input }">
            <li>
              <div class="create-new-supplier">
                <div class="badge mr-8">
                  <font-awesome-icon icon="plus" transform="shrink-6" />
                </div>
                <span>Create New "{{ input }}" {{ $t('global.distributor') }}</span>
              </div>
            </li>
          </template>
          <template #result="{ result }">
            <div class="venue-result">
              <img :src="result.logo" alt="" class="venue-result__image" />
              <div class="venue-result__info">
                <div class="venue-result__title">
                  <span :title="result.name">{{ result.name }}</span>
                  <ez-premium-badge v-if="$helpers.isPremium(result.accountType)" />
                </div>
              </div>
            </div>
          </template>
        </ez-autocomplete>
        <ez-input
          v-else
          disabled
          :disabledTooltip="
            invoice?.type === 'order'
              ? 'Supplier not editable because the invoice is uploaded for an existing order'
              : ''
          "
          :class="{
            'selected-supplier': true,
            'selected-supplier--disabled': invoice?.type === 'order',
            'selected-supplier--new': invoice?.order?.distributor?.isNew || isNewAddedSupplier,
          }"
          formKey="pending-invoice"
          :value="invoice?.order?.distributor?.name"
          name="supplier"
          :label="`${$t('global.distributor')}`"
        >
          <template v-if="invoice?.order?.distributor?.isNew || isNewAddedSupplier" #prefix>
            <status-badge status="new" />
          </template>
          <template v-if="!suggestedMatches.length && invoice?.type !== 'order'" #suffix>
            <font-awesome-icon class="mr-8 ml-8" icon="pen" @click="editSelectedSupplier" />
            <font-awesome-icon class="mr-16" icon="times" @click="removeSelectedSupplier" />
          </template>
          <template v-else-if="invoice?.type !== 'order'" #suffix>
            <div class="suffix-action" @click="editSelectedSupplier">
              <font-awesome-icon
                icon="exclamation-circle"
                v-tooltip="{
                  placement: 'top',
                  content: 'Potential matches',
                  classes: ['tooltip--reset-margin', 'tooltip--lift-up'],
                }"
              />
              <span class="ml-8">Potential matches</span>
              <font-awesome-icon
                class="mr-8 ml-8"
                icon="pen"
                v-tooltip="{
                  placement: 'top',
                  content: 'Edit',
                  classes: ['tooltip--reset-margin', 'tooltip--lift-up'],
                }"
              />
              <font-awesome-icon
                class="mr-16"
                icon="times"
                v-tooltip="{
                  placement: 'top',
                  content: 'Clear',
                  classes: ['tooltip--reset-margin', 'tooltip--lift-up'],
                }"
                @click="removeSelectedSupplier"
              />
            </div>
          </template>
        </ez-input>
      </div>
      <ez-input
        class="ml-16"
        name="invoiceNumber"
        label="Invoice Number"
        formKey="pending-invoice"
        ref="invoiceNumberInput"
        :value="invoice?.order?.invoiceNumber ?? null"
        :errorMsg="invoice?.validationErrors?.invoiceNumber?.[0] ?? ''"
        placeholder="Invoice Number"
        @onChange="onInvoiceNumberChange"
      />
      <ez-date-input
        form-key="pending-invoice"
        name="orderedAt"
        label="Invoice Date"
        :placeholder="invoice?.dateFormat || 'DD/MM/YYYY'"
        :date-format="invoice?.dateFormat || 'DD/MM/YYYY'"
        :class="['mask-input', 'ml-16', { 'empty-date': !invoice?.order?.orderedAt }]"
        :value="dayjs(invoice.order.orderedAt)"
        @onChange="updateDate"
      />
      <ez-input
        class="ml-16"
        placeholder="Tax Rate"
        formKey="pending-invoice"
        type="number"
        name="tax"
        step="0.01"
        label="Tax Rate"
        :value="
          invoice?.order?.tax !== undefined && invoice?.order?.tax !== null
            ? invoice?.order?.tax
            : invoice?.order?.distributor?.tax || 0
        "
        :disabled="!invoice?.order?.distributor?.id"
        disabledTooltip="Please select a supplier first."
        @onChange="onTaxChange"
        min="0"
      >
        <template #suffix>%</template>
      </ez-input>
    </div>
    <div v-if="editSupplierMode" class="edit-box">
      <h2 class="title">Edit Supplier</h2>
      <p>Edit this invoice’s supplier or select another match.</p>
      <ez-input
        :disabled="
          !(invoice?.order?.distributor?.isNew || isNewAddedSupplier) ||
          !invoice?.order?.distributor?.id
        "
        formKey="edit-supplier"
        ref="supplierName"
        :value="invoice?.order?.distributor?.name"
        name="name"
        @onChange="setUpdatedSupplierName"
        :label="`${$t('global.distributor')}`"
      />
      <div class="suggested-matches">
        <h2 class="title" v-if="suggestedMatches && suggestedMatches.length">Suggested Matches</h2>
        <ez-table
          v-if="suggestedMatches && suggestedMatches.length"
          :data="suggestedMatches"
          :columns="['name', 'action']"
          :headers="{
            name: () => 'Supplier',
            action: () => '',
          }"
          :columnProps="{
            action: { class: 'medium-cell' },
          }"
        >
          <template #cell-name="{ row }">
            <v-distributor-entity-info :distributor="row || {}" />
          </template>
          <template #cell-action="{ row }">
            <ez-button type="primary" @click="onDistributorSelect({ selected: row })">
              Switch
              <font-awesome-icon icon="sync" class="ml-4 mr-0" />
            </ez-button>
          </template>
        </ez-table>
        <hr />
        <ez-autocomplete
          ref="autocomplete"
          placeholder="Search for a supplier"
          :source="`/venues/${venueId}/search/distributors`"
          :hasConnectedParam="false"
          :requestParams="{
            venueId: venueId,
            sameCurrency: 'true',
            fromGroup: true,
            includeTemp: true,
          }"
          searchProperty="name"
          label="Alternatively, search for a Supplier"
          @selected="onDistributorSelect"
        >
          <template #icon>
            <font-awesome-icon icon="search" />
          </template>
          <template #result="{ result }">
            <div class="venue-result">
              <img :src="result.logo" alt="" class="venue-result__image" />
              <div class="venue-result__info">
                <div class="venue-result__title">
                  <span :title="result.name">{{ result.name }}</span>
                  <ez-premium-badge v-if="$helpers.isPremium(result.accountType)" />
                </div>
              </div>
            </div>
          </template>
        </ez-autocomplete>
      </div>
      <hr />
      <footer class="edit-footer mt-24">
        <ez-button @click="cancelEdit" type="link" class="mr-auto">Cancel</ez-button>
        <ez-button @click="updateSupplierName"> Save Changes </ez-button>
      </footer>
    </div>
    <ez-confirmation-modal
      class="confirm-modal"
      v-if="supplierAlternative.name"
      ref="confirmSwitchModal"
    >
      <template #title>Confirm Supplier Switch?</template>
      <template #content>
        If you confirm, the current supplier will be switched to
        <br />
        <p>
          <strong class="mt-16">{{ supplierAlternative.name }}</strong>
        </p>
      </template>
      <template #footer>
        <ez-button @click="close" type="link">Cancel</ez-button>
        <ez-button @click="confirmSwitch" type="primary">Confirm</ez-button>
      </template>
    </ez-confirmation-modal>
  </div>
</template>
<style lang="scss" scoped>
.processing-details {
  &__supplier {
    display: flex;

    .supplier-input {
      width: 50%;
    }
  }
}

.create-new-supplier {
  display: flex;
  align-items: center;

  .badge {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    @extend %flex-center;
    justify-content: center;
    background-color: #e2eaff;
    color: #4d7cfe;
    @include font-size(20px);
  }
}

.venue-result {
  @extend %flex-center;

  &__image {
    @include size(32px);
    border-radius: 50%;
    border: 1px solid #dee1e4;
  }

  &__info {
    margin-left: 8px;
  }

  &__titele {
    color: #252631;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }

  &__label {
    color: #6c7995;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
  }
}

:deep() .selected-supplier {
  .input-group__suffix {
    font-weight: 400;
  }
  .input-group__input input:disabled {
    color: $color-gray-25;
    cursor: auto;
  }

  &--disabled {
    .input-group__input input:disabled {
      cursor: not-allowed;
    }
  }

  &--new .input-group__input--has-prefix input {
    padding-left: 60px;
  }

  .input-group__input--has-suffix input {
    padding-right: 60px;
  }
  &--new {
    .input-group__input input:read-only,
    .input-group__input input:disabled {
      background-color: $color-pastel-blue;
      border: 1px solid #4d7cfe;
    }
    .input-group__suffix {
      color: $color-gray-25;
      font-weight: 400;
    }
    .input-group__suffix svg {
      color: #4d7cfe;
    }
  }

  .input-group__suffix {
    svg,
    .suffix-action {
      cursor: pointer;
    }
  }

  .input-group__prefix {
    display: flex;
    line-height: 1;
    top: 50%;
    transform: translate(0, -50%);

    .status--new {
      background-color: #4d7cfe;
      color: white;
    }
  }
}

.edit-footer {
  display: flex;

  .mr-auto {
    margin-right: auto;
  }
}

.suggested-matches {
  :deep() .button.button--primary {
    background-color: #eedff4;
    color: $color-primary-purple;
  }
  :deep() .autocomplete__label {
    text-transform: none;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 1.6;
    color: $color-gray-25;
    letter-spacing: -0.25px;
    font-weight: 400;
  }
  :deep() .autocomplete__input-group {
    svg {
      color: $color-gray-6C;
    }
  }
}

.edit-box {
  margin-top: 24px;
  border: 1px solid #ccd2e0;
  border-radius: 3px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);
  padding: 24px;

  h2 {
    font-weight: 500;
  }
}
</style>
