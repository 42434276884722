<script>
/**
 * VenueSync
 * @version 1.0.0
 * @since
 */

import EzAlert from '@/components/ui/Alert';
import {
  venueGetConnectedDistributors,
  venueGetContacts,
} from '@/api/endpoints/xero';
import EzTable from '@/components/ui/Table/EzTable';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch/index';
import { mapMutations, mapState } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';

export default {
  mixins: [wizardListenerMixin],
  components: {
    VSelectSearch,
    EzTable,
    EzAlert,
    VVenueEntityInfo,
  },
  inject: ['venueId'],
  data() {
    return {
      contactsData: [],
      connectedDistributors: [],
      tableHeaders: {
        distributorName: () => this.$t('xero.wizzard.venue.step2.distributorName'),
      },
    };
  },
  computed: {
    ...mapState('xero', ['connections', 'contacts']),

    hasConnections() {
      return Object.keys(this.connections).length > 0;
    },
    isContactsEmpty() {
      return Object.keys(this.contacts).length === 0;
    },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  watch: {
    hasConnections() {
      this.enableNextStep();
    },
  },
  methods: {
    ...mapMutations('xero', ['UPDATE_VENUE_SYNC', 'SET_VENUES', 'SET_CONTACTS']),

    onNextStep() {
      this.$emit('stepCompleted');
    },

    onPreviousStep() {
      this.$emit('stepBack');
    },

    xeroSelected(contact, venue) {
      this.UPDATE_VENUE_SYNC({ [venue.id]: contact.xeroId });
      if (!this.contactsData.find(item => item.xeroId === contact.xeroId)) {
        this.contactsData.push(contact);
        this.SET_CONTACTS({ data: this.contactsData });
      }
      this.enableNextStep();
    },
  },
  async mounted() {
    const { data: connectedDistributors } = await venueGetConnectedDistributors(this.venueId);
    const { data: contacts } = await venueGetContacts(this.venueId);

    this.connectedDistributors = connectedDistributors.data;
    this.contactsData = contacts.data;

    this.SET_VENUES({ data: this.connectedDistributors });
    if (this.isContactsEmpty) this.SET_CONTACTS({ data: this.contactsData });

    this.enableNextStep();
  },
};
</script>
<template>
  <div>
    <ez-alert variant="disclaimer" class="mb-24">
      <template #icon><font-awesome-icon icon="exclamation-circle"/></template>
      <span>{{ $t('xero.wizzard.venue.step2.alertContent', { platform: platformName }) }}</span>
    </ez-alert>

    <ez-table
      class="sync-table"
      :disable-hover="true"
      :data="connectedDistributors"
      :columns="['distributorName', 'xeroContact']"
      :headers="tableHeaders"
    >
      <template #cell-distributorName="{ row }">
        <v-venue-entity-info :venue="row.entity" />
      </template>
      <template #cell-xeroContact="{ row }">
        <v-select-search
          :data="contacts.data ? contacts.data : contactsData"
          :selected="connections[row.entity.id]"
          :search="`/venues/${venueId}/xero/contacts`"
          value-property="xeroId"
          placeholder="Select Xero Contact"
          @selected="(obj) => xeroSelected(obj, row.entity)"
        />
      </template>
    </ez-table>
  </div>
</template>
<style lang="scss" scoped>
.sync-table tbody tr td { overflow: visible; }
</style>
