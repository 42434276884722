<script>
/**
 * This is a fake component.
 * @version 1.0.0
 * @since
 */
export default {
  name: 'Account',
};
</script>

<template>
  <div>Account works</div>
</template>

<style lang="scss" scoped>

</style>
