<script>
/**
 * SwitchTabs component enables tab switching
 * @version 1.0.0
 */
export default {
  props: {
    /**
     * Defines default active tab
     */
    defaultTabIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tabs: [],
      activeTabHash: '',
    };
  },
  methods: {
    findTab(hash) {
      let tab = null;

      if (hash) {
        tab = this.tabs.find(t => t.hash === hash);
      }

      if (this.defaultTabIndex < this.tabs.length) {
        tab = tab || this.tabs[this.defaultTabIndex];
      }

      return tab;
    },
    selectTab(hash) {
      const activeTab = this.findTab(hash);

      if (activeTab) {
        this.tabs.forEach((tab) => {
          tab.isActive = tab.hash === activeTab.hash;
        });

        this.activeTabHash = activeTab.hash;

        activeTab.$emit('tabClick');
      }
    },
  },
  render(h) {
    const { tabs, activeTabHash } = this;
    // eslint-disable-next-line dot-notation
    const items = tabs.filter(t => t.$options['_componentTag'] === 'ez-tab')
      .map((tab, key) => {
        const { title, hash } = tab;

        let tabTitle = hash;

        if (typeof title === 'function') {
          tabTitle = title(h, { key });
        } else if (typeof title === 'string') {
          tabTitle = title;
        }

        const button = h(
          'button',
          {
            attrs: { type: 'button' },
            class: [
              'ez-tabs__button', { 'ez-tabs__button-active': hash === activeTabHash },
            ],
            on: {
              click: () => {
                this.selectTab(hash);
              },
            },
          },
          [
            tabTitle,
          ],
        );

        return h(
          'li',
          null,
          [button],
        );
      });

    const header = h('ul', { class: 'ez-tabs__buttons' }, items);

    const body = h('div', { class: 'ez-tabs__content' }, [this.$slots.default]);

    return h('div', { class: 'ez-tabs' }, [
      header,
      body,
    ]);
  },

  mounted() {
    this.tabs = this.$children;

    this.selectTab(window.location.hash);
  },
};

</script>

<style scoped lang="scss">
$hover-text-color: rgba(255, 255, 255, 0.7);
$active-text-color: #FFFFFF;

.ez-tabs {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;

  &__buttons {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 4px;
    list-style: none;
    position: relative;
    top: 5px;
    background-color: $color-gray-F5;
    border-radius: 3px;
    height: 36px;

    li {
      display: flex;
      flex: 1 1 100%;
      max-width: 50%;
      height: 100%;
    }
  }
  &__button {
    @include font-size(14px, 20px);
    font-weight: 600;
    color: $color-gray-6C;
    outline: 0;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    &:hover {
      cursor: pointer;
    }
    &:not(.ez-tabs__button-active):hover {
      color: $color-primary-blue;
    }
  }
  &__button-active {
    background-color: $color-white;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
  }
  &__content {
    flex: 1 1 auto;
    overflow-y: auto;
    border-top: 2px solid #F3F5FA;
  }
}
</style>
