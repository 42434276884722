<script>
/**
 * Invoice
 * @version 1.0.0
 * @since
 */

import { EzConfirmationModal, EzImageModal } from '@/components/ui/Modal';
import EmptyState from '@/views/common/empty-state';
import { EzMultipleImageUpload } from '@/components/ui/ImageUpload';
import EzForm from '@/components/ui/Form';
import flash from '@/components/ui/FlashMessage';
import Order from '@/models/Order';
import bus from '@/views/public/bus';
import VPdfViewer from '@/components/v3/elements/VPdfViewer';
import EzButton from '@/components/ui/Button/EzButton';

export default {
  components: {
    VPdfViewer,
    EzImageModal,
    EmptyState,
    EzButton,
    EzMultipleImageUpload,
    EzForm,
    EzConfirmationModal,
  },
  props: {
    invoice: {
      type: Array,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    token: {
      type: String,
      required: false,
    },
    editingMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    order: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isPublicOrder: {
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      imageData: null,
      formKey: 'invoiceUpload',
      loadingKey: 'invoiceUpload',
      hasInvoiceSelected: false,
    };
  },
  computed: {
    isTypeOfInvoiceObject() {
      return typeof this.invoice === 'object';
    },
    hasInvoice() {
      return this.invoice.length;
    },
    additionalHeaders() {
      return this.token ? { 'X-Public-Token': this.token } : null;
    },
    hasNotMatchingFlag() {
      return this.order.notMatching;
    },
  },
  methods: {
    openInvoice(invoice) {
      if (this.editingMode && this.hasNotMatchingFlag && !this.isPublicOrder) {
        this.$emit('imageModalOpened', invoice);
      } else if (invoice.type !== 'pdf') {
        this.$refs.imageModal.open(invoice.image);
      }
    },
    async imagesUploaded(formData) {
      this.imageData = formData;
      this.hasInvoiceSelected = formData.get('invoice');
    },
    openModal() {
      this.$refs.uploadInvoice.open();
    },
    closeModal() {
      this.$refs.uploadInvoice.close();
    },
    submitInvoice() {
      this.$refs.invoiceForm.onSubmit();
    },
    reset() {
      this.imageData = null;
      this.hasInvoiceSelected = false;
      this.$refs.imageUpload.reset();
    },
    confirmSuccess({ data }) {
      Order.update(data);
      this.reset();
      flash.success({
        title: 'Invoice successfully uploaded',
      });
      this.closeModal();
      bus.$emit('invoiceUploaded'); // Signal public order
      this.$emit('invoiceUploaded', data); // Emit success action
    },
  },
};
</script>
<template>
  <div :class="['invoice', { 'invoice--no-invoice': !hasInvoice }]">
    <template v-if="hasInvoice">
      <ez-image-modal ref="imageModal" />

      <div class="invoice-preview mb-16" v-for="item in invoice" :key="item.name">
        <div class="invoice-preview__image-container">
          <img
            v-if="item.type !== 'pdf'"
            class="invoice-preview__image width-100"
            :src="item.image"
            alt="Invoice Image"
            @click="openInvoice(item)"
            :data-cy="`${dataCy}-${item.name}`"
          />
          <a :href="item.image" target="_blank">
            <v-pdf-viewer
              class="invoice-preview__pdf"
              v-if="item.type === 'pdf'"
              :src="item.image"
              target="_blank"
              :showAllPages="false"
              @click="openInvoice(item)"
              :data-cy="`${dataCy}-${item.name}`"
            />
          </a>
        </div>
        <div class="invoice-preview__info">
          <div class="invoice-preview__title">{{ item.name }}</div>
          <div v-if="item.uploadedAt" class="invoice-preview__date">
            Uploaded on:
            <time :datetime="item.uploadedAt | htmlDateTime">{{ item.uploadedAt | date }}</time>
          </div>
        </div>
      </div>
      <ez-button
        @click="openModal"
        type="secondary"
        form-type="button"
        class="upload-btn-with-invoices"
        :data-cy="`${dataCy}-uploadInvoices`"
      >
        <font-awesome-icon icon="upload" />
        Upload Invoices
      </ez-button>
    </template>
    <template v-else>
      <div class="upload-invoices-wrapper">
        <empty-state class="no-invoices">
          <template #badge><div></div></template>
          <template #title> No invoices here </template>
          <template #info>
            There are no invoices included in this order, but you can still upload one if needed.
          </template>
        </empty-state>
        <ez-button @click="openModal" type="secondary" form-type="button" class="upload-btn">
          <font-awesome-icon icon="upload" />
          Upload Invoices
        </ez-button>
      </div>
    </template>

    <ez-confirmation-modal ref="uploadInvoice" @close="reset" :icon="null">
      <template #title>Upload Invoices</template>
      <template #content>
        <p>Please upload up to 5 invoices max.</p>
        <hr />
        <ez-form
          ref="invoiceForm"
          method="put"
          @success="confirmSuccess"
          submitType="multipart"
          :action="action"
          :additionalData="imageData"
          :form-key="formKey"
          :loading-key="loadingKey"
          :additional-headers="additionalHeaders"
        >
          <ez-multiple-image-upload
            cardType
            ref="imageUpload"
            accept="image/jpeg,image/png,application/pdf"
            :formKey="formKey"
            :number-of-files="5"
            name="invoice"
            @change="imagesUploaded"
            class="upload-component"
            :data-cy="`${dataCy}-upload`"
          >
            <template #label>Invoices</template>
            <template #cta>Upload Invoices</template>
          </ez-multiple-image-upload>
        </ez-form>
      </template>
      <template #footer>
        <ez-button type="link" @click="closeModal" :data-cy="`${dataCy}-cancel`">
          Cancel
        </ez-button>
        <ez-button
          @click="submitInvoice"
          :disabled="!hasInvoiceSelected"
          :data-cy="`${dataCy}-confirm`"
        >
          Upload
        </ez-button>
      </template>
    </ez-confirmation-modal>
  </div>
</template>
<style lang="scss" scoped>
$image-height: 64px;
$image-max-width: 120px;

.invoice {
  padding: 24px 0;
  &--no-invoice {
    display: flex;
    justify-content: center;
  }
  .upload-btn-with-invoices {
    width: 100%;
  }
}

.invoice-preview__image-container {
  height: $image-height;
  width: $image-max-width;

  border-radius: 3px;
  margin-right: 16px;
}

:deep() .invoice-preview {
  display: flex;
  align-items: center;

  &__image {
    height: $image-height;
    max-width: $image-max-width;
    object-fit: cover;

    cursor: pointer;
    border-radius: inherit;
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__pdf {
    display: block;
    height: 100%;
    width: 100%;
    overflow: hidden;
    .pdf-page-canvas {
      width: 100%;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 16px;
    margin-bottom: 6px;
  }

  &__date {
    color: #6c7995;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: -0.25px;
    line-height: 14px;
  }
  &:not(&:last-child) {
    margin-bottom: 8px;
  }
}

:deep() .preview {
  justify-content: center;
}

.upload-invoices-wrapper {
  display: flex;
  align-content: center;
  flex-direction: column;

  :deep() .no-invoices {
    margin-top: 0;

    .ez-empty-state__image {
      max-width: none;
      img {
        margin-bottom: px-to-rem(24px);
        width: 219px;
        height: 116px;
      }
    }
  }

  .upload-btn {
    width: 368px;
    margin-top: 16px;
  }
}
.upload-component {
  :deep() .preview {
    &__images {
      display: flex;
      flex-direction: column;
    }
  }
  :deep() .preview__image + .preview__image {
    margin-left: 0;
  }
}
</style>
