<template>
  <div class="order-container">
    <div class="order-container__header">
      <!-- distributor and/or venue info + amount section -->
      <section
        :class="['order-container__info-and-amount-section', { 'editing-mode': editingMode }]"
      >
        <div v-if="hasVenueSlot">
          <div class="label">{{ $t('global.venue') }}</div>
          <slot name="venue"></slot>
        </div>
        <div v-if="hasDistributorSlot">
          <div class="label">{{ $t('global.distributor') }}</div>
          <slot name="distributor"></slot>
        </div>
      </section>

      <!-- order number and dates section -->
      <div v-if="!isQuote" class="order-container__number-and-dates-section">
        <div
          v-if="!!$slots.orderNumber"
          :class="['order-container__number-and-dates-item', { 'editing-mode': editingMode }]"
        >
          <div class="label">Order Number</div>
          <div class="value" :data-cy="commonCy.SINGLE_ORDER.TEXT__ORDER_NUMBER">
            <slot name="orderNumber"></slot>
          </div>
        </div>
        <div v-if="!!$slots.requestedDate" :class="['order-container__number-and-dates-item']">
          <slot name="orderDateLabel">
            <div class="label">Order Date</div>
          </slot>
          <div class="value" :data-cy="commonCy.SINGLE_ORDER.TEXT__REQUESTED_DATE">
            <slot name="requestedDate"></slot>
          </div>
        </div>
        <div
          v-if="!!$slots.deliveryOn && !isPublicOrder"
          class="order-container__number-and-dates-item"
        >
          <slot name="deliveryLabel">
            <div class="label">Delivery Date</div>
          </slot>
          <div class="value value--deliveryOn" :data-cy="commonCy.SINGLE_ORDER.TEXT__DELIVERY_DATE">
            <slot name="deliveryOn"></slot>
          </div>
        </div>
        <div v-if="!!$slots.dueDate" class="order-container__number-and-dates-item">
          <slot name="dueDateLabel">
            <div class="label">Due Date</div>
          </slot>
          <div class="value value--deliveryOn" :data-cy="''">
            <slot name="dueDate"></slot>
          </div>
        </div>
        <div v-if="!!$slots.invoiceDate" class="order-container__number-and-dates-item">
          <slot name="invoiceDateLabel">
            <div class="label">Invoice Date</div>
          </slot>
          <div class="value value--deliveryOn" :data-cy="''">
            <slot name="invoiceDate"></slot>
          </div>
        </div>
        <div v-if="!!$slots.invoiceNumber" :class="['order-container__number-and-dates-item']">
          <slot name="invoiceNumberLabel">
            <div class="label">Invoice Number</div>
          </slot>
          <div class="value value--deliveryOn" :data-cy="''">
            <slot name="invoiceNumber"></slot>
          </div>
        </div>
        <div
          v-if="hasResolvedDateSlot"
          :class="['order-container__number-and-dates-item', { 'editing-mode': editingMode }]"
        >
          <div class="label">
            <slot name="resolvedDateLabel"></slot>
          </div>
          <div class="value">
            <slot name="resolvedDate"></slot>
          </div>
        </div>

        <div
          v-if="!!$slots.orderCreated"
          :class="['order-container__number-and-dates-item', { 'editing-mode': editingMode }]"
        >
          <div class="label">Order Created</div>
          <div class="value">
            <slot name="orderCreated"></slot>
          </div>
        </div>

        <div
          v-if="!!$slots.repeat"
          :class="['order-container__number-and-dates-item', { 'editing-mode': editingMode }]"
        >
          <div class="label">Repeat Every</div>
          <div class="value">
            <slot name="repeat"></slot>
          </div>
        </div>

        <div
          v-if="!!$slots.nextOrder"
          :class="['order-container__number-and-dates-item', { 'editing-mode': editingMode }]"
        >
          <div class="label">Next Order On</div>
          <div class="value">
            <slot name="nextOrder"></slot>
          </div>
        </div>

        <div
          v-if="!!$slots.scheduled"
          :class="['order-container__number-and-dates-item', { 'editing-mode': editingMode }]"
        >
          <div class="label">Scheduled Order Date</div>
          <div class="value">
            <slot name="scheduled"></slot>
          </div>
        </div>
      </div>

      <div v-else class="order-container__number-and-dates-section">
        <div v-if="!!$slots.accRep" class="order-container__number-and-dates-item">
          <div class="label">Account Rep</div>
          <div class="value"><slot name="accRep"></slot></div>
        </div>
        <div class="order-container__number-and-dates-item">
          <div class="label">Quote Date</div>
          <div class="value"><slot name="quoteDate"></slot></div>
        </div>
        <div class="order-container__number-and-dates-item">
          <div class="label">Quote Deadline</div>
          <div class="value"><slot name="quoteDeadline"></slot></div>
        </div>
      </div>

      <div
        v-if="secondaryLine && !isQuote && !isPublicOrder"
        class="order-container__number-and-dates-section"
      >
        <hr class="section-divider" />

        <div v-if="!!$slots.driver" :class="['order-container__number-and-dates-item']">
          <slot name="driverLabel">
            <div class="label">Driver</div>
          </slot>
          <div class="value value--deliveryOn" :data-cy="''">
            <slot name="driver"></slot>
          </div>
        </div>
        <div
          v-if="!!$slots.accRep"
          :class="['order-container__number-and-dates-item', { 'editing-mode': editingMode }]"
        >
          <div class="label">Account Rep</div>
          <div class="value"><slot name="accRep"></slot></div>
        </div>
        <div
          v-if="!!$slots.fulfilmentWarehouse"
          :class="['order-container__number-and-dates-item', { 'editing-mode': editingMode }]"
        >
          <div class="label">Warehouse</div>
          <div class="value"><slot name="fulfilmentWarehouse"></slot></div>
        </div>
        <div v-if="!!$slots.poNumber" class="order-container__number-and-dates-item">
          <slot name="poNumberLabel">
            <div class="label">Reference Number</div>
          </slot>
          <div class="value value--deliveryOn" :data-cy="''">
            <slot name="poNumber"></slot>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!!$slots.deliveryOn && isPublicOrder"
      class="order-container__number-and-dates-item mt-12 highlighted"
    >
      <slot name="deliveryLabel">
        <div class="label">Delivery Date</div>
      </slot>
      <div class="value value--deliveryOn" :data-cy="commonCy.SINGLE_ORDER.TEXT__DELIVERY_DATE">
        <slot name="deliveryOn"></slot>
      </div>
    </div>

    <!-- tabs section -->
    <section class="order-container__tabs-section">
      <slot></slot>
    </section>
  </div>
</template>

<script>
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';

export default {
  props: {
    editingMode: {
      type: Boolean,
      required: false,
    },
    isQuote: {
      type: Boolean,
      default: false,
    },
    isPublicOrder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      commonCy,
    };
  },
  computed: {
    hasVenueSlot() {
      return !!this.$slots.venue;
    },
    hasDistributorSlot() {
      return !!this.$slots.distributor;
    },
    hasResolvedDateSlot() {
      return !!this.$slots.resolvedDate && !!this.$slots.resolvedDateLabel;
    },
    secondaryLine() {
      return (
        this.$slots.driverLabel ||
        this.$slots.driver ||
        this.$slots.poNumber ||
        this.$slots.accRep ||
        this.$slots.fulfilmentWarehouse
      );
    },
  },
};
</script>

<style scoped lang="scss">
$text-white: #ffffff;
$header-padding: 0 2em 0;
$table-border-color: #dee1e4;
$number-and-date-padding-top: 10px;
$number-and-date-padding-leftRight: 16px;

.order-container {
  font-size: 0.75rem;

  &__header {
    padding: $header-padding;
    transition: opacity 0.3s ease-in-out;
  }

  &__info-and-amount-section {
    display: flex;
    border-bottom: 1px dashed #dee1e4;
    padding-bottom: 1rem;

    > div {
      max-width: 100%; // Prevent long text expending div
      flex: 1 1 auto;
    }

    .label {
      margin-bottom: 1em;
      color: $color-gray-6C;
      font-weight: 500;
    }

    :deep() .entity-info {
      @include font-size(14px);
      color: $color-gray-25;
      font-weight: 500;

      flex: 1;
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      a {
        @include font-size(14px);
        text-decoration: none;
        color: $color-gray-25;
        font-weight: 500;
      }

      .entity-details {
        @include font-size(12px);
        padding-left: 1em;

        &__name {
          @include font-size(14px);
          margin-bottom: 1em;
        }

        &__contact {
          margin-bottom: 0.5em;

          span:first-child {
            margin-right: 0.5em;
          }

          span:last-child {
            color: $secondary-color;
          }
        }
      }
    }
  }

  &__number-and-dates-section {
    display: flex;
    padding: $number-and-date-padding-top $number-and-date-padding-leftRight;
    margin-top: 1.5em;
    background-color: $color-gray-F5;

    & + & {
      margin-top: 0;
      position: relative;

      > :nth-child(-n + 2) {
        border-left: 0;
      }
    }

    > * + * {
      border-left: 1px solid #dddfe6;
    }
  }

  &__number-and-dates-item {
    @include font-size(11px);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 100%;

    * + * {
      margin-top: 0.25rem;
    }
    .value {
      text-align: center;
      line-height: 18px;
    }
  }

  &__tabs-section {
    padding: 0 24px;
    :deep() .ez-tabs__buttons {
      li:first-child {
        .ez-tabs__button {
          margin-left: 0;
        }
      }
    }
  }

  .label {
    @extend %gray-label;
  }

  .value {
    @include font-size(14px);

    &--deliveryOn {
      font-weight: 400;
    }
  }

  .amount {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: right;
  }

  :deep() .table {
    margin-top: 0.5rem;
    border-bottom: 1px solid $table-border-color;

    thead {
      tr {
        th:first-child {
          padding-left: 0;
        }

        th:last-child {
          padding-right: 0;
        }
      }
    }

    tbody {
      tr {
        td:first-child {
          padding-left: 0;
        }

        td:last-child {
          padding-right: 0;
        }
      }
    }
  }

  :deep() .notes {
    margin: 1rem 1.5rem;
  }

  &__tabs-section {
    margin-top: 0.75rem;
  }
}

.editing-mode {
  opacity: 0.5;
  pointer-events: none;
}

.section-divider {
  margin: 0;
  width: calc(100% - $number-and-date-padding-leftRight * 2);
  position: absolute;
  top: 0;
}

@media (max-width: 556px) {
  .order-container__number-and-dates-section {
    flex-direction: column;
    padding-top: 0;
    padding-bottom: 0;
    .order-container__number-and-dates-item {
      padding: 10px 0;

      & + .order-container__number-and-dates-item {
        border-top: 1px dashed #dee1e4;
        border-left: none;
      }
    }
  }
  .order-container__header .order-container__info-and-amount-section {
    :deep() .entity-info {
      align-items: flex-start;
      .button {
        margin-left: 0;
      }

      &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .venue-info {
          margin-bottom: 8px;
        }
      }
    }
  }
  :deep() .media-info {
    white-space: initial;
  }
}

.highlighted {
  border: 1px solid #e1e5ed;
  border-radius: 3px;
  padding-top: 12px;
  padding-bottom: 12px;
  .single-order__delivery-label-container {
    align-items: center;
  }
  svg {
    margin: 0;
  }
  .value {
    font-size: 20px;
    line-height: 20px;
    color: $color-gray-25;
    letter-spacing: 0.25px;
  }
}
</style>
