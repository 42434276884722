<template>
<ez-confirmation-modal @close="onClose" ref="approveModal">
  <template #title>Approve Order?</template>
  <template #content>
    <p>Please review before submitting purchase order.</p>

    <ez-textarea
      :placeholder="`Leave a note. (Optional)`"
      formKey="note"
      name="message"
      label="Note"
      class="mt-16"
      @onChange="onNoteChange"
    />
  </template>
  <template #footer>
    <ez-button @click="close" type="link">Cancel</ez-button>
    <ez-button @click="approveOrder" :is-loading="isLoading">Submit</ez-button>
  </template>
</ez-confirmation-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import flash from '@/components/ui/FlashMessage';
import { LOADING_KEY } from '@/util/constants';
import EzTextarea from '@/components/ui/Textarea/EzTextarea';
/**
 * ApproveOrder
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    EzConfirmationModal,
    EzButton,
    EzTextarea,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      note: '',
    };
  },
  computed: {
    hasNotes() {
      return (this.order.notes || [])
        .filter(n => !!n.message || (n.images || []).length).length;
    },
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.APPROVE_ORDER);
    },
    excludedProductsIds() {
      return (this.order.orderedProducts || []).filter(p => p.isDeclined).map(a => a.id);
    },
  },
  methods: {
    ...mapActions('entities/orders', ['venueOrderAction']),
    ...mapActions('entities/users', ['updateVenueContext']),
    open() { this.$refs.approveModal.open(); },
    close() { this.$refs.approveModal.close(); },
    onClose() {
      this.note = '';
    },
    onNoteChange(ev) {
      this.note = ev;
    },
    async approveOrder() {
      await this.venueOrderAction({
        id: this.order.id,
        action: 'approve',
        message: this.note,
        declinedProducts: this.excludedProductsIds,
      });
      this.close();
      this.$emit('success');

      flash.success({
        title: 'Order approved.',
        message: 'You have successfully approved the order.',
      });
      this.updateVenueContext();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
