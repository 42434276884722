<script>
import { mapState, mapActions } from 'vuex';
import Group from '@/models/Group';
import EzOnOff from '@/components/ui/OnOff';
import EzForm from '@/components/ui/Form';
import flash from '@/components/ui/FlashMessage';
import EzButton from '@/components/ui/Button/EzButton.vue';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  components: {
    EzOnOff,
    EzForm,
    EzButton,
  },
  data() {
    return {
      formKey: 'venue-group-settings',
      loadingKeyForm: 'venue-update-group-settings',
      shouldMirrorVenueToGroup: false,
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    venueGroup() {
      return Group.query().first() || {};
    },
  },
  async created() {
    await this.fetchGroup();
    this.shouldMirrorVenueToGroup = this.venueGroup.shouldMirrorVenueToGroup || false;
  },
  methods: {
    ...mapActions('entities/groups', ['fetchGroup']),
    async saveChanges() {
      try {
        this.$refs.form.onSubmit();
        flash.success({ title: 'Venue group settings successfully updated!' });
      } catch (e) {
        flash.error({ title: 'Something went wrong!' });
      }
    },
    transformer(data) {
      if (!this.shouldMirrorVenueToGroup)
        data = { ...data, shouldMirrorVenueToGroup: this.shouldMirrorVenueToGroup.toString() };
      return data;
    },
  },
};
</script>
<template>
  <div class="sales-settings">
    <ez-form
      :formKey="formKey"
      :loading-key="loadingKeyForm"
      :transformer="transformer"
      action="/venue-group"
      method="patch"
      ref="form"
    >
      <div class="sales-settings__section sales-settings__section--notifications container--small">
        <div class="help-block">
          <h4 class="help-block__title custom-title">
            Mirror Suppliers and Products among Outlets
          </h4>
          <p class="help-block__text mb-0">
            When enabled, any supplier or product added to a single outlet will be added to all
            outlets in the group.
          </p>
        </div>
        <ez-on-off
          :formKey="formKey"
          name="shouldMirrorVenueToGroup"
          :value="shouldMirrorVenueToGroup.toString()"
          :checked="shouldMirrorVenueToGroup"
          @change="$ev => (shouldMirrorVenueToGroup = $ev)"
        />
      </div>

      <ez-button class="mt-24" form-type="submit">Save Changes</ez-button>
    </ez-form>
  </div>
</template>

<style lang="scss" scoped>
.sales-settings {
  &__section {
    display: flex;
    align-items: flex-start;

    &--notifications {
      .help-block {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }

    .help-block {
      &__title {
        &.custom-title {
          font-weight: 600;
        }
      }

      &__text {
        @include font-size(14px, 20px);
      }
    }
  }
}
.price-change-input {
  margin-bottom: 1rem;
}

.price-change-input__title {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.price-change-input__info {
  @include font-size(12px);
  color: $color-gray-6C;
  margin: 0;
  line-height: px-to-rem(18px);
}

.price-change-input__box {
  height: 36px;
  margin: 12px 0;

  :deep() .input-group {
    max-width: 56px;
    margin: 0 8px;
    text-align: right;
  }
}
.price-change-input__label {
  @include font-size(14px);
}
</style>
