<template>
  <ez-user-settings-modal
    ref="modal"
    :formKey="formKey"
    :formAction="formAction"
    :additional-headers="{ loadingKey }"
  >
    <template #title>Change Email</template>

    <template #modalDescription>{{ description }}</template>

    <ez-input
      :formKey="formKey"
      name="email"
      placeholder="Enter New Email Address"
      label="New Email Address"></ez-input>

    <ez-input
      :formKey="formKey"
      type="password"
      name="password"
      label="Confirm Password"
      placeholder="Enter Your Password"
      autocomplete="new-password"></ez-input>
  </ez-user-settings-modal>
</template>

<script>
import EzInput from '@/components/ui/Input';
import { LOADING_KEY } from '@/util/constants';
import EzUserSettingsModal from './EzUserSettingsModal.vue';

export default {
  components: {
    EzInput,
    EzUserSettingsModal,
  },
  props: {
    formAction: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formKey: 'change-email',
      loadingKey: LOADING_KEY.VENUE_CHANGE_EMAIL,
      description: 'We will send a confirmation email to the address entered. Email address will not be changed until you click the confirmation link in the email to confirm your new email address.',
    };
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
