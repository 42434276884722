var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"overview-actions mt-24"},[(_vm.$permission.has('createOrder') && !_vm.$permission.isFree && !_vm.groupViewMode)?_c('div',{staticClass:"dropdown-button"},[_c('ez-button',{attrs:{"data-cy":_vm.outletCy.ORDERS.GENERAL.BUTTON__LOG_OFFLINE_ORDER},on:{"click":function($event){return _vm.openUploadInvoice('invoice')}}},[_vm._v(" Upload Invoice ")]),_c('ez-button-dropdown',{attrs:{"buttonType":"primary"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"angle-down"}})]},proxy:true},{key:"dropdown",fn:function(){return [_c('ez-button',{attrs:{"type":"secondary","data-cy":_vm.outletCy.ORDERS.GENERAL.BUTTON__LOG_OFFLINE_ORDER},on:{"click":function($event){return _vm.openUploadInvoice('credit_note')}}},[_vm._v(" Upload Credit Note ")])]},proxy:true}],null,false,985384014)})],1):_vm._e()]),_c('div',{staticClass:"overview mt-24"},[_c('div',{staticClass:"stats"},[(!_vm.groupViewMode)?_c('router-link',{attrs:{"to":{
          name: 'venue-pending-updates',
          query: {
            processingStatus: 'action_needed',
          },
        }}},[_c('v-rich-stats-box',{staticClass:"mb-24",attrs:{"backgroundColor":"#FFF8EE","accentColor":"#FBE7C7","textColor":"#FC773B"}},[_c('template',{slot:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'file-invoice-dollar']}})],1),_c('template',{slot:"label"},[_vm._v("Invoices with action needed")]),_c('template',{slot:"value"},[_vm._v(_vm._s(_vm.statistics.pendingInvoicesCount))])],2)],1):_vm._e(),(!_vm.groupViewMode)?_c('router-link',{attrs:{"to":{
          name: 'venue-orders-history',
          query: {
            status: _vm.orderStatusFilter,
          },
        }}},[_c('v-rich-stats-box',{staticClass:"mb-24",attrs:{"backgroundColor":"#FDF5FF","accentColor":"#EEDFF4","textColor":"#983BBD"}},[_c('template',{slot:"icon"},[_c('font-awesome-icon',{attrs:{"icon":['fa', 'list-alt']}})],1),_c('template',{slot:"label"},[_vm._v("Orders awaiting approval")]),_c('template',{slot:"value"},[_vm._v(_vm._s(_vm.statistics.ordersApprovalCount))])],2)],1):_vm._e(),_c('router-link',{attrs:{"to":{
          name: 'venue-all-suppliers-products',
          query: {
            productGroup: 'unassigned',
          },
        }}},[_c('v-rich-stats-box',{staticClass:"mb-24",attrs:{"backgroundColor":"#EFFBF7","accentColor":"#DAF1E9","textColor":"#1BA975","compactMode":!_vm.groupViewMode}},[_c('template',{slot:"icon"},[_c('font-awesome-icon',{attrs:{"icon":"coffee"}})],1),_c('template',{slot:"label"},[_vm._v("Products without groups")]),_c('template',{slot:"value"},[_vm._v(_vm._s(_vm.statistics.productsWithoutGroupCount))])],2)],1),_c('router-link',{attrs:{"to":{
          name: 'venue-all-suppliers',
          params: {
            orderingMethod: _vm.orderingMethodFilter,
          },
        }}},[_c('v-rich-stats-box',{attrs:{"backgroundColor":"#F5F6FA","accentColor":"#E1E5ED","textColor":"#495063","compactMode":!_vm.groupViewMode}},[_c('template',{slot:"icon"},[_c('font-awesome-icon',{attrs:{"icon":"truck"}})],1),_c('template',{slot:"label"},[_vm._v("Suppliers without contacts")]),_c('template',{slot:"value"},[_vm._v(_vm._s(_vm.statistics.distributorWithoutContactsCount))])],2)],1)],1),_c('div',{staticClass:"tables"},[_c('div',{staticClass:"table"},[_c('div',{staticClass:"table-header"},[_c('h2',[_vm._v("Recent New Products")]),_c('router-link',{staticClass:"see-all-action mb-16",attrs:{"to":{
              name: 'venue-all-suppliers-products',
              params: {
                newFilter: true,
              },
            }}},[_c('span',{staticClass:"mr-8"},[_vm._v("See All")]),_c('font-awesome-icon',{staticStyle:{"font-size":"20px"},attrs:{"icon":"angle-right"}})],1)],1),(_vm.newProducts.length)?_c('div',{staticClass:"no-min-height"},[_c('ez-table',{attrs:{"headers":{
              name: () => 'Product',
              price: () => 'Price',
              distributor: () => `${_vm.$t('global.distributor').toLowerCase()}`,
            },"columns":['name', 'distributor', 'price'],"columnProps":{
              price: { class: 'price-cell' },
            },"data":_vm.newProducts},scopedSlots:_vm._u([{key:"cell-name",fn:function({ row: { image, sku, name, orderingUnit } }){return [_c('ez-entity-info',{attrs:{"imgUrl":image,"imgHasBorder":true}},[_c('div',{staticClass:"product-info"},[_c('span',{staticClass:"product-info__name",attrs:{"title":name}},[_vm._v(" "+_vm._s(name)+" ")]),_c('span',{staticClass:"product-info-secondary"},[_c('span',[_vm._v(_vm._s(orderingUnit.label))]),_vm._v(" • "+_vm._s(sku)+" ")])])])]}},{key:"cell-distributor",fn:function({ row: { distributor } }){return [_c('v-distributor-entity-info',{attrs:{"distributor":distributor}})]}},{key:"cell-price",fn:function({ row: { price, priceUnit } }){return [_c('v-price',{attrs:{"price":price || 0,"unit":priceUnit && priceUnit.label,"is-market-price":!price}})]}}],null,false,3941341119)})],1):_c('div',{staticClass:"empty-state"},[_c('p',{staticClass:"empty-state__label"},[_vm._v("No new products to show")]),_c('empty-state',{scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/no-products.svg"),"alt":""}})]},proxy:true}])})],1)]),_c('div',{staticClass:"table mt-24"},[_c('div',{staticClass:"table-header"},[_c('h2',[_vm._v("Recent Price Changes")]),_c('router-link',{staticClass:"see-all-action mb-16",attrs:{"to":{
              name: 'venue-reports-invoices',
              params: {
                onlyWithChange: true,
                ...(_vm.priceChangesMeta.from ? { from: _vm.priceChangesMeta.from } : {}),
                ...(_vm.priceChangesMeta.to ? { to: _vm.priceChangesMeta.to } : {}),
              },
            }}},[_c('span',{staticClass:"mr-8"},[_vm._v("See All")]),_c('font-awesome-icon',{staticStyle:{"font-size":"20px"},attrs:{"icon":"angle-right"}})],1)],1),(_vm.priceChanges.length)?_c('div',{staticClass:"no-min-height"},[_c('ez-table',{attrs:{"headers":{
              name: () => 'Product',
              oldPrice: () => 'Reference Price',
              newPrice: () => 'New Price',
              priceChange: () => 'Price Change',
              distributor: () => `${_vm.$t('global.distributor').toLowerCase()}`,
            },"columns":['name', 'distributor', 'oldPrice', 'newPrice', 'priceChange'],"columnProps":{
              priceChange: { class: 'price-cell price-change-cell' },
              newPrice: { class: 'price-cell' },
              oldPrice: { class: 'price-cell' },
            },"data":_vm.priceChanges},scopedSlots:_vm._u([{key:"cell-name",fn:function({ row: { image, sku, name, orderingUnit } }){return [_c('ez-entity-info',{attrs:{"imgUrl":image,"imgHasBorder":true}},[_c('div',{staticClass:"product-info"},[_c('span',{staticClass:"product-info__name",attrs:{"title":name}},[_vm._v(" "+_vm._s(name)+" ")]),_c('span',{staticClass:"product-info-secondary"},[_c('span',[_vm._v(_vm._s(orderingUnit.label))]),_vm._v(" • "+_vm._s(sku)+" ")])])])]}},{key:"cell-distributor",fn:function({ row: { distributor } }){return [_c('v-distributor-entity-info',{attrs:{"distributor":distributor}})]}},{key:"cell-oldPrice",fn:function({ row: { oldPrice, priceUnit } }){return [_c('v-price',{attrs:{"price":oldPrice || 0,"unit":priceUnit && priceUnit.label}})]}},{key:"cell-newPrice",fn:function({ row: { newPrice, priceUnit } }){return [_c('v-price',{attrs:{"price":newPrice || 0,"unit":priceUnit && priceUnit.label}})]}},{key:"cell-priceChange",fn:function({ row: { priceChangeAmount, priceChangePercent } }){return [_c('div',{staticClass:"price-change__amount"},[(priceChangeAmount > 0)?_c('span',{staticClass:"price-red"},[_vm._v("+")]):_vm._e(),_c('v-price',{class:{
                    'price-green': priceChangeAmount < 0,
                    'price-red': priceChangeAmount > 0,
                  },attrs:{"price":priceChangeAmount}})],1),_c('span',{staticClass:"percent"},[_vm._v(_vm._s(priceChangeAmount > 0 ? '+' : '')+_vm._s(priceChangePercent)+"%")])]}}],null,false,2073324284)})],1):_c('div',{staticClass:"empty-state"},[_c('p',{staticClass:"empty-state__label"},[_vm._v("No price changes to show")]),_c('empty-state',{scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/no-reports.svg"),"alt":""}})]},proxy:true}])})],1)])]),_c('ez-loader',{attrs:{"show":_vm.isLoading}},[_vm._v("Loading...")]),_c('upload-invoice',{ref:"uploadInvoice",attrs:{"fileType":_vm.fileType},on:{"uploadFinished":() => (this.fileType = '')}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }