<template>
  <ez-tabs>
    <ez-tab
      title="Included products"
      hash="tab-1"
      :data-cy="commonCy.SINGLE_ORDER.TABS.BUTTON__INCLUDED_PRODUCTS"
    >
      <editable-order
        :type="type"
        :is-public-order="isPublicOrder"
        :is-simple-order="isSimpleOrder"
        :editing-mode="editingMode"
        :filter-by="{ prop: 'isIncluded', value: true }"
        @isSomeUnavailable="e => $emit('isSomeUnavailable', e)"
      />
    </ez-tab>
    <ez-tab
      title="Excluded
      products"
      hash="tab-2"
      :data-cy="commonCy.SINGLE_ORDER.TABS.BUTTON__EXCLUDED_PRODUCTS"
    >
      <ez-order-products :order="order" :products="excludedProducts" />
    </ez-tab>
    <ez-tab
      :title="notesTitle"
      hash="tab-3"
      :data-cy="commonCy.SINGLE_ORDER.TABS.BUTTON__NOTES"
      @tabClick="markNote"
    >
      <ez-notes v-if="hasNotes" :order="order" />
      <empty-state v-else>
        <template #badge><img src="@/assets/no-notes-state.svg" alt="" /></template>
        <template #title>No notes here</template>
        <template #info>There are no notes included in this order.</template>
      </empty-state>
    </ez-tab>
    <ez-tab
      v-if="showHistory"
      :title="$t('orders.orderHistoryTab')"
      hash="tab-4"
      :data-cy="commonCy.SINGLE_ORDER.TABS.BUTTON__HISTORY"
    >
      <timeline :history="order.historyLog" :currency="order.currency" />
    </ez-tab>
    <ez-tab
      v-if="showInvoice || scheduleForDeliveryShowInvoice"
      :title="$t('orders.orderInvoiceTab')"
      hash="tab-invoice"
      :data-cy="commonCy.SINGLE_ORDER.TABS.BUTTON__INVOICE"
    >
      <invoice
        @invoiceUploaded="ev => $emit('invoiceUploaded', ev)"
        :invoice="order.invoices || []"
        :token="token"
        :action="isPublicOrder ? invoiceAction : invoiceUploadAction"
        :editing-mode="editingMode"
        :order="order"
        :is-public-order="isPublicOrder"
        @imageModalOpened="inv => $emit('imageModalOpened', inv)"
      />
    </ez-tab>
    <ez-tab v-if="hasLinkedPlatforms" title="Linked Platforms" hash="tab-linkedPlatforms">
      <linked-platforms :data="order.integrationLinks" />
    </ez-tab>
  </ez-tabs>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import {
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_COMPLETED_AFTER_DISPUTE,
  ORDER_STATUS_IN_DISPUTE,
  ORDER_STATUS_SHIPPED,
} from '@/util/constants';
import EmptyState from '@/views/common/empty-state';
import { EzNotes, EzNotesTitle } from '@/views/common/orders/notes';
import EzOrderProducts from '@/views/common/orders/products';
import { EzTabs, EzTab } from '@/components/ui/Tabs';
import Timeline from '@/views/common/orders/history-log/Timeline';
import Invoice from '@/views/common/orders/Invoice/Invoice';
import EditableOrder from '@/views/common/orders/EditableOrder';
import { isExcluded } from '@/util/utils';
import LinkedPlatforms from '@/views/common/orders/linked-platforms';

export default {
  components: {
    EditableOrder,
    Invoice,
    EmptyState,
    EzOrderProducts,
    EzNotes,
    EzTabs,
    EzTab,
    Timeline,
    LinkedPlatforms,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    enableEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    editingMode: {
      type: Boolean,
      required: false,
    },
    isPublicOrder: {
      type: Boolean,
      default: false,
    },
    isSimpleOrder: {
      type: Boolean,
      required: false,
      default: false,
    },
    invoiceAction: {
      required: false,
    },
    token: {
      required: false,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      commonCy,
    };
  },
  computed: {
    ...mapState('entities/users', ['contextId']),
    ...mapGetters('entities/users', ['isVenue', 'isDistributor']),
    hasNotes() {
      return (this.order.notes || []).length;
    },
    showHistory() {
      return (this.order.historyLog || []).length;
    },
    hasLinkedPlatforms() {
      return (this.order.integrationLinks || []).length;
    },
    showInvoice() {
      const hasInvoices = this.order.invoices?.length;
      const isRightStatus = [
        ORDER_STATUS_COMPLETED,
        ORDER_STATUS_COMPLETED_AFTER_DISPUTE,
        ORDER_STATUS_IN_DISPUTE,
      ].includes(this.order.status);

      return hasInvoices || isRightStatus;
    },
    scheduleForDeliveryShowInvoice() {
      const hasInvoices = this.order.invoices?.length;
      const isRightStatus = [ORDER_STATUS_SHIPPED].includes(this.order.status);

      return hasInvoices && isRightStatus;
    },
    excludedProducts() {
      return (this.order.orderedProducts || []).filter(isExcluded);
    },
    invoiceUploadAction() {
      let action = '';
      if (this.isVenue) {
        action = `/venues/${this.contextId}/orders/${this.order.id}/invoice`;
      } else if (this.isDistributor) {
        action = `distributor/orders/${this.order.id}/invoice`;
      } else {
        action = `admin/orders/${this.order.id}/invoice`;
      }
      return action;
    },
  },
  methods: {
    ...mapActions('entities/orders', ['markVenueMessage', 'markDistributorMessage']),
    notesTitle(h, { key }) {
      return h(EzNotesTitle, { key, props: { order: this.order } });
    },
    markNote() {
      const isVenue = localStorage.getItem('role') === 'venue';
      const isDistributor = localStorage.getItem('role') === 'distributor';

      if (this.order.isMessageRead) {
        return;
      }

      if (isVenue) {
        this.markVenueMessage({ id: this.order.id });
        return;
      }

      if (isDistributor) {
        this.markDistributorMessage({ id: this.order.id });
      }
    },
  },
};
</script>

<style scoped lang="scss">
:deep() .ez-empty-state {
  padding: 3rem 0;

  img {
    width: 200px;
    height: 88px;
  }
}
:deep() .alert {
  margin: 1rem 0;

  p {
    font-weight: 400;
    letter-spacing: 0;
  }
}
</style>
