<script>
/**
 * SelectedItem
 * @version 1.0.0
 * @since 2.4.0
 */

import EzEntityInfo from '@/components/ui/EntityInfo';
import EzPremiumBadge from '@/components/ui/PremiumBadge';
import EzLiteBadge from '@/components/ui/LiteBadge';

export default {
  components: {
    EzEntityInfo,
    EzPremiumBadge,
    EzLiteBadge,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: null,
    },
    /**
     * true: Display only outlet logo and name, without
     * any additional info
     * false: Display everything
     */
    onlyBasicInfo: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideRemoveButton: {
      required: false,
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
    removeButtonDataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isSendOnboarding: false,
    };
  },
  computed: {
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
    hasItem() { return !!this.item && Object.keys(this.item).length; },
    hoursOfOperation() {
      return this.item.workingHours;
    },
    additionalData() {
      return {
        name: this.item.name,
        [this.item.xeroId ? 'xeroId' : 'venueId']: this.item.xeroId ? this.item.xeroId : this.item.id,
      };
    },
    isPremiumOrIsLite() {
      return this.$helpers.isPremium(this.item.accountType)
        || this.$helpers.isLite(this.item.accountType);
    },
  },
  methods: {
    addressFormat(data) {
      return [
        ...(data.street ? [`${data.street}`] : []),
        ...(data.city ? [`${data.zipCode} ${data.city}`] : []),
        ...(data.country ? [`${data.country}`] : []),
      ].join(', ');
    },
    remove() { this.$emit('remove'); },
    onboardingChange() {
      this.isSendOnboarding = !this.isSendOnboarding;
      this.$emit('onboarding');
    },
    resetOnOff() {
      this.isSendOnboarding = false;
    },
    submitOnboardingForm() {
      this.$refs.onboardingForm.submitOnboardingForm();
    },
    onSuccessOnboarding() {
      this.$emit('success');
    },
  },
};
</script>

<template>
  <div class="selected">
    <template v-if="hasItem">
      <div class="selected__card">
        <div
          :class="[
            'selected__card__venue',
            { 'selected__card__venue--border': !item.xeroId && !onlyBasicInfo }
            ]"
        >
          <ez-entity-info
            :imgUrl="item.logo"
            :imgHasBorder="true"
            imgBorderRadius="50%"
          >
            <div>
              <div>
                <span :data-cy="dataCy">{{ item.name }}</span>
                <ez-premium-badge v-if="$helpers.isPremium(item.accountType)"/>
                <ez-lite-badge v-if="$helpers.isLite(item.accountType)" />
              </div>
              <div>
                <small v-if="item.xeroId">Xero</small>
                <small v-else>{{ platformName }}</small>
              </div>
            </div>
          </ez-entity-info>
          <button
            class="selected__remove"
            v-if="!hideRemoveButton"
            @click="remove"
            :data-cy="removeButtonDataCy"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
              <!-- eslint-disable-next-line max-len -->
              <path d="M7.758 6.309L13.916.15c.2-.2.524-.2.725 0l.724.725c.2.2.2.524 0 .724L9.207 7.758l6.158 6.158c.2.2.2.524 0 .725l-.724.724c-.2.2-.525.2-.725 0L7.758 9.207l-6.159 6.158c-.2.2-.524.2-.724 0l-.725-.724c-.2-.2-.2-.525 0-.725L6.31 7.758.15 1.599c-.2-.2-.2-.524 0-.724L.875.15c.2-.2.524-.2.724 0L7.758 6.31z"/>
            </svg>
          </button>
        </div>
        <div v-if="!onlyBasicInfo" class="selected__card__info">
          <div class="primary-info" v-if="isPremiumOrIsLite">
            <div class="info-segment">
              <p class="info-segment__title">Primary Contact Name</p>
              <p class="info-segment__info">{{ item.primaryContactName }}</p>
            </div>
            <div class="info-segment">
              <p class="info-segment__title">Primary Contact Phone Number</p>
              <p class="info-segment__info">{{ item.primaryContactPhone }}</p>
            </div>
          </div>
          <hr v-if="isPremiumOrIsLite">
          <div class="info-segment mb-16" v-if="item.shippingAddress">
            <p class="info-segment__title">Delivery Address</p>
            <p class="info-segment__info">{{ addressFormat(item.shippingAddress) }}</p>
          </div>
          <div class="info-segment" v-if="item.billingAddress">
            <p class="info-segment__title">Billing Address</p>
            <p class="info-segment__info">{{ addressFormat(item.billingAddress) }}</p>
          </div>
          <hr v-if="hoursOfOperation">
          <div class="info-segment" v-if="hoursOfOperation">
            <p class="info-segment__title">Hours of Operation</p>
            <p class="info-segment__info">{{ hoursOfOperation.from }}
              to {{ hoursOfOperation.to }}</p>
          </div>
        </div>
      </div>
    </template>
    <div v-else>
      <slot name="emptyState"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .selected {
    &__card {
      padding: 16px;
      border: 1px solid #E9EBF2;
      border-radius: 3px;
      background-color: #FFFFFF;
      box-shadow: 0 1px 1px 0 #DEE1E4;

      &__venue {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &--border {
          border-bottom: 1px solid #E9EBF2;
          padding-bottom: 16px;
          margin-bottom: 16px;
        }
      }
      &__info {
        .primary-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .info-segment {
            flex: 1 1 100%;
          }
        }
        .info-segment {
          &__title {
            @include font-size(12px, 14px);
            color: $color-gray-6C;
            margin: 0 0 6px 0;
            font-weight: 500;
          }
          &__info {
            @include font-size(14px, 16px);
            color: #2B2D3E;
            margin: 0;
          }
        }
      }
    }

    &__remove {
      @extend %button-reset;
      @include size(12px);
      line-height: 0;
      cursor: pointer;
      svg {
        fill: #6C7995;
      }
    }

    .onboarding-option {
      display: flex;
      justify-content: space-between;
    }
  }
</style>
