<template>
  <ez-form-modal ref="modal" @close="onModalClose">
    <template #title>
      <slot name="title"></slot>
    </template>
    <template #content>
      <ez-form
        ref="form"
        method="patch"
        :formKey="formKey"
        :action="formAction"
        :additional-headers="additionalHeaders"
        @success="onSubmitSuccess">
        <p>
          <slot name="modalDescription"></slot>
        </p>
        <slot></slot>
      </ez-form>
    </template>
    <template #footer>
      <ez-button
        type="link"
        formType="button"
        @click="close">Cancel</ez-button>
      <ez-button :is-loading="isLoading" @click="submitForm">Save Changes</ez-button>
    </template>
  </ez-form-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import EzButton from '@/components/ui/Button';
import { EzFormModal } from '@/components/ui/Modal';
import EzForm from '@/components/ui/Form';
import { LOADING_KEY } from '@/util/constants';

export default {
  components: {
    EzButton,
    EzForm,
    EzFormModal,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    formAction: {
      type: String,
      required: true,
    },
    additionalHeaders: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.VENUE_CHANGE_PASSWORD)
          || this.getLoading(LOADING_KEY.VENUE_CHANGE_EMAIL);
    },
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    submitForm() {
      this.$refs.form.onSubmit();
    },
    onModalClose() {
      this.$refs.form.$el.reset();
      this.CLEAR_ERRORS({ key: this.formKey });
    },
    onSubmitSuccess() {
      this.close();
    },
    ...mapMutations('errors', [
      'CLEAR_ERRORS',
    ]),
  },
};
</script>

<style scoped lang="scss">
  .ez-form {
    p {
      @include font-size(14px, 20px);
      margin: 0;
      padding: 0;
      @include separator('bottom');
    }
    :deep() .input-group + .input-group {
      margin-top: .75rem;
    }
  }
</style>
