var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"productList",staticClass:"product-list"},[(_vm.tagHover)?_c('div',{staticClass:"popover",style:(`top: ${_vm.tagPopOver.top}px; left: ${_vm.tagPopOver.left}px`)},_vm._l((_vm.tagPopOver.tags),function(tag){return _c('v-tag',{key:tag.id,attrs:{"color":tag.color.colors[0],"backgroundColor":tag.color.colors[1]}},[_vm._v(" "+_vm._s(tag.name)+" ")])}),1):_vm._e(),_vm._t("filters"),_c('div',{staticClass:"product-list__alert"},[_vm._t("alert")],2),(_vm.productsCopy.length)?_c('ez-table',{staticClass:"products-table",attrs:{"headers":_vm.tableHeaders,"columns":_vm.tableColumns,"data":_vm.productsCopy,"columnProps":_vm.tableColumnProps,"loading":_vm.isLoading},on:{"rowClick":_vm.handleRowClick},scopedSlots:_vm._u([{key:"cell-name",fn:function({ row }){return [_c('ez-entity-info',{attrs:{"imgWidth":"2rem","imgHeight":"2rem","imgUrl":row.image}},[_c('div',{class:['product-info', { 'responsive-info': _vm.responsiveInfo }],attrs:{"title":row.name}},[_c('span',{attrs:{"data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_NAME}-${row.id}`}},[_vm._v(" "+_vm._s(row.name)+" ")]),_c('span',{staticClass:"product-info-secondary"},[_c('span',{staticClass:"product-info-secondary--category",attrs:{"data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_CATEGORY}-${row.id}`}},[_vm._v(" "+_vm._s(_vm._f("categoryWithParent")(row.category))+" ")]),(row.sku)?_c('span',{staticClass:"product-info-secondary--sku",attrs:{"data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_SKU}-${row.id}`}},[_vm._v(" • "+_vm._s(row.sku)+" ")]):_vm._e()])])])]}},{key:"cell-expiresSoon",fn:function({ row, row: { inventoryTracked, expiresSoon } }){return [(inventoryTracked && expiresSoon)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: 'top-start',
          content: `This product has a batch that's about to expire soon`,
          classes: ['tooltip--reset-margin u-text-center mb-8'],
        }),expression:"{\n          placement: 'top-start',\n          content: `This product has a batch that's about to expire soon`,\n          classes: ['tooltip--reset-margin u-text-center mb-8'],\n        }"}],attrs:{"icon":"calendar-alt","data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_EXPIRES_SOON}-${row.id}`}}):_vm._e()]}},{key:"cell-parLevelReached",fn:function({ row, row: { inventoryTracked, parLevelReached } }){return [(inventoryTracked && parLevelReached)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: 'top-start',
          content: 'This product has reached its par level',
          classes: ['tooltip--reset-margin u-text-center mb-8'],
        }),expression:"{\n          placement: 'top-start',\n          content: 'This product has reached its par level',\n          classes: ['tooltip--reset-margin u-text-center mb-8'],\n        }"}],attrs:{"icon":"exclamation-circle","data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_PAR_LEVEL_REACHED}-${row.id}`}}):_vm._e()]}},{key:"cell-warehouses",fn:function({ row: { id, warehouses, locations, inventoryTracked } }){return [(!inventoryTracked)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: 'top-start',
          content: 'Not Tracking',
          classes: ['tooltip--reset-margin u-text-center mb-2'],
        }),expression:"{\n          placement: 'top-start',\n          content: 'Not Tracking',\n          classes: ['tooltip--reset-margin u-text-center mb-2'],\n        }"}],attrs:{"flip":"horizontal","icon":['far', 'eye-slash']}}):_c('v-warehouse-location',{attrs:{"data":_vm.warehouseId ? locations : warehouses,"data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_WAREHOUSE}-${id}`}})]}},{key:"cell-tags",fn:function({ row }){return [(row.tags.length)?_c('div',{ref:`tag-${row.id}`,staticClass:"tags",on:{"mouseenter":function($event){return _vm.tagsShowHover(row.id, row.tags)},"mouseleave":_vm.tagsRemoveHover}},[_c('v-tag',{staticClass:"mr-8",attrs:{"color":row.tags[0].color.colors[0],"backgroundColor":row.tags[0].color.colors[1]}},[_vm._v(_vm._s(row.tags[0].name))]),(row.tags.length > 1)?_c('span',[_vm._v("+"+_vm._s(row.tags.length - 1))]):_vm._e()],1):_c('span',[_vm._v("-")])]}},{key:"cell-parLevel",fn:function({ row: { id, parLevel, inventoryTracked } }){return [(!inventoryTracked)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: 'top-start',
          content: 'Not Tracking',
        }),expression:"{\n          placement: 'top-start',\n          content: 'Not Tracking',\n        }"}],attrs:{"flip":"horizontal","icon":['far', 'eye-slash']}}):_c('span',{attrs:{"data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_PAR_LEVEL}-${id}`}},[_vm._v(" "+_vm._s(parLevel || '-')+" ")])]}},{key:"cell-stock",fn:function({ row }){return [(_vm.warehouseId || (_vm.inventoryDate && !_vm.isInventoryDateToday))?[(!row.inventoryTracked)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            placement: 'top-start',
            content: 'Not Tracking',
          }),expression:"{\n            placement: 'top-start',\n            content: 'Not Tracking',\n          }"}],attrs:{"flip":"horizontal","icon":['far', 'eye-slash']}}):(!row.available)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            placement: 'top',
            content: 'Out of stock',
            classes: ['tooltip', 'tooltip--lift-up', 'tooltip--reset-margin'],
          }),expression:"{\n            placement: 'top',\n            content: 'Out of stock',\n            classes: ['tooltip', 'tooltip--lift-up', 'tooltip--reset-margin'],\n          }"}],staticClass:"text-red",attrs:{"icon":"minus-circle"}}):_c('span',{attrs:{"data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_STOCK}-${row.id}`}},[_vm._v(" "+_vm._s(row.inventory)+" ")])]:[(!row.inventoryTracked)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            placement: 'top-start',
            content: 'Not Tracking',
            classes: ['tooltip--reset-margin u-text-center mb-2'],
          }),expression:"{\n            placement: 'top-start',\n            content: 'Not Tracking',\n            classes: ['tooltip--reset-margin u-text-center mb-2'],\n          }"}],attrs:{"flip":"horizontal","icon":['far', 'eye-slash']}}):(!row.available)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            placement: 'top',
            content: 'Out of stock',
            classes: ['tooltip', 'tooltip--lift-up', 'tooltip--reset-margin'],
          }),expression:"{\n            placement: 'top',\n            content: 'Out of stock',\n            classes: ['tooltip', 'tooltip--lift-up', 'tooltip--reset-margin'],\n          }"}],staticClass:"text-red",attrs:{"icon":"minus-circle"}}):_c('v-product-quantity-inventory',{attrs:{"product":row,"canBeInvalid":false,"warehouseProperty":"warehouseInventory","isDistributor":"","showTotalAsLabel":"","inventoryTracking":""}})]]}},{key:"cell-category",fn:function({ row, row: { category } }){return [_c('span',{staticClass:"category-badge",attrs:{"data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_CATEGORY}-${row.id}`}},[_vm._v(" "+_vm._s(_vm._f("categoryWithParent")(category))+" ")])]}},{key:"cell-distributor",fn:function({ row, row: { distributor } }){return [(distributor)?_c('ez-entity-info',{attrs:{"imgWidth":"2.125rem","imgHeight":"2.125rem","imgUrl":distributor.logo,"imgBorderRadius":"50%"}},[_c('div',{staticClass:"distributor-info",attrs:{"title":distributor.name,"data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_SUPPLIER}-${row.id}`}},[_vm._v(" "+_vm._s(distributor.name)+" ")])]):_vm._e()]}},{key:"cell-importType",fn:function({ row, row: { importType } }){return [_c('span',{attrs:{"data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_IMPORT_TYPE}-${row.id}`}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(importType === 'trade_gecko' ? _vm.$t('quickBooks.nameShort') : importType))+" ")])]}},{key:"cell-quantity",fn:function({ row }){return [(!row.inventoryTracked)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: 'top-start',
          content: 'Not Tracking',
        }),expression:"{\n          placement: 'top-start',\n          content: 'Not Tracking',\n        }"}],staticClass:"fa-regular fa-flip-horizontal",attrs:{"flip":"horizontal","icon":['far', 'eye-slash']}}):(!row.available)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: 'top',
          content: 'Out of stock',
          classes: ['tooltip', 'tooltip--lift-up', 'tooltip--reset-margin'],
        }),expression:"{\n          placement: 'top',\n          content: 'Out of stock',\n          classes: ['tooltip', 'tooltip--lift-up', 'tooltip--reset-margin'],\n        }"}],staticClass:"text-red",attrs:{"icon":"minus-circle"}}):_c('span',{attrs:{"data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_STOCK}-${row.id}`}},[_vm._v(_vm._s(row.inventory))])]}},{key:"cell-unit",fn:function({ row: { id, orderingUnit } }){return [(orderingUnit)?[_c('v-data-with-info',{attrs:{"info":orderingUnit.abbreviation
              ? `${orderingUnit.name} ${!!_vm.$t(`product.unitInfo.${orderingUnit.type}`) ? '-' : ''}
          ${_vm.$t(`product.unitInfo.${orderingUnit.type}`)}`
              : '',"show-underline":!!_vm.$t(`product.unitInfo.${orderingUnit.type}`) || !!orderingUnit.abbreviation,"data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_ORDERING_UNIT}-${id}`}},[_vm._v(" "+_vm._s(orderingUnit.label)+" ")])]:_vm._e()]}},{key:"cell-cogs",fn:function({ row: { id, cogs, inventoryTracked } }){return [(_vm.trackingInventory && !inventoryTracked)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: 'top-start',
          content: 'Not Tracking',
        }),expression:"{\n          placement: 'top-start',\n          content: 'Not Tracking',\n        }"}],attrs:{"flip":"horizontal","icon":['far', 'eye-slash']}}):_c('v-price',{attrs:{"price":cogs || 0,"data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_COGS}-${id}`}})]}},{key:"cell-price",fn:function({ row: { id, price, priceUnit, marketPrice } }){return [_c('v-price',{attrs:{"price":price || 0,"unit":priceUnit && priceUnit.label,"is-market-price":marketPrice,"data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_PRICE}-${id}`}})]}},{key:"cell-availability",fn:function({ row, row: { customerInventory } }){return [(customerInventory?.outOfStock)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: 'top',
          content: 'Out of stock',
          classes: ['tooltip', 'tooltip--lift-up'],
        }),expression:"{\n          placement: 'top',\n          content: 'Out of stock',\n          classes: ['tooltip', 'tooltip--lift-up'],\n        }"}],staticClass:"text-red mx-4",attrs:{"icon":"minus-circle","data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_OUT_OF_STOCK}-${row.id}`}}):_vm._e(),(customerInventory?.lowInventory)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
          placement: 'top',
          content: 'Low inventory',
          classes: ['tooltip', 'tooltip--lift-up'],
        }),expression:"{\n          placement: 'top',\n          content: 'Low inventory',\n          classes: ['tooltip', 'tooltip--lift-up'],\n        }"}],staticClass:"text-carrot mx-4",attrs:{"icon":"exclamation-circle","data-cy":`${_vm.commonCy.PRODUCTS.TEXT__PRODUCT_LOW_INVENTORY}-${row.id}`}}):_vm._e(),_vm._v(" "+_vm._s(customerInventory?.inventoryLevel)+" ")]}},(_vm.hasQuickAdd && _vm.$permission.has('createOrder'))?{key:"cell-addToCart",fn:function({ row }){return [_c('div',{staticClass:"quantity"},[_c('ez-mask-input-simple',{attrs:{"name":"quantity","formKey":"","minValue":row.minimumQuantity ?? 0,"maxValue":_vm.productQuantityMaxValue(row),"value":row.quantity,"disabled":_vm.productQuantityDisabled(row),"precision":_vm.isFractional(row.orderingUnit) ? 2 : 0,"data-cy":`${_vm.commonCy.PRODUCTS.INPUT__PRODUCT_QUANTITY}-${row.id}`,"has-currency":false},on:{"input":function($event){return _vm.onQuantityChange($event, row.id)},"invalid":function($event){return _vm.onQuantityInvalid($event, row.id)}}}),_c('div',{staticClass:"quantity__icon"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:({
              content: _vm.productCartTooltipContent(row),
              classes: ['tooltip-general'],
            }),expression:"{\n              content: productCartTooltipContent(row),\n              classes: ['tooltip-general'],\n            }",modifiers:{"bottom":true}}]},[_c('ez-button',{class:['add-to-cart-button', { 'add-to-cart-button--active': _vm.isInCart(row) }],attrs:{"type":"link","data-cy":`${_vm.commonCy.PRODUCTS.BUTTON__PRODUCT_ADD_TO_CART}-${row.id}`,"disabled":_vm.addToCartDisabled(row)},on:{"click":function($event){return _vm.quickAddToCart(row)}}},[_c('font-awesome-icon',{attrs:{"icon":"cart-plus"}})],1)],1)])],1)]}}:null],null,true)}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }