<script>
import EzOnOff from '@/components/ui/OnOff/EzOnOff';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'PrimaryContactToggle',
  components: {
    EzOnOff,
  },
  props: {
    formKey: {
      required: true,
      type: String,
    },
    name: {
      type: String,
      required: false,
      default: 'primary',
    },
    checked: {
      required: false,
      default: false,
      type: Boolean,
    },
    value: {
      required: false,
      type: String,
      default: 'false',
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      val: '',
    };
  },
  methods: {
    onChange(val) {
      this.val = `${val}`;
      this.$emit('change', val);
    },
  },
  created() {
    this.val = this.value;
  },
};
</script>

<template>
  <div class="primary-contact-row">
    <div>
      <h4 class="primary-contact-row__title">Primary Contact</h4>
      <p class="primary-contact-row__text">
        Set this person as your primary contact. Phone number for primary contacts is mandatory.
      </p>
    </div>
    <ez-on-off
      :value="val"
      :name="name"
      :formKey="formKey"
      :checked="checked"
      @change="onChange"
      :disabled="disabled"
    />
  </div>
</template>

<style lang="scss" scoped>
  .primary-contact-row {
    display: flex;
    justify-content: space-between;
    &__title {
      @include font-size(14px, 16px);
      font-weight: 500;
      margin: 0 0 4px;
    }
    &__text {
      @include font-size(14px);
      color: $color-gray-6C;
      margin: 0 12px 0 0;
    }
  }
</style>
