<script>
/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */

import { wizardEmitterMixin } from '@/mixins/wizard';
import EzButton from '@/components/ui/Button/EzButton';
import EzWizard from '@/components/layout/Wizard';
import steps, {
  ONBOARDING_STEP_1, ONBOARDING_STEP_2, ONBOARDING_STEP_3, ONBOARDING_STEP_4,
} from '@/views/platform/venue/suppliers/onboarding-from-public/steps';
import { mapMutations, mapState } from 'vuex';
import Footer from '@/views/platform/venue/suppliers/onboarding-from-public/Footer';
import EzAlert from '@/components/ui/Alert/EzAlert';
import bus from '@/views/public/bus';

export default {
  mixins: [wizardEmitterMixin],
  components: {
    EzButton,
    EzWizard,
    Footer,
    EzAlert,
  },
  props: {
    supplierId: {
      type: Number,
    },
  },
  data() {
    return {
      currentStep: 1,
      SUPPLIER_STEP_1: ONBOARDING_STEP_1,
      SUPPLIER_STEP_2: ONBOARDING_STEP_2,
      SUPPLIER_STEP_3: ONBOARDING_STEP_3,
      SUPPLIER_STEP_4: ONBOARDING_STEP_4,
      nextDisabled: true,
      isEdit: false,
    };
  },
  computed: {
    ...mapState('entities/venues', [
      'publicOnboarding',
    ]),
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === ONBOARDING_STEP_1;
    },
    isLastStep() {
      return this.currentStep === ONBOARDING_STEP_4;
    },
    disableNext() {
      return false;
    },
    isNewOutlet() {
      return this.publicOnboarding.venue?.targetAccount && this.publicOnboarding.venue?.editable;
    },
  },
  methods: {
    onWizardExit() {
      this.CLEAR_ONBOARDING_CONTACTS();
      this.$router.push({ name: 'venue-home' });
    },
    onStepBack(step) {
      if (step !== undefined && step >= ONBOARDING_STEP_1 && step <= ONBOARDING_STEP_4) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= ONBOARDING_STEP_1) {
        return;
      }

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= ONBOARDING_STEP_4) {
        return;
      }
      this.nextDisabled = true;
      this.currentStep += 1;
    },
    ...mapMutations('entities/venues', [
      'CLEAR_ONBOARDING_CONTACTS',
    ]),
  },
  async created() {
    bus.$emit('editExistingOutlet');
    await this.$router.push({ query: { ...this.$route.query, edit: 'true' } });
  },
};
</script>
<template>
  <ez-wizard
    :is-last-step="isLastStep"
    class="new-supplier"
    @exit="onWizardExit"
    @back="goToPreviousStep">
    <template #title>Account Opening Form</template>
    <template #nav>
      <ul>
        <li :class="{
          'ez-step-active': currentStep === SUPPLIER_STEP_1,
          'ez-step-completed': currentStep > SUPPLIER_STEP_1,
        }">
          1. Account
        </li>
        <li :class="{
          'ez-step-active': currentStep === SUPPLIER_STEP_2,
          'ez-step-completed': currentStep > SUPPLIER_STEP_2,
        }">
          2. {{ $t('global.venue') }} Info
        </li>
        <li :class="{
          'ez-step-active': currentStep === SUPPLIER_STEP_3,
          'ez-step-completed': currentStep > SUPPLIER_STEP_3,
        }">
          3. Contacts
        </li>
      </ul>
    </template>
    <template #actions>
      <ez-button
        :disabled="nextDisabled"
        @click="goToNextStep">{{ nextCta }}</ez-button>
    </template>
    <template #prevStep>
      <ez-button
        v-if="currentStep > SUPPLIER_STEP_2 && currentStep < SUPPLIER_STEP_4"
        type="link"
        formType="button"
        @click="goToPreviousStep">
        <font-awesome-icon icon="arrow-left"/>
        <span>Back</span>
      </ez-button>
    </template>
    <template #pageTitle>{{
        (isFirstStep && !isNewOutlet) ? `Check ${$t('global.venue').toLowerCase()} info` : currentStepPageTitle }}
    </template>
    <template #pageInfo>
      <span v-html="(isFirstStep && !isNewOutlet)
        ? `Check if all the info entered by the supplier is correct. If not, <br> you can update it
          right away and continue to the next step.`
        : currentStepPageInfo"
      />
    </template>
    <component
      :is="currentStepComponent"
      @stepCompleted="onStepCompleted"
      :supplierId="supplierId"
      @stepBack="onStepBack" />
    <footer
      @accepted="enableNextStep"
      @download="downloadTosPdf"
      :supplier-id="supplierId"
      :hasActions="footerActions" />
  </ez-wizard>
</template>
<style lang="scss" scoped>
  .new-supplier {
    overflow: scroll;
    height: 100%;
  }
  .banner {
    padding-bottom: 0;
    margin-top: 0;
    .banner__text {
      color: $color-gray-6C;
      font-weight: 400;
      margin: 0;
      .banner-title {
        color: $color-gray-25;
        font-weight: 500;
      }
    }
    .banner__btn {
      padding: 0;
    }
  }
</style>
