<template>
  <venue-list
    :groupId="loggedUser.group.id"
    :venues="venues"
    :can-delete="true"
    :can-create="false"
    @venueCreated="onVenueCreated"
    @rowClick="openVenue"
    @removeItem="openConfirmationModal"
  >
    <venue-modal></venue-modal>
    <ez-confirmation-modal ref="deleteModal" type="red">
      <template #title>Delete this {{ $t('global.venue') | lowercase }}?</template>
      <template #content>
        <p>
          When deleted, this {{ $t('global.venue') | lowercase }} will no longer be live on
          {{ oezPlatformName }}.
        </p>
      </template>
      <template #footer>
        <ez-button @click="closeDeleteModal" type="link">Cancel</ez-button>
        <ez-button @click="deleteVenue" type="red" :is-loading="isLoadingDelete">
          Yes, Delete
        </ez-button>
      </template>
    </ez-confirmation-modal>
  </venue-list>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import VenueList from '@/views/common/venues/List.vue';
import VenueModal from '@/views/common/venues/Modal.vue';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button/EzButton';
import flash from '@/components/ui/FlashMessage';
import { LOADING_KEY } from '@/util/constants';

export default {
  components: {
    VenueList,
    VenueModal,
    EzConfirmationModal,
    EzButton,
  },
  data() {
    return {
      itemForDelete: {},
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    isLoadingDelete() {
      return this.isSomeLoading([LOADING_KEY.DELETE_VENUE_FROM_VENUE_GROUP]);
    },
    venues() {
      return this.loggedUser.venues;
    },
    ...mapState('entities/users', ['loggedUser']),
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    onVenueCreated() {
      this.initVenue();
    },
    openVenue({ id }) {
      this.$router.push({
        name: 'venue-settings',
        params: {
          id,
        },
      });
    },
    openConfirmationModal(data) {
      this.itemForDelete = data;
      this.$refs.deleteModal.open();
    },
    closeDeleteModal() {
      this.itemForDelete = {};
      this.$refs.deleteModal.close();
    },
    async deleteVenue() {
      await this.deleteVenueFromVenueGroup({
        venueId: this.itemForDelete.id,
      });
      this.initVenue();
      this.closeDeleteModal();
      flash.success({
        title: `${this.$t('global.venue')} successfully deleted!`,
      });
    },
    ...mapActions('entities/users', ['initVenue']),
    ...mapActions('entities/venues', ['deleteVenueFromVenueGroup']),
  },
  mounted() {
    this.initVenue();
  },
};
</script>

<style scoped lang="scss"></style>
