<template>
  <span v-if="showBadge" :class="['badge', `badge--${type}`, customClass]" :data-cy="dataCy">
    {{ count }}
  </span>
</template>

<script>
/**
 * Custom badge component
 * @since 2.0.0
 * @version 1.0.0
 */
export default {
  props: {
    count: {
      required: true,
      type: Number,
    },
    /**
     * Type of badge, this will change the color.
     * Allowed values: `grey`, `blue`, `blue-inverted`, `yellow`, `purple`..
     */
    type: {
      required: false,
      type: String,
      default: 'grey',
      validator: value =>
        [
          'grey',
          'gray',
          'blue',
          'blue-inverted',
          'yellow',
          'purple',
          'red',
          'green',
          'error-red',
        ].indexOf(value) !== -1,
    },
    /**
     * Adds a custom class to the button.
     */
    customClass: {
      required: false,
      type: String,
      default: null,
    },
    /**
     *  This prop lets you show zero.
     *  By default this component only shows numbers that are bigger then zero.
     */
    showEveryNumber: {
      required: false,
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    showBadge() {
      return this.showEveryNumber ? Number.isFinite(this.count) : this.count > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
$badge-border-radius: 18px;

@mixin color-variant($bg, $color) {
  background-color: $bg;
  color: $color;
}

.badge {
  display: inline-block;
  margin: 0 6px;
  padding: 0.25em 0.6em;
  border-radius: $badge-border-radius;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
  @include font-size(12px, 1);

  &--grey,
  &--gray {
    @include color-variant(rgba($color-gray-6C, 0.16), $color-gray-6C);
  }
  &--blue {
    @include color-variant(rgba($color-primary-blue, 0.16), $color-primary-blue);
  }
  &--blue-inverted {
    @include color-variant($color-primary-blue, #ffffff);
  }
  &--yellow {
    @include color-variant(rgba($color-primary-yellow, 0.24), $color-primary-orange);
  }
  &--purple {
    @include color-variant(rgba($color-primary-purple, 0.16), $color-primary-purple);
  }
  &--red {
    @include color-variant(#fbe7c7, #f76f31);
  }
  &--error-red {
    @include color-variant(rgba($color-primary-red, 0.16), $color-primary-red);
  }
  &--green {
    @include color-variant(rgba($color-primary-green, 0.16), $color-primary-green);
  }
}
</style>
