<template>
  <ez-user-settings-modal
    ref="modal"
    :formKey="formKey"
    :formAction="formAction"
    :additional-headers="{ loadingKey }"
  >
    <template #title>Change Password</template>

    <template #modalDescription>{{ description }}</template>

    <ez-input
      :formKey="formKey"
      type="password"
      name="password"
      placeholder="Enter Current Password"
      label="Current Password"></ez-input>

    <ez-input
      :formKey="formKey"
      type="password"
      name="newPassword"
      label="New Password"
      placeholder="Enter New Password"
      autocomplete="new-password"></ez-input>
  </ez-user-settings-modal>
</template>

<script>
import EzInput from '@/components/ui/Input';
import { LOADING_KEY } from '@/util/constants';
import EzUserSettingsModal from './EzUserSettingsModal.vue';

export default {
  components: {
    EzInput,
    EzUserSettingsModal,
  },
  props: {
    formAction: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formKey: 'change-password',
      loadingKey: LOADING_KEY.VENUE_CHANGE_PASSWORD,
      description: 'Changing your password will sign you out of all your devices, including your phone. You will need to enter your new password on all your devices.',
    };
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
