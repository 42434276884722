<script>
import EzFilterList from '@/components/ui/FilterList/EzFilterList';
import EzInput from '@/components/ui/Input/EzInput';
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import EzTable from '@/components/ui/Table/EzTable';
import VDistributorEntityInfo from '@/components/v3/patterns/VDistributorEntityInfo/index';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { LOADING_KEY } from '@/util/constants';

/**
 * Connection Requests
 * @version 1.0.0
 * @since 2.3.0
 */
export default {
  components: {
    EzLoader,
    EzFilterList,
    EzInput,
    EzTable,
    VDistributorEntityInfo,
  },
  data() {
    return {
      filters: {
        term: null,
      },
      allInvitations: [],
    };
  },
  computed: {
    ...mapState('entities/users', [
      'context',
      'contextId',
    ]),
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.VENUE_CONNECTION_REQUESTS);
    },
    invitations() {
      return this.allInvitations;
    },
    filteredInvitations() {
      return this.invitations.filter(this.filterInvitations);
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'venueFetchInvitations',
    ]),
    ...mapMutations('entities/distributors', [
      'UPDATE_DISTRIBUTOR_COUNT',
      'UPDATE_ONBOARDING_FLAG',
    ]),
    async refresh() {
      const { data: { data } } = await this.venueFetchInvitations({ id: this.contextId });
      this.allInvitations = data;
      if (this.context.invitationsCount < 1) this.$router.push({ name: 'venue-all-suppliers' });
      this.UPDATE_DISTRIBUTOR_COUNT(this.allInvitations.length);
    },
    filterInvitations(invitations) {
      return invitations.name.toLowerCase().includes((this.filters.term || '').toLowerCase());
    },
    resetFilters() {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = null;
      });
    },
    updateFilters(filterName, event) {
      this.filters = { ...this.filters, term: event };
    },
    goToSingle({ id, onboarding }) {
      this.UPDATE_ONBOARDING_FLAG(onboarding);
      this.$router.push({ name: 'buyer-invitation-single-info', params: { id } });
    },
  },
  mounted() {
    this.refresh();
  },
};
</script>
<template>
  <div class="venue-invitations-tab">
    <div class="filter-container">
      <ez-filter-list :filters="filters" @resetFilter="resetFilters" @filterUpdated="updateFilters">
        <ez-input
          formKey="filters"
          label="search"
          name="search"
          :placeholder="$t('distributors.searchForDistributorPlaceholder')"
        >
          <template #prefix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
      </ez-filter-list>
    </div>

    <ez-table
      :data="filteredInvitations"
      :headers="{
        supplierName: () => $t('tables.distributorName'),
      }"
      :columns="['supplierName']"
      @rowClick="goToSingle"
    >
      <template #cell-supplierName="{ row }">
        <VDistributorEntityInfo :distributor="row" />
      </template>

      <template #cell-status="{ row }">
        <status-badge :status="row.status || 'draft'"></status-badge>
      </template>
    </ez-table>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>
<style lang="scss" scoped>
:deep() .loader {
  @extend %whole-screen;
}

  .venue-invitations-tab {
    @include platform-tab-content();
    position: relative;
    min-height: $loading-min-height;
  }
  .filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $spacing-24;
    margin-bottom: $spacing-24;
  }
</style>
