<template>
  <div
    :class="['alert', `alert--${type}`, `alert--${size}`, `alert--${variant}`, customClass]"
    role="alert"
    :data-cy="dataCy"
  >
    <div class="alert__icon" v-if="hasIconSlot" :data-cy="dataCy ? `${dataCy}-icon` : ''">
      <!-- @slot Pass down the icon to be shown on the left -->
      <slot name="icon"></slot>
    </div>
    <div class="alert__text" :data-cy="dataCy ? `${dataCy}-text` : ''">
      <h5 v-if="hasTitleSlot" class="alert__title" :data-cy="dataCy ? `${dataCy}-title` : ''">
        <slot name="title" />
      </h5>
      <!-- @slot Text shown in the alert -->
      <slot></slot>
    </div>
    <div class="alert__cta" v-if="hasCTASlot" :data-cy="dataCy ? `${dataCy}-cta` : ''">
      <!-- @slot Pass down the button/s. -->
      <slot name="cta"></slot>
    </div>
  </div>
</template>

<script>
/**
 * Alert component used to show different alerts.
 * These alerts are not tasters or notification, they can' be closed.
 *
 * @since 2.0.0
 * @version 1.0.0
 */
export default {
  props: {
    /**
     * Type of alert being shown. Type will only change the color..
     * Allowed values: `grey`, `blue`, `yellow`, `purple`.
     */
    type: {
      required: false,
      type: String,
      default: 'yellow',
      validator: value => [
        'light-gray',
        'grey',
        'gray',
        'blue',
        'green',
        'yellow',
        'yellow-gray',
        'purple',
        'red',
        'white',
        'orange',
        'white-peach',
      ].indexOf(value) !== -1,
    },
    size: {
      type: String,
      default: 'block',
      validator: value => ['block', 'inline', 'big'].indexOf(value) !== -1,
    },
    /**
     * Adds a custom class to the button.
     */
    customClass: {
      required: false,
      type: String,
      default: null,
    },
    variant: {
      required: false,
      type: String,
      default: 'banner',
      validator: value => ['banner', 'disclaimer'].indexOf(value) !== -1,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      hasTitleSlot: false,
    };
  },
  computed: {
    hasCTASlot() {
      return !!this.$slots.cta;
    },
    hasIconSlot() {
      return !!this.$slots.icon;
    },
  },
  mounted() {
    this.hasTitleSlot = !!this.$slots.title;
  },
  updated() {
    this.hasTitleSlot = !!this.$slots.title;
  },
};
</script>

<style lang="scss" scoped>
@mixin color-variant($bg, $color) {
  background-color: $bg;
  color: $color;
}

.alert {
  @extend %flex-center;
  justify-content: center;
  height: 56px;
  padding: px-to-rem(12px);
  margin: px-to-rem(24px) 0;
  border-radius: 0.1875rem;

  &--disclaimer {
    justify-content: flex-start;

    .alert {
      &__text {
        color: $color-gray-6C;
      }

      &__title {
        margin: 0 0 4px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 600;
        color: $global-text-color;
      }
    }
  }

  &__text {
    @include font-size(14px);
    font-weight: 400;

    p {
      margin: 0;
    }
    p + p {
      margin-top: 1em;
    }
  }

  &__icon { @include font-size(16px); }

  &--light-gray { @include color-variant(rgba($color-gray-F5, .16), $color-gray-6C); }
  &--grey, &--gray { @include color-variant(rgba($color-gray-6C, .16), $color-gray-6C); }
  &--blue { @include color-variant(rgba($color-primary-blue, .16), $color-primary-blue); }
  &--blue-inverted { @include color-variant($color-primary-blue, #FFFFFF); }
  &--yellow { @include color-variant(rgba($yellow-bg, 1), $color-primary-orange); }
  &--yellow-gray {
    @include color-variant(rgba($yellow-bg, 1), $color-gray-6C);
    .alert__icon {
      svg {
        color: $color-primary-orange;
      }
    }
  }
  &--purple { @include color-variant(rgba($color-primary-purple, .16), $color-primary-purple); }
  &--red { @include color-variant(rgba($color-primary-red, .16), $color-primary-red); }
  &--white { @include color-variant(#FFF, $color-gray-6C); }
  &--green { @include color-variant(rgba($color-primary-green,.16), $color-primary-green); }
  &--orange { @include color-variant(rgba($color-primary-orange,.16), $color-primary-orange); }
  &--white-peach { @include color-variant($color-white-peach, $color-gray-6C); }

  &--inline {
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
    padding: px-to-rem(12px);
  }
  &--big {
    height: auto;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__icon + &__text { margin-left: px-to-rem(12px); }
  &__text + &__cta { margin-left: px-to-rem(16px); }
}
</style>
