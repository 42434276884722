<template>
  <ez-drawer
    ref="productDrawer"
    class="venue-products__drawer"
    @onClose="$event => $emit('close', $event)"
  >
    <template #title>
      <span class="category-badge" :data-cy="outletCy.PRODUCTS.SINGLE_PRODUCT.TEXT__CATEGORY">
        {{ productCopy.category | categoryWithParent }}
      </span>
    </template>
    <template #content>
      <div class="content">
        <section class="product">
          <ez-entity-info
            :img-url="productCopy.image"
            img-width="4rem"
            img-height="4rem"
            img-border-radius=".375rem"
          >
            <h2 :title="productCopy.name" :data-cy="outletCy.PRODUCTS.SINGLE_PRODUCT.TEXT__NAME">
              {{ productCopy.name }}
            </h2>
            <div>
              <v-price
                flex-row
                :price="productCopy.price || 0"
                :is-market-price="productCopy.marketPrice"
                :show-market-price-long="true"
                :show-market-price-info="false"
                :unit="priceUnitLabel || orderingUnitLabel"
                :data-cy="outletCy.PRODUCTS.SINGLE_PRODUCT.TEXT__PRICE"
              />
            </div>
          </ez-entity-info>
          <div v-if="productCopy.sku" class="product__info">
            <small>SKU Number</small>
            <span :data-cy="outletCy.PRODUCTS.SINGLE_PRODUCT.TEXT__SKU">
              {{ productCopy.sku }}
            </span>
          </div>
          <div v-if="productCopy.description" class="product__info">
            <small>Description</small>
            <span :data-cy="outletCy.PRODUCTS.SINGLE_PRODUCT.TEXT__DESCRIPTION">
              {{ productCopy.description }}
            </span>
          </div>
        </section>
        <section class="tier" v-if="productDeals && productDeals.length">
          <div class="tier__label">Trade Deals</div>
          <div class="tier-list">
            <div v-for="(deal, ind) in productDeals" :key="ind" class="tier-list__item-container">
              <div class="tier-list__item">
                <b> Offer </b>
                <small>
                  {{ `Buy ${deal.buy}, get ${deal.get}` }}
                </small>
                <div>For {{ deal.price | price }}</div>
              </div>
            </div>
          </div>
        </section>
        <section class="tier" v-if="productTiersRows && productTiersRows.length">
          <div class="tier__label">Pricing Tiers</div>
          <div class="tier-list" v-for="(productTiers, idx) in productTiersRows" :key="idx">
            <div
              v-for="(tier, ind) in productTiers"
              :key="tier.id"
              class="tier-list__item-container"
            >
              <div v-if="ind !== 0" class="tier-list__vertical-separator"></div>
              <div class="tier-list__item">
                <b :data-cy="`${outletCy.PRODUCTS.SINGLE_PRODUCT.TEXT__TIER}-${tier.id}`">
                  Tier {{ idx * maxTiersPerRow + ind + 1 }}
                </b>
                <small
                  :data-cy="`${outletCy.PRODUCTS.SINGLE_PRODUCT.TEXT__TIER_FROM_TO}-${tier.id}`"
                >
                  {{
                    !!productTiers[ind + 1]
                      ? `From ${tier.minQuantity} to ${productTiers[ind + 1].minQuantity}`
                      : `From ${tier.minQuantity || 0} and up`
                  }}
                </small>
                <div :data-cy="`${outletCy.PRODUCTS.SINGLE_PRODUCT.TEXT__TIER_PRICE}-${tier.id}`">
                  {{
                    (tier.discountType === 'price'
                      ? tier.value
                      : (1 - tier.value / 100) * productCopy.price) | price
                  }}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="distributor" v-if="productCopy.distributor">
          <div class="distributor__label">{{ $t('global.distributor') }}</div>
          <ez-entity-info
            :imgUrl="productCopy.distributor.logo"
            imgWidth="2.125rem"
            imgHeight="2.125rem"
            imgHasBorder
            imgBorderRadius="50%"
          >
            <div class="distributor--details">
              <div
                class="distributor--details__name"
                :data-cy="outletCy.PRODUCTS.SINGLE_PRODUCT.TEXT__SUPPLIER_NAME"
              >
                {{ productCopy.distributor.name }}
              </div>
              <div class="distributor--details__address">
                <ez-button
                  type="secondary"
                  formType="button"
                  @click="showDistributorInfo"
                  :data-cy="outletCy.PRODUCTS.SINGLE_PRODUCT.BUTTON__SUPPLIER_INFO"
                >
                  <font-awesome-icon icon="info-circle" />
                  <span>{{ $t('global.distributor') }} Info</span>
                </ez-button>
              </div>
            </div>
          </ez-entity-info>
        </section>
        <ez-info-modal
          ref="infoModal"
          v-if="productCopy.distributor"
          :data="productCopy.distributor"
          :data-cy="outletCy.PRODUCTS.SINGLE_PRODUCT.SUPPLIER_INFO_MODAL"
        >
          <template #title>{{ $t('global.distributor') }} Info</template>
        </ez-info-modal>
      </div>
    </template>
    <template #footer>
      <ez-button
        v-if="$permission.has('createOrder')"
        @click="addToCart"
        :disabled="isPriceZeroOrUnavailable"
        :is-loading="isLoading"
        :data-cy="outletCy.PRODUCTS.SINGLE_PRODUCT.BUTTON__ADD_TO_CART"
      >
        Add to Cart
      </ez-button>
      <ez-mask-input-simple
        v-if="$permission.has('createOrder')"
        class="ml-12"
        formKey="product-cart"
        name="count"
        :minValue="productCopy.minimumQuantity ?? 0"
        :maxValue="productQuantityMaxValue(productCopy)"
        :value="productCopy.minimumQuantity"
        :disabled="productCopy.customerInventory?.outOfStock"
        @input="$event => (quantity = $event)"
        :precision="isFractional(productCopy.orderingUnit) ? 2 : 0"
        @invalid="isQuantityInvalid"
        :data-cy="outletCy.PRODUCTS.SINGLE_PRODUCT.INPUT__QUANTITY"
        :has-currency="false"
      />
      <span
        class="price-unit-label ml-4"
        :data-cy="outletCy.PRODUCTS.SINGLE_PRODUCT.TEXT__ORDERING_UNIT"
      >
        {{ productCopy.orderingUnit ? productCopy.orderingUnit.label : '' }}
      </span>
      <span class="total-price">
        <v-price
          :price="totalPrice || productCopy.minimumQuantity * productCopy.price"
          :currency="productCopy.currency"
          :show-market-price-info="false"
          :is-to-be-determined="isToBeDetermined(productCopy)"
          :data-cy="outletCy.PRODUCTS.SINGLE_PRODUCT.TEXT__TOTAL_PRICE"
        />
      </span>
    </template>
  </ez-drawer>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import EzButton from '@/components/ui/Button';
import EzDrawer from '@/components/ui/Drawer';
import EzEntityInfo from '@/components/ui/EntityInfo';
import VPrice from '@/components/v3/elements/VPrice';
import { EzInfoModal } from '@/components/ui/Modal';
import flash from '@/components/ui/FlashMessage';
import { clone, debounce } from '@/util/utils';
import uuid from 'uuid/v4';
import { LOADING_KEY, UNIT_TYPE_FRACTIONAL } from '@/util/constants';
import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';

export default {
  components: {
    EzButton,
    VPrice,
    EzDrawer,
    EzEntityInfo,
    EzInfoModal,
    EzMaskInputSimple,
  },
  props: {
    productId: {
      type: Number,
      required: false,
    },
    maxTiersPerRow: {
      type: Number,
      required: false,
      default: 3,
    },
    product: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  watch: {
    quantity(newVal) {
      this.updateTotalPrice(newVal);
    },
    productId() {
      this.updateTotalPrice(1);
    },
    selectedProduct(val) {
      this.productCopy = clone(val);
    },
  },
  data() {
    return {
      quantity: 1,
      totalPrice: 0,
      productCopy: {},
      outletCy,
    };
  },
  computed: {
    productTiersRows() {
      if (!this.productCopy.tiers.length) return [];
      return this.splitArray(this.productCopy.tiers, this.maxTiersPerRow);
    },
    productDeals() {
      if (!this.productCopy.deals?.length) return [];
      return this.productCopy.deals;
    },
    selectedProduct() {
      return this.product;
    },
    priceUnitLabel() {
      return this.selectedProduct.priceUnit?.label;
    },
    orderingUnitLabel() {
      return this.selectedProduct.orderingUnit?.label;
    },
    isPriceZeroOrUnavailable() {
      return (
        this.selectedProduct.customerInventory?.outOfStock ||
        this.selectedProduct.price < 0 ||
        this.productCopy.quantityInvalid ||
        this.quantity < this.productCopy.minimumQuantity
      );
    },
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.BUTTON_ADD_TO_CART);
    },
  },
  methods: {
    splitArray(arr, size) {
      return arr.length > size
        ? [arr.slice(0, size), ...this.splitArray(arr.slice(size), size)]
        : [arr];
    },
    isQuantityInvalid(val) {
      this.productCopy = { ...this.productCopy, quantityInvalid: val };
    },
    open() {
      this.quantity = this.productCopy.minimumQuantity;
      this.$refs.productDrawer.open();
    },
    close() {
      this.$refs.productDrawer.close();
    },
    async addToCart() {
      await this.pushToCart({
        product: {
          ...this.selectedProduct,
          quantity: Number.parseFloat(this.quantity),
          internalId: uuid(),
        },
        loadingKey: LOADING_KEY.BUTTON_ADD_TO_CART,
      });
      this.quantity = 1;
      this.close();

      const { href } = this.$router.resolve({ name: 'venue-shopping-cart' });

      flash.success({
        title: 'Product successfully added to cart!',
        message: 'Go to cart to check all items you added and complete the order.',
        ctas: `<a href="${href}">Go to Cart</a>`,
      });
    },
    isToBeDetermined(product) {
      const { priceUnit, marketPrice, price } = product || {};
      return (!!priceUnit && price > 0) || marketPrice;
    },
    updateTotalPrice: debounce(function deb() {
      const { selectedProduct, quantity } = this;

      if (!selectedProduct || !selectedProduct.id || !quantity) {
        return;
      }
      this.fetchTotalProductPrice({
        id: selectedProduct.id,
        quantity,
      }).then(({ data: { data } }) => {
        this.totalPrice = data.price;
        this.productCopy.marketPrice = data.marketPrice;
        this.productCopy.price = data.pricePerUnit;
      });
    }, 300),
    showDistributorInfo() {
      this.$refs.infoModal.open();
    },
    isFractional(orderingUnit) {
      return orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    ...mapActions('cart', ['fetchTotalProductPrice', 'pushToCart']),
    ...mapMutations('cart', ['ADD_TO_CART']),
    productQuantityMaxValue(row) {
      const { customerInventory: { orderMoreThanAvailable, inventoryLevel } = {} } = row;
      return !orderMoreThanAvailable && inventoryLevel !== null
        ? inventoryLevel
        : Number.MAX_SAFE_INTEGER;
    },
  },
};
</script>

<style scoped lang="scss">
$drawer-separator-color: #dee1e4;
$price-color: #4d7cfe;

:deep() .drawer {
  &__content {
    padding: 0 1.5rem;
  }
  &__footer {
    @extend %flex-center;
    border-top: 1px solid #e9ebf2;
    padding: 0.625rem 1.5rem;
  }
  .total-price {
    .v-price__price,
    .v-price__market-price--column,
    .v-price__to-be-determined {
      @include font-size(20px);
    }
    margin-left: auto;
    color: $price-color;
    font-weight: bold;
  }
  .ez-number-input {
    margin-left: 1.5rem;
    max-width: 8em;
  }
}
.product {
  @include separator('bottom');
  &__info {
    display: flex;
    flex-direction: column;
    small {
      @extend %gray-label;
      font-weight: 500;
      margin-bottom: 0.25rem;
    }
    span {
      @include font-size(14px);
    }
    + .product__info {
      margin-top: 1rem;
    }
  }
  :deep() .entity-info {
    margin-bottom: 1rem;
    &__text {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      margin-left: 0.75rem;
      padding: 0.375rem 0;
      h2 {
        @include font-size(24px, 29px);
        font-weight: 500;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
      }
    }
  }
}

.tier {
  @include separator('bottom');
  &__label {
    @extend %gray-label;
    font-weight: 500;
    margin-bottom: 0.75rem;
  }
  .tier-list {
    @extend %flex-center;
    background-color: $color-gray-F5;
    padding: 0.75rem;
    margin-top: 12px;

    &__item {
      @extend %flex-center;
      justify-content: space-between;
      flex-direction: column;
      height: 3.75rem;
      margin: 0 auto;
      b {
        @extend %gray-label;
        font-weight: 500;
        text-transform: uppercase;
      }
      small {
        @include font-size(12px, 14px);
      }
      div {
        @include font-size(16px, 19px);
        font-weight: bold;
      }
    }
    &__item-container {
      @extend %flex-center;
      flex: 1 1 auto;
    }
    &__vertical-separator {
      width: 1px;
      background-color: #dddfe6;
      height: 2.5rem;
    }
  }
}

.distributor {
  font-weight: 600;

  &__label {
    @extend %gray-label;
    font-weight: 500;
    padding-bottom: 0.5rem;
  }

  &--details {
    @extend %flex-center;
    justify-content: space-between;
    @include font-size(12px);
    flex: 1 1 auto;
    display: flex;
    padding-left: 2px;

    &__name {
      @include font-size(14px);
      font-weight: 500;
    }

    :deep() .button--secondary {
      svg {
        display: inline-block;
        margin-right: 0.5rem;
      }
    }
  }
}

.category-badge {
  @extend %category-badge;
  max-width: 100%;
  white-space: nowrap;
}
.price-unit-label {
  @include font-size(14px, 16px);
  color: $color-gray-6C;
}

:deep() .mask-input-wrapper {
  width: 75px;

  .mask-input__input {
    text-align: center;
    font-weight: normal;
    color: $color-gray-25;
  }
}
</style>
