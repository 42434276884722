<script>
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import EzFormModal from '@/components/ui/Modal/EzFormModal.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';
import EzInput from '@/components/ui/Input/EzInput.vue';

/**
 * AddGroupModal
 * @version 1.0.0
 * @since 3.29.0
 */
export default {
  name: 'AddGroupModal',
  components: {
    EzFormModal,
    EzButton,
    EzInput,
  },
  data() {
    return {
      groupId: null,
      name: '',
      isEdit: false,
      isOpen: false,
      commonCy,
    };
  },
  computed: {
    disabled() {
      return !this.name;
    },
  },
  methods: {
    open(group, isEdit = false) {
      if (group) {
        this.groupId = group.id;
        this.name = group.name;
      }
      this.isEdit = isEdit;
      this.isOpen = true;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    onClose() {
      this.$emit('onClose');
      this.$refs.productGroupNameInput.clearErrors();
      this.groupId = null;
      this.name = '';
      this.isEdit = false;
      this.isOpen = false;
    },
    async submit() {
      this.$emit('confirm', {
        ...(this.isEdit && { groupId: this.groupId }),
        name: this.name,
      });
    },
  },
};
</script>

<template>
  <ez-form-modal class="group-modal" ref="modal" @close="onClose">
    <template #title>{{ isEdit ? 'Edit Group' : 'Add New Group' }}</template>
    <template #content>
      <ez-input
        v-if="isOpen"
        form-key="createProductGroup"
        ref="productGroupNameInput"
        name="name"
        label="Name"
        placeholder="Enter Group Name"
        :value="name"
        @onChange="name = $event"
        :data-cy="commonCy.PRODUCTS.PRODUCT_GROUP.ADD_EDIT_MODAL.INPUT__GROUP_NAME"
      />
    </template>
    <template #footer>
      <ez-button
        type="link"
        formType="button"
        @click="close"
        :data-cy="commonCy.PRODUCTS.PRODUCT_GROUP.ADD_EDIT_MODAL.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        :disabled="disabled"
        @click="submit"
        type="primary"
        :data-cy="commonCy.PRODUCTS.PRODUCT_GROUP.ADD_EDIT_MODAL.BUTTON__SAVE"
      >
        {{ isEdit ? 'Save Changes' : 'Add New Group' }}
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped>
.group-modal {
  @include z-index('modal', 10);

  :deep() {
    .modal {
      @include z-index('modal', 20);

      .modal__inner {
        .modal__text {
          overflow-y: inherit;
        }
      }
    }
  }
}
</style>
