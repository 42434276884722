<template>
  <div class="order-header">
    <div class="order-header__title">
      <h1>
        <slot name="title">Orders</slot>
      </h1>
      <small v-if="!isProducts && !isProcessing && !noCount">{{ count }} in total</small>
      <small v-else-if="!noCount && isProcessing">{{ `${countProcessing} in total` }}</small>
      <small v-else-if="filtersApplied.term && !noCount">{{
        `${countProducts} results for ${filtersApplied.term}`
      }}</small>
      <small v-else-if="!noCount && isProducts">{{ `${countProducts} in total` }}</small>
    </div>
    <div class="order-header__group">
      <div v-if="!toHideTotal" class="order-header__stats">
        <div class="order-header__stat order-header__stat--amount">
          <small v-if="showLabel">
            <slot name="total">Total Amount For {{ statusLabel }} {{ orderLabel }}</slot>
          </small>
          <span :data-cy="amountDataCy">{{ amount | price }}</span>
        </div>
        <total-icon :icon="totalIcon" :variant="totalIconVariant" :biggerIcon="biggerTotalIcon" />
      </div>
      <div v-if="toShowInvoices && context.pendingInvoicesLimit !== 0" class="order-header__stats">
        <div class="order-header__stat order-header__stat--invoices">
          <span
            v-if="context.pendingInvoicesLimit === null"
            :data-cy="outletCy.ORDERS.GENERAL.TEXT__INVOICE_NUMBER_REMAINING"
          >
            &infin;
          </span>
          <span v-else>
            <span :data-cy="outletCy.ORDERS.GENERAL.TEXT__INVOICE_NUMBER_REMAINING">
              {{ invoicesNumber ? invoicesNumber : context.pendingInvoicesRemaining }}
            </span>
          </span>
          <small>{{ invoicesLabel }}</small>
        </div>
        <total-icon
          :icon="invoicesIcon"
          :variant="invoicesIconVariant"
          :biggerIcon="true"
          v-tooltip="{
            classes: ['tooltip--reset-margin', 'u-text-center'],
            content: 'Number of remaining invoices',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import TotalIcon from '@/views/common/orders/total-icon';
import { statusLabels } from '@/util/constants';
import { pluralize } from '@/util/utils';

export default {
  components: {
    TotalIcon,
  },
  props: {
    amount: {
      type: Number,
      default: 0,
    },
    count: {
      type: Number,
      default: 0,
    },
    noCount: {
      type: Boolean,
      default: false,
    },
    countProducts: {
      type: Number,
      default: 0,
    },
    countProcessing: {
      type: Number,
      default: 0,
    },
    isProducts: {
      type: Boolean,
      required: false,
      default: false,
    },
    isProcessing: {
      type: Boolean,
      required: false,
      default: false,
    },
    filtersApplied: {
      type: Object,
      default: () => ({}),
    },
    toHideTotal: {
      required: false,
      type: Boolean,
      default: false,
    },
    toShowInvoices: {
      required: false,
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    biggerTotalIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    totalIcon: {
      type: String,
      required: false,
      default: 'money-bill-wave',
    },
    totalIconVariant: {
      type: String,
      required: false,
      default: 'green',
    },
    totalIconTooltip: {
      type: Object,
      required: false,
    },
    invoicesIcon: {
      type: String,
      required: false,
      default: 'list-alt',
    },
    invoicesIconVariant: {
      type: String,
      required: false,
      default: 'purple',
    },
    invoicesNumber: {
      type: Number,
      required: false,
      default: null,
    },
    invoicesLabel: {
      type: String,
      required: false,
      default: 'Remaining/total invoices',
    },
    amountDataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      outletCy,
    };
  },
  computed: {
    ...mapState('entities/users', ['context']),
    statusLabel() {
      return statusLabels[this.filtersApplied.status] || 'All';
    },
    /**
     * If we don't have a label the word will be all, therefore, it is always plural.
     * @returns {string}
     */
    orderLabel() {
      return statusLabels[this.filtersApplied.status] ? pluralize('order', this.count) : 'orders';
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .tax-excluded-icon {
  color: $color-gray-6C;
}

.order-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button {
    margin-left: auto;
  }

  &__title {
    display: flex;
    flex-direction: column;

    h1 {
      @include font-size(24px);
      font-weight: 500;
      margin: 0;
    }

    small {
      @include font-size(12px);
      font-weight: 500;
      margin-top: 0.25rem;
      color: $color-gray-6C;
    }
  }

  &__stats {
    display: flex;
    align-items: center;
  }

  &__stat {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    margin-right: 0.75em;

    small {
      @include font-size(12px);
      font-weight: 500;
      letter-spacing: 0.25px;
      color: $color-gray-6C;
    }

    span {
      @include font-size(20px);
      color: $color-gray-25;
      font-weight: 500;
    }

    &--amount {
      small {
        text-transform: uppercase;
      }
    }
  }
}
</style>
