<script>
export default {
  name: 'VAccountOwnerEntityInfo',
  props: {
    accountOwner: {
      required: true,
      type: Object,
    },
    toShowName: {
      required: false,
      type: Boolean,
      default: true,
    },
    tooltipContent: {
      required: false,
      type: String,
    },
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
  },
  computed: {
    color() {
      const colors = this.accountOwner.colors || [];
      return colors.length ? colors[0] : '#252631';
    },
    backgroundColor() {
      const colors = this.accountOwner.colors || [];
      return colors.length ? colors[1] : '#E9EBF2';
    },
    content() {
      return `<span data-cy="${this.dataCy}-tooltipName">${
        this.tooltipContent ?? this.accountOwner.name
      }</span>`;
    },
  },
};
</script>
<template>
  <div class="u-flex-row u-flex-center">
    <div
      v-if="accountOwner"
      v-tooltip="{
        content: toShowName ? '' : content,
        classes: ['tooltip-general'],
        placement: 'start',
      }"
      class="account-owner__badge"
      :style="{
        color: color,
        backgroundColor: backgroundColor,
      }"
    >
      <span v-if="accountOwner.initials" :data-cy="`${dataCy}-initials`">
        {{ accountOwner.initials }}
      </span>
      <span v-else class="account-owner__user-icon">
        <font-awesome-icon icon="user" />
      </span>
    </div>
    <slot v-if="toShowName" name="name">
      <span class="account-owner__name ml-4" :data-cy="`${dataCy}-name`">{{
        accountOwner.name
      }}</span>
    </slot>
  </div>
</template>

<style scoped lang="scss">
.account-owner__badge {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  @include font-size(12px);
  @extend %flex-center;
  justify-content: center;
  border: 1.5px solid #ffffff;
  text-transform: uppercase;
}
.account-owner__name {
  @include font-size(14px, 22px);
  color: $color-gray-25;
}

.account-owner__user-icon {
  color: $color-gray-6C;
}
</style>
