<template>
  <order-list
    :columns="[
      'orderNumber',
      'statusIcon',
      'distributor',
      'expectedAt',
      'orderedAt',
      'createdByUser',
      'status',
      'amount',
    ]"
    :headers="{
      orderNumber: () => 'Order & INV No.',
      createdByUser: () => 'Created By',
      statusIcon: () => '',
      distributor: () => $t('global.distributor'),
      expectedAt: () => `Delivery Date${sortBy === 'expectedAt' ? ' ▼' : ''}`,
      orderedAt: () => `Order Date${sortBy === 'orderedAt' ? ' ▼' : ''}`,
    }"
    :filters="filters"
    :hasStats="false"
    :is-loading-more="isLoadingMore"
    :orders="orders"
    :groupByOrderDate="sortBy === 'orderedAt'"
    @loadMore="onLoadMoreClick"
    @rowClick="openDrawerAndLoadOrder"
    @exportOrder="exportOrder"
    @openIntegrationMapping="openAccountingIntegration"
    show-invoice-number
    @orderedAt="setSortBy('orderedAt')"
    @expectedAt="setSortBy('expectedAt')"
  >
    <template #filtersGroup>
      <div class="filters-grp">
        <ez-filter-list
          :filters="listFilters"
          @filterUpdated="updateListFilters"
          @resetFilter="resetListFilters"
          class="mr-16"
        >
          <ez-input
            formKey="filters"
            name="orderNumber"
            class="search"
            placeholder="Enter Order Number"
            label="Order Number"
            :data-cy="outletCy.ORDERS.GENERAL.INPUT__FILTER_SEARCH"
          >
            <template #suffix>
              <font-awesome-icon icon="search" />
            </template>
          </ez-input>
          <ez-dropdown
            class="ml-16"
            name="processingStatus"
            formKey="filters"
            :data="statusFilterDropdown"
            :selected="filters.status?.length > 1 ? '' : listFilters.processingStatus"
            :placeholder="filters.status?.length > 1 ? 'Multiple' : 'All Statuses'"
          />
          <v-date-picker
            v-if="sortBy === 'expectedAt'"
            ref="calendar"
            class="calendar ml-16"
            name="scheduled"
            label="Delivery Date"
            hide-label
            v-model="scheduledRange"
            formKey="filters"
            is-all-time-in-future
            range-mode
            with-predefined-ranges
            :number-of-calendars="2"
            :select-predefined-range="defaultRangeNameScheduled"
            :what-date-to-display="today"
            @rangeNameChange="changeScheduledRangeName"
            :data-cy="outletCy.ORDERS.GENERAL.BUTTON__DELIVERY_DATE"
          />
          <v-date-picker
            v-else-if="sortBy === 'orderedAt'"
            ref="calendar"
            class="calendar ml-16"
            label="Order Date"
            name="month"
            hide-label
            v-model="range"
            formKey="filters"
            range-mode
            with-predefined-ranges
            :number-of-calendars="2"
            :select-predefined-range="defaultRangeName"
            @rangeNameChange="changeRangeName"
          />
        </ez-filter-list>
        <v-filter-dropdown
          :data-cy="outletCy.ORDERS.GENERAL.BUTTON_FILTERS"
          :filters="initialStatusFilters"
          :default-date-filters="defaultDateFilters"
          @filterUpdated="updateFilters"
          @resetFilters="resetFilters"
          @filterClosed="filterClosed"
          ref="filtersGroup"
        >
          <template #firstRow>
            <v-select-search
              class="select-supplier"
              formKey="filter-orders"
              :selected="initialStatusFilters.distributorId"
              name="distributorId"
              :label="`${$t('global.distributor')}`"
              :data="distributorFilter"
              :placeholder="`All ${$t('global.distributors')}`"
              :searchPlaceholder="`Select ${$t('global.distributor')}`"
              align-left
            >
              <template #result="{ result }">
                <v-distributor-entity-info :distributor="result" />
              </template>
            </v-select-search>

            <v-date-picker
              ref="calendar"
              class="calendar"
              name="scheduled"
              label="Delivery Date"
              v-model="scheduledRange"
              formKey="filters"
              is-all-time-in-future
              range-mode
              with-predefined-ranges
              :number-of-calendars="2"
              :select-predefined-range="defaultRangeNameScheduled"
              :what-date-to-display="today"
              @rangeNameChange="changeScheduledRangeName"
              :data-cy="outletCy.ORDERS.GENERAL.BUTTON__DELIVERY_DATE"
            />

            <v-date-picker
              class="calendar"
              name="month"
              label="Order Date"
              v-model="range"
              formKey="filters"
              range-mode
              with-predefined-ranges
              :number-of-calendars="2"
              :select-predefined-range="defaultRangeName"
              @rangeNameChange="changeRangeName"
            />
          </template>
          <template #secondRowLabel>Status</template>
          <template #secondRow>
            <ez-checkbox
              v-for="status in statusFilter"
              class="status-checkbox"
              :checked="initialStatusFilters[status.id]"
              :key="status.id"
              formKey="filters"
              :name="status.id"
              :label="status.name"
            />
          </template>
        </v-filter-dropdown>
      </div>
    </template>
    <template #actions>
      <div class="orders-actions">
        <export-orders
          :data-cy="outletCy.ORDERS.GENERAL.BUTTON__EXPORT_ORDERS"
          v-if="filters"
          :orders="orders"
          :action="exportOrdersAction"
          :filters="filters"
          :number-of-applied-filters="numberOfAppliedFilters"
          :exportToAccounting="false"
          @exportOrder="exportAllOrders"
        />
      </div>
    </template>
    <template #banner>
      <ez-alert type="purple" v-if="context.actionsCount > 0 && !approvalFilterActive">
        <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
        <template>{{ alertMessage }}</template>
        <template #cta>
          <ez-button
            :data-cy="outletCy.ORDERS.GENERAL.BUTTON__NEEDS_APPROVAL_BANNER"
            type="purple-link"
            @click="filterApproval"
          >
            View Orders
          </ez-button>
        </template>
      </ez-alert>
    </template>

    <ez-drawer ref="orderDrawer" class="order-drawer" @onClose="resetDrawerRoute">
      <template #title>
        <h2>Order #{{ selectedOrder.orderNumber }}</h2>
        <status-badge :status="selectedOrder.status || 'pending'"></status-badge>
      </template>

      <!--   Info messages // Start   -->

      <template #info-message>
        <template v-if="editingMode">
          <div class="mx-24 mb-16">
            <ez-alert variant="disclaimer" class="m-0" size="big">
              <template #icon>
                <font-awesome-icon icon="exclamation-circle" />
              </template>
              <template #title>
                <span>Edit mode active</span>
              </template>
              <p>
                Edit the existing {{ !isPublicOrder ? 'or add new' : '' }} products to this order.
                {{ changeDeliveryDateMsg }}
                Once you save the changes, the {{ venueOrDistributor }} will be notified.
              </p>
            </ez-alert>
          </div>
        </template>
        <template v-if="checkIsMpOrToBeDetermined && hasMpInOrder && !editingMode">
          <div class="mx-24 mb-16">
            <ez-alert variant="disclaimer" class="m-0" size="big">
              <template #icon>
                <font-awesome-icon icon="exclamation-circle" />
              </template>
              <p v-if="hasMpInOrder && hasTbdInOrder">
                This order contains products with market prices and fractional units which may
                affect the total amount (incl. subtotal and taxes) based on the actual delivered
                quantity.
              </p>
              <p v-if="hasMpInOrder && !hasTbdInOrder">
                Product with market price is included in this order. Market prices affect the total
                amount, subtotal and taxes.
              </p>
            </ez-alert>
          </div>
        </template>
        <template
          v-if="
            selectedOrder.status === ORDER_STATUS_SENT &&
            selectedOrder.afterWorkingHours &&
            !editingMode
          "
        >
          <div class="info-message-wrapper">
            <div class="info">
              <div class="column-left">
                <font-awesome-icon icon="info-circle" />
              </div>
              <div class="column-right">
                This order has been placed after work hours. The {{ $t('global.distributor') }} will
                process it on the next working day.
              </div>
            </div>
          </div>
        </template>

        <template v-if="hasIntegrationWarnings && $permission.has('exportAccounting')">
          <div
            v-for="(warning, idx) in selectedOrder.integrationWarnings"
            :key="idx"
            class="mx-24 mb-16"
          >
            <ez-alert variant="disclaimer" size="big">
              <template #icon>
                <font-awesome-icon icon="info-circle" />
              </template>
              <p>{{ warning.text }}</p>
              <ez-button
                v-if="warning.url"
                type="blue-link"
                class="orders__alert-retry"
                @click="onIntegrationWarningCTA(warning.url)"
              >
                {{ warning.cta }}
              </ez-button>
            </ez-alert>
          </div>
        </template>
      </template>

      <!--   Info messages // End   -->

      <template #content>
        <single-order
          :order="selectedOrder"
          :enableEdit="canEditProducts"
          :editingMode="editingMode"
          :hasDeclinedIcon="selectedOrder.status === ORDER_STATUS_DECLINED"
          :can-edit-delivery-on="canEditDeliveryOn"
          :can-edit-invoice-number="canEditInvoiceNumber"
          @editDeliveryOn="onEditDeliveryOn"
          @editInvoiceNumber="onEditInvoiceNumber"
          @invoiceUploaded="uploadedInvoice"
          @editedProduct="editedProduct"
          @productRemoved="editedProduct"
        >
          <template #distributor>
            <div class="u-flex-space" v-if="selectedOrder.distributor">
              <v-distributor-entity-info
                class="entity-info-custom"
                :distributor="selectedOrder.distributor"
              />
              <ez-button-group>
                <export-button
                  class="export-btn"
                  direction="down"
                  v-if="selectedOrder.exportTypes.length"
                  :order="selectedOrder"
                  :exportToAccounting="false"
                  @exportOrder="exportSingleOrder"
                  :openPDF="true"
                />
                <ez-button type="secondary" formType="button" @click="showDistributorInfo">
                  <font-awesome-icon icon="info-circle" />
                  <span>{{ $t('global.distributor') }} Info</span>
                </ez-button>
              </ez-button-group>
            </div>
          </template>
        </single-order>
        <ez-loader :show="creatingBill">Creating Bill...</ez-loader>
      </template>
      <template #footer>
        <template v-if="shouldDisplayFooter">
          <button-switch
            :order="selectedOrder"
            :editing-mode="editingMode"
            @dispute="openDispute"
            @resolve="openResolve"
            @complete="openConfirm"
            @reorder="openReorder"
            @decline="openDecline"
            @abandon="openAbandon"
            @receive="openReceive"
            @createFromDraft="openCreateFromDraft"
            @approve="openApprove"
            @saveEdit="saveEdit"
            @discardEdit="discardEdit"
            @copyToClipboard="copyToClipboard"
            @edit="edit"
          />
          <div v-if="areTaxValuesNumbers" class="order-total-price">
            <v-subtotal-info
              :item-list="itemList"
              :delivery-fee="deliveryFee"
              :delivery-fee-taxable="deliveryFeeTaxable"
              :amount-adjustment="amountAdjustment"
              :tax-adjustment="taxAdjustment"
              :tax="tax"
              :is-tbd="isSomeTbd"
              :discount="orderDiscountAmount"
              :taxCalculation="taxCalculation"
            />
          </div>
        </template>
      </template>
    </ez-drawer>
    <div v-if="isLoading" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-info-modal
      ref="infoModal"
      v-if="selectedOrder.distributor"
      :data="selectedOrder.distributor"
    >
      <template #title>{{ $t('global.distributor') }} Info</template>
    </ez-info-modal>

    <open-dispute
      @success="closeOrderDrawerAndRefresh"
      :venueId="contextId"
      :orderId="selectedOrder.id"
      ref="dispute"
    />
    <approve-order @success="closeOrderDrawerAndRefresh" :order="selectedOrder" ref="approve" />
    <receive-order @success="closeOrderDrawerAndRefresh" :order="selectedOrder" ref="receive" />
    <create-from-draft
      @success="closeOrderDrawerAndRefresh"
      :order="selectedOrder"
      ref="createFromDraft"
    />
    <resolve-order @success="closeOrderDrawerAndRefresh" :order="selectedOrder" ref="resolve" />
    <confirm-order
      @success="closeOrderDrawerAndRefresh"
      :action="`/venues/${contextId}/orders/${selectedOrder.id}/complete`"
      :order="selectedOrder"
      :isPublicOrder="isPublicOrder"
      ref="confirm"
    />
    <reorder @success="closeOrderDrawerAndRefresh" :order="selectedOrder" ref="reorder" />
    <decline-order
      @success="closeOrderDrawerAndRefresh"
      :order="selectedOrder"
      :venueId="contextId"
      ref="decline"
    />
    <ez-confirmation-modal ref="abandon" icon="question">
      <template #title>Cancel Order?</template>
      <template #content>
        <p>Are you sure you want to cancel this order?</p>
        <ez-textarea
          form-key="cancelOrder"
          placeholder="Leave a Note"
          name="message"
          label="Note"
          class="mt-8"
          @onChange="val => (cancelNote = val)"
          :data-cy="commonCy.SINGLE_ORDER.CANCEL_ORDER_MODAL.TEXTAREA__NOTE"
        />
      </template>
      <template #footer>
        <ez-button type="link" @click="closeAbandonModal">Discard</ez-button>
        <ez-button @click="confirmAbandonOrder" :is-loading="isLoadingAbandon">
          Cancel Order
        </ez-button>
      </template>
    </ez-confirmation-modal>

    <ez-confirmation-modal ref="deliveryOnModal">
      <template #title>Edit Delivery Date</template>
      <template #content>
        <div class="mt-16">
          <v-date-picker
            class="calendar mt-8"
            :data-cy="commonCy.SINGLE_ORDER.EDIT_DELIVERY_DATE_MODAL.INPUT__DELIVERY_ON"
            name="expectedAt"
            label="Delivery Date"
            v-model="deliveryOn"
            :deliveryDays="selectedOrder?.distributor?.deliveryDays"
            form-key="filters"
            @dateChange="updateDeliveryOn"
          />
          <ez-textarea
            class="mt-12"
            :data-cy="commonCy.SINGLE_ORDER.EDIT_DELIVERY_DATE_MODAL.TEXTAREA__NOTE"
            name="note"
            label="Note"
            placeholder="Leave a Note"
            :form-key="'deliveryOnForm'"
            :value="deliveryOnNote"
            @onChange="val => (deliveryOnNote = val)"
          />
        </div>
      </template>
      <template #footer>
        <ez-button @click="closeDeliveryOnModal" type="link">Cancel</ez-button>
        <ez-button @click="saveDeliveryOnDate"> Save Changes </ez-button>
      </template>
    </ez-confirmation-modal>

    <ez-confirmation-modal ref="invoiceNumberEditModal">
      <template #title>Edit Invoice Number</template>
      <template #content>
        <div class="mt-16">
          <ez-input
            formKey="order-reference-number"
            name="invoiceNumber"
            label="Invoice Number"
            placeholder="Enter Invoice Number"
            :value="invoiceNumber ? invoiceNumber : selectedOrder.invoiceNumber"
            class="mt-16"
            @onChange="val => (invoiceNumber = val)"
          />
        </div>
      </template>
      <template #footer>
        <ez-button type="link" @click="closeInvoiceNumberModal"> Cancel </ez-button>
        <ez-button @click="saveInvoiceNumber"> Save Changes </ez-button>
      </template>
    </ez-confirmation-modal>

    <ez-loader :show="loading || (isSwitchingAccount && !creatingBill)">Loading...</ez-loader>
  </order-list>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import { resetState } from '@/store';
import httpService from '@/api/http';
import EzButton, { EzButtonGroup } from '@/components/ui/Button';
import EzDrawer from '@/components/ui/Drawer';
import VDistributorEntityInfo from '@/components/v3/patterns/VDistributorEntityInfo';
import { EzConfirmationModal, EzInfoModal } from '@/components/ui/Modal';
import { SingleOrder, OrderList } from '@/views/common/orders';
import StatusBadge from '@/views/common/status-badge';
import {
  DATE_INPUT_FORMAT,
  DELIVERY_PERIODS_MAPPED,
  LOADING_KEY,
  ORDER_DATE_FILTER_DEFAULT,
  OUTLET_ORDER_SCHEDULED_DATE_FILTER_DEFAULT as ORDER_SCHEDULED_DATE_FILTER_DEFAULT,
  ORDER_STATUS_ACCEPTED,
  ORDER_STATUS_COMPLETED,
  ORDER_STATUS_COMPLETED_AFTER_DISPUTE,
  ORDER_STATUS_DECLINED,
  ORDER_STATUS_NEEDS_APPROVAL,
  ORDER_STATUS_PARTIALLY_ACCEPTED,
  ORDER_STATUS_PENDING,
  ORDER_STATUS_SENT,
  ORDER_STATUS_RECEIVED,
  ORDER_STATUS_SHIPPED,
  PARTIALLY_ACCEPTABLE,
  INVOICE_EXPORT_STATUS_READY,
  statusFilterLabelsOutlet,
} from '@/util/constants';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import EzAlert from '@/components/ui/Alert';
import ButtonSwitch from '@/views/platform/venue/orders/ButtonSwitch.vue';
import OpenDispute from '@/views/platform/venue/orders/actions/OpenDispute.vue';
import ApproveOrder from '@/views/platform/venue/orders/actions/ApproveOrder.vue';
import ReceiveOrder from '@/views/platform/venue/orders/actions/ReceiveOrder.vue';
import CreateFromDraft from '@/views/platform/venue/orders/actions/CreateFromDraft.vue';
import ResolveOrder from '@/views/platform/venue/orders/actions/ResolveOrder.vue';
import ConfirmOrder from '@/views/common/orders/actions/ConfirmOrder.vue';
import Reorder from '@/views/platform/venue/orders/actions/Reorder.vue';
import DeclineOrder from '@/views/platform/venue/orders/actions/DeclineOrder.vue';
import flash from '@/components/ui/FlashMessage';
import ExportButton from '@/views/common/orders/ExportButton.vue';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index';
import dayjs from 'dayjs';
import ExportOrders from '@/views/common/orders/ExportOrders';
import EzSpinner from '@/components/ui/Spinner';
import EzInput from '@/components/ui/Input';
import { canVenueEditDeliveryFee, isDeclined, isExcluded, isPremium, debounce } from '@/util/utils';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import EzLoader from '@/components/ui/Loader/EzLoader';
import VSubtotalInfo from '@/components/v3/patterns/VSubtotalInfo';
import VFilterDropdown from '@/components/v3/patterns/VFilterDropdown';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import { isVenue } from '@/mixins/permissions/utils';
import EzTextarea from '@/components/ui/Textarea/EzTextarea';
import { addTotalPriceCustom } from '@/util/utilsFinCalculator';
import EzFilterList from '@/components/ui/FilterList/EzFilterList.vue';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown.vue';

export default {
  components: {
    EzTextarea,
    VSubtotalInfo,
    VSelectSearch,
    EzLoader,
    ExportButton,
    EzButtonGroup,
    DeclineOrder,
    ConfirmOrder,
    ResolveOrder,
    EzAlert,
    EzButton,
    EzDrawer,
    VDistributorEntityInfo,
    EzInfoModal,
    SingleOrder,
    StatusBadge,
    OrderList,
    ButtonSwitch,
    OpenDispute,
    ApproveOrder,
    ReceiveOrder,
    CreateFromDraft,
    Reorder,
    VDatePicker,
    ExportOrders,
    EzConfirmationModal,
    EzSpinner,
    EzInput,
    VFilterDropdown,
    EzCheckbox,
    EzFilterList,
    EzDropdown,
  },
  props: {
    orderId: {
      type: Number,
      required: false,
    },
    isPublicOrder: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      ORDER_STATUS_DECLINED,
      ORDER_STATUS_PENDING,
      ORDER_STATUS_SENT,
      ORDER_STATUS_RECEIVED,
      INVOICE_EXPORT_STATUS_READY,
      commonCy,
      listFilters: {
        orderNumber: null,
      },
      filters: {
        distributorId: null,
        status: null,
      },
      defaultRangeName: ORDER_DATE_FILTER_DEFAULT.name,
      range: {
        start: ORDER_DATE_FILTER_DEFAULT.from,
        end: ORDER_DATE_FILTER_DEFAULT.to,
      },
      loading: false,
      editingMode: false,
      initialStatusFilters: {},
      scheduledRange: {
        start: ORDER_SCHEDULED_DATE_FILTER_DEFAULT.from,
        end: ORDER_SCHEDULED_DATE_FILTER_DEFAULT.to,
      },
      defaultRangeNameScheduled: ORDER_SCHEDULED_DATE_FILTER_DEFAULT.name,
      deliveryPeriodMapped: DELIVERY_PERIODS_MAPPED,
      today: new Date(),
      deliveryOn: null,
      invoiceNumber: null,
      deliveryOnNote: '',
      cancelNote: '',
      outletCy,
      prevDatesLabels: {},
      approvalFilterActive: false,
      orders: [],
      originalSelectedOrder: {},
      distributors: [],
      isSwitchingAccount: false,
      sortBy: 'expectedAt',
      total: 0,
    };
  },
  computed: {
    ...mapState('entities/users', ['context', 'contextId', 'loggedUser']),
    ...mapState('entities/orders', ['meta', 'singleOrder']),
    ...mapGetters('entities/users', [
      'isVenueXeroConnected',
      'isVenueQuickBooksConnected',
      'isVenue',
      'isDistributor',
    ]),
    ...mapGetters('loading', ['getLoading']),
    isBulkExportEnabled() {
      return this.isVenue && this.context?.config?.bulkExportAccounting;
    },
    hasIntegrationWarnings() {
      return !!this.selectedOrder.integrationWarnings?.length;
    },
    areTaxValuesNumbers() {
      const { tax, amount, amountWithTax } = this.selectedOrder;
      return Number.isFinite(tax) && Number.isFinite(amount) && Number.isFinite(amountWithTax);
    },
    venueOrDistributor() {
      let str = '';

      if (this.isPublicOrder) {
        str = this.type === 'venue' ? this.$t('global.distributor') : this.$t('global.venue');
      } else {
        str = this.isDistributor ? this.$t('global.venue') : this.$t('global.distributor');
      }

      return str.toLocaleLowerCase();
    },
    changeDeliveryDateMsg() {
      return this.canEditDeliveryOn ? ' You can also change the delivery date.' : '';
    },
    isLoading() {
      return this.getLoading('load-more-orders');
    },
    isLoadingAbandon() {
      return this.getLoading(LOADING_KEY.ABANDON_ORDER);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.FETCH_MORE_ORDERS);
    },
    creatingBill() {
      return this.getLoading(LOADING_KEY.XERO_CREATE_BILL);
    },
    canEditProducts() {
      return PARTIALLY_ACCEPTABLE.includes(this.selectedOrder.status);
    },
    canEditDeliveryFee() {
      return canVenueEditDeliveryFee({
        order: this.selectedOrder,
        permission: this.$permission,
      });
    },
    canExport() {
      return [
        ORDER_STATUS_COMPLETED_AFTER_DISPUTE,
        ORDER_STATUS_COMPLETED,
        ORDER_STATUS_RECEIVED,
        ORDER_STATUS_ACCEPTED,
        ORDER_STATUS_PARTIALLY_ACCEPTED,
        ORDER_STATUS_SHIPPED,
      ].includes(this.selectedOrder.status);
    },
    canEditDeliveryOn() {
      const { editConfig } = this.selectedOrder;

      return this.editingMode && editConfig?.deliveryDate;
    },
    canEditInvoiceNumber() {
      const { editConfig } = this.selectedOrder;

      return this.editingMode && editConfig?.invoiceNumber;
    },
    alertMessage() {
      const approvalCount = this.context.actionsCount;

      return approvalCount > 1
        ? `There are ${approvalCount} orders awaiting approval. You can see them by clicking on the button on the right.`
        : `There is ${approvalCount} order awaiting approval. You can see it by clicking on the button on the right.`;
    },
    statusFilter() {
      return Object.entries(statusFilterLabelsOutlet).map(([id, name]) => ({
        id,
        name,
      }));
    },
    statusFilterDropdown() {
      const statuses = Object.entries(statusFilterLabelsOutlet).map(([id, name]) => ({
        id,
        name,
      }));
      return [
        {
          id: null,
          name: 'All Statuses',
        },
        ...statuses,
      ];
    },
    distributorFilter() {
      return this.distributors;
    },
    monthFilter() {
      return [
        {
          id: null,
          name: 'All Times',
        },
        ...this.$helpers.generateMonthsToDate(this.meta.firstOrderedAt),
      ];
    },
    exportOrdersAction() {
      return `venues/${this.contextId}/orders`;
    },
    canSeePendingUpdates() {
      return this.$permission.has('seePendingInvoices');
    },
    isVenuePremium() {
      if (this.isPublicOrder && isVenue(this.type)) {
        const {
          venue: { accountType },
        } = this.singleOrder;

        return isPremium(accountType);
      }
      return this.isVenue && this.$permission.isPremium;
    },
    selectedOrder() {
      if (this.singleOrder.status === ORDER_STATUS_PENDING)
        return {
          ...this.singleOrder,
          status: ORDER_STATUS_SENT,
        };
      return this.singleOrder;
    },
    // Props for VSubtotalInfo - Start
    itemList() {
      return this.selectedOrder.orderedProducts
        .filter(prod => !isExcluded(prod) && !isDeclined(prod))
        .map(item =>
          addTotalPriceCustom({
            item,
            multiplicand: 'priceQuantity',
            checkPriceUnits: false,
          }),
        );
    },
    deliveryFee() {
      return this.selectedOrder.deliveryFee;
    },
    deliveryFeeTaxable() {
      return this.selectedOrder.deliveryFeeWithoutTax;
    },
    amountAdjustment() {
      return this.selectedOrder.amountAdjustment;
    },
    taxAdjustment() {
      return this.selectedOrder.taxAdjustment;
    },
    tax() {
      return this.selectedOrder.tax;
    },
    taxCalculation() {
      return this.selectedOrder.taxCalculation;
    },
    isSomeTbd() {
      return (this.selectedOrder.orderedProducts || [])
        .filter(pr => !isDeclined(pr))
        .some(pr => pr.marketPrice || (!pr.priceQuantity && pr.price > 0));
    },
    orderDiscountAmount() {
      return this.selectedOrder?.orderDiscountAmount;
    },
    // Props for VSubtotalInfo - End
    checkIsMpOrToBeDetermined() {
      return this.selectedOrder?.orderedProducts?.some(pr => pr.variableAmount || pr.marketPrice);
    },
    hasMpInOrder() {
      const products = this.selectedOrder?.orderedProducts;
      return products?.some(pr => pr.marketPrice);
    },
    hasTbdInOrder() {
      const products = this.selectedOrder?.orderedProducts;
      return products?.some(pr => pr.variableAmount);
    },
    hasActions() {
      return !!this.selectedOrder?.actions?.length;
    },
    shouldDisplayFooter() {
      return this.hasActions || this.areTaxValuesNumbers;
    },
    defaultDateFilters() {
      return {
        month: {
          from: dayjs(ORDER_DATE_FILTER_DEFAULT.from).valueOf(),
          to: dayjs(ORDER_DATE_FILTER_DEFAULT.to).valueOf(),
        },
        scheduled: {
          from: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.from).valueOf(),
          to: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.to).valueOf(),
        },
      };
    },
    numberOfAppliedFilters() {
      /**
       * Default filters for outlet are valid and we want to enable 'Export Orders'
       */
      const appliedFilters = Object.values(this.initialStatusFilters).filter(
        x => x === null || x === '' || x,
      ).length;

      /**
       * If user selects 'All Time' for 'Delivery Date' from filters dropdown,
       * we want the 'Export Orders' disabled if no other filters are active.
       */
      const isAllTimeScheduled =
        this.initialStatusFilters.scheduled && this.defaultRangeNameScheduled === 'All Time';
      if (isAllTimeScheduled && appliedFilters === 1) return 0;

      return appliedFilters;
    },
  },
  methods: {
    ...mapMutations('entities/orders', [
      'SET_SELECTED_SINGLE_ORDER',
      'UPDATE_SELECTED_SINGLE_ORDER',
      'UPDATE_META',
    ]),
    ...mapActions('entities/orders', [
      'fetchVenueOrder',
      'fetchVenueOrders',
      'fetchVenueOrdersHistoryTotal',
      'venueAbandonOrder',
      'venueSaveOrderEdit',
      'exportToAccountingIntegration',
      'bulkExportToAccountingIntegration',
      'updateExportStatus',
    ]),
    ...mapActions('entities/venues', [
      'venueFetchDeliveryDate',
      'venueFetchDistributorFilterOptions',
    ]),
    ...mapActions('entities/users', ['ownerCheck']),
    openDispute() {
      this.$refs.dispute.open();
    },
    openApprove() {
      this.$refs.approve.open();
    },
    openResolve() {
      this.$refs.resolve.open();
    },
    openConfirm() {
      this.$refs.confirm.open();
    },
    openReorder() {
      this.$refs.reorder.open();
    },
    openDecline() {
      this.$refs.decline.open();
    },
    openAbandon() {
      this.$refs.abandon.open();
    },
    openReceive() {
      this.$refs.receive.open();
    },
    openCreateFromDraft() {
      this.$refs.createFromDraft.open();
    },
    setRangeToAllTime() {
      // Change the range to all time.
      this.range = {
        start: dayjs('1970-01-02'),
        end: dayjs().endOf('d'),
      };
    },
    setScheduledRangeToAllTime() {
      this.scheduledRange = {
        start: dayjs('1970-01-02'),
        end: dayjs().endOf('d'),
      };
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.selectedOrder.copyUrl || '');
      flash.success({
        title: 'Link copied!',
        message: 'The link has been copied successfully.',
      });
    },
    filterApproval() {
      this.scheduledRange = {
        start: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.from).valueOf(),
        end: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.to).valueOf(),
      };
      this.defaultRangeNameScheduled = ORDER_SCHEDULED_DATE_FILTER_DEFAULT.name;

      this.filters = {};

      const filters = {
        [ORDER_STATUS_NEEDS_APPROVAL]: true,
      };

      this.updateFilters(filters, true);
    },
    resetDrawerRoute() {
      this.editingMode = false;
      this.$router.replace({ name: 'venue-orders-history' });
    },
    resetFilters() {
      this.initialStatusFilters = {};
      this.approvalFilterActive = false;
      this.listFilters = {
        orderNumber: this.listFilters.orderNumber,
      };
      Object.keys(this.filters).forEach(key => {
        this.filters[key] = null;
      });
      delete this.filters.scheduledTo;
      delete this.filters.scheduledFrom;

      this.range = {
        start: dayjs(ORDER_DATE_FILTER_DEFAULT.from).valueOf(),
        end: dayjs(ORDER_DATE_FILTER_DEFAULT.to).valueOf(),
      };
      this.scheduledRange = {
        start: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.from).valueOf(),
        end: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.to).valueOf(),
      };
      this.defaultRangeName = ORDER_DATE_FILTER_DEFAULT.name;
      this.defaultRangeNameScheduled = ORDER_SCHEDULED_DATE_FILTER_DEFAULT.name;

      this.$nextTick(() => this.$refs.filtersGroup.syncLocalFilters());
      this.$emit('updateFilter', this.filters);

      this.orders = [];
      this.refresh({ filters: this.filters });
    },
    updateFilters(filters, approvalFilter = false) {
      this.initialStatusFilters = filters;
      this.approvalFilterActive = approvalFilter;

      const filtersKeys = Object.keys(statusFilterLabelsOutlet); // Platform status labels
      // looking for selected status filters
      const statusFilters = [];
      Object.keys(filters).forEach(key => {
        if (filtersKeys.includes(key) && filters[key]) {
          statusFilters.push(key);
          this.listFilters = {
            ...this.listFilters,
            processingStatus: key,
          };
        }
      });

      const isAllTimeScheduled =
        !!this.defaultRangeNameScheduled && // If default range name is 'All Time'
        this.defaultRangeNameScheduled !== 'All Time';

      const isAllTime =
        !!this.defaultRangeName && // If default range name is 'All Time'
        this.defaultRangeName !== 'All Time';

      this.filters = {
        ...this.filters,
        from: isAllTime ? dayjs(this.range?.start).valueOf() : null,
        to: isAllTime ? dayjs(this.range?.end).valueOf() : null,
        distributorId: filters.distributorId?.id,
        scheduledFrom: isAllTimeScheduled ? dayjs(this.scheduledRange?.start).valueOf() : null,
        scheduledTo: isAllTimeScheduled ? dayjs(this.scheduledRange?.end).valueOf() : null,
        status: [...statusFilters],
      };
      if (filters.distributorId === undefined) delete this.filters.distributorId;

      this.range = {
        start: dayjs(filters.month?.start || this.range?.start).valueOf(),
        end: dayjs(filters.month?.end || this.range?.end).valueOf(),
      };
      this.scheduledRange = {
        start: dayjs(filters.scheduled?.start || this.scheduledRange?.start).valueOf(),
        end: dayjs(filters.scheduled?.end || this.scheduledRange?.end).valueOf(),
      };

      this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());
      this.$emit('updateFilter', this.filters);

      this.orders = [];
      this.refresh({ filters: this.filters });
    },
    resetListFilters() {
      this.listFilters = {};
      this.listFilters.orderNumber = null;
      delete this.filters.scheduledTo;
      delete this.filters.scheduledFrom;
      delete this.filters.status;
      delete this.filters.event;
      delete this.filters.processingStatus;
      this.initialStatusFilters = {};
      this.initialStatusFilters = this.filters;
      this.scheduledRange = {
        start: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.from).valueOf(),
        end: dayjs(ORDER_SCHEDULED_DATE_FILTER_DEFAULT.to).valueOf(),
      };
      this.defaultRangeNameScheduled = ORDER_SCHEDULED_DATE_FILTER_DEFAULT.name;
      this.filters = {
        ...this.filters,
        ...this.listFilters,
      };
      this.$emit('updateFilter', this.filters);

      this.orders = [];
      this.refresh({ filters: this.filters });
    },
    updateListFilters: debounce(function deb(name, event) {
      if (typeof event !== 'object' && this.listFilters.orderNumber === event) return;
      if (typeof event !== 'object') this.listFilters.orderNumber = event;
      else if (event.id === null) {
        if (this.listFilters.event === undefined) this.resetListFilters();
        else {
          delete this.listFilters.processingStatus;
          delete this.filters.status;
          delete this.filters.processingStatus;
          this.initialStatusFilters = {};
          this.initialStatusFilters = this.filters;
          if (this.sortBy === 'expectedAt') {
            delete this.initialStatusFilters.scheduledTo;
            delete this.initialStatusFilters.scheduledFrom;
          } else if (this.sortBy === 'orderedAt') {
            delete this.initialStatusFilters.to;
            delete this.initialStatusFilters.from;
          }
          this.filters = {
            ...this.filters,
            ...this.listFilters,
          };
          this.$emit('updateFilter', this.filters);

          this.orders = [];
          this.refresh({ filters: this.filters });
        }
        return;
      } else {
        this.listFilters = {
          ...this.listFilters,
          ...(event.id ? { processingStatus: event.id } : { event }),
        };
        if (event.id) {
          this.statusFilter.forEach(item => delete this.initialStatusFilters[item.id]);
        }
        this.initialStatusFilters = {
          ...this.initialStatusFilters,
          ...(event.id ? { [event.id]: true } : { event }),
        };
        const filtersKeys = Object.keys(statusFilterLabelsOutlet); // Platform status labels
        // looking for selected status filters
        const statusFilters = [];
        if (filtersKeys.includes(event.id)) {
          statusFilters.push(event.id);
        }
        const isAllTimeScheduled =
          !!this.defaultRangeNameScheduled && // If default range name is 'All Time'
          this.defaultRangeNameScheduled !== 'All Time';

        const isAllTime =
          !!this.defaultRangeName && // If default range name is 'All Time'
          this.defaultRangeName !== 'All Time';

        this.filters = {
          ...this.filters,
          ...(this.sortBy === 'expectedAt'
            ? {
                scheduledFrom: isAllTimeScheduled
                  ? dayjs(this.scheduledRange?.start).valueOf()
                  : null,
              }
            : {}),
          ...(this.sortBy === 'expectedAt'
            ? { scheduledTo: isAllTimeScheduled ? dayjs(this.scheduledRange?.end).valueOf() : null }
            : {}),
          ...(this.sortBy === 'orderedAt'
            ? { from: isAllTime ? dayjs(this.range?.start).valueOf() : null }
            : {}),
          ...(this.sortBy === 'orderedAt'
            ? { to: isAllTime ? dayjs(this.range?.end).valueOf() : null }
            : {}),
          ...(event.id ? { status: [...statusFilters] } : {}),
        };
        if (this.sortBy === 'expectedAt') {
          this.scheduledRange = {
            start: dayjs(event.scheduled?.start || this.scheduledRange?.start).valueOf(),
            end: dayjs(event.scheduled?.end || this.scheduledRange?.end).valueOf(),
          };
        } else if (this.sortBy === 'orderedAt') {
          this.range = {
            start: dayjs(event.month?.start || this.range?.start).valueOf(),
            end: dayjs(event.month?.end || this.range?.end).valueOf(),
          };
        }
      }
      this.filters = {
        ...this.filters,
        ...this.listFilters,
      };
      this.$emit('updateFilter', this.filters);

      this.orders = [];
      this.refresh({ filters: this.filters });
    }, 300),
    filterClosed() {
      if (!this.filters.from && !this.filters.to) {
        this.defaultRangeName = ORDER_DATE_FILTER_DEFAULT.name;
      }
      this.resetDateLabelsIfNeeded();
      this.resetDateFiltersIfNeeded();
    },
    resetDateLabelsIfNeeded() {
      const { defaultRangeName, defaultRangeNameScheduled } = this.prevDatesLabels;
      const sameOrderLabel = defaultRangeName === this.defaultRangeName;
      const sameDeliveryLabel = defaultRangeNameScheduled === this.defaultRangeNameScheduled;

      if (!sameOrderLabel) this.changeRangeName(defaultRangeName);
      if (!sameDeliveryLabel) this.changeScheduledRangeName(defaultRangeNameScheduled);
    },
    resetDateFiltersIfNeeded() {
      const { month, scheduled } = this.initialStatusFilters;

      if (month) {
        const sameOrderDate = month.start === this.range.start && month.end === this.range.end;
        if (!sameOrderDate) this.range = { ...month };
      }

      if (scheduled) {
        const sameScheduledDate =
          scheduled.start === this.scheduledRange.start &&
          scheduled.end === this.scheduledRange.end;
        if (!sameScheduledDate) this.scheduledRange = { ...scheduled };
      }
    },
    changeRangeName(rangeName) {
      this.defaultRangeName = rangeName;
    },
    changeScheduledRangeName(rangeName) {
      this.defaultRangeNameScheduled = rangeName;
    },
    openOrderDrawer() {
      this.$refs.orderDrawer.open();
    },
    async closeOrderDrawerAndRefresh() {
      this.orders = [];
      await this.refresh({ filters: this.filters });
      this.closeOrderDrawer();
    },
    closeOrderDrawer() {
      this.$refs.orderDrawer.close();
    },
    closeAbandonModal() {
      this.$refs.abandon.close();
    },
    async confirmAbandonOrder() {
      await this.venueAbandonOrder({
        orderId: this.selectedOrder.id,
        venueId: this.contextId,
        cancelNote: this.cancelNote,
      });

      this.orders = [];
      await this.refresh({ filters: this.filters });

      this.closeAbandonModal();
      this.closeOrderDrawer();

      const { href } = this.$router.resolve({
        name: 'venue-orders-history',
        params: { id: this.selectedOrder.id },
      });
      flash.success({
        title: 'Order successfully cancelled.',
        message: 'You can see the order overview in Order Details.',
        ctas: `<a href="${href}">View Order Details</a>`,
      });
    },
    async openDrawerAndLoadOrder({ id }) {
      try {
        const { data } = await this.fetchVenueOrder({ id });
        this.originalSelectedOrder = data.data;

        this.$router.push({
          name: 'venue-orders-history',
          params: { id },
        });

        this.openOrderDrawer();
      } catch (e) {
        this.resetDrawerRoute();
      }
    },
    async loadDrawerOrder({ id }) {
      const token = this.$route.query?.token;
      if (token) {
        try {
          const { data: accountOwnerData } = await this.ownerCheck({
            data: {
              entity: 'order',
              entityId: id,
              scope: 'venue',
            },
          });
          if (accountOwnerData.data.id === this.loggedUser.id) {
            const { data } = await this.fetchVenueOrder({ id });
            const orderIndex = this.orders.findIndex(item => item.id === id);
            if (orderIndex !== -1) {
              this.orders[orderIndex].exportStatus = data.data.exportStatus;
            }
            this.originalSelectedOrder = data.data;

            this.openOrderDrawer();
          } else {
            this.isSwitchingAccount = true;
            const { data } = await httpService.put(`/accounts/switch/${accountOwnerData.data.id}`);
            const { platform } = data.data;
            if (process.env.VUE_APP_PLATFORM_TITLE.toLowerCase() === platform) {
              await httpService.delete('/manager/oauth');
              localStorage.clear();
              resetState();
            }
            this.isSwitchingAccount = false;
            window.location.href = `${data.data.url}/login?token=${data.data.token}&redirectTo=venue-orders-history&orderId=${id}`;
          }
        } catch (e) {
          this.resetDrawerRoute();
        }
      } else {
        try {
          const { data } = await this.fetchVenueOrder({ id });
          this.originalSelectedOrder = data.data;
          const orderIndex = this.orders.findIndex(item => item.id === id);
          if (orderIndex !== -1) {
            this.orders[orderIndex].exportStatus = data.data.exportStatus;
          }

          this.openOrderDrawer();
        } catch (e) {
          this.resetDrawerRoute();
        }
      }
    },
    editedProduct() {
      this.loadDrawerOrder({ id: this.orderId });
    },
    onEditDeliveryOn() {
      this.$refs.deliveryOnModal.open();
    },
    onEditInvoiceNumber() {
      this.$refs.invoiceNumberEditModal.open();
    },
    saveDeliveryOnDate() {
      this.UPDATE_SELECTED_SINGLE_ORDER({
        ...this.selectedOrder,
        expectedAt: dayjs(this.deliveryOn.format(DATE_INPUT_FORMAT)).valueOf(),
        isExpectedAtEdited: true,
        message: this.deliveryOnNote,
      });
      this.$refs.deliveryOnModal.close();
    },
    saveInvoiceNumber() {
      this.UPDATE_SELECTED_SINGLE_ORDER({
        ...this.selectedOrder,
        invoiceNumber: this.invoiceNumber,
        isInvoiceNumberEdited: true,
      });
      this.$refs.invoiceNumberEditModal.close();
    },
    closeInvoiceNumberModal() {
      this.$refs.invoiceNumberEditModal.close();
      this.invoiceNumber = null;
    },
    updateDeliveryOn(date) {
      this.deliveryOn = date;
    },
    closeDeliveryOnModal() {
      this.$refs.deliveryOnModal.close();
    },
    async onLoadMoreClick() {
      await this.refresh(
        {
          filters: this.filters,
          sortBy: this.sortBy,
          ...(this.meta?.nextId && { nextId: this.meta.nextId }),
          ...(this.meta?.nextValue && { nextValue: this.meta.nextValue }),
        },
        LOADING_KEY.FETCH_MORE_ORDERS,
      );
    },
    showDistributorInfo() {
      this.$refs.infoModal.open();
    },
    async refresh(query = {}, loadingKey = LOADING_KEY.FETCH_ORDERS) {
      const { data } = await this.fetchVenueOrders({ sortBy: this.sortBy, ...query, loadingKey });
      if (loadingKey === LOADING_KEY.FETCH_ORDERS) {
        this.orders = data.map(item => {
          if (item.status === ORDER_STATUS_PENDING) return { ...item, status: 'sent' };
          return item;
        });
        const { data: total } = await this.fetchVenueOrdersHistoryTotal({
          query: {
            ...(this.sortBy === 'expectedAt'
              ? { scheduledFrom: dayjs().startOf('M').valueOf() }
              : { from: dayjs().startOf('M').valueOf() }),
            ...(this.sortBy === 'expectedAt'
              ? { scheduledTo: dayjs().endOf('M').valueOf() }
              : { to: dayjs().endOf('M').valueOf() }),
            ...query.filters,
          } ?? {
            ...(this.sortBy === 'expectedAt'
              ? { scheduledFrom: dayjs().startOf('M').valueOf() }
              : { from: dayjs().startOf('M').valueOf() }),
            ...(this.sortBy === 'expectedAt'
              ? { scheduledTo: dayjs().endOf('M').valueOf() }
              : { to: dayjs().endOf('M').valueOf() }),
          },
        });
        this.total = total.data;
        this.UPDATE_META({ meta: { ...this.meta, totalAmount: total.data } });
      } else {
        this.orders = [
          ...this.orders,
          ...data.map(item => {
            if (item.status === ORDER_STATUS_PENDING) return { ...item, status: 'sent' };
            return item;
          }),
        ];
        this.UPDATE_META({ meta: { ...this.meta, totalAmount: this.total } });
      }
    },
    uploadedInvoice() {
      this.loadDrawerOrder({ id: this.orderId });
    },
    edit() {
      this.editingMode = true;
    },
    async saveEdit() {
      try {
        this.loading = true;
        if (!this.canEditDeliveryFee) delete this.singleOrder.deliveryFee;

        await this.venueSaveOrderEdit({ order: this.singleOrder, venueId: this.contextId });
        this.orders = [];
        await this.refresh({ filters: this.filters });
        this.editingMode = false;

        const message = `You have successfully edited the order. ${this.$t(
          'global.distributor',
        )} will be notified about the change.`;
        flash.success({
          title: 'Order successfully edited.',
          ...(!this.isVenuePremium ? { message } : {}),
        });
      } finally {
        this.loading = false;
      }
    },
    discardEdit() {
      this.editingMode = false;
      this.SET_SELECTED_SINGLE_ORDER(this.originalSelectedOrder);
    },
    async fetchDistributors() {
      const { data } = await this.venueFetchDistributorFilterOptions({
        venueId: this.contextId,
        query: { for: 'orders' },
      });
      this.distributors = data.data;
    },
    async onIntegrationWarningCTA(url) {
      this.loading = true;

      try {
        await httpService.put(url);
        await this.loadDrawerOrder({ id: this.orderId });

        this.loading = false;
        flash.success({ title: 'Action completed successfully!' });
      } catch (e) {
        this.loading = false;
        // eslint-disable-next-line no-console
        console.error(e);
        flash.error({
          title: 'Something went wrong!',
          message: e.response.data.error.message,
        });
      }
    },
    async exportOrder(id) {
      try {
        const orderIndex = this.orders.findIndex(item => item.id === id);
        if (orderIndex !== -1) {
          this.orders[orderIndex].exportStatus = 'loading';
        }
        await this.exportToAccountingIntegration({
          venueId: this.contextId,
          orderId: id,
        });
        if (orderIndex !== -1) {
          this.orders[orderIndex].exportStatus = 'completed';
        }
        flash.success({
          title: 'Order successfully exported!',
        });
      } catch (e) {
        if (e.response.status === 400) {
          const orderIndex = this.orders.findIndex(item => item.id === id);
          if (orderIndex !== -1) {
            this.orders[orderIndex].exportStatus = 'error';
          }
        }
      }
    },
    async exportSingleOrder() {
      this.exportOrder(this.selectedOrder.id);
    },
    async exportAllOrders() {
      await this.bulkExportToAccountingIntegration({ venueId: this.contextId });
    },
    async openAccountingIntegration(order) {
      if (this.isVenue) {
        await this.updateExportStatus({
          venueId: this.contextId,
          orderId: order.id,
          exportStatus: INVOICE_EXPORT_STATUS_READY,
        });
        const orderIndex = this.orders.findIndex(item => item.id === order.id);
        if (orderIndex !== -1) {
          this.orders[orderIndex].exportStatus = INVOICE_EXPORT_STATUS_READY;
        }
        const routerData = this.$router.resolve({
          name: 'venue-all-suppliers-products',
          query: {
            productGroup: 'unassigned',
            supplierId: order.distributorId,
          },
        });
        window.open(routerData.href, '_blank');
      }
    },
    async setSortBy(param) {
      if (this.sortBy === param) return;
      this.sortBy = param;
      await this.$nextTick();
      if (param === 'orderedAt') {
        this.defaultRangeName = this.defaultRangeNameScheduled;
        this.defaultRangeNameScheduled = 'All Time';
        this.range = this.scheduledRange;
        this.setScheduledRangeToAllTime();
        delete this.initialStatusFilters.scheduledTo;
        delete this.initialStatusFilters.scheduledFrom;
        this.initialStatusFilters.month = this.initialStatusFilters.scheduled;
        delete this.initialStatusFilters.scheduled;
      } else if (param === 'expectedAt') {
        this.defaultRangeNameScheduled = this.defaultRangeName;
        this.defaultRangeName = 'All Time';
        this.scheduledRange = this.range;
        this.setRangeToAllTime();
        delete this.initialStatusFilters.to;
        delete this.initialStatusFilters.from;
        this.initialStatusFilters.scheduled = this.initialStatusFilters.month;
        delete this.initialStatusFilters.month;
      }
      const isAllTimeScheduled =
        !!this.defaultRangeNameScheduled && // If default range name is 'All Time'
        this.defaultRangeNameScheduled !== 'All Time';

      const isAllTime =
        !!this.defaultRangeName && // If default range name is 'All Time'
        this.defaultRangeName !== 'All Time';

      this.filters = {
        ...this.filters,
        from: isAllTime ? dayjs(this.range?.start).valueOf() : null,
        to: isAllTime ? dayjs(this.range?.end).valueOf() : null,
        scheduledFrom: isAllTimeScheduled ? dayjs(this.scheduledRange?.start).valueOf() : null,
        scheduledTo: isAllTimeScheduled ? dayjs(this.scheduledRange?.end).valueOf() : null,
      };
      await this.refresh({ filters: this.filters });
      if (this.orderId) await this.loadDrawerOrder({ id: this.orderId });
    },
  },
  watch: {
    originalSelectedOrder(newVal) {
      this.SET_SELECTED_SINGLE_ORDER(newVal);
    },
    selectedOrder(order) {
      if (!order.expectedAt) {
        return;
      }
      this.deliveryOn = dayjs(order.expectedAt);
    },
    filters: {
      immediate: true,
      handler() {
        this.prevDatesLabels = {
          defaultRangeName: this.defaultRangeName,
          defaultRangeNameScheduled: this.defaultRangeNameScheduled,
        };
      },
    },
  },
  async created() {
    if (this.$route.query.status && this.$route.query.status.length) {
      if (Array.isArray(this.$route.query.status)) {
        this.$route.query.status.forEach(item => {
          this.initialStatusFilters[item] = true;
        });
        this.filters.status = this.$route.query.status;
        this.listFilters.processingStatus =
          this.$route.query.status[this.$route.query.status.length - 1];
      } else {
        this.initialStatusFilters[this.$route.query.status] = true;
        this.filters.status = this.$route.query.status;
        this.listFilters.processingStatus = this.$route.query.status;
      }
    }
    await Promise.all([
      // Initial filters that should be applied.
      this.refresh({ filters: this.filters }),
      this.fetchDistributors(),
    ]);
    if (this.orderId) await this.loadDrawerOrder({ id: this.orderId });
  },
};
</script>

<style scoped lang="scss">
.filters-grp {
  display: flex;
}
:deep() .ez-filter-list .ez-filter-list__items .ez-dropdown {
  width: auto;
  min-width: 128px;

  .ez-dropdown__options ul {
    min-width: 100%;
    width: max-content;
  }
}

:deep() .calendar .date-picker .placeholder {
  min-width: 128px;
}
:deep() .loader {
  @extend %whole-screen;
}

:deep() .drawer {
  @extend %order-drawer;
}

:deep() .icon-text {
  color: inherit;
}

:deep() .select-search {
  color: $color-gray-6C;

  &__list {
    max-height: 244px;
  }
}

.orders__alert-retry {
  padding: 0;
  height: 25px;
}

.orders__alert-retry-group {
  & + & {
    margin-left: 25px;
  }
}
.info-message-wrapper {
  padding: 0 24px 0;
  margin-bottom: px-to-rem(16px);
  display: flex;
  justify-content: flex-start;
  .info {
    padding: 12px;
    background-color: $yellow-bg;
    border-radius: 3px;
    display: flex;
    width: 100%;
    @include font-size(14px);
    .column-left {
      margin-right: 10px;
      color: $color-primary-orange;
    }
    .column-right {
      color: $color-gray-6C;
      .create-invoice {
        padding: 0;
        height: 25px;
        &--disabled {
          color: $color-primary-blue;
          cursor: not-allowed;
        }
      }
    }
  }
}

.orders-actions {
  display: flex;
  flex-shrink: 0;
}

.export-btn {
  :deep() .ez-simple-dropdown {
    &__label {
      color: $color-gray-6C;
    }
  }
}

.calendar {
  :deep() .date-picker {
    .placeholder {
      color: $color-gray-6C;
    }
  }
}

.search {
  min-width: 168px;
}

.log-offline {
  &--custom-width {
    :deep() .button-dropdown {
      &__toggle-container {
        .button span {
          margin-right: 0 !important;
          padding-right: 15px;
        }
      }

      &__dropdown {
        width: 280px;
      }
    }
  }
  .upgrade-premium {
    width: 392px;
  }
}

.offline-order-item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 8px 15px;
  color: $color-gray-25;
  @include font-size(14px, 24px);
  cursor: pointer;
  &:hover {
    background-color: #f5f6fa;
  }
  &__icon {
    color: $color-gray-6C;
  }
  &__info {
    &__text {
      color: $color-gray-6C;
      @include font-size(12px, 18px);
      margin: 0;
    }
  }
}
.entity-info-custom {
  :deep() .entity-info {
    &__text {
      max-width: 80%;
    }
  }
}
.calendar {
  :deep() .date-picker {
    .ez-simple-dropdown__display-container {
      width: 100%;
      .placeholder {
        width: 100%;
        color: $color-gray-6C;
      }
    }
  }
}
.select-supplier {
  :deep() .select-search__trigger {
    width: 100%;
  }
}
.status-checkbox {
  @include font-size(14px, 20px);
}

:deep() .export-button.ez-simple-dropdown {
  width: fit-content;
}

:deep() .export-button .ez-simple-dropdown__dropdown {
  min-width: max-content;
}
</style>
