<script>
/**
 *
 * @version 1.0.0
 * @since 3.0.0
 */

import { wizardEmitterMixin } from '@/mixins/wizard';
import EzButton from '@/components/ui/Button/EzButton';
import EzWizard from '@/components/layout/Wizard';
import steps, {
  OFFLINE_ORDER_STEP_1, OFFLINE_ORDER_STEP_2, OFFLINE_ORDER_STEP_3,
} from '@/views/platform/venue/orders/new-offline/existing-supplier/steps';
import { mapMutations } from 'vuex';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';

export default {
  mixins: [wizardEmitterMixin],
  components: {
    EzButton,
    EzWizard,
  },
  data() {
    return {
      OFFLINE_ORDER_STEP_1,
      OFFLINE_ORDER_STEP_2,
      OFFLINE_ORDER_STEP_3,
      currentStep: 0,
      nextDisabled: true,
      outletCy,
    };
  },
  computed: {
    currentStepComponent() {
      return steps[this.currentStep].component;
    },
    currentStepPageTitle() {
      return steps[this.currentStep].pageTitle;
    },
    currentStepPageInfo() {
      return steps[this.currentStep].pageInfo;
    },
    nextCta() {
      return steps[this.currentStep].nextCta;
    },
    isFirstStep() {
      return this.currentStep === OFFLINE_ORDER_STEP_1;
    },
    isLastStep() {
      return this.currentStep === OFFLINE_ORDER_STEP_3;
    },
    disableNext() {
      return false;
    },
  },
  methods: {
    ...mapMutations('entities/venues', ['CLEAR_OFFLINE']),
    clearState() {
      this.CLEAR_OFFLINE();
    },
    onWizardExit() {
      this.clearState();
      this.$router.push({ name: 'venue-orders' });
    },
    onStepBack(step) {
      if (step !== undefined && step >= OFFLINE_ORDER_STEP_1 && step <= OFFLINE_ORDER_STEP_3) {
        this.currentStep = step;
        return;
      }

      if (this.currentStep <= OFFLINE_ORDER_STEP_1) {
        return;
      }

      this.currentStep -= 1;
    },
    onStepCompleted() {
      if (this.currentStep >= OFFLINE_ORDER_STEP_3) {
        return;
      }
      this.nextDisabled = true;
      this.currentStep += 1;
    },
  },
  beforeDestroy() {
    this.clearState();
  },
};
</script>
<template>
  <div>
    <ez-wizard
      :fullWidth="currentStep === OFFLINE_ORDER_STEP_2"
      @exit="onWizardExit"
      @back="goToPreviousStep"
    >
      <template #title>Log Offline Order</template>
      <template #nav>
        <ul>
          <li :class="{
          'ez-step-active': currentStep === OFFLINE_ORDER_STEP_1,
          'ez-step-completed': currentStep > OFFLINE_ORDER_STEP_1,
        }">
            1. {{ $t('global.distributor') }}
          </li>
          <li :class="{
          'ez-step-active': currentStep === OFFLINE_ORDER_STEP_2,
          'ez-step-completed': currentStep > OFFLINE_ORDER_STEP_2,
        }">
            2. {{ $t('global.products') }}
          </li>
        </ul>
      </template>
      <template #actions>
        <ez-button
          :disabled="nextDisabled"
          @click="goToNextStep"
          :data-cy="outletCy.ORDERS.EXISTING_OFFLINE_ORDER.BUTTON__NEXT_STEP"
        >
          {{ nextCta }}
        </ez-button>
      </template>
      <template #prevStep>
        <ez-button
          v-if="currentStep > OFFLINE_ORDER_STEP_1 && currentStep < OFFLINE_ORDER_STEP_3"
          type="link"
          formType="button"
          @click="goToPreviousStep"
          :data-cy="outletCy.ORDERS.EXISTING_OFFLINE_ORDER.BUTTON__PREV_STEP"
        >
          <font-awesome-icon icon="arrow-left"/>
          <span>Back</span>
        </ez-button>
      </template>
      <template #pageTitle>{{ currentStepPageTitle }}</template>
      <template #pageInfo><span v-html="currentStepPageInfo"/></template>
      <component
        :is="currentStepComponent"
        @stepCompleted="onStepCompleted"
        @stepBack="onStepBack"
      />
    </ez-wizard>
  </div>
</template>
<style lang="scss" scoped>
</style>
