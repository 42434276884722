import bus from './bus';

function confirm({
  title,
  message,
  buttons,
  loadingKey,
}) {
  return new Promise(((resolve, reject) => {
    bus.$emit('show', {
      title,
      message,
      icon: 'question',
      type: 'blue',
      buttons,
      loadingKey,
      resolve,
      reject,
    });
  }));
}

function alert({
  title, message, buttons,
}) {
  return new Promise(((resolve, reject) => {
    bus.$emit('show', {
      title, message, type: 'red', buttons, resolve, reject,
    });
  }));
}

export default {
  confirm,
  alert,
};
