<template>
  <ez-button-dropdown
    :data-cy="dataCy"
    :expandOnClick="true"
    buttonType="white"
  >
    <slot></slot>
    <template #dropdown>
      <slot name="dropdown"></slot>

      <div class="dropdown-section">
        <hr/>

        <slot name="userSettings"></slot>

        <ez-button
          :data-cy="commonCy.ACCOUNT_SETTINGS.BUTTON__LEGAL"
          type="link"
          formType="button"
          isFullWidth
          @click="$router.push({ name: legal })"
        >
          Legal
        </ez-button>
        <ez-button
          :data-cy="commonCy.ACCOUNT_SETTINGS.BUTTON__HELP"
          type="link"
          formType="button"
          isFullWidth
          @click="openHelp"
        >
          Help
        </ez-button>
        <hr/>
        <ez-button
          :data-cy="commonCy.ACCOUNT_SETTINGS.BUTTON__SIGN_OUT"
          type="link"
          formType="button"
          customClass="gray-link"
          isFullWidth
          @click="onLogoutClick"
        >
          Sign Out
        </ez-button>
      </div>
    </template>
  </ez-button-dropdown>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import EzButton from '@/components/ui/Button';
import EzButtonDropdown from '@/components/ui/ButtonDropdown';
import { resetState } from '@/store';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';

export default {
  components: {
    EzButton,
    EzButtonDropdown,
  },
  props: {
    /**
     * This attribute is used for
     * marking the element when testing with cypress
     */
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpen: false,
      commonCy,
    };
  },
  computed: {
    legal() {
      const role = localStorage.getItem('role');

      if (role === 'venue') {
        return 'venue-legal';
      }

      return 'distributor-legal';
    },
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    onLogoutClick() {
      this.managerLogout()
        .finally(() => {
          localStorage.clear();
          resetState();
          window.Intercom('shutdown');
          this.$router.replace({ name: 'platform-login' });
        });
    },
    openHelp() {
      const helpUrl = process.env.VUE_APP_HELP_LINK;
      window.open(helpUrl, '_blank');
    },
    ...mapActions('entities/users', [
      'managerLogout',
    ]),
    ...mapMutations('entities/users', [
      'SWITCH_CONTEXT',
    ]),
    ...mapMutations('cart', [
      'EMPTY_CART',
    ]),
  },
};
</script>


<style scoped lang="scss">
  :deep() .button-dropdown {
    &__toggle-container {
      .entity-info__text {
        max-width: 10rem;

        span {
          display: inline-block;
          overflow-x: hidden;
          line-height: 1.6;
          text-overflow: ellipsis;
        }
      }

      .button {
        &:last-child {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }

    &__dropdown {
      width: 15rem;
      padding: .25rem 0;
      max-height: calc(100vh - 60px);
      overflow: auto;

      .dropdown-section {
        &__label {
          @extend %gray-label;
          @include font-size(11px, 13px);
          font-weight: 600;
          letter-spacing: .15px;
          text-transform: uppercase;
          margin: .5rem 0;
          padding: 0 .75rem;
        }

        &:first-child {
          padding-top: 0;
        }

        &:last-child {
          padding-bottom: 0;
        }

        hr {
          @include separator('bottom', .25rem, .25rem, 1px solid #DEE1E4);
          margin-left: .75rem;
          margin-right: .75rem;
        }
      }

      .context-section {
        .dropdown-section__label {
          margin-top: 1rem;
        }
      }

      .button--link {
        @include font-size(14px, 16px);
        display: block;
        padding: .625rem .75rem;
        color: $color-gray-25;
        font-weight: 400;
        text-align: left;
        text-decoration: none;

        &:hover {
          background-color: $color-gray-F5;
          cursor: pointer;
        }

        &.gray-link {
          color: $color-gray-6C;
        }
      }

      .entity-info {
        margin: .25rem .75rem;

        &__text {
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-around;
        }

        a {
          @extend %flex-center;
          @include font-size(12px, 14px);
          color: $color-gray-6C;
          text-decoration: none;
          font-weight: 400;

          span {
            margin-right: .25rem;
          }

          svg {
            @include font-size(12px);
          }
        }
      }
    }
  }
</style>
