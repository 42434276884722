<script>
import VDistributorEntityInfo from '@/components/v3/patterns/VDistributorEntityInfo/index';
import { mapActions, mapState } from 'vuex';
import EzButton from '@/components/ui/Button/EzButton';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  components: {
    VDistributorEntityInfo,
    EzButton,
  },
  props: {
    supplierId: {
      type: Number,
    },
    hasActions: {
      type: Boolean,
    },
  },
  data() {
    return {
      distributor: {},
      buttonType: 'primary',
    };
  },
  computed: {
    ...mapState('entities/users', [
      'contextId',
    ]),
    isAccepted() {
      return this.buttonType === 'green';
    },
  },
  methods: {
    accept() {
      this.buttonType = 'green';
      this.$emit('accepted');
    },
    downloadPdf() {
      this.$emit('download');
    },
    ...mapActions('entities/distributors', [
      'venueFetchSupplierSingle',
    ]),
  },
  async mounted() {
    const { data } = await this.venueFetchSupplierSingle({
      id: this.supplierId,
    });
    this.distributor = data.data;
  },
};
</script>
<template>
  <div class="footer">
    <div class="footer__container">
      <v-distributor-entity-info :distributor="distributor" />
      <div v-if="hasActions">
        <ez-button
          @click="downloadPdf"
          type="secondary">
          <font-awesome-icon icon="file-pdf"/>
          Download PDF
        </ez-button>
        <ez-button
          @click="accept"
          class="ml-12"
          :type="buttonType">
          <template v-if="isAccepted">
            <font-awesome-icon icon="check"/>
          </template>{{isAccepted ? 'Accepted' : 'I Accept'}}
        </ez-button>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
  .footer {
    background-color: #ffffff;
    border-top: 1px solid $color-gray-E9;
    width: 100%;
    height: 56px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    &__container {
      max-width: 792px;
      padding: 0 24px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>
