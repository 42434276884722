<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { venueAuthorize, venueGetXeroAccounts, venueGetXeroTaxesSetup } from '@/api/endpoints/xero';
import { wizardListenerMixin } from '@/mixins/wizard';
import XeroAccountSettingsForm from '@/views/platform/distributor/xero/setup/XeroAccountSettingsForm';

/**
 * AccountSetup
 * @version 1.0.0
 * @since 1.0.0
 */
export default {
  mixins: [wizardListenerMixin],
  components: {
    XeroAccountSettingsForm,
  },
  inject: ['venueId'],
  data() {
    return {
      accounts: [],
      taxes: [],
      selectedAccount: null,
      selectedTax: null,
      selectedDeliveryFeeAccount: null,
      selectedExportModeId: '',
      orderExportModes: [
        { id: 'manual', name: 'Manual' },
        { id: 'product_groups', name: 'Product Group Settings Required' },
        { id: 'auto', name: 'Automatic' },
      ],
    };
  },
  computed: {
    ...mapState('xero', ['draft', 'isAuthorized']),
    ...mapGetters('entities/users', ['isVenueXeroConnected']),

    isXeroConnected() {
      return this.isVenueXeroConnected(this.venueId);
    },
    isBothSelected() {
      return this.selectedAccount !== null || Object.keys(this.draft).length === 1;
    },
  },
  watch: {
    isBothSelected() {
      this.enableNextStep();
    },
  },
  methods: {
    changeSelectedAccount(item) {
      this.selectedAccount = item;
      this.UPDATE_DRAFT({ account: item });
    },
    changeTax(item) {
      this.selectedTax = item.taxType;
      this.UPDATE_DRAFT({ tax: item });
    },
    changeDeliveryFee(item) {
      this.selectedDeliveryFeeAccount = item;
      this.UPDATE_DRAFT({ deliveryFeeAccountCode: item });
    },
    changeExportMode(item) {
      this.buttonDisabled = false;
      this.UPDATE_DRAFT({ orderingMode: item });
    },
    ...mapMutations('xero', ['UPDATE_DRAFT', 'AUTHORIZE']),

    onNextStep() {
      this.$emit('stepCompleted');
    },
  },
  async mounted() {
    const params = window.location.search;
    try {
      if (!this.isAuthorized) {
        await venueAuthorize(this.venueId, params);
        this.AUTHORIZE();
      }
      if (this.isXeroConnected) {
        await this.$router.push({
          name: 'venue-settings-integrations',
          params: {
            id: this.venueId,
          },
        });
      }
    } catch (e) {
      await this.$router.push({
        name: 'venue-settings-integrations',
        params: {
          flash: {
            title: 'There was an error.',
            message: 'An error occurred when authorizing Xero connection. Please try again later.',
            type: 'fail',
          },
        },
      });
    }

    const { data: accounts } = await venueGetXeroAccounts(this.venueId);
    this.accounts = accounts.data;
    const { data: taxes } = await venueGetXeroTaxesSetup(this.venueId);
    this.taxes = taxes.data;

    if (this.isBothSelected) this.enableNextStep();
  },
};
</script>
<template>
  <div>
    <xero-account-settings-form
      @changeAccount="changeSelectedAccount"
      @changeTax="changeTax"
      @changeDeliveryFee="changeDeliveryFee"
      @changeExportMode="changeExportMode"
      :orderExportModes="orderExportModes"
      :accounts="accounts"
      :selected-account="draft.account && draft.account.code"
      :selected-tax="draft.tax && draft.tax.taxType"
      :selected-export-mode-id="draft.orderingMode && draft.orderingMode.id"
      :taxes="taxes"
      :selected-delivery-fee-account="
        draft.deliveryFeeAccountCode && draft.deliveryFeeAccountCode.code
      "
    />
  </div>
</template>
<style lang="scss" scoped>
.account-dropdown {
  margin-bottom: $spacing-16;
}

:deep() .ez-dropdown {
  font-size: 16px;
  font-weight: 400;
}
</style>
