<script>
import EzForm from '@/components/ui/Form/EzForm';
import EzInput from '@/components/ui/Input/EzInput';
import EzButton from '@/components/ui/Button/EzButton';
import { mapActions, mapGetters, mapState } from 'vuex';
import flash from '@/components/ui/FlashMessage';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'AccountInfo',
  components: {
    EzForm,
    EzInput,
    EzButton,
  },
  data() {
    return {
      formKey: 'venue-hub-doc-settings',
      loadingKeyForm: 'venue-hub-doc-settings-loading',
      formValid: false,
      requiredValues: {
        hubdocEmail: null,
      },
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    ...mapGetters('loading', ['isSomeLoading']),
    venueId() {
      return this.$route.params.id;
    },
    venue() {
      return this.loggedUser.venues.find(ven => ven.id === +this.venueId);
    },
  },
  methods: {
    ...mapActions('entities/users', [
      'initVenue',
    ]),
    async onSubmitSuccess() {
      this.initVenue();
      flash.success({
        title: `${this.$t('hubDoc.name')} settings updated!`,
        message: `You have successfully updated ${this.$t('hubDoc.name')} settings.`,
      });
    },
    async updateFormState(name, value) {
      this.requiredValues[name] = await this.$validator.validate(name, value);
      this.validateForm();
    },
    validateForm() {
      this.formValid = true;
      Object.keys(this.requiredValues).forEach((key) => {
        if (!this.requiredValues[key]) this.formValid = false;
      });
    },
  },
  created() {
    this.updateFormState('hubdocEmail', this.venue?.hubdocEmail);
  },
};
</script>

<template>
  <div class="container--small">
    <ez-form
      :formKey="formKey"
      :loading-key="loadingKeyForm"
      :action="`/venues/${venueId}`"
      method="patch"
      @success="onSubmitSuccess"
      ref="form"
    >
      <ez-input
        :formKey="formKey"
        name="hubdocEmail"
        label="Email Address"
        placeholder="Enter Email"
        :value="venue.hubdocEmail"
        class="mb-8"
        validators="email"
        @onChange="updateFormState('hubdocEmail', $event)"
      />
      <template #buttons>
        <ez-button class="mt-24" form-type="submit" :disabled="!formValid">
          Save Changes
        </ez-button>
      </template>
    </ez-form>
  </div>
</template>

<style lang="scss" scoped>

</style>
