<script>
/**
 * VWarehouseLocation
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'VWarehouseLocation',
  props: {
    data: {
      type: Array,
      required: true,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    label() {
      if (!this.data.length) return '-';
      if (this.data.length === 1) return this.data[0];
      return 'Multiple';
    },
    isMultiple() {
      return this.data.length > 1;
    },
    tooltip() {
      return {
        content: this.data.join('\n'),
        classes: ['tooltip--reset-margin', 'v-warehouse-location__tooltip'],
      };
    },
  },
};
</script>

<template>
  <span
    v-if="isMultiple"
    :class="[{ 'dashed-underline': isMultiple }]"
    v-tooltip="tooltip"
    :data-cy="dataCy"
  >
    {{ label }}
  </span>
  <span v-else :title="label" :data-cy="dataCy">{{ label }}</span>
</template>

<style lang="scss">
.v-warehouse-location {
  &__tooltip {
    top: -10px !important;
    white-space: pre-line;
  }
}
</style>
