<script>
/**
 *
 * @version 1.0.0
 * @since
 */

import SupplierInfo from '@/views/platform/venue/suppliers/all/SupplierInfo';
import VDistributorForm from '@/components/v3/patterns/VDistributorForm/index';
import { mapState, mapMutations, mapGetters } from 'vuex';
import EzButton from '@/components/ui/Button/EzButton';
import flash from '@/components/ui/FlashMessage';
import { LOADING_KEY } from '@/util/constants';
import EzLoader from '@/components/ui/Loader/EzLoader';

export default {
  components: {
    EzLoader,
    SupplierInfo,
    VDistributorForm,
    EzButton,
  },
  props: {
    supplierId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formValid: false,
      formKey: 'edit-distributor',
      loadingKey: LOADING_KEY.VENUE_EDIT_SUPPLIER,
      cutOffTimeValidation: false,
    };
  },
  computed: {
    ...mapState('entities/users', ['contextId']),
    ...mapState('entities/distributors', ['selectedSupplier']),
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.VENUE_FETCH_SUPPLIER_SINGLE,
        LOADING_KEY.FETCH_TIME_ZONES,
      ]);
    },
    distributor() {
      return this.selectedSupplier;
    },
    distributorId() {
      return this.distributor.id;
    },
    action() {
      return `venue/distributors/${this.distributorId}`;
    },
  },
  methods: {
    ...mapMutations('entities/distributors', ['UPDATE_SELECTED_SUPPLIER']),
    onSubmitSuccess({ data }) {
      this.UPDATE_SELECTED_SUPPLIER(data.data);
      flash.success({
        title: this.$t('distributors.successFlashTitleEditSupplier'),
        message: this.$t('distributors.successFlashMessageEditSupplier', {
          supplierName: this.distributor.name,
        }),
      });
    },
    submitForm() {
      this.$refs.form.submitForm();
    },
  },
};
</script>
<template>
  <div>
    <supplier-info :distributor="distributor" v-if="!distributor.editable" />
    <div class="editable-distributor" v-else>
      <v-distributor-form
        ref="form"
        :formKey="formKey"
        :loadingKey="loadingKey"
        :supplier-id="supplierId"
        :action="action"
        :avatarColumnMode="false"
        submitType="multipart"
        :method="'patch'"
        @submitSuccess="onSubmitSuccess"
        @formValid="val => (formValid = val)"
        @cutOffTimeValid="ev => (cutOffTimeValidation = ev)"
        class="distributor-info"
        hide-notification-settings
      />
      <ez-button :disabled="!formValid || !cutOffTimeValidation" @click="submitForm" class="mt-16">
        <template>Save Changes</template>
      </ez-button>
    </div>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </div>
</template>
<style lang="scss" scoped></style>
