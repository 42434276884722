<template>
  <ez-form
    ref="form"
    :form-key="formKey"
    :loading-key="loadingKey"
    :action="action"
    :method="method"
    submitType="multipart"
    :additional-data="{
      ...(imgModified && { logo: '' }),
      ...(freeTrialExpiresAt ? { freeTrialExpiresAt } : {}),
      ...(!inventoryEnabled ? { inventoryEnabled: inventoryEnabled?.toString() } : {}),
    }"
    @success="onSubmitSuccess"
    @error="onSubmitError"
  >
    <ez-image-upload
      :formKey="formKey"
      :isRound="true"
      :previewUrl="groupCopy.logo || defaultImage"
      :isDefaultImage="groupCopy.isDefaultImage"
      :columnMode="columnMode"
      name="logo"
      @removeImage="onRemoveAvatar(groupCopy)"
      @change="onImageChanged"
    >
      <template #addImage>
        <span v-if="isExistingGroup">Change Group Logo</span>
        <span v-else>Add Group Logo</span>
      </template>
      <template #removeImage>
        <font-awesome-icon icon="times" />
        <span>Remove Logo</span>
      </template>
    </ez-image-upload>
    <ez-input
      :form-key="formKey"
      :value="group.name"
      name="name"
      :placeholder="`Enter ${$t('global.venue')} Group Name`"
      :label="`${$t('global.venue')} Group Name`"
    ></ez-input>
    <ez-drop-down
      v-if="isAdmin"
      :data="venueAccountType"
      :form-key="formKey"
      is-full-width
      name="accountType"
      :label="$t('global.accountType')"
      :selected="group.accountType"
      class="mt-12"
      @change="updateAccountType"
    />
    <v-date-picker
      class="mt-12"
      v-if="isAdmin && isFreeTrial"
      name="freeTrialExpiresAt"
      label="Free Trial Expires At"
      v-model="freeTrialExpiresAt"
      :formKey="formKey"
      isFullWidth
      @dateChange="updateDate"
    />
    <ez-drop-down
      v-if="isAdmin"
      :data="venuePriority"
      :form-key="formKey"
      is-full-width
      name="priority"
      label="Priority"
      :selected="group.priority"
      class="mt-12"
    />
    <div class="label mt-12" v-if="isAdmin">
      Inventory Enabled
      <ez-on-off
        :disabled="isFree"
        class="ml-8"
        :form-key="formKey"
        :value="inventoryEnabled?.toString()"
        :checked="inventoryEnabled"
        @change="onInventoryEnabledChange"
        name="inventoryEnabled"
      />
    </div>

    <div v-if="isAdmin" class="form-help">
      <h4>Invoices</h4>
    </div>

    <ez-drop-down
      v-if="isAdmin"
      :data="venueSubscription"
      :form-key="formKey"
      is-full-width
      name="pendingInvoicesSubscription"
      label="Subscription"
      :selected="group.pendingInvoicesSubscription"
      @change="onSubscriptionyDropdownChange"
      class="mt-12"
    />

    <ez-input
      v-if="isAdmin && !isUnlimited && !isNone"
      :formKey="formKey"
      :value="group.pendingInvoicesLimit"
      name="pendingInvoicesLimit"
      label="Limit"
      placeholder="Limit"
      class="mt-12"
      type="number"
    />

    <ez-input
      v-if="isAdmin && !isUnlimited && !isNone"
      :formKey="formKey"
      :value="group.pendingInvoicesRemaining"
      name="pendingInvoicesRemaining"
      label="Remaining"
      placeholder="Remaining"
      class="mt-12"
      type="number"
    />
    <hr class="address-separator" />

    <div class="form-help">
      <h4>Currency</h4>
      <p>
        Select the default currency for this {{ $t('global.venue') | lowercase }} group. Only
        {{ $t('global.distributors') | lowercase }} with the same default currency will be available
        to connect to.
      </p>
    </div>

    <v-select-search
      class="width-100"
      name="currencyId"
      label="Currency*"
      :key="group.currency && group.currency.id"
      :disable="isCurrencySelectDisabled"
      :data="currencyOptions"
      value-property="id"
      :placeholder="$t('global.currency')"
      :searchPlaceholder="`Select ${$t('global.currency')}`"
      :selected="group.currency"
      :has-clear="false"
      align-left
      is-full-width
      @selected="currencySelected"
    >
      <template #result="{ result }">
        <span>
          {{ `${result.name} - ${result.symbol}` }}
        </span>
      </template>
    </v-select-search>

    <div class="form-help">
      <h4>Date Format</h4>
    </div>

    <ez-select
      v-if="supportedDateFormats.length"
      is-full-width
      name="dateFormat"
      label="Date Format"
      placeholder="Select Date Format"
      class="width-100 mt-12"
      :options="supportedDateFormats"
      :value="selectedDateFormat"
    />
    <slot name="buttons"></slot>
  </ez-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import EzForm from '@/components/ui/Form';
import EzImageUpload from '@/components/ui/ImageUpload';
import EzInput from '@/components/ui/Input';
import EzDropDown from '@/components/ui/Dropdown';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import VDatePicker from '@/components/v3/patterns/VDatePicker/index';
import {
  venueAccountType,
  venuePriority,
  isAdmin,
  LOADING_KEY,
  venueSubscription,
} from '@/util/constants';
import dayjs from 'dayjs';
import EzOnOff from '@/components/ui/OnOff/EzOnOff.vue';
import EzSelect from '@/components/ui/Select/EzSelect.vue';

export default {
  components: {
    EzForm,
    EzImageUpload,
    EzInput,
    EzDropDown,
    VSelectSearch,
    VDatePicker,
    EzOnOff,
    EzSelect,
  },
  props: {
    formAction: {
      type: String,
      required: true,
    },
    columnMode: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      venueAccountType,
      venuePriority,
      formKey: 'venue-group-form',
      groupCopy: {},
      imgModified: false,
      currencyOptions: [],
      loadingKey: LOADING_KEY.VENUE_GROUP_EDIT,
      isFreeTrial: false,
      isFree: false,
      freeTrialExpiresAt: dayjs(new Date()).valueOf(),
      inventoryEnabled: false,
      venueSubscription,
      isUnlimited: false,
      isNone: false,
    };
  },
  computed: {
    ...mapGetters('defaultImages', ['getDefaultImage']),
    isAdmin() {
      return isAdmin();
    },
    isExistingGroup() {
      return this.group && this.group.id;
    },
    defaultImage() {
      return this.getDefaultImage('venue');
    },
    action() {
      if (!this.isExistingGroup || !this.isAdmin) {
        return this.formAction;
      }

      return `${this.formAction}/${this.group.id}`;
    },
    method() {
      return this.isExistingGroup ? 'patch' : 'post';
    },
    isCurrencySelectDisabled() {
      const { currencyChangeEnabled } = this.group;
      return typeof currencyChangeEnabled === 'boolean' ? !currencyChangeEnabled : false;
    },
    supportedDateFormats() {
      if (!this.group?.supportedDateFormats) return [];
      return this.group.supportedDateFormats.map(f => ({ id: f, name: f }));
    },
    selectedDateFormat() {
      const dateFormat = this.group?.dateFormat || '';
      return { id: dateFormat, name: dateFormat };
    },
  },
  watch: {
    group: {
      deep: true,
      immediate: true,
      handler(val) {
        this.groupCopy = JSON.parse(JSON.stringify(val));
        this.isFreeTrial = this.group.accountType === 'free_trial';
        this.isFree = this.group.accountType === 'free';
        if (this.isFreeTrial) this.freeTrialExpiresAt = this.group.freeTrialExpiresAt;
        this.inventoryEnabled = this.group.inventoryEnabled;
        this.isUnlimited = this.group.pendingInvoicesSubscription === 'unilimited';
        this.isNone = this.group.pendingInvoicesSubscription === 'none';
      },
    },
  },
  methods: {
    submit() {
      this.$refs.form.onSubmit();
    },
    updateDate(date) {
      if (!date) {
        this.freeTrialExpiresAt = null;
        return;
      }
      this.freeTrialExpiresAt = dayjs(date).valueOf();
    },
    onInventoryEnabledChange(ev) {
      this.inventoryEnabled = ev;
    },
    onSubmitSuccess(result) {
      this.groupCopy.isDefaultImage = true;
      this.CLEAR_DRAFT();
      this.$emit('submitSuccess', result);
    },
    onSubmitError(result) {
      this.$emit('submitError', result);
    },
    onRemoveAvatar(groupCopy) {
      groupCopy.logo = null;
      groupCopy.isDefaultImage = true;
      this.imgModified = true;

      this.$emit('removeImage');
    },
    onImageChanged(img) {
      this.UPDATE_DRAFT({ logo: img, isDefaultImage: !img });
      this.imgModified = true;
    },
    currencySelected() {
      if (!this.isCurrencySelectDisabled) this.$emit('currencySelected');
    },
    updateAccountType(val) {
      if (val.id === 'free_trial') this.isFreeTrial = true;
      else this.isFreeTrial = false;
      if (val.id === 'free') {
        this.inventoryEnabled = false;
        this.isFree = true;
      } else {
        this.isFree = false;
      }
    },
    onSubscriptionyDropdownChange(val) {
      if (val.id === 'unlimited') this.isUnlimited = true;
      else if (val.id === 'none') this.isNone = true;
      else {
        this.isUnlimited = false;
        this.isNone = false;
      }
    },
    ...mapActions('entities/groups', ['removeImage']),
    ...mapMutations('entities/groups', ['UPDATE_DRAFT', 'CLEAR_DRAFT']),
    ...mapActions('entities/users', ['adminFetchCurrencies', 'fetchCurrencies']),
  },
  async created() {
    const {
      data: { data },
    } = isAdmin() ? await this.adminFetchCurrencies() : await this.fetchCurrencies();

    this.currencyOptions = data;
    this.isFreeTrial = this.group.accountType === 'free_trial';
    this.isFree = this.group.accountType === 'free' || this.group.accountType === undefined;
    if (this.isFreeTrial) this.freeTrialExpiresAt = this.group.freeTrialExpiresAt;
    this.inventoryEnabled = this.group.inventoryEnabled;
  },
};
</script>

<style scoped lang="scss">
.ez-image-upload {
  margin-bottom: 1rem;
}

:deep() .select-search {
  .select-search__list {
    max-height: 280px;
  }

  .select-search__item {
    height: 42px;
    display: flex;
    align-items: center;
  }
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
}
</style>
