<script>
import EzButton from '@/components/ui/Button';
import EzForm from '@/components/ui/Form';
import { EzFormModal } from '@/components/ui/Modal';
import EzInput from '@/components/ui/Input';
import { LOADING_KEY, userModalTab1, userModalTab2 } from '@/util/constants';
import { mapGetters, mapMutations } from 'vuex';
import uuid from 'uuid/v4';
import PrimaryContactToggle from '@/views/common/users/PrimaryContactToggle';
import { falsy } from '@/util/utils';
import { EzTab, EzTabs } from '@/components/ui/Tabs';
import OrderNotificationToggle from '@/views/common/users/OrderNotificationToggle';

export default {
  components: {
    EzButton,
    EzForm,
    EzFormModal,
    EzInput,
    PrimaryContactToggle,
    EzTabs,
    EzTab,
    OrderNotificationToggle,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    formAction: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: false,
      default: () => {},
    },
    additionalData: {
      type: Object,
      required: false,
      default: null,
    },
    entity: {
      type: String,
      required: false,
      default: 'User',
    },
    hasAtLeastOneDefaultPrimary: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      keyId: null,
      loadingKey: LOADING_KEY.VENUE_SUPPLIER_ADD_USER,
      phoneValidatorVal: '',
      isPrimaryToggled: false,
      formValid: false,
      requiredValues: {
        name: null,
        email: null,
      },
      values: {
        name: null,
        email: null,
        phone: null,
      },
      userModalTab1,
      userModalTab2,
      settings: {
        email: false,
        whatsApp: false,
        sms: false,
      },
    };
  },
  computed: {
    ...mapGetters('errors', ['getFormErrors']),
    getErrors() {
      return this.getFormErrors(this.formKey) || {};
    },
    hasErrors() {
      return !falsy(this.getErrors);
    },
    isChecked() {
      return this.user?.primary;
    },
    defaultData() {
      return { ...{ primary: this.isPrimaryToggled }, ...this.additionalData };
    },
    isExistingUser() {
      return this.user && !!this.user.id;
    },
    method() {
      return this.isExistingUser ? 'patch' : 'post';
    },
    action() {
      if (!this.isExistingUser) {
        return this.formAction;
      }

      return `${this.formAction}/${this.user.id}`;
    },
    ...mapGetters('loading', ['getLoading']),
    isLoadingUser() {
      return this.getLoading(LOADING_KEY.VENUE_SUPPLIER_ADD_USER);
    },
  },
  watch: {
    async user(val) {
      this.user = val;
      this.keyId = uuid();
      this.settings = {
        ...this.user?.notificationSettings,
      };
      if (!falsy(this.user)) {
        this.isPrimaryToggled = this.isChecked;
        await this.initialFormState();
        await this.validateForm();
      } else {
        this.requiredValues = {
          name: null,
          email: null,
        };
      }
    },
    isPrimaryToggled(val) {
      if (val) {
        this.phoneValidatorVal = 'required';
        this.requiredValues = {
          ...this.requiredValues,
          ...(this.isPrimaryToggled && {
            phone: (this.isExistingUser && !!this.user?.phone) || !!this.values.phone,
          }),
        };
      } else {
        this.phoneValidatorVal = '';
        this.requiredValues = {
          ...this.requiredValues,
          phone: true,
        };
      }
      this.validateForm();
    },
    hasErrors(val) {
      if (val) this.changeTab(userModalTab1);
    },
  },
  methods: {
    ...mapMutations('errors', ['CLEAR_ERRORS']),
    open() {
      this.$refs.userModal.open();
    },
    close() {
      this.resetValues();
      this.$refs.userModal.close();
    },
    resetValues() {
      this.values = {
        name: null,
        email: null,
        phone: null,
      };
    },
    submitForm() {
      this.$refs.form.onSubmit();
    },
    onSubmitSuccess(result) {
      this.$emit('success', { ...result, isUpdate: this.isExistingUser });
      if (!this.isExistingUser) this.$refs.form.reset();
    },
    onSubmitError(result) {
      this.$emit('error', result);
    },
    onClose() {
      this.CLEAR_ERRORS({ key: this.formKey });
      this.$refs.notifySettings.reset();
      this.$emit('close');
    },
    primaryToggled(val) {
      this.isPrimaryToggled = val;
      this.validateForm();
    },
    async updateFormState(name, value) {
      this.values[name] = value;
      this.requiredValues[name] = await this.$validator.validate(name, value);
      this.validateForm();
    },
    async initialFormState() {
      await this.$nextTick();

      this.requiredValues = {
        name: this.user.name,
        email: this.user.email,
        ...(this.isPrimaryToggled && { phone: this.user.phone }),
      };
      this.values = {
        name: this.user.name,
        email: this.user.email,
        phone: this.user.phone,
      };
    },
    validateForm() {
      this.formValid = true;
      Object.keys(this.requiredValues).forEach(key => {
        if (!this.requiredValues[key]) this.formValid = false;
      });
    },
    setNotifySettings(settings) {
      this.settings = {
        ...settings,
      };
    },
    transformer(data) {
      const tst = {
        ...data,
        notificationSettings: { ...this.settings },
      };
      return tst;
    },
    changeTab(tab) {
      this.$refs.tabs.selectTab(tab);
    },
  },
};
</script>

<template>
  <ez-form-modal ref="userModal" class="ez-user-modal" @close="onClose">
    <template #title>
      <slot name="title">{{ isExistingUser ? `${entity} Details` : `Add New ${entity}` }}</slot>
    </template>
    <template #content>
      <ez-form
        :key="keyId"
        ref="form"
        :formKey="formKey"
        :additional-headers="{ loadingKey }"
        :action="action"
        :method="method"
        :default-data="defaultData"
        :transformer="transformer"
        @success="onSubmitSuccess"
        @error="onSubmitError"
      >
        <ez-tabs ref="tabs">
          <ez-tab :title="`${entity} Info`" :hash="userModalTab1">
            <ez-input
              class="mt-16"
              :formKey="formKey"
              label="Name*"
              name="name"
              validators="required"
              placeholder="Enter Full Name"
              @onChange="updateFormState('name', $event)"
              :value="user.name"
            />
            <ez-input
              :formKey="formKey"
              label="Email Address*"
              placeholder="Enter Email Address"
              validators="email|required"
              name="email"
              @onChange="updateFormState('email', $event)"
              :value="user.email"
            />
            <ez-input
              class="mt-12"
              :formKey="formKey"
              :value="user.phone"
              :validators="`${phoneValidatorVal}`"
              placeholder="Enter Phone Number"
              @onChange="updateFormState('phone', $event)"
              name="phone"
              label="Phone Number"
            />
            <input type="hidden" name="shouldMirror" value="false" />
            <hr />
            <primary-contact-toggle
              @change="primaryToggled"
              :key="user.id"
              :form-key="formKey"
              :disabled="isChecked && !hasAtLeastOneDefaultPrimary"
              :checked="isChecked"
            />
          </ez-tab>
          <ez-tab title="Order Notifications" :hash="userModalTab2">
            <order-notification-toggle
              :user="user"
              ref="notifySettings"
              :form-key="formKey"
              @change="setNotifySettings"
            />
          </ez-tab>
        </ez-tabs>
        <slot></slot>
      </ez-form>
    </template>
    <template #footer>
      <ez-button type="link" formType="button" @click="close">Cancel</ez-button>
      <ez-button
        v-tooltip.bottom="{
          content: !isEditable ? 'Please edit this user in the Group View' : '',
          classes: ['tooltip', 'tooltip--reset-margin'],
        }"
        :disabled="!formValid || !isEditable"
        formType="button"
        @click="submitForm"
        :is-loading="isLoadingUser"
      >
        {{ isExistingUser ? 'Update Details' : `Add ${entity}` }}
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<style scoped lang="scss">
.ez-user-modal {
  :deep() .ez-dropdown {
    max-width: 100%;
  }
  :deep() .modal {
    overflow: visible;
  }
  .ez-form {
    .input-group + .input-group {
      margin-top: 0.75rem;
    }
  }
}
:deep() .ez-tabs {
  &__buttons {
    & > li:first-child {
      button {
        margin-left: 0;
      }
    }
  }
  &__content {
    overflow: inherit;
  }
}
</style>
