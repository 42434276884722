<script>
import { wizardListenerMixin } from '@/mixins/wizard';
import { mapState, mapMutations } from 'vuex';
import dayjs from 'dayjs';
import EzAutocomplete from '@/components/ui/Autocomplete';
import EzEntityInfo from '@/components/ui/EntityInfo';
import { EzSelectedDistributor } from '@/components/ui/SelectedItem';
import EzPremiumBadge from '@/components/ui/PremiumBadge';
import VDatePicker from '@/components/v3/patterns/VDatePicker';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  mixins: [wizardListenerMixin],
  components: {
    EzAutocomplete,
    EzEntityInfo,
    EzSelectedDistributor,
    EzPremiumBadge,
    VDatePicker,
  },
  data() {
    return {
      result: {},
      today: dayjs(new Date()),
      date: null,
      tomorrow: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      todayDate: dayjs().format('YYYY-MM-DD'),
      outletCy,
    };
  },
  computed: {
    ...mapState('entities/users', ['context', 'contextId']),
    ...mapState('entities/venues', ['offlineOrder']),
    isDateValid() {
      return this.isDateTodayOrBefore(this.date);
    },
    hasSelectedDistributor() {
      return Object.keys(this.result).length > 0;
    },
    hasFirstStep() {
      return Object.keys(this.result).length && this.isDateValid;
    },
    hasFirstStepPrefilled() {
      return Object.keys(this.offlineOrder.distributor).length && this.offlineOrder.date;
    },
  },
  methods: {
    ...mapMutations('entities/venues', [
      'SET_OFFLINE_ORDER_DISTRIBUTOR',
      'SET_OFFLINE_ORDER_DATE',
      'CLEAR_OFFLINE_PRODUCTS',
      'CLEAR_OFFLINE_SELECTED_PRODUCTS',
      'CLEAR_OFFLINE_ORDER_RECEIPTS',
    ]),
    onNextStep() {
      this.SET_OFFLINE_ORDER_DISTRIBUTOR(this.result);
      this.SET_OFFLINE_ORDER_DATE(dayjs(this.date).valueOf());
      this.$emit('stepCompleted');
    },
    onDistributorSelect(result) {
      this.result = result.selected;
      this.CLEAR_OFFLINE_PRODUCTS();
      this.CLEAR_OFFLINE_SELECTED_PRODUCTS();
      this.CLEAR_OFFLINE_ORDER_RECEIPTS();
    },
    removeSelectedDistributor() {
      this.result = {};
    },
    updateDate(date) {
      this.date = date;
    },
    isDateTodayOrBefore(date) {
      const today = dayjs().format('YYYY-MM-DD');
      const selectedVal = dayjs(date).format('YYYY-MM-DD');
      return dayjs(selectedVal).isBefore(today, 'day') || dayjs(selectedVal).isSame(today, 'day');
    },
  },
  mounted() {
    if (this.hasFirstStepPrefilled) {
      this.date = dayjs(new Date(this.offlineOrder.date)).format('YYYY-MM-DD');
      this.result = this.offlineOrder.distributor;
    }
  },
  watch: {
    hasFirstStep(val) {
      if (val) {
        this.enableNextStep();
      } else {
        this.disableNextStep();
      }
    },
  },
};
</script>
<template>
  <div class="offline-order-supplier">
    <ez-autocomplete
      v-if="!hasSelectedDistributor"
      :source="`/venue/search/distributors`"
      :request-params="{ connected: true }"
      search-property="name"
      @selected="onDistributorSelect"
      placeholder="Enter Supplier Name"
      class="distributor-select"
    >
      <template #result="{ result }">
        <ez-entity-info :imgUrl="result.logo" :imgHasBorder="true" imgBorderRadius="50%">
          <span :title="result.name">{{ result.name }}</span>
          <ez-premium-badge v-if="$helpers.isPremium(result.accountType)" />
        </ez-entity-info>
      </template>
    </ez-autocomplete>
    <ez-selected-distributor v-else @remove="removeSelectedDistributor" :distributor="result" />
    <hr />
    <v-date-picker
      :data-cy="outletCy.ORDERS.EXISTING_OFFLINE_ORDER.BUTTON__STANDING_DATE"
      class="date-picker mt-8"
      name="standingDate"
      label="Order Date"
      v-model="date"
      formKey="date"
      @dateChange="updateDate"
      :max="todayDate"
    />
  </div>
</template>
<style lang="scss" scoped>
.offline-order-supplier {
  font-size: 14px;
}
.date-picker {
  :deep() .ez-simple-dropdown__display-container {
    width: 100%;
    .placeholder {
      width: 100%;
    }
  }
}
</style>
