<template>
  <div class="section-wrapper">
    <ez-form
      ref="form"
      :action="action"
      :form-key="formKey"
      :transformer="transformer"
      method="patch"
      @success="onSubmitSuccess"
      @error="onSubmitError"
    >
      <h4 class="section-title mt-0 mb-4">Stocktake Time</h4>
      <div class="section-description mt-0 mb-16">
        Select the assigned time of day for stocktake
      </div>
      <ez-radio-button
        class="radio-btn"
        formKey="venue-stocktake"
        name="stocktakeTime"
        label="End of Day"
        :data="stocktakeTime"
        value="end_of_day"
        @change="updateStocktakeTime('end_of_day')"
      />
      <div class="radio-info">After day's invoices and orders</div>
      <ez-radio-button
        class="radio-btn mt-16"
        formKey="venue-stocktake"
        name="stocktakeTime"
        label="Beginning of Day"
        :data="stocktakeTime"
        value="beginning_of_day"
        @change="updateStocktakeTime('beginning_of_day')"
      />
      <div class="radio-info">Before day's invoices and orders</div>
      <hr />
      <h4 class="section-title mt-0 mb-4">Locations</h4>
      <p class="section-description mt-0 mb-24">Edit or add stocktake locations.</p>
      <ez-table
        v-if="locations.length"
        :data="locations"
        :columns="['name']"
        :headers="{ name: () => 'Location Name' }"
        @rowHover="rowHover"
      >
        <template #cell-name="{ row }">
          <div class="actions-wrapper">
            <span>{{ row.name }}</span>
            <div>
              <button
                v-if="isRowHovered(row)"
                @click.stop="editLocation(row)"
                v-tooltip="{
                  content: 'Edit location',
                  classes: ['tooltip-general'],
                }"
              >
                <font-awesome-icon icon="pen" />
              </button>
              <button
                v-tooltip="{
                  content: 'Delete location',
                  classes: ['tooltip-general'],
                }"
                v-if="isRowHovered(row)"
                @click.stop="deleteLocation(row)"
                class="ml-12"
              >
                <font-awesome-icon icon="trash" />
              </button>
            </div>
          </div>
        </template>
      </ez-table>
      <ez-button type="secondary" class="mt-16" @click="openLocationModal" isFullWidth>
        <font-awesome-icon icon="plus" />
        Add Location
      </ez-button>

      <ez-form-modal ref="modal" @close="onClose">
        <template #title>{{ isEdit ? 'Edit ' : 'Add ' }}Location</template>
        <template #content>
          <form class="input" @submit.prevent>
            <ez-input
              name="locationName"
              label="Name"
              :value="locationName"
              @onChange="locationName = $event"
              placeholder="Enter Location Name"
              formKey="create-update-location"
            />
          </form>
        </template>
        <template #footer>
          <ez-button type="link" formType="button" @click="onClose">Cancel</ez-button>
          <ez-button @click="addOrEditLocation" :disabled="!locationName" type="primary">{{
            isEdit ? 'Save Changes' : 'Add Location'
          }}</ez-button>
        </template>
      </ez-form-modal>

      <ez-confirmation-modal ref="deleteModal" type="red">
        <template #title>Remove Location?</template>
        <template #content>
          <p>This location will be deleted from the list.</p>
        </template>
        <template #footer>
          <ez-button @click="closeDeleteModal" type="link">Cancel</ez-button>
          <ez-button @click="confirmDeleteLocation" type="red">Remove Location</ez-button>
        </template>
      </ez-confirmation-modal>
      <template #buttons>
        <ez-button form-type="submit" class="mt-24">Save Changes</ez-button>
      </template>
    </ez-form>
  </div>
</template>

<script>
import { mapActions } from 'vuex/dist/vuex.common';
import EzTable from '@/components/ui/Table/EzTable.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';
import { EzConfirmationModal, EzFormModal } from '@/components/ui/Modal';
import EzInput from '@/components/ui/Input/EzInput.vue';
import flash from '@/components/ui/FlashMessage';
import EzRadioButton from '@/components/ui/RadioButton/EzRadioButton.vue';
import EzForm from '@/components/ui/Form/EzForm.vue';

export default {
  components: {
    EzTable,
    EzButton,
    EzFormModal,
    EzConfirmationModal,
    EzInput,
    EzRadioButton,
    EzForm,
  },
  data() {
    return {
      locations: [],
      isEdit: false,
      locationName: '',
      locationId: null,
      hoveredRow: null,
      stocktakeTime: 'end_of_day',
      formKey: 'venue-settings',
    };
  },
  props: {
    venueId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    action() {
      return `/venues/${this.venueId}`;
    },
  },
  methods: {
    ...mapActions('entities/venues', [
      'fetchVenue',
      'fetchVenueLocations',
      'addVenueLocation',
      'updateVenueLocation',
      'deleteVenueLocation',
    ]),
    updateStocktakeTime(val) {
      this.stocktakeTime = val;
    },
    transformer(data) {
      return { ...data, stocktakeTime: this.stocktakeTime };
    },
    openLocationModal() {
      this.$refs.modal.open();
    },
    onClose() {
      this.isEdit = false;
      this.locationName = '';
      this.$refs.modal.close();
    },
    async addOrEditLocation() {
      try {
        if (!this.isEdit) {
          const { data } = await this.addVenueLocation({
            venueId: this.venueId,
            data: { name: this.locationName },
          });
          this.locations.push(data.data);
          flash.success({ title: 'Location successfully added!' });
        } else {
          await this.updateVenueLocation({
            venueId: this.venueId,
            locationId: this.locationId,
            data: { name: this.locationName },
          });
          this.locations = this.locations.map(item => {
            if (item.id !== this.locationId) return item;
            return {
              ...item,
              name: this.locationName,
            };
          });
          flash.success({ title: 'Location successfully updated!' });
        }
        this.onClose();
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
    rowHover(row) {
      this.hoveredRow = row;
    },
    isRowHovered(row) {
      if (this.hoveredRow) {
        return row.id === this.hoveredRow.id;
      }
      return false;
    },
    editLocation(row) {
      this.isEdit = true;
      this.locationName = row.name;
      this.locationId = row.id;
      this.$refs.modal.open();
    },
    deleteLocation(row) {
      this.locationId = row.id;
      this.$refs.deleteModal.open();
    },
    closeDeleteModal() {
      this.$refs.deleteModal.close();
      this.locationId = null;
    },
    async confirmDeleteLocation() {
      try {
        await this.deleteVenueLocation({
          venueId: this.venueId,
          locationId: this.locationId,
        });
        this.locations = this.locations.filter(item => item.id !== this.locationId);
        this.closeDeleteModal();
        flash.success({
          title: 'Location successfully deleted!',
        });
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
    onSubmitSuccess({ data: { data } }) {
      this.stocktakeTime = data.stocktakeTime;
      flash.success({
        title: this.$t('venues.groupSettings.venueInfo.flashMessages.success'),
      });
    },
    onSubmitError() {},
  },
  async created() {
    const { data } = await this.fetchVenueLocations({ venueId: this.venueId });
    this.locations = data.data;

    const { data: venueData } = await this.fetchVenue(this.venueId);
    this.stocktakeTime = venueData.data.stocktakeTime || '';
  },
};
</script>

<style lang="scss" scoped>
.section-wrapper {
  max-width: 400px;

  .section-title {
    line-height: 22px;
  }

  .section-description {
    line-height: 20px;
    color: $color-gray-6C;
  }
}

.actions-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-gray-25;

  button {
    cursor: pointer;
    color: $color-gray-6C;
  }
}

.radio-btn {
  :deep() .ez-radio-button__label {
    color: $color-gray-25;
    @include font-size(14px, 20px);
  }
}

.radio-info {
  margin-left: 20px;
  color: $color-gray-6C;
  @include font-size(12px, 18px);
}
</style>
