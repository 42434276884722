<script>
import { wizardListenerMixin } from '@/mixins/wizard';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import EzFilterList from '@/components/ui/FilterList/EzFilterList';
import { debounce } from '@/util/utils';
import EzBadge from '@/components/ui/Badge/EzBadge';

import ProductsSelectList from '@/views/common/products/ProductsSelectList';
import EzInput from '@/components/ui/Input/EzInput';
import VSubtotalInfo from '@/components/v3/patterns/VSubtotalInfo';
import { addTotalPriceCustom } from '@/util/utilsFinCalculator';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';

const PRODUCTS_STORE_NAME = 'entities/products';
const VENUES_STORE_NAME = 'entities/venues';
const ORDERS_STORE_NAME = 'entities/orders';

const ACTION_HEADER_INIT_TOP = 254;
const ACTION_HEADER_MIN_TOP = 64;

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  components: {
    VSubtotalInfo,
    EzInput,
    ProductsSelectList,
    EzBadge,
    EzFilterList,
  },
  mixins: [wizardListenerMixin],
  data() {
    return {
      filters: {
        term: null,
      },
      meta: {},
      outletCy,
    };
  },
  computed: {
    ...mapState(ORDERS_STORE_NAME, ['draft']),
    ...mapState(VENUES_STORE_NAME, ['offlineOrder']),
    ...mapGetters(VENUES_STORE_NAME, ['areAllSelected']),
    products() {
      return this.offlineOrder.products;
    },
    distributor() {
      return this.offlineOrder.distributor;
    },
    selectedProducts() {
      return this.offlineOrder?.selectedProducts || [];
    },
    // Props for VSubtotalInfo - Start
    itemList() {
      return this.selectedProducts
        .map(item => (!item.totalPrice
          ? addTotalPriceCustom({ item })
          : { ...item, totalPrice: item.totalPrice }));
    },
    tax() {
      return this.distributor?.tax || 0;
    },
    deliveryFee() {
      return this.offlineOrder.deliveryFee ?? 0;
    },
    hasSomeTbd() {
      return this.selectedProducts.some(pr => (pr.marketPrice || (pr.priceUnit && pr.price > 0)) && !pr.totalToChange);
    },
    // Props for VSubtotalInfo - End
    selectedProductsMsg() {
      return this.selectedProducts.length ? 'Products selected:' : 'No products selected';
    },
    distributorId() {
      return this.distributor.id;
    },
    hasSelectedProducts() {
      const invalidProduct = this.selectedProducts.find(
        prod => (!prod.marketPrice && prod.price < 0) || prod.quantity <= 0
          || (prod.totalToChange && prod.totalPrice < 0),
      );

      return this.selectedProducts.length && !invalidProduct;
    },
    hasSomeQuantityErr() {
      return this.selectedProducts.some(pr => pr.quantityInvalid);
    },
  },
  methods: {
    ...mapMutations(VENUES_STORE_NAME, [
      'SET_OFFLINE_PRODUCTS',
      'ADD_OFFLINE_SELECTED_PRODUCT',
      'REMOVE_OFFLINE_SELECTED_PRODUCT',
      'UPDATE_OFFLINE_PRODUCT',
    ]),
    ...mapActions(PRODUCTS_STORE_NAME, ['fetchVenueProducts']),
    ...mapActions(VENUES_STORE_NAME, {
      fetchProducts: 'fetchAllVenueProductsForDistributor',
    }),
    ...mapActions('cart', ['fetchProductPriceForQuantity']),
    setActionHeaderPosition() {
      const { actionHeader } = this.$refs;
      if (!actionHeader) {
        return;
      }
      let topPosition = ACTION_HEADER_INIT_TOP - window.scrollY;
      topPosition = topPosition < ACTION_HEADER_MIN_TOP ? ACTION_HEADER_MIN_TOP : topPosition;
      actionHeader.style.top = `${topPosition}px`;
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    searchForProduct: debounce(async function deb(term) {
      this.filters.term = term;

      const { distributorId } = this;
      const { data: { data, meta } } = await this.fetchProducts({ distributorId, term });

      this.meta = meta;
      this.SET_OFFLINE_PRODUCTS(data);
    }, 300),
    async resetFilters() {
      this.filters.term = null;

      const { distributorId } = this;
      const { data: { data, meta } } = await this.fetchProducts({ distributorId });

      this.meta = meta;
      this.SET_OFFLINE_PRODUCTS(data);
    },
    onNextStep() {
      this.$emit('stepCompleted');
    },
    onProductSelect(product) {
      const checked = !product.checked;
      if (checked) {
        this.ADD_OFFLINE_SELECTED_PRODUCT(product);
        const { minimumQuantity } = product;
        this.UPDATE_OFFLINE_PRODUCT({
          ...product,
          quantity: minimumQuantity,
          checked,
          quantityInvalid: false,
        });
      } else {
        this.REMOVE_OFFLINE_SELECTED_PRODUCT(product);
      }
    },
    onQuantityChange: debounce(async function deb(product, quantity) {
      const selectedProd = this.selectedProducts.find(p => p.id === product.id);

      const isChecked = !!quantity;

      if (!selectedProd && isChecked) {
        this.ADD_OFFLINE_SELECTED_PRODUCT({ ...product, quantity: product.minimumQuantity });
      }
      if (selectedProd && !isChecked) {
        this.REMOVE_OFFLINE_SELECTED_PRODUCT({ ...product, quantity });
      }

      if (!quantity) {
        return;
      }

      // When first time increment is clicked set to minimumQuantity
      const { minimumQuantity, quantity: selectedQuantity } = selectedProd || product;
      quantity = quantity + selectedQuantity < minimumQuantity ? minimumQuantity : quantity;

      if (product.priceModified) {
        this.UPDATE_OFFLINE_PRODUCT({ ...product, quantity, checked: isChecked });
        return;
      }
      const data = await this.fetchProductPriceForQuantity({
        id: product.id,
        distributorId: this.distributorId,
        quantity,
      });

      const { pricePerUnit: price, marketPrice } = data;
      product = { ...product, price, quantity, checked: isChecked, marketPrice };

      this.UPDATE_OFFLINE_PRODUCT(product);
    }, 300),
    onPriceChange(product, newPrice) {
      if (product.price === newPrice) {
        return;
      }
      this.UPDATE_OFFLINE_PRODUCT({ ...product, price: newPrice, priceModified: true });
    },
    setRegularPrice(product) {
      this.UPDATE_OFFLINE_PRODUCT({ ...product, marketPrice: false });
    },
    setMarketPrice(product) {
      this.UPDATE_OFFLINE_PRODUCT({ ...product, marketPrice: true });
    },
    setTotalAmount(product) {
      this.UPDATE_OFFLINE_PRODUCT({ ...product, totalToChange: true });
    },
    setTbd(product) {
      this.UPDATE_OFFLINE_PRODUCT({ ...product, totalToChange: false, totalPrice: 0 });
    },
    totalAmountChanged(product, val) {
      if (product.totalPrice === val) {
        return;
      }
      this.UPDATE_OFFLINE_PRODUCT({ ...product, totalPrice: val });
    },
    async loadMoreProducts() {
      const {
        distributorId,
        filters: { term },
        meta: { nextId, nextValue },
      } = this;

      const { data: { data, meta } } = await this.fetchProducts({
        distributorId,
        term,
        nextId,
        nextValue,
      });

      this.meta = meta;
      const products = [...this.offlineOrder.products, ...data];
      this.SET_OFFLINE_PRODUCTS(products);
    },
    priceError(row) {
      return (!row.price || row.price <= 0) && !!row.checked ? 'Price cannot be 0 or less.' : '';
    },
    onQuantityInvalid(value, id) {
      const product = this.selectedProducts.find(pr => pr.id === id);
      this.UPDATE_OFFLINE_PRODUCT({
        ...product,
        quantityInvalid: value,
      });
    },
  },
  watch: {
    hasSelectedProducts(val) {
      if (val) {
        this.enableNextStep();
      } else {
        this.disableNextStep();
      }
    },
    hasSomeQuantityErr(val) {
      if (val) {
        this.disableNextStep();
      } else {
        this.enableNextStep();
      }
    },
  },
  async created() {
    const { distributorId } = this;
    const { data: { data, meta } } = await this.fetchProducts({ distributorId });

    this.meta = meta;
    this.SET_OFFLINE_PRODUCTS(data.map(pr => ({ ...pr, quantity: pr.minimumQuantity })));

    window.addEventListener('scroll', this.setActionHeaderPosition);

    if (this.hasSelectedProducts) {
      this.enableNextStep();
    }
  },
  mounted() {
    this.setActionHeaderPosition();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.setActionHeaderPosition);
  },
};
</script>
<template>
  <div class="wizard-products">
    <div ref="actionHeader" class="wizard-products__actions-header">
      <div class="wizard-products__actions">
        <!-- So layout can be the same -->
        <span class="fake-element"></span>
        <span class="wizard-products__table-actions-msg">
          <span> {{ selectedProductsMsg }} </span>
          <ez-badge
            class="wizard-products__table-actions-badge"
            :count="selectedProducts.length"
            type="blue"
          />
        </span>
        <span class="wizard-products__table-actions-filters">
          <ez-filter-list :filters="filters" @resetFilter="resetFilters">
            <ez-input
              formKey="product-search"
              name="search"
              label="Search"
              placeholder="Search for a Product"
              @onChange="searchForProduct"
            >
              <template #suffix>
                <font-awesome-icon icon="search" />
              </template>
            </ez-input>
          </ez-filter-list>
        </span>
      </div>
    </div>
    <products-select-list
      :data-cy="outletCy.ORDERS.EXISTING_OFFLINE_ORDER.SELECT_PRODUCTS"
      :products="products"
      :next-id="meta.nextId"
      is-offline-order
      @productSelect="onProductSelect"
      @priceChange="onPriceChange"
      @quantityChange="onQuantityChange"
      @loadMore="loadMoreProducts"
      @setRegularPrice="setRegularPrice"
      @setMarketPrice="setMarketPrice"
      @onQuantityInvalid="onQuantityInvalid"
      @setTotalAmount="setTotalAmount"
      @setTbd="setTbd"
      @totalAmountChanged="totalAmountChanged"
    />
    <footer class="wizard-products__footer">
      <div class="total-container">
        <div class="total">
          <strong class="total__label">
            Total (incl. taxes)
          </strong>
          <v-subtotal-info
            :item-list="itemList"
            :delivery-fee="deliveryFee"
            :tax="tax"
            :is-tbd="hasSomeTbd"
          />
        </div>
      </div>
    </footer>
  </div>
</template>
<style lang="scss" scoped>
$border-color: #eceef5;
$action-min-width: 240px;

.fake-element {
  width: $action-min-width;
}

.wizard-products {
  padding-top: 152px;
}

.wizard-products__actions-header {
  position: fixed;
  left: 0;
  z-index: 1;

  @extend %flex-center;
  justify-content: center;

  width: 100%;
  height: 64px;

  border-bottom: 1px solid $border-color;
  background-color: white;
}

.wizard-products__actions {
  @extend %flex-space;
  width: 920px;
}

.wizard-products__table-actions-msg {
  @include font-size(14px, 22px);
  @extend %flex-center;
  justify-content: center;

  min-width: $action-min-width;
  color: $color-gray-6C;
  background-color: white;
}

.wizard-products__table-actions-filters {
  @extend %flex-center;
  justify-content: flex-end;

  min-width: $action-min-width;
  :deep() .ez-filter-list,
  :deep() .input-group {
    width: 100%;
  }
}

.wizard-products__checkbox-all {
  @include font-size(14px, 22px);

  min-width: $action-min-width;
  color: $color-gray-6C;
}

.wizard-products__table-actions-badge {
  margin: 0 0 0 4px;
}

.cell-name-container {
  @extend %flex-center;
  justify-content: flex-start;

  > * {
    margin-right: 12px;
  }
}

.changeable-price,
.changeable-price :deep() .mask-input__input {
  font-size: 16px;
  font-weight: bold;
}

.input-warning__icon {
  margin-left: 6px;
}

.wizard-products__footer {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 56px;

  background-color: white;
  border-top: 1px solid $border-color;
}

.total-container {
  width: 100%;
  @extend %flex-center;
  justify-content: center;
}
.total {
  @extend %flex-center;
  justify-content: flex-end;

  width: 920px;
  margin-top: 16px;

  &__label {
    margin-right: 12px;
    @include font-size(12px);
    color: $color-gray-6C;
    text-transform: uppercase;
  }

  &__amount {
    @include font-size(18px);
    font-weight: bold;
    color: $color-gray-25;
  }
}

.product-info {
  display: flex;
  flex-direction: column;
}

.product-info-secondary {
  display: block;
}

:deep() .table {
  .sku-cell,
  .category-cell {
    display: none;
  }
}

@media (max-width: 1023px) {
  .product-info-secondary {
    display: none;
  }
}
</style>
