<template>
  <div class="selected">
    <div class="selected__item" v-if="hasItem">
      <ez-entity-info
        :imgUrl="item.image"
        :imgHasBorder="true"
        imgBorderRadius="50%"
      >
        <div>
          <div>
            <span>{{ item.name }}</span>
            <ez-premium-badge v-if="$helpers.isPremium(item.accountType)"/>
          </div>
          <div>
            <small v-if="item.xeroId">Xero</small>
            <small v-else>{{ platformName }}</small>
          </div>
        </div>
      </ez-entity-info>
      <div class="selected-actions-bar">
        <button v-if="hasEditButton" class="selected__remove selected__remove--edit" @click="edit">
          <font-awesome-icon icon="pen"/>
        </button>
        <button class="selected__remove" @click="remove">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
            <!-- eslint-disable-next-line max-len -->
            <path d="M7.758 6.309L13.916.15c.2-.2.524-.2.725 0l.724.725c.2.2.2.524 0 .724L9.207 7.758l6.158 6.158c.2.2.2.524 0 .725l-.724.724c-.2.2-.525.2-.725 0L7.758 9.207l-6.159 6.158c-.2.2-.524.2-.724 0l-.725-.724c-.2-.2-.2-.525 0-.725L6.31 7.758.15 1.599c-.2-.2-.2-.524 0-.724L.875.15c.2-.2.524-.2.724 0L7.758 6.31z"/>
          </svg>
        </button>
      </div>
    </div>
    <div v-else>
      <slot name="emptyState"/>
    </div>
  </div>
</template>

<script>
/**
 * SelectedItem
 * @version 1.0.0
 * @since 2.3.0
 */

import EzEntityInfo from '@/components/ui/EntityInfo';
import EzPremiumBadge from '@/components/ui/PremiumBadge';

export default {
  components: {
    EzEntityInfo,
    EzPremiumBadge,
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: null,
    },
    hasEditButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    hasItem() { return !!this.item && Object.keys(this.item).length; },
    platformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    remove() { this.$emit('remove'); },
    edit() { this.$emit('edit'); },
  },
};
</script>

<style lang="scss" scoped>
.selected {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border: 1px solid #E9EBF2;
    border-radius: 3px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 1px 0 #DEE1E4;
  }
  .selected-actions-bar {
    display: flex;
    align-items: center;
  }

  &__remove {
    @extend %button-reset;
    @include size(12px);
    line-height: 0;
    cursor: pointer;
    svg {
      fill: #6C7995;
    }
    &--edit {
      margin-right: 12px;
      @include font-size(12px);
      width: 12px;
      height: 12px;
      color: #6C7995;
    }
  }
}
</style>
