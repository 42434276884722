<script>
import Paper from '@/components/layout/WizardOverviewPaper';
import EzButton from '@/components/ui/Button/EzButton';
import { mapMutations, mapState } from 'vuex';
import { wizardListenerMixin } from '@/mixins/wizard';
import VVenueEntityInfo from '@/components/v3/patterns/VVenueEntityInfo/index';
import EzLoader from '@/components/ui/Loader/EzLoader';
import flash from '@/components/ui/FlashMessage';
import { venueXeroAddConnections, venueXeroSetupAccount } from '@/api/endpoints/xero';
import { VENUE_STEP_1, VENUE_STEP_2 } from './steps';

/**
 * Overview
 * @version 1.0.0
 * @since
 */

export default {
  mixins: [wizardListenerMixin],
  components: {
    Paper,
    EzButton,
    VVenueEntityInfo,
    EzLoader,
  },
  inject: ['venueId'],
  data() {
    return {
      isLoaderShown: false,
      overviewDistributors: [],
      syncItems: [],
    };
  },
  computed: {
    ...mapState('xero', ['venues', 'contacts', 'draft', 'connections']),
  },
  methods: {
    ...mapMutations('xero', ['CLEAR_STATE']),
    distributorsItems() {
      this.overviewDistributors = Object.keys(this.connections)
        .map(key => ({
          distributor: this.venues.data.find(venue => venue.entity.id === +key),
          xeroContact: this.contacts.data.find(contact => this.connections[key] === contact.xeroId),
        }))
        .filter(item => item.distributor.entity.id && item.xeroContact.xeroId);
    },
    editAccount() {
      this.$emit('stepBack', VENUE_STEP_1);
    },
    editDistributors() {
      this.$emit('stepBack', VENUE_STEP_2);
    },
    async onNextStep() {
      this.isLoaderShown = true;
      const account = {
        accountCode: this.draft?.account?.code || '',
        taxType: this.draft?.tax?.taxType || '',
        deliveryFeeAccountCode: this.draft?.deliveryFeeAccountCode?.code || '',
        accountingInvoicePush: this.draft?.orderingMode?.id || '',
      };
      const connections = this.overviewDistributors.map(item => ({
        contactId: item.xeroContact.xeroId,
        distributorId: item.distributor.entity.id,
      }));

      try {
        const promises = [];
        promises.push(venueXeroSetupAccount(this.venueId, account));
        if (connections.length) {
          promises.push(venueXeroAddConnections(this.venueId, { connections }));
        }
        await Promise.all(promises);
        await this.$router.push({
          name: 'venue-settings-integrations',
          params: {
            id: this.venueId,
            flash: {
              title: this.$t('xero.wizzard.venue.overview.flashMessage.success.title'),
              message: this.$t('xero.wizzard.venue.overview.flashMessage.success.message'),
              type: 'success',
            },
          },
        });
        this.CLEAR_STATE();
      } catch (e) {
        flash.error({
          title: this.$t('xero.wizzard.venue.overview.flashMessage.error.title'),
        });
      } finally {
        this.isLoaderShown = false;
      }
    },
  },
  mounted() {
    this.enableNextStep();
    this.distributorsItems();
  },
};
</script>
<template>
  <div>
    <ez-loader :show="isLoaderShown">
      {{ $t('xero.wizzard.venue.overview.loaderMessage') }}
    </ez-loader>
    <paper>
      <template #title-prefix>
        <img class="products-img" src="@/assets/logo-xero.png" alt="" />
      </template>
      <template #title>{{ $t('xero.wizzard.venue.overview.accountPaper.title') }}</template>
      <template #actions>
        <ez-button type="link" @click="editAccount">
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <div v-if="draft.account" class="info info--horizontal">
        <div class="product-info">
          <span>{{ $t('xero.wizzard.venue.overview.accountPaper.labelAccount') }}</span>
        </div>
        <small>{{ draft.account.name }}</small>
      </div>
      <div v-if="draft.tax" class="info info--horizontal">
        <div class="product-info">
          <span>{{ $t('xero.wizzard.venue.overview.accountPaper.labelTax') }}</span>
        </div>
        <small>{{ draft.tax.name }}</small>
      </div>
      <div v-if="draft.deliveryFeeAccountCode" class="info info--horizontal">
        <div class="product-info">
          <span>Delivery Fee Account</span>
        </div>
        <small>{{ draft.deliveryFeeAccountCode.name }}</small>
      </div>
      <div v-if="draft.orderingMode" class="info info--horizontal">
        <div class="product-info">
          <span>Order Export Mode</span>
        </div>
        <small>{{ draft.orderingMode.name }}</small>
      </div>
    </paper>
    <paper v-if="overviewDistributors.length">
      <template #title>
        {{ $t('xero.wizzard.venue.overview.distributorsPaper.title') }}
      </template>
      <template #actions>
        <ez-button type="link" @click="editDistributors">
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <div
        class="overview-items"
        v-for="item in overviewDistributors"
        :key="item.distributor.entity.id"
      >
        <div class="overview-items--info">
          <v-venue-entity-info class="overview-items--entity" :venue="item.distributor.entity" />
        </div>
        <div class="contact">
          <span>{{ item.xeroContact.name }}</span>
        </div>
      </div>
    </paper>
  </div>
</template>
<style lang="scss" scoped>
.overview-items {
  @extend %flex-space;
  width: 100%;

  &--info {
    max-width: 270px;
  }

  &--entity {
    width: 100%;
  }

  .contact {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      color: $label-color;
      @include font-size(14px, 16px);
    }
  }
  .price {
    span {
      font-weight: 600;
      @include font-size(14px, 16px);
    }
  }
}
.products-img {
  border-radius: 50%;
}
</style>
