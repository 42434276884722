<script>
/**
 *
 * @version 1.0.0
 * @since
 */
import { formatPrice } from '@/util/utils';
import { V_PRICE_TYPE } from '@/util/constants';

export default {
  name: 'VPrice',
  props: {
    price: {
      required: true,
      type: Number,
    },
    currency: {
      required: false,
      type: Object,
      default: () => null,
    },
    /**
     * Determines should `price` or `market price label` be displayed.
     */
    isMarketPrice: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    /**
     * If true, market price label = Market Price
     * If false, market price label = MP
     */
    showMarketPriceLong: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    /**
     * If true, `market price info` tooltip is going to show on hover
     */
    showMarketPriceInfo: {
      required: false,
      type: Boolean,
      default: () => true,
    },
    unit: {
      required: false,
      type: String,
      default: () => '',
    },
    unitShort: {
      required: false,
      type: String,
      default: () => '',
    },
    /**
     * Determines should `price` or `TBD label` be displayed.
     * If isMarketPrice is true & isToBeDetermined is true, `market price label` will be shown.
     */
    isToBeDetermined: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    /**
     * If this is set to true, v-price will be displayed like row.
     * By default it is displayed like column.
     */
    flexRow: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Determines styling type of component.
     *
     * You can add new type & then add new scss classes for that type.
     * If you don't add type class for some element, default class will be used.
     * For eg. look `types` section in <style>
     */
    type: {
      required: false,
      type: String,
      default: () => V_PRICE_TYPE.DEFAULT,
      validator: val => Object.values(V_PRICE_TYPE).includes(val),
    },
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
  },
  computed: {
    showUnitLabel() {
      return this.unit || this.unitShort;
    },
    unitLabel() {
      const unitLabel = this.unit ? ` ${this.unit}` : '';
      const unitShortLabel = this.unitShort ? ` ${this.unitShort} -` : '';

      return this.showUnitLabel ? `/${unitShortLabel}${unitLabel}` : '';
    },
  },
  data() {
    return {
      formatPrice,
    };
  },
};
</script>

<template>
  <span
    :class="[
      { 'v-price--column': !flexRow },
      { 'v-price--row': flexRow },
      { [`v-price-${type}--column`]: !flexRow },
      { [`v-price-${type}--row`]: !flexRow },
    ]"
  >
    <span
      v-if="!(isMarketPrice || isToBeDetermined)"
      :class="['v-price__price', `v-price-${type}__price`]"
      :data-cy="dataCy"
    >
      {{ formatPrice(price, currency) }}
    </span>

    <template v-if="isMarketPrice">
      <span
        :class="[
          { 'v-price__market-price--column': !flexRow },
          { 'v-price__market-price--row': flexRow },
          { [`v-price-${type}__market-price--column`]: !flexRow },
          { [`v-price-${type}__market-price--row`]: flexRow },
        ]"
        :data-cy="dataCy"
      >
        -
      </span>
    </template>

    <span
      v-if="isToBeDetermined && !isMarketPrice"
      :class="['v-price__to-be-determined', `v-price-${type}__to-be-determined`]"
      :data-cy="dataCy"
    >
      {{ $t('global.tbd') }}
    </span>

    <span
      v-if="showUnitLabel"
      :class="[
        { 'v-price__unit--column': !flexRow },
        { 'v-price__unit--row': flexRow },
        { [`v-price-${type}__unit--column`]: !flexRow },
        { [`v-price-${type}__unit--row`]: flexRow },
      ]"
      :data-cy="`${dataCy}-unitLabel`"
    >
      {{ unitLabel }}
    </span>
  </span>
</template>

<style scoped lang="scss">
.v-price--column {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.v-price--row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}

.v-price__price {
  @include font-size(16px, 22px);
  font-weight: bold;
}

.v-price__market-price {
  width: fit-content;
}

.v-price__market-price--column {
  @extend .v-price__market-price;
}

.v-price__market-price--row {
  @extend .v-price__market-price;
  @include font-size(14px, 22px);
  font-weight: 600;
}

.v-price__to-be-determined {
  color: $color-gray-25;
}

.v-price__unit {
  color: $color-gray-6C;
  margin-left: 2px;
  font-weight: 500;
}

.v-price__unit--column {
  @extend .v-price__unit;
  @include font-size(12px, 14px);
}

.v-price__unit--row {
  @extend .v-price__unit;
  @include font-size(14px, 22px);
}

// types

// secondary

.secondary__text {
  @include font-size(12px, 14px);
  font-weight: 600;
}

.v-price-secondary__price {
  @extend .secondary__text;
  color: $color-gray-25;
}

.v-price-secondary__unit--row {
  @extend .secondary__text;
  color: $color-gray-25;
}

.v-price-secondary__market-price--row {
  @extend .secondary__text;
}
</style>
