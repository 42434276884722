<script>
/* eslint-disable */

/**
 * index
 * @version 1.0.0
 * @since
 */

import dayjs from 'dayjs';
import { isCorrectOrder } from '@/util/utils';
import VCalendar from '@/components/v3/elements/VCalendar';
import EzButton from '@/components/ui/Button';
import { EzSimpleDropdown } from '@/components/ui/Dropdown';
import {
  getAllTimeRange,
  getAllTimeRangeInFuture,
  getLastMonthRange,
  getLastWeekRange,
  getLastYearRange,
  getThisMonthRange,
  getThisWeekRange,
  getThisYearRange,
  getTodayRange,
} from '@/util/utils-date';
import { formatDate } from '@/util/utils';

const Event = {
  rangeNameChange: 'rangeNameChange',
  dateChange: 'dateChange',
  clearDate: 'clearDate',
};

export default {
  name: 'VDatePicker',
  components: {
    VCalendar,
    EzSimpleDropdown,
    EzButton,
  },
  model: {
    prop: 'date',
    event: Event.dateChange,
  },
  props: {
    // date for normal mode or array of two dates for range mode (start, end in an object)
    date: true,
    name: {
      required: true,
      type: String,
    },
    label: {
      required: true,
      type: String,
    },
    placeholder: {
      type: String,
      default: 'Select Date',
    },
    hideLabel: {
      type: Boolean,
    },
    withTimePicker: {
      type: Boolean,
    },
    withPredefinedRanges: {
      type: Boolean,
    },
    numberOfCalendars: {
      default: 1,
      type: Number,
    },
    // in range mode, date should be an object with keys "start" and "end"
    rangeMode: {
      type: Boolean,
    },
    weekStartsOn: {
      default: 1,
      type: Number,
    },
    selectPredefinedRange: {
      type: String,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    deliveryDays: {
      type: Array,
      required: false,
      default: () => ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    },
    min: {
      type: [Date, Object, String],
      required: false,
    },
    max: {
      type: [Date, Object, String],
      required: false,
    },
    // diff All Time values for future dates
    isAllTimeInFuture: {
      type: Boolean,
      required: false,
      default: false,
    },
    // what month will show in user viewport when calendar is opened
    whatDateToDisplay: {
      type: [Date],
      required: false,
      default: null,
    },
    withClearButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFullWidth: {
      required: false,
      type: Boolean,
      default: false,
    },
    displayEndOfRange: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * This attribute is used for
     * marking the element when testing with cypress
     */
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
  },
  watch: {
    date(val) {
      this.model = val;
      this.displayDate = this.getDisplayDate();
    },
  },
  data() {
    return {
      model: this.date,
      displayDate: new Date(),
      hoveredDate: null,
      // Used for proper order. As object keys don't follow the order
      predefinedRangeOrder: [
        'All Time',
        'Last Week',
        'Last Month',
        'Last Year',
        'Today',
        'This Week',
        'This Month',
        'This Year',
      ],
      predefinedRanges: {
        'All Time': today =>
          !this.isAllTimeInFuture ? getAllTimeRange(today) : getAllTimeRangeInFuture(today),
        Today: today => getTodayRange(today),
        'This Week': today => getThisWeekRange(today, this.weekStartsOn),
        'This Month': today => getThisMonthRange(today),
        'This Year': today => getThisYearRange(today),
        'Last Week': today => getLastWeekRange(today, this.weekStartsOn),
        'Last Month': today => getLastMonthRange(today),
        'Last Year': today => getLastYearRange(today),
      },
      oldModel: {},
      rightAlign: false,
    };
  },
  computed: {
    potentialNewRange() {
      if (this.rangeMode && this.hoveredDate != null) {
        const { start, end } = this.model;
        const hover = this.hoveredDate;
        switch (true) {
          // [S] [H] [E] => [S] [E] [ ]
          case isCorrectOrder(start, hover, end):
            return this.rangeTransform({ start, end: hover });
          // [S] [E] [H] => [S] [ ] [E]
          case isCorrectOrder(start, end, hover):
            return this.rangeTransform({ start, end: hover });
          // [H] [S] [E] => [S] [ ] [E]
          case isCorrectOrder(hover, start, end):
            return this.rangeTransform({ start: hover, end });
          // [SH] [E] => [SE] [ ]
          // [S] [EH] => [ ] [SE]
          case dayjs(hover).isSame(start, 'd') || dayjs(hover).isSame(end, 'd'):
            return this.rangeTransform({ start: hover, end: hover });
          case dayjs(start).isSame(end, 'd'):
            // [H] [SE] => [S] [E]
            if (dayjs(hover).isBefore(start, 'd')) {
              return this.rangeTransform({ start: hover, end });
            }
            // [SE] [H] => [S] [E]
            if (dayjs(start).isBefore(hover, 'd')) {
              return this.rangeTransform({ start, end: hover });
            }
            // [SEH] => [SE]
            return this.rangeTransform({ start, end });
        }
      } else {
        return null;
      }
    },
    rangeName() {
      if (this.rangeMode) {
        const today = new Date();
        const result = Object.keys(this.predefinedRanges).find(name => {
          const fn = this.predefinedRanges[name];
          const potential = fn(today);
          const sameStart = dayjs(potential.start).isSame(this.model.start, 'd');
          const sameEnd = dayjs(potential.end).isSame(this.model.end, 'd');
          return sameStart && sameEnd;
        });
        if (result == null) {
          return `${dayjs(this.model.start).format('DD MMM')} - ${dayjs(this.model.end).format(
            'DD MMM',
          )}`;
        }
        return result;
      } else {
        return this.model ? `${formatDate(this.model)}` : this.placeholder;
      }
    },
  },
  methods: {
    /**
     * Reset the component to the predefined range
     * @see EzFilterList
     */
    reset() {
      this.setPredefinedRange();
    },
    /**
     * If the range is provided in the prop find it and set it.
     */
    setPredefinedRange() {
      if (this.selectPredefinedRange != null) {
        this.onPredefinedRangeSelect(
          this.predefinedRanges[this.selectPredefinedRange],
          this.selectPredefinedRange,
        );
      }
    },
    rangeTransform({ start, end }) {
      return {
        start: dayjs(start).startOf('d'),
        end: dayjs(end).endOf('d'),
      };
    },
    onNext() {
      this.displayDate = dayjs(this.displayDate).add(1, 'M');
    },
    onPrev() {
      this.displayDate = dayjs(this.displayDate).subtract(1, 'M');
    },
    onMouseOverDate(hoveredDate) {
      this.hoveredDate = hoveredDate;
    },
    onMouseLeaveDate() {
      this.hoveredDate = null;
    },
    onDateSelect(newModel) {
      this.oldModel = this.model;
      if (!newModel) return; // disabled dates
      const year = dayjs(newModel).get('y');
      const month = dayjs(newModel).get('M');
      const date = dayjs(newModel).get('D');
      if (!this.rangeMode) {
        if (this.model) {
          this.model = dayjs(this.model).set('y', year).set('M', month).set('D', date);
        } else {
          // selected value when our model is null
          this.model = dayjs().set('y', year).set('M', month).set('D', date);
        }
      } else {
        this.model = this.potentialNewRange;
      }
      if (!this.rangeMode) this.$refs.datePicker.close();
      this.$emit(Event.dateChange, this.model);
      this.$emit(Event.rangeNameChange, this.rangeName);
    },
    onPredefinedRangeSelect(fn, rangeName) {
      if (rangeName === this.rangeName) return;
      const { start, end } = fn(new Date());
      this.model = { start: dayjs(start).startOf('day'), end: dayjs(end).endOf('day') };
      this.$emit(Event.dateChange, this.model);
      this.$emit(Event.rangeNameChange, this.rangeName);
    },
    getDisplayDate() {
      if (this.whatDateToDisplay && this.isAllTimeInFuture && this.rangeName === 'All Time') {
        return dayjs(this.whatDateToDisplay);
      }
      const isBeforeStart = dayjs(this.model?.start).isBefore(this.oldModel?.start);
      const isAfterEnd = dayjs(this.model?.end).isAfter(this.oldModel?.end);
      if (isBeforeStart) {
        return this.model?.start;
      } else if (isAfterEnd) {
        return this.model?.end;
      }
      return this.date ? (this.date.end ? this.date.end : this.date) : new Date();
    },
    async dropdownOpen() {
      if (!this.displayEndOfRange) {
        this.displayDate = this.getDisplayDate();
      } else {
        this.displayDate = this.date ? (this.date.end ? this.date.end : this.date) : new Date();
        this.displayDate = dayjs(this.displayDate).subtract(1, 'month');
      }
      await this.$nextTick();
      const dropdownEl = document.querySelector('.date-picker .ez-simple-dropdown__dropdown');
      if (dropdownEl) {
        const bounding = dropdownEl.getBoundingClientRect();
        if (bounding.right > (window.innerWidth || document.clientWidth)) {
          this.rightAlign = true;
        }
      }
      document.addEventListener('click', this.clickOutsideListener, true);
    },
    formatStartDate(model) {
      const date = model && model.start ? model.start : model;
      return date ? dayjs(date) : date; // provide null for initial state
    },
    formatEndDate(model) {
      const date = model && model.end ? model.end : model;
      return date ? dayjs(date) : date; // provide null for initial state
    },
    onClearDate() {
      this.$emit(Event.clearDate);
    },
    onClose() {
      this.removeEventListeners();
    },
    clickOutsideListener(event) {
      if (this.$el && !this.$el.contains(event.target)) {
        this.$refs.datePicker.close();
      }
    },
    removeEventListeners() {
      this.eventListeners = false;
      document.removeEventListener('click', this.clickOutsideListener, true);
    },
  },
  mounted() {
    this.setPredefinedRange();
  },
};
</script>
<template>
  <div>
    <ez-simple-dropdown
      :class="['date-picker', { 'date-picker--right-aligned': rightAlign }]"
      :label="hideLabel ? '' : label"
      direction="down"
      :disabled="disabled"
      :is-full-width="isFullWidth"
      @open="dropdownOpen"
      @close="onClose"
      ref="datePicker"
      :data-cy="dataCy"
    >
      <template #display>
        <div :class="['placeholder', { 'width-100': isFullWidth }]">
          <span>{{ rangeName }}</span>
          <font-awesome-icon :icon="['far', 'calendar-alt']" />
        </div>
      </template>
      <template #dropdown>
        <div :class="['calendars', { 'is-only-one': numberOfCalendars === 1 }]" @click.stop>
          <v-calendar
            v-for="n in numberOfCalendars"
            :key="n"
            withYearAndMonth
            :has-navigation-prev="n === 1"
            :has-navigation-next="n === numberOfCalendars"
            @next="onNext"
            @previous="onPrev"
            :displayedDate="displayDate | addMonths(n - 1)"
            :startDate="formatStartDate(model)"
            :endDate="formatEndDate(model)"
            @mouseOverDate="onMouseOverDate"
            @mouseLeaveDate="onMouseLeaveDate"
            @select="onDateSelect"
            :deliveryDays="deliveryDays"
            :potential-new-range="potentialNewRange"
            :min="min"
            :max="max"
            :data-cy="dataCy"
          />
        </div>

        <div v-if="withPredefinedRanges" class="predefined-ranges">
          <div v-for="key in predefinedRangeOrder" class="predefined-ranges__item" :key="key">
            <ez-button
              type="secondary"
              @click="onPredefinedRangeSelect(predefinedRanges[key], key)"
              is-full-width
              :custom-class="key === rangeName ? 'selected' : ''"
              :data-cy="`${dataCy}-${key.split(' ').join('_')}`"
            >
              {{ key }}
            </ez-button>
          </div>
        </div>
        <div v-if="withClearButton" class="mt-8">
          <ez-button class="clear-btn" type="blue-link" @click="onClearDate"> Clear </ez-button>
        </div>
      </template>
    </ez-simple-dropdown>
  </div>
</template>
<style lang="scss" scoped>
.calendars {
  display: flex;
}

:deep() .vcalendar + .vcalendar {
  margin-left: 32px;
}

.predefined-ranges {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 0 -6px;
  margin-top: 16px;

  &__item {
    padding: 6px 6px;
  }
}

.button.selected {
  background-color: $color-primary-blue;
  color: #fff;
}

.date-picker :deep() {
  width: auto;

  .ez-simple-dropdown__display-container {
    display: inline-flex;
    height: 36px;
  }

  .ez-simple-dropdown__dropdown {
    max-height: none;
    width: auto;
    padding: 16px;
    line-height: 1;
  }

  .is-only-one {
    .vcalendar__table td {
      &::before {
        content: none;
      }
    }
  }
}

.date-picker--right-aligned {
  :deep() .ez-simple-dropdown__dropdown {
    right: 0;
  }
}

.placeholder {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
  span {
    @include font-size(14px, 16px);
  }
}
.clear-btn {
  padding: 0;
  @include font-size(14px, 14px);
  height: 14px;
}
</style>
