<script>
/**
 * index
 * @version 1.0.0
 * @since
 */

export default {
  props: {
    type: {
      required: false,
      type: String,
      default: 'green',
      validator: value => ['green', 'yellow', 'red'].indexOf(value) !== -1,
    },
  },
};
</script>
<template>
  <div :class="`location-badge location-badge--${type}`">
    <font-awesome-icon icon="store-alt" class="mr-8" />
    <slot></slot>
  </div>
</template>
<style lang="scss" scoped>
.location-badge {
  display: inline-block;
  padding: 4px;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  &--green {
    background-color: $color-pastel-green;
    color: $color-primary-green;
  }
  &--yellow {
    background-color: $color-pastel-yellow;
    color: $color-primary-orange;
  }
  &--red {
    background-color: $color-white-peach;
    color: $color-primary-red;
  }
}
</style>
