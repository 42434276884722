<template>
  <ez-selected-item v-on="$listeners" :item="item" :hasEditButton="hasEditButton">
    <template #emptyState>
      <slot v-if="hasEmptySlot" name="emptyState"></slot>
      <empty-state v-else>
        <template #badge>
          <img src="@/assets/no-venue-empty-state.svg" width="160" alt=""/>
        </template>
        <template #title>
          {{ $t('distributors.emptyStates.autoComplete.title') }}
        </template>
        <template #info>
          {{ $t('distributors.emptyStates.autoComplete.info') }}
        </template>
      </empty-state>
    </template>
  </ez-selected-item>
</template>


<script>
/**
 * SelectedDistributor
 * @version 1.0.0
 * @since
 */
import EmptyState from '@/views/common/empty-state';
import EzSelectedItem from './SelectedItem.vue';


export default {
  components: {
    EmptyState,
    EzSelectedItem,
  },
  props: {
    distributor: {
      required: true,
    },
    hasEditButton: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    hasEmptySlot() {
      return !!this.$slots.emptyState;
    },
    item() {
      if (!this.distributor) return {};
      return {
        xeroId: !!this.distributor.xeroId,
        image: this.distributor.logo,
        name: this.distributor.name,
        accountType: this.distributor.accountType,
      };
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
