<template>
  <div :class="{ 'public-order-products': isPublicOrder }">
    <ez-alert
      v-if="isSomeUnavailableOnProvino()"
      type="red"
      custom-class="provino-alert"
      variant="disclaimer"
      :data-cy="commonCy.SINGLE_ORDER.TEXT__WARNING_INVENTORY"
    >
      <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
      <template v-if="isDistributorProvinoConnected">{{
        $t('provino.productsNotAvailableErrorTxt')
      }}</template>
      <template v-else>{{ $t('orders.productsNotAvailableErrorTxt') }}</template>
    </ez-alert>
    <ez-table
      :hasMinHeight="false"
      class="products-table"
      :columns="columns"
      :data="products"
      :headers="productHeaders"
      :rowCls="getRowClass"
      :columnProps="{
        sku: { class: 'medium-cell sku-cell' },
        quantity: { class: 'small-cell quantity-cell' },
        unitPrice: { class: 'date-cell discount-cell' },
        price: { class: 'small-cell price-cell' },
        exclude: { class: 'medium-cell exclude-cell' },
      }"
      :rowDataCy="row => `${commonCy.SINGLE_ORDER.ROW__INCLUDED_PRODUCT}-${row.id}`"
      @rowHover="rowHover"
    >
      <template #cell-product="{ row }">
        <ez-entity-info
          :img-url="row.image"
          :class="{ 'provino-not-exist': checkIsUnavailable(row.productId) }"
        >
          <div class="product-info" :title="row.name">
            <span :data-cy="`${commonCy.SINGLE_ORDER.TEXT__PRODUCT_NAME}-${row.id}`">{{
              row.name
            }}</span>
            <span v-if="!isSimpleOrder && !isPublicOrder" class="product-info-secondary">
              <v-price
                flex-row
                :price="row.defaultPrice || 0"
                :currency="singleOrder.currency"
                :is-market-price="row.marketPrice"
                :show-market-price-long="true"
                :show-market-price-info="false"
                :type="V_PRICE_TYPE.SECONDARY"
              />

              <span
                v-if="row.sku && !isSimpleOrder"
                class="ml-2"
                :data-cy="`${commonCy.SINGLE_ORDER.TEXT__PRODUCT_SKU}-${row.id}`"
              >
                &bull; {{ row.sku }}
              </span>

              <span v-if="checkIsUnavailable(row.productId)" class="provino-label ml-2">
                &bull;
                <span :data-cy="commonCy.SINGLE_ORDER.TEXT__PRODUCT_AVAILABLE">
                  {{ unavailableType(row.productId) }}
                </span>
              </span>
            </span>
            <span v-else-if="isPublicOrder" class="product-info-secondary">
              <v-price
                flex-row
                :price="row.price || 0"
                :currency="singleOrder.currency"
                :is-market-price="row.marketPrice"
                :show-market-price-long="true"
                :show-market-price-info="false"
                :type="V_PRICE_TYPE.SECONDARY"
              />
              <span class="ml-2 unit">/ {{ getProductUnitLabel(row) }}</span>

              <span
                v-if="row.sku"
                class="ml-2"
                :data-cy="`${commonCy.SINGLE_ORDER.TEXT__PRODUCT_SKU}-${row.id}`"
              >
                &bull; {{ row.sku }}
              </span>

              <span v-if="checkIsUnavailable(row.productId)" class="provino-label ml-2">
                &bull;
                <span :data-cy="commonCy.SINGLE_ORDER.TEXT__PRODUCT_AVAILABLE">
                  {{ unavailableType(row.productId) }}
                </span>
              </span>
            </span>
          </div>
        </ez-entity-info>
      </template>

      <template #cell-sku="{ row }">
        {{ row.sku }}
      </template>

      <template #cell-quantity="{ row }">
        <div v-if="isSimpleOrder" class="actions-wrapper">
          <v-product-quantity-inventory
            v-if="!isRowHovered(row) || !isSomeActionVisible(row)"
            :product="row"
            :warehouseId="warehouseId"
            :hasTooltip="!editingMode"
            :isDistributor="isDistributor"
            :inventoryTracking="isDistributor ? me.inventorySettings?.tracking : false"
            :totalLabel="totalLabel"
            :canBeInvalid="isOrderStatusPending"
            :data-cy="`${commonCy.SINGLE_ORDER.TEXT__PRODUCT_QTY}-${row.id}`"
          />
          <span class="minus ml-8" v-if="isExcludeVisible(row)">
            <font-awesome-icon
              v-tooltip="{
                content: row.isDeclined ? 'Include in order' : 'Exclude from order',
                classes: ['tooltip-general'],
              }"
              icon="minus-circle"
              @click.stop="toggleExcludeProduct(row)"
            />
          </span>
          <font-awesome-icon
            v-tooltip="{
              content: 'Duplicate product',
              classes: ['tooltip-general'],
            }"
            v-if="isDuplicateVisible(row)"
            @click.stop="duplicateProduct(row)"
            icon="plus"
            :data-cy="`${commonCy.SINGLE_ORDER.ICON__EDIT_PRODUCT}-${row.id}`"
          />
          <font-awesome-icon
            v-tooltip="{
              content: 'Edit details',
              classes: ['tooltip-general'],
            }"
            v-if="isEditVisible(row)"
            @click.stop="open(row)"
            class="ml-12"
            icon="pen"
            :data-cy="`${commonCy.SINGLE_ORDER.ICON__EDIT_PRODUCT}-${row.id}`"
          />
          <font-awesome-icon
            v-tooltip="{
              content: 'Remove from order',
              classes: ['tooltip-general'],
            }"
            @click.stop="deleteProduct(row)"
            class="ml-12"
            v-if="isDeleteVisible(row)"
            icon="trash"
          />
        </div>
        <v-product-quantity-inventory
          v-else
          :product="row"
          :warehouseId="warehouseId"
          :hasTooltip="!editingMode"
          :isDistributor="isDistributor"
          :inventoryTracking="isDistributor ? me.inventorySettings?.tracking : false"
          :totalLabel="totalLabel"
          :canBeInvalid="isOrderStatusPending"
          :data-cy="`${commonCy.SINGLE_ORDER.TEXT__PRODUCT_QTY}-${row.id}`"
        />
      </template>

      <template #cell-unitPrice="{ row }">
        <v-price
          :price="row.price || 0"
          :currency="singleOrder.currency"
          :show-market-price-info="false"
          :is-to-be-determined="false"
          :data-cy="''"
        />
        <span class="additional-info">/ {{ getProductUnitLabel(row) }}</span>
      </template>

      <template #cell-price="{ row }">
        <div class="actions-wrapper">
          <span v-if="!isRowHovered(row) || !isSomeActionVisible(row)">
            <v-price
              :price="!row.quantity ? 0 : roundPrice(row.price, row.priceQuantity)"
              :currency="singleOrder.currency"
              :show-market-price-info="false"
              :is-to-be-determined="isToBeDetermined(row)"
              :data-cy="`${commonCy.SINGLE_ORDER.TEXT__PRODUCT_PRICE}-${row.id}`"
            />
            <span v-if="row.isBonus" class="additional-info additional-info--bonus">Bonus</span>
            <span
              class="additional-info"
              v-else-if="row.discountAmount && row.discountType === 'amount'"
            >
              <template v-if="row.discountAmount > 0">
                {{ (row.discountAmount * row.priceQuantity) | price }} off
              </template>
              <template v-if="row.discountAmount < 0">
                {{ (-row.discountAmount * row.priceQuantity) | price }} increase
              </template>
            </span>
            <span
              class="additional-info"
              v-else-if="row.discountAmount && row.discountType === 'percentage'"
            >
              <template v-if="row.discountValue > 0"> {{ row.discountValue }}% off </template>
              <template v-if="row.discountValue < 0"> {{ -row.discountValue }}% increase </template>
            </span>
          </span>

          <span class="minus ml-8" v-if="isExcludeVisible(row)">
            <font-awesome-icon
              v-tooltip="{
                content: row.isDeclined ? 'Include in order' : 'Exclude from order',
                classes: ['tooltip-general'],
              }"
              icon="minus-circle"
              @click.stop="toggleExcludeProduct(row)"
            />
          </span>
          <font-awesome-icon
            v-tooltip="{
              content: 'Duplicate product',
              classes: ['tooltip-general'],
            }"
            v-if="isDuplicateVisible(row)"
            @click.stop="duplicateProduct(row)"
            icon="plus"
            :data-cy="`${commonCy.SINGLE_ORDER.ICON__EDIT_PRODUCT}-${row.id}`"
          />
          <font-awesome-icon
            v-tooltip="{
              content: 'Edit details',
              classes: ['tooltip-general'],
            }"
            v-if="isEditVisible(row)"
            @click.stop="open(row)"
            class="ml-12"
            icon="pen"
            :data-cy="`${commonCy.SINGLE_ORDER.ICON__EDIT_PRODUCT}-${row.id}`"
          />
          <font-awesome-icon
            v-tooltip="{
              content: 'Remove from order',
              classes: ['tooltip-general'],
            }"
            @click.stop="deleteProduct(row)"
            class="ml-12"
            v-if="isDeleteVisible(row)"
            icon="trash"
          />
        </div>
      </template>
    </ez-table>
    <div class="mobile-products-list">
      <div
        v-for="product in products"
        :key="product.id"
        :class="[getRowClass(product), 'product-item']"
        :id="`product-item-${product.id}`"
        @mouseover="rowHover(product)"
        @mouseleave="rowHover(null)"
      >
        <div class="first-row">
          <ez-entity-info imgWidth="2.5rem" imgHeight="2.5rem" :imgUrl="product.image">
            <div class="product-info" :title="product.name">
              <span>
                {{ product.name }}
              </span>
              <span class="product-info-secondary">
                <span class="product-info-secondary--category">
                  Quantity: <span class="unit">{{ product.quantity }}</span>
                </span>
              </span>
            </div>
          </ez-entity-info>
          <div class="product-quantity" v-if="!isSimpleOrder">
            <v-price
              :price="!product.quantity ? 0 : roundPrice(product.price, product.priceQuantity)"
              :currency="singleOrder.currency"
              :show-market-price-info="false"
              :is-to-be-determined="isToBeDetermined(product)"
              :data-cy="`${commonCy.SINGLE_ORDER.TEXT__PRODUCT_PRICE}-${product.id}`"
            />
            <span class="product-info-secondary--category">
              <v-price
                flex-row
                :price="product.price || 0"
                :currency="singleOrder.currency"
                :is-market-price="product.marketPrice"
                :show-market-price-long="true"
                :show-market-price-info="false"
                :type="V_PRICE_TYPE.SECONDARY"
              />
              <span class="ml-2 unit">/ {{ getProductUnitLabel(product) }}</span>
            </span>
          </div>
        </div>
        <div class="actions">
          <span class="minus ml-8" v-if="showExclude">
            <font-awesome-icon icon="minus-circle" @click.stop="toggleExcludeProduct(product)" />
          </span>
          <font-awesome-icon
            v-if="enableEdit"
            @click.stop="duplicateProduct(product)"
            icon="plus"
            :data-cy="`${commonCy.SINGLE_ORDER.ICON__EDIT_PRODUCT}-${product.id}`"
          />
          <font-awesome-icon
            v-if="isEditVisibleMobile(product)"
            @click.stop="open(product)"
            class="ml-12"
            icon="pen"
            :data-cy="`${commonCy.SINGLE_ORDER.ICON__EDIT_PRODUCT}-${product.id}`"
          />
          <font-awesome-icon
            @click.stop="deleteProduct(product)"
            class="ml-12"
            v-if="isDeleteVisibleMobile(product)"
            icon="trash"
          />
        </div>
      </div>
    </div>

    <div class="button-wrapper" v-if="editingMode && !isPublicOrder">
      <ez-button
        class="mt-16"
        type="secondary"
        is-full-width
        @click="$refs.orderAddProducts.open()"
        :data-cy="commonCy.SINGLE_ORDER.BUTTON__ADD_PRODUCTS"
      >
        <font-awesome-icon icon="plus" /> Add Product
      </ez-button>
    </div>

    <additional-items
      v-if="!isSimpleOrder"
      :type="type"
      :order="singleOrder"
      :editable="editingMode"
      :is-public-order="isPublicOrder"
      :credit-note-amount="singleOrder.creditNoteAmount"
      @change="updateAdditionalItems"
    />

    <order-add-products
      ref="orderAddProducts"
      :order="singleOrder"
      :order-id="orderId || -1"
      :distributor-id="singleOrder.distributorId"
      :existing-product-ids="productIds"
      @change="addProducts"
    />

    <edit-details-modal
      ref="editDetails"
      :order="singleOrder"
      :product="selectedProduct"
      :can-edit-price="canEditPrice"
      :is-public-order="isPublicOrder"
      :is-simple-order="isSimpleOrder"
      :type="type"
      @change="updateProduct"
      @close="onEditDetailsClose"
    />
  </div>
</template>

<script>
/**
 * Editable order that is coupled to Vuex store.
 * Temporary object in store is used for editing because all changes should be created locally,
 * and sent to the backend only after confirmation.
 *
 * Everything inside the order should work with this store object.
 * If the edit is confirmed the object will be sent to BE.
 */
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzTable from '@/components/ui/Table';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import {
  ADDITIONAL_ITEMS,
  isAdmin,
  ORDER_STATUS_ACCEPTED,
  ORDER_STATUS_PARTIALLY_ACCEPTED,
  ORDER_STATUS_PENDING,
  PROVINO_UNAVAILABLE_TYPE,
  V_PRICE_TYPE,
} from '@/util/constants';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';

import EditDetailsModal from '@/views/common/orders/products/EditDetailsModal';
import EzButton from '@/components/ui/Button/EzButton';
import AdditionalItems from '@/views/common/orders/tabs/AdditionalItems';
import OrderAddProducts from '@/views/common/orders/actions/OrderAddProducts';
import { falsy } from '@/util/utils';
import EzAlert from '@/components/ui/Alert/EzAlert';
import bus from '@/views/platform/distributor/orders/bus';
import publicBus from '@/views/public/bus';
import VPrice from '@/components/v3/elements/VPrice';
import VProductQuantityInventory from '@/components/v3/elements/VProductQuantityInventory';
import { roundNumber } from '@/util/utilsFinCalculator';
import uuid from 'uuid/v4';

export default {
  name: 'EditableOrder',
  components: {
    VPrice,
    OrderAddProducts,
    AdditionalItems,
    EzButton,
    EzEntityInfo,
    EzTable,
    EditDetailsModal,
    EzAlert,
    VProductQuantityInventory,
  },
  props: {
    editingMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPublicOrder: {
      type: Boolean,
      default: false,
    },
    isSimpleOrder: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
    },
    /**
     * Defines products that will be shown based on prop & its value.
     * If set to null or empty object it will show all products.
     *
     * (eg.)
     *
     * <editable-order
     *  :type="type"
     *  :is-public-order="isPublicOrder"
     *  :editing-mode="editingMode"
     *  :filter-by="{ prop: 'isIncluded', value: true }"
     * />
     *
     * Will show only products that have prop isIncluded that is true
     *
     */
    filterBy: {
      type: Object, // { prop: String, value: any }
      required: false,
      default: null,
    },
  },
  data() {
    return {
      productHeaders: {
        price: h =>
          h(
            'div',
            { style: { textAlign: 'right' } },
            this.isPublicOrder ? 'Total Amount' : 'Subtotal',
          ),
        sku: () => 'SKU',
        quantity: () => 'Qty',
        unitPrice: () => 'Unit Price',
      },
      hoveredRow: null,
      selectedProduct: {},
      storeWatcher: null,
      inventoryStatus: [],
      provinoUnavailableTypes: PROVINO_UNAVAILABLE_TYPE,
      V_PRICE_TYPE,
      commonCy,
      ADDITIONAL_ITEMS,
    };
  },
  computed: {
    ...mapState('entities/orders', ['singleOrder']),
    ...mapGetters('entities/users', ['isDistributor', 'isVenue', 'isDistributorProvinoConnected']),
    ...mapState('entities/users', ['loggedUser']),
    ...mapState('entities/users', ['contextId']),
    me() {
      return this.loggedUser.distributor;
    },
    columns() {
      return [
        'product',
        ...(this.isSimpleOrder ? [] : ['sku']),
        'quantity',
        ...(this.isSimpleOrder || this.isPublicOrder ? [] : ['unitPrice']),
        ...(this.isSimpleOrder ? [] : ['price']),
      ];
    },
    enableEdit() {
      return this.editingMode;
    },
    productsFiltered() {
      if (falsy(this.filterBy)) {
        return this.singleOrder?.orderedProducts || [];
      }
      const { prop, value } = this.filterBy;
      return this.singleOrder?.orderedProducts?.filter(p => p[prop] === value) || [];
    },
    products() {
      return this.productsFiltered;
    },
    productIds() {
      return this.products.map(p => p.productId);
    },
    inventoryStatusIds() {
      return this.inventoryStatus?.map(item => item.id);
    },
    orderId() {
      return this.singleOrder.id;
    },
    excludedProductsIds() {
      return (this.singleOrder.orderedProducts || [])
        .filter(p => p.isDeclined)
        .map(a => a.productId);
    },
    showExclude() {
      const isSomeActionPartial = !!this.singleOrder.actions?.find(action => action.isPartial);
      return !this.enableEdit && isSomeActionPartial;
    },
    canEditPrice() {
      return this.singleOrder?.editConfig?.price || this.singleOrder.offline;
    },
    isDistributorAndIsProvinoConnected() {
      return this.isDistributor && this.isDistributorProvinoConnected;
    },
    isAcceptedAndProvinoConnected() {
      return (
        (this.singleOrder.status === ORDER_STATUS_ACCEPTED ||
          this.singleOrder.status === ORDER_STATUS_PENDING ||
          this.singleOrder.status === ORDER_STATUS_PARTIALLY_ACCEPTED) &&
        this.isDistributorAndIsProvinoConnected
      );
    },
    isOrderStatusPending() {
      return this.singleOrder.status === ORDER_STATUS_PENDING;
    },
    warehouseId() {
      return this.singleOrder.fulfillmentWarehouse?.id || null;
    },
    totalLabel() {
      return this.isOrderStatusPending ? 'Total' : 'Total Remaining';
    },
  },
  methods: {
    ...mapMutations('entities/orders', [
      'ADD_PRODUCTS_TO_SELECTED_SINGLE_ORDER',
      'SET_DELIVERY_FEE_TO_SELECTED_SINGLE_ORDER',
      'SET_DISCOUNT_TO_SELECTED_SINGLE_ORDER',
      'SET_PRICE_ADJUSTMENT_TO_SELECTED_SINGLE_ORDER',
      'SET_TAX_ADJUSTMENT_TO_SELECTED_SINGLE_ORDER',
      'REMOVE_PRODUCT_FROM_SELECTED_SINGLE_ORDER',
      'CALCULATE_SELECT_SINGLE_ORDER_AMOUNT',
      'UPDATE_PRODUCT_FROM_SELECTED_SINGLE_ORDER',
      'TOGGLE_PRODUCT',
      'UPDATE_SELECTED_SINGLE_ORDER',
      'UPDATE_DELIVERY_FEE_TO_SELECTED_SINGLE_ORDER',
      'UPDATE_DELIVERY_FEE_TO_SELECTED_SINGLE_ORDER_PUBLIC',
    ]),
    ...mapActions('entities/distributors', ['distributorCheckInventoryStatus']),
    duplicateProduct(product) {
      this.addProducts([
        {
          ...product,
          isBonus: false,
          bonusId: null,
          id: uuid(),
        },
      ]);
    },
    addProducts(products) {
      this.ADD_PRODUCTS_TO_SELECTED_SINGLE_ORDER(products);
      this.UPDATE_SELECTED_SINGLE_ORDER({ oldAmount: this.singleOrder.amount });
      this.CALCULATE_SELECT_SINGLE_ORDER_AMOUNT();
      this.UPDATE_DELIVERY_FEE_TO_SELECTED_SINGLE_ORDER();
      this.$emit('productsAdded', products);
    },
    rowClick(item) {
      this.$emit('productClick', item);
    },
    getRowClass(row) {
      let rowClass = null;
      if (row.isDeclined && row.isBonus) rowClass = 'item-excluded item-bonus';
      else if (row.isDeclined) rowClass = 'item-excluded';
      else if (row.isBonus) rowClass = 'item-bonus';
      return rowClass;
    },
    getProductUnitLabel(product) {
      const { priceUnit, orderingUnit } = product || {};
      return priceUnit?.label || orderingUnit?.label || '';
    },
    isToBeDetermined(product) {
      const { priceQuantity, marketPrice, price, quantity } = product || {};
      if (!quantity) return false;
      return (!priceQuantity && price > 0) || marketPrice;
    },
    rowHover(row) {
      this.hoveredRow = row;
    },
    isRowHovered(row) {
      if (this.hoveredRow) {
        return row.id === this.hoveredRow.id;
      }
      return false;
    },
    canEditProduct(product) {
      const canUserEdit = !isAdmin();
      const { addedInEditMode } = product;
      return this.enableEdit && (canUserEdit || addedInEditMode);
    },
    open(row) {
      this.selectedProduct = row;
      this.$refs.editDetails.open();
    },
    onEditDetailsClose() {
      this.selectedProduct = {};
    },
    async toggleExcludeProduct(product) {
      const oldAmountVal = this.singleOrder.amount;
      await this.TOGGLE_PRODUCT({ productId: product.id });
      await this.UPDATE_SELECTED_SINGLE_ORDER({ oldAmount: oldAmountVal });
      await this.CALCULATE_SELECT_SINGLE_ORDER_AMOUNT();
      this.$emit('productExcluded', product);

      if (this.isPublicOrder) {
        await this.UPDATE_DELIVERY_FEE_TO_SELECTED_SINGLE_ORDER_PUBLIC();
        return;
      }

      await this.UPDATE_DELIVERY_FEE_TO_SELECTED_SINGLE_ORDER();
      this.isSomeUnavailableOnProvino();
    },
    updateAdditionalItems(item) {
      switch (item.key) {
        case ADDITIONAL_ITEMS.DELIVERY_FEE:
          this.SET_DELIVERY_FEE_TO_SELECTED_SINGLE_ORDER(item.amount);
          break;
        case ADDITIONAL_ITEMS.DISCOUNT:
          this.SET_DISCOUNT_TO_SELECTED_SINGLE_ORDER(item.amount);
          break;
        case ADDITIONAL_ITEMS.PRICE_ADJUSTMENT:
          this.SET_PRICE_ADJUSTMENT_TO_SELECTED_SINGLE_ORDER(item.amount);
          break;
        case ADDITIONAL_ITEMS.TAX_ADJUSTMENT:
          this.SET_TAX_ADJUSTMENT_TO_SELECTED_SINGLE_ORDER(item.amount);
          break;
        default:
          break;
      }
    },
    deleteProduct(product) {
      this.REMOVE_PRODUCT_FROM_SELECTED_SINGLE_ORDER(product);
      this.UPDATE_SELECTED_SINGLE_ORDER({ oldAmount: this.singleOrder.amount });
      this.CALCULATE_SELECT_SINGLE_ORDER_AMOUNT();
      this.UPDATE_DELIVERY_FEE_TO_SELECTED_SINGLE_ORDER();
      this.$emit('productDeleted', product);
    },
    /**
     * BE handles if product can be deleted or not, unless product is added in edit mode.
     * If product is added in edit mode than it can be deleted by user in edit mode.
     *
     * @param product
     *
     * @returns {boolean}
     */
    isDeleteVisible(product) {
      return (
        this.enableEdit &&
        this.isRowHovered(product) &&
        (product.canBeDeleted || product.addedInEditMode)
      );
    },
    isDeleteVisibleMobile(product) {
      return this.enableEdit && (product.canBeDeleted || product.addedInEditMode);
    },
    isExcludeVisible(row) {
      return this.showExclude && (row.isDeclined || this.isRowHovered(row));
    },
    isEditVisible(row) {
      return this.isRowHovered(row) && this.canEditProduct(row) && !row.isBonus;
    },
    isEditVisibleMobile(row) {
      return this.canEditProduct(row) && !row.isBonus;
    },
    isDuplicateVisible(row) {
      return this.isRowHovered(row) && this.enableEdit;
    },
    isSomeActionVisible(row) {
      return (
        this.isExcludeVisible(row) ||
        this.isEditVisible(row) ||
        this.isDeleteVisible(row) ||
        this.isDuplicateVisible(row)
      );
    },
    updateProduct(product) {
      this.UPDATE_PRODUCT_FROM_SELECTED_SINGLE_ORDER(product);
      this.UPDATE_SELECTED_SINGLE_ORDER({ oldAmount: this.singleOrder.amount });
      this.CALCULATE_SELECT_SINGLE_ORDER_AMOUNT();
      this.UPDATE_DELIVERY_FEE_TO_SELECTED_SINGLE_ORDER();
      this.$emit('productUpdated', product);
    },
    checkIsUnavailable(id) {
      return !!this.inventoryStatusIds?.find(item => item === id);
    },
    isSomeUnavailableOnProvino() {
      let productsIds = this.productIds;
      if (this.excludedProductsIds.length) {
        productsIds = productsIds.filter(el => !this.excludedProductsIds.includes(el));
      }
      const isSomeUnavailable = productsIds.some(item => this.inventoryStatusIds.includes(item));
      this.$emit('isSomeUnavailable', isSomeUnavailable);
      return isSomeUnavailable;
    },
    unavailableType(id) {
      const unType = this.inventoryStatus.find(item => item.id === id);
      return unType.error === this.provinoUnavailableTypes.not_existing
        ? `Doesn’t exist in ${this.$t('provino.name')}`
        : `${unType.quantity} available for delivery`;
    },
    async checkInventoryStatus() {
      if (!this.isDistributor || !this.singleOrder?.id || isAdmin()) return;

      const {
        data: { data },
      } = await this.distributorCheckInventoryStatus({
        orderId: this.singleOrder.id,
      });
      this.inventoryStatus = data;
      this.isSomeUnavailableOnProvino();
    },
    roundPrice(price, quantity) {
      return roundNumber({
        number: price * quantity,
        decimals: 8,
      });
    },
  },
  async created() {
    /**
     * This is a simple bus event handler to check inventory products status
     * after successfully order edit
     *
     */
    bus.$on('savedEditSuccessfully', () => {
      this.checkInventoryStatus();
    });

    /**
     * Add a Vuex watcher to watch for product changes.
     * If there is a change to product orders recalculate the sum.
     *
     * @see {@link https://vuex.vuejs.org/api/#watch}
     */
    this.$store.watch(
      (state, getters) => getters['entities/orders/selectedOrderOrderedProducts'],
      () => {
        this.CALCULATE_SELECT_SINGLE_ORDER_AMOUNT();
      },
      { deep: true },
    );

    await this.checkInventoryStatus();
  },
  watch: {
    products: {
      immediate: true,
      handler(newVal) {
        if (this.isPublicOrder) publicBus.$emit('productsUpdated', !newVal.length);
      },
    },
  },
  beforeDestroy() {
    bus.$off('savedEditSuccessfully');
    // remove the vuex watcher
    if (this.storeWatcher) this.storeWatcher();
  },
};
</script>

<style scoped lang="scss">
$icon-excluded-color: #e84d60;
$excluded-background-color: #f0f4f9;
$text-color: #252631;
$icon-color: $color-gray-45;

.table {
  thead {
    th {
      &.quantity-cell {
        text-align: right;
      }
    }
  }
  tbody {
    tr {
      .minus {
        display: none;
      }
      svg {
        outline: none;
      }
      &.item-bonus {
        background-color: $color-pastel-blue;
        :deep() td:first-child {
          padding-left: 8px;
        }
        :deep() td:last-child {
          padding-right: 8px;
        }
      }
      &.item-excluded {
        background-color: $excluded-background-color;
        text-decoration: line-through;
        opacity: 0.6;
        &:hover {
          opacity: 0.8;
        }

        .price-cell {
          color: #8387a0;
        }

        .minus {
          display: flex;
          svg {
            color: $icon-excluded-color;
          }
        }
      }
      &:not(.item-excluded):hover {
        .minus {
          display: flex;
          svg {
            color: $icon-color;
          }
        }
      }

      td {
        &.price-cell {
          color: $text-color;
          text-align: right;
        }
        &.quantity-cell {
          text-align: right;
          overflow: visible;
        }
      }
      .declined-minus {
        display: flex;
        color: $icon-excluded-color;
        opacity: 0.24;
      }
    }
  }
  .tax-label {
    cursor: pointer;
    color: #6c7995;
    @include font-size(12px);
    font-weight: 600;
  }

  .tax {
    min-width: 164px;
    &__item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 6px;

      &--total {
        padding-top: 6px;
        border-top: 1px solid rgba(#fff, 0.2);
      }
    }
  }
  .actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $icon-color;

    span {
      color: $text-color;
    }
    span.additional-info {
      color: $color-gray-6C;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;

      &--bonus {
        border-bottom: 2px dotted #a3bbff;
        color: $color-primary-blue;
      }
    }
  }
}
.product-info {
  display: flex;
  flex-direction: column;

  & > span:not(.product-info-secondary) {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .product-info-secondary {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-top: 2px;

    .unit {
      color: $color-gray-25;
    }

    .provino-label {
      @include font-size(12px, 14px);
      color: $color-gray-6C;
      span {
        color: $color-primary-red;
      }
    }
  }
}

:deep() .table {
  .sku-cell {
    display: none;
  }

  :is(th, td).price-cell {
    width: 152px;
  }

  .discount-cell {
    text-align: right;

    span {
      color: $color-gray-25;
    }
    span.additional-info {
      color: $color-gray-6C;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
    }
  }
}

:deep() .item-bonus .has-border {
  border: 1px solid $color-primary-blue;
}
:deep() .provino-not-exist {
  .has-border {
    border: 1px solid $color-primary-red;
  }
}
:deep() .provino-alert {
  align-items: flex-start;
}
.mobile-products-list {
  display: none;
  padding-bottom: 24px;

  .product-item {
    padding: 8px 0;
    border-bottom: 1px solid #eceef5;
    hr {
      margin: 8px 0;
    }
    svg {
      outline: none;
      font-size: 16px;
      color: $icon-color;
    }
    &.item-bonus {
      background-color: $color-pastel-blue;
      :deep() td:first-child {
        padding-left: 8px;
      }
      :deep() td:last-child {
        padding-right: 8px;
      }
    }
    &.item-excluded {
      background-color: $excluded-background-color;
      text-decoration: line-through;
      opacity: 0.6;
      &:hover {
        opacity: 0.8;
      }

      .price-cell {
        color: #8387a0;
      }

      .minus {
        display: flex;
        svg {
          color: $icon-excluded-color;
        }
      }
    }
    &:not(.item-excluded):hover {
      .minus {
        display: flex;
        svg {
          color: $icon-color;
        }
      }
    }
  }

  .first-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.5;

    .entity-info {
      align-items: center;
      min-width: 0;
      flex-shrink: 1;
    }

    .product-quantity {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      font-size: 14px;
      flex-shrink: 0;

      :deep() .v-price__price {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }

      .product-info-secondary--category {
        font-size: 12px;
        line-height: 18px;
        color: $color-gray-6C;
        display: flex;
        align-items: center;

        :deep() .v-price__price {
          font-size: 12px;
          line-height: 18px;
          color: $color-gray-6C;
        }
      }
    }
  }

  .actions {
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $icon-color;
    cursor: pointer;

    span {
      color: $text-color;
    }
    span.additional-info {
      color: $color-gray-6C;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;

      &--bonus {
        border-bottom: 2px dotted #a3bbff;
        color: $color-primary-blue;
      }
    }
  }

  .product-info {
    display: flex;
    flex-direction: column;
    line-height: 1.5;
    font-size: 14px;
    font-weight: 500;

    .product-info-secondary {
      display: block;
      font-size: 12px;
      line-height: 18px;
    }

    span {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

@media (max-width: 1024px) {
  :deep() .products-table {
    display: none;
  }
  .mobile-products-list {
    display: block;
  }
  .public-order-products {
    :deep() table.table tbody tr td {
      padding: 8px;
      &::before {
        text-transform: none;
        font-weight: 500;
        min-width: 120px;
        font-size: 12px;
      }
    }
  }

  .actions-wrapper .v-price--column {
    align-items: flex-start;
  }
}
</style>
