<script>
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown';
import EzButton from '@/components/ui/Button/EzButton';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput';
import VPrice from '@/components/v3/elements/VPrice';
import { getCurrency } from '@/util/utils';
import i18n from '@/i18n';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'VMarketPriceInput',
  components: { VPrice, EzMaskInput, EzButton, EzSimpleDropdown },
  props: {
    isMarketPrice: {
      required: false,
      type: Boolean,
      default: false,
    },
    isItDifferentFromNumber: {
      required: false,
      type: Boolean,
      default: false,
    },
    isTbd: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Actions will be displayed in Dropdown list.
     */
    actions: {
      required: false,
      type: Array,
      default: () => [
        { type: 'setRegularPrice', label: i18n.t('product.regularPrice'), payload: {} },
        { type: 'setMarketPrice', label: i18n.t('product.marketPrice'), payload: {} },
      ],
    },
    /**
     * This attribute is used for
     * marking the element when testing with cypress
     */
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
    /**
     * EzMaskInput Props - Start
     */
    formKey: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    label: {
      required: false,
      type: String,
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    error: {
      type: String,
      required: false,
      default: '',
    },
    isInvalid: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'inline',
      validator: v => ['inline', 'input'].includes(v),
    },
    currency: {
      type: Object,
      required: false,
      default: () => {},
    },
    pricePrefix: {
      type: Boolean,
      required: false,
      default: true,
    },
    precision: {
      type: Number,
      required: false,
      default: 2,
    },
    masked: {
      type: Boolean,
      required: false,
      default: false,
    },
    priceUnit: {
      type: Object,
      required: false,
      default: () => null,
    },
    allowNegativePrice: {
      type: Boolean,
      required: false,
      default: true,
    },
    customPrefix: {
      required: false,
      type: String,
    },
    minValue: {
      type: Number,
      required: false,
      default: Number.MIN_SAFE_INTEGER,
    },
    maxValue: {
      type: Number,
      required: false,
      default: Number.MAX_SAFE_INTEGER,
    },
    /**
     * EzMaskInput Props - End
     */
  },
  computed: {
    currencySymbol() {
      const { symbol } = getCurrency() || {};
      return this.currency?.symbol || symbol;
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>

<template>
  <div class="u-flex-row">
    <div class="mp-price-wrapper">
      <ez-mask-input
        v-if="!isItDifferentFromNumber"
        class="v-market-price-input__input"
        :form-key="formKey"
        :name="name"
        :label="label"
        :value="value"
        :disabled="disabled"
        :error="error"
        :type="type"
        :currency="currency"
        :price-prefix="pricePrefix"
        :custom-prefix="customPrefix"
        :min-value="minValue"
        :max-value="maxValue"
        :precision="precision"
        :masked="masked"
        :is-invalid="isInvalid"
        @input="$emit('input', $event)"
        @invalid="$emit('invalid', $event)"
        :data-cy="`${dataCy}-input`"
        :allow-negative-value="allowNegativePrice"
      />
      <div
        v-if="isItDifferentFromNumber"
        :class="[
          'width-100',
          'v-market-price-input__mp-input',
          { 'v-market-price-input__mp-input--disabled': disabled },
        ]"
      >
        <v-price
          class="v-market-price-input__mp-input__mp"
          :price="value"
          :is-market-price="isMarketPrice"
          :show-market-price-info="false"
          :show-market-price-long="false"
          :is-to-be-determined="isTbd"
          :data-cy="`${dataCy}-marketPrice`"
        />

        <span v-if="pricePrefix">
          {{ currencySymbol }}
        </span>
      </div>
      <span
        v-if="priceUnit && !isMarketPrice"
        :title="priceUnit.label"
        class="mt-2 mp-price-wrapper__unit"
      >
        {{ `/ ${priceUnit.label}` }}
      </span>
    </div>
    <ez-simple-dropdown
      class="v-market-price-input__dropdown mt-2"
      :disabled="disabled"
      :is-full-width="false"
      @open="isOpen = true"
      @close="isOpen = false"
      :data-cy="`${dataCy}-dropdown`"
    >
      <template #display>
        <font-awesome-icon
          :class="[
            'ml-12',
            { 'v-market-price-input__icon--is-open': isOpen },
            { 'v-market-price-input__icon--is-closed': !isOpen },
          ]"
          icon="angle-down"
        />
      </template>
      <template #dropdown>
        <ul>
          <li v-for="{ type, label, payload } in actions" :key="type" @click="$emit(type, payload)">
            <ez-button
              :data-cy="`${dataCy}-action-${type}`"
              class="v-market-price-input__btn width-100"
              type="link"
            >
              {{ label }}
            </ez-button>
          </li>
        </ul>
      </template>
    </ez-simple-dropdown>
  </div>
</template>

<style scoped lang="scss">
.mp-price-wrapper {
  flex-direction: column;
  display: flex;
  align-items: flex-end;
}
.mp-price-wrapper__unit {
  @include font-size(12px, 14px);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 85px;
}
:deep() .ez-simple-dropdown {
  width: fit-content;
}

.v-market-price-input__dropdown {
  :deep() .ez-simple-dropdown__display-container {
    padding: 0;
    background-color: transparent !important;
  }

  :deep() .ez-simple-dropdown__dropdown {
    width: fit-content;
    right: 80%;
  }
}

.v-market-price-input__input {
  width: fit-content;
  display: flex;
}

.v-market-price-input__mp-input {
  @include font-size(14px);

  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 75px;
  border-bottom: 1px solid $input-border-color;
}

.v-market-price-input__mp-input--disabled {
  opacity: 0.5;
  pointer-events: none;
}

.v-market-price-input__mp-input__mp {
  font-weight: bold;
}

ul {
  @extend %ul-reset;
}

.v-market-price-input__icon--is-open {
  transform: rotate(180deg);
  transition: all 0.6s ease;
}

.v-market-price-input__icon--is-closed {
  transform: rotate(0);
  transition: all 0.6s ease;
}

.v-market-price-input__btn {
  color: $color-gray-25;

  &:hover {
    color: $color-primary-blue;
  }
}
</style>
