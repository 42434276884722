<script>

/**
 * NewProductModal
 * @version 1.0.0
 * @since 2.3.0
 */

import EzButton from '@/components/ui/Button';
import { EzFormModal } from '@/components/ui/Modal';
import ProductForm from '@/views/common/products/new-product-supplier/ProductForm';
import { mapActions, mapGetters, mapState } from 'vuex';
import { LOADING_KEY } from '@/util/constants';

export default {
  components: {
    EzButton,
    EzFormModal,
    ProductForm,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    formAction: {
      type: String,
      required: true,
    },
    product: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    distributorId: {
      type: Number,
      required: true,
      default: -1,
    },
    additionalData: {
      type: Object,
      required: false,
      default: null,
    },
    updateProductGroups: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      categories: [],
      formValid: false,
      units: [],
    };
  },
  computed: {
    ...mapState('entities/users', ['contextId']),
    venueId() { return this.contextId; },
    defaultData() {
      return { optionalPermissions: [], ...this.additionalData };
    },
    isExistingProduct() {
      return this.product && !!this.product.id;
    },
    method() {
      return this.isExistingProduct ? 'patch' : 'post';
    },
    action() {
      if (!this.isExistingProduct) {
        return this.formAction;
      }

      return `${this.formAction}/${this.product.id}`;
    },
    ...mapGetters('loading', ['getLoading']),
    isLoadingProduct() {
      return this.getLoading(LOADING_KEY.ADD_OR_UPDATE_PRODUCT);
    },
  },
  methods: {
    ...mapActions('entities/categories', ['venueFetchCategories']),
    ...mapActions('entities/products', ['venueFetchUnits']),
    open() {
      this.$refs.userModal.open();
    },
    close() {
      this.$refs.userModal.close();
    },
    submitForm() {
      this.$refs.form.submit();
    },
    onSubmitSuccess(result) {
      this.$emit('success', { ...result, isUpdate: this.isExistingProduct });
      if (!this.isExistingProduct) this.$refs.form.reset();
    },
    onSubmitError(result) {
      this.$emit('error', result);
    },
    onClose() {
      this.$refs.form.resetPriceAndUnits();
      this.$refs.form.reset();
      this.$refs.form.clear();
      this.$emit('close');
    },
    isFormValid(valid) {
      this.formValid = valid;
    },
    async getUnits() {
      const { data: { data } } = await this.venueFetchUnits({ venueId: this.venueId });
      this.units = data;
    },
  },
  async mounted() {
    const { data } = await this.venueFetchCategories();
    this.categories = data.data;
    await this.getUnits();
  },
};
</script>

<template>
  <ez-form-modal
    ref="userModal"
    class="ez-user-modal"
    @close="onClose"
  >
    <template #title>
      <slot name="title">{{ isExistingProduct ? 'Update Product' : 'Add Product' }}</slot>
    </template>
    <template #content>
      <product-form
        ref="form"
        :form-key="formKey"
        :method="method"
        :action="action"
        :product="product"
        :categories="categories"
        :distributorId="distributorId"
        @submitSuccess="onSubmitSuccess"
        @formValid="isFormValid"
        :units="units"
        :updateProductGroups="updateProductGroups"
        @onUnitChange="getUnits"
        @successDeleteUnit="getUnits"
      />
    </template>
    <template #footer>
      <ez-button
        type="link"
        formType="button"
        @click="close">Cancel</ez-button>
      <ez-button
        :is-loading="isLoadingProduct"
        :disabled="!formValid"
        formType="button"
        @click="submitForm">{{ isExistingProduct ? 'Update Product' : 'Add Product' }}</ez-button>
    </template>
  </ez-form-modal>
</template>

<style scoped lang="scss">
  .ez-user-modal {
    :deep() .ez-dropdown {
      max-width: 100%;
    }
    :deep() .modal {
      overflow: visible;
      .modal__text {
        max-height: 625px;
        overflow-y: auto;
      }
    }
    .ez-form {
      .input-group + .input-group {
        margin-top: .75rem;
      }
    }
  }
</style>
