<script>
/**
 *
 * @version 1.0.0
 * @since 3.13.0
 *
 * Simple component which catch event changes on child components
 */
export default {
  name: 'FilterBody',
  methods: {
    reset() {
      /**
       * Start reset mode.
       * @see In the watch section
       */
      this.$children.forEach(({ reset }) => {
        if (typeof reset === 'function') {
          reset();
        }
      });

      /**
       * When filter value is changed.
       * @event resetFilter
       * @type Object
       */
      this.$emit('resetFilter');
    },
    onFilterUpdated({ name }, event) {
      /**
         * When filter value is changed.
         * @event filterUpdated
         * @type String
         */
      this.$emit('filterUpdated', name, event);
    },
  },
  mounted() {
    this.$children.forEach((child) => {
      child.$on('change', (event) => { this.onFilterUpdated(child, event); });
      child.$on('onChange', (event) => { this.onFilterUpdated(child, event); });
      child.$on('dateChange', (event) => { this.onFilterUpdated(child, event); });
      child.$on('selected', (event) => { this.onFilterUpdated(child, event); });
    });
  },
};
</script>

<template>
  <div><slot></slot></div>
</template>

<style lang="scss" scoped>

</style>
