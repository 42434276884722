<script>
/**
 * index
 * @version 1.0.0
 * @since
 */

export default {};
</script>
<template>
  <span class="e-badge"><slot/></span>
</template>
<style lang="scss" scoped>
.e-badge {
  display: inline-block;
  @include font-size(11px);
  max-width: 12em;
  padding: 0 $spacing-04;
  color: $category-color;
  font-weight: 500;
  line-height: 1.2;
  border: 1px solid $category-border-color;
  border-radius: 2px;
  text-transform: uppercase;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
</style>
