<template>
  <div>
    <div class="overview-actions mt-24">
      <div
        class="dropdown-button"
        v-if="$permission.has('createOrder') && !$permission.isFree && !groupViewMode"
      >
        <ez-button
          @click="openUploadInvoice('invoice')"
          :data-cy="outletCy.ORDERS.GENERAL.BUTTON__LOG_OFFLINE_ORDER"
        >
          Upload Invoice
        </ez-button>
        <ez-button-dropdown buttonType="primary">
          <template #icon>
            <font-awesome-icon icon="angle-down" />
          </template>
          <template #dropdown>
            <ez-button
              type="secondary"
              @click="openUploadInvoice('credit_note')"
              :data-cy="outletCy.ORDERS.GENERAL.BUTTON__LOG_OFFLINE_ORDER"
            >
              Upload Credit Note
            </ez-button>
          </template>
        </ez-button-dropdown>
      </div>
    </div>
    <div class="overview mt-24">
      <div class="stats">
        <router-link
          v-if="!groupViewMode"
          :to="{
            name: 'venue-pending-updates',
            query: {
              processingStatus: 'action_needed',
            },
          }"
        >
          <v-rich-stats-box
            class="mb-24"
            backgroundColor="#FFF8EE"
            accentColor="#FBE7C7"
            textColor="#FC773B"
          >
            <template slot="icon">
              <font-awesome-icon :icon="['fas', 'file-invoice-dollar']" />
            </template>
            <template slot="label">Invoices with action needed</template>
            <template slot="value">{{ statistics.pendingInvoicesCount }}</template>
          </v-rich-stats-box>
        </router-link>
        <router-link
          v-if="!groupViewMode"
          :to="{
            name: 'venue-orders-history',
            query: {
              status: orderStatusFilter,
            },
          }"
        >
          <v-rich-stats-box
            class="mb-24"
            backgroundColor="#FDF5FF"
            accentColor="#EEDFF4"
            textColor="#983BBD"
          >
            <template slot="icon">
              <font-awesome-icon :icon="['fa', 'list-alt']" />
            </template>
            <template slot="label">Orders awaiting approval</template>
            <template slot="value">{{ statistics.ordersApprovalCount }}</template>
          </v-rich-stats-box>
        </router-link>
        <router-link
          :to="{
            name: 'venue-all-suppliers-products',
            query: {
              productGroup: 'unassigned',
            },
          }"
        >
          <v-rich-stats-box
            backgroundColor="#EFFBF7"
            accentColor="#DAF1E9"
            textColor="#1BA975"
            class="mb-24"
            :compactMode="!groupViewMode"
          >
            <template slot="icon">
              <font-awesome-icon icon="coffee" />
            </template>
            <template slot="label">Products without groups</template>
            <template slot="value">{{ statistics.productsWithoutGroupCount }}</template>
          </v-rich-stats-box>
        </router-link>
        <router-link
          :to="{
            name: 'venue-all-suppliers',
            params: {
              orderingMethod: orderingMethodFilter,
            },
          }"
        >
          <v-rich-stats-box
            backgroundColor="#F5F6FA"
            accentColor="#E1E5ED"
            textColor="#495063"
            :compactMode="!groupViewMode"
          >
            <template slot="icon">
              <font-awesome-icon icon="truck" />
            </template>
            <template slot="label">Suppliers without contacts</template>
            <template slot="value">{{ statistics.distributorWithoutContactsCount }}</template>
          </v-rich-stats-box>
        </router-link>
      </div>
      <div class="tables">
        <div class="table">
          <div class="table-header">
            <h2>Recent New Products</h2>
            <router-link
              :to="{
                name: 'venue-all-suppliers-products',
                params: {
                  newFilter: true,
                },
              }"
              class="see-all-action mb-16"
            >
              <span class="mr-8">See All</span>
              <font-awesome-icon icon="angle-right" style="font-size: 20px" />
            </router-link>
          </div>
          <div v-if="newProducts.length" class="no-min-height">
            <ez-table
              :headers="{
                name: () => 'Product',
                price: () => 'Price',
                distributor: () => `${$t('global.distributor').toLowerCase()}`,
              }"
              :columns="['name', 'distributor', 'price']"
              :columnProps="{
                price: { class: 'price-cell' },
              }"
              :data="newProducts"
            >
              <template #cell-name="{ row: { image, sku, name, orderingUnit } }">
                <ez-entity-info :imgUrl="image" :imgHasBorder="true">
                  <div class="product-info">
                    <span class="product-info__name" :title="name">
                      {{ name }}
                    </span>
                    <span class="product-info-secondary">
                      <span>{{ orderingUnit.label }}</span> &#8226; {{ sku }}
                    </span>
                  </div>
                </ez-entity-info>
              </template>
              <template #cell-distributor="{ row: { distributor } }">
                <v-distributor-entity-info :distributor="distributor" />
              </template>
              <template #cell-price="{ row: { price, priceUnit } }">
                <v-price
                  :price="price || 0"
                  :unit="priceUnit && priceUnit.label"
                  :is-market-price="!price"
                />
              </template>
            </ez-table>
          </div>
          <div class="empty-state" v-else>
            <p class="empty-state__label">No new products to show</p>
            <empty-state>
              <template #badge><img src="@/assets/no-products.svg" alt="" /></template>
            </empty-state>
          </div>
        </div>
        <div class="table mt-24">
          <div class="table-header">
            <h2>Recent Price Changes</h2>
            <router-link
              :to="{
                name: 'venue-reports-invoices',
                params: {
                  onlyWithChange: true,
                  ...(priceChangesMeta.from ? { from: priceChangesMeta.from } : {}),
                  ...(priceChangesMeta.to ? { to: priceChangesMeta.to } : {}),
                },
              }"
              class="see-all-action mb-16"
            >
              <span class="mr-8">See All</span>
              <font-awesome-icon icon="angle-right" style="font-size: 20px" />
            </router-link>
          </div>
          <div v-if="priceChanges.length" class="no-min-height">
            <ez-table
              :headers="{
                name: () => 'Product',
                oldPrice: () => 'Reference Price',
                newPrice: () => 'New Price',
                priceChange: () => 'Price Change',
                distributor: () => `${$t('global.distributor').toLowerCase()}`,
              }"
              :columns="['name', 'distributor', 'oldPrice', 'newPrice', 'priceChange']"
              :columnProps="{
                priceChange: { class: 'price-cell price-change-cell' },
                newPrice: { class: 'price-cell' },
                oldPrice: { class: 'price-cell' },
              }"
              :data="priceChanges"
            >
              <template #cell-name="{ row: { image, sku, name, orderingUnit } }">
                <ez-entity-info :imgUrl="image" :imgHasBorder="true">
                  <div class="product-info">
                    <span class="product-info__name" :title="name">
                      {{ name }}
                    </span>
                    <span class="product-info-secondary">
                      <span>{{ orderingUnit.label }}</span> &#8226; {{ sku }}
                    </span>
                  </div>
                </ez-entity-info>
              </template>
              <template #cell-distributor="{ row: { distributor } }">
                <v-distributor-entity-info :distributor="distributor" />
              </template>
              <template #cell-oldPrice="{ row: { oldPrice, priceUnit } }">
                <v-price :price="oldPrice || 0" :unit="priceUnit && priceUnit.label" />
              </template>
              <template #cell-newPrice="{ row: { newPrice, priceUnit } }">
                <v-price :price="newPrice || 0" :unit="priceUnit && priceUnit.label" />
              </template>
              <template #cell-priceChange="{ row: { priceChangeAmount, priceChangePercent } }">
                <div class="price-change__amount">
                  <span v-if="priceChangeAmount > 0" class="price-red">+</span>
                  <v-price
                    :class="{
                      'price-green': priceChangeAmount < 0,
                      'price-red': priceChangeAmount > 0,
                    }"
                    :price="priceChangeAmount"
                  />
                </div>
                <span class="percent"
                  >{{ priceChangeAmount > 0 ? '+' : '' }}{{ priceChangePercent }}%</span
                >
              </template>
            </ez-table>
          </div>
          <div class="empty-state" v-else>
            <p class="empty-state__label">No price changes to show</p>
            <empty-state>
              <template #badge><img src="@/assets/no-reports.svg" alt="" /></template>
            </empty-state>
          </div>
        </div>
      </div>
      <ez-loader :show="isLoading">Loading...</ez-loader>
      <upload-invoice
        :fileType="fileType"
        ref="uploadInvoice"
        @uploadFinished="() => (this.fileType = '')"
      />
    </div>
  </div>
</template>

<script>
/**
 * Dashboard
 * @version 1.0.0
 * @since 2.3.0
 */

import { mapActions, mapGetters, mapState } from 'vuex';
import { LOADING_KEY, ORDER_STATUS_NEEDS_APPROVAL } from '@/util/constants';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import VRichStatsBox from '@/components/v3/elements/VRichStatsBox';
import EzLoader from '@/components/ui/Loader';
import EzEntityInfo from '@/components/ui/EntityInfo';
import VDistributorEntityInfo from '@/components/v3/patterns/VDistributorEntityInfo';
import EzTable from '@/components/ui/Table';
import VPrice from '@/components/v3/elements/VPrice';
import EmptyState from '@/views/common/empty-state';
import EzButton from '@/components/ui/Button';
import UploadInvoice from '@/views/platform/venue/orders/actions/UploadInvoice.vue';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown.vue';

export default {
  components: {
    VRichStatsBox,
    EzLoader,
    EmptyState,
    EzTable,
    EzEntityInfo,
    VDistributorEntityInfo,
    VPrice,
    EzButton,
    UploadInvoice,
    EzButtonDropdown,
  },
  data() {
    return {
      meta: {},
      statistics: {},
      newProducts: [],
      priceChanges: [],
      priceChangesMeta: {},
      limit: 3,
      outletCy,
      fileType: '',
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser', 'context']),
    ...mapGetters('loading', ['isSomeLoading']),
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.VENUE_FETCH_OVERVIEW_STATS,
        LOADING_KEY.VENUE_FETCH_OVERVIEW_NEW_PRODUCTS,
        LOADING_KEY.VENUE_FETCH_OVERVIEW_PRICE_CHANGES,
      ]);
    },
    groupViewMode() {
      if (this.loggedUser.context === 'venue-group') return true;
      return false;
    },
    orderStatusFilter() {
      return [ORDER_STATUS_NEEDS_APPROVAL];
    },
    orderingMethodFilter() {
      return {
        id: 'none',
        name: 'None',
      };
    },
  },
  methods: {
    ...mapActions('entities/venues', [
      'venueFetchOverviewStatistics',
      'venueFetchPriceChanges',
      'venueFetchNewProducts',
    ]),
    async fetchData() {
      const [
        {
          data: { data, meta },
        },
        {
          data: { data: newProducts },
        },
        {
          data: { data: priceChanges, meta: priceChangesMeta },
        },
      ] = await Promise.all([
        this.venueFetchOverviewStatistics(),
        this.venueFetchNewProducts({
          limit: this.limit,
        }),
        this.venueFetchPriceChanges({
          limit: this.limit,
        }),
      ]);

      this.statistics = data;
      this.$emit('updateStats', this.statistics);
      this.meta = meta;
      this.newProducts = newProducts;
      this.priceChanges = priceChanges;
      this.priceChangesMeta = priceChangesMeta;
    },
    openUploadInvoice(type) {
      this.fileType = type;
      this.$refs.uploadInvoice.open();
    },
  },
  async created() {
    await this.fetchData();
  },
};
</script>

<style lang="scss" scoped>
.overview-actions {
  width: fit-content;
  margin-left: auto;
}
.overview {
  display: flex;

  .tables {
    margin-left: 36px;
    width: 100%;

    .table {
      padding: 12px 16px 16px 16px;
      border-radius: 3px;
      border: 1px solid #eceef5;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);

      h2 {
        margin-top: 0;
        margin-bottom: 16px;
        color: $color-gray-6C;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        letter-spacing: 0.15px;
      }

      .empty-state {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__label {
          margin-top: 0;
          margin-bottom: 16px;
          color: $color-gray-6C;
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
          letter-spacing: 0.15px;
        }

        :deep() .ez-empty-state {
          h3 {
            margin: 0;
          }
        }
      }
    }
  }
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.product-info-secondary {
  span {
    color: $color-gray-25;
  }
}

@media (max-width: 1023px) {
  .product-info-secondary {
    display: none;
  }
}

.see-all-action {
  display: flex;
  align-items: center;
  color: $color-gray-6C;
  font-weight: 500;
  justify-content: center;
  text-decoration: none;
  span {
    font-size: 14px;
    line-height: 24px;
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price-change-cell {
  .price-change__amount {
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .price-green {
    :deep() .v-price__price {
      color: $color-primary-green;
    }
  }

  .price-red {
    color: $color-primary-red;
    line-height: 22px;
    :deep() .v-price__price {
      color: $color-primary-red;
    }
  }

  .percent {
    color: $color-gray-6C;
    font-size: 12px;
    line-height: 14px;
    font-weight: normal;
  }
}

.stats :deep() a {
  text-decoration: none;
}

@media (max-width: 575px) {
  .overview {
    flex-direction: column;
    align-items: center;
  }

  .stats {
    margin-bottom: 16px;
    width: 100%;
    :deep() .stats-box {
      width: 100%;
    }
  }
}

:deep() table tbody tr:last-child td {
  padding-bottom: 0;
  border-bottom: none;
}

.no-min-height :deep() div {
  min-height: auto !important;
}

.dropdown-button {
  :deep() .button.button--primary {
    padding-right: 6px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: initial;
    display: inline-block;
  }
  :deep() .button-dropdown.button-dropdown--primary {
    padding-left: 0px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .button.button--primary {
      padding-left: 6px;
      padding-right: 12px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      margin-left: 0;
    }
    .button.button--secondary {
      min-width: initial;
      margin-left: 0;
      justify-content: flex-start;
      border: none;
      background-color: #fff;

      &:hover {
        background-color: #f5f6fa;
      }
    }
  }
}

:deep() table.table tbody tr:hover {
  cursor: default;
  td {
    background-color: transparent;
  }
}
</style>
