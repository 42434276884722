var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"venue-all-suppliers"},[_c('div',{staticClass:"filter-container"},[_c('ez-filter-list',{attrs:{"filters":_vm.filters},on:{"resetFilter":_vm.resetFilters,"filterUpdated":_vm.updateFilters}},[_c('ez-input',{attrs:{"formKey":"filters","label":"search","name":"search","placeholder":_vm.$t('distributors.searchForDistributorPlaceholder'),"data-cy":_vm.outletCy.SUPPLIERS.CURRENT_SUPPLIERS.INPUT__SEARCH},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"search"}})]},proxy:true}])}),_c('ez-select',{ref:"select",staticClass:"input-group",attrs:{"name":"orderingMethod","value":_vm.orderingMethod,"selected":_vm.orderingMethod.id,"options":_vm.orderingMethods}})],1),(_vm.canCreateNewDistributor)?_c('ez-button',{on:{"click":_vm.openConnectModal}},[[_vm._v(_vm._s(_vm.$t('distributors.addNewDistributor')))]],2):_vm._e()],1),(_vm.context.invitationsCount > 0)?_c('ez-alert',{attrs:{"type":"orange"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"exclamation-circle"}})]},proxy:true},{key:"cta",fn:function(){return [_c('router-link',{attrs:{"to":{ name: 'venue-connection-requests' }}},[_c('ez-button',{attrs:{"type":"yellow-link"}},[_vm._v(_vm._s(_vm.$t('distributors.viewAllConnections')))])],1)]},proxy:true}],null,false,3277086197)},[[_vm._v(" "+_vm._s(_vm.$tc('distributors.connectionRequestAlert', _vm.context.invitationsCount))+" ")]],2):_vm._e(),(_vm.suppliers.length)?_c('ez-table',{attrs:{"data":_vm.suppliers,"headers":{
      ...(_vm.groupViewMode
        ? { supplierName: _vm.nameCheckbox }
        : { supplierName: () => _vm.$t('tables.distributorName') }),
      orderingMethod: () => 'Ordering Method',
      productCount: () => _vm.$t('tables.numberOfProducts'),
    },"columnProps":{
      productCount: { class: 'medium-cell' },
      orderingMethod: { class: 'ordering-method-cell' },
      location: { class: 'location-cell' },
    },"columns":[
      'supplierName',
      ...(_vm.groupViewMode ? ['location'] : []),
      'orderingMethod',
      'productCount',
    ]},on:{"rowClick":_vm.goToSingleSupplier},scopedSlots:_vm._u([{key:"cell-supplierName",fn:function({ row }){return [_c('div',{staticClass:"u-flex-row u-flex-center"},[(_vm.groupViewMode)?_c('ez-checkbox',{key:row.id,staticClass:"cursor-pointer mr-8",attrs:{"checked":_vm.isSupplierSelected(row),"stopPropagation":true},on:{"change":function($event){return _vm.onCheckboxChange(row, $event)}}}):_vm._e(),_c('v-distributor-entity-info',{attrs:{"distributor":row,"data-cy":`${_vm.outletCy.SUPPLIERS.CURRENT_SUPPLIERS.TEXT__SUPPLIER_NAME}-${row.id}`}})],1)]}},(_vm.groupViewMode)?{key:"cell-location",fn:function({ row }){return [_c('div',{staticClass:"location-wrapper",on:{"click":function($event){$event.stopPropagation();return _vm.openLocationModal(row)}}},[(row.allConnectedFromGroup)?_c('v-location-badge',{attrs:{"type":"green"}},[_vm._v("All")]):(row.connectedVenueIdsFromGroup.length)?_c('v-location-badge',{attrs:{"type":"yellow"}},[_vm._v("Some")]):_c('v-location-badge',{attrs:{"type":"red"}},[_vm._v("None")]),_c('font-awesome-icon',{staticClass:"location-edit",attrs:{"icon":"pen"}})],1)]}}:null,{key:"cell-orderingMethod",fn:function({ row: { orderingMethods, orderingMethodLabel, id } }){return [(orderingMethods.length)?_c('div',{staticClass:"ordering-method__icons",on:{"click":function($event){$event.stopPropagation();return _vm.openContactsPage(id)}}},_vm._l((orderingMethods),function(orderingMethod){return _c('div',{key:orderingMethod.type,staticClass:"ordering-method__icon"},[(orderingMethod.type === 'sms')?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              placement: 'top',
              content: 'SMS',
            }),expression:"{\n              placement: 'top',\n              content: 'SMS',\n            }"}],attrs:{"icon":['fas', 'sms']}}):_vm._e(),(orderingMethod.type === 'email')?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              placement: 'top',
              content: 'Email',
            }),expression:"{\n              placement: 'top',\n              content: 'Email',\n            }"}],attrs:{"icon":['fas', 'envelope']}}):_vm._e(),(orderingMethod.type === 'whatsApp')?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              placement: 'top',
              content: 'Whatsapp',
            }),expression:"{\n              placement: 'top',\n              content: 'Whatsapp',\n            }"}],attrs:{"icon":['fab', 'whatsapp']}}):_vm._e()],1)}),0):_c('div',{on:{"click":function($event){$event.stopPropagation();return _vm.openContactsPage(id)}}},[_c('span',[_vm._v(_vm._s(orderingMethodLabel))])])]}}],null,true)}):_vm._e(),(_vm.isLoadingMore)?_c('div',{staticClass:"u-text-center mt-12"},[_c('ez-spinner')],1):_vm._e(),(_vm.meta.nextId && !_vm.isLoadingMore)?_c('ez-load-more',{on:{"loadMore":_vm.onLoadMore}}):_vm._e(),_c('select-locations-modal',{ref:"locationsModal",attrs:{"locations":_vm.selectedSupplierLocations,"supplierId":_vm.supplierId,"suppliers":_vm.listOfSupplierIds},on:{"success":_vm.updateSupplierLocations,"close":_vm.onSupplierLocationsClose}}),_c('ez-form-modal',{ref:"connect",staticClass:"new-supplier-modal",on:{"open":_vm.focusOnInput},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Add New "+_vm._s(_vm.$t('global.distributor')))]},proxy:true},{key:"content",fn:function(){return [_c('ez-autocomplete',{ref:"autocomplete",attrs:{"source":`/venue/search/distributors`,"requestParams":{ connected: false },"searchProperty":"name","label":`Search for a ${_vm.$t('global.distributor')}`,"disable-result-prop-name":"differentCurrency","disable-based-on-status":{
          property: 'invitationStatus',
          statuses: ['invitation_sent', 'invitation_received'],
        }},on:{"selected":_vm.onSupplierSelect},scopedSlots:_vm._u([{key:"firstResult",fn:function({ input }){return [_c('li',[_c('ez-entity-info',{attrs:{"imgUrl":_vm.defaultImage,"imgHasBorder":true,"imgBorderRadius":"50%"}},[_c('span',[_vm._v("Create New \""+_vm._s(input)+"\" "+_vm._s(_vm.$t('global.distributor')))])])],1)]}},{key:"result",fn:function({ result }){return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
              content: _vm.tooltipContent(result),
              classes: ['tooltip-general'],
            }),expression:"{\n              content: tooltipContent(result),\n              classes: ['tooltip-general'],\n            }"}],staticClass:"width-100"},[_c('div',{staticClass:"u-flex-space width-100"},[_c('div',{class:[
                  'venue-result',
                  {
                    'venue-result--disabled':
                      result.differentCurrency || _vm.isInvitationSent(result),
                  },
                ]},[_c('img',{staticClass:"venue-result__image",attrs:{"src":result.logo,"alt":""}}),_c('div',{staticClass:"venue-result__info"},[_c('div',{staticClass:"venue-result__title"},[_c('span',{attrs:{"title":result.name}},[_vm._v(_vm._s(result.name))]),(_vm.$helpers.isPremium(result.accountType))?_c('ez-premium-badge'):_vm._e()],1),_c('div',{staticClass:"venue-result__label"},[(result.xeroId)?_c('small',[_vm._v("Xero")]):_c('small',[_vm._v(_vm._s(_vm.oezPlatformName))])])])]),_c('div',{staticClass:"u-flex-row"},[(_vm.isInvitationSent(result))?_c('status-badge',{attrs:{"status":"invitation_sent"}}):_vm._e(),(result.differentCurrency)?_c('status-badge',{attrs:{"status":"currency-no-matching"}}):_vm._e()],1)])])]}}])}),_c('ez-selected-distributor',{attrs:{"distributor":_vm.selectedSupplier},on:{"remove":_vm.removeSelectedSupplier}})]},proxy:true},{key:"footer",fn:function(){return [_c('ez-button',{attrs:{"type":"link"},on:{"click":_vm.closeConnectModal}},[_vm._v("Cancel")]),_c('ez-button',{attrs:{"disabled":!_vm.selectedSupplier,"is-loading":_vm.isLoadingAddNew},on:{"click":_vm.addSelectedSupplier}},[_vm._v(" Add "+_vm._s(_vm.$t('global.distributor')))])]},proxy:true}])}),_c('ez-loader',{attrs:{"show":_vm.isLoading}},[_vm._v("Loading...")]),(_vm.listOfSupplierIds.length)?_c('footer-for-update',{attrs:{"entity":"Suppliers","items-count":_vm.listOfSupplierIds.length},on:{"footerAction":function($event){return _vm.openLocationModal(null, true)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }