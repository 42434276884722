<script>
import { mapState, mapActions } from 'vuex';
import Venue from '@/models/Venue';
import EzOnOff from '@/components/ui/OnOff';
import EzForm from '@/components/ui/Form';
import flash from '@/components/ui/FlashMessage';
import EzInput from '@/components/ui/Input';
import EzButton from '@/components/ui/Button/EzButton.vue';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown.vue';
import { orderingType } from '@/util/constants';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  components: {
    EzOnOff,
    EzForm,
    EzInput,
    EzButton,
    EzDropdown,
  },
  props: {
    venueId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formKey: 'venue-group-settings',
      loadingKeyForm: 'venue-update-group-settings',
      priceChangeNotificationPercent: null,
      shouldUpdateReferencePrice: false,
      type: null,
      email: '',
      orderingType,
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    venue() {
      return Venue.find(this.venueId) || {};
    },
    action() {
      return `/venues/${this.venueId}`;
    },
  },
  async created() {
    await this.fetchVenue(this.venueId);
    this.shouldUpdateReferencePrice = this.venue.shouldUpdateReferencePrice || false;
    this.priceChangeNotificationPercent = this.venue.priceChangeNotificationPercent || null;
    this.type = this.venue.orderingType || null;
    this.email = this.venue.invoiceEmail || '';
  },
  methods: {
    ...mapActions('entities/venues', ['fetchVenue']),
    async saveChanges() {
      try {
        this.$refs.form.onSubmit();
        flash.success({ title: 'Venue settings successfully updated!' });
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
    transformer(data) {
      if (!this.shouldUpdateReferencePrice)
        data = { ...data, shouldUpdateReferencePrice: this.shouldUpdateReferencePrice.toString() };
      return data;
    },
  },
};
</script>
<template>
  <div class="sales-settings container--small">
    <ez-form
      :formKey="formKey"
      :loading-key="loadingKeyForm"
      :transformer="transformer"
      :action="action"
      method="patch"
      ref="form"
    >
      <h4 class="price-change-input__title">Product Settings</h4>
      <div class="price-change-input">
        <h4 class="custom-title mb-8">Default Price Change Notification</h4>
        <div class="price-change-input__info">
          Set the default threshold for notification when a price changes from the Product Reference
          Price.
        </div>
        <div class="u-flex-center price-change-input__box">
          <p class="price-change-input__label">Send a notification if a price changes by</p>
          <ez-input
            :form-key="formKey"
            type="number"
            name="priceChangeNotificationPercent"
            :value="priceChangeNotificationPercent"
            min="0"
            max="100"
            :isTooltipError="true"
          />
          <p class="price-change-input__label">%.</p>
        </div>
      </div>
      <div class="sales-settings__section sales-settings__section--notifications">
        <div class="help-block">
          <h4 class="help-block__title custom-title">Update Product Reference Price</h4>
          <p class="help-block__text mb-0">
            When enabled, the product reference price will update to the price from the latest
            invoice. Otherwise the manually set price will remain the reference price for price
            change notifications.
          </p>
        </div>
        <ez-on-off
          :formKey="formKey"
          name="shouldUpdateReferencePrice"
          :value="shouldUpdateReferencePrice.toString()"
          :checked="shouldUpdateReferencePrice"
          @change="$ev => (shouldUpdateReferencePrice = $ev)"
        />
      </div>
      <hr />
      <h4 class="price-change-input__title">Order Settings</h4>
      <div class="price-change-input">
        <h4 class="custom-title mb-8">Ordering Method</h4>
        <div class="price-change-input__info">
          Simple order includes only product name and quantity. Select Purchase order to include
          price and SKU as well.
        </div>
        <ez-dropdown
          :data="orderingType"
          :form-key="formKey"
          is-full-width
          name="orderingType"
          :selected="type"
          class="mt-12"
        />
      </div>
      <hr />
      <h4 class="price-change-input__title">Invoice Settings</h4>
      <div class="price-change-input">
        <h4 class="custom-title mb-8">Invoice Upload Via Email</h4>
        <div class="price-change-input__info">
          Share this email with your suppliers or forward any email with a PDF or image invoice
          attachment to this email address and we’ll automatically start processing it.
        </div>
        <ez-input
          disabled
          :form-key="formKey"
          is-full-width
          name="invoiceEmail"
          :value="email"
          class="mt-12"
        />
      </div>

      <ez-button class="mt-24" form-type="submit">Save Changes</ez-button>
    </ez-form>
  </div>
</template>

<style lang="scss" scoped>
.custom-title {
  font-weight: 500;
}
.sales-settings {
  &__section {
    display: flex;
    align-items: flex-start;

    &--notifications {
      .help-block {
        margin-right: 16px;
        margin-bottom: 0;
      }
    }

    .help-block {
      &__text {
        @include font-size(14px, 20px);
      }
    }
  }
}
.price-change-input {
  margin-bottom: 1rem;
}

.price-change-input__title {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.price-change-input__info {
  @include font-size(12px);
  color: $color-gray-6C;
  margin: 0;
  line-height: px-to-rem(18px);
}

.price-change-input__box {
  height: 36px;
  margin: 12px 0;

  :deep() .input-group {
    max-width: 56px;
    margin: 0 8px;
    text-align: right;
  }
}
.price-change-input__label {
  @include font-size(14px);
}
</style>
