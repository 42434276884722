<script>
/**
 * Rich Statistic Box
 * @version 1.0.0
 * @since
 */

export default {
  props: {
    backgroundColor: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      required: true,
    },
    accentColor: {
      type: String,
      required: true,
    },
    compactMode: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    css() {
      return {
        '--box-accent-color': this.accentColor,
        '--box-background-color': this.backgroundColor,
        '--box-text-color': this.textColor,
      };
    },
    hasIconSlot() {
      return !this.compactMode;
    },
  },
};
</script>
<template>
  <div :style="css" class="stats-box">
    <div class="stats-box__icon" v-if="hasIconSlot"><slot name="icon" /></div>
    <div class="stats-box__label"><slot name="label" /></div>
    <div class="stats-box__value" v-if="hasIconSlot"><slot name="value" /></div>
    <div class="stats-box__value" v-else><slot name="icon" /> <slot name="value" /></div>
  </div>
</template>
<style lang="scss" scoped>
.stats-box {
  width: 200px;
  padding: 16px;
  background-color: var(--box-background-color);
  border-radius: 3px;
  border: 1px solid var(--box-accent-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: var(--box-text-color);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.15px;
  line-height: 22px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.12);

  &__icon {
    width: 32px;
    height: 32px;
    margin-bottom: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--box-accent-color);
  }

  &__label {
    text-align: center;
    margin-bottom: 7px;
  }

  &__value {
    padding: 8px 20px;
    line-height: 16px;
    font-weight: 700;
    border-radius: 17px;
    background-color: var(--box-accent-color);
  }
}
</style>
