<script>
/**
 * Stock
 * @version 1.0.0
 * @since 2.3.0
 */

import { mapActions, mapGetters } from 'vuex';
import { getContextId, debounce } from '@/util/utils';
import { LOADING_KEY } from '@/util/constants';
import EzTable from '@/components/ui/Table/EzTable.vue';
import EzEntityInfo from '@/components/ui/EntityInfo';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import VPrice from '@/components/v3/elements/VPrice';
import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';
import flash from '@/components/ui/FlashMessage';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzLoadMore from '@/components/ui/LoadMore';
import EzFilterList from '@/components/ui/FilterList';
import EzInput from '@/components/ui/Input';
import VFilterDropdown from '@/components/v3/patterns/VFilterDropdown';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import EzProductGroupFilter from '@/components/ui/Filter/ProductGroup.vue';
import EzCategoryFilter from '@/components/ui/Filter/Category.vue';
import EzButton from '@/components/ui/Button';
import EzSelect from '@/components/ui/Select/EzSelect.vue';
import VDistributorEntityInfo from '@/components/v3/patterns/VDistributorEntityInfo';
import VSelectSearchLocation from '@/components/v3/elements/VSelectSearchLocation';
import { EzConfirmationModal, EzFormModal } from '@/components/ui/Modal';
import VTag from '@/components/v3/elements/VTag';
import EzCsvUploadModal from '@/components/ui/Modal/EzCsvUploadModal.vue';

const FilterArea = {
  List: 'list',
  Dropdown: 'dropdown',
};

export default {
  components: {
    EzTable,
    EzEntityInfo,
    VDataWithInfo,
    VPrice,
    EzMaskInputSimple,
    EzSpinner,
    EzLoadMore,
    EzLoader,
    EzFilterList,
    EzInput,
    VFilterDropdown,
    VSelectSearch,
    EzProductGroupFilter,
    EzCategoryFilter,
    EzButton,
    EzSelect,
    VDistributorEntityInfo,
    VSelectSearchLocation,
    EzConfirmationModal,
    EzFormModal,
    VTag,
    EzCsvUploadModal,
  },
  data() {
    return {
      products: [],
      meta: {},
      dropdownFilters: {},
      listFilters: { term: null },
      inventoryLocations: [],
      productGroups: [],
      distributors: [],
      categories: [],
      FilterArea,
      isEdit: false,
      locationName: '',
      locationId: null,
      countingOrderEdit: null,
      productToAddLocation: null,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    venueId() {
      return getContextId();
    },
    isLoading() {
      return this.getLoading(LOADING_KEY.FETCH_PRODUCTS);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.LOAD_MORE_PRODUCTS);
    },
    productGroupsFilter() {
      return [{ id: null, name: 'All Product Groups' }, ...this.productGroups];
    },
    distributorFilter() {
      return this.distributors;
    },
    inventoryLocationsFilter() {
      return [
        { id: null, name: 'All Locations' },
        { id: '', name: 'Unassigned' },
        ...this.inventoryLocations,
      ];
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'venueFetchInventoryProducts',
      'venueUpdateInventoryProduct',
      'venueFetchProductGroups',
      'venueFetchInventoryProductsTotal',
    ]),
    ...mapActions('entities/venues', [
      'venueFetchDistributorFilterOptions',
      'fetchVenueLocations',
      'addVenueLocation',
      'updateVenueLocation',
      'deleteVenueLocation',
      'venueImportAllStockCSV',
    ]),
    ...mapActions('entities/categories', ['venueFetchCategoriesNew']),
    async fetchProductGroups() {
      const {
        data: { data },
      } = await this.venueFetchProductGroups();
      this.productGroups = data;
    },
    async fetchDistributors() {
      const { data } = await this.venueFetchDistributorFilterOptions({
        venueId: this.venueId,
        query: { for: 'products' },
      });
      this.distributors = data.data;
    },
    async fetchCategoriesFilter() {
      const {
        data: { data },
      } = await this.venueFetchCategoriesNew();
      this.categories = data;
    },
    async fetchLocations() {
      const {
        data: { data },
      } = await this.fetchVenueLocations({ venueId: this.venueId });
      this.inventoryLocations = data;
    },
    async fetchInventoryProductsTotal(query = {}, loadingKey = LOADING_KEY.FETCH_PRODUCTS) {
      try {
        const {
          data: { data },
        } = await this.venueFetchInventoryProductsTotal({
          venueId: this.venueId,
          loadingKey,
          filters: query,
        });
        this.$emit('productTotalUpdate', data);
      } catch (e) {
        flash.error({
          title: 'Something went wrong',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
    async fetchInventoryProducts(query = {}, loadingKey = LOADING_KEY.FETCH_PRODUCTS) {
      try {
        const {
          data: { data, meta },
        } = await this.venueFetchInventoryProducts({
          venueId: this.venueId,
          ...this.meta,
          loadingKey,
          filters: query,
        });

        if (loadingKey === LOADING_KEY.FETCH_PRODUCTS) {
          this.products = data;
        } else {
          this.products = [...this.products, ...data];
        }
        this.meta = meta;
        this.$emit('productCountUpdate', this.meta.totalCount);
      } catch (e) {
        flash.error({
          title: 'Something went wrong',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
    async onLoadMoreClick() {
      await this.fetchInventoryProducts(this.dropdownFilters, LOADING_KEY.LOAD_MORE_PRODUCTS);
    },
    updateProductParLevel: debounce(async function deb(row, event) {
      if ((row.parLevel || 0) === event) return;
      try {
        await this.venueUpdateInventoryProduct({
          venueId: this.venueId,
          productId: row.id,
          body: {
            parLevel: event,
          },
        });
        this.products = this.products.map(item => {
          if (item.id === row.id)
            return {
              ...item,
              parLevel: event,
            };
          return item;
        });
      } catch (e) {
        flash.error({
          title: 'Something went wrong',
          message: e.response?.data?.error?.message || '',
        });
      }
    }, 300),
    updateFilters: debounce(async function deb(area, event) {
      if (area === FilterArea.List && event[0] !== 'locationId') {
        const [filterName, value] = event;
        this.listFilters = { ...this.listFilters, [filterName]: value };
      } else if (area === FilterArea.Dropdown || event[0] === 'locationId') {
        this.dropdownFilters = {
          ...this.dropdownFilters,
          ...(typeof event.categoryId === 'object' &&
            event.categoryId !== null && { categoryId: event.categoryId.id }),
          ...(typeof event.distributorId === 'object' &&
            event.distributorId !== null && { distributorId: event.distributorId.id }),
          ...(typeof event.productGroupId === 'object' &&
            event.productGroupId !== null && { productGroupId: event.productGroupId.id }),
          ...(typeof event.locationId === 'object' &&
            event.locationId !== null && { locationId: event.locationId.id }),
          ...(event[0] === 'locationId' && { locationId: event[1].id }),
        };
        if (event.distributorId === undefined) delete this.dropdownFilters.distributorId;
        this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());
      }
      this.meta = {
        nextId: null,
        nextValue: null,
        ...(this.listFilters.term && { term: this.listFilters.term }),
      };
      await this.fetchInventoryProducts(this.dropdownFilters);
      await this.fetchInventoryProductsTotal(this.dropdownFilters);
    }, 300),
    async resetFilters(area) {
      if (area === FilterArea.List) {
        Object.keys(this.listFilters).forEach(key => {
          this.listFilters[key] = null;
        });
      } else if (area === FilterArea.Dropdown) {
        this.dropdownFilters = {};
      }
      this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());
      this.meta = {};
      await this.fetchInventoryProducts();
      await this.fetchInventoryProductsTotal();
    },
    uploadCSVModal() {
      this.$refs.csvUploadModal.open();
    },
    async onUploadFinished() {
      this.meta = {};
      await this.fetchInventoryProducts(this.dropdownFilters);
      await this.fetchInventoryProductsTotal(this.dropdownFilters);
    },
    uploadAction(data) {
      return this.venueImportAllStockCSV({
        body: data,
        venueId: this.venueId,
      });
    },
    openLocationModal(id) {
      this.$refs.modal.open();
      this.productToAddLocation = id;
    },
    onClose() {
      this.isEdit = false;
      this.locationName = '';
      this.productToAddLocation = null;
      this.$refs.modal.close();
    },
    async addOrEditLocation() {
      try {
        if (!this.isEdit) {
          const { data } = await this.addVenueLocation({
            venueId: this.venueId,
            data: { name: this.locationName },
          });
          this.inventoryLocations.push(data.data);
          this.products = this.products.map(item => {
            if (item.id === this.productToAddLocation)
              return {
                ...item,
                venueProductLocations: [
                  ...item.venueProductLocations,
                  {
                    location: data.data,
                    positions: null,
                  },
                ],
              };
            return item;
          });
          flash.success({ title: 'Location successfully added!' });
        } else {
          const { data } = await this.updateVenueLocation({
            venueId: this.venueId,
            locationId: this.locationId,
            data: { name: this.locationName },
          });
          const locIndex = this.inventoryLocations.findIndex(item => item.id === this.locationId);
          this.inventoryLocations[locIndex] = data.data;
          flash.success({ title: 'Location successfully updated!' });
        }
        this.onClose();
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
    onEdit(row) {
      this.isEdit = true;
      this.locationName = row.name;
      this.locationId = row.id;
      this.$refs.modal.open();
    },
    onRemove(row) {
      this.locationId = row.id;
      this.$refs.deleteModal.open();
    },
    closeDeleteModal() {
      this.$refs.deleteModal.close();
      this.locationId = null;
    },
    async confirmDeleteLocation() {
      try {
        await this.deleteVenueLocation({
          venueId: this.venueId,
          locationId: this.locationId,
        });
        this.inventoryLocations = this.inventoryLocations.filter(
          item => item.id !== this.locationId,
        );
        this.products = this.products.map(item => {
          if (
            item.venueProductLocations.findIndex(loc => loc.location.id === this.locationId) !== -1
          ) {
            return {
              ...item,
              venueProductLocations: item.venueProductLocations.filter(
                loc => loc.location.id !== this.locationId,
              ),
            };
          }
          return item;
        });
        this.closeDeleteModal();
        flash.success({
          title: 'Location successfully deleted!',
        });
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
    updateProductLocations: debounce(async function deb(id) {
      const product = this.products.filter(item => item.id === id)[0];
      const locationIds = [];
      product.venueProductLocations.forEach(location => {
        locationIds.push(location.location.id);
      });
      try {
        await this.venueUpdateInventoryProduct({
          venueId: this.venueId,
          productId: id,
          body: {
            locationIds,
          },
        });
      } catch (e) {
        flash.error({
          title: 'Something went wrong',
          message: e.response?.data?.error?.message || '',
        });
      }
    }, 1000),
    async onSelectProductLocation(event, id) {
      const product = this.products.filter(item => item.id === id)[0];
      const locationIds = [];
      product.venueProductLocations.forEach(location => {
        locationIds.push(location.location.id);
      });
      const idx = locationIds.findIndex(item => item === event.id);
      this.products = this.products.map(item => {
        if (item.id === id)
          if (idx !== -1) {
            return {
              ...item,
              venueProductLocations: [
                ...item.venueProductLocations.filter(loc => loc.location.id !== event.id),
              ],
            };
          } else
            return {
              ...item,
              venueProductLocations: [
                ...item.venueProductLocations,
                {
                  location: event,
                  positions: null,
                },
              ],
            };

        return item;
      });
      await this.updateProductLocations(id);
    },
    productHasPositions(row) {
      return row.venueProductLocations.filter(
        item => item.location.id === this.dropdownFilters.locationId,
      )[0]?.positions?.length;
    },
    positions(row) {
      return row.venueProductLocations.filter(
        item => item.location.id === this.dropdownFilters.locationId,
      )[0]?.positions;
    },
    updatePositions: debounce(async function deb(row, event) {
      if (/^[0-9,.\s]*$/.test(event) === false) return;
      const positions = event
        .replaceAll(' ', '')
        .split(',')
        .filter(item => item);
      try {
        await this.venueUpdateInventoryProduct({
          venueId: this.venueId,
          productId: row.id,
          body: {
            positionsLocationId: this.dropdownFilters.locationId,
            positions,
          },
        });
        const productIndex = this.products.findIndex(item => item.id === row.id);
        const locationIndex = this.products[productIndex].venueProductLocations.findIndex(
          loc => loc.location.id === this.dropdownFilters.locationId,
        );
        this.products[productIndex].venueProductLocations[locationIndex] = {
          ...this.products[productIndex].venueProductLocations[locationIndex],
          positions,
        };
        this.countingOrderEdit = null;
        this.$forceUpdate();
      } catch (e) {
        flash.error({
          title: 'Something went wrong',
          message: e.response?.data?.error?.message || '',
        });
      }
    }, 1000),
    editCountingOrder(id) {
      this.countingOrderEdit = id;
    },
    positionsString(row) {
      return (
        row.venueProductLocations
          .filter(item => item.location.id === this.dropdownFilters.locationId)[0]
          ?.positions?.toString() || ''
      );
    },
  },
  async created() {
    await Promise.all([
      this.fetchInventoryProducts(),
      this.fetchInventoryProductsTotal(),
      this.fetchProductGroups(),
      this.fetchCategoriesFilter(),
      this.fetchDistributors(),
      this.fetchLocations(),
    ]);
  },
};
</script>

<template>
  <div class="inventory-products">
    <div class="inventory-products__actions mt-24">
      <ez-filter-list
        :filters="listFilters"
        @filterUpdated="(...$event) => updateFilters(FilterArea.List, $event)"
        @resetFilter="resetFilters(FilterArea.List)"
        class="mr-16"
      >
        <ez-input
          formKey="filters"
          label="search"
          name="term"
          class="search"
          placeholder="Search for a Product"
        >
          <template #prefix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
        <ez-select
          class="input-group locations-filter"
          name="locationId"
          :value="dropdownFilters.locationId"
          :selected="dropdownFilters.locationId"
          :options="inventoryLocationsFilter"
        />
      </ez-filter-list>
      <v-filter-dropdown
        :filters="dropdownFilters"
        @filterUpdated="$event => updateFilters(FilterArea.Dropdown, $event)"
        @resetFilters="resetFilters(FilterArea.Dropdown)"
        ref="filtersGroup"
      >
        <template #firstRow>
          <ez-product-group-filter
            name="productGroupId"
            label="Product Group"
            :productGroups="productGroupsFilter"
            :selected="dropdownFilters.productGroupId"
            isFullWidth
          />
          <v-select-search
            class="select-supplier"
            :selected="dropdownFilters.distributorId"
            formKey="filters"
            name="distributorId"
            :label="`${$t('global.distributor')}`"
            :data="distributorFilter"
            :placeholder="`All ${$t('global.distributors')}`"
            :searchPlaceholder="`Select ${$t('global.distributor')}`"
            align-left
            is-full-width
          >
            <template #result="{ result }">
              <v-distributor-entity-info :distributor="result" />
            </template>
          </v-select-search>
          <ez-category-filter
            name="categoryId"
            label="Category"
            :categories="categories"
            :selected="dropdownFilters.categoryId"
            isFullWidth
          />
          <ez-select
            class="input-group product-group-filter"
            name="locationId"
            :value="dropdownFilters.locationId"
            :selected="dropdownFilters.locationId"
            label="Location"
            :options="inventoryLocationsFilter"
            isFullWidth
          />
        </template>
      </v-filter-dropdown>
      <ez-button type="secondary" @click="uploadCSVModal" class="import-action"
        >Import CSV</ez-button
      >
    </div>
    <ez-table
      class="mt-32"
      v-if="products.length"
      :data="products"
      :headers="{
        name: () => 'Product',
        distributor: () => `${$t('global.distributor').toLowerCase()}`,
        countingOrder: () => `${dropdownFilters.locationId ? 'Counting Order' : 'Location'}`,
      }"
      :columns="[
        'name',
        'distributor',
        'unit',
        'lastStocktake',
        'quantity',
        'parLevel',
        'value',
        'countingOrder',
      ]"
      :columnProps="{
        value: { class: 'price-cell' },
        distributor: { class: 'small-cell' },
        unit: { class: 'medium-cell' },
        lastStocktake: { class: 'medium-cell' },
        quantity: { class: 'small-cell' },
        parLevel: { class: 'medium-cell' },
        countingOrder: { class: 'large-cell product-location-cell' },
      }"
    >
      <template #cell-name="{ row }">
        <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="row.image">
          <div class="product-info" :title="row.name">
            <span>
              {{ row.name }}
            </span>
            <span class="product-info-secondary">
              <span class="product-info-secondary--category">
                {{ row.category | categoryWithParent }}
              </span>
              <span v-if="row.sku" class="product-info-secondary--sku">
                &#8226; {{ row.sku }}
              </span>
            </span>
          </div>
        </ez-entity-info>
      </template>
      <template #cell-distributor="{ row }">
        <ez-entity-info
          v-if="row.distributor"
          imgWidth="2.125rem"
          imgHeight="2.125rem"
          :imgUrl="row.distributor.logo"
          imgBorderRadius="50%"
          v-tooltip="{
            placement: 'start',
            content: row.distributor.name,
            classes: ['tooltip--reset-margin'],
          }"
        />
      </template>
      <template #cell-unit="{ row: { orderingUnit } }">
        <template v-if="orderingUnit">
          <v-data-with-info
            :info="
              orderingUnit.abbreviation
                ? `${orderingUnit.name} ${!!$t(`product.unitInfo.${orderingUnit.type}`) ? '-' : ''}
            ${$t(`product.unitInfo.${orderingUnit.type}`)}`
                : ''
            "
            :show-underline="
              !!$t(`product.unitInfo.${orderingUnit.type}`) || !!orderingUnit.abbreviation
            "
          >
            {{ orderingUnit.label }}
          </v-data-with-info>
        </template>
      </template>
      <template #cell-lastStocktake="{ row: { lastStocktakeAt, stocktakeOverdue } }">
        <span v-if="lastStocktakeAt" :class="{ overdue: stocktakeOverdue }">
          {{ lastStocktakeAt | date }}
        </span>
        <span v-else>-</span>
      </template>
      <template #cell-parLevel="{ row }">
        <ez-mask-input-simple
          name="parLevel"
          :minValue="0"
          :value="row.parLevel || 0"
          @input="updateProductParLevel(row, $event)"
          :precision="0"
          :has-currency="false"
        />
      </template>
      <template #cell-value="{ row: { value } }">
        <v-price :price="value || 0" />
      </template>
      <template #cell-countingOrder="{ row }">
        <div v-if="dropdownFilters.locationId">
          <ez-input
            v-if="!productHasPositions(row) || countingOrderEdit === row.id"
            name="order"
            placeholder="Add Order"
            form-key="order"
            :value="positionsString(row) || null"
            @onChange="updatePositions(row, $event)"
            :validators="{ regex: /^[0-9,.\s]*$/ }"
          ></ez-input>
          <div v-else class="counting-order-items" @click="editCountingOrder(row.id)">
            <v-tag
              color="#983BBD"
              backgroundColor="#EEDFF4"
              class="ml-4"
              v-for="pos in positions(row)"
              :key="pos"
              >{{ pos }}</v-tag
            >
          </div>
        </div>
        <v-select-search-location
          v-else
          ref="selectLocation"
          :data="inventoryLocations"
          :selectedData="row.venueProductLocations"
          name="locations"
          placeholder="Select Location"
          searchProperty="name"
          @select="event => onSelectProductLocation(event, row.id)"
          @addLocation="openLocationModal(row.id)"
          @edit="onEdit"
          @remove="onRemove"
        />
      </template>
    </ez-table>
    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>
    <ez-loader :show="isLoading">Loading...</ez-loader>
    <ez-load-more
      v-if="meta.nextId && !isLoadingMore && !isLoading"
      @loadMore="onLoadMoreClick"
    ></ez-load-more>

    <ez-form-modal ref="modal" @close="onClose">
      <template #title>{{ isEdit ? 'Edit ' : 'Add ' }}Location</template>
      <template #content>
        <ez-input
          name="locationName"
          label="Name"
          :value="locationName"
          @onChange="locationName = $event"
          placeholder="Enter Location Name"
          formKey="create-update-location"
        />
      </template>
      <template #footer>
        <ez-button type="link" formType="button" @click="onClose">Cancel</ez-button>
        <ez-button @click="addOrEditLocation" :disabled="!locationName" type="primary">{{
          isEdit ? 'Save Changes' : 'Add Location'
        }}</ez-button>
      </template>
    </ez-form-modal>

    <ez-confirmation-modal ref="deleteModal" type="red">
      <template #title>Remove Location?</template>
      <template #content>
        <p>This location will be deleted from the list.</p>
      </template>
      <template #footer>
        <ez-button @click="closeDeleteModal" type="link">Cancel</ez-button>
        <ez-button @click="confirmDeleteLocation" type="red">Remove Location</ez-button>
      </template>
    </ez-confirmation-modal>

    <ez-csv-upload-modal
      ref="csvUploadModal"
      :uploadCSV="uploadAction"
      :onUploadFinished="onUploadFinished"
      venue-all-stock
      :stocktakeTemplateUrl="`venues/${venueId}/inventory-products/import-template`"
    />
  </div>
</template>

<style lang="scss" scoped>
.inventory-products {
  .overdue {
    color: $color-primary-red;
  }

  :deep() table.table tbody tr:hover {
    cursor: default;
  }
  :deep() table.table tbody tr td.price-cell,
  :deep() table.table thead tr th.price-cell {
    text-align: left;

    .v-price--column {
      align-items: flex-start;
    }
  }

  :deep() .table {
    .mask-input-wrapper .mark-input--inline .mask-input__input {
      color: $color-primary-blue;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.25;
      padding-left: 2px;
    }
    .product-location-cell {
      overflow: visible;

      .select-search__wrapper {
        text-align: right;
        cursor: default;
      }

      .select-search__wrapper .select-search__value {
        color: $color-gray-6C;
      }

      .select-search__wrapper .select-search__trigger {
        display: inline-block;
        font-size: 11px;
        font-size: 0.6875rem;
        max-width: 100%;
        padding: 0 6px;
        color: #6c7995;
        font-weight: 500;
        line-height: 1.2;
        border: 1px solid #eceef5;
        background-color: #eceef5;
        border-radius: 2px;
        text-transform: uppercase;
        overflow-x: hidden;
        text-overflow: ellipsis;
        height: auto;
        min-width: auto;
        width: auto;

        &.focused {
          border: 1px solid #4d7cfe;
          background-color: #fff;
        }

        span {
          margin-right: 6px;
        }
      }

      .product-group--selected .select-search__wrapper .select-search__trigger {
        color: $color-gray-6C;
        border: 1px solid #dee1e4;
        background: white;
      }
      .select-search__wrapper .select-search__dropdown {
        width: 320px;
      }
      .counting-order-items {
        white-space: initial;
      }
    }

    .v-tag {
      font-weight: bold;
    }
  }
  .product-info {
    display: flex;
    flex-direction: column;

    & > span {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .product-info-secondary {
      &--category {
        color: $color-gray-25;
        font-weight: 600;
      }
    }
  }

  .product-info.responsive-info {
    .product-info-secondary {
      display: none;
    }

    @media (max-width: 1420px) {
      .product-info-secondary {
        display: block;
      }

      :deep() .table {
        .sku-cell,
        .category-cell {
          display: none;
        }
      }
    }

    @media (max-width: 1023px) {
      .product-info-secondary {
        display: none;
      }
    }
  }

  .inventory-products__actions {
    display: flex;

    .select-supplier {
      :deep() .select-search__trigger {
        width: 100%;
        color: $color-gray-6C;
      }
    }
    :deep() .ez-select__dropdown {
      width: max-content;
      min-width: 100%;
    }
    :deep() .ez-select__display-container {
      height: 36px;
      svg {
        color: $color-gray-6C;
      }
    }

    .locations-filter {
      width: auto;
      :deep() .ez-select__display {
        padding-right: 12px;
      }
    }

    :deep() .dropdown-filters {
      .input-group {
        margin: 0;
      }

      .filters-area__row:not(:first-child) {
        justify-content: start;
      }
    }

    :deep() .ez-filter-list {
      &__items {
        margin: 0;
      }
    }

    .button-dropdown {
      margin-left: auto;
    }
  }
}

.import-action {
  margin-left: auto;
}
</style>
