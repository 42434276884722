<template>
  <ez-user-list
    :users="users"
    :columns="['name', 'email', 'phone', 'type']"
    :headers="{
      accountName: () => $t('global.venue'),
      type: () => 'Contact Type',
    }"
    @addUser="onAddUser"
    @userClick="onUserClick"
    @removeUser="onRemoveUser"
  >
    <user-modal
      entity="Contact"
      ref="userModal"
      :formKey="formKey"
      :formAction="formAction"
      :additionalData="{ type: 'subscriber' }"
      :user="selectedUser"
      :isEditable="selectedUser.owned"
      :has-at-least-one-default-primary="hasAtLeastOneDefaultPrimary"
      @success="onSubmitSuccess"
    >
    </user-modal>
    <template #cta>Add Contact</template>
  </ez-user-list>
</template>

<script>
/**
 * VenueUsers
 * @version 1.0.0
 * @since 2.3.0
 */

import { mapActions, mapState } from 'vuex';
import EzUserList from '@/views/common/users';
import flash from '@/components/ui/FlashMessage';
import UserModal from '@/views/platform/venue/suppliers/all/UserModal';

export default {
  components: {
    EzUserList,
    UserModal,
  },
  props: {
    supplierId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formKey: 'venue-group-user',
      selectedUser: {},
      roles: [],
      users: [],
      hasAtLeastOneDefaultPrimary: false,
    };
  },
  computed: {
    ...mapState('entities/users', ['contextId']),
    formAction() {
      return `/venue/distributors/${this.supplierId}/users`;
    },
    isChecked() {
      return this.selectedUser?.primary;
    },
  },
  methods: {
    async getUsers() {
      const { data } = await this.venueFetchDistributorUsers({
        venueId: this.contextId,
        id: this.supplierId,
      });
      this.users = data.data;
      this.hasAtLeastOneDefaultPrimary = !!this.users.find(usr => usr.primaryByDefault);
    },
    openModal() {
      this.$refs.userModal.open();
    },
    closeModal() {
      this.$refs.userModal.close();
    },
    onAddUser() {
      this.selectedUser = {};
      this.openModal();
    },
    async onUserClick({ id }) {
      const {
        data: { data },
      } = await this.venueFetchDistributorUser({
        venueId: this.contextId,
        id: this.supplierId,
        userId: id,
      });
      this.selectedUser = data;
      this.openModal();
    },
    onSubmitSuccess({ isUpdate }) {
      this.getUsers();
      flash.success({ title: `User successfully ${isUpdate ? 'updated' : 'added'}.` });
      this.closeModal();
    },
    async onRemoveUser({ id }) {
      await this.venueRemoveDistributorUser({
        venueId: this.contextId,
        id: this.supplierId,
        userId: id,
      });
      await this.getUsers();
    },
    ...mapActions('entities/users', ['removeUser']),
    ...mapActions('entities/distributors', [
      'venueFetchDistributorUsers',
      'venueFetchDistributorUser',
      'venueRemoveDistributorUser',
    ]),
  },
  created() {
    this.getUsers();
  },
};
</script>
