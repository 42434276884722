<script>
import { mapMutations } from 'vuex';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import {
  UNIT_TYPE_FRACTIONAL,
  UNIT_TYPE_PACK,
  UNIT_TYPE_SINGLE,
  UNIT_TYPES,
} from '@/util/constants';
import { capitalizeWord } from '@/util/utils';
import { EzFormModal } from '@/components/ui/Modal';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput';
import EzButton from '@/components/ui/Button/EzButton';
import EzInput from '@/components/ui/Input/EzInput';
import EzDropdown from '@/components/ui/Dropdown/EzDropdown';
import { editUnit, createUnit } from '@/components/v3/patterns/VUnitsSettings/UnitSettingsActions';

/**
 *
 * @version 1.0.0
 * @since 3.13.1
 */
export default {
  name: 'AddUnitModal',
  components: {
    EzFormModal,
    EzButton,
    EzInput,
    EzDropdown,
    EzMaskInput,
  },
  props: {
    unit: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      formKey: 'add-new-unit',
      selectedType: UNIT_TYPE_SINGLE,
      name: '',
      abbreviation: '',
      unitQuantity: 1,
      customizable: false,
      venueId: null,
      commonCy,
    };
  },
  computed: {
    types() {
      return UNIT_TYPES.map(ty => ({ id: ty, name: capitalizeWord(ty) }));
    },
    isPackSelected() {
      return this.selectedType === UNIT_TYPE_PACK;
    },
    isFractionalSelected() {
      return this.selectedType === UNIT_TYPE_FRACTIONAL;
    },
    data() {
      return {
        ...(this.name && { name: this.name }),
        ...(this.venueId && { venueId: this.venueId }),
        ...(this.selectedType && { type: this.selectedType }),
        ...(this.abbreviation && { abbreviation: this.abbreviation }),
        ...(this.isPackSelected && this.unitQuantity && { quantity: this.unitQuantity }),
      };
    },
    disabled() {
      if (this.isFractionalSelected) return !this.name || !this.abbreviation || !this.selectedType;
      return !this.name || !this.selectedType;
    },
    isEdit() {
      return !!this.unit?.id;
    },
  },
  methods: {
    ...mapMutations('errors', ['CLEAR_ERRORS']),
    open() {
      this.$refs.addUnitModal.open();
    },
    close() {
      this.$refs.addUnitModal.close();
    },
    onClose() {
      this.CLEAR_ERRORS({ key: this.formKey });
      this.$emit('onClose');
      this.selectedType = UNIT_TYPE_SINGLE;
      this.name = '';
      this.abbreviation = '';
      this.unitQuantity = 1;
    },
    onTypeChange(type) {
      this.selectedType = type.id;
      this.unitQuantity = 1;
    },
    onNameChange(val) {
      this.name = val;
    },
    onAbbreviationChange(val) {
      this.abbreviation = val;
    },
    onUnitQuantityChange(val) {
      this.unitQuantity = Number(val);
    },
    handleResponse(res) {
      this.$emit(res.message);
      if (res.code >= 200 && res.code < 300) {
        this.close();
      }
    },
    async addNewUnit() {
      if (this.isEdit) {
        const res = await editUnit({
          unitId: this.unit.id,
          data: this.data,
          formKey: this.formKey,
        });
        this.handleResponse(res);
        return;
      }
      const res = await createUnit({ data: this.data, formKey: this.formKey });
      this.handleResponse(res);
    },
  },
  watch: {
    unit(val) {
      this.selectedType = val.type;
      this.name = val.name;
      this.abbreviation = val.abbreviation;
      this.unitQuantity = val.quantity;
      this.venueId = val.venueId || null;
    },
  },
};
</script>

<template>
  <ez-form-modal class="unit-modal" ref="addUnitModal" @close="onClose">
    <template #title>{{ isEdit ? 'Edit Unit' : 'Add New Unit' }}</template>
    <template #content>
      <p v-if="!isEdit" class="subtitle">
        Choose what information you would like to change for selected <br />products.
      </p>
      <hr v-if="!isEdit" />
      <ez-input
        :form-key="formKey"
        name="name"
        placeholder="Enter Unit Name"
        label="Unit Name"
        @onChange="onNameChange"
        :value="name"
        :data-cy="commonCy.PRODUCTS.ADD_UNIT_MODAL.INPUT__NAME"
      />
      <ez-input
        class="mt-12"
        :form-key="formKey"
        name="abbreviation"
        placeholder="Enter Unit Abbreviation"
        label="Unit Abbreviation"
        @onChange="onAbbreviationChange"
        :value="abbreviation"
        :data-cy="commonCy.PRODUCTS.ADD_UNIT_MODAL.INPUT__ABBREVIATION"
      />
      <ez-dropdown
        class="mt-12"
        name="type"
        :form-key="formKey"
        :data="types"
        label="Unit Type"
        placeholder="Select Unit Type"
        is-full-width
        @change="onTypeChange"
        :selected="selectedType"
        :disabled="isEdit"
        :data-cy="commonCy.PRODUCTS.ADD_UNIT_MODAL.BUTTON__TYPE"
      />
      <ez-mask-input
        v-if="isPackSelected"
        :precision="0"
        class="mask-input mt-12"
        :price-prefix="false"
        :form-key="formKey"
        name="quantity"
        label="Default Pack Size"
        :value="unitQuantity"
        type="input"
        @input="onUnitQuantityChange"
        :data-cy="commonCy.PRODUCTS.ADD_UNIT_MODAL.INPUT__QUANTITY"
      />
    </template>
    <template #footer>
      <ez-button
        type="link"
        formType="button"
        @click="close"
        :data-cy="commonCy.PRODUCTS.ADD_UNIT_MODAL.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        :disabled="disabled"
        @click="addNewUnit"
        type="primary"
        :data-cy="commonCy.PRODUCTS.ADD_UNIT_MODAL.BUTTON__SAVE"
      >
        {{ isEdit ? 'Save Changes' : 'Add New Unit' }}
      </ez-button>
    </template>
  </ez-form-modal>
</template>

<style lang="scss" scoped>
.subtitle {
  @include font-size(14px, 20px);
  color: $color-gray-25;
  margin: 0;
}
.label {
  color: $color-gray-6C;
  @include font-size(12px, 18px);
  margin-bottom: 6px;
}
.market-price {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  :deep() .input-group {
    height: auto;
  }
}
.info-circle {
  color: #6c7995;
}
.mask-input {
  :deep() .mask-input__input {
    font-weight: normal;
  }
}
.unit-modal {
  @include z-index('modal', 10);
  :deep() {
    .modal {
      @include z-index('modal', 20);
      .modal__inner {
        .modal__text {
          overflow-y: inherit;
        }
      }
    }
  }
}
</style>
