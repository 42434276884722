<template>
  <ez-confirmation-modal ref="approveModal">
    <template #title>Reorder?</template>
    <template #content>
      <ez-alert variant="disclaimer" size="big" v-if="isSomeUnavailable || isAllUnavailable">
        <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
        <p :data-cy="commonCy.SINGLE_ORDER.STANDING_ORDER.REORDER_MODAL.TEXT__WARNING">
          <template v-if="isAllUnavailable">
            The products from this order are not available in the expected quantities, or the
            {{ $t('global.distributor') | lowercase }} has made them unavailable. They won’t be put
            automatically in the cart, but you can try adding them manually from the Products tab.
          </template>
          <template v-else>
            Some of the products from this order are not available in the expected quantities or the
            {{ $t('global.distributor') | lowercase }} has made them unavailable. They won’t be put
            automatically in the cart, but you can try adding them manually from the Products tab:
          </template>
        </p>
        <ul v-if="isSomeUnavailable" class="missing-products-list m-0 pl-0">
          <li v-for="{ id, name } in unavailableProducts" :key="id">{{ name }}</li>
        </ul>
      </ez-alert>
      <p>
        Ordering this order again will put all the products from the order to your cart. From there
        you’ll be able to go through everything and make changes if needed.
      </p>
    </template>
    <template #footer>
      <ez-button
        @click="close"
        type="link"
        :data-cy="commonCy.SINGLE_ORDER.STANDING_ORDER.REORDER_MODAL.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        @click="reorder"
        :disabled="isAllUnavailable"
        :data-cy="commonCy.SINGLE_ORDER.STANDING_ORDER.REORDER_MODAL.BUTTON__ADD_TO_CART"
      >
        Add to Cart
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<script>
import { mapMutations } from 'vuex';
import { isIncluded } from '@/util/utils';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button';
import flash from '@/components/ui/FlashMessage';
import EzAlert from '@/components/ui/Alert/EzAlert';

/**
 * Reorder
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    EzConfirmationModal,
    EzButton,
    EzAlert,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      commonCy,
    };
  },
  computed: {
    products() {
      return this.order.orderedProducts || [];
    },
    unavailableProducts() {
      return this.products.filter(pr => !pr.available && isIncluded(pr));
    },
    isSomeUnavailable() {
      return this.products.some(pr => !pr.available);
    },
    isAllUnavailable() {
      const availableProducts = this.products.filter(pr => pr.available);
      return availableProducts.length === 0;
    },
    hasNotes() {
      return (this.order.notes || []).filter(n => !!n.message || (n.images || []).length).length;
    },
  },
  methods: {
    ...mapMutations('cart', ['ADD_TO_CART']),
    open() {
      this.$refs.approveModal.open();
    },
    close() {
      this.$refs.approveModal.close();
    },
    reorder() {
      // Insert each available product to cart
      this.products.forEach(product => {
        if (product.available) {
          this.ADD_TO_CART({
            product: {
              ...product,
              distributor: this.order.distributor,
              id: product.productId,
              internalId: product.id,
            },
            quantity: Number.parseFloat(product.quantity),
            price: product.totalPrice,
          });
        }
      });

      this.close();
      this.$emit('success');

      const { href } = this.$router.resolve({ name: 'venue-shopping-cart' });
      flash.success({
        title: 'Products successfully re-added to cart!',
        message: 'You can go to cart to check all the items you added and complete the order.',
        ctas: `<a href="${href}">Go to Cart</a>`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() .alert {
  margin-bottom: 0;

  p {
    font-weight: 400;
    letter-spacing: 0;
    @include font-size(14px, 16px);
  }
}

.missing-products-list li {
  list-style-type: none;
}
</style>
