<template>
  <div class="venue-products">
    <ez-product-list
      :headers="{
        name: () => 'Product',
        price: () => 'Price per unit',
        distributor: () => `${$t('global.distributor').toLowerCase()}`,
      }"
      :columns="[
        'name',
        'distributor',
        'unit',
        'price',
        ...(meta.showAvailability ? ['availability'] : []),
      ]"
      :columnProps="{
        name: { class: 'medium-cell' },
        unit: { class: 'medium-cell unit-cell' },
        price: { class: 'price-cell price-cell-overflow' },
        availability: { class: 'text-right-cell medium-cell' },
        distributor: { class: 'distributor-cell' },
      }"
      :products="productsData"
      :hasQuickAdd="$permission.has('createOrder')"
      :responsiveInfo="false"
      @rowClick="openDrawerAndLoadProduct"
    >
      <template #filters>
        <div class="filters-row">
          <ez-filter-list
            :filters="listFilters"
            @filterUpdated="updateFilters"
            @resetFilter="resetFilters(FilterArea.List)"
            class="venue-products__filters"
          >
            <ez-input
              formKey="filters"
              label="search"
              name="term"
              class="search"
              placeholder="Search for a Product"
              :data-cy="outletCy.PRODUCTS.GENERAL.INPUT__SEARCH"
            >
              <template #prefix>
                <font-awesome-icon icon="search" />
              </template>
            </ez-input>
            <v-select-search
              class="ml-16"
              formKey="filters"
              name="distributorId"
              :search="distributorFilterUrl"
              :selected="dropdownFilters.distributorId"
              :request-params="{ connected: true, limit: undefined }"
              :data="distributors"
              :placeholder="`All ${$t('global.distributors')}`"
              :searchPlaceholder="`Select ${$t('global.distributor')}`"
              align-left
              :data-cy="outletCy.PRODUCTS.GENERAL.BUTTON__SUPPLIER_FILTER"
              :min-length="3"
            >
              <template #result="{ result }">
                <v-distributor-entity-info :distributor="result" />
              </template>
            </v-select-search>
            <ez-product-group-filter
              class="ml-16"
              name="productGroupId"
              label="Product Group"
              :productGroups="productGroupsFilter"
              :selected="dropdownFilters.productGroupId"
            />
          </ez-filter-list>
          <v-filter-dropdown
            :filters="dropdownFilters"
            @filterUpdated="updateFilters"
            @resetFilters="resetFilters(FilterArea.Dropdown)"
            ref="filtersGroup"
            class="ml-16"
          >
            <template #firstRow>
              <v-select-search
                formKey="filters"
                name="distributorId"
                :label="`${$t('global.distributor')}`"
                :search="distributorFilterUrl"
                :selected="dropdownFilters.distributorId"
                :request-params="{ connected: true, limit: undefined }"
                :data="distributors"
                :placeholder="`All ${$t('global.distributors')}`"
                :searchPlaceholder="`Select ${$t('global.distributor')}`"
                align-left
                :data-cy="outletCy.PRODUCTS.GENERAL.BUTTON__SUPPLIER_FILTER"
                :min-length="3"
                is-full-width
              >
                <template #result="{ result }">
                  <v-distributor-entity-info :distributor="result" />
                </template>
              </v-select-search>
              <ez-product-group-filter
                name="productGroupId"
                label="Product Group"
                :productGroups="productGroupsFilter"
                :selected="dropdownFilters.productGroupId"
                isFullWidth
              />
              <v-select-search
                class="input-group"
                formKey="filters"
                name="categoryId"
                label="Category"
                :selected="dropdownFilters.categoryId"
                :data="categoryOptions"
                :placeholder="`All Categories`"
                :searchPlaceholder="`Select Category`"
                align-left
                isFullWidth
                :data-cy="outletCy.PRODUCTS.GENERAL.BUTTON__CATEGORY_FILTER"
              >
                <template #result="{ result }">
                  <v-category-entity-info :category="result" :is-child="result.isChild" />
                </template>
              </v-select-search>
            </template>
          </v-filter-dropdown>
        </div>
      </template>
      <template v-if="!productsData.length && !isLoading">
        <empty-state v-if="hasFilters">
          <template #badge>
            <img src="@/assets/no-products-search-state.svg" alt="" />
          </template>
          <template #title>No products match this search</template>
          <template #info>Try with a different search.</template>
        </empty-state>
        <empty-state v-else>
          <template #badge>
            <img src="@/assets/no-product-empty-state.svg" alt="" />
          </template>
          <template #title>No products listed</template>
          <template #info>
            As soon as you get connected to a supplier, you'll see a list of available products
            here.
          </template>
        </empty-state>
      </template>
      <ez-loader v-else :show="isLoading">Loading...</ez-loader>
      <ez-load-more v-if="meta.nextId && !isLoadingMore" @loadMore="onLoadMoreClick" />
    </ez-product-list>
    <product-drawer
      ref="productDrawer"
      :productId="productId"
      @close="resetDrawerRoute"
      :product="selectedProduct"
    />
    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>
    <upload-invoice ref="uploadInvoice" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import EzFilterList from '@/components/ui/FilterList';
import EzInput from '@/components/ui/Input';
import EzLoadMore from '@/components/ui/LoadMore';
import ProductDrawer from '@/views/platform/venue/ProductDrawer.vue';
import EzProductList from '@/views/common/products/List.vue';
import EmptyState from '@/views/common/empty-state';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import VDistributorEntityInfo from '@/components/v3/patterns/VDistributorEntityInfo';
import VCategoryEntityInfo from '@/components/v3/patterns/VCategoryEntityInfo';
import { debounce, getContextId } from '@/util/utils';
import EzSpinner from '@/components/ui/Spinner';
import EzLoader from '@/components/ui/Loader';
import UploadInvoice from '@/views/platform/venue/orders/actions/UploadInvoice.vue';
import { LOADING_KEY } from '@/util/constants';
import VFilterDropdown from '@/components/v3/patterns/VFilterDropdown';
import dayjs from 'dayjs';
import EzProductGroupFilter from '@/components/ui/Filter/ProductGroup.vue';

const FilterArea = {
  List: 'list',
  Dropdown: 'dropdown',
};

export default {
  components: {
    VSelectSearch,
    EzFilterList,
    EzInput,
    EzLoadMore,
    ProductDrawer,
    EzProductList,
    EmptyState,
    EzSpinner,
    VDistributorEntityInfo,
    VCategoryEntityInfo,
    EzLoader,
    UploadInvoice,
    VFilterDropdown,
    EzProductGroupFilter,
  },
  props: {
    productId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      dropdownFilters: {},
      listFilters: { term: null },
      quantity: 1,
      totalPrice: 0,
      outletCy,
      distributors: [],
      productsData: [],
      meta: {},
      selectedProduct: {},
      categories: [],
      FilterArea,
      productGroups: [],
    };
  },
  computed: {
    ...mapGetters('cart', ['getProductCount']),
    ...mapGetters('loading', ['getLoading', 'isSomeLoading']),
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.LOAD_MORE_PRODUCTS);
    },
    isLoading() {
      return this.isSomeLoading([
        LOADING_KEY.FETCH_PRODUCTS,
        LOADING_KEY.VENUE_FETCH_DISTRIBUTORS_FILTER,
        LOADING_KEY.VENUE_FETCH_CATEGORIES_FILTER,
      ]);
    },
    filters() {
      return { ...this.listFilters, ...this.dropdownFilters };
    },
    productGroupsFilter() {
      return [
        { id: null, name: 'All Product Groups' },
        { id: '', name: 'Unassigned' },
        ...this.productGroups,
      ];
    },
    categoryOptions() {
      const compareByName = (a, b) => a.name.localeCompare(b.name);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const categories = this.categories.sort(compareByName);

      let options = [];
      let parent = {};
      let children = [];
      categories.sort(compareByName).forEach(cat => {
        parent = {
          id: cat.id,
          name: cat.name,
          isChild: false,
        };
        children = (cat.children || [])
          .map(catChild => ({
            id: catChild.id,
            name: catChild.name,
            isChild: true,
          }))
          .sort(compareByName);

        options = [...options, parent, ...children];
      });

      return options;
    },
    hasFilters() {
      return !!this.filters.term || !!this.filters.categoryId || !!this.filters.distributorId;
    },
    venueId() {
      return getContextId();
    },
    distributorFilterUrl() {
      return `/venue/filters/distributors?for=products`;
    },
  },
  methods: {
    resetDrawerRoute() {
      this.$router.replace({ name: 'venue-orders-products' });
    },
    resetData() {
      this.productsData = [];
      this.meta = {};
    },
    resetFilters(area) {
      if (area === FilterArea.List) {
        Object.keys(this.listFilters).forEach(key => {
          this.listFilters[key] = null;
          this.dropdownFilters[key] = null;
        });
      } else if (area === FilterArea.Dropdown) {
        this.dropdownFilters = {};
        delete this.listFilters.distributorId;
        delete this.listFilters.productGroupId;
      }
      this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());
      this.$emit('updateFilter', this.filters);
      this.resetData();
      return this.refresh({
        filters: this.filters,
        sortBy: 'name',
      });
    },
    async openDrawerAndLoadProduct({ id }) {
      try {
        const {
          data: { data },
        } = await this.venueFetchProduct({
          venueId: this.venueId,
          id,
        });

        this.selectedProduct = data;
        await this.$router.push({
          name: 'venue-orders-products',
          params: { id },
        });
        this.fetchTotalPrice(id);
      } catch (e) {
        this.resetDrawerRoute();
        console.error(e);
      }
    },
    async loadDrawerProduct({ id }) {
      try {
        const {
          data: { data },
        } = await this.venueFetchProduct({
          venueId: this.venueId,
          id,
        });

        this.selectedProduct = data;
        this.fetchTotalPrice(id);
      } catch (e) {
        this.resetDrawerRoute();
        console.error(e);
      }
    },
    fetchTotalPrice(id) {
      this.fetchTotalProductPrice({
        id,
        quantity: this.quantity,
      }).then(({ data }) => {
        this.totalPrice = data.data.price;
        this.$refs.productDrawer.open();
      });
    },
    async fetchProductGroups() {
      const {
        data: { data },
      } = await this.venueFetchProductGroups();
      this.productGroups = data;
    },
    updateFilters: debounce(function deb(filterName, event) {
      if (filterName === 'term') {
        this.listFilters = {
          ...this.listFilters,
          term: event,
        };
      } else if (filterName === 'distributorId') {
        this.dropdownFilters = {
          ...this.dropdownFilters,
          distributorId: event.id,
        };
        this.listFilters = {
          ...this.listFilters,
          distributorId: event.id,
        };
      } else if (filterName === 'productGroupId') {
        this.dropdownFilters = {
          ...this.dropdownFilters,
          productGroupId: event.id,
        };
        this.listFilters = {
          ...this.listFilters,
          productGroupId: event.id,
        };
      } else {
        this.dropdownFilters = {
          ...this.dropdownFilters,
          ...(typeof filterName.categoryId === 'object' &&
            filterName.categoryId !== null && { categoryId: filterName.categoryId.id }),
          ...(typeof filterName.distributorId === 'object' &&
            filterName.distributorId !== null && { distributorId: filterName.distributorId.id }),
          ...(typeof filterName.productGroupId === 'object' &&
            filterName.productGroupId !== null && { productGroupId: filterName.productGroupId.id }),
        };
        this.listFilters = {
          ...this.listFilters,
          ...(typeof filterName.distributorId === 'object' &&
            filterName.distributorId !== null && { distributorId: filterName.distributorId.id }),
          ...(typeof filterName.productGroupId === 'object' &&
            filterName.productGroupId !== null && { productGroupId: filterName.productGroupId.id }),
        };

        if (filterName.distributorId === undefined) {
          delete this.dropdownFilters.distributorId;
          delete this.listFilters.distributorId;
        }
      }
      this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());

      this.resetData();
      this.refresh({
        filters: this.filters,
        sortBy: 'name',
      });

      this.$emit('updateFilter', this.filters);
    }, 300),
    onLoadMoreClick() {
      this.refresh({
        filters: this.filters,
        sortBy: 'name',
        loadingKey: LOADING_KEY.LOAD_MORE_PRODUCTS,
      });
    },
    async fetchSuppliersFilter() {
      const {
        data: { data: distributors },
      } = await this.venueFetchFilterSuppliers({
        venueId: this.venueId,
        query: {
          connected: true,
        },
      });
      this.distributors = distributors;
    },
    async fetchCategoriesFilter() {
      const {
        data: { data },
      } = await this.venueFetchCategoriesNew();
      this.categories = data;
    },
    async refresh(query = {}) {
      const combinedQuery = {
        ...query,
        ...this.meta,
      };

      try {
        const {
          data: { data, meta },
        } = await this.venueFetchProducts({
          venueId: this.venueId,
          sortBy: 'name',
          ...combinedQuery,
        });

        if (query.loadingKey !== LOADING_KEY.LOAD_MORE_PRODUCTS) {
          this.productsData = data;
        } else {
          this.productsData = [...this.productsData, ...data];
        }
        this.meta = meta;
        this.UPDATE_META({ meta });
      } catch (e) {
        console.error(e);
      }
    },
    async fetchTotal() {
      const { data: total } = await this.fetchVenueOrdersHistoryTotal({
        query: {
          scheduledFrom: dayjs().startOf('M').valueOf(),
          scheduledTo: dayjs().endOf('M').valueOf(),
        },
      });
      this.SET_TOTAL_AMOUNT({ totalAmount: total.data });
    },
    openUploadInvoice() {
      this.$refs.uploadInvoice.open();
    },
    ...mapActions('entities/products', [
      'venueFetchProduct',
      'venueFetchProducts',
      'venueFetchProductGroups',
    ]),
    ...mapActions('entities/categories', ['venueFetchCategoriesNew']),
    ...mapActions('entities/venues', ['venueFetchFilterSuppliers']),
    ...mapActions('cart', ['fetchTotalProductPrice']),
    ...mapActions('entities/orders', ['fetchVenueOrdersHistoryTotal']),
    ...mapMutations('entities/products', ['UPDATE_META']),
    ...mapMutations('entities/orders', ['SET_TOTAL_AMOUNT']),
  },
  async created() {
    await Promise.all([
      this.fetchSuppliersFilter(),
      this.fetchCategoriesFilter(),
      this.fetchProductGroups(),
      this.refresh(),
      this.fetchTotal(),
    ]);

    if (this.productId) {
      await this.loadDrawerProduct({ id: this.productId });
    }
  },
  beforeMount() {
    this.$emit('updateFilter', {});
  },
};
</script>

<style scoped lang="scss">
$price-color: #4d7cfe;
$drawer-separator-color: #dee1e4;

$secondary-color: #8790a3;
$primary-color: #252631;

:deep() .loader {
  @extend %whole-screen;
}

.venue-products {
  @include platform-tab-content();

  .ez-load-more {
    margin-top: 2em;
  }
}

:deep() .table tbody tr td.category-cell {
  text-overflow: inherit;
}

:deep() {
  .table {
    .price-cell-overflow,
    .unit-cell {
      overflow: visible;
    }
  }
}

:deep() .select-search {
  color: $color-gray-6C;

  &__list {
    max-height: 244px;
    .category-entity__parent,
    .category-entity__child {
      margin: 3.5px 0px;
      line-height: 1;
    }
  }
}

:deep() .ez-empty-state {
  margin-top: 5rem;
  &__image {
    max-width: none;
  }
  img {
    width: 256px;
    height: 118px;
  }
}

.filters-row {
  display: flex;
  align-items: center;

  .orders-actions {
    margin-left: auto;
  }
}

:deep() .ez-filter-list .ez-filter-list__items .select-search__trigger {
  width: auto;
  min-width: 128px;
}

:deep() .ez-filter-list .ez-filter-list__items .product-group-filter .select-search__trigger {
  min-width: 172px;
}

:deep() .ez-filter-list .ez-filter-list__items .select-search__wrapper {
  .select-search__dropdown,
  .select-search__dropdown.select-search__dropdown--width-100 {
    min-width: 100%;
    width: max-content;
    color: $color-gray-25;
  }
}

:deep() .ez-filter-list .ez-filter-list__items .ez-select {
  width: auto;
  min-width: 164px;

  .ez-select__dropdown {
    min-width: 100%;
    width: max-content;
  }
  svg {
    color: $color-gray-6C;
  }
}
</style>
