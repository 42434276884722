<template>
  <div class="navigation">
    <nav class="navigation__nav">
      <!-- @slot `ul` router navigation -->
      <slot name="nav"></slot>
    </nav>
    <div class="navigation__container">
      <!-- @slot Default slot for elements -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
/**
 * Navigation component that looks like tabs but works on a router level.
 * @version 2.0.0
 */
export default {

};
</script>

<style lang="scss" scoped>
$tab-text-color: $color-gray-6C;
$active-tab-border-color: #4D7CFE;
$active-tab-text-color: $color-gray-25;
$separator-color: #E9EBED;
$item-count-color: #FFFFFF;

.navigation {
  &__nav {
    background-color: #fff;
    border-bottom: 1px solid $color-gray-E9;
    position: relative;
    height: 2.5rem;
    display: flex;
    align-items: stretch;

    :deep() ul {
      @extend %ul-reset;
      position: relative;
      top: 1px;
      display: flex;

       li {
         @extend %flex-center;
        + li { margin-left: 1.5rem; }

        a {
          @extend %flex-center;
          height: 100%;
          color: $tab-text-color;
          font-weight: 400;
          text-decoration: none;
          border-top: .125rem solid rgba(255, 255, 255, 0);
          border-bottom: .125rem solid rgba(255, 255, 255, 0);

          &.router-link-active {
            border-bottom: .125rem solid $active-tab-border-color;
            color: $active-tab-text-color;
          }
        }
      }
    }
  }
}
</style>
