<template>
  <div>
    <div :class="{ 'desktop-table': isPublicOrder }">
      <ez-table
        v-if="canAddDeliveryFee || tableData.length > 0"
        :columns="tableColumns"
        :data="tableData"
        :column-props="{ totalAmount: { class: 'small-cell price-cell' } }"
        @rowHover="rowHover"
        :has-min-height="false"
      >
        <template #cell-additionalItems="{ row }">
          <div class="media-info">
            <v-icon-badge :icon="row.icon" :color="row.color" />
            <div class="media-info__content">
              <div class="media-info__title" :data-cy="row.dataCy.title">
                {{ row.title }}
              </div>
              <div class="media-info__subtitle" :data-cy="row.dataCy.description">
                {{ row.description }}
              </div>
            </div>
          </div>
        </template>
        <template #cell-totalAmount="{ row }">
          <font-awesome-icon
            class="table-icon"
            v-tooltip="`Edit ${row.title}`"
            v-if="isRowHovered(row) && row.isEditable"
            @click.stop="edit(row)"
            icon="pen"
            :data-cy="row.dataCy.button"
          />
          <span v-else :data-cy="row.dataCy.amount">{{
            $helpers.formatPrice(row.amount, currency)
          }}</span>
        </template>
      </ez-table>
    </div>
    <div v-if="isPublicOrder" :class="{ 'mobile-list': isPublicOrder }">
      <h4>Additional Details</h4>
      <div v-for="item in tableData" :key="item.id" class="additional-item mb-12">
        <v-icon-badge :icon="item.icon" :color="item.color" class="mr-8" />
        <div class="width-100">
          <div class="additional-item-description">
            <span class="item-title">{{ item.title }}</span>
            <span class="item-price">
              {{ $helpers.formatPrice(item.amount, currency) }}
              <font-awesome-icon
                class="table-icon"
                v-if="item.isEditable"
                @click.stop="edit(item)"
                icon="pen"
                :data-cy="item.dataCy.button"
              />
            </span>
          </div>
          <span class="item-description">
            {{ item.description }}
          </span>
        </div>
      </div>
    </div>
    <div class="button-wrapper" v-if="canAddDeliveryFee">
      <ez-button
        class="mt-16 mb-32"
        type="secondary"
        is-full-width
        @click="addDeliveryFee"
        :data-cy="commonCy.SINGLE_ORDER.BUTTON__ADD_DELIVERY_FEE"
      >
        <font-awesome-icon icon="plus" />
        Add Delivery Fee
      </ez-button>
    </div>

    <div class="button-wrapper" v-if="canAddDiscount">
      <ez-button
        class="mt-16 mb-32"
        type="secondary"
        is-full-width
        @click="addDiscount"
        :data-cy="''"
      >
        <font-awesome-icon icon="plus" />
        Add Discount
      </ez-button>
    </div>

    <div class="button-wrapper" v-if="canAddPriceAdjustment && !isDistributor">
      <ez-button
        class="mt-16 mb-32"
        type="secondary"
        is-full-width
        @click="addPriceAdjustment"
        :data-cy="''"
      >
        <font-awesome-icon icon="plus" />
        Add Price Adjustment
      </ez-button>
    </div>

    <div class="button-wrapper" v-if="canAddTaxAdjustment && !isDistributor">
      <ez-button
        class="mt-16 mb-32"
        type="secondary"
        is-full-width
        @click="addTaxAdjustment"
        :data-cy="''"
      >
        <font-awesome-icon icon="plus" />
        Add Tax Adjustment
      </ez-button>
    </div>

    <ez-form-modal ref="editModal">
      <template #title>{{ isEditModal ? 'Edit' : 'Add' }} {{ editRow.title }}</template>

      <template #content>
        <ez-mask-input
          :currency="currency"
          :value="editRow.amount || 0"
          type="input"
          @input="onPriceChange"
          :data-cy="commonCy.SINGLE_ORDER.EDIT_DELIVERY_FEE_MODAL.INPUT__DELIVERY_FEE"
        />
      </template>

      <template #footer>
        <ez-button
          @click="close"
          type="link"
          :data-cy="commonCy.SINGLE_ORDER.EDIT_DELIVERY_FEE_MODAL.BUTTON__CANCEL"
        >
          Cancel
        </ez-button>
        <ez-button
          :disabled="!editFieldChanged"
          @click="save"
          :data-cy="commonCy.SINGLE_ORDER.EDIT_DELIVERY_FEE_MODAL.BUTTON__CONFIRM"
        >
          Save Changes
        </ez-button>
      </template>
    </ez-form-modal>

    <ez-form-modal ref="editModalDiscount">
      <template #title
        >{{ isEditModalDiscount ? 'Edit' : 'Add' }} Order {{ editRow.title }}</template
      >

      <template #content>
        <ez-mask-input
          :currency="currency"
          :value="order?.orderDiscountAmount"
          type="input"
          :max-value="subtotalAfterDiscounts"
          @input="onDiscountChange"
          @invalid="val => (isDiscountInvalid = val)"
          :data-cy="''"
        />
      </template>

      <template #footer>
        <ez-button @click="closeDiscount" type="link" :data-cy="''"> Cancel </ez-button>
        <ez-button
          :disabled="!editFieldChanged || isDiscountInvalid"
          @click="saveDiscount"
          :data-cy="''"
        >
          Save Changes
        </ez-button>
      </template>
    </ez-form-modal>

    <ez-form-modal ref="editModalPrice">
      <template #title>{{ isEditModalPrice ? 'Edit' : 'Add' }} Order {{ editRow.title }}</template>

      <template #content>
        <ez-mask-input
          :currency="currency"
          :value="editRow.amount || 0"
          type="input"
          @input="onPriceAdjustmentChange"
          :data-cy="''"
        />
      </template>

      <template #footer>
        <ez-button @click="closePrice" type="link" :data-cy="''"> Cancel </ez-button>
        <ez-button :disabled="!editFieldChanged" @click="savePriceAdjustment" :data-cy="''">
          Save Changes
        </ez-button>
      </template>
    </ez-form-modal>

    <ez-form-modal ref="editModalTax">
      <template #title>{{ isEditModalTax ? 'Edit' : 'Add' }} Order {{ editRow.title }}</template>

      <template #content>
        <ez-mask-input
          :currency="currency"
          :value="editRow.amount || 0"
          type="input"
          @input="onTaxAdjustmentChange"
          :data-cy="''"
        />
      </template>

      <template #footer>
        <ez-button @click="closeTax" type="link" :data-cy="''"> Cancel </ez-button>
        <ez-button :disabled="!editFieldChanged" @click="saveTax" :data-cy="''">
          Save Changes
        </ez-button>
      </template>
    </ez-form-modal>
  </div>
</template>

<script>
import EzTable from '@/components/ui/Table/EzTable';
import VIconBadge from '@/components/v3/patterns/VIconBadge';
import { canVenueEditDeliveryFee } from '@/util/utils';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput';
import EzButton from '@/components/ui/Button/EzButton';
import EzFormModal from '@/components/ui/Modal/EzFormModal';
import { mapGetters } from 'vuex';
import { isDistributor } from '@/mixins/permissions/utils';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import { ADDITIONAL_ITEMS } from '@/util/constants';
import { getDiscountedPrice } from '@/views/platform/distributor/orders/new/overview/utils';

export default {
  name: 'AdditionalItems',
  components: {
    EzFormModal,
    EzButton,
    EzMaskInput,
    VIconBadge,
    EzTable,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
    },
    isPublicOrder: {
      type: Boolean,
      default: false,
    },
    creditNoteAmount: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  data() {
    return {
      tableColumns: ['additionalItems', 'totalAmount'],
      hoveredRow: null,
      editRow: {},
      editFieldChanged: true,
      newValue: 0,
      commonCy,
      isEditModal: false,
      isEditModalDiscount: false,
      isEditModalPrice: false,
      isEditModalTax: false,
      discount: 0,
      priceAdjustmentAmount: 0,
      taxAdjustmentAmount: 0,
      ADDITIONAL_ITEMS,
      isDiscountInvalid: false,
    };
  },
  computed: {
    ...mapGetters('entities/users', ['isDistributor', 'isVenue']),
    deliveryFee() {
      return this.order.deliveryFee;
    },
    priceAdjustment() {
      return this.order.amountAdjustment;
    },
    taxAdjustment() {
      return this.order.taxAdjustment;
    },
    minimalOrderAmount() {
      return this.order.distributor.minimalOrderAmount || 0;
    },
    canVenueEdit() {
      return (
        canVenueEditDeliveryFee({
          order: this.order,
          permission: this.$permission,
        }) || this.order.offline
      );
    },
    canDistributorEdit() {
      return this.isDistributor || isDistributor(this.type);
    },
    canUserEdit() {
      return this.isVenue ? this.canVenueEdit : this.canDistributorEdit;
    },
    isEditable() {
      return this.editable && this.canUserEdit;
    },
    canAddDeliveryFee() {
      const isDeliveryFeeZero = this.deliveryFee === 0;

      return this.isEditable && isDeliveryFeeZero;
    },
    canAddDiscount() {
      const isDiscountZero = this.order.orderDiscountAmount === 0;

      return this.isEditable && isDiscountZero;
    },
    canAddPriceAdjustment() {
      const isPriceAdjustment = this.priceAdjustment === 0;

      return this.isEditable && isPriceAdjustment;
    },
    canAddTaxAdjustment() {
      const isTaxAdjustment = this.taxAdjustment === 0;

      return this.isEditable && isTaxAdjustment;
    },
    subtotalAfterDiscounts() {
      const filteredProducts = this.order.orderedProducts?.filter(
        p => !p.marketPrice && !p.defaultMarketPrice,
      );
      if (!filteredProducts?.length) return 0;
      return filteredProducts.reduce((sum, cur) => sum + getDiscountedPrice(cur) * cur.quantity, 0);
    },
    deliveryFeeData() {
      const editMsg = 'The amount is added to order total (editable).';
      const noEditMsg = `The amount is added to the order total. ${this.$t(
        'global.distributor',
      )} can edit it if needed.`;

      return this.deliveryFee
        ? [
            {
              key: ADDITIONAL_ITEMS.DELIVERY_FEE,
              icon: 'truck',
              title: 'Delivery Fee',
              description: this.canUserEdit ? editMsg : noEditMsg,
              amount: this.deliveryFee,
              isEditable: this.isEditable,
              id: 0,
              color: 'orange',
              dataCy: {
                title: commonCy.SINGLE_ORDER.TEXT__DELIVERY_FEE_TITLE,
                description: commonCy.SINGLE_ORDER.TEXT__DELIVERY_FEE_DESCRIPTION,
                button: commonCy.SINGLE_ORDER.ICON__EDIT_DELIVERY_FEE,
                amount: commonCy.SINGLE_ORDER.TEXT__DELIVERY_FEE,
              },
            },
          ]
        : [];
    },
    creditNoteData() {
      return [
        {
          key: ADDITIONAL_ITEMS.CREDIT_NOTE,
          icon: 'file-invoice-dollar',
          title: 'Credit Note',
          description: 'This is not editable and it is not included in the total order amount.',
          amount: this.creditNoteAmount,
          isEditable: false,
          id: 1,
          color: 'yellow-dark',
          dataCy: {
            title: commonCy.SINGLE_ORDER.TEXT__CREDIT_NOTE_TITLE,
            description: commonCy.SINGLE_ORDER.TEXT__CREDIT_NOTE_DESCRIPTION,
            amount: commonCy.SINGLE_ORDER.TEXT__CREDIT_NOTE,
          },
        },
      ];
    },
    discountData() {
      return [
        {
          key: ADDITIONAL_ITEMS.DISCOUNT,
          icon: 'money-bill-wave',
          title: 'Discount',
          description: "Subtracted from the order's total (editable).",
          amount: this.order.orderDiscountAmount,
          isEditable: this.isEditable,
          id: 2,
          color: 'green',
          dataCy: {
            title: commonCy.SINGLE_ORDER.TEXT__CREDIT_NOTE_TITLE,
            description: commonCy.SINGLE_ORDER.TEXT__CREDIT_NOTE_DESCRIPTION,
            amount: commonCy.SINGLE_ORDER.TEXT__CREDIT_NOTE,
          },
        },
      ];
    },
    priceData() {
      return [
        {
          key: ADDITIONAL_ITEMS.PRICE_ADJUSTMENT,
          icon: 'tag',
          title: 'Price Adjustment',
          description: 'Included to help account for non-standard price issues (editable).',
          amount: this.order.amountAdjustment,
          isEditable: this.isEditable,
          id: 3,
          color: 'blue',
          dataCy: {
            title: commonCy.SINGLE_ORDER.TEXT__CREDIT_NOTE_TITLE,
            description: commonCy.SINGLE_ORDER.TEXT__CREDIT_NOTE_DESCRIPTION,
            amount: commonCy.SINGLE_ORDER.TEXT__CREDIT_NOTE,
          },
        },
      ];
    },
    taxData() {
      return [
        {
          key: ADDITIONAL_ITEMS.TAX_ADJUSTMENT,
          icon: 'balance-scale',
          title: 'Tax Adjustment',
          description: 'Included to help account for non-standard tax issues (editable).',
          amount: this.order.taxAdjustment,
          isEditable: this.isEditable,
          id: 4,
          color: 'purple',
          dataCy: {
            title: commonCy.SINGLE_ORDER.TEXT__CREDIT_NOTE_TITLE,
            description: commonCy.SINGLE_ORDER.TEXT__CREDIT_NOTE_DESCRIPTION,
            amount: commonCy.SINGLE_ORDER.TEXT__CREDIT_NOTE,
          },
        },
      ];
    },
    tableData() {
      return [
        ...(this.creditNoteAmount ? this.creditNoteData : []),
        ...this.deliveryFeeData,
        ...(this.order.orderDiscountAmount ? this.discountData : []),
        ...(this.order.amountAdjustment ? this.priceData : []),
        ...(this.order.taxAdjustment ? this.taxData : []),
      ];
    },
    currency() {
      return this.order?.currency;
    },
  },
  methods: {
    rowHover(row) {
      this.hoveredRow = row;
    },
    edit(row) {
      this.editRow = row;
      if (row.key === ADDITIONAL_ITEMS.DISCOUNT) {
        this.isEditModalDiscount = true;
        this.$refs.editModalDiscount.open();
        return;
      }
      if (row.key === ADDITIONAL_ITEMS.PRICE_ADJUSTMENT) {
        this.isEditModalPrice = true;
        this.$refs.editModalPrice.open();
        return;
      }
      if (row.key === ADDITIONAL_ITEMS.TAX_ADJUSTMENT) {
        this.isEditModalTax = true;
        this.$refs.editModalTax.open();
        return;
      }
      this.isEditModal = true;
      this.$refs.editModal.open();
    },
    close() {
      this.$refs.editModal.close();
      this.isEditModal = false;
      this.editRow = {};
    },
    closeDiscount() {
      this.$refs.editModalDiscount.close();
      this.isEditModalDiscount = false;
      this.editRow = {};
    },
    closePrice() {
      this.$refs.editModalPrice.close();
      this.isEditModalPrice = false;
      this.editRow = {};
    },
    closeTax() {
      this.$refs.editModalTax.close();
      this.isEditModalTax = false;
      this.editRow = {};
    },
    isRowHovered(row) {
      if (this.hoveredRow) {
        return row.id === this.hoveredRow.id;
      }
      return false;
    },
    onPriceChange(val) {
      this.newValue = val;
    },
    onDiscountChange(val) {
      this.discount = val;
    },
    onPriceAdjustmentChange(val) {
      this.priceAdjustmentAmount = val;
    },
    onTaxAdjustmentChange(val) {
      this.taxAdjustmentAmount = val;
    },
    addDeliveryFee() {
      this.editRow = {
        key: ADDITIONAL_ITEMS.DELIVERY_FEE,
        icon: 'truck',
        title: 'Delivery Fee',
        description: 'Additional charge that will be applied to the order.',
        amount: this.deliveryFee,
      };
      this.$refs.editModal.open();
    },
    addDiscount() {
      this.editRow = {
        key: ADDITIONAL_ITEMS.DISCOUNT,
        icon: 'money-bill-wave',
        title: 'Discount',
        description: "Subtracted from the order's total (editable).",
        amount: this.order.orderDiscountAmount,
      };
      this.$refs.editModalDiscount.open();
    },
    addPriceAdjustment() {
      this.editRow = {
        key: ADDITIONAL_ITEMS.PRICE_ADJUSTMENT,
        icon: 'tag',
        title: 'Price Adjustment',
        description: 'Included to help account for non-standard tax issues (editable).',
        amount: this.order.amountAdjustment,
      };
      this.$refs.editModalPrice.open();
    },
    addTaxAdjustment() {
      this.editRow = {
        key: ADDITIONAL_ITEMS.TAX_ADJUSTMENT,
        icon: 'balance-scale',
        title: 'Tax Adjustment',
        description: 'Included to help account for non-standard tax issues (editable).',
        amount: this.order.taxAdjustment,
      };
      this.$refs.editModalTax.open();
    },
    save() {
      this.$emit('change', { ...this.editRow, amount: this.newValue });

      this.close();
    },
    saveDiscount() {
      this.$emit('change', { ...this.editRow, amount: this.discount });
      this.closeDiscount();
    },
    savePriceAdjustment() {
      this.$emit('change', { ...this.editRow, amount: this.priceAdjustmentAmount });
      this.closePrice();
    },
    saveTax() {
      this.$emit('change', { ...this.editRow, amount: this.taxAdjustmentAmount });
      this.closeTax();
    },
  },
};
</script>

<style lang="scss" scoped>
.media-info {
  display: flex;
  align-items: center;

  &__content {
    margin-left: 8px;
  }
  &__title {
    color: #252631;
    @include font-size(14px, 20px);
  }
  &__subtitle {
    @include font-size(12px, 14px);
  }
}

.table-icon {
  color: #6c7995;
  cursor: pointer;
}

.mobile-list {
  display: none;
  h4 {
    font-size: 16px;
    line-height: 24px;
    color: $color-gray-25;
    margin-bottom: 16px;
    margin-top: 0;
    font-weight: 500;
  }

  .additional-item {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  .additional-item-description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    color: $color-gray-25;
    font-weight: 500;
  }
  .item-description {
    font-size: 12px;
    line-height: 18px;
    color: $color-gray-6C;
  }
}

@media (max-width: 1024px) {
  .desktop-table {
    display: none;
  }
  .mobile-list {
    display: block;
  }
}
</style>
