<template>
  <div class="from-to">
    <ez-input
      :disabled="disabled"
      :formKey="formKey"
      label="From"
      type="time"
      :value="valueFrom"
      :isTooltipError="true"
      :name="nameFrom"/>
    <ez-input
      :disabled="disabled"
      :formKey="formKey"
      label="To"
      type="time"
      :value="valueTo"
      :isTooltipError="true"
      :name="nameTo"/>
  </div>
</template>

<script>
import EzInput from '@/components/ui/Input';

export default {
  components: {
    EzInput,
  },
  props: {
    formKey: {
      required: true,
      type: String,
    },
    valueFrom: {
      required: true,
      type: String,
    },
    valueTo: {
      required: true,
      type: String,
    },
    nameFrom: {
      required: true,
      type: String,
    },
    nameTo: {
      required: true,
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {

    };
  },
  methods: {
    reset(fields = ['ezinput']) {
      this.$children
        .filter(child => fields.indexOf(child.$options.name) !== -1)
        .forEach(field => field.reset());
    },
  },
};
</script>

<style scoped lang="scss">
  .from-to {
    display: flex;
    :deep() .input-group {
      &:first-child {
        position: relative;
        &:after {
          content: "";
          width: 7px;
          height: 2px;
          background-color: #6C7995;
          display: block;
          right: -15px;
          top: 37px;
          position: absolute;
        }
      }
      &:last-child {
        margin-left: px-to-rem(22px);
      }
    }
  }
</style>
