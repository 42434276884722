<template>
  <page>
    <template #breadcrumbs>
      <router-link :to="{ name: 'platform-home' }">Home</router-link>
      <span>/</span>
      <router-link :to="{ name: 'venue-group-settings-venues' }"> Group Settings </router-link>
    </template>
    <template #actions>
      <ez-button-dropdown buttonType="secondary">
        <template #icon>
          <font-awesome-icon icon="ellipsis-h" />
        </template>
        <template #dropdown>
          <ez-button @click="onDeleteVenue" type="red-link" :is-loading="isLoadingDelete">
            Delete {{ $t('global.venue') }}
          </ez-button>
        </template>
      </ez-button-dropdown>
    </template>
    <template #title>
      <ez-entity-info imgBorderRadius="50%" imgWidth="2rem" imgHeight="2rem" :imgUrl="venue.logo">
        <h1>{{ title }}</h1>
        <status-badge :status="venue.status || 'draft'"></status-badge>
      </ez-entity-info>
    </template>
    <template #navigation>
      <ul>
        <li>
          <router-link :to="{ name: 'venue-settings' }">
            {{ $t('global.venue') }} Info
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: 'venue-settings-settings' }">Settings</router-link>
        </li>
        <li v-if="$permission.isPremium && $permission.has('manageAccounting')">
          <router-link :to="{ name: 'venue-settings-integrations' }">
            {{ $t('xero.integrations.tabTitle') }}
          </router-link>
        </li>
        <li
          v-if="
            $permission.isPremium &&
            ($permission.has('manageInventory') || $permission.has('conductStocktake'))
          "
        >
          <router-link :to="{ name: 'venue-settings-stocktake' }"> Stocktake </router-link>
        </li>
      </ul>
    </template>
    <ez-confirmation-modal ref="deleteModal" type="red">
      <template #title>Delete this {{ $t('global.venue') | lowercase }}?</template>
      <template #content>
        <p>
          When deleted, this {{ $t('global.venue') | lowercase }} will no longer be live on
          {{ oezPlatformName }}.
        </p>
      </template>
      <template #footer>
        <ez-button @click="closeDeleteModal" type="link">Cancel</ez-button>
        <ez-button @click="confirmDeleteVenue" type="red" :is-loading="isLoadingDelete">
          Yes, Delete
        </ez-button>
      </template>
    </ez-confirmation-modal>
  </page>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Page from '@/views/admin/SinglePage.vue';
import EzEntityInfo from '@/components/ui/EntityInfo';
import StatusBadge from '@/views/common/status-badge';
import Venue from '@/models/Venue';
import EzButton from '@/components/ui/Button/EzButton';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown';
import { EzConfirmationModal } from '@/components/ui/Modal';
import { LOADING_KEY } from '@/util/constants';

export default {
  components: {
    Page,
    EzEntityInfo,
    StatusBadge,
    EzButton,
    EzButtonDropdown,
    EzConfirmationModal,
  },
  props: {
    venueId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading']),
    isLoadingDelete() {
      return this.isSomeLoading([LOADING_KEY.DELETE_VENUE_FROM_VENUE_GROUP]);
    },
    venue() {
      return Venue.query().with('group').find(this.venueId) || { name: '', group: { name: '' } };
    },
    title() {
      const { venue } = this;

      return venue.name && venue.group ? `${venue.group.name} - ${venue.name}` : '';
    },
    ...mapState('entities/users', ['loggedUser']),
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
  },
  methods: {
    onDeleteVenue() {
      this.$refs.deleteModal.open();
    },
    closeDeleteModal() {
      this.$refs.deleteModal.close();
    },
    async confirmDeleteVenue() {
      await this.deleteVenueFromVenueGroup({
        venueId: this.venue.id,
      });
      this.closeDeleteModal();
      await this.$router.push({
        name: 'venue-group-settings-venues',
        params: {
          flash: {
            type: 'success',
            title: `${this.$t('global.venue')} successfully deleted!`,
          },
        },
      });
    },
    ...mapActions('entities/groups', ['fetchGroup']),
    ...mapActions('entities/venues', ['deleteVenueFromVenueGroup']),
  },
  created() {
    this.fetchGroup();
  },
};
</script>

<style scoped lang="scss">
.admin-single-page {
  h1 {
    @include font-size(24px, 32px);
    font-weight: 500;
    margin: 0 0 0.5rem 0;
    letter-spacing: -1px;
  }
}
</style>
