<template>
    <div :class="{
      'notes-badge': true,
      'notes-badge--has-badge': !!noteCount,
      'notes-badge--unread': !isNoteSeen
    }">
      <span class="notes-badge__text">Notes</span>
      <span v-if="noteCount" class="notes-badge__count">{{ noteCount }}</span>
    </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    noteCount() {
      return (this.order.notes || []).length;
    },
    isNoteSeen() {
      const userRole = localStorage.getItem('role');

      if (!userRole || userRole === 'admin') {
        return true;
      }

      return !!this.order.isMessageRead;
    },
  },
};
</script>

<style scoped lang="scss">
.notes-badge {
  @extend %flex-center;
  position: relative;
  &--unread {
    .notes-badge__count {
      background-color: rgba($color-primary-blue, .16);
      color: $color-primary-blue;
    }
  }

  &__count {
    @include font-size(11px);
    @extend %flex-center;
    color: $color-gray-6C;
    font-weight: bold;
    justify-content: center;
    margin-left: 8px;
    width: 1.5rem;
    height: 1rem;
    border-radius: .5rem;
    background-color: rgba($color-gray-6C, .16);
  }
}
</style>
