import i18n from '@/i18n';

export const SUPPLIER_STEP_1 = 0;
export const SUPPLIER_STEP_2 = 1;

const steps = {
  [SUPPLIER_STEP_1]: {
    component: () => import(/* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/connect/Contacts.vue'),
    title: `Connect New ${i18n.t('global.distributor')}`,
    pageTitle: 'Great! You’re now connected',
    pageInfo: 'You can now add your ordering contacts and set your prices for their products.',
    nextCta: 'Next Step',
  },
  [SUPPLIER_STEP_2]: {
    component: () => import(/* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/connect/Products.vue'),
    title: `Connect New ${i18n.t('global.distributor')}`,
    pageTitle: 'Now add products',
    pageInfo: 'Set prices for supplier’s products. You can also add new products and set prices for them.',
    nextCta: 'Finish',
  },
};

export default steps;
