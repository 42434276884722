var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ez-table',{attrs:{"columns":_vm.columns,"data":_vm.products,"headers":_vm.productHeaders,"rowCls":_vm.getRowClass,"columnProps":{
      sku: {class: 'medium-cell sku-cell'},
      quantity: { class: 'small-cell quantity-cell' },
      price: { class: 'small-cell price-cell' },
      action: { class: 'medium-cell action-cell' },
    },"rowDataCy":(row) => `${_vm.commonCy.SINGLE_ORDER.ROW__EXCLUDED_PRODUCT}-${row.id}`},on:{"rowHover":_vm.rowHover},scopedSlots:_vm._u([{key:"cell-product",fn:function({ row }){return [_c('ez-entity-info',{attrs:{"imgUrl":row.image}},[_c('div',{staticClass:"product-info",attrs:{"title":row.name}},[_c('span',[_vm._v(_vm._s(row.name))]),_c('span',{staticClass:"product-info-secondary"},[_c('v-price',{attrs:{"flex-row":"","price":row.price || 0,"currency":_vm.order.currency,"is-market-price":row.marketPrice,"show-market-price-long":true,"show-market-price-info":false,"unit":_vm.getProductUnitLabel(row),"type":_vm.V_PRICE_TYPE.SECONDARY}}),(row.sku)?_c('span',[_vm._v("• "+_vm._s(row.sku))]):_vm._e()],1)])])]}},{key:"cell-sku",fn:function({ row }){return [_vm._v(" "+_vm._s(row.sku)+" ")]}},{key:"cell-price",fn:function({ row }){return [_c('div',{staticClass:"actions-wrapper"},[(!_vm.isRowHovered(row))?_c('span',[_c('v-price',{attrs:{"price":row.price * row.priceQuantity,"currency":_vm.order.currency,"is-market-price":row.marketPrice,"show-market-price-info":false,"is-to-be-determined":_vm.isToBeDetermined(row)}})],1):_vm._e(),(_vm.isRowHovered(row) && _vm.canEditProduct)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: 'Edit details',
            classes: ['tooltip-general']
          }),expression:"{\n            content: 'Edit details',\n            classes: ['tooltip-general']\n          }"}],attrs:{"icon":"pen"},on:{"click":function($event){$event.stopPropagation();return _vm.open(row)}}}):_vm._e(),(_vm.enableEdit && !row.canBeDeleted)?[_c('div',{staticClass:"minus"},[(_vm.enableEdit)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: 'Exclude from order',
                classes: ['tooltip-general']
              }),expression:"{\n                content: 'Exclude from order',\n                classes: ['tooltip-general']\n              }"}],staticClass:"ml-8",attrs:{"icon":"minus-circle"},on:{"click":function($event){$event.stopPropagation();return _vm.rowClick(row)}}}):_vm._e()],1)]:_vm._e(),(_vm.enableEdit && row.canBeDeleted)?[_c('div',{staticClass:"minus"},[(_vm.enableEdit)?_c('font-awesome-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: 'Remove from order',
                classes: ['tooltip-general']
              }),expression:"{\n                content: 'Remove from order',\n                classes: ['tooltip-general']\n              }"}],staticClass:"ml-12",attrs:{"icon":"trash"},on:{"click":function($event){$event.stopPropagation();return _vm.deleteProduct(row)}}}):_vm._e()],1)]:_vm._e(),(_vm.hasDeclinedIcon)?_c('font-awesome-icon',{staticClass:"declined-minus ml-8",attrs:{"icon":"minus-circle"}}):_vm._e()],2)]}},{key:"cell-quantity",fn:function({ row: { quantity } }){return [_vm._v(" "+_vm._s(quantity)+" ")]}}])}),_c('edit-details-modal',{ref:"editDetails",attrs:{"order":_vm.order,"product":_vm.selectedProduct,"is-public-order":_vm.isPublicOrder,"type":_vm.type,"token":_vm.token},on:{"editedProduct":() => _vm.$emit('editedProduct')}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }