<script>
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzButton from '@/components/ui/Button/EzButton';
import { deleteUnit } from '@/components/v3/patterns/VUnitsSettings/UnitSettingsActions';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
  name: 'RemoveUnitModal',
  components: {
    EzConfirmationModal,
    EzButton,
  },
  props: {
    unit: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  methods: {
    open() {
      this.$refs.deleteModal.open();
    },
    close() {
      this.$refs.deleteModal.close();
      this.$emit('onClose');
    },
    async onRemove() {
      const { message } = await deleteUnit({ unitId: this.unit.id });
      this.$emit(message);
      this.close();
    },
  },
};
</script>

<template>
  <ez-confirmation-modal
    class="remove-unit-modal"
    ref="deleteModal"
    type="red"
  >
    <template #title>Remove {{ unit.label }} Unit?</template>
    <template #content>
      <p>You are about to remove {{ unit.label }} unit from your list.</p>
    </template>
    <template #footer>
      <ez-button @click="close" type="link">Cancel</ez-button>
      <ez-button @click="onRemove" type="red">Remove Unit</ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<style lang="scss" scoped>
.remove-unit-modal {
  @include z-index('modal', 10);
  :deep() {
    .modal {
      @include z-index('modal', 20);
    }
  }
}
</style>
