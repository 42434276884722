<template>
  <main>
    <Header
      :count="metaOrders.totalCount"
      :count-products="metaProducts.totalCount"
      :count-processing="stats.totalCount"
      :amount="metaOrders.totalAmount"
      :filters-applied="filters"
      :is-products="['venue-orders-products'].includes($route.name)"
      :is-processing="['venue-pending-updates'].includes($route.name)"
      :to-hide-total="['venue-orders-standing', 'venue-pending-updates'].includes($route.name)"
      :to-show-invoices="$route.name === 'venue-pending-updates' && !$permission.isFree && !loading"
      :show-label="!!totalLabel"
    >
      <template v-if="['venue-orders-history'].includes($route.name)" #title>History</template>
      <template v-else-if="['venue-orders-products'].includes($route.name)" #title
        >Ordering</template
      >
      <template v-else-if="['venue-pending-updates'].includes($route.name)" #title
        >Processing</template
      >
      <template v-else-if="['venue-orders-standing'].includes($route.name)" #title
        >Automated Orders</template
      >
      <template v-if="totalLabel" #total>{{ totalLabel }}</template>
    </Header>
    <ez-nav>
      <router-view
        @updateFilter="updateFilter"
        @updateStats="updateStats"
        @loadingEnds="clearLoading"
        @loadingStarts="setLoading"
      />
    </ez-nav>
  </main>
</template>

<script>
import { mapState } from 'vuex';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import EzNav from '@/components/ui/Nav';
import Header from './Header.vue';

export default {
  components: {
    EzNav,
    Header,
  },
  data() {
    return {
      filters: {},
      stats: {},
      loading: false,
      outletCy,
    };
  },
  computed: {
    ...mapState('entities/orders', {
      metaOrders: state => state.meta,
    }),
    ...mapState('entities/products', {
      metaProducts: state => state.meta,
    }),
    ...mapState('entities/users', ['context']),
    totalLabel() {
      if (['venue-orders-products'].includes(this.$route.name)) return 'Total ordered this month';
      if (!['venue-orders-history'].includes(this.$route.name)) return false;
      const appliedFilters = Object.entries(this.filters).filter(([key, val]) => {
        /**
         * Checks if the current values for date filters are the same as the default ones.
         * If not equal, that means the filter has changed and should be accounted for.
         */
        if (['scheduledFrom', 'scheduledTo'].includes(key)) {
          const filter = key.startsWith('scheduled') ? 'scheduled' : 'month';
          const property = key.split('scheduled').filter(Boolean)[0]; // 'from' | 'to'
          return val && val !== this.defaultDateFilters?.[filter]?.[property];
        }

        if (Array.isArray(val)) return !!val.length;
        return !!val;
      });

      return appliedFilters.length || 'scheduledFrom' in this.filters
        ? 'Total for selected filters'
        : 'Total ordered this month';
    },
  },
  methods: {
    updateFilter(filters) {
      this.filters = filters;
    },
    updateStats(stats) {
      this.stats = stats;
    },
    setLoading() {
      this.loading = true;
    },
    clearLoading() {
      this.loading = false;
    },
  },
  created() {
    if (this.$route.params.id && this.$route.name === 'venue-orders') {
      this.$router.push({
        name: 'venue-orders-history',
        params: {
          id: this.$route.params.id,
        },
        query: this.$route.query,
      });
    } else if (this.$route.name === 'venue-orders') {
      this.$router.push({ name: 'venue-orders-products' });
    }
  },
  updated() {
    if (this.$route.params.id && this.$route.name === 'venue-orders') {
      this.$router.push({
        name: 'venue-orders-history',
        params: {
          id: this.$route.params.id,
        },
        query: this.$route.query,
      });
    } else if (this.$route.name === 'venue-orders') {
      this.$router.push({ name: 'venue-orders-products' });
    }
  },
};
</script>

<style scoped lang="scss">
:deep() .navigation .navigation__nav {
  display: none;
}
:deep() .drawer {
  @extend %order-drawer;
}

.notification {
  display: inline-block;
  margin-left: 6px;
  @include size(6px);
  border-radius: 50%;
  background-color: $color-primary-blue;
}

:deep() .top-space {
  margin-top: px-to-rem(34px);
}

:deep() {
  .order-header__stat {
    flex-direction: column-reverse;

    small {
      text-transform: initial;
    }
  }
}
</style>
