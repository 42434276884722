<template>
  <div :class="['overview-paper', { 'overview-paper--fullWidth' : fullWidth }]">
    <div :class="[
      'overview-paper__title',
      { 'overview-paper__title--has-title': hasTitle }]">
      <div v-if="hasTitle" class="title-prefix">
        <slot name="title-prefix"></slot>
        <h4><slot name="title"></slot></h4>
      </div>
      <div class="overview-paper__actions"><slot name="actions"></slot></div>
    </div>
    <div class="overview-paper__content">
      <slot></slot>
    </div>
    <div v-if="hasFooter" class="overview-paper__footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    hasTitle() {
      return !!this.$slots.title;
    },
    hasFooter() {
      return !!this.$slots.footer;
    },
  },
};
</script>

<style scoped lang="scss">
.overview-paper {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 1rem;
  border: 1px solid $color-gray-E9;
  border-radius: 3px;
  box-shadow: 0 1px 1px 0 #DEE1E4;

  &--fullWidth {
    max-width: 100%;
  }

  &__title {
    @extend %flex-center;
    justify-content: space-between;
    h4 {
      display: inline-flex;
      align-items: center;
      @include font-size(12px, 14px);
      margin: 0;
      padding: 0;
      color: $color-gray-6C;
      font-weight: 700;
      text-transform: uppercase;
    }

    &--has-title {
      margin-bottom: 1.125rem;
    }

    .title-prefix {
      display: flex;
      align-items: center;

      img {
        margin-right: $spacing-08;
        width: 20px;
        height: 20px;
      }
    }
  }
  &__actions {
    margin-left: auto;

    > a,
    > .button {
      @extend %flex-center;
      @include font-size(12px, 14px);
      margin: 0;
      padding: 0;
      color: $color-gray-6C;
      font-weight: 400;
      text-decoration: none;

      :deep() svg {
        margin-right: .375rem;
      }
    }
  }
  + .overview-paper {
    margin-top: .75rem;
  }
}
</style>
