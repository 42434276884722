<script>
const PLACEMENT_ARRAY = [
  'auto',
  'auto-start',
  'auto-end',
  'top',
  'top-start',
  'top-end',
  'right',
  'right-start',
  'right-end',
  'bottom',
  'bottom-end',
  'left',
  'left-start',
  'left-end',
];

/**
 * @version 1.0.0
 * @since
 *
 * Component is made so tooltip can be used in render function.
 * If you need tooltip, better use v-tooltip directive.
 *
 * More info about v-tooltip directive:
 * @see {@link https://github.com/Akryum/v-tooltip}
 *
 * TODO: add more props so it matches v-tooltip options;
 */
export default {
  name: 'VTooltipComponent',
  props: {
    content: {
      required: true,
      type: String,
      default: '',
    },
    classes: {
      required: false,
      // type: String || Array,
      default: () => ['tooltip-general'],
    },
    placement: {
      required: false,
      type: String,
      default: 'auto',
      validator: val => !!PLACEMENT_ARRAY.find(p => p === val),
    },
  },
};
</script>
<template>
  <span
    v-tooltip="{
      content,
      classes,
      placement,
    }"
  >
    <slot></slot>
  </span>
</template>
<style scoped lang="scss"></style>
