<script>
/* eslint-disable arrow-body-style */
/**
 * Calendar component
 * @version 1.0.0
 * @since
 */

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import dayjs from 'dayjs';
import { endOfWeek, isCorrectOrderEqual } from '@/util/utils';

const navigationPrevButton = (h, { previous }) =>
  h(
    'button',
    {
      class: 'vcalendar__button vcalendar__button--prev',
      on: previous ? { click: previous } : null,
      attrs: { type: 'button' },
    },
    [h(FontAwesomeIcon, { props: { icon: 'angle-left' } })],
  );

const navigationNextButton = (h, { next }) =>
  h(
    'button',
    {
      class: 'vcalendar__button vcalendar__button--next',
      on: next ? { click: next } : null,
      attrs: { type: 'button' },
    },
    [h(FontAwesomeIcon, { props: { icon: 'angle-right' } })],
  );

/**
 * Generate the days of the week.
 * @param {number} weekStartOn
 */
function daysOfWeek(weekStartOn) {
  const dayNumbers = new Array(7).fill(null).map((_, i) => (weekStartOn + i) % 7);
  return dayNumbers.map(day => dayjs().day(day).format('ddd'));
}

/**
 * Generate a matrix of dates. Also based on where the first date starts add null for
 * all previous days in the week.
 * @param {Date|dayjs.Dayjs|number} date
 * @param {number} weekStartsOn
 * @return {[[]]}
 */
function dateMatrix(date, weekStartsOn) {
  const monthStart = dayjs(date).startOf('M');
  const monthEnd = dayjs(date).endOf('M');
  const month = [];

  let currDate = monthStart;
  while (dayjs(currDate).isBefore(monthEnd)) {
    month.push([]);
    const currWeekEnd = endOfWeek(currDate, weekStartsOn);
    while (dayjs(currDate).isBefore(currWeekEnd) && dayjs(currDate).isBefore(monthEnd)) {
      month[month.length - 1].push(currDate);
      currDate = dayjs(currDate).add(1, 'd');
    }
  }

  // Fill with null the days in first week left from prev month.
  month[0].unshift(...Array(7 - month[0].length).fill(null));

  return month;
}

const CalendarDate = {
  functional: true,
  props: {
    selected: Boolean,
    intermediate: Boolean,
    potential: Boolean,
    date: [Date, Object],
    startDate: [Date, Object],
    endDate: [Date, Object],
    potentialNewRange: {
      validator(potentialNewRange) {
        return potentialNewRange.start != null && potentialNewRange.end != null;
      },
    },
    min: [Date, Object, String],
    max: [Date, Object, String],
    deliveryDays: Array,
    dataCy: String,
  },
  render(h, context) {
    const {
      props: { startDate, endDate, potentialNewRange, date, deliveryDays, min, max, dataCy },
      listeners: {
        mouseover = () => null,
        mouseleave = () => null,
        select = () => null,
        blur = () => null,
        focus = () => null,
      },
      data: { nativeOn },
    } = context;
    const isBeforeStart = dayjs(date).isBefore(startDate);
    const isAfterEnd = dayjs(date).isAfter(endDate);
    const isBeforeMin = dayjs(date).isBefore(min);
    const isAfterMax = dayjs(date).isAfter(max);
    const isIntermediate = !isBeforeStart && !isAfterEnd;
    const isStart = dayjs(date).isSame(startDate, 'd');
    const isEnd = dayjs(date).isSame(endDate, 'd');
    const selected = isStart || isEnd;
    let disabled; // disabled class if disabledWeekend or only disabledSunday
    let disabledBeforeMin; // disabled class if before min date
    let disabledAfterMax; // disabled class if after max date
    if (min) disabledBeforeMin = isBeforeMin;
    if (max) disabledAfterMax = isAfterMax;
    if (deliveryDays?.length < 7) {
      if (!deliveryDays.includes('Monday')) disabled = disabled || dayjs(date).day() === 1;
      if (!deliveryDays.includes('Tuesday')) disabled = disabled || dayjs(date).day() === 2;
      if (!deliveryDays.includes('Wednesday')) disabled = disabled || dayjs(date).day() === 3;
      if (!deliveryDays.includes('Thursday')) disabled = disabled || dayjs(date).day() === 4;
      if (!deliveryDays.includes('Friday')) disabled = disabled || dayjs(date).day() === 5;
      if (!deliveryDays.includes('Saturday')) disabled = disabled || dayjs(date).day() === 6;
      if (!deliveryDays.includes('Sunday')) disabled = disabled || dayjs(date).day() === 0;
    }
    const potential =
      potentialNewRange &&
      isCorrectOrderEqual(potentialNewRange.start, date, potentialNewRange.end) &&
      !selected;
    // if our value for end date is null we dont need to mark intermediate dates
    const intermediate = endDate && isIntermediate && !selected && !isEnd && !isStart;
    const beforeStart =
      potentialNewRange && dayjs(potentialNewRange.start).isBefore(startDate, 'd') && isStart;
    const afterEnd =
      potentialNewRange && dayjs(potentialNewRange.end).isAfter(endDate, 'd') && isEnd;

    const display = dayjs(date).format('D');
    const dataCyDate = dayjs(date).format('DD_MM_YYYY');
    return h(
      'td',
      {
        class: {
          selected,
          isStart,
          isEnd,
          intermediate,
          potential,
          beforeStart,
          afterEnd,
          isAfterEnd,
          isBeforeStart,
          disabled,
          disabledBeforeMin,
          disabledAfterMax,
        },
        domProps: { tabIndex: 0 },
        attrs: { 'data-cy': dataCy ? `${dataCy}-date-${dataCyDate}` : '' },
        on: {
          mouseover,
          mouseleave,
          click: select,
          blur,
          focus,
          keypress: event => {
            if (event.keyCode === 13) {
              select();
            } else if (event.keyCode === 32) {
              select();
            }
          },
          ...nativeOn,
        },
      },
      [h('span', { class: 'vcalendar__date' }, display)],
    );
  },
};

const CalendarBody = {
  functional: true,
  render(h, context) {
    const {
      props: {
        displayedDate,
        potentialNewRange,
        endDate,
        startDate: startDateProp,
        weekStartsOn: weekStartsOnProp = 1, // 0 = sunday, 1 = monday
        deliveryDays,
        min,
        max,
        dataCy,
      },
      listeners: {
        select = () => null,
        mouseOverDate = () => null,
        mouseLeaveDate = () => null,
        // focus = () => null,
        // blur = () => null
      },
    } = context.data;

    let startDate;
    if (startDateProp != null) {
      startDate = startDateProp;
    } else {
      startDate = new Date(); // when out model is null we only mark today day like on native date picker no need to select it
    }

    const daysOfWeekMap = daysOfWeek(weekStartsOnProp);
    const thead = h('thead', null, [
      h(
        'tr',
        null,
        daysOfWeekMap.map(day => h('th', null, day)),
      ),
    ]);
    const tbody = h(
      'tbody',
      null,
      dateMatrix(displayedDate, weekStartsOnProp).map(week => {
        return h(
          'tr',
          null,
          week.map(date => {
            if (!date) return h('td', { class: 'empty' }); // Skip cell

            let disableDay;
            let selectDate = date;
            // disable weekend
            if (deliveryDays?.length < 7) {
              if (!deliveryDays.includes('Monday'))
                disableDay = disableDay || dayjs(date).day() === 1;
              if (!deliveryDays.includes('Tuesday'))
                disableDay = disableDay || dayjs(date).day() === 2;
              if (!deliveryDays.includes('Wednesday'))
                disableDay = disableDay || dayjs(date).day() === 3;
              if (!deliveryDays.includes('Thursday'))
                disableDay = disableDay || dayjs(date).day() === 4;
              if (!deliveryDays.includes('Friday'))
                disableDay = disableDay || dayjs(date).day() === 5;
              if (!deliveryDays.includes('Saturday'))
                disableDay = disableDay || dayjs(date).day() === 6;
              if (!deliveryDays.includes('Sunday'))
                disableDay = disableDay || dayjs(date).day() === 0;
              selectDate = !disableDay ? date : null;
            }
            // disable all dates before min
            if (min) {
              if (dayjs(date).isBefore(min)) {
                selectDate = null;
              }
            }
            // disable all dates after max
            if (max) {
              if (dayjs(date).isAfter(max)) {
                selectDate = null;
              }
            }

            return h(CalendarDate, {
              props: {
                date,
                startDate,
                endDate,
                potentialNewRange,
                deliveryDays,
                min,
                max,
                dataCy,
              },
              on: {
                select: () => select(selectDate),
                mouseover: () => mouseOverDate(date),
                mouseleave: () => mouseLeaveDate(date),
              },
            });
          }),
        );
      }),
    );

    return h('table', { class: 'vcalendar__table' }, [thead, tbody]);
  },
};

const Calendar = {
  name: 'VCalendar',
  functional: true,
  props: {
    hasNavigationPrev: Boolean,
    hasNavigationNext: Boolean,
    displayedDate: [Date, Object],
    weekStartsOn: [Number, String],
    startDate: [Date, Object],
    endDate: [Date, Object],
    potentialNewRange: {
      validator(potentialNewRange) {
        return potentialNewRange.start != null && potentialNewRange.end != null;
      },
    },
    deliveryDays: Array,
    min: [Date, Object, String],
    max: [Date, Object, String],
    dataCy: String,
  },
  render(h, context) {
    const {
      props: { hasNavigationPrev, hasNavigationNext, displayedDate },
      listeners: {
        previous,
        next,
        // select = () => null,
        // mouseOverDate = () => null,
        // mouseLeaveDate = () => null,
        // focus = () => null,
        // blur = () => null,
      },
    } = context;

    const prevBtn = hasNavigationPrev ? navigationPrevButton(h, { previous }) : null;
    const nextBtn = hasNavigationNext ? navigationNextButton(h, { next }) : null;
    const displayDate = h(
      'span',
      { class: 'vcalendar__title' },
      dayjs(displayedDate).format('MMMM YYYY'),
    );
    const header = h('div', { class: 'vcalendar__header' }, [prevBtn, displayDate, nextBtn]);

    return h('div', { class: 'vcalendar' }, [header, h(CalendarBody, context)]);
  },
};

export default Calendar;
</script>

<style lang="scss" scoped>
$date-width: 42px;
$date-height: 32px;
$date-range-bg-color: #f5f6fa;
$date-highlight-color: $color-primary-blue;

.vcalendar {
  display: inline-block;
  width: 294px;

  &__title {
    color: #252631;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: -0.25px;
    text-align: center;
  }
  &__header {
    position: relative;
    margin-bottom: 16px;
    text-align: center;
  }

  &__table {
    border-spacing: 0 4px;
  }

  :deep() .vcalendar__date {
    position: relative;
    z-index: 1;
  }

  &__button {
    position: absolute;
    color: #6c7995;
    cursor: pointer;

    &--next {
      right: 0;
    }
    &--prev {
      left: 0;
    }
  }
}

.vcalendar__table :deep() {
  th,
  td {
    position: relative;
    width: $date-width;
    height: $date-height;
    padding: 0;
    line-height: $date-height;
    text-align: center;
    cursor: pointer;
  }
  th {
    opacity: 0.64;
    color: #6c7995;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: -0.25px;
    line-height: 13px;
    text-transform: uppercase;
  }
  .disabled,
  .disabledBeforeMin,
  .disabledAfterMax {
    cursor: not-allowed;
    opacity: 0.4;
    &:hover {
      &::before {
        content: none;
      }
      &::after {
        content: none;
      }
    }
  }
}

// Cell coloring
.vcalendar__table :deep() td {
  // Circle
  &::after {
    content: '';
    @include absolute(top 0 left 50%);
    @include size(32px);
    border-radius: 50%;
    transform: translateX(-50%);
  }
  // Partial background
  &::before {
    content: '';
    height: 32px;
    background-color: $date-range-bg-color;
  }

  &.potential,
  &.beforeStart,
  &.afterEnd {
    background-color: $date-range-bg-color;
  }
  &.selected {
    color: #fff;
    &::after {
      background-color: $date-highlight-color;
    }
  }

  &.isStart,
  &.isBeforeStart:hover {
    &::before {
      @include absolute(top 0 left 50% right 0);
    }
  }
  &.isEnd,
  &.isAfterEnd:hover {
    &::before {
      @include absolute(top 0 right 50% left 0);
    }
  }

  &.isStart.isEnd {
    &::before {
      content: none;
    }
  }

  &:focus {
    outline: none;
  }

  &:hover:not(.empty) {
    color: $global-text-color;
    background-color: transparent;
    &::after {
      border: 2px solid $date-highlight-color;
      background-color: $date-range-bg-color;
    }
  }
  &.intermediate,
  &.intermediate:hover {
    background-color: $date-range-bg-color;
  }
}
</style>
