<template>
  <div class="autocomplete">
    <div class="autocomplete__input-wrapper">
      <label class="autocomplete__label" v-if="label" :for="id">{{ label }}</label>
      <div
        :class="[
          'autocomplete__input-group',
          { 'autocomplete__input-group--error': hasInputError },
          { 'autocomplete__input-group--focus': isFocused },
        ]"
      >
        <input
          :id="id"
          ref="input"
          v-model="input"
          :disable="disable"
          :placeholder="placeholder"
          class="autocomplete__input"
          @click="search"
          @input="search"
          @keydown.enter="enter"
          @keydown.tab="close"
          @keydown.up="up"
          @keydown.down="down"
          @keydown.esc="close"
          @focus="onFocus"
          @blur="onBlur"
          type="text"
          autocomplete="off"
          :data-cy="dataCy"
        />
        <button
          class="autocomplete__reset"
          v-if="selected"
          @click="reset"
          :data-cy="resetButtonDataCy"
        >
          <font-awesome-icon icon="times" />
        </button>
        <slot name="icon"></slot>
      </div>
      <div v-if="hasInputError && !showResults" class="autocomplete__error">
        {{ errorMsg }}
      </div>
      <div class="autocomplete__dropdown" v-if="showResults">
        <ul class="autocomplete__list">
          <li
            v-if="hasError"
            class="autocomplete__item autocomplete__item--error"
            :data-cy="resultDataCy ? `${resultDataCy}-error` : ''"
          >
            {{ error }}
          </li>
          <li
            v-if="hasFirstSlot"
            @click.prevent="select({}, { first: true })"
            class="autocomplete__item"
            :class="{ 'autocomplete__item--active': isSelected(-1) }"
            :data-cy="resultDataCy ? `${resultDataCy}-firstResult` : ''"
          >
            <slot name="firstResult" :input="input"></slot>
          </li>
          <li
            v-if="isEmptyState"
            class="autocomplete__item autocomplete__no-results"
            :data-cy="resultDataCy ? `${resultDataCy}-noResults` : ''"
          >
            <slot name="emptyState">No Results.</slot>
          </li>
          <li
            v-for="(result, index) in transformedResults"
            :key="index"
            @click.prevent="select(result)"
            :class="[
              'autocomplete__item',
              { 'autocomplete__item--active': isSelected(index) },
              { 'autocomplete__item--disabled': isAnyReasonIsDisabled(result) },
            ]"
            :data-cy="resultDataCy ? `${resultDataCy}-${index}` : ''"
          >
            <!-- @slot -->
            <slot name="result" :result="result" :index="index"></slot>
          </li>
          <li class="autocomplete__item" v-if="isLoading">Loading...</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import uuid from 'uuid/v4';
import httpService from '@/api/http';
import { debounce, isBoolean } from '@/util/utils';
import flash from '@/components/ui/FlashMessage';
import { LOADING_KEY } from '@/util/constants';

/**
 * Autocomplete from the given array or endpoint.
 * @version 1.0.0
 * @since 2.3.0
 */
export default {
  props: {
    /**
     * Provide a data source to fetch the results.
     * It can accept a `String` which will be used as a URL to fetch data
     * Or a `Array` and `Object` for direct source.
     */
    source: {
      type: [String, Array, Object],
      required: true,
    },
    /**
     * If API is not used as a source search value is required.
     * This is the property used to search inside the object.
     */
    searchProperty: {
      type: String,
    },
    /**
     * Input placeholder.
     */
    placeholder: {
      type: String,
      default: 'Search',
    },
    /**
     * Input label.
     */
    label: {
      type: String,
    },
    /**
     * If the input is disabled or not
     */
    disable: {
      type: Boolean,
      default: false,
    },
    /**
     * Custom render function for a different icon.
     */
    icon: {
      type: Function,
    },
    /**
     * Additional request headers.
     */
    requestParams: {
      type: Object,
    },
    hasConnectedParam: {
      type: Boolean,
      default: true,
    },
    /**
     * Minimum length of the search string before search is done.
     */
    minLength: {
      type: Number,
      default: 2,
    },
    /**
     * Time to debounce typing before sending the request.
     */
    debounceTime: {
      type: Number,
      default: 300,
    },
    /**
     * Transformer function to run the results through.
     * Useful if you want to additionally transform the result.
     */
    transformer: {
      type: Function,
    },
    showSelected: {
      type: Boolean,
      default: false,
      required: false,
    },
    /**
     * If this prop in the result is true, the result in the list won't be selectable.
     */
    disableResultPropName: {
      type: String,
      default: '',
      required: false,
    },
    /**
     * Pass Object with properties: 'property'=String and 'statuses'=Array of strings.
     * If provided 'statuses' Array includes 'property' status for item which you want to select,
     * it will be disabled.
     */
    disableBasedOnStatus: {
      type: Object,
      default: () => {},
      required: false,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
    resetButtonDataCy: {
      type: String,
      required: false,
      default: '',
    },
    resultDataCy: {
      type: String,
      required: false,
      default: '',
    },
    errorMsg: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      id: '',
      input: '',
      results: [],
      isLoading: false,
      selectedItem: -1,
      isFocused: false,
      hasError: false,
      eventListeners: false,
      error: null,
      selected: false,
    };
  },
  computed: {
    hasFirstSlot() {
      return !!this.$scopedSlots.firstResult;
    },
    hasMinLengthQuery() {
      return this.input?.length >= this.minLength;
    },
    showResults() {
      return (
        this.hasMinLengthQuery &&
        (this.transformedResults.length || this.hasError || this.hasFirstSlot || this.isLoading)
      );
    },
    hasResults() {
      return Array.isArray(this.transformedResults) && this.transformedResults.length > 0;
    },
    isEmptyState() {
      return (
        !this.hasError &&
        !this.hasResults &&
        !this.isLoading &&
        this.isFocused &&
        !this.hasFirstSlot
      );
    },
    initialSelectedValue() {
      return this.hasFirstSlot ? -2 : -1;
    },
    query() {
      return this.input.toLowerCase();
    },
    /**
     * Given an outside transformer, transform the results.
     * @return {[]|*}
     */
    transformedResults() {
      if (!this.transformer) return this.results;
      return this.transformer(this.results);
    },
    hasInputError() {
      return !!this.errorMsg;
    },
  },
  methods: {
    isSelected(index) {
      return index === this.selectedItem;
    },
    isAnyReasonIsDisabled(item) {
      return this.isDisabled(item) || this.isDisabledBasedOnStatus(item);
    },
    isDisabled(item) {
      const disabled = item[this.disableResultPropName];
      return isBoolean(disabled) ? disabled : false;
    },
    isDisabledBasedOnStatus(item) {
      const status = item[this.disableBasedOnStatus?.property];
      return !!this.disableBasedOnStatus?.statuses.includes(status);
    },
    search() {
      if (this.input === '') this.close();
      if (!this.hasMinLengthQuery) return [];
      this.selectedItem = this.initialSelectedValue;
      this.setupEventListeners();
      if (typeof this.source === 'string') return this.requestSearch();
      if (Array.isArray(this.source)) return this.arraySearch();
      return [];
    },
    requestSearch: debounce(function deb() {
      if (!this.hasMinLengthQuery) return;
      this.isLoading = true;
      this.request();
    }, 300),

    async request() {
      try {
        const { data } = await httpService.get(this.source, {
          headers: {
            loadingKey: LOADING_KEY.EZ_AUTOCOMPLETE,
          },
          params: {
            term: this.query,
            limit: 10,
            ...(this.hasConnectedParam && { connected: false }),
            ...this.requestParams,
          },
        });

        this.results = data.data;
        this.onResult();
        this.isLoading = false;
      } catch (e) {
        flash.error({
          title: 'Something went wrong',
          message: e.response?.data?.error?.message || '',
        });
        this.isLoading = false;
      }
    },

    arraySearch() {
      if (!this.query) {
        this.results = this.source;
      } else {
        this.results = this.source.filter(item => this.getSearchValue(item).includes(this.query));
      }
      this.onResult();
    },

    getSearchValue(obj) {
      return obj[this.searchProperty].toLowerCase();
    },

    onResult() {
      this.$emit('results', { results: this.results });
      this.loading = false;
    },
    up() {
      if (this.selectedItem === this.initialSelectedValue) {
        this.selectedItem = this.results.length - 1;
      }
      this.selectedItem =
        this.selectedItem === this.initialSelectedValue + 1
          ? this.results.length - 1
          : this.selectedItem - 1;
    },
    down() {
      this.selectedItem =
        this.selectedItem === this.results.length - 1
          ? this.initialSelectedValue + 1
          : this.selectedItem + 1;
    },
    enter() {
      if (this.input === '') return;
      if (this.hasFirstSlot && this.selectedItem === -1) this.select({}, { first: true });
      else this.select(this.results[this.selectedItem]);
    },
    select(obj, other = {}) {
      if (!obj) {
        return;
      }
      if (this.isAnyReasonIsDisabled(obj)) {
        return;
      }
      /**
       * When an item is selected
       */
      this.$emit('selected', { selected: obj, query: this.input, ...other });
      this.close();
      // Show selected value
      if (this.showSelected) {
        this.selected = true;
        this.input = obj[this.searchProperty];
      }
      this.$refs.input.blur();
    },
    reset() {
      this.clear();
      this.$emit('selected', { selected: null, query: '' });
    },
    clear() {
      this.input = '';
      this.error = null;
      this.selected = false;
    },
    close() {
      this.clear();
      this.removeEventListeners();
      this.results = [];
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    focus() {
      this.$refs.input.focus();
    },
    clickOutsideListener(event) {
      if (this.$el && !this.$el.contains(event.target)) {
        this.close();
      }
    },
    setupEventListeners() {
      if (this.eventListeners) return;
      this.eventListeners = true;
      document.addEventListener('click', this.clickOutsideListener, true);
    },
    removeEventListeners() {
      this.eventListeners = false;
      document.removeEventListener('click', this.clickOutsideListener, true);
    },
  },
  mounted() {
    this.id = uuid();
    this.selectedItem = this.initialSelectedValue;
  },
};
</script>

<style lang="scss" scoped>
.autocomplete {
  &__input-wrapper {
    position: relative;
  }

  &__label {
    display: block;
    margin-bottom: 0.375rem;
    color: $label-color;
    @include font-size($label-font-size, $label-line-height);
    font-weight: 500;
    text-transform: capitalize;
  }

  &__input-group {
    display: flex;
    align-items: center;
    height: $input-height;
    padding: 0 $input-padding-x;
    border-radius: $input-border-radius;
    border: 1px solid $input-border-color;
    color: $input-color;
    font-weight: 400;

    &--focus {
      border: $input-focus-border;
    }

    &--error {
      border-color: $input-border-error-color;
    }
  }

  &__error {
    margin-top: $spacing-08;
    color: $input-border-error-color;
    @include font-size(12px);
  }

  &__input {
    @extend %input-reset;
    width: 100%;
    outline: none;

    &::placeholder {
      color: $input-placeholder-color;
    }

    &:focus {
      outline: none;
    }

    &:not(:read-only):focus,
    &::placeholder {
      margin-left: -1px;
    }

    &:read-only,
    &:disabled {
      background-color: $input-disabled-bg-color;
      border: 0;
      &::placeholder {
        color: $input-disabled-color;
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  &__reset {
    color: #6c7995;
    cursor: pointer;
  }

  &__dropdown {
    position: absolute;
    top: 100%;
    margin-top: $dropdown-top;
    max-height: 20rem;
    overflow-y: auto;
    border: 1px solid #dee1e4;
    background-color: $color-white;
    border-radius: $border-radius;
    padding: $dropdown-padding-y $dropdown-padding-x;
    box-shadow: $dropdown-shadow;
    width: 100%;
    @include z-index('autocomplete');
  }

  &__list {
    @extend %ul-reset;
  }

  &__item {
    padding: 8px 12px;
    cursor: pointer;

    &:hover,
    &--active {
      background-color: $color-gray-F5;
    }
    &--error {
      color: $color-primary-dark-red;
    }
  }

  &__label {
    @extend %gray-label;
  }
}

.autocomplete__item--disabled {
  cursor: not-allowed;
}
</style>
