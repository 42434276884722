<template>
  <ez-account-menu :data-cy="outletCy.MAIN_NAVIGATION.BUTTON__ACCOUNT_SETTINGS">
    <ez-entity-info
      :imgUrl="loggedUser.group.logo"
      imgWidth="2rem"
      imgHeight="2rem"
      imgBorderRadius="50%"
    >
      <span>{{ context?.name }}</span>
    </ez-entity-info>
    <template #dropdown>
      <div class="dropdown-section">
        <div class="dropdown-section__label">{{ loggedUser.name }}</div>
        <ez-entity-info
          :imgUrl="loggedUser.group.logo"
          imgWidth="2.25rem"
          imgHeight="2.25rem"
          imgBorderRadius="50%"
        >
          <div>{{ loggedUser.group.name }}</div>
        </ez-entity-info>
      </div>
      <div v-if="showContextSwitch" class="dropdown-section context-section">
        <hr />
        <div class="dropdown-section__label">Switch to</div>
        <ez-button
          v-if="loggedUser.scope === 'venue-group'"
          type="link"
          formType="button"
          isFullWidth
          class="venue-context-btn"
          @click="updateContext(loggedUser.group, true)"
        >
          Group View
        </ez-button>
        <ez-button
          v-for="venue in loggedUser.venues"
          :key="venue.id"
          type="link"
          formType="button"
          isFullWidth
          class="venue-context-btn"
          @click="updateContext(venue)"
          :data-cy="`${outletCy.MAIN_NAVIGATION.BUTTON__SWITCH_TO_OUTLET}-${camalizeName(
            venue.name,
          )}`"
        >
          {{ venue.name }}
          <ez-badge
            type="blue-inverted"
            :count="(venue.actionsCount || 0) + (venue.pendingInvoicesCount || 0)"
          />
        </ez-button>
      </div>
    </template>
    <template #userSettings>
      <router-link :to="{ name: 'manage-accounts' }" class="button button--link">
        Manage Accounts
      </router-link>
      <router-link
        :to="{ name: 'venue-user-settings' }"
        class="button button--link"
        :data-cy="outletCy.MAIN_NAVIGATION.BUTTON__MY_SETTINGS"
      >
        My Settings
      </router-link>
      <router-link
        v-if="hasGroupPermissions && $permission.has('manageSettings')"
        :to="{ name: 'venue-group-settings' }"
        class="button button--link"
        :data-cy="outletCy.MAIN_NAVIGATION.BUTTON__GROUP_SETTINGS"
      >
        Group Settings
      </router-link>
      <ez-button
        v-if="$permission.has('manageBilling')"
        type="link"
        isFullWidth
        class="venue-context-btn"
        @click="redirectToBillingUrl"
        >Billing</ez-button
      >
      <ez-button type="link" isFullWidth class="venue-context-btn" @click="goToFeaturePage"
        >Features List</ez-button
      >
    </template>
  </ez-account-menu>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import flash from '@/components/ui/FlashMessage';
import EzButton from '@/components/ui/Button';
import EzEntityInfo from '@/components/ui/EntityInfo';
import { EzAccountMenu } from '@/views/platform/user-settings';
import { resetState } from '@/store';
import EzBadge from '@/components/ui/Badge';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import { camalize } from '@/util/utils';
import httpService from '@/api/http';

export default {
  components: {
    EzAccountMenu,
    EzButton,
    EzEntityInfo,
    EzBadge,
  },
  data() {
    return {
      outletCy,
    };
  },
  computed: {
    showContextSwitch() {
      return this.loggedUser.venues;
    },
    hasGroupPermissions() {
      return this.loggedUser.scope === 'venue-group';
    },
    ...mapState('entities/users', ['loggedUser', 'context', 'contextId']),
  },
  methods: {
    redirect(url) {
      window.open(url, '_blank');
    },
    async redirectToBillingUrl() {
      const { data } = await httpService.get('/venue-group/billing-url');
      if (data.data && data.data.url) {
        window.open(data.data.url, '_blank');
      }
    },
    goToFeaturePage() {
      this.$router.push({ name: 'venue-features-list' });
    },
    camalizeName(name) {
      return camalize(name);
    },
    async updateContext(venue, isGroup = false) {
      if (venue.id === this.contextId) {
        return;
      }
      try {
        await this.switchContext({
          context: isGroup ? 'venue-group' : 'venue',
          contextId: venue.id,
        });
        this.SWITCH_CONTEXT(venue);
        flash.success({
          title: `Switched to ${venue.name}`,
        });
        this.EMPTY_CART();
        this.initVenue();
      } catch (e) {
        flash.fail({
          title: `Unable to switch to ${venue.name}'`,
        });
      }
    },
    ...mapActions('entities/users', ['initVenue', 'managerLogout', 'switchContext']),
    onLogoutClick() {
      this.managerLogout().finally(() => {
        localStorage.clear();
        resetState();
        this.$router.replace({ name: 'platform-login' });
      });
    },
    ...mapMutations('entities/users', ['SWITCH_CONTEXT', 'ENABLE_GROUP_VIEW_MODE']),
    ...mapMutations('cart', ['EMPTY_CART']),
  },
};
</script>

<style lang="scss" scoped>
.account-menu-button {
  padding-left: 0;
}
.venue-context-btn {
  min-height: 36px;
  height: auto;
}
</style>
