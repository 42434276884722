var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"venue-list"},[_c('ez-table',{attrs:{"data":_vm.venues,"columns":['name', ...(_vm.canDelete ? ['actions'] : [])],"columnProps":{
      name: { class: 'cell-name' },
    },"headers":{
      name: () => `${_vm.$t('global.venue')} name`,
      email: () => 'Email address',
      registeredAt: () => 'Date registered',
      status: () => 'Status',
      actions: () => '',
    },"removeButton":_vm.canDelete},on:{"removeItem":_vm.onRemoveItem,"rowClick":_vm.openVenue},scopedSlots:_vm._u([{key:"cell-name",fn:function({ row }){return [_c('span',{attrs:{"title":row.name}},[_vm._v(_vm._s(row.name))])]}}])}),(_vm.isLoadingMoreVenues)?_c('div',{staticClass:"u-text-center mt-12"},[_c('ez-spinner')],1):_vm._e(),(_vm.hasMoreVenues)?_c('ez-load-more',{on:{"loadMore":_vm.fetchMoreVenues}}):_vm._e(),(_vm.$permission.isPremium && _vm.canCreate)?_c('ez-button',{attrs:{"type":"secondary","form-type":"button","isFullWidth":"","disabled":_vm.createDisabled},on:{"click":_vm.addVenue}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" Add "+_vm._s(_vm._f("lowercase")(_vm.$t('global.venue')))+" ")],1):(_vm.$permission.isPremium)?_c('ez-button',{attrs:{"type":"secondary","form-type":"button","isFullWidth":""},on:{"click":_vm.requestOutlets}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}}),_vm._v(" Request additional "+_vm._s(_vm._f("lowercase")(_vm.$t('global.venues')))+" ")],1):_vm._e(),_c('venue-modal',{ref:"createModal",attrs:{"groupId":_vm.groupId,"venueId":_vm.selectedVenueId,"venueData":_vm.selectedVenue},on:{"submitSuccess":_vm.onCreateFormSubmit,"close":_vm.onCreateModalClose}}),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }