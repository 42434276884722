<script>
export default {
  name: 'VIconBadge',
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: 'gray',
    },
  },
};
</script>

<template>
  <div :class="`icon-badge icon-badge--${color}`">
    <font-awesome-icon :icon="icon"/>
  </div>
</template>

<style lang="scss" scoped>
.icon-badge {
  position: relative;
  @extend %flex-center;
  flex-shrink: 0;
  justify-content: center;
  @include size(32px);
  border-radius: 50%;
  border: 1px solid #E9EBF2;
  background-color: #F5F6FA;
  color: #6c7994;

  &--completed,
  &--in_dispute,
  &--pending { border: 0; }

  &--green {
    color: #1BA975;
    background-color: #DAF1E9;
  }

  &--red {
    color: #F73731;
    background-color: #FEDFDE;
  }

  &--declined {
    color: #F73731;
  }

  &--out_for_delivery {
    color: #EF9B13;
  }

  &--accepted {
    color: #1BA975;
  }

  &--partially_accepted {
    color: #4D7CFE;
  }

  &--yellow-dark {
    color: #EF9B13;
    background-color: #FEF6DA;
  }

  &--orange {
    color: #FC773B;
    background-color: #FBE7C7;
  }

  &--purple{
    color: #983BBD;
    background-color: #EEDFF4;
  }

  &--blue {
    background-color: #E2EAFF;
    color: #4D7CFE;
  }
  &--gray {
    color: #6C7995;
    background-color: #ECEEF5;
  }
  &--yellow {
    color: #F8C61B;
    background-color: #FEF6DA;
  }
}
</style>
