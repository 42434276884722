var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ez-user-list',{attrs:{"users":_vm.users,"columns":['name', 'email'],"columnProps":{
      venue: { class: 'venue-cell' },
      role: { class: 'role-cell' },
    },"headers":{
      accountName: () => 'Venue',
      venue: () => _vm.$t('global.venue'),
    }},on:{"addUser":_vm.onAddUser,"userClick":_vm.onUserClick,"removeUser":_vm.onRemoveUser},scopedSlots:_vm._u([{key:"cta",fn:function(){return [_vm._v("Add Contact")]},proxy:true}])},[_c('new-supplier-user-modal',{ref:"userModal",attrs:{"entity":"Contact","formKey":_vm.formKey,"formAction":_vm.formActionModal,"user":_vm.user,"isEmailRequired":false},on:{"success":_vm.onSubmitSuccess}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }