<script>
/**
   * index
   * @version 1.0.0
   * @since
   */

export default {};
</script>
<template>
  <div class="stats-boxes"><slot/></div>
</template>
<style lang="scss" scoped>
.stats-boxes {
  display: flex;
  align-items: center;
  padding: 18px 0;

  background-color: #F5F6FA;
  border-radius: $border-radius;

  :deep() .stats-box {
    padding-top: 0;
    padding-bottom: 0;
    flex: 1;
    border-right: 1px solid #DDDFE6;

    &:last-child { border-right: 0; }
  }
}
</style>
