<script>
import { wizardListenerMixin } from '@/mixins/wizard';
import Paper from '@/components/layout/WizardOverviewPaper';
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown';
import EzButton from '@/components/ui/Button/EzButton';
import CreateNewUser from '@/views/platform/venue/suppliers/onboarding/CreateNewUser';
import { mapActions, mapState, mapMutations } from 'vuex';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  mixins: [wizardListenerMixin],
  components: {
    Paper,
    EzSimpleDropdown,
    EzButton,
    CreateNewUser,
  },
  props: {
    supplierId: {
      type: Number,
    },
  },
  data() {
    return {
      financePlaceholder: 'Select User',
      orderingPlaceholder: 'Select User',
      selectedFinance: {},
      selectedOrdering: {},
      contacts: [],
      financeNewUser: false,
      orderingNewUser: false,
      formFinanceValid: false,
      formOrderingValid: false,
    };
  },
  computed: {
    ...mapState('entities/users', [
      'contextId',
    ]),
    ...mapState('entities/venues', [
      'onboarding',
    ]),
    isNextEnabled() {
      return this.formFinanceValid && this.formOrderingValid;
    },
    action() {
      return `/venues/${this.contextId}/users`;
    },
    isSelectedFinance() {
      return Object.keys(this.onboarding.finance).length !== 0;
    },
    isSelectedOrdering() {
      return Object.keys(this.onboarding.ordering).length !== 0;
    },
    nextStepEnabled() {
      return ((this.isSelectedFinance || this.isSelectedOrdering)
        && (this.orderingNewUser || this.financeNewUser))
        || (this.isSelectedFinance && this.isSelectedOrdering);
    },
    filteredContacts() {
      return this.contacts
        .filter(con => con.id !== this.selectedFinance.id)
        .filter(con => con.id !== this.selectedOrdering.id);
    },
  },
  watch: {
    nextStepEnabled: {
      handler() {
        this.enableNextStep();
      },
    },
    isNextEnabled: {
      handler(val) {
        if (val) this.enableNextStep();
        else this.disableNextStep();
      },
    },
  },
  methods: {
    async fetchContacts() {
      const { data } = await this.venueFetchOnboardingContacts({
        id: this.contextId,
      });
      this.contacts = data.data;
    },
    closeFinanceDropdown() {
      this.$refs.financeDropdown.toggle();
    },
    closeOrderingDropdown() {
      this.$refs.orderingDropdown.toggle();
    },
    selectFinance(user) {
      this.financePlaceholder = user.name;
      this.selectedFinance = user;
    },
    selectOrdering(user) {
      this.orderingPlaceholder = user.name;
      this.selectedOrdering = user;
    },
    onSelectFinance(user) {
      this.selectFinance(user);
      this.closeFinanceDropdown();
      this.updateStore();
    },
    onSelectOrdering(user) {
      this.selectOrdering(user);
      this.closeOrderingDropdown();
      this.updateStore();
    },
    createNewFinance() {
      this.financePlaceholder = 'Create New User';
      this.closeFinanceDropdown();
      this.selectedFinance = {};
      this.financeNewUser = true;
    },
    createNewOrdering() {
      this.orderingPlaceholder = 'Create New User';
      this.selectedOrdering = {};
      this.closeOrderingDropdown();
      this.orderingNewUser = true;
    },
    makeAdditionalData(role) {
      return {
        distributorId: this.supplierId,
        roleKey: role,
      };
    },
    onNextStep() {
      if (!this.financeNewUser && !this.orderingNewUser) {
        this.$emit('stepCompleted');
        return;
      }
      if (this.financeNewUser) this.$refs.financeNewUser.$refs.userForm.onSubmit();
      if (this.orderingNewUser) this.$refs.orderingNewUser.$refs.userForm.onSubmit();
    },
    addedFinance({ data }) {
      this.selectedFinance = data.data;
      this.updateStore();
      if (!this.orderingNewUser) this.$emit('stepCompleted');
    },
    addedOrdering({ data }) {
      this.selectedOrdering = data.data;
      this.updateStore();
      this.$emit('stepCompleted');
    },
    updateStore() {
      this.UPDATE_ONBOARDING_CONTACTS({
        isNewFinance: this.financeNewUser,
        isNewOrdering: this.orderingNewUser,
        finance: this.selectedFinance,
        ordering: this.selectedOrdering,
      });
    },
    ...mapActions('entities/venues', [
      'venueFetchOnboardingContacts',
    ]),
    ...mapMutations('entities/venues', [
      'UPDATE_ONBOARDING_CONTACTS',
    ]),
  },
  mounted() {
    this.fetchContacts();
    if (this.isSelectedFinance) this.selectFinance(this.onboarding.finance);
    if (this.isSelectedOrdering) this.selectOrdering(this.onboarding.ordering);
    if (this.isSelectedFinance && this.isSelectedOrdering) {
      this.enableNextStep();
    }
  },
};
</script>
<template>
  <div>
    <paper>
      <template #title>Finance Contact</template>
      <label for="finance-user" class="dropdown-label">Contact</label>
      <ez-simple-dropdown
        :placeholder="financePlaceholder"
        id="finance-user"
        ref="financeDropdown"
        class="select-user mt-8">
        <template #dropdown>
          <ul class="select-user__list">
            <li
              v-for="finance in filteredContacts"
              :key="finance.id"
              class="select-user__item"
              @click="onSelectFinance(finance)">
              {{ finance.name }}
            </li>
          </ul>
          <div class="select-user__item--last">
            <ez-button
              @click="createNewFinance"
              class="create-new-btn"
              type="blue-link"
            >
              Create New User
            </ez-button>
          </div>
        </template>
      </ez-simple-dropdown>
      <create-new-user
        @formValid="(val) => formFinanceValid = val"
        :action="action"
        formKey="addNewFinance"
        :additionalData="makeAdditionalData('venue_finance')"
        ref="financeNewUser"
        @complete="addedFinance"
        @cancel="financeNewUser = !financeNewUser"
        v-if="financeNewUser" />
    </paper>
    <paper>
      <template #title>Ordering Contact</template>
      <label for="ordering-user" class="dropdown-label">Contact</label>
      <ez-simple-dropdown
        :placeholder="orderingPlaceholder"
        id="ordering-user"
        ref="orderingDropdown"
        class="select-user mt-8">
        <template #dropdown>
          <ul class="select-user__list">
            <li
              v-for="ordering in filteredContacts"
              :key="ordering.id"
              class="select-user__item"
              @click="onSelectOrdering(ordering)">
              {{ ordering.name }}
            </li>
          </ul>
          <div class="select-user__item--last">
            <ez-button
              @click="createNewOrdering"
              class="create-new-btn"
              type="blue-link"
            >
              Create New User
            </ez-button>
          </div>
        </template>
      </ez-simple-dropdown>
      <create-new-user
        @formValid="(val) => formOrderingValid = val"
        :action="action"
        formKey="addNewOrdering"
        :additionalData="makeAdditionalData('venue_orderer')"
        @complete="addedOrdering"
        ref="orderingNewUser"
        @cancel="orderingNewUser = !orderingNewUser"
        v-if="orderingNewUser" />
    </paper>
  </div>
</template>
<style lang="scss" scoped>
  .dropdown-label {
    @include font-size(12px, 14px);
    font-weight: 500;
    color: $color-gray-6C;
  }

  .select-user {
    width: 100%;

    :deep() .ez-simple-dropdown {
      &__label {
        font-weight: 400;
      }
    }

    &__list {
      @extend %ul-reset;
      max-height: 108px;
      overflow-y: auto;
    }

    &__item {
      padding: 10px;
      @include font-size(14px, 16px);

      &:hover,
      &.selected { background-color: $color-gray-F5; }

      &--last {
        border-top: 1px solid #DEE1E4;
        padding: 0 10px;
      }
    }
    .create-new-btn {
      padding: 0;
    }
  }
</style>
