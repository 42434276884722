<template>
  <ez-form
    :action="action"
    :formKey="formKey"
    :loadingKey="loadingKey"
    :transformer="transformer"
    :method="method"
    :additional-data="{ ...(imgModified && { logo: '' }), ...additionalData }"
    submitType="multipart"
    @success="onSubmitSuccess"
    @error="onSubmitError"
    ref="distributorForm"
    class="distributor-form"
  >
    <ez-image-upload
      :formKey="formKey"
      :isRound="true"
      :previewUrl="distributorCopy.logo || defaultImage"
      :isDefaultImage="distributorCopy.isDefaultImage"
      :columnMode="avatarColumnMode"
      @change="imgModified = true"
      name="logo"
      @removeImage="onRemoveImage(distributorCopy)"
    >
      <template #addImage>
        <span>Change {{ $t('global.distributor') }} Logo</span>
      </template>
      <template #removeImage>
        <font-awesome-icon icon="times" />
        <span>Remove Logo</span>
      </template>
    </ez-image-upload>
    <ez-input
      :formKey="formKey"
      :value="distributorCopy.name"
      :label="`${$t('global.distributor')} name*`"
      @onChange="updateFormState('name', $event)"
      validators="required"
      :placeholder="`${$t('global.distributor')} name`"
      name="name"
    />

    <ez-input
      class="mt-12"
      :form-key="formKey"
      :value="distributor.companyRegistrationNumber"
      name="companyRegistrationNumber"
      placeholder="Enter Company Registration Number / Tax ID"
      label="Company Registration Number / Tax ID"
    />
    <ez-input
      class="mt-12"
      :form-key="formKey"
      name="taxNumber"
      :value="distributor.taxNumber"
      placeholder="GST / Tax ID Number"
      label="Enter GST / Tax ID Number"
    />

    <ez-input
      :formKey="formKey"
      :value="distributorCopy.customInfo"
      name="customInfo"
      label="Additional Info"
      placeholder="Additional Info"
      class="mt-12"
    />

    <hr />

    <div class="form-help">
      <h4>Payment Terms</h4>
    </div>
    <ez-radio-button
      class="radio-btn"
      :formKey="formKey"
      name="payment"
      label="No Terms"
      :data="distributor.paymentTerms ?? ''"
      value="no_terms"
      @change="updatePaymentTerms('no_terms')"
    />
    <ez-radio-button
      v-if="paymentTermsDataCash"
      class="radio-btn mt-16"
      :formKey="formKey"
      name="payment"
      :label="paymentTermsDataCash.name"
      :data="distributor.paymentTerms ?? ''"
      value="cash_on_delivery"
      @change="updatePaymentTerms(paymentTermsDataCash.key)"
    />
    <div v-if="paymentTermsDataCash" class="radio-info">
      Pay the full amount upon the delivery of the order.
    </div>

    <ez-radio-button
      v-if="paymentTermsData.length"
      class="radio-btn mt-16"
      :formKey="formKey"
      name="payment"
      label="Net"
      :data="net"
      value="net"
      @change="updatePaymentTerms('net')"
    />
    <div v-if="paymentTermsData.length" class="radio-info">
      Pay the full amount within a certain time period.
    </div>

    <ez-drop-down
      v-if="paymentTermsData.length"
      :disabled="net !== 'net'"
      ref="fromInput"
      name="payment"
      :formKey="formKey"
      label="Payment Period"
      class="mb-8 mt-12"
      :data="paymentTermsData"
      :selected="(ifFromNet && distributor.paymentTerms) || paymentTermsData[0].key"
      is-full-width
      :idKey="'key'"
      placeholder="Payment Period"
      @change="updateNet"
    />
    <div class="dropdown-info">
      Set how much time {{ $t('global.venue') | lowercase }} has to complete the payment for the
      issued invoice.
    </div>

    <hr class="address-separator" />

    <ez-address-form
      :fieldValidators="requiredAddressFields"
      :formKey="formKey"
      :address="distributorCopy.address || {}"
      name="address"
    />

    <hr class="address-separator" />
    <div class="form-help">
      <h4>Minimum order amount</h4>
      <p>Delivery charges will apply if order amount is below set value.</p>
    </div>

    <ez-input
      :formKey="formKey"
      :value="distributorCopy.minimumOrderAmount"
      placeholder="Minimum order amount"
      label="Minimum order amount"
      name="minimumOrderAmount"
      type="number"
    >
      <template v-if="currencySymbol" #prefix>{{ currencySymbol }}</template>
    </ez-input>

    <ez-input
      class="mt-12"
      :formKey="formKey"
      :value="distributorCopy.deliveryFee"
      placeholder="Delivery Fee"
      label="Delivery Fee"
      name="deliveryFee"
      type="number"
    >
      <template v-if="currencySymbol" #prefix>{{ currencySymbol }}</template>
    </ez-input>

    <hr class="address-separator" />

    <div class="form-help">
      <h4>Tax Rate Applicable</h4>
      <p>
        Tax will be added to the final order amount for the current
        {{ $t('global.distributor') | lowercase }}.
      </p>
    </div>
    <ez-drop-down
      :data="taxOptions"
      :form-key="formKey"
      is-full-width
      idKey="value"
      name="taxCalculation"
      label="Tax Exclusive / Inclusive / Exempt"
      :selected="distributor.taxCalculation || defaultTaxCalculation"
      @change="onTaxCalculationChange"
      class="mb-16"
    />
    <div class="tax-wrapper">
      <ez-input
        :form-key="formKey"
        type="number"
        name="tax"
        step="any"
        label="Tax Rate"
        placeholder="Tax Rate"
        :value="taxRateDisabled ? 0 : distributorCopy.tax"
        :disabled="taxRateDisabled || isXeroConnected || isQuickBooksConnected"
        :disabledTooltip="taxRateDisabled ? 'Tax Exempt set' : 'Update in accounting integration'"
        min="0"
      >
        <template #suffix>%</template>
      </ez-input>
      <ez-button
        class="ml-12"
        type="secondary"
        v-if="isXeroConnected || isQuickBooksConnected"
        @click="goToAccountingIntegration"
      >
        Settings
      </ez-button>
    </div>

    <hr class="address-separator" />

    <v-cut-off-time
      :form-key="formKey"
      :distributor-data="distributorCopy"
      @cutOffTimeValid="ev => $emit('cutOffTimeValid', ev)"
    />

    <v-dormant-input
      :form-key="formKey"
      name="dormantPeriod"
      :value="dormantPeriod"
      v-if="!$helpers.isFree(distributorCopy.accountType)"
    >
      <p class="m-0">
        Set a reminder to notify you if
        {{ $t('global.venues') | lowercase }}
        haven’t ordered for some time.
        <br />
        <em> If the value is set to 0, the dormant account notification will be disabled. </em>
      </p>
    </v-dormant-input>

    <hr v-if="!$helpers.isFree(distributorCopy.accountType)" class="address-separator" />

    <div class="form-help">
      <h4>Time Zone & Date Format</h4>
    </div>

    <v-select-search
      v-if="timeZones.length"
      class="width-100"
      align-left
      is-full-width
      name="timezone"
      label="Time Zone"
      :data="timeZones"
      value-property="name"
      placeholder="Select Time Zone"
      searchPlaceholder="Search Time Zone"
      :selected="selectedTimeZone"
      :has-clear="false"
    />

    <ez-select
      v-if="supportedDateFormats.length"
      is-full-width
      name="dateFormat"
      label="Date Format"
      placeholder="Select Date Format"
      class="width-100 mt-12"
      :options="supportedDateFormats"
      :value="selectedDateFormat"
    />

    <template v-if="!hideNotificationSettings">
      <hr class="address-separator" />

      <div class="form-help">
        <h4>Order Request Notifications</h4>
        <p>
          Choose how {{ $t('global.distributor') | lowercase }} will receive notifications for new
          order requests.
        </p>
      </div>
      <ez-checkbox
        :formKey="formKey"
        :checked="emailEnabled"
        name="notification"
        label="Email"
        value="EMAIL"
        @change="value => (this.emailEnabled = value)"
      />
      <ez-input
        :formKey="formKey"
        :value="emailEnabled ? distributor.email : ''"
        :readonly="!emailEnabled"
        class="notification-number-input"
        placeholder="Email Address"
        label="Email"
        name="email"
      />
      <ez-checkbox
        :formKey="formKey"
        :checked="smsEnabled"
        name="notification"
        label="SMS"
        value="SMS"
        @change="value => (smsEnabled = value)"
      />
      <ez-input
        :formKey="formKey"
        :value="smsEnabled ? distributor.smsNumber : ''"
        :readonly="!smsEnabled"
        class="notification-number-input"
        placeholder="Phone Number"
        label="Sms number"
        name="smsNumber"
      />
      <ez-checkbox
        :formKey="formKey"
        :checked="whatsAppEnabled"
        name="notification"
        label="WhatsApp"
        value="whatsApp"
        @change="value => (whatsAppEnabled = value)"
      />
      <ez-input
        :formKey="formKey"
        :value="whatsAppEnabled ? distributor.whatsAppNumber : ''"
        :readonly="!whatsAppEnabled"
        class="notification-number-input"
        placeholder="Phone Number"
        label="WhatsApp number"
        name="whatsAppNumber"
      />
    </template>

    <slot name="fieldsetButtons"></slot>

    <template #buttons>
      <slot name="buttons"></slot>
    </template>
  </ez-form>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import EzAddressForm from '@/views/common/address';
import EzForm from '@/components/ui/Form';
import EzCheckbox from '@/components/ui/Checkbox';
import EzInput from '@/components/ui/Input';
import EzImageUpload from '@/components/ui/ImageUpload';
import { falsy, getCurrency } from '@/util/utils';
import { TAX_CALCULATION_OPTIONS } from '@/util/constants';
import VDormantInput from '@/components/v3/patterns/VDormantInput';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import VCutOffTime from '@/components/v3/patterns/VCutOffTime';
import EzDropDown from '@/components/ui/Dropdown/EzDropdown';
import EzButton from '@/components/ui/Button/EzButton';
import EzRadioButton from '@/components/ui/RadioButton/EzRadioButton.vue';
import EzSelect from '@/components/ui/Select/EzSelect.vue';

export default {
  components: {
    VDormantInput,
    EzAddressForm,
    EzForm,
    EzCheckbox,
    EzInput,
    EzImageUpload,
    VSelectSearch,
    VCutOffTime,
    EzDropDown,
    EzButton,
    EzRadioButton,
    EzSelect,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    loadingKey: {
      type: String,
      required: false,
    },
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    avatarColumnMode: {
      type: Boolean,
      default: false,
    },
    supplierId: {
      type: Number,
      required: true,
    },
    hideNotificationSettings: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      emailEnabled: false,
      smsEnabled: false,
      timeZones: [],
      whatsAppEnabled: false,
      distributorCopy: {},
      imgModified: false,
      dormantPeriod: 0,
      formValid: false,
      requiredValues: {
        name: null,
      },
      selectedTimeZone: '',
      taxOptions: TAX_CALCULATION_OPTIONS,
      defaultTaxCalculation: TAX_CALCULATION_OPTIONS[0].value,
      taxRateDisabled: false,
      paymentTerms: '',
      paymentTermsData: [],
      paymentTermsDataCash: null,
      cashVal: '',
      net: '',
      additionalData: {},
    };
  },
  computed: {
    ...mapGetters('defaultImages', ['getDefaultImage']),
    ...mapState('entities/distributors', ['selectedSupplier']),
    ...mapState('entities/users', ['loggedUser', 'contextId']),
    ...mapGetters('entities/users', ['isVenueQuickBooksConnected', 'isVenueXeroConnected']),
    distributor() {
      return this.selectedSupplier;
    },
    currencySymbol() {
      const { symbol } = getCurrency() || {};
      return symbol;
    },
    defaultImage() {
      return this.getDefaultImage('distributor');
    },
    workingHours() {
      return this.distributor?.workingHours || {};
    },
    requiredAddressFields() {
      return {};
    },
    isXeroConnected() {
      return this.isVenueXeroConnected(this.contextId);
    },
    isQuickBooksConnected() {
      return this.isVenueQuickBooksConnected(this.contextId);
    },
    ifFromNet() {
      return !!this.paymentTermsData?.find(pt => pt.key === this.distributor.paymentTerms);
    },
    supportedDateFormats() {
      if (!this.distributor?.supportedDateFormats) return [];
      return [
        { id: null, name: 'Default' },
        ...this.distributor.supportedDateFormats.map(f => ({ id: f, name: f })),
      ];
    },
    selectedDateFormat() {
      const dateFormat = this.distributor?.dateFormat;
      return { id: dateFormat, name: dateFormat || 'Default' };
    },
  },
  methods: {
    submitForm() {
      this.$refs.distributorForm.onSubmit();
    },
    transformer(data) {
      const deliveryDays = data.getAll('deliveryDay');
      if (deliveryDays.length) {
        deliveryDays.forEach(item => data.append('deliveryDays[]', item));
      } else {
        data.append('deliveryDays', '');
      }
      return data;
    },
    onSubmitSuccess(response) {
      this.distributorCopy.isDefaultImage = true;
      this.$emit('submitSuccess', response);
    },
    onSubmitError(response) {
      this.$emit('submitError', response);
    },
    onRemoveImage(distributor) {
      distributor.logo = null;
      distributor.isDefaultImage = true;
      this.imgModified = true;
    },
    initialFormState() {
      this.distributorCopy = JSON.parse(JSON.stringify(this.distributor));
      this.emailEnabled = !!this.distributor.email;
      this.smsEnabled = !!this.distributor.smsNumber;
      this.whatsAppEnabled = !!this.distributor.whatsAppNumber;
      this.dormantPeriod = this.distributor.dormantPeriod || 0;
      this.requiredValues = {
        name: this.distributor.name,
      };
      if (this.distributor.taxCalculation === 'exempt') this.taxRateDisabled = true;
    },
    updateFormState(name, value) {
      this.requiredValues[name] = value;
      this.validateForm();
    },
    validateForm() {
      this.formValid = true;
      Object.keys(this.requiredValues).forEach(key => {
        if (falsy(this.requiredValues[key])) this.formValid = false;
      });
    },
    async getTimeZones() {
      await this.fetchTimeZones().then(data => {
        this.timeZones = data;
      });
    },
    async getPaymentTerms() {
      const {
        data: { data },
      } = await this.fetchPaymentTerms();
      this.paymentTermsData = data.filter(terms => terms.type === 'net');
      const [first] = data.filter(terms => terms.type === 'cash_on_delivery');
      this.paymentTermsDataCash = first;
      if (this.ifFromNet) {
        this.updatePaymentTerms('net');
      }
    },
    getSingleDistributor() {
      this.selectedTimeZone = this.distributor?.timezone || this.loggedUser?.venues[0]?.timezone;
    },
    onTaxCalculationChange(ev) {
      if (ev.value === 'exempt') this.taxRateDisabled = true;
      else this.taxRateDisabled = false;
    },
    ...mapActions('entities/distributors', [
      'removeDistributorLogo',
      'fetchTimeZones',
      'fetchPaymentTerms',
    ]),
    ...mapMutations('entities/distributors', ['UPDATE_SELECTED_SUPPLIER']),
    goToAccountingIntegration() {
      let routeName;
      if (this.isXeroConnected) routeName = 'venue-xero-venues';
      else if (this.isQuickBooksConnected) routeName = 'venue-quick-books-distributors';
      const routerData = this.$router.resolve({
        name: routeName,
        params: {
          id: this.contextId,
        },
      });
      window.open(routerData?.href, '_blank');
    },
    updatePaymentTerms(type) {
      if (type === 'cash_on_delivery') {
        this.cashVal = type;
        this.net = '';
        this.paymentTerms = type;
      } else if (type === 'no_terms') {
        this.paymentTerms = type;
        this.net = '';
        this.cashVal = '';
      } else {
        this.cashVal = '';
        this.net = type;
        this.paymentTerms = this.distributor.paymentTerms || this.paymentTermsData[0].key;
      }
      this.additionalData = {
        paymentTerms: this.paymentTerms,
      };
    },
    updateNet(net) {
      this.paymentTerms = net.key;
      this.additionalData = {
        paymentTerms: this.paymentTerms,
      };
    },
  },
  watch: {
    distributor() {
      this.initialFormState();
    },
    formValid() {
      this.$emit('formValid', this.formValid);
    },
  },
  async created() {
    this.initialFormState();
    this.validateForm();
    this.getSingleDistributor();
    await Promise.all([this.getTimeZones(), this.getPaymentTerms()]);
  },
};
</script>

<style scoped lang="scss">
.distributor-form {
  max-width: 26rem;

  .ez-image-upload {
    margin-bottom: 1rem;
  }

  > .input-group + .input-group {
    margin-top: 0.75rem;
  }

  .form-buttons {
    display: flex;
  }

  .form-help {
    margin-bottom: 1rem;

    h4 {
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    p {
      @include font-size(12px);
      color: $color-gray-6C;
      margin: 0;
    }
  }

  .input-group + .notification-number-input.input-group {
    margin-top: 0.5em;
  }

  .notification-number-input {
    :deep() .input-group__label {
      display: none;
    }

    &.input-group + .input-group {
      margin-top: 1em;
    }
  }

  .address-separator {
    border: 0;
    border-top: 1px dashed #dee1e4;
    margin: 1.25rem 0;
  }

  .form-help {
    margin-bottom: 1rem;

    h4 {
      margin-top: 0;
      margin-bottom: 0.5rem;
    }

    p {
      @include font-size(12px);
      color: $color-gray-6C;
      margin: 0;
      line-height: px-to-rem(18px);
    }
  }
  .time-zone-dropdown {
    margin-top: 0.75rem;
  }

  .buttons {
    margin-top: px-to-rem(24px);
  }

  .cut-off-time {
    width: 115px;
  }
}
:deep() .select-search {
  .select-search__list {
    max-height: 280px;
  }

  .select-search__item {
    height: 42px;
    display: flex;
    align-items: center;
  }
}

.tax-wrapper {
  :deep() .input-group {
    flex: 1;
  }
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.radio-btn {
  :deep() .ez-radio-button__label {
    color: $color-gray-25;
    @include font-size(14px, 20px);
  }
}
.radio-info {
  margin-left: 20px;
  color: $color-gray-6C;
  @include font-size(12px, 18px);
}
.dropdown-info {
  color: $color-gray-6C;
  @include font-size(12px, 18px);
}
</style>
