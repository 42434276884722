<script>
/**
 * ProposePriceModal
 * @version 1.0.0
 * @since 2.3.0
 */

import EzButton from '@/components/ui/Button';
import EzForm from '@/components/ui/Form';
import { EzFormModal } from '@/components/ui/Modal';
import { getCurrency } from '@/util/utils';
import { LOADING_KEY } from '@/util/constants';
import { mapGetters } from 'vuex';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput';

export default {
  components: {
    EzCheckbox,
    EzButton,
    EzForm,
    EzFormModal,
    EzMaskInput,
  },
  props: {
    venueId: {
      type: Number,
      required: true,
    },
    distributorId: {
      type: Number,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    diffAction: {
      type: String,
      required: false,
      default: '',
    },
    hasMarketPrice: {
      type: Boolean,
      required: false,
      default: true,
    },
    canSetZeroPrice: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      formKey: 'proposedPricing',
      loadingKey: LOADING_KEY.PROPOSE_PRICE,
      proposedPrice: 0,
      marketPrice: false,
    };
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    action() {
      if (this.diffAction) {
        return this.diffAction;
      }
      return `/venue/distributors/${this.distributorId}/products/${this.product.id}/price`;
    },
    data() {
      return {
        ...(this.hasMarketPrice && { marketPrice: this.marketPrice }),
        ...{ price: this.proposedPrice },
      };
    },
    currencySymbol() {
      const { symbol } = getCurrency() || {};
      return symbol;
    },
    money() {
      return {
        decimal: '.',
        thousands: ',',
        prefix: this.currencySymbol,
        suffix: '',
        precision: 2,
        masked: false,
      };
    },
    isDisabled() {
      if (this.canSetZeroPrice) {
        return !this.marketPrice && this.proposedPrice < 0;
      }
      return !this.marketPrice && this.proposedPrice <= 0;
    },
    isLoadingSetPrice() {
      return this.getLoading(LOADING_KEY.PROPOSE_PRICE);
    },
    tooltipMp() {
      return {
        html: true,
        classes: ['tooltip-general'],
        content: 'Used for products whose price depends on the current price on the market.',
      };
    },
  },
  methods: {
    open() {
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    submitForm() {
      this.$refs.form.onSubmit();
    },
    onClose() {
      this.marketPrice = false;
      this.$refs.form.clearErrors(['ezcheckbox', 'ezmaskinput']);
    },
    onSubmitSuccess() {
      this.close();
      this.$emit('success', this.proposedPrice);
    },
    onPriceChange(val) {
      this.proposedPrice = val;
    },
    onMarketPriceChange(checked) {
      this.marketPrice = checked;
    },
  },
  watch: {
    product(val) {
      this.proposedPrice = val.price || 0;
      this.marketPrice = val.marketPrice;
    },
  },
};
</script>
<template>
  <ez-form-modal ref="modal" @close="onClose">
    <template #title>
      <slot name="title">New Pricing</slot>
    </template>
    <template #content>
      <slot name="text" />
      <ez-form
        ref="form"
        :formKey="formKey"
        :action="action"
        method="put"
        :additional-data="data"
        :additional-headers="{ loadingKey }"
        @success="onSubmitSuccess"
      >
        <ez-mask-input
          ref="money"
          :form-key="formKey"
          name="price"
          class="mask-input mt-12"
          :value="proposedPrice"
          type="input"
          @input="onPriceChange"
          :disabled="marketPrice && hasMarketPrice"
        />
        <div class="u-flex-row mt-12" v-if="hasMarketPrice">
          <ez-checkbox
            :form-key="formKey"
            name="marketPrice"
            label="Market Price"
            :checked="marketPrice"
            @change="onMarketPriceChange"
          />
          <font-awesome-icon
            class="info-circle cursor-help ml-8"
            icon="info-circle"
            v-tooltip="tooltipMp"
          />
        </div>
      </ez-form>
    </template>
    <template #footer>
      <ez-button type="link" formType="button" @click="close">Cancel</ez-button>
      <ez-button
        :disabled="isDisabled"
        formType="button"
        :is-loading="isLoadingSetPrice"
        @click="submitForm"
      >
        <slot name="confirm" />
      </ez-button>
    </template>
  </ez-form-modal>
</template>
<style lang="scss" scoped>
.mask-input {
  :deep() .mask-input__input {
    font-weight: normal;
  }
}

.info-circle {
  color: $color-gray-6C;
}
</style>
