<script>
/**
 * VProductQuantityInventory
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'VProductQuantityInventory',
  props: {
    product: {
      type: Object,
      required: true,
    },
    isDistributor: {
      type: Boolean,
      required: true,
    },
    inventoryTracking: {
      type: Boolean,
      required: true,
    },
    canBeInvalid: {
      type: Boolean,
      required: false,
      default: true,
    },
    totalLabel: {
      type: String,
      required: false,
      default: 'Total',
    },
    hasTooltip: {
      type: Boolean,
      required: false,
      default: true,
    },
    warehouseProperty: {
      type: String,
      required: false,
      default: 'warehouses',
    },
    showTotalAsLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    warehouseId: {
      type: Number,
      required: false,
      default: null,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    warehouses() {
      return this.product[this.warehouseProperty];
    },
    canShowTooltip() {
      return this.isDistributor && this.hasTooltip;
    },
    outOfStock() {
      if (!this.canShowTooltip) return false;

      if (this.warehouseId) {
        const orderWarehouse = this.warehouses?.find(w => w.id === this.warehouseId);
        return this.product.quantity > orderWarehouse?.quantity;
      }
      const primaryWarehouse = this.warehouses?.find(w => w.primary);
      return this.product.quantity > primaryWarehouse?.quantity;
    },
    total() {
      const total = this.warehouses?.reduce((a, c) => a + c?.quantity || 0, 0);
      if (total % 1 === 0) return total;
      return total.toFixed(2);
    },
    tooltip() {
      if (!this.canShowTooltip) return undefined;

      if (!this.inventoryTracking || !this.product.inventoryTracked) {
        return {
          placement: 'left',
          classes: ['tooltip--reset-margin', 'my-8', 'v-product-quantity-inventory__tooltip'],
          content: `<span data-cy="${this.dataCy}-tooltipNotTracked">Inventory not tracked</span>`,
        };
      }

      if (!this.warehouses?.length) {
        return {
          placement: 'left',
          classes: ['tooltip--reset-margin', 'my-8', 'v-product-quantity-inventory__tooltip'],
          content: `<span data-cy="${this.dataCy}-tooltipNotAvailable">No items available</span>`,
        };
      }

      const warehouses = this.warehouses.map(
        w => `
        <div class="inventory-item">
          <strong class="inventory-item__label" data-cy="${this.dataCy}-tooltipItemName-${w.id}">
            ${w.name}:
          </strong>
          <span class="inventory-item__number" data-cy="${this.dataCy}-tooltipItemStock-${w.id}">
            ${w.quantity}
          </span>
        </div>`,
      );

      const total = `
        <div class="inventory-item inventory-item--total">
          <strong class="inventory-item__label" data-cy="${this.dataCy}-tooltipTotalLabel">
            ${this.totalLabel}:
          </strong>
          <span class="inventory-item__number" data-cy="${this.dataCy}-tooltipTotalStock">
            ${this.total}
          </span>
        </div>`;

      const tooltipContent = `
        <div class="v-product-quantity-inventory__tooltip">
          ${warehouses.join('')}
          ${total}
        </div>`;

      return {
        html: true,
        placement: 'left',
        classes: ['tooltip--reset-margin', 'm-8'],
        content: tooltipContent,
      };
    },
  },
};
</script>

<template>
  <span
    v-tooltip="tooltip"
    :data-cy="dataCy"
    :class="[
      'v-product-quantity-inventory',
      {
        'dashed-underline': canShowTooltip,
        'dashed-underline--out-of-stock': canShowTooltip && outOfStock && canBeInvalid,
      },
    ]"
  >
    {{ showTotalAsLabel ? total : product.quantity }}
  </span>
</template>

<style lang="scss">
.v-product-quantity-inventory {
  &.dashed-underline {
    border-bottom: 2px dotted rgba($color-gray-6C, 0.64);
    @include font-size(14px);

    &--out-of-stock {
      border-color: rgba($color-primary-red, 0.64);
    }
  }

  &__tooltip {
    @include font-size(12px);

    .inventory-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 144px;
      margin-bottom: 6px;

      &--total {
        padding-top: 6px;
        border-top: 1px solid rgba(#fff, 0.2);
        margin-bottom: 0;
      }

      &__number {
        margin-left: 10px;
      }
    }
  }
}
</style>
