<template>
  <button :class="`banner-button banner-button--${color}`" @click="onClick">
    <!-- @slot Default slot used to show content -->
    <font-awesome-icon :icon="icon" class="mr-8" />
    <slot></slot>
    <div class="arrow">
      <font-awesome-icon class="arrow" icon="chevron-right" />
    </div>
  </button>
</template>

<script>
/**
 * Custom banner button
 * @since 1.0.0
 */
export default {
  props: {
    icon: {
      required: false,
      type: String,
      default: '',
    },
    color: {
      required: false,
      type: String,
      default: 'yellow',
      validator: value =>
        ['yellow', 'orange', 'blue', 'purple', 'green', 'red'].indexOf(value) !== -1,
    },
  },
  methods: {
    onClick(e) {
      /**
       * When button is clicked.
       * @event click
       * @type Event
       */
      if (this.stopPropagation) {
        e.stopPropagation();
      }
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss">
.banner-button {
  width: 100%;
  padding: 12px;
  border-radius: 3px;
  font-size: 14px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  .arrow {
    margin-left: auto;
  }

  &--yellow {
    background-color: $color-pastel-yellow;
    color: #f4b117;
  }

  &--orange {
    background-color: $color-pastel-orange;
    color: $color-primary-carrot;
  }

  &--blue {
    background-color: $color-pastel-blue;
    color: $color-primary-blue;
  }

  &--green {
    background-color: $color-pastel-green;
    color: $color-primary-green;
  }

  &--purple {
    background-color: $color-pastel-purple;
    color: $color-primary-purple;
  }
}
</style>
