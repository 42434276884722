<script>

/**
 * InvitationSingle
 * @version 1.0.0
 * @since 2.3.0
 */

import { mapActions, mapMutations, mapState, mapGetters } from 'vuex';
import EzButton from '@/components/ui/Button/EzButton';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo';
import SinglePage from '@/views/admin/SinglePage';
import StatusBadge from '@/views/common/status-badge/StatusBadge';
import EzPremiumBadge from '@/components/ui/PremiumBadge/PremiumBadge';
import confirmation from '@/components/v3/patterns/VConfirmation/control';
import flash from '@/components/ui/FlashMessage';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { LOADING_KEY } from '@/util/constants';

export default {
  components: {
    EzLoader,
    EzButton,
    EzButtonDropdown,
    EzEntityInfo,
    SinglePage,
    StatusBadge,
    EzPremiumBadge,
  },
  props: {
    supplierId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      distributor: {},
    };
  },
  computed: {
    ...mapState('entities/users', [
      'loggedUser',
      'contextId',
    ]),
    ...mapState('entities/distributors', [
      'isOnboardingRequest',
    ]),
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.VENUE_APPROVE_OR_DECLINE_CONNECTION);
    },
  },
  methods: {
    ...mapActions('entities/users', [
      'updateVenueContext',
      'initVenue',
    ]),
    ...mapActions('entities/distributors', [
      'venueFetchSupplierSingle',
      'venueApproveOrDeclineRequest',
    ]),
    async getSingleDistributor() {
      const { data } = await this.venueFetchSupplierSingle({
        id: this.supplierId,
      });
      this.distributor = data.data;
    },
    async acceptRequest() {
      if (this.distributor.onboarding) {
        const { close, canceled } = await confirmation.confirm({
          title: this.$t('distributors.titleAcceptInvitationAlert'),
          message: this.$t('distributors.messageAcceptOnboardingInvitationAlert', {
            supplierName: this.distributor.name,
          }),
          buttons: {
            confirm: this.$t('distributors.acceptOnboardingRequest'),
          },
        });
        if (canceled) return;
        close();
        await this.$router.push({ name: 'buyer-invitation-onboarding', params: { id: this.supplierId } });
        return;
      }
      const { close, canceled } = await confirmation.confirm({
        title: this.$t('distributors.titleAcceptInvitationAlert'),
        message: this.$t('distributors.messageAcceptInvitationAlert', {
          supplierName: this.distributor.name,
        }),
        buttons: {
          confirm: this.$t('distributors.acceptRequest'),
        },
      });
      if (canceled) return;
      close();
      await this.venueApproveOrDeclineRequest({
        venueId: this.contextId,
        id: this.supplierId,
        status: 'approved',
      });
      await this.updateVenueContext();
      await this.$router.push({
        name: 'venue-connection-requests',
        params: {
          flash: {
            title: this.$t('distributors.successFlashTitleAcceptConnection'),
            message: this.$t('distributors.successFlashMessageAcceptConnection', {
              supplierName: this.distributor.name,
            }),
          },
        },
      });
    },
    async declineRequest() {
      const { close, canceled } = await confirmation.alert({
        title: this.$t('distributors.titleDeclineInvitationAlert'),
        message: this.$t('distributors.messageDeclineInvitationAlert', {
          supplierName: this.distributor.name,
        }),
        buttons: {
          confirm: this.$t('distributors.declineRequest'),
        },
      });
      if (canceled) return;
      close();
      await this.venueApproveOrDeclineRequest({
        venueId: this.contextId,
        id: this.supplierId,
        status: 'declined',
      });
      await this.updateVenueContext();
      await this.$router.push({
        name: 'venue-connection-requests',
        params: {
          flash: {
            title: this.$t('distributors.successFlashTitleDeclineConnection'),
            message: this.$t('distributors.successFlashMessageDeclineConnection'),
          },
        },
      });
    },
    switchContext(venue) {
      if (venue.id === this.contextId) {
        return;
      }

      try {
        this.SWITCH_CONTEXT(venue);
        flash.success({
          title: `Switched to ${venue.name}`,
        });
        this.EMPTY_CART();
        this.initVenue();
      } catch (e) {
        flash.fail({
          title: `Unable to switch to ${venue.name}'`,
        });
      }
    },
    ...mapMutations('cart', [
      'EMPTY_CART',
    ]),
    ...mapMutations('entities/users', [
      'SWITCH_CONTEXT',
    ]),
  },
  mounted() {
    if (this.$route.query.venueId) {
      const paramId = this.$route.query.venueId;
      const venue = this.loggedUser.venues.find(ven => ven.id === +paramId);
      this.switchContext(venue);
    }
    this.getSingleDistributor();
  },
};
</script>
<template>
  <single-page>
    <template #breadcrumbs>
      <router-link :to="{ name: 'venue-connection-requests' }">
        <font-awesome-icon icon="arrow-left"/>
        <span>Back to Connection Requests</span>
      </router-link>
    </template>

    <template #title>
      <ez-entity-info
        v-if="distributor"
        imgBorderRadius="50%"
        imgWidth="2rem"
        imgHeight="2rem"
        :imgUrl="distributor.logo">
        <h1>{{ distributor.name }}<ez-premium-badge
          v-if="$helpers.isPremium(distributor.accountType)"/></h1>
        <status-badge :status="distributor.status || 'draft'"></status-badge>
      </ez-entity-info>
    </template>
    <template #actions>
      <ez-button @click="acceptRequest">
        <template>Accept request</template>
      </ez-button>
      <ez-button-dropdown buttonType="secondary">
        <template #icon>
          <font-awesome-icon icon="ellipsis-h"/>
        </template>
        <template #dropdown>
          <ez-button @click="declineRequest" type="red-link">
            Decline request
          </ez-button>
        </template>
      </ez-button-dropdown>
    </template>
    <template #navigation>
      <ul>
        <li>
          <router-link :to="{ name: 'buyer-invitation-single-info' }">
            {{ $t('global.distributor') }} Info
          </router-link>
        </li>
      </ul>
    </template>
    <ez-loader :show="isLoading">Loading...</ez-loader>
  </single-page>
</template>
<style lang="scss" scoped></style>
