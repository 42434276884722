<script>
/**
 * Stock
 * @version 1.0.0
 * @since 2.3.0
 */

import EzNav from '@/components/ui/Nav';
import TotalIcon from '@/views/common/orders/total-icon';
import { mapState } from 'vuex';

export default {
  components: {
    EzNav,
    TotalIcon,
  },
  data() {
    return {
      productCount: null,
      productTotal: 0,
      stocktakeCount: null,
      isProduct: false,
      isStocktake: false,
    };
  },
  computed: {
    ...mapState('entities/users', ['context']),
  },
  methods: {
    updateHeaderInfo(info) {
      this.productCount = info;
      this.isProduct = true;
      this.isStocktake = false;
    },
    updateTotalInfo(info) {
      this.productTotal = info;
    },
    updateStocktakeHeaderInfo(info) {
      this.stocktakeCount = info;
      this.isProduct = false;
      this.isStocktake = true;
    },
  },
};
</script>
<template>
  <div class="venue-stock">
    <header>
      <div>
        <h1 v-if="['venue-stock-stocktake'].includes($route.name)">Counts</h1>
        <h1 v-else>Products</h1>
        <small v-if="isProduct">{{ productCount }} {{ 'product' | pluralize(productCount) }}</small>
        <small v-else-if="isStocktake"
          >{{ stocktakeCount }} {{ 'count' | pluralize(productCount) }}</small
        >
      </div>

      <div
        v-if="isProduct"
        class="subheader__section order-header__stat order-header__stat--amount"
      >
        <span class="mr-12">{{ productTotal | price }}</span>
        <total-icon />
      </div>
    </header>
    <ez-nav>
      <router-view
        @productTotalUpdate="updateTotalInfo"
        @productCountUpdate="updateHeaderInfo"
        @stocktakeCountUpdate="updateStocktakeHeaderInfo"
      />
    </ez-nav>
  </div>
</template>

<style lang="scss" scoped>
.venue-stock {
  @include platform-tab-content();
  .ez-load-more {
    margin-top: $spacing-16;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    small {
      font-size: 12px;
      font-weight: 500;
      margin-top: 4px;
      color: #6c7995;
    }

    .order-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .button {
        margin-left: auto;
      }

      &__title {
        display: flex;
        h1 {
          @include font-size(24px);
          font-weight: 500;
          margin: 0;
        }

        small {
          @include font-size(12px);
          font-weight: 500;
          margin-top: 0.25rem;
          color: $color-gray-6C;
        }
      }

      &__stats {
        display: flex;
        align-items: center;
      }

      &__stat {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 0.75em;
        margin-left: auto;
        align-self: center;

        small {
          @include font-size(12px);
          font-weight: 500;
          letter-spacing: 0.25px;
          color: $color-gray-6C;
        }

        span {
          @include font-size(20px);
          color: $color-gray-25;
          font-weight: 500;
        }

        &--amount {
          small {
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
:deep() .navigation .navigation__nav {
  display: none;
}
</style>
