<script>
import { roundNumber, getTaxFee, getTotalWithTax } from '@/util/utilsFinCalculator';

export default {
  name: 'VSubtotalInfo',
  props: {
    showTotal: {
      type: Boolean,
      required: false,
      default: true,
    },
    itemList: {
      required: true,
      type: Array,
      default: () => [],
    },
    itemProp: {
      required: false,
      type: String,
      default: () => 'totalPrice', // price * quantity
    },
    deliveryFee: {
      required: true,
      type: Number,
      default: () => 0,
    },
    amountAdjustment: {
      required: false,
      type: Number,
      default: () => 0,
    },
    taxAdjustment: {
      required: false,
      type: Number,
      default: () => 0,
    },
    deliveryFeeTaxable: {
      type: Boolean,
      required: false,
      default: true,
    },
    tax: {
      required: true,
      type: Number,
      default: () => 0,
    },
    taxCalculation: {
      required: false,
      type: String,
      default: 'exclusive',
    },
    taxDecimals: {
      required: false,
      type: Number,
      default: () => 2,
    },
    currency: {
      required: false,
      type: Object,
      default: () => null,
    },
    isTbd: {
      type: Boolean,
      required: false,
      default: false,
    },
    discount: {
      type: Number,
      required: false,
      default: 0,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
    totalDiscountAmount: {
      type: Number,
      required: false,
      default: 0,
    },
    quantityKey: {
      type: String,
      required: false,
      default: 'priceQuantity',
    },
    priceKey: {
      type: String,
      required: false,
      default: 'defaultPrice',
    },
  },
  computed: {
    priceBeforeTax() {
      const productTax = getTaxFee({
        itemList: this.itemList,
        itemProp: this.itemProp,
        itemQuantity: this.quantityKey,
        withDiscount: true,
        deliveryFee: 0,
        discount: 0, // discount is negative value because it needs to be subtracted
        tax: this.tax,
        decimals: this.taxDecimals,
        taxCalculation: this.taxCalculation,
      });
      const productTotal = roundNumber({
        number: this.itemList.reduce(
          (acc, cur) =>
            acc + roundNumber({ number: cur[this.priceKey] * cur[this.quantityKey], decimals: 2 }),
          0,
        ),
        decimals: 8,
      });
      if (this.taxCalculation === 'inclusive') return productTotal - productTax;
      return productTotal;
    },
    taxFee() {
      return (
        getTaxFee({
          itemList: this.itemList,
          itemProp: this.itemProp,
          ...(this.deliveryFeeTaxable ? { deliveryFee: this.deliveryFee } : { deliveryFee: 0 }),
          discount: -this.discount, // discount is negative value because it needs to be subtracted
          tax: this.tax,
          decimals: this.taxDecimals,
          taxCalculation: this.taxCalculation,
        }) + this.taxAdjustment
      );
    },
    total() {
      return getTotalWithTax({
        itemList: this.itemList,
        itemProp: this.itemProp,
        deliveryFee: this.deliveryFee,
        deliveryFeeTaxable: this.deliveryFeeTaxable,
        amountAdjustment: this.amountAdjustment,
        taxAdjustment: this.taxAdjustment,
        discount: -this.discount, // discount is negative value because it needs to be subtracted
        tax: this.tax,
        taxDecimals: this.taxDecimals,
        taxCalculation: this.taxCalculation,
      });
    },
    totalDiscount() {
      const productsDiscount = this.itemList.reduce(
        (acc, cur) =>
          acc +
          roundNumber({
            number: (cur.discountAmountNotRounded || cur.discountAmount) * cur[this.quantityKey],
            decimals: 2,
          }),
        0,
      );
      const productsDiscountTax = this.itemList.reduce(
        (acc, cur) =>
          acc +
          getTaxFee({
            itemList: [],
            itemProp: this.itemProp,
            deliveryFee: 0,
            discount: -cur.discountAmount * cur[this.quantityKey], // discount is negative value because it needs to be subtracted
            tax: cur.tax,
            decimals: this.taxDecimals,
            taxCalculation: this.taxCalculation,
          }),
        0,
      );
      const discountTax = getTaxFee({
        itemList: [],
        itemProp: this.itemProp,
        deliveryFee: 0,
        discount: -this.discount, // discount is negative value because it needs to be subtracted
        tax: this.tax,
        decimals: this.taxDecimals,
        taxCalculation: this.taxCalculation,
      });
      if (this.taxCalculation === 'inclusive') {
        return (
          this.discount +
          (Number.isFinite(productsDiscount) ? productsDiscount : 0) +
          discountTax +
          productsDiscountTax
        );
      }
      return this.discount + (Number.isFinite(productsDiscount) ? productsDiscount : 0);
    },
    isTaxUniform() {
      let uniformTax = true;
      this.itemList.forEach(item => {
        if (item.tax !== this.tax) uniformTax = false;
      });
      return uniformTax;
    },
    content() {
      return `
      <div class="tax">
        <div class="tax__item">
          <strong class="tax__label">Subtotal:</strong>
          <span class="tax__number" data-cy="${this.dataCy}-tooltipSubtotal">
            ${
              this.isTbd
                ? this.$t('global.tbd')
                : this.$helpers.formatPrice(this.priceBeforeTax, this.currency)
            }
          </span>
        </div>
        <div class="tax__item">
          <strong class="tax__label">Discounts:</strong>
          <span class="tax__number" data-cy="${this.dataCy}-discounts">
            ${this.$helpers.formatPrice(-this.totalDiscount, this.currency)}
          </span>
        </div>
        <div class="tax__item">
          <strong class="tax__label">Delivery Fee:</strong>
          <span class="tax__number" data-cy="${this.dataCy}-tooltipDeliveryFee">
            +${this.$helpers.formatPrice(this.deliveryFee, this.currency)}
          </span>
        </div>
        <div class="tax__item">
          <strong class="tax__label">Price Adjustment:</strong>
          <span class="tax__number">
            ${
              this.isTbd
                ? this.$t('global.tbd')
                : this.$helpers.formatPrice(this.amountAdjustment, this.currency)
            }
          </span>
        </div>
        <div class="tax__item">
          <strong class="tax__label">Tax${this.isTaxUniform ? ` (${this.tax}%)` : ''}:</strong>
          <span class="tax__number" data-cy="${this.dataCy}-tooltipTax">
            +${
              this.isTbd && this.tax > 0
                ? this.$t('global.tbd')
                : this.$helpers.formatPrice(this.taxFee, this.currency)
            }
          </span>
        </div>
        <div class="tax__item tax__item--total">
          <strong class="tax__label">Total:</strong>
          <span class="tax__number" data-cy="${this.dataCy}-tooltipTotal">
            ${
              this.isTbd
                ? this.$t('global.tbd')
                : this.$helpers.formatPrice(this.total, this.currency)
            }
          </span>
        </div>
      </div>
`;
    },
    tooltip() {
      return {
        html: true,
        classes: ['tooltip-general'],
        content: this.content,
      };
    },
  },
};
</script>

<template>
  <span class="v-subtotal-info">
    <span class="v-subtotal-info__text" v-if="$slots.default"><slot /></span>
    <font-awesome-icon
      icon="info-circle"
      v-tooltip="tooltip"
      class="v-subtotal-info__icon"
      :data-cy="`${dataCy}-infoIcon`"
    />
    <span class="v-subtotal-info__total" v-if="showTotal" :data-cy="dataCy">
      {{ isTbd ? $t('global.tbd') : $helpers.formatPrice(total, currency) }}
    </span>
  </span>
</template>

<style lang="scss">
.v-subtotal-info {
  display: inline-flex;
  align-items: center;
  @include font-size(18px);
  font-weight: 600;
  vertical-align: middle;

  &__icon {
    color: #6c7995;
    cursor: pointer;
  }

  &__total {
    color: #252631;
    margin-left: 8px;
    @include font-size(20px);
  }

  &__text {
    margin-right: 4px;
    color: #6c7995;
    @include font-size(12px);
  }
}

.tax {
  min-width: 164px;
  &__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;

    &--total {
      padding-top: 6px;
      border-top: 1px solid rgba(#fff, 0.2);
    }
  }
}
</style>
