import i18n from '@/i18n';

export const ONBOARDING_STEP_1 = 0;
export const ONBOARDING_STEP_2 = 1;
export const ONBOARDING_STEP_3 = 2;
export const ONBOARDING_STEP_4 = 3;

const steps = {
  [ONBOARDING_STEP_1]: {
    component: () => import(/* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/onboarding-from-public/Account.vue'),
    title: '',
    pageTitle: '',
    pageInfo: '',
    nextCta: 'next',
  },
  [ONBOARDING_STEP_2]: {
    component: () => import(/* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/onboarding-from-public/OutletInfo'),
    title: `${i18n.t('global.venue')} info`,
    pageTitle: `Please add ${i18n.t('global.venue')} info`,
    pageInfo: `Add your ${i18n.t('global.venue')}’s avatar, it’s name, contact info and addresses of the business that will be used throughout the platform.`,
    nextCta: 'Next Step',
  },
  [ONBOARDING_STEP_3]: {
    component: () => import(/* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/onboarding-from-public/Contacts.vue'),
    title: 'Please add your contacts',
    pageTitle: 'Please add your contacts',
    pageInfo: 'Choose from your existing users or add your new finance and ordering contacts.',
    nextCta: 'Next Step',
  },
  [ONBOARDING_STEP_4]: {
    component: () => import(/* webpackChunkName: "venue" */ '@/views/platform/venue/suppliers/onboarding-from-public/Overview.vue'),
    title: 'Overview',
    pageTitle: 'Overview',
    pageInfo: 'Before you complete the form and connect with the supplier, check the information below to make sure you’ve entered everything correctly.',
    nextCta: 'Complete and Connect',
  },
};

export default steps;
