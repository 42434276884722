<template>
  <div class="height-100">
    <tab-layout :class="{ 'desktop-screen': !isFreemium }" :disabled="isTrialExpired">
      <template v-if="!isPublic" #nav>
        <ul class="nav">
          <li v-if="$permission.has('seeOverview') && !$permission.isFree">
            <router-link
              :data-cy="outletCy.MAIN_NAVIGATION.LINK__PRODUCTS"
              :to="{ name: 'venue-home' }"
            >
              Overview
            </router-link>
          </li>
          <li v-if="!groupViewMode">
            <div class="sub-menu">
              <router-link
                :data-cy="outletCy.MAIN_NAVIGATION.LINK__ORDERS"
                :to="{ name: 'venue-orders' }"
              >
                Orders
              </router-link>
            </div>
            <ul class="sub-menu__dropdown">
              <li>
                <router-link
                  :to="{ name: 'venue-orders-history' }"
                  :data-cy="outletCy.ORDERS.GENERAL.LINK__ORDER_HISTORY"
                >
                  History
                </router-link>
                <ez-badge type="purple" :count="context.actionsCount || 0" />
              </li>
              <li v-if="$permission.has('createOrder') && $permission.has('seeProducts')">
                <router-link :to="{ name: 'venue-orders-products' }"> Ordering </router-link>
              </li>
              <li v-if="$permission.has('seeFutureOrders')">
                <router-link
                  :to="{ name: 'venue-orders-standing' }"
                  :data-cy="outletCy.ORDERS.GENERAL.LINK__STANDING_ORDERS"
                >
                  Automated Orders
                </router-link>
              </li>
            </ul>
          </li>
          <li v-if="$permission.has('seePendingInvoices') && !$permission.isFree && !groupViewMode">
            <router-link
              :to="{ name: 'venue-pending-updates' }"
              :data-cy="outletCy.ORDERS.GENERAL.LINK__PENDING_UPDATES"
            >
              Invoices
            </router-link>
            <ez-badge type="error-red" :count="context.pendingInvoicesCount || 0" />
          </li>
          <li v-if="$permission.has('seeSuppliers')">
            <template v-if="$permission.isLite || $permission.isPremium">
              <div class="sub-menu">
                <router-link
                  :data-cy="outletCy.MAIN_NAVIGATION.LINK__SUPPLIERS"
                  :to="{ name: 'venue-suppliers' }"
                >
                  Catalog
                </router-link>
                <ez-badge :count="supplierNotificationNumber" type="yellow" />
              </div>
              <ul class="sub-menu__dropdown">
                <li>
                  <router-link :to="{ name: 'venue-all-suppliers-products' }">
                    Products
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'venue-all-suppliers' }">
                    {{ $t('global.distributors') }}
                  </router-link>
                </li>
                <li v-if="canSeeConnectionRequest">
                  <router-link :to="{ name: 'venue-connection-requests' }">
                    {{ $t('distributors.connectionRequestsTab') }}
                  </router-link>
                  <EzBadge :count="context.invitationsCount || 0" type="yellow" />
                </li>
              </ul>
            </template>
            <template v-else>
              <router-link
                :data-cy="outletCy.MAIN_NAVIGATION.LINK__SUPPLIERS"
                :to="{ name: 'venue-all-suppliers-freemium' }"
              >
                {{ $t('global.distributors') }}
              </router-link>
              <status-badge class="ml-4" status="premium" />
            </template>
          </li>
          <li v-if="isInventoryEnabled && hasManageInventoryAndConductStocktake">
            <div class="sub-menu">
              <router-link :to="{ name: 'venue-stock' }">Inventory</router-link>
            </div>
            <ul class="sub-menu__dropdown">
              <li>
                <router-link
                  v-if="$permission.has('manageInventory')"
                  :to="{ name: 'venue-stock-all' }"
                >
                  Products
                </router-link>
              </li>
              <li :class="{ 'ml-0': !$permission.has('manageInventory') }">
                <router-link
                  v-if="$permission.has('conductStocktake')"
                  :to="{ name: 'venue-stock-stocktake' }"
                >
                  Counts
                </router-link>
              </li>
            </ul>
          </li>
          <li v-else-if="isInventoryEnabled && hasManageInventory">
            <router-link :to="{ name: 'venue-stock-all' }">Inventory</router-link>
          </li>
          <li v-else-if="isInventoryEnabled && hasConductStocktake">
            <router-link :to="{ name: 'venue-stock-stocktake' }">Inventory</router-link>
          </li>
          <li v-if="!$permission.isFree && $permission.has('seeReports')">
            <div class="sub-menu">
              <router-link :to="{ name: 'venue-reports' }">Reports</router-link>
            </div>
            <ul class="sub-menu__dropdown">
              <li>
                <router-link :to="{ name: 'venue-reports-invoices' }">Invoice Reports </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'venue-reports-orders' }">Order Reports </router-link>
              </li>
              <li v-if="context.chartbrewUrl">
                <router-link :to="{ name: 'venue-reports-chartbrew' }">
                  Chartbrew Reports
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </template>
      <template v-if="!isPublic" #cart>
        <button
          :data-cy="outletCy.MAIN_NAVIGATION.BUTTON__CART"
          v-if="$permission.has('createOrder')"
          class="cart"
          @click="openCart"
        >
          <font-awesome-icon icon="shopping-cart" />
          <ez-badge type="blue" :count="itemsCount" />
        </button>
      </template>
      <template v-if="!isPublic" #accountMenu>
        <account-menu />
      </template>
      <div class="features-title">
        <p class="mb-16 mt-0 heading pl-0">Feature Availability by Mobile, Web, & Apps</p>
        <p class="subheading">Check which features are available in each platform</p>
      </div>
      <div class="features-list">
        <table>
          <thead>
            <tr>
              <th class="feature-cell">Feature</th>
              <th class="desktop-cell">Desktop Browser</th>
              <th class="apps-cell">iOS & Android Apps</th>
              <th class="mobile-cell">Mobile Browser</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="feature in features" :key="feature.name">
              <td class="feature-cell">{{ feature.name }}</td>
              <td class="desktop-cell">
                <img
                  v-if="feature.desktop === 'yes'"
                  src="@/assets/icons/ic_available.svg"
                  alt="Green checkmark icon"
                />
                <img
                  v-else-if="feature.desktop === 'no'"
                  src="@/assets/icons/ic_not-available.svg"
                  alt="Red icon with an X"
                />
                <span v-else>{{ feature.desktop }}</span>
              </td>
              <td class="apps-cell">
                <img
                  v-if="feature.apps === 'yes'"
                  src="@/assets/icons/ic_available.svg"
                  alt="Green checkmark icon"
                />
                <img
                  v-else-if="feature.apps === 'no'"
                  src="@/assets/icons/ic_not-available.svg"
                  alt="Red icon with an X"
                />
                <span v-else>{{ feature.apps }}</span>
              </td>
              <td class="mobile-cell">
                <img
                  v-if="feature.mobile === 'yes'"
                  src="@/assets/icons/ic_available.svg"
                  alt="Green checkmark icon"
                />
                <img
                  v-else-if="feature.mobile === 'no'"
                  src="@/assets/icons/ic_not-available.svg"
                  alt="Red icon with an X"
                />
                <span v-else>{{ feature.mobile }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </tab-layout>
    <div
      :class="{
        'mobile-screen': !isFreemium,
        'mobile-screen__premium': !isTrial,
      }"
    >
      <div class="width-100">
        <div class="mobile-header">
          <img
            class="mobile-screen__logo"
            v-if="oezPlatformName === 'Foodrazor'"
            src="@/assets/Foodrazor-logo.svg"
            alt="Foodrazor logo"
          />
          <img v-else src="@/assets/logo-dark.svg" alt="EZ logo" class="mobile-screen__logo" />
          <account-menu v-if="!isPublic" />
        </div>
        <div class="features-title">
          <ez-button type="link" formType="button" @click="goToHomePage">
            <font-awesome-icon icon="arrow-left" />
            <span v-if="!isPublic" class="ml-4">Back</span>
            <span v-else class="ml-4">Sign In</span>
          </ez-button>
          <p class="mb-16 heading">Feature Availability by Mobile, Web, & Apps</p>
          <p class="subheading">Check which features are available in each platform</p>
        </div>
        <div class="features-list">
          <table>
            <thead>
              <tr>
                <th class="feature-cell">Feature</th>
                <th class="desktop-cell">Desktop Browser</th>
                <th class="apps-cell">iOS & Android Apps</th>
                <th class="mobile-cell">Mobile Browser</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="feature in features" :key="feature.name">
                <td class="feature-cell">{{ feature.name }}</td>
                <td class="desktop-cell">
                  <img
                    v-if="feature.desktop === 'yes'"
                    src="@/assets/icons/ic_available.svg"
                    alt="Green checkmark icon"
                  />
                  <img
                    v-else-if="feature.desktop === 'no'"
                    src="@/assets/icons/ic_not-available.svg"
                    alt="Red icon with an X"
                  />
                  <span v-else>{{ feature.desktop }}</span>
                </td>
                <td class="apps-cell">
                  <img
                    v-if="feature.apps === 'yes'"
                    src="@/assets/icons/ic_available.svg"
                    alt="Green checkmark icon"
                  />
                  <img
                    v-else-if="feature.apps === 'no'"
                    src="@/assets/icons/ic_not-available.svg"
                    alt="Red icon with an X"
                  />
                  <span v-else>{{ feature.apps }}</span>
                </td>
                <td class="mobile-cell">
                  <img
                    v-if="feature.mobile === 'yes'"
                    src="@/assets/icons/ic_available.svg"
                    alt="Green checkmark icon"
                  />
                  <img
                    v-else-if="feature.mobile === 'no'"
                    src="@/assets/icons/ic_not-available.svg"
                    alt="Red icon with an X"
                  />
                  <span v-else>{{ feature.mobile }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import AccountMenu from '@/views/platform/venue/AccountMenu.vue';
import TabLayout from '@/components/layout/Tab.vue';
import EzBadge from '@/components/ui/Badge';
import StatusBadge from '@/views/common/status-badge/StatusBadge';
import EzButton from '@/components/ui/Button/EzButton.vue';
import { resetState } from '@/store';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import { ACCOUNT_STATUS_TRIAL_EXPIRED, LOADING_KEY } from '@/util/constants';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import { getContextId } from '@/util/utils';

export default {
  components: {
    AccountMenu,
    TabLayout,
    EzBadge,
    StatusBadge,
    EzButton,
  },
  props: {
    isPublic: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      outletCy,
      commonCy,
      imageData: null,
      LOADING_KEY,
      features: [
        {
          name: 'Invoice Upload',
          desktop: 'yes',
          apps: 'yes',
          mobile: 'yes',
        },
        {
          name: 'Stocktake',
          desktop: 'yes',
          apps: 'Coming Soon',
          mobile: 'yes',
        },
        {
          name: 'Assign Product Groups',
          desktop: 'yes',
          apps: 'Coming Soon',
          mobile: 'no',
        },
        {
          name: 'Assign Inventory Status',
          desktop: 'yes',
          apps: 'Coming Soon',
          mobile: 'no',
        },
        {
          name: 'Ordering',
          desktop: 'yes',
          apps: 'yes',
          mobile: 'no',
        },
        {
          name: 'Reporting',
          desktop: 'yes',
          apps: 'no',
          mobile: 'no',
        },
        {
          name: 'Accounting Integration',
          desktop: 'yes',
          apps: 'no',
          mobile: 'no',
        },
        {
          name: 'Add Users',
          desktop: 'yes',
          apps: 'no',
          mobile: 'no',
        },
        {
          name: 'Add Products',
          desktop: 'yes',
          apps: 'no',
          mobile: 'no',
        },
        {
          name: 'Add Suppliers',
          desktop: 'yes',
          apps: 'no',
          mobile: 'no',
        },
        {
          name: 'Invoice Processing',
          desktop: 'yes',
          apps: 'no',
          mobile: 'no',
        },
        {
          name: 'Settings',
          desktop: 'yes',
          apps: 'no',
          mobile: 'no',
        },
      ],
    };
  },
  computed: {
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
    venueId() {
      return getContextId();
    },
    ...mapGetters({
      itemsCount: 'cart/getItemCount',
    }),
    ...mapState('entities/users', ['context', 'contextId', 'loggedUser']),
    groupViewMode() {
      if (this.loggedUser.context === 'venue-group') return true;
      return false;
    },
    isInventoryEnabled() {
      return this.context?.features?.inventory || false;
    },
    canSeeConnectionRequest() {
      return this.context?.invitationsCount > 0 && !this.$permission.isFree;
    },
    hasManageInventory() {
      return this.$permission.has('manageInventory');
    },
    hasConductStocktake() {
      return this.$permission.has('conductStocktake');
    },
    hasManageInventoryAndConductStocktake() {
      return this.$permission.has('manageInventory') && this.$permission.has('conductStocktake');
    },
    banner() {
      return this.context?.banner || null;
    },
    isTrialExpired() {
      return this.context?.subscriptionStatus === ACCOUNT_STATUS_TRIAL_EXPIRED;
    },
    isTrial() {
      return this.context?.isTrial ?? false;
    },
    isFreemium() {
      return this.context?.accountType === 'free' ?? false;
    },
    css() {
      return `:root {
        --banner-primary-color: ${this.banner.primaryColor};
        --banner-secondary-color: ${this.banner.secondaryColor};
      }`;
    },
    supplierNotificationNumber() {
      return this.context?.invitationsCount || 0;
    },
    ordersNotificationNumber() {
      return (this.context?.actionsCount || 0) + (this.context?.pendingInvoicesCount || 0);
    },
  },
  methods: {
    openCart() {
      this.$router.push({
        name: 'venue-shopping-cart',
      });
    },
    ...mapActions('entities/users', ['initVenue', 'managerLogout']),
    ...mapActions('entities/venues', ['venueStartStocktake']),
    onBannerCTAClick() {
      if (this.banner.path.startsWith('https')) {
        window.open(this.banner.path, '_blank');
      } else {
        this.$router.push(this.banner.path);
      }
    },
    onLogoutClick() {
      this.managerLogout().finally(() => {
        localStorage.clear();
        resetState();
        window.Intercom('shutdown');
        this.$router.replace({ name: 'platform-login' });
      });
    },
    goToFeaturePage() {
      this.$router.push({ name: 'venue-features-list' });
    },
    goToHomePage() {
      this.$router.push({ name: 'venue-home' });
    },
  },
  created() {
    if (!this.isPublic) {
      this.initVenue();
    }
  },
};
</script>

<style scoped lang="scss">
$tab-text-color: #8790a3;
$active-tab-border-color: #4d7cfe;
$item-count-color: #ffffff;

:deep() div.alert.alert--banner.venue-banner {
  margin-top: 0;
  margin-bottom: 0;
  background-color: var(--banner-secondary-color);
  color: var(--banner-primary-color);

  &__message,
  .button,
  svg {
    color: var(--banner-primary-color);
  }

  .button {
    background-color: $color-white;
  }

  .banner__link {
    background: transparent;
    color: var(--banner-primary-color);
    font-weight: 500;
    text-decoration: none;
  }
  .sign-out {
    position: absolute;
    right: 0;
  }
}

.layout-tab__navigation {
  .cart {
    @extend %button-reset;
    @extend %flex-center;
    margin-right: 0.375rem;
    outline: 0;
    background: none;
    cursor: pointer;

    :deep() .badge {
      flex-shrink: 0;
      margin-right: 0;
    }
  }

  .sub-menu {
    display: flex;
    align-items: center;
    height: 100%;
    a {
      height: 100%;
    }
    &__dropdown {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 54px;
      margin: 0;
      margin-left: -16px;
      padding: 8px 16px;
      z-index: 100;
      background-color: #fff;
      border-radius: 3px;
      border: 1px solid #eceef5;
      border-top: none;
      height: fit-content;
      width: max-content;

      &:hover {
        display: flex;
      }

      li {
        margin-left: 0;
        a {
          padding: 8px 0;
        }
      }
    }
    &:hover + .sub-menu__dropdown {
      display: flex;
    }
  }
}

.mobile-screen {
  display: none;
}

@media (max-width: 768px) {
  .mobile-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    text-align: center;

    hr {
      width: 100%;
    }

    &__logo {
      height: 40px;
    }

    &__title {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      letter-spacing: 0.15px;
      span {
        color: $color-primary-blue;
      }
    }

    &__subtitle {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 1.5;
      font-weight: 500;
    }

    &__desc {
      font-size: 12px;
      line-height: 1.5;
      color: $color-gray-6C;
    }

    &__download-apps {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: 32px;
      }
    }

    :deep() .ez-form {
      width: 100%;
    }

    .upload-component {
      :deep() .preview__button label {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-primary-blue;
        color: white;
        font-size: 16px;
        line-height: 1.5;
        padding: 12px 16px;
        font-weight: 500;
        height: auto;
        svg {
          display: none;
        }
      }

      :deep() .preview__images {
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
      }
    }
    &__premium {
      padding: 0;
      letter-spacing: 0.15px;

      .mobile-header {
        padding: 16px 24px;
        border-bottom: 1px solid #eceef5;

        display: flex;
        align-items: center;
        justify-content: space-between;

        :deep() .button-dropdown__dropdown {
          max-height: 250px;
          text-align: left;
        }

        :deep() .button-dropdown {
          .button-dropdown__toggle-container {
            .button {
              padding: 0;
            }
            .trigger {
              display: none;
            }
            .entity-info__text {
              display: none;
            }
          }
        }
      }
      .content {
        padding: 32px 24px;

        .upload-component {
          :deep() .preview__button label {
            display: flex;
            margin-bottom: 16px;
            color: $color-gray-6C;
            background-color: #f5f6fa;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            line-height: 1.5;
            padding: 12px 16px;
            font-weight: 500;
            height: auto;
            svg {
              display: block;
            }
          }
        }

        .heading {
          font-size: 20px;
          line-height: 1.4;
          font-weight: 600;
        }

        .subheading {
          font-size: 14px;
          line-height: 1.4;
          font-weight: 400;
          color: $color-gray-6C;
        }
      }
    }
  }
  :deep() .layout-tab.desktop-screen {
    display: none;
  }
}

.features-title {
  padding: 32px 24px;
  .heading {
    padding: 0 24px;
    font-size: 20px;
    line-height: 1.4;
    font-weight: 600;
  }

  .subheading {
    font-size: 14px;
    line-height: 1.4;
    font-weight: 400;
    color: $color-gray-6C;
    margin-bottom: 0;
  }
}
.features-list {
  padding: 24px 0;
  padding-left: 0;
  margin: 0 24px;
  overflow-x: auto;
  background-color: white;
  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    thead {
      font-size: 14px;
      line-height: 20px;
      color: #495063;
      border-bottom: 2px solid #e1e5ed;

      tr th {
        font-weight: 500;
        text-align: left;
        padding: 10px 0;
      }
    }
    tr td {
      text-align: left;
      font-size: 14px;
      line-height: 20px;
      padding: 10px 0;
      border-bottom: 1px solid #e1e5ed;
    }
    tr td.feature-cell {
      color: $color-gray-25;
    }
    .feature-cell {
      position: sticky;
      left: 0;
      top: 0;
      background-color: #fff;
      width: 176px;
    }

    .desktop-cell {
      width: 130px;
    }
    .apps-cell {
      width: 150px;
    }
    .mobile-cell {
      width: 120px;
    }
  }
}
</style>
