<template>
  <div>
    <div class="all-products__actions mt-24">
      <ez-filter-list
        :filters="listFilters"
        @filterUpdated="(...$event) => updateFilters(FilterArea.List, $event)"
        @resetFilter="resetFilters(FilterArea.List)"
        class="mr-16"
      >
        <ez-input
          formKey="filters"
          label="search"
          name="term"
          class="search"
          placeholder="Search for a Product"
        >
          <template #prefix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
        <v-select-search
          class="select-supplier ml-16"
          :selected="dropdownFilters.distributorId"
          formKey="filters"
          name="distributorId"
          :data="distributorFilter"
          :placeholder="`All ${$t('global.distributors')}`"
          :searchPlaceholder="`Select ${$t('global.distributor')}`"
          align-left
        >
          <template #result="{ result }">
            <v-distributor-entity-info :distributor="result" />
          </template>
        </v-select-search>
        <ez-product-group-filter
          class="ml-16"
          name="productGroupId"
          :productGroups="productGroupsFilter"
          :selected="dropdownFilters.productGroupId"
        />
      </ez-filter-list>
      <v-filter-dropdown
        :filters="dropdownFilters"
        @filterUpdated="$event => updateFilters(FilterArea.Dropdown, $event)"
        @resetFilters="resetFilters(FilterArea.Dropdown)"
        ref="filtersGroup"
      >
        <template #firstRow>
          <v-select-search
            class="select-supplier"
            :selected="dropdownFilters.distributorId"
            formKey="filters"
            name="distributorId"
            :label="`${$t('global.distributor')}`"
            :data="distributorFilter"
            :placeholder="`All ${$t('global.distributors')}`"
            :searchPlaceholder="`Select ${$t('global.distributor')}`"
            align-left
            is-full-width
          >
            <template #result="{ result }">
              <v-distributor-entity-info :distributor="result" />
            </template>
          </v-select-search>
          <ez-product-group-filter
            name="productGroupId"
            label="Product Group"
            :productGroups="productGroupsFilter"
            :selected="dropdownFilters.productGroupId"
            isFullWidth
          />
          <ez-category-filter
            name="categoryId"
            label="Category"
            :categories="categories"
            :selected="dropdownFilters.categoryId"
            isFullWidth
          />
          <ez-select
            v-if="hasManageInventoryPermission && isInventoryEnabled"
            class="input-group product-group-filter"
            name="venueInventoryTracked"
            :isFullWidth="true"
            :value="dropdownFilters.venueInventoryTracked"
            :selected="dropdownFilters.venueInventoryTracked"
            label="Track"
            :options="trackingData"
          />
        </template>
        <template #secondRowLabel>
          <div class="split">
            <div>Visibility</div>
            <div>Other</div>
          </div>
        </template>
        <template #secondRow>
          <ez-checkbox
            class="status-checkbox"
            :checked="dropdownFilters.visible"
            key="visible"
            formKey="filters"
            name="visible"
            label="Visible"
          />
          <ez-checkbox
            class="status-checkbox"
            :checked="dropdownFilters.hidden"
            key="hidden"
            formKey="filters"
            name="hidden"
            label="Hidden"
          />
          <ez-checkbox
            class="status-checkbox ml-96"
            :checked="dropdownFilters.newFilter"
            key="newFilter"
            formKey="filters"
            name="newFilter"
            label="New"
          />
        </template>
      </v-filter-dropdown>
      <ez-button-dropdown
        v-if="loggedUser.groupViewFeaturesEnabled"
        :showToggleIcon="false"
        :expandOnClick="true"
        buttonType="secondary"
        class
      >
        <template>
          <span>Import/Export CSV</span>
        </template>
        <template #dropdown>
          <ez-button type="link" formType="button" customClass="upload-csv" @click="uploadCSVModal">
            Upload CSV
          </ez-button>
          <ez-button type="link" formType="button" customClass="upload-csv" @click="exportCSV">
            Export CSV
          </ez-button>
        </template>
      </ez-button-dropdown>
    </div>

    <ez-alert type="blue" v-if="newProductsCount > 0">
      <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
      <template
        >There {{ newProductsCount > 1 ? 'are' : 'is' }} {{ newProductsCount }} new
        {{ newProductsCount > 1 ? 'products' : 'product' }}. You can see
        {{ newProductsCount > 1 ? 'it' : 'them' }}
        by clicking on the button on the right.
      </template>
      <template #cta>
        <ez-button type="blue-link" @click="filterApproval" :is-loading="isNewProductsLoading">
          View Products
        </ez-button>
      </template>
    </ez-alert>

    <ez-table
      class="mt-24"
      :loading="isPageLoading"
      v-if="allSuplierProducts.length && !isLoading"
      @rowClick="openSingleProduct"
      :headers="{
        ...(hasManageDistributorPermission ? { name: nameCheckbox } : {}),
        distributor: () => `${$t('global.distributor').toLowerCase()}`,
        orderingUnit: () => 'Unit',
        priceChangeNotification: () => '% Notify',
        isHidden: () => 'Visibility',
        price: () => 'Price per unit',
        ...(hasManageInventoryPermission && isInventoryEnabled
          ? { venueInventoryTracked: () => 'Track' }
          : {}),
      }"
      :columns="[
        'name',
        'distributor',
        'orderingUnit',
        'productGroup',
        'priceChangeNotification',
        'isHidden',
        'price',
        ...(hasManageInventoryPermission && isInventoryEnabled ? ['venueInventoryTracked'] : []),
      ]"
      :columnProps="{
        price: { class: 'price-cell' },
        isHidden: { class: 'visibility-cell' },
        priceChangeNotification: { class: 'small-cell' },
        orderingUnit: { class: 'medium-cell' },
        productGroup: { class: 'large-cell product-group-cell' },
        venueInventoryTracked: { class: 'product-group-cell medium-cell' },
      }"
      :data="allSuplierProducts"
    >
      <template #cell-name="{ row }">
        <div class="u-flex-row u-flex-center">
          <ez-checkbox
            v-if="hasManageDistributorPermission"
            class="cursor-pointer mr-8"
            :key="row.id"
            :checked="isProductSelected(row)"
            @change="onCheckboxChange(row, $event)"
            stop-propagation
          />
          <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="row.image">
            <div class="product-info" :title="row.name">
              <span>{{ row.name }}</span>
              <span class="product-info-secondary">
                <span class="product-info-secondary--category">
                  {{ row.category | categoryWithParent }}
                </span>
                <span v-if="row.sku" class="product-info-secondary--sku">
                  &#8226; {{ row.sku }}
                </span>
              </span>
            </div>
          </ez-entity-info>
        </div>
      </template>
      <template #cell-distributor="{ row: { distributor } }">
        <v-distributor-entity-info :distributor="distributor" />
      </template>
      <template #cell-orderingUnit="{ row: { orderingUnit } }">
        <template v-if="orderingUnit">
          <v-data-with-info
            :info="
              orderingUnit.abbreviation
                ? `${orderingUnit.name} ${!!$t(`product.unitInfo.${orderingUnit.type}`) ? '-' : ''}
            ${$t(`product.unitInfo.${orderingUnit.type}`)}`
                : ''
            "
            :show-underline="
              !!$t(`product.unitInfo.${orderingUnit.type}`) || !!orderingUnit.abbreviation
            "
          >
            {{ orderingUnit.label }}
          </v-data-with-info>
        </template>
      </template>
      <template #cell-productGroup="{ row: { productGroup, id } }">
        <v-select-search-product-group
          v-if="$permission.has('assignProductGroups')"
          :class="{ 'has-group': productGroup?.id !== undefined }"
          :ref="`selectProductGroup-${id}`"
          :data="productGroups"
          name="productGroup"
          :selectedId="productGroup?.id"
          placeholder="Select Group"
          searchProperty="name"
          :has-actions="$permission.has('manageProductGroups')"
          @select="event => onSelectProductGroup(event, id)"
          @addGroup="onAddGroup(id)"
          @edit="onEdit"
          @remove="onRemove"
          alignLeft
          :stopPropagation="true"
          @open="closeIfOpened(`selectProductGroup-${id}`)"
          @onClose="close(`selectProductGroup-${id}`)"
        />
        <v-badge v-else>Unassigned</v-badge>
      </template>
      <template
        #cell-priceChangeNotification="{
          row: {
            priceChangeNotification: { percent, isDefault },
          },
        }"
      >
        <span v-if="!percent && isDefault">-</span>
        <v-data-with-info v-else info="Default value" :show-underline="isDefault">
          {{ percent }}%
        </v-data-with-info>
      </template>
      <template #cell-isHidden="{ row }">
        <div v-if="groupViewMode" class="location-wrapper" @click.stop="openLocationModal(row)">
          <v-location-badge v-if="row.visibleForAllFromGroup" type="green">All</v-location-badge>
          <v-location-badge v-else-if="row.visibleForVenueIdsFromGroup.length" type="yellow"
            >Some</v-location-badge
          >
          <v-location-badge v-else type="red">None</v-location-badge>
          <font-awesome-icon icon="pen" class="location-edit"></font-awesome-icon>
        </div>
        <ez-button
          v-else-if="hasManageDistributorPermission"
          type="link"
          @click.stop="updateVisibility(row)"
        >
          <status-badge :status="row.isHidden ? 'hidden' : 'visible'" />
        </ez-button>
        <status-badge v-else :status="row.isHidden ? 'hidden' : 'visible'" />
      </template>
      <template #cell-price="{ row: { price, marketPrice } }">
        <v-price :price="price || 0" :is-market-price="marketPrice" />
      </template>
      <template #cell-venueInventoryTracked="{ row }">
        <v-status-dropdown
          @change="updateTracking($event, row)"
          :data="$helpers.getTrackingOptions(row.venueInventoryTracked)"
        />
      </template>
    </ez-table>
    <template v-if="!allSuplierProducts.length">
      <empty-state class="empty-state-center" v-if="hasFilters">
        <template #badge><img src="@/assets/no-products-search-state.svg" alt="" /></template>
        <template #title>No products match this search</template>
        <template #info>Try with a different search.</template>
      </empty-state>
      <empty-state class="empty-state-center" v-else>
        <template #badge><img src="@/assets/no-product-empty-state.svg" alt="" /></template>
        <template #title>No products listed</template>
        <!-- eslint-disable-next-line -->
      </empty-state>
    </template>
    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>
    <ez-loader :show="isLoading">Loading...</ez-loader>
    <ez-load-more
      v-if="meta.nextId && !isLoadingMore && !isLoading"
      @loadMore="onLoadMoreClick"
    ></ez-load-more>
    <ez-products-update-modal
      v-if="hasManageDistributorPermission"
      ref="productsUpdateModal"
      :productGroups="productGroups"
      hasNotifyPriceChange
      hasVisibility
      hasPricePerUnit
      :numberOfProducts="bulkActionProductsCount"
      :has-tracking="hasManageInventoryPermission && isInventoryEnabled"
      @update="bulkUpdateProducts"
      @addProductGroup="addProductGroup"
      @editProductGroup="editProductGroup"
      @removeProductGroup="removeProductGroup"
    />
    <remove-group-modal ref="removeGroupModal" @remove="onRemoveConfirm" />
    <add-group-modal ref="addGroupModal" @confirm="onAddConfirm" />
    <footer-for-update
      v-if="bulkActionProductsCount"
      entity="Products"
      :items-count="bulkActionProductsCount"
      :hasSecondaryAction="groupViewMode"
      secondaryActionEntity="Visibility"
      @footerAction="editSelectedProducts"
      @footerSecondaryAction="editSelectedProductsVisibility"
    />
    <select-locations-modal
      ref="locationsModal"
      :locations="selectedProductLocations"
      :productId="productId"
      :products-bulk-update="productsBulkUpdate"
      @productsBulkUpdate="bulkUpdateProductsLocations"
      @success="updateProductLocations"
      @close="onProductLocationsClose"
    />
    <ez-confirmation-modal ref="bulkUpdateConfirmation" type="red" icon="question">
      <template #title>Confirm changes</template>
      <template #content>
        <p>Your changes will affect <strong>all outlets</strong>.</p>
      </template>
      <template #footer>
        <ez-button @click="() => $refs.bulkUpdateConfirmation.close()" type="link"
          >Cancel</ez-button
        >
        <ez-button @click="onConfirm" type="red">Confirm</ez-button>
      </template>
    </ez-confirmation-modal>
    <ez-csv-upload-modal
      ref="csvUploadModal"
      :uploadCSV="uploadCSVAction"
      :onUploadFinished="onUploadFinished"
      :forAll="true"
    />
    <ez-product-modal
      v-if="selectedProduct"
      class="product-modal"
      ref="productModal"
      formKey="product-edit-modal"
      :formAction="editAction"
      :distributorId="selectedProduct?.distributor?.id"
      :product="selectedProduct"
      :updateProductGroups="updateProductGroups"
      @success="onModalSuccess"
      @close="() => (updateProductGroups = false)"
      @error="() => (updateProductGroups = false)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { LOADING_KEY, PRODUCT_NOT_TRACKED, SELECT_DESELECT_ALL } from '@/util/constants';
import flash from '@/components/ui/FlashMessage';
import EzFilterList from '@/components/ui/FilterList';
import EzInput from '@/components/ui/Input';
import VFilterDropdown from '@/components/v3/patterns/VFilterDropdown';
import VSelectSearch from '@/components/v3/patterns/VSelectSearch';
import EzProductGroupFilter from '@/components/ui/Filter/ProductGroup.vue';
import EzProductsUpdateModal from '@/components/ui/Modal/EzProductsUpdateModal.vue';
import EzCategoryFilter from '@/components/ui/Filter/Category.vue';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzTable from '@/components/ui/Table';
import EzEntityInfo from '@/components/ui/EntityInfo';
import VDistributorEntityInfo from '@/components/v3/patterns/VDistributorEntityInfo';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import VPrice from '@/components/v3/elements/VPrice';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import EmptyState from '@/views/common/empty-state';
import EzLoader from '@/components/ui/Loader/EzLoader';
import EzLoadMore from '@/components/ui/LoadMore';
import VStatusDropdown from '@/components/v3/patterns/VStatusDropdown';
import VBadge from '@/components/v3/elements/VBadge';
import VSelectSearchProductGroup from '@/components/v3/elements/VProductGroupSettings/VSelectSearchProductGroup.vue';
import EzAlert from '@/components/ui/Alert';
import EzButton from '@/components/ui/Button';
import { debounce, clone } from '@/util/utils';
import RemoveGroupModal from '@/components/v3/elements/VProductGroupSettings/RemoveGroupModal.vue';
import AddGroupModal from '@/components/v3/elements/VProductGroupSettings/AddGroupModal.vue';
import FooterForUpdate from '@/views/platform/venue/xero/settings/FooterForUpdate';
import StatusBadge from '@/views/common/status-badge/StatusBadge.vue';
import VLocationBadge from '@/components/v3/elements/VLocationBadge';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal.vue';
import { EzCsvUploadModal } from '@/components/ui/Modal';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown';
import downloadAttachment from '@/util/downloadAttachment';
import EzSelect from '@/components/ui/Select/EzSelect.vue';
import EzProductModal from '@/views/common/products/new-product-supplier/EzProductModal';
import SelectLocationsModal from './SelectLocationsModal.vue';

const FilterArea = {
  List: 'list',
  Dropdown: 'dropdown',
};

export default {
  components: {
    EzFilterList,
    EzInput,
    VFilterDropdown,
    VSelectSearch,
    EzProductGroupFilter,
    EzCategoryFilter,
    EzCheckbox,
    EzTable,
    EzEntityInfo,
    VDistributorEntityInfo,
    VDataWithInfo,
    VPrice,
    EmptyState,
    EzSpinner,
    EzLoadMore,
    EzLoader,
    VStatusDropdown,
    VBadge,
    VSelectSearchProductGroup,
    EzAlert,
    EzButton,
    EzProductsUpdateModal,
    RemoveGroupModal,
    AddGroupModal,
    FooterForUpdate,
    StatusBadge,
    VLocationBadge,
    SelectLocationsModal,
    EzConfirmationModal,
    EzCsvUploadModal,
    EzButtonDropdown,
    EzSelect,
    EzProductModal,
  },
  props: {
    productGroupFilter: {
      type: Object,
      required: false,
    },
    trackingFilter: {
      type: Object,
      required: false,
    },
    newFilter: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      allSuplierProducts: [],
      selectedProduct: {},
      updateProductGroups: false,
      meta: {},
      FilterArea,
      dropdownFilters: {},
      listFilters: { term: null },
      distributors: [],
      categories: [],
      productGroups: [],
      isOpened: null,
      newProductsCount: null,
      selected: false,
      listOfProductIds: [],
      currentProductId: null,
      selectedProductLocations: [],
      productId: null,
      updateData: null,
      productsBulkUpdate: false,
      trackingData: [
        { id: null, name: 'All' },
        {
          id: '',
          name: 'Unassinged',
        },
        {
          id: 'true',
          name: 'Tracked',
        },
        { id: 'false', name: 'Not Tracked' },
      ],
    };
  },
  computed: {
    ...mapState('entities/users', ['context', 'contextId', 'loggedUser']),
    ...mapGetters('loading', ['getLoading']),
    ...mapGetters('entities/users', ['getVenues']),
    groupViewMode() {
      if (this.loggedUser.context === 'venue-group') return true;
      return false;
    },
    isInventoryEnabled() {
      return this.context?.features?.inventory || false;
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.VENUE_FETCH_MORE_ALL_SUPPLIERS_PRODUCTS);
    },
    isNewProductsLoading() {
      return this.getLoading(LOADING_KEY.VENUE_BULK_UPDATE_SUPPLIERS_PRODUCTS);
    },
    isLoading() {
      return (
        this.getLoading(LOADING_KEY.VENUE_FETCH_ALL_SUPPLIERS_PRODUCTS) ||
        this.getLoading(LOADING_KEY.VENUE_FETCH_DISTRIBUTOR_FILTER_OPTIONS) ||
        this.getLoading(LOADING_KEY.VENUE_FETCH_CATEGORIES_FILTER)
      );
    },
    isPageLoading() {
      return (
        this.getLoading(LOADING_KEY.VENUE_HIDE_UNHIDE_DISTRIBUTOR_PRODUCT) ||
        this.getLoading(LOADING_KEY.VENUE_UPDATE_SUPPLIER_PRODUCT) ||
        this.getLoading(LOADING_KEY.FETCH_PRODUCT_GROUPS)
      );
    },
    hasManageDistributorPermission() {
      return this.$permission.has('manageDistributor');
    },
    hasManageInventoryPermission() {
      return this.$permission.has('manageInventory');
    },
    distributorFilter() {
      return this.distributors;
    },
    productGroupsFilter() {
      return [
        { id: null, name: 'All Product Groups' },
        { id: '', name: 'Unassigned' },
        ...this.productGroups,
      ];
    },
    filters() {
      return { ...this.listFilters, ...this.dropdownFilters };
    },
    hasFilters() {
      return Object.entries(this.filters).reduce(
        (prevFilter, [, value]) => prevFilter || !!value,
        false,
      );
    },
    canHideProducts() {
      return this.$permission.has('hideProducts');
    },
    bulkActionProductsCount() {
      return this.selected
        ? this.meta.totalCount - this.listOfProductIds.length
        : this.listOfProductIds.length;
    },
    areAllSelected() {
      return this.bulkActionProductsCount === this.meta.totalCount;
    },
    venues() {
      return this.getVenues;
    },
    editAction() {
      if (this.selectedProduct)
        return `/venue/distributors/${this.selectedProduct?.distributor?.id || null}/products`;
      return null;
    },
  },
  methods: {
    ...mapActions('entities/products', [
      'venueFetchAllSuppliersProducts',
      'venueBulkUpdateSuppliersProducts',
      'venueFetchProductGroups',
      'venueHideDistributorProduct',
      'venueUnhideDistributorProduct',
      'venueUpdateSuppliersProduct',
      'venueFetchAllProductsStats',
      'venueCreateProductGroup',
      'venueUpdateProductGroup',
      'venueDeleteProductGroup',
      'venueUploadAllDistributorProducts',
      'venueFetchNewDistributorProduct',
    ]),
    ...mapActions('entities/venues', [
      'venueFetchDistributorFilterOptions',
      'outletDownloadAllProductsCSV',
    ]),
    ...mapActions('entities/categories', ['venueFetchCategoriesNew']),
    nameCheckbox(h) {
      return h('span', { class: 'u-flex-center select-all-checkbox' }, [
        h(
          'ez-checkbox',
          {
            class: 'mr-8',
            props: {
              label: 'Product',
              indeterminate: this.bulkActionProductsCount > 0 && !this.areAllSelected,
              checked: this.areAllSelected,
            },
            on: {
              change: event => {
                this.selectDeselectAll(event);
              },
            },
          },
          [],
        ),
      ]);
    },
    isProductSelected(product) {
      return (
        (this.selected && this.listOfProductIds.findIndex(el => el.productId === product.id) < 0) ||
        (!this.selected && this.listOfProductIds.findIndex(el => el.productId === product.id) >= 0)
      );
    },
    onCheckboxChange(product, checked) {
      if (this.selected !== checked) this.listOfProductIds.push({ productId: product.id });
      else
        this.listOfProductIds = this.listOfProductIds.filter(item => item.productId !== product.id);
    },
    async selectDeselectAll(selected) {
      this.selected = selected;
      this.listOfProductIds = [];
    },
    resetSelectedProducts() {
      this.selected = false;
      this.listOfProductIds = [];
    },
    editSelectedProducts() {
      this.$refs.productsUpdateModal.open();
    },
    editSelectedProductsVisibility(allSelected = false) {
      if (allSelected) {
        this.selectedProductLocations = this.venues.map(item => item.id);
      } else {
        this.selectedProductLocations = [];
      }
      this.productId = null;
      this.productsBulkUpdate = true;
      this.$refs.locationsModal.open();
    },
    async fetchAllSuppliersProducts(
      query = {},
      loadingKey = LOADING_KEY.VENUE_FETCH_ALL_SUPPLIERS_PRODUCTS,
    ) {
      const { data } = await this.venueFetchAllSuppliersProducts({
        ...(this.meta.nextId && { nextId: this.meta.nextId }),
        ...(this.meta.nextValue && { nextValue: this.meta.nextValue }),
        ...query,
        loadingKey,
      });
      if (loadingKey === LOADING_KEY.VENUE_FETCH_ALL_SUPPLIERS_PRODUCTS) {
        this.allSuplierProducts = data.data;
      } else {
        this.allSuplierProducts = [...this.allSuplierProducts, ...data.data];
      }
      this.meta = data.meta;
      this.$emit('updateHeaderInfo', {
        productCount: this.meta.totalCount,
      });
    },
    async onLoadMoreClick() {
      await this.fetchAllSuppliersProducts(
        {
          ...this.filters,
          ...(this.filters?.visible !== this.filters?.hidden && this.filters?.visible
            ? { visibility: true }
            : {}),
          ...(this.filters?.visible !== this.filters?.hidden && this.filters?.hidden
            ? { visibility: false }
            : {}),
        },
        LOADING_KEY.VENUE_FETCH_MORE_ALL_SUPPLIERS_PRODUCTS,
      );
    },
    async fetchDistributors() {
      const { data } = await this.venueFetchDistributorFilterOptions({
        venueId: this.contextId,
        query: { for: 'products' },
      });
      this.distributors = data.data;
    },
    async fetchCategoriesFilter() {
      const {
        data: { data },
      } = await this.venueFetchCategoriesNew();
      this.categories = data;
    },
    async fetchProductGroups() {
      const {
        data: { data },
      } = await this.venueFetchProductGroups();
      this.productGroups = data;
    },
    updateFilters: debounce(async function deb(area, event) {
      if (event[0] === 'term') {
        const [filterName, value] = event;
        this.listFilters = { ...this.listFilters, [filterName]: value };
      } else if (event[0] === 'productGroupId') {
        this.dropdownFilters = {
          ...this.dropdownFilters,
          productGroupId: event[1].id,
        };
        this.listFilters = {
          ...this.listFilters,
          productGroupId: event[1].id,
        };
      } else if (event[0] === 'distributorId') {
        this.dropdownFilters = {
          ...this.dropdownFilters,
          distributorId: event[1].id,
        };
        this.listFilters = {
          ...this.listFilters,
          distributorId: event[1].id,
        };
      } else {
        this.dropdownFilters = {
          ...this.dropdownFilters,
          ...(typeof event.categoryId === 'object' &&
            event.categoryId !== null && { categoryId: event.categoryId.id }),
          ...(typeof event.distributorId === 'object' &&
            event.distributorId !== null && { distributorId: event.distributorId.id }),
          ...(typeof event.productGroupId === 'object' &&
            event.productGroupId !== null && { productGroupId: event.productGroupId.id }),
          ...(event.visible !== undefined && { visible: event.visible }),
          ...(event.newFilter !== undefined && { newFilter: event.newFilter }),
          ...(event.hidden !== undefined && { hidden: event.hidden }),
          ...(typeof event.venueInventoryTracked === 'object' &&
            event.venueInventoryTracked !== null && {
              venueInventoryTracked: event.venueInventoryTracked.id,
            }),
        };
        if (event.distributorId === undefined) delete this.dropdownFilters.distributorId;
        this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());
      }
      this.resetSelectedProducts();
      this.allSuplierProducts = [];
      this.meta = {
        nextId: null,
        nextValue: null,
        ...(this.listFilters.term && { term: this.listFilters.term }),
      };
      await this.fetchAllSuppliersProducts({
        ...this.filters,
        ...(this.filters.visible !== this.filters.hidden && this.filters.visible
          ? { visibility: true }
          : {}),
        ...(this.filters.visible !== this.filters.hidden && this.filters.hidden
          ? { visibility: false }
          : {}),
      });
    }, 300),
    async resetFilters(area) {
      if (area === FilterArea.List) {
        Object.keys(this.listFilters).forEach(key => {
          this.listFilters[key] = null;
          this.dropdownFilters[key] = null;
        });
      } else if (area === FilterArea.Dropdown) {
        this.dropdownFilters = {};
        delete this.listFilters.productGroupId;
        delete this.listFilters.distributorId;
        this.visible = false;
        this.hidden = false;
      }

      this.$nextTick(() => this.$refs.filtersGroup.syncLocalFilters());
      this.$nextTick(() => this.$refs.filtersGroup.closeFilterDropdown());
      this.resetSelectedProducts();
      this.allSuplierProducts = [];
      this.meta = {};
      await this.fetchAllSuppliersProducts({
        ...this.filters,
        ...(this.filters?.visible !== this.filters?.hidden && this.filters?.visible
          ? { visibility: true }
          : {}),
        ...(this.filters?.visible !== this.filters?.hidden && this.filters?.hidden
          ? { visibility: false }
          : {}),
      });
    },
    updateProductAvailability(venue) {
      const { id, isHidden, distributorId } = venue;
      const data = {
        productId: id,
        venueId: this.contextId,
        distributorId,
      };
      return isHidden
        ? this.venueUnhideDistributorProduct(data)
        : this.venueHideDistributorProduct(data);
    },
    async updateVisibility(venue) {
      try {
        await this.updateProductAvailability(venue);
        const productIndex = this.allSuplierProducts.findIndex(item => item.id === venue.id);
        this.allSuplierProducts[productIndex].isHidden =
          !this.allSuplierProducts[productIndex].isHidden;
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
    async updateTracking(name, row) {
      const isTrackingUnassigned = row.venueInventoryTracked === null;
      try {
        const { data } = await this.venueUpdateSuppliersProduct({
          productId: row.id,
          venueInventoryTracked: name !== PRODUCT_NOT_TRACKED,
        });
        const productIndex = this.allSuplierProducts.findIndex(item => item.id === data.data.id);
        this.allSuplierProducts[productIndex].venueInventoryTracked = name !== PRODUCT_NOT_TRACKED;
        if (this.allSuplierProducts[productIndex].productGroup !== null && isTrackingUnassigned)
          this.newProductsCount -= 1;
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
    closeIfOpened(selectRef) {
      if (this.isOpened && this.isOpened === selectRef) {
        this.$refs[this.isOpened].close();
        this.isOpened = null;
      } else {
        this.isOpened = selectRef;
      }
    },
    close() {
      this.isOpened = null;
    },
    async onSelectProductGroup(productGroup, productId) {
      try {
        const { data } = await this.venueUpdateSuppliersProduct({
          productId,
          productGroupId: productGroup.id,
        });
        const productIndex = this.allSuplierProducts.findIndex(item => item.id === data.data.id);
        this.allSuplierProducts[productIndex].productGroup = clone(data.data.productGroup);
        if (this.allSuplierProducts[productIndex].venueInventoryTracked !== null)
          this.newProductsCount -= 1;
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
    filterApproval() {
      this.dropdownFilters = {};
      this.listFilters.term = null;
      this.updateFilters(FilterArea.Dropdown, {
        ...this.dropdownFilters,
        newFilter: true,
      });
    },
    async bulkUpdateProducts(updateData) {
      if (this.groupViewMode) {
        this.updateData = updateData;
        this.$refs.bulkUpdateConfirmation.open();
      } else {
        await this.bulkUpdateProductAction(updateData);
      }
    },
    async bulkUpdateProductAction(updateData) {
      let idsForUpdate = [];
      if (!this.selected) idsForUpdate = this.listOfProductIds.map(item => item.productId);
      else {
        this.allSuplierProducts.forEach(prod => {
          if (this.listOfProductIds.findIndex(item => prod.id === item.productId) === -1)
            idsForUpdate.push(prod.id);
        });
      }
      const updatedProducts = await this.venueBulkUpdateSuppliersProducts({
        config: {
          ...updateData,
          state: this.selected
            ? SELECT_DESELECT_ALL.selectedAll
            : SELECT_DESELECT_ALL.deselectedAll,
          items: this.listOfProductIds,
          idsForUpdate,
        },
        ...this.filters,
        ...(this.filters?.visible !== this.filters?.hidden && this.filters?.visible
          ? { visibility: true }
          : {}),
        ...(this.filters?.visible !== this.filters?.hidden && this.filters?.hidden
          ? { visibility: false }
          : {}),
      });
      this.$refs.productsUpdateModal.close();

      const updatedProductPrices = updatedProducts.data.data.products;
      if (updatedProductPrices && updatedProductPrices.length) {
        updatedProductPrices.forEach(prod => {
          const index = this.allSuplierProducts.findIndex(item => item.id === prod.productId);
          if (index !== -1 && prod.price !== null)
            this.allSuplierProducts[index].price = prod.price;
        });
      }

      let updatedProductProps = updatedProducts.data.data.dto;
      if ('productGroupId' in updatedProductProps && updatedProductProps.productGroupId !== null) {
        const productGroup = this.productGroups.find(
          item => item.id === updatedProductProps.productGroupId,
        );
        updatedProductProps = {
          ...updatedProductProps,
          productGroup: {
            id: updatedProductProps.productGroupId,
            name: productGroup.name,
          },
        };
      } else if (
        'productGroupId' in updatedProductProps &&
        updatedProductProps.productGroupId === null
      ) {
        updatedProductProps = {
          ...updatedProductProps,
          productGroup: null,
        };
      }
      if (updatedProductProps.priceChangeNotificationPercent) {
        updatedProductProps = {
          ...updatedProductProps,
          priceChangeNotification: {
            isDefault: false,
            percent: updatedProductProps.priceChangeNotificationPercent,
          },
        };
      }
      if ('visibility' in updatedProductProps) {
        updatedProductProps = {
          ...updatedProductProps,
          isHidden: !updatedProductProps.visibility,
        };
      }

      if (this.selected) {
        this.allSuplierProducts = this.allSuplierProducts.map(prod => {
          if (this.listOfProductIds.findIndex(item => prod.id === item.productId) === -1) {
            return {
              ...prod,
              ...updatedProductProps,
            };
          }
          return prod;
        });
      } else {
        this.listOfProductIds.forEach(item => {
          const index = this.allSuplierProducts.findIndex(prod => prod.id === item.productId);
          this.allSuplierProducts[index] = {
            ...this.allSuplierProducts[index],
            ...updatedProductProps,
          };
        });
      }

      const { data } = await this.venueFetchAllProductsStats();
      this.newProductsCount = data.data.newProductsCount;
    },
    onAddGroup(id) {
      this.currentProductId = id;
      this.$refs.addGroupModal.open();
    },
    onEdit(group) {
      this.$refs.addGroupModal.open(group, true);
    },
    onRemove(group) {
      this.$refs.removeGroupModal.open(group);
    },
    async onAddConfirm({ groupId, name }) {
      const isEdit = !!groupId;
      try {
        if (isEdit) {
          const { data } = await this.venueUpdateProductGroup({
            id: groupId,
            name,
            formKey: 'createProductGroup',
          });
          const productsWithGroup = this.allSuplierProducts.filter(
            item => item.productGroup?.id === data.data.id,
          );
          productsWithGroup.forEach(product => {
            const productIndex = this.allSuplierProducts.findIndex(item => item.id === product.id);
            this.allSuplierProducts[productIndex].productGroup = clone(data.data);
          });
        } else {
          const { data } = await this.venueCreateProductGroup({
            name,
            formKey: 'createProductGroup',
          });
          const currentProductIndex = this.allSuplierProducts.findIndex(
            item => item.id === this.currentProductId,
          );
          this.allSuplierProducts[currentProductIndex].productGroup = clone(data.data);
          this.newProductsCount -= 1;
        }

        flash.success({ title: `Product group successfully ${isEdit ? 'updated' : 'created'}!` });
        this.$refs.addGroupModal.close();

        await this.fetchProductGroups();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    async onRemoveConfirm(groupId) {
      try {
        await this.venueDeleteProductGroup({ id: groupId });
        const productsWithGroup = this.allSuplierProducts.filter(
          item => item.productGroup?.id === groupId,
        );
        productsWithGroup.forEach(product => {
          const productIndex = this.allSuplierProducts.findIndex(item => item.id === product.id);
          this.allSuplierProducts[productIndex].productGroup = null;
          this.newProductsCount += 1;
        });
        flash.success({ title: 'Product group successfully removed!' });

        this.$refs.removeGroupModal.close();

        await this.fetchProductGroups();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        flash.error({ title: 'Something went wrong!' });
      }
    },
    addProductGroup(productGroup) {
      this.productGroups = [...this.productGroups, productGroup];
    },
    editProductGroup(productGroup) {
      const productGroupIndex = this.productGroups.findIndex(item => item.id === productGroup.id);
      this.productGroups[productGroupIndex].name = productGroup.name;

      const productsWithGroup = this.allSuplierProducts.filter(
        item => item.productGroup?.id === productGroup.id,
      );
      productsWithGroup.forEach(product => {
        const productIndex = this.allSuplierProducts.findIndex(item => item.id === product.id);
        this.allSuplierProducts[productIndex].productGroup = clone(productGroup);
      });
    },
    removeProductGroup(groupId) {
      this.productGroups = this.productGroups.filter(item => item.id !== groupId);
      const productsWithGroup = this.allSuplierProducts.filter(
        item => item.productGroup?.id === groupId,
      );
      productsWithGroup.forEach(product => {
        const productIndex = this.allSuplierProducts.findIndex(item => item.id === product.id);
        this.allSuplierProducts[productIndex].productGroup = null;
        this.newProductsCount += 1;
      });
    },
    openLocationModal(product) {
      this.selectedProductLocations = product.visibleForVenueIdsFromGroup;
      this.productId = product.id;
      this.productsBulkUpdate = false;
      this.$refs.locationsModal.open();
    },
    updateProductLocations(val) {
      const index = this.allSuplierProducts.findIndex(item => item.id === this.productId);
      if (index !== -1) {
        this.allSuplierProducts[index].visibleForVenueIdsFromGroup = val;
        if (val.length === this.venues.length) {
          this.allSuplierProducts[index].visibleForAllFromGroup = true;
        } else {
          this.allSuplierProducts[index].visibleForAllFromGroup = false;
        }
      }
    },
    bulkUpdateProductsLocations(val) {
      this.updateData = val;
      this.$refs.bulkUpdateConfirmation.open();
    },
    async bulkUpdateProductsLocationsAction(val) {
      try {
        await this.venueBulkUpdateSuppliersProducts({
          config: {
            visibleVenueIdsFromGroup: val,
            state: this.selected
              ? SELECT_DESELECT_ALL.selectedAll
              : SELECT_DESELECT_ALL.deselectedAll,
            items: this.listOfProductIds,
          },
          ...this.filters,
          ...(this.filters?.visible !== this.filters?.hidden && this.filters?.visible
            ? { visibility: true }
            : {}),
          ...(this.filters?.visible !== this.filters?.hidden && this.filters?.hidden
            ? { visibility: false }
            : {}),
        });
        flash.success({
          title: "Product's availability successfully updated",
        });
        this.productsBulkUpdate = false;
        this.$refs.locationsModal.close();
        this.meta = {};
        this.fetchAllSuppliersProducts({
          ...this.filters,
          ...(this.filters?.visible !== this.filters?.hidden && this.filters?.visible
            ? { visibility: true }
            : {}),
          ...(this.filters?.visible !== this.filters?.hidden && this.filters?.hidden
            ? { visibility: false }
            : {}),
        });
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
        });
      }
    },
    onProductLocationsClose() {
      this.selectedProductLocations = [];
      this.productId = null;
    },
    async onConfirm() {
      if (!this.productsBulkUpdate) {
        await this.bulkUpdateProductAction(this.updateData);
      } else {
        await this.bulkUpdateProductsLocationsAction(this.updateData);
      }
      this.$refs.bulkUpdateConfirmation.close();
      this.updateData = null;
    },
    uploadCSVModal() {
      this.$refs.csvUploadModal.open();
    },
    uploadCSVAction(data) {
      return this.venueUploadAllDistributorProducts({
        data,
      });
    },
    onUploadFinished() {
      this.fetchAllSuppliersProducts(this.filters);
    },
    async exportCSV() {
      const { data, headers } = await this.outletDownloadAllProductsCSV({});
      const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
      const name = Array.isArray(filename) ? decodeURI(filename[1]) : 'Products.xlsx';
      downloadAttachment(data, name);
    },
    async openSingleProduct(product) {
      const { editable, id } = product;
      if (!editable) return;
      const { data } = await this.venueFetchNewDistributorProduct({
        venueId: this.contextId,
        distributorId: product.distributor.id,
        productId: id,
      });
      this.selectedProduct = {
        ...data.data,
        distributor: product.distributor,
      };
      this.openEditModal();
    },
    openEditModal() {
      this.$refs.productModal.open();
    },
    closeEditModal() {
      this.$refs.productModal.close();
      if (this.updateProductGroups) this.updateProductGroups = false;
    },
    onModalSuccess(event) {
      const index = this.allSuplierProducts.findIndex(prod => prod.id === event.data.data.id);
      this.allSuplierProducts[index] = {
        ...this.allSuplierProducts[index],
        ...event.data.data,
      };
      flash.success({
        title: `Product successfully updated.`,
      });
      this.selectedProduct = {};
      this.closeEditModal();
    },
  },
  async created() {
    if (this.productGroupFilter && this.productGroupFilter.id === '')
      this.dropdownFilters.productGroupId = '';
    if (this.trackingFilter && this.trackingFilter.id === '')
      this.dropdownFilters.venueInventoryTracked = '';
    if (this.newFilter) this.dropdownFilters.newFilter = true;
    if (this.$route.query.productGroup && this.$route.query.productGroup === 'unassigned')
      this.dropdownFilters.productGroupId = '';
    if (this.$route.query.supplierId)
      this.dropdownFilters.distributorId = +this.$route.query.supplierId;
    await Promise.all([
      this.fetchAllSuppliersProducts({
        ...this.filters,
        ...(this.$route.query.orderId ? { orderId: this.$route.query.orderId } : {}),
      }),
      this.fetchDistributors(),
      this.fetchProductGroups(),
      this.fetchCategoriesFilter(),
    ]);
    const { data } = await this.venueFetchAllProductsStats();
    this.newProductsCount = data.data.newProductsCount;

    this.$emit('updateHeaderInfo', {
      productCount: this.meta.totalCount,
    });
  },
};
</script>

<style lang="scss" scoped>
.all-products__actions {
  display: flex;

  :deep() .dropdown-filters {
    .input-group {
      margin: 0;
    }
    .filters-area {
      hr {
        margin-bottom: 12px;
      }
    }
    .filters-area__row:not(:first-child) {
      justify-content: start;
    }
  }

  :deep() .ez-filter-list {
    &__items {
      margin: 0;
    }
  }
  :deep() .ez-filter-list .ez-filter-list__items .product-group-filter .select-search__trigger {
    min-width: 172px;
  }

  :deep() .ez-filter-list .ez-filter-list__items .select-search__wrapper {
    .select-search__dropdown,
    .select-search__dropdown.select-search__dropdown--width-100 {
      min-width: 100%;
      width: max-content;
      color: $color-gray-25;
    }
  }

  :deep() .select-search {
    color: $color-gray-6C;

    &__list {
      max-height: 244px;
    }
  }

  .button-dropdown {
    margin-left: auto;
  }
}

.select-supplier {
  :deep() .select-search__trigger {
    width: 100%;
    color: $color-gray-6C;
  }

  :deep() .select-search__list {
    min-height: 20rem;
  }
}
.product-info {
  display: flex;
  flex-direction: column;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .product-info-secondary {
    &--category {
      color: $color-gray-25;
      font-weight: 600;
    }
  }
}
:deep() .table {
  .visibility-cell {
    width: 100px;
    overflow: visible;

    .button {
      padding-left: 0;
    }
    .location-edit {
      display: none;
    }

    &:hover {
      cursor: pointer;
      .location-edit {
        display: inline-block;
      }
      .location-badge {
        display: none;
      }
    }
  }

  .product-group-cell {
    overflow: visible;

    .select-search {
      width: auto;
      max-width: 100%;
    }

    .select-search__wrapper .select-search__value {
      color: $color-gray-6C;
    }

    .select-search__wrapper .select-search__trigger {
      display: inline-block;
      font-size: 11px;
      font-size: 0.6875rem;
      max-width: 100%;
      padding: 0 6px;
      color: #6c7995;
      font-weight: 500;
      line-height: 1.2;
      border: 1px solid #eceef5;
      background-color: #eceef5;
      border-radius: 2px;
      text-transform: uppercase;
      overflow-x: hidden;
      text-overflow: ellipsis;
      height: auto;
      min-width: auto;
      width: auto;

      &.focused {
        border: 1px solid #4d7cfe;
        background-color: #fff;
      }

      span {
        margin-right: 6px;
      }
    }

    .has-group .select-search__wrapper .select-search__trigger {
      background-color: #fff;
    }
    .select-search__wrapper .select-search__dropdown {
      width: 320px;
    }
  }
}

:deep() .alert__cta .button--blue-link {
  background-color: #fff;
}
:deep() .empty-state-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.status-checkbox {
  @include font-size(14px, 20px);
}

.select-all-checkbox {
  .input-group {
    @include font-size(12px);
  }
}

:deep() .product-group-filter .ez-select__display-container {
  height: 2.25rem;
}

:deep() .ez-simple-dropdown.status-dropdown {
  width: 100%;
}

.split {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px 12px;
}

:deep() .ez-filter-list .ez-filter-list__items .select-search__trigger {
  width: auto;
  min-width: 128px;
}
:deep() .ez-filter-list .ez-filter-list__items .ez-select {
  width: auto;
  min-width: 164px;
  .ez-select__dropdown {
    min-width: 100%;
    width: max-content;
  }
  svg {
    color: $color-gray-6C;
  }
}
</style>
