<script>
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown';
import EzButton from '@/components/ui/Button/EzButton';
import { CaseType, FILE_TYPE_XLSX } from '@/util/constants';
import { mapActions, mapGetters, mapState } from 'vuex';
import downloadAttachment from '@/util/downloadAttachment';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { convertToSentenceCase } from '@/util/utils';

/**
 *
 * @version 1.0.0
 * @since 2.3.0
 */
export default {
  components: {
    EzButton,
    EzSimpleDropdown,
    EzLoader,
  },
  props: {
    action: {
      required: true,
      type: String,
    },
    orders: {
      required: true,
      type: Array,
    },
    filters: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    numberOfAppliedFilters: {
      type: Number,
      required: false,
    },
    exportToAccounting: {
      type: Boolean,
      required: false,
      default: false,
    },
    exportToAccountingDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isInvoice: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * This attribute is used for
     * marking the element when testing with cypress
     */
    dataCy: {
      required: false,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoaderShown: false,
      FILE_TYPE_XLSX,
    };
  },
  computed: {
    ...mapState('entities/orders', ['meta']),
    ...mapGetters('entities/users', ['isDistributor']),
    hasOrders() {
      return this.orders.length;
    },
    exportTypes() {
      const { exportTypes: types } = this.meta;

      let data;
      Object.keys(types).forEach(key => {
        data = {
          ...data,
          [key]: types[key].map(ty => ({
            id: ty,
            ...(this.isInvoice && ty === 'order_history'
              ? { text: 'Invoice History' }
              : { text: convertToSentenceCase(ty, true, CaseType.SNAKE_CASE) }),
            fileType: key,
          })),
        };
      });
      return data;
    },
    exportDisabled() {
      return (!(this.numberOfAppliedFilters ?? 1) && !this.isInvoice) || !this.hasOrders;
    },
  },
  methods: {
    async downloadFile(type, exportType) {
      const {
        venueId,
        distributorId,
        from,
        to,
        status,
        scheduledFrom,
        scheduledTo,
        fulfillmentWarehouseId,
        driverId,
      } = this.filters;
      const queryParams = [
        ...(from ? [`from=${from}`] : []),
        ...(to ? [`to=${to}`] : []),
        ...(scheduledFrom ? [`scheduledFrom=${scheduledFrom}`] : []),
        ...(scheduledTo ? [`scheduledTo=${scheduledTo}`] : []),
        ...(venueId ? [`venueId=${venueId}`] : []),
        ...(status ? status.map(st => `status[]=${st}`) : []),
        ...(distributorId ? [`distributorId=${distributorId}`] : []),
        ...(fulfillmentWarehouseId ? [`fulfillmentWarehouseId=${fulfillmentWarehouseId}`] : []),
        ...(driverId ? [`driverId=${driverId}`] : []),
        ...(exportType ? [`type=${exportType}`] : []),
      ].join('&');

      const route = `${this.action}/${type}${queryParams ? `?${queryParams}` : ''}`;

      try {
        this.isLoaderShown = true;
        const { data, headers } = await this.exportOrders(route);
        const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
        const name = Array.isArray(filename) ? decodeURI(filename[1]) : `Filtered Orders.${type}`;
        downloadAttachment(data, name);
        this.isLoaderShown = false;
      } catch (e) {
        this.isLoaderShown = false;
      }
    },
    ...mapActions('entities/orders', ['exportOrders']),
    exportOrder() {
      this.$emit('exportOrder');
    },
  },
};
</script>
<template>
  <div v-if="exportTypes">
    <ez-loader :show="isLoaderShown">Applying action...</ez-loader>
    <ez-simple-dropdown
      :data-cy="dataCy"
      :disabled="exportDisabled"
      :placeholder="!isInvoice ? 'Export Orders' : 'Export Invoices'"
      class="export-button"
      v-tooltip="
        exportDisabled
          ? {
              content: 'Select Filters before Exporting Orders',
              classes: ['tooltip-general', 'tooltip--reset-margin'],
              placement: 'top',
            }
          : null
      "
    >
      <template #dropdown>
        <p v-if="exportTypes.pdf" class="drp-subtitle mb-8">PDF File</p>
        <div class="btn-wrapper" v-for="type in exportTypes.pdf" :key="`pdf${type.id}`">
          <ez-button
            :data-cy="`${dataCy}-${type.fileType}-${type.id}`"
            @click="downloadFile(type.fileType, type.id)"
            :disabled="exportDisabled"
            v-tooltip="
              exportDisabled
                ? {
                    content: 'Select Filters before Exporting Orders',
                    classes: ['tooltip-general', 'tooltip--reset-margin'],
                    placement: 'top',
                  }
                : null
            "
            type="link"
          >
            <font-awesome-icon icon="file-pdf" />
            <span class="btn-txt">{{ type.text }}</span>
          </ez-button>
        </div>
        <p class="drp-subtitle mb-8">CSV File</p>
        <div class="btn-wrapper" v-for="type in exportTypes.xlsx" :key="`xlsx${type.id}`">
          <ez-button
            :data-cy="`${dataCy}-${type.fileType}-${type.id}`"
            :disabled="exportDisabled"
            v-tooltip="
              exportDisabled
                ? {
                    content: 'Select Filters before Exporting Orders',
                    classes: ['tooltip-general', 'tooltip--reset-margin'],
                    placement: 'top',
                  }
                : null
            "
            @click="downloadFile(type.fileType, type.id)"
            type="link"
          >
            <font-awesome-icon icon="file-csv" />
            <span class="btn-txt">{{ type.text }}</span>
          </ez-button>
        </div>
        <p v-if="exportToAccounting" class="drp-subtitle mb-8">Accounting Integration</p>
        <div v-if="exportToAccounting" class="btn-wrapper">
          <ez-button @click="exportOrder" type="link" :disabled="exportToAccountingDisabled">
            <img src="@/assets/icons/ic_export.svg" alt="Export to accounting integration" />
            <span class="btn-txt">Export All</span>
          </ez-button>
        </div>
      </template>
    </ez-simple-dropdown>
  </div>
</template>
<style lang="scss" scoped>
$icon-color: #b2b8c4;
$text-color: #252631;

:deep() .loader {
  @extend %whole-screen;
}

.export-button {
  width: fit-content;
  display: inline-block;

  .drp-subtitle {
    @include font-size(11px, 14px);
    letter-spacing: 0.15px;
    color: $color-gray-6C;
    font-weight: 600;
    margin-left: 14px;
    margin-right: 8px;
    text-transform: uppercase;
  }

  :deep() .ez-simple-dropdown {
    &__label {
      color: #6c7995;
      padding-right: 15px;
    }
    &__dropdown {
      min-width: 160px;
      width: 100%;
      .btn-wrapper {
        > button {
          box-shadow: none;
          border-left: 0;
          color: $color-gray-6C;
          opacity: 0.8;
          width: 100%;
          text-align: left;

          img {
            width: 14px;
          }

          &:hover {
            opacity: 1;
          }

          .btn-txt {
            margin-left: 0.5rem;
            color: $text-color;
          }
          &:disabled {
            background-color: #fff;
            .btn-txt {
              color: $input-disabled-color;
            }
          }
        }
      }
    }
  }
}
</style>
