<script>
/**
 *
 * @version 1.0.0
 * @since
 */
import { wizardListenerMixin } from '@/mixins/wizard';
import EzLoader from '@/components/ui/Loader';
import EzButton from '@/components/ui/Button';
import Paper from '@/components/layout/WizardOverviewPaper';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzMultipleImageUpload from '@/components/ui/ImageUpload/EzMultipleImageUpload';
import VProductEntityInfo from '@/components/v3/patterns/VProductEntityInfo';
import flash from '@/components/ui/FlashMessage';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
import VSubtotalInfo from '@/components/v3/patterns/VSubtotalInfo';
import {
  addTotalPriceCustom,
  getTotalAmountQuantityPrice,
  getTotalWithTax,
} from '@/util/utilsFinCalculator';
import VAdditionalDeliveryFee from '@/components/v3/patterns/VAdditionalDeliveryFee';
import VPrice from '@/components/v3/elements/VPrice';
import EzTable from '@/components/ui/Table/EzTable';
import { UNIT_TYPE_FRACTIONAL } from '@/util/constants';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import { getCurrency } from '@/util/utils';
import { OFFLINE_ORDER_STEP_1, OFFLINE_ORDER_STEP_2 } from './steps';

export default {
  mixins: [wizardListenerMixin],
  components: {
    VSubtotalInfo,
    EzButton,
    Paper,
    EzEntityInfo,
    EzMultipleImageUpload,
    EzLoader,
    VProductEntityInfo,
    VAdditionalDeliveryFee,
    VPrice,
    EzTable,
  },
  data() {
    return {
      orderId: null,
      loading: false,
      hasInvoice: false,
      outletCy,
    };
  },
  computed: {
    ...mapState('entities/venues', ['offlineOrder']),
    ...mapState('entities/users', ['contextId']),
    ...mapGetters('entities/venues', ['getOfflineOrderMulti']),
    distributor() {
      return this.offlineOrder.distributor;
    },
    selectedProducts() {
      return this.offlineOrder?.selectedProducts || [];
    },
    // Props for VSubtotalInfo - Start
    itemList() {
      return this.selectedProducts
        .map(item => (!item.totalPrice
          ? addTotalPriceCustom({ item })
          : { ...item, totalPrice: item.totalPrice }));
    },
    tax() {
      return this.distributor?.tax || 0;
    },
    taxCalculation() {
      return this.distributor?.taxCalculation || 'exclusive';
    },
    deliveryFeeTaxable() {
      return this.offlineOrder.supplier?.deliveryFeeWithoutTax;
    },
    deliveryFee() {
      return this.offlineOrder.deliveryFee ?? 0;
    },
    amountAdjustment() {
      return this.offlineOrder.amountAdjustment || 0;
    },
    taxAdjustment() {
      return this.offlineOrder.taxAdjustment || 0;
    },
    isSomeTbd() {
      return this.offlineOrder.selectedProducts.some(pr => (pr.marketPrice || (pr.priceUnit && pr.price > 0)) && !pr.totalToChange);
    },
    currencySymbol() {
      return getCurrency() || {};
    },
    // Props for VSubtotalInfo - End
    totalAmount() {
      return getTotalAmountQuantityPrice(this.offlineOrder.selectedProducts);
    },
    total() {
      return getTotalWithTax({
        itemList: this.itemList,
        deliveryFee: this.deliveryFee,
        deliveryFeeTaxable: this.deliveryFeeTaxable,
        amountAdjustment: this.amountAdjustment,
        taxAdjustment: this.taxAdjustment,
        tax: this.tax,
        taxCalculation: this.taxCalculation,
      });
    },
  },
  methods: {
    ...mapActions('entities/venues', ['createOfflineOrderMulti']),
    ...mapMutations('entities/venues', [
      'SET_OFFLINE_ORDER_RECEIPTS',
      'SET_OFFLINE_ORDER_DELIVERY_FEE',
      'SET_OFFLINE_ORDER_AMOUNT_ADJUSTMENT',
      'SET_OFFLINE_ORDER_TAX_ADJUSTMENT',
      'CLEAR_OFFLINE_ORDER_DELIVERY_FEE',
      'CLEAR_OFFLINE_ORDER_AMOUNT_ADJUSTMENT',
      'CLEAR_OFFLINE_ORDER_TAX_ADJUSTMENT',
    ]),
    editDistributor() {
      this.$emit('stepBack', OFFLINE_ORDER_STEP_1);
    },
    editProducts() {
      this.$emit('stepBack', OFFLINE_ORDER_STEP_2);
    },
    imageUploadChange(data) {
      this.SET_OFFLINE_ORDER_RECEIPTS(data);
      this.hasInvoice = !!data.get('invoice');
    },
    async onNextStep() {
      this.loading = true;
      try {
        const { data } = await this.createOfflineOrderMulti({
          venueId: this.contextId,
          data: this.getOfflineOrderMulti,
        });
        this.orderId = data.data[0].id;
        await this.$nextTick();

        this.loading = false;
        this.CLEAR_OFFLINE_ORDER_DELIVERY_FEE();
        this.CLEAR_OFFLINE_ORDER_AMOUNT_ADJUSTMENT();
        this.CLEAR_OFFLINE_ORDER_TAX_ADJUSTMENT();
        await this.$router.push({ name: 'venue-orders' });
        flash.success({
          title: 'Offline order created successfully.',
          message: 'You can see the order in your order history.',
        });
      } catch (e) {
        this.loading = false;
      }
    },
    editDeliveryFee(val) {
      this.SET_OFFLINE_ORDER_DELIVERY_FEE(val.amount);
    },
    editAmountAdjustment(val) {
      this.SET_OFFLINE_ORDER_AMOUNT_ADJUSTMENT(val.amount);
    },
    editTaxAdjustment(val) {
      this.SET_OFFLINE_ORDER_TAX_ADJUSTMENT(val.amount);
    },
    isToBeDetermined(product) {
      const { priceUnit, marketPrice, price } = product || {};
      return (!!priceUnit && price > 0) || marketPrice;
    },
    isFractional(orderingUnit) {
      return orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
  },
  created() {
    this.enableNextStep();
  },
};
</script>

<template>
  <div>
    <paper class="distributor">
      <template #actions>
        <ez-button
          type="link"
          formType="button"
          @click="editDistributor"
          :data-cy="outletCy.ORDERS.EXISTING_OFFLINE_ORDER.OVERVIEW.BUTTON__EDIT_SUPPLIER"
        >
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <ez-entity-info
        imgWidth="4rem"
        imgHeight="4rem"
        imgBorderRadius="50%"
        :img-url="distributor.logo"
      >
        {{ distributor.name }}
      </ez-entity-info>
      <hr />
      <div class="info info--vertical">
        <small>Order Date</small>
        <span>{{ offlineOrder.date | date }}</span>
      </div>
    </paper>
    <paper>
      <template
        #title
      >
        Products ({{ offlineOrder.selectedProducts.length }})
      </template>
      <template #actions>
        <ez-button
          type="link"
          formType="button"
          @click="editProducts"
          :data-cy="outletCy.ORDERS.EXISTING_OFFLINE_ORDER.OVERVIEW.BUTTON__EDIT_PRODUCTS"
        >
          <font-awesome-icon icon="pen" />
          <span>Edit</span>
        </ez-button>
      </template>
      <div class="products-table">
        <ez-table
          :data="offlineOrder.selectedProducts"
          :headers="false"
          :columns="['name', 'quantity', 'amount']"
          :columnProps="{
            quantity: { class: 'quantity-cell' },
            amount: { class: 'price-cell no-padding-cell' },
          }"
        >
          <template #cell-name="{ row }">
            <v-product-entity-info :product="row" />
          </template>
          <template #cell-quantity="{ row }">
            {{ isFractional(row.orderingUnit) ? row.quantity.toFixed(2) : row.quantity }}
          </template>
          <template #cell-amount="{ row, row: { price, quantity, currency, totalPrice, totalToChange } }">
            <v-price
              :price="totalToChange ? totalPrice : (price * quantity)"
              :currency="currency"
              :show-market-price-info="false"
              :is-to-be-determined="isToBeDetermined(row) && !totalToChange"
            />
          </template>
        </ez-table>
      </div>
      <div class="total">
        <strong class="total__label">
          Total (incl. taxes)
          <v-subtotal-info
            class="ml-4"
            :item-list="itemList"
            :delivery-fee="deliveryFee"
            :tax="tax"
            :show-total="false"
            :is-tbd="isSomeTbd"
          />
        </strong>
        <div class="total__amount">
          <v-price
            :price="total"
            :currency="currencySymbol"
            :show-market-price-info="false"
            :is-to-be-determined="isSomeTbd"
          />
        </div>
      </div>
    </paper>
    <paper>
      <template #title>Additional Details</template>
      <v-additional-delivery-fee
        :distributor="distributor"
        :data="{
          deliveryFee,
          amountAdjustment,
          taxAdjustment,
        }"
        @changeDeliveryFee="editDeliveryFee"
        @changeAmountAdjustment="editAmountAdjustment"
        @changeTaxAdjustment="editTaxAdjustment"
        :data-cy="outletCy.ORDERS.EXISTING_OFFLINE_ORDER.OVERVIEW.BUTTON__DELIVERY_FEE"
      />
    </paper>
    <paper v-if="!$permission.isFree">
      <template #title>Invoice</template>
      <ez-multiple-image-upload
        name="invoice"
        form-key="upload-invoice"
        card-type
        ref="imageUpload"
        accept="image/jpeg,image/png,application/pdf"
        class="invoice-upload"
        :number-of-files="5"
        :value="offlineOrder.receipts"
        @change="imageUploadChange"
        :data-cy="outletCy.ORDERS.EXISTING_OFFLINE_ORDER.OVERVIEW.BUTTON__UPLOAD_INVOICE"
      >
        <template #label></template>
        <template #cta>Upload Invoice</template>
      </ez-multiple-image-upload>
    </paper>
    <ez-loader :show="loading">Loading...</ez-loader>
  </div>
</template>

<style lang="scss" scoped>
.distributor :deep() .entity-info {
  flex-direction: column;
  align-items: center;
  &__text {
    margin: 0.75rem 0 0;
    justify-content: center;
  }
}

.products-table {
  margin-top: 20px;
  :deep() tbody {
    .quantity-cell {
      width: 100px;
    }
    tr td {
      border-bottom: 0;
    }
  }
}

.total {
  @extend %flex-center;
  justify-content: space-between;
  margin-top: 16px;
  padding-top: 16px;
  text-align: right;
  border-top: 1px solid $color-gray-E9;

  &__label {
    display: inline-flex;
    align-items: center;
    margin-right: 12px;
    @include font-size(12px);
    color: $color-gray-6C;
    text-transform: uppercase;
  }

  &__amount {
    @include font-size(18px);
    font-weight: bold;
    color: $color-gray-25;
  }
}

.width-10 {
  width: 10%;
}

.width-30 {
  width: 30%;
}

.width-60 {
  width: 60%;
}

.height-22 {
  height: 22px;
}

.invoice-upload {
  :deep() .preview__images {
    flex-direction: column;
    .preview__image + .preview__image {
      margin-left: 0;
    }
  }
}
</style>
