<script>
import EzInput from '@/components/ui/Input/EzInput';
import EzButton from '@/components/ui/Button';
import EzForm from '@/components/ui/Form/EzForm';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  components: {
    EzForm,
    EzInput,
    EzButton,
  },
  props: {
    additionalData: {
      type: Object,
      required: false,
      default: () => {},
    },
    action: {
      type: String,
      required: true,
    },
    additionalHeaders: {
      type: Object,
      required: false,
      default: null,
    },
    method: {
      type: String,
      default: 'post',
    },
    formKey: {
      type: String,
      required: true,
    },
    loadingKey: {
      required: false,
      type: String,
    },
    hacCancel: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      required: false,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: true,
    },
    mandatoryForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      formValid: false,
      inputValues: {
        name: '',
        email: '',
      },
      requiredValues: {
        name: null,
        email: null,
      },
    };
  },
  computed: {
    isFormDirty() {
      return this.inputValues.name !== '' || this.inputValues.email !== '';
    },
  },
  watch: {
    formValid() {
      if (this.formValid) this.$emit('formValid', true);
      else this.$emit('formValid', false);
    },
    isFormDirty(val) {
      this.$emit('dirty', val);
    },
  },
  methods: {
    async updateFormState(name, ev) {
      this.inputValues[name] = ev;
      this.requiredValues[name] = await this.$validator.validate(name, ev);
      this.validateForm();
    },
    validateForm() {
      this.formValid = true;
      Object.keys(this.requiredValues).forEach((key) => {
        if (!this.requiredValues[key]) this.formValid = false;
      });
    },
    initialFormState() {
      this.requiredValues = {
        name: this.data.name,
        email: this.data.email,
      };
    },
    closeAddSection() {
      this.$emit('cancel');
    },
    onUserAdded(data) {
      this.$emit('complete', data);
    },
  },
  mounted() {
    if (this.data) {
      this.initialFormState();
      this.validateForm();
    }
  },
};
</script>
<template>
  <div class="create-new-user">
    <ez-form
      class="ordering-fields"
      ref="userForm"
      :formKey="formKey"
      :loading-key="loadingKey"
      :additional-data="additionalData"
      :action="action"
      :method="method"
      :additionalHeaders="additionalHeaders"
      @success="onUserAdded">
      <ez-input
        :formKey="formKey"
        name="name"
        placeholder="Enter User Name"
        :validators="isRequired ? 'required' : ''"
        @onChange="updateFormState('name', $event)"
        :value="data ? data.name : ''"
        :disabled="disabled"
        :label="`Name${mandatoryForm ? '*' : ''}`" />
      <ez-input
        class="mt-12"
        :formKey="formKey"
        @onChange="updateFormState('email', $event)"
        :label="`Email Address${mandatoryForm ? '*' : ''}`"
        :validators="`${isRequired ? 'required' : ''}|email`"
        placeholder="Enter Email Address"
        :value="data ? data.email : ''"
        :disabled="disabled"
        name="email" />
      <ez-input
        class="mt-12"
        :formKey="formKey"
        :value="data ? data.phone : ''"
        placeholder="Enter Phone Number"
        name="phone"
        :disabled="disabled"
        label="Phone Number" />
    </ez-form>
    <ez-button
      v-if="hacCancel"
      class="mt-12"
      type="secondary"
      formType="button"
      isFullWidth
      @click="closeAddSection">
      Cancel
    </ez-button>
  </div>
</template>
<style lang="scss" scoped>
  .create-new-user {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px dashed #DEE1E4;
    width: 100%;
  }
</style>
