<template>
  <div class="product-list" ref="productList">
    <div
      class="popover"
      :style="`top: ${tagPopOver.top}px; left: ${tagPopOver.left}px`"
      v-if="tagHover"
    >
      <v-tag
        v-for="tag in tagPopOver.tags"
        :key="tag.id"
        :color="tag.color.colors[0]"
        :backgroundColor="tag.color.colors[1]"
      >
        {{ tag.name }}
      </v-tag>
    </div>
    <slot name="filters"></slot>
    <div class="product-list__alert">
      <slot name="alert" />
    </div>
    <ez-table
      v-if="productsCopy.length"
      class="products-table"
      :headers="tableHeaders"
      :columns="tableColumns"
      :data="productsCopy"
      :columnProps="tableColumnProps"
      :loading="isLoading"
      @rowClick="handleRowClick"
    >
      <template #cell-name="{ row }">
        <ez-entity-info imgWidth="2rem" imgHeight="2rem" :imgUrl="row.image">
          <div :class="['product-info', { 'responsive-info': responsiveInfo }]" :title="row.name">
            <span :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_NAME}-${row.id}`">
              {{ row.name }}
            </span>
            <span class="product-info-secondary">
              <span
                class="product-info-secondary--category"
                :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_CATEGORY}-${row.id}`"
              >
                {{ row.category | categoryWithParent }}
              </span>
              <span
                v-if="row.sku"
                class="product-info-secondary--sku"
                :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_SKU}-${row.id}`"
              >
                &#8226; {{ row.sku }}
              </span>
            </span>
          </div>
        </ez-entity-info>
      </template>
      <template #cell-expiresSoon="{ row, row: { inventoryTracked, expiresSoon } }">
        <font-awesome-icon
          v-if="inventoryTracked && expiresSoon"
          v-tooltip="{
            placement: 'top-start',
            content: `This product has a batch that's about to expire soon`,
            classes: ['tooltip--reset-margin u-text-center mb-8'],
          }"
          icon="calendar-alt"
          :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_EXPIRES_SOON}-${row.id}`"
        />
      </template>
      <template #cell-parLevelReached="{ row, row: { inventoryTracked, parLevelReached } }">
        <font-awesome-icon
          v-if="inventoryTracked && parLevelReached"
          v-tooltip="{
            placement: 'top-start',
            content: 'This product has reached its par level',
            classes: ['tooltip--reset-margin u-text-center mb-8'],
          }"
          icon="exclamation-circle"
          :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_PAR_LEVEL_REACHED}-${row.id}`"
        />
      </template>
      <template #cell-warehouses="{ row: { id, warehouses, locations, inventoryTracked } }">
        <font-awesome-icon
          v-if="!inventoryTracked"
          flip="horizontal"
          :icon="['far', 'eye-slash']"
          v-tooltip="{
            placement: 'top-start',
            content: 'Not Tracking',
            classes: ['tooltip--reset-margin u-text-center mb-2'],
          }"
        />
        <v-warehouse-location
          v-else
          :data="warehouseId ? locations : warehouses"
          :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_WAREHOUSE}-${id}`"
        />
      </template>
      <template #cell-tags="{ row }">
        <div
          class="tags"
          v-if="row.tags.length"
          @mouseenter="tagsShowHover(row.id, row.tags)"
          @mouseleave="tagsRemoveHover"
          :ref="`tag-${row.id}`"
        >
          <v-tag
            class="mr-8"
            :color="row.tags[0].color.colors[0]"
            :backgroundColor="row.tags[0].color.colors[1]"
            >{{ row.tags[0].name }}</v-tag
          >
          <span v-if="row.tags.length > 1">+{{ row.tags.length - 1 }}</span>
        </div>
        <span v-else>-</span>
      </template>
      <template #cell-parLevel="{ row: { id, parLevel, inventoryTracked } }">
        <font-awesome-icon
          v-if="!inventoryTracked"
          flip="horizontal"
          :icon="['far', 'eye-slash']"
          v-tooltip="{
            placement: 'top-start',
            content: 'Not Tracking',
          }"
        />
        <span v-else :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_PAR_LEVEL}-${id}`">
          {{ parLevel || '-' }}
        </span>
      </template>
      <template #cell-stock="{ row }">
        <template v-if="warehouseId || (inventoryDate && !isInventoryDateToday)">
          <font-awesome-icon
            v-if="!row.inventoryTracked"
            flip="horizontal"
            :icon="['far', 'eye-slash']"
            v-tooltip="{
              placement: 'top-start',
              content: 'Not Tracking',
            }"
          />
          <font-awesome-icon
            v-else-if="!row.available"
            v-tooltip="{
              placement: 'top',
              content: 'Out of stock',
              classes: ['tooltip', 'tooltip--lift-up', 'tooltip--reset-margin'],
            }"
            icon="minus-circle"
            class="text-red"
          />
          <span v-else :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_STOCK}-${row.id}`">
            {{ row.inventory }}
          </span>
        </template>
        <template v-else>
          <font-awesome-icon
            v-if="!row.inventoryTracked"
            flip="horizontal"
            :icon="['far', 'eye-slash']"
            v-tooltip="{
              placement: 'top-start',
              content: 'Not Tracking',
              classes: ['tooltip--reset-margin u-text-center mb-2'],
            }"
          />
          <font-awesome-icon
            v-else-if="!row.available"
            v-tooltip="{
              placement: 'top',
              content: 'Out of stock',
              classes: ['tooltip', 'tooltip--lift-up', 'tooltip--reset-margin'],
            }"
            icon="minus-circle"
            class="text-red"
          />
          <v-product-quantity-inventory
            v-else
            :product="row"
            :canBeInvalid="false"
            warehouseProperty="warehouseInventory"
            isDistributor
            showTotalAsLabel
            inventoryTracking
          />
        </template>
      </template>
      <template #cell-category="{ row, row: { category } }">
        <span
          class="category-badge"
          :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_CATEGORY}-${row.id}`"
        >
          {{ category | categoryWithParent }}
        </span>
      </template>
      <template #cell-distributor="{ row, row: { distributor } }">
        <ez-entity-info
          v-if="distributor"
          imgWidth="2.125rem"
          imgHeight="2.125rem"
          :imgUrl="distributor.logo"
          imgBorderRadius="50%"
        >
          <div
            class="distributor-info"
            :title="distributor.name"
            :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_SUPPLIER}-${row.id}`"
          >
            {{ distributor.name }}
          </div>
        </ez-entity-info>
      </template>
      <template #cell-importType="{ row, row: { importType } }">
        <span :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_IMPORT_TYPE}-${row.id}`">
          {{ importType === 'trade_gecko' ? $t('quickBooks.nameShort') : importType | capitalize }}
        </span>
      </template>
      <template #cell-quantity="{ row }">
        <font-awesome-icon
          v-if="!row.inventoryTracked"
          class="fa-regular fa-flip-horizontal"
          flip="horizontal"
          :icon="['far', 'eye-slash']"
          v-tooltip="{
            placement: 'top-start',
            content: 'Not Tracking',
          }"
        />
        <font-awesome-icon
          v-else-if="!row.available"
          v-tooltip="{
            placement: 'top',
            content: 'Out of stock',
            classes: ['tooltip', 'tooltip--lift-up', 'tooltip--reset-margin'],
          }"
          icon="minus-circle"
          class="text-red"
        />
        <span v-else :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_STOCK}-${row.id}`">{{
          row.inventory
        }}</span>
      </template>
      <template #cell-unit="{ row: { id, orderingUnit } }">
        <template v-if="orderingUnit">
          <v-data-with-info
            :info="
              orderingUnit.abbreviation
                ? `${orderingUnit.name} ${!!$t(`product.unitInfo.${orderingUnit.type}`) ? '-' : ''}
            ${$t(`product.unitInfo.${orderingUnit.type}`)}`
                : ''
            "
            :show-underline="
              !!$t(`product.unitInfo.${orderingUnit.type}`) || !!orderingUnit.abbreviation
            "
            :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_ORDERING_UNIT}-${id}`"
          >
            {{ orderingUnit.label }}
          </v-data-with-info>
        </template>
      </template>
      <template #cell-cogs="{ row: { id, cogs, inventoryTracked } }">
        <font-awesome-icon
          v-if="trackingInventory && !inventoryTracked"
          flip="horizontal"
          :icon="['far', 'eye-slash']"
          v-tooltip="{
            placement: 'top-start',
            content: 'Not Tracking',
          }"
        />
        <v-price
          v-else
          :price="cogs || 0"
          :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_COGS}-${id}`"
        />
      </template>
      <template #cell-price="{ row: { id, price, priceUnit, marketPrice } }">
        <v-price
          :price="price || 0"
          :unit="priceUnit && priceUnit.label"
          :is-market-price="marketPrice"
          :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_PRICE}-${id}`"
        />
      </template>
      <template #cell-availability="{ row, row: { customerInventory } }">
        <font-awesome-icon
          v-if="customerInventory?.outOfStock"
          v-tooltip="{
            placement: 'top',
            content: 'Out of stock',
            classes: ['tooltip', 'tooltip--lift-up'],
          }"
          icon="minus-circle"
          class="text-red mx-4"
          :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_OUT_OF_STOCK}-${row.id}`"
        />
        <font-awesome-icon
          v-if="customerInventory?.lowInventory"
          v-tooltip="{
            placement: 'top',
            content: 'Low inventory',
            classes: ['tooltip', 'tooltip--lift-up'],
          }"
          icon="exclamation-circle"
          class="text-carrot mx-4"
          :data-cy="`${commonCy.PRODUCTS.TEXT__PRODUCT_LOW_INVENTORY}-${row.id}`"
        />
        {{ customerInventory?.inventoryLevel }}
      </template>
      <template #cell-addToCart="{ row }" v-if="hasQuickAdd && $permission.has('createOrder')">
        <div class="quantity">
          <ez-mask-input-simple
            name="quantity"
            formKey=""
            :minValue="row.minimumQuantity ?? 0"
            :maxValue="productQuantityMaxValue(row)"
            :value="row.quantity"
            :disabled="productQuantityDisabled(row)"
            @input="onQuantityChange($event, row.id)"
            :precision="isFractional(row.orderingUnit) ? 2 : 0"
            @invalid="onQuantityInvalid($event, row.id)"
            :data-cy="`${commonCy.PRODUCTS.INPUT__PRODUCT_QUANTITY}-${row.id}`"
            :has-currency="false"
          />
          <div class="quantity__icon">
            <div
              v-tooltip.bottom="{
                content: productCartTooltipContent(row),
                classes: ['tooltip-general'],
              }"
            >
              <ez-button
                :class="['add-to-cart-button', { 'add-to-cart-button--active': isInCart(row) }]"
                type="link"
                :data-cy="`${commonCy.PRODUCTS.BUTTON__PRODUCT_ADD_TO_CART}-${row.id}`"
                @click="quickAddToCart(row)"
                :disabled="addToCartDisabled(row)"
              >
                <font-awesome-icon icon="cart-plus" />
              </ez-button>
            </div>
          </div>
        </div>
      </template>
    </ez-table>
    <slot></slot>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { LOADING_KEY, UNIT_TYPE_FRACTIONAL } from '@/util/constants';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';

import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzButton from '@/components/ui/Button';
import EzTable from '@/components/ui/Table';
import flash from '@/components/ui/FlashMessage';
import dayjs from 'dayjs';
import uuid from 'uuid/v4';

import VWarehouseLocation from '@/components/v3/elements/VWarehouseLocation';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import VPrice from '@/components/v3/elements/VPrice';

import VTag from '@/components/v3/elements/VTag';
import VProductQuantityInventory from '@/components/v3/elements/VProductQuantityInventory';

export default {
  components: {
    VDataWithInfo,
    VPrice,
    EzEntityInfo,
    EzButton,
    EzTable,
    VWarehouseLocation,
    VTag,
    VProductQuantityInventory,
    EzMaskInputSimple,
  },
  props: {
    headers: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    columnProps: {
      type: Object,
      default: null,
    },
    products: {
      type: Array,
      required: true,
    },
    hasQuickAdd: {
      type: Boolean,
      required: false,
      default: false,
    },
    responsiveInfo: {
      type: Boolean,
      required: false,
      default: true,
    },
    warehouseId: {
      type: Number,
      required: false,
      default: null,
    },
    inventoryDate: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      commonCy,
      productsCopy: [],
      tagHover: false,
      tagPopOver: {
        tags: [],
        top: null,
        left: null,
      },
    };
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
    ...mapState('cart', ['cart']),
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.FETCH_PRODUCT) || this.getLoading(LOADING_KEY.ADD_TO_CART);
    },
    me() {
      return this.loggedUser.distributor;
    },
    tableColumns() {
      return this.hasQuickAdd ? [...this.columns, 'addToCart'] : this.columns;
    },
    tableHeaders() {
      return this.hasQuickAdd
        ? {
            ...this.headers,
            addToCart: () => 'Quantity',
          }
        : this.headers;
    },
    tableColumnProps() {
      return this.hasQuickAdd
        ? {
            ...this.columnProps,
            addToCart: {
              class: 'add-to-cart-cell',
              on: {
                click: event => {
                  event.stopPropagation();
                },
              },
            },
            name: {
              class: 'product-cell',
            },
          }
        : this.columnProps;
    },
    trackingInventory() {
      return this.me.inventorySettings.tracking;
    },
    isInventoryDateToday() {
      const today = dayjs().endOf();
      if (dayjs(this.inventoryDate).isSame(today, 'day')) return true;
      return false;
    },
  },
  methods: {
    ...mapActions('cart', ['pushToCart']),
    handleRowClick(row) {
      this.$emit('rowClick', row);
    },
    onQuantityChange(quantity, id) {
      const pr = this.productsCopy.find(pro => pro.id === id);
      if (pr.quantity !== quantity) {
        pr.quantity = quantity;
      }
    },
    onQuantityInvalid(quantityInvalid, id) {
      const pr = this.productsCopy.find(pro => pro.id === id);
      pr.quantityInvalid = quantityInvalid;
      this.$forceUpdate();
    },
    addToCartDisabled(row) {
      return (
        row.quantityInvalid ||
        this.minimumQuantityUnavailable(row) ||
        row.customerInventory.outOfStock ||
        !(row.price >= 0)
      );
    },
    async quickAddToCart(product) {
      await this.$nextTick();

      const { minimumQuantity, quantity: selectedQuantity } = product;

      const quantity =
        selectedQuantity && selectedQuantity < minimumQuantity ? minimumQuantity : selectedQuantity;
      await this.pushToCart({ product: { ...product, quantity, internalId: uuid() } });

      const { href } = this.$router.resolve({ name: 'venue-shopping-cart' });

      flash.success({
        title: 'Product successfully added to cart!',
        message: 'Go to cart to check all items you added and complete the order.',
        ctas: `<a href="${href}">Go to Cart</a>`,
      });
    },
    isFractional(orderingUnit) {
      return orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    isInCart(row) {
      let isIn = false;
      Object.entries(this.cart).forEach(item => {
        if (item[1]) {
          Object.entries(item[1]).forEach(dist => {
            if (dist[1]?.product && dist[1]?.product?.id === row.id) isIn = true;
          });
        }
      });
      return isIn;
    },
    productQuantityMaxValue(row) {
      const {
        customerInventory: { orderMoreThanAvailable, inventoryLevel },
      } = row;
      return !orderMoreThanAvailable && inventoryLevel !== null
        ? inventoryLevel
        : Number.MAX_SAFE_INTEGER;
    },
    productQuantityDisabled(row) {
      return row.customerInventory.outOfStock || this.minimumQuantityUnavailable(row);
    },
    productCartTooltipContent(row) {
      if (row.customerInventory.outOfStock) {
        return "Product is out of stock and can't be added to the cart.\n";
      }
      if (row.quantityInvalid) return 'Quantity is not valid';
      if (this.minimumQuantityUnavailable(row)) {
        return 'Product can’t be added to the cart because the available quantity is lower than the minimum order quantity.';
      }
      if (!row.marketPrice ? row.price >= 0 : true) return 'Add to Cart';
      return 'Invalid price';
    },
    minimumQuantityUnavailable(row) {
      const {
        minimumQuantity,
        customerInventory: { orderMoreThanAvailable, outOfStock, inventoryLevel },
      } = row;
      return !orderMoreThanAvailable && !outOfStock && minimumQuantity > inventoryLevel;
    },
    tagsShowHover(id, tags) {
      this.tagHover = true;
      this.tagPopOver.tags = tags;
      const { top, left, bottom } = this.$refs[`tag-${id}`].getBoundingClientRect();
      const topParent = this.$refs.productList.getBoundingClientRect().top;
      const leftParent = this.$refs.productList.getBoundingClientRect().left;
      // Adding the height of the element
      this.tagPopOver.top = top - topParent + (bottom - top);
      this.tagPopOver.left = left - leftParent;
    },
    tagsRemoveHover() {
      this.tagHover = false;
      this.tagPopOver = {
        tags: [],
        top: null,
        left: null,
      };
    },
  },
  watch: {
    products(newVal) {
      this.productsCopy = newVal;
    },
  },
};
</script>

<style scoped lang="scss">
.product-list {
  position: relative;
}

:deep() .products-table {
  tbody {
    .stock-cell {
      overflow: visible;
    }
  }
}

:deep() .table {
  .sku-cell,
  .quantity-cell {
    width: 10rem;
  }
  .category-badge {
    @extend %category-badge;
    max-width: 100%;
  }

  .category-cell {
    width: 16rem;
  }

  .product-cell {
    width: 470px;
  }

  .distributor-cell {
    width: 14rem;

    .distributor-info {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.popover {
  position: absolute;
  background-color: white;
  margin-top: 10px;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  padding: 16px;
  max-width: 360px;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  overflow: visible;
}

.quantity {
  @extend %flex-center;
  justify-content: flex-end;

  &__icon {
    padding-left: px-to-rem(24px);
    color: rgba($color-gray-6C, 0.4);
    @include font-size(20px);
  }

  .ez-number-input {
    height: auto;
    width: 90px;
  }

  .add-to-cart-button {
    padding: unset;
    height: auto;
    color: inherit;
    font-size: inherit;

    &--active {
      color: $color-primary-blue;
    }

    &:not(:disabled):hover {
      svg {
        color: #000;
      }
    }

    &:disabled {
      background-color: inherit;
      color: rgba($color-gray-6C, 0.4);
    }
  }
}

.table thead th.add-to-cart-cell {
  padding-right: 46px;
}

.add-to-cart-cell {
  width: 160px;
  :deep() .mask-input-wrapper .mask-input .mask-input__input {
    padding-left: 2px;
  }
}

:deep() .table tbody tr td:last-child {
  overflow: visible;
}

.category-badge {
  @extend %category-badge;
}

.product-info {
  display: flex;
  flex-direction: column;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .product-info-secondary {
    &--category {
      color: $color-gray-25;
      font-weight: 600;
    }
  }
}

.product-info.responsive-info {
  .product-info-secondary {
    display: none;
  }

  @media (max-width: 1420px) {
    .product-info-secondary {
      display: block;
    }

    :deep() .table {
      .sku-cell,
      .category-cell {
        display: none;
      }
    }
  }

  @media (max-width: 1023px) {
    .product-info-secondary {
      display: none;
    }
  }
}

:deep() .tags {
  display: flex;

  .v-tag {
    min-width: 0;
    flex-shrink: 1;
    overflow: hidden;
  }
}

:deep() .mask-input.mark-input--inline {
  width: 100%;
  .mask-input__input {
    font-weight: normal;
    color: $color-gray-25;
    text-align: center;
  }
}
</style>
