<script>
/**
 * NewProductForm
 * @version 1.0.0
 * @since 2.3.0
 */

import { mapGetters, mapState, mapActions } from 'vuex';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import EzForm from '@/components/ui/Form';
import EzImageUpload from '@/components/ui/ImageUpload';
import EzInput from '@/components/ui/Input';
import EzTextarea from '@/components/ui/Textarea/';
import EzCategoryFilter from '@/components/ui/Filter/Category.vue';
import { getCurrency, getContextId } from '@/util/utils';
import { LOADING_KEY } from '@/util/constants';
import VUnitsSettings from '@/components/v3/patterns/VUnitsSettings';
import VProductGroupSettings from '@/components/v3/elements/VProductGroupSettings/index.vue';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzButton from '@/components/ui/Button/EzButton';
import EzMaskInput from '@/components/ui/MaskInput/EzMaskInput';
import flash from '@/components/ui/FlashMessage';
import { EzConfirmationModal, EzFormModal } from '@/components/ui/Modal';
import VSelectSearchLocation from '@/components/v3/elements/VSelectSearchLocation';
import EzSelect from '@/components/ui/Select/EzSelect.vue';

export default {
  components: {
    EzCategoryFilter,
    EzTextarea,
    EzInput,
    EzImageUpload,
    EzForm,
    VUnitsSettings,
    VProductGroupSettings,
    EzCheckbox,
    EzButton,
    EzMaskInput,
    EzConfirmationModal,
    EzFormModal,
    VSelectSearchLocation,
    EzSelect,
  },
  props: {
    formKey: {
      type: String,
      required: true,
    },
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
    },
    distributorId: {
      type: Number,
      required: false,
      default: -1,
    },
    product: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    categories: {
      required: false,
      default: null,
    },
    units: {
      type: Array,
      required: true,
      default: () => [],
    },
    updateProductGroups: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      formValid: true,
      priceUnitsDisabled: false,
      requiredValues: {
        name: null,
      },
      loadingKey: LOADING_KEY.ADD_OR_UPDATE_PRODUCT,
      priceUnits: null,
      outletCy,
      tax: null,
      parLevel: null,
      venueInventoryTracked: null,
      isTaxDefault: false,
      inventoryLocations: [],
      isEdit: false,
      imgModified: false,
      locationName: '',
      locationId: null,
      locationIds: [],
      trackingData: [
        { id: null, name: 'Unassigned' },
        {
          id: 'true',
          name: 'Track',
        },
        { id: 'false', name: 'Do Not Track' },
      ],
    };
  },
  computed: {
    ...mapState('entities/users', ['contextId', 'loggedUser']),
    ...mapGetters('defaultImages', ['getDefaultImage']),
    ...mapGetters('entities/users', [
      'isVenue',
      'isDistributor',
      'isVenueXeroConnected',
      'isVenueQuickBooksConnected',
      'isDistributorXeroConnected',
    ]),
    venueId() {
      return getContextId();
    },
    hasManageInventoryPermission() {
      const hasInventoryEnabled = this.loggedUser.venues.filter(
        item => item.id === this.venueId,
      )?.[0]?.features?.inventory;
      return this.$permission.has('manageInventory') && hasInventoryEnabled;
    },
    defaultImage() {
      return this.getDefaultImage('category');
    },
    hasProduct() {
      return Object.keys(this.product).length;
    },
    currencySymbol() {
      const { symbol } = getCurrency() || {};
      return symbol;
    },
    isValid() {
      return this.formValid && !this.priceUnitsDisabled;
    },
    unitsDataCy() {
      const { NEW_PRODUCT } = outletCy.ORDERS.PENDING_UPDATES.NEW_PENDING_UPDATE.PRODUCTS;

      return {
        cogs: NEW_PRODUCT.INPUT__COGS,
        price: NEW_PRODUCT.INPUT__PRICE,
        minimumQuantity: NEW_PRODUCT.INPUT__MINIMUM_QUANTITY,
        orderingQuantityIncrement: NEW_PRODUCT.INPUT__QUANTITY_INCREMENT,
        orderingUnitId: NEW_PRODUCT.BUTTON__SALES_UNIT,
        priceUnitId: NEW_PRODUCT.BUTTON__PRICE_UNIT,
        marketPrice: NEW_PRODUCT.LABEL__MARKET_PRICE,
        priceUnit: NEW_PRODUCT.LABEL__PRICE_UNIT,
      };
    },
    isTaxDisabled() {
      return (
        (this.isVenue &&
          (this.isVenueXeroConnected(this.contextId) ||
            this.isVenueQuickBooksConnected(this.contextId))) ||
        (this.isDistributor && this.isDistributorXeroConnected)
      );
    },
    locations() {
      const locations = {};
      this.locationIds.forEach((item, i) => {
        locations[`locationIds[${i}]`] = item;
      });
      return locations;
    },
  },
  watch: {
    isValid: {
      immediate: true,
      handler() {
        this.$emit('formValid', this.isValid);
      },
    },
    async product() {
      this.priceUnits = {};
      await this.initialFormState();
      this.validateForm();
      this.tax = this.product.tax;
      this.parLevel = this.product.parLevel;
      this.venueInventoryTracked = this.product.venueInventoryTracked?.toString() || null;
      this.isTaxDefault = this.product.isTaxDefault;
    },
  },
  methods: {
    ...mapActions('entities/venues', [
      'fetchVenueLocations',
      'addVenueLocation',
      'updateVenueLocation',
      'deleteVenueLocation',
    ]),
    submit() {
      this.$refs.form.onSubmit();
    },
    reset() {
      this.$refs.form.reset(['ezinput', 'eztextarea', 'ezimageupload', 'ezcategoryfilter']);
      this.$refs.productGroup.reset();
      this.isTaxDefault = false;
      this.parLevel = null;
      this.venueInventoryTracked = null;
    },
    clear() {
      this.$refs.form.clearErrors(['ezinput', 'eztextarea']);
    },
    onSubmitSuccess(response) {
      this.$emit('submitSuccess', response);
    },
    onSubmitError(response) {
      this.$emit('submitError', response);
    },
    onRemoveImage() {
      this.product.image = null;
      this.product.isDefaultImage = true;
      this.imgModified = true;
    },
    updateFormState(name, value) {
      this.requiredValues[name] = value;
      this.validateForm();
    },
    async initialFormState() {
      await this.$nextTick();
      this.requiredValues = {
        name: this.product.name,
      };
    },
    validateForm() {
      this.formValid = true;
      Object.keys(this.requiredValues).forEach(key => {
        if (!this.requiredValues[key]) this.formValid = false;
      });
    },
    unitsUpdated(data) {
      this.priceUnits = data;
    },
    resetPriceAndUnits() {
      this.$refs.unitsSettings.reset();
    },
    onDefaultTaxRateChange(val) {
      this.isTaxDefault = val;
    },
    goToAccountingIntegration() {
      let routeName;
      let routerData;
      if (this.isVenue) {
        if (this.isVenueXeroConnected(this.contextId)) {
          routeName = 'venue-xero-products';
        } else if (this.isVenueQuickBooksConnected(this.contextId)) {
          routeName = 'venue-quick-books-products';
        }
        routerData = this.$router.resolve({
          name: routeName,
          params: {
            id: this.contextId,
          },
        });
      } else if (this.isDistributor && this.isDistributorXeroConnected) {
        routerData = this.$router.resolve({
          name: 'distributor-xero-products',
        });
      }
      window.open(routerData?.href, '_blank');
    },
    async onSelectProductLocation(event) {
      if (!this.product.venueProductLocations) this.product.venueProductLocations = [];
      this.locationIds = [];
      this.product.venueProductLocations.forEach(location => {
        this.locationIds.push(location.location.id);
      });
      const idx = this.locationIds.findIndex(item => item === event.id);
      if (idx !== -1) {
        this.locationIds.splice(idx, 1);
        this.product.venueProductLocations = this.product.venueProductLocations?.filter(
          loc => loc.location.id !== event.id,
        );
      } else {
        this.locationIds.push(event.id);
        this.product.venueProductLocations = [
          ...this.product.venueProductLocations,
          { location: event, positions: null },
        ];
      }
    },
    openLocationModal() {
      this.$refs.modal.open();
    },
    onClose() {
      this.isEdit = false;
      this.locationName = '';
      this.$refs.modal.close();
    },
    async addOrEditLocation() {
      try {
        if (!this.isEdit) {
          const { data } = await this.addVenueLocation({
            venueId: this.venueId,
            data: { name: this.locationName },
          });
          this.inventoryLocations.push(data.data);
          flash.success({ title: 'Location successfully added!' });
        } else {
          const { data } = await this.updateVenueLocation({
            venueId: this.venueId,
            locationId: this.locationId,
            data: { name: this.locationName },
          });
          const locIndex = this.inventoryLocations.findIndex(item => item.id === this.locationId);
          this.inventoryLocations[locIndex] = data.data;
          flash.success({ title: 'Location successfully updated!' });
        }
        this.onClose();
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
    onEdit(row) {
      this.isEdit = true;
      this.locationName = row.name;
      this.locationId = row.id;
      this.$refs.modal.open();
    },
    onRemove(row) {
      this.locationId = row.id;
      this.$refs.deleteModal.open();
    },
    closeDeleteModal() {
      this.$refs.deleteModal.close();
      this.locationId = null;
    },
    async confirmDeleteLocation() {
      try {
        await this.deleteVenueLocation({
          venueId: this.venueId,
          locationId: this.locationId,
        });
        this.inventoryLocations = this.inventoryLocations.filter(
          item => item.id !== this.locationId,
        );
        if (
          this.product.venueProductLocations?.findIndex(
            loc => loc.location.id === this.locationId,
          ) !== -1
        ) {
          this.product.venueProductLocations = this.product.venueProductLocations?.filter(
            loc => loc.location.id !== this.locationId,
          );
        }
        this.closeDeleteModal();
        flash.success({
          title: 'Location successfully deleted!',
        });
      } catch (e) {
        flash.error({
          title: 'Something went wrong!',
          message: e.response?.data?.error?.message || '',
        });
      }
    },
    onTrackingChange(ev) {
      this.venueInventoryTracked = ev.id;
    },
  },
  async created() {
    if (this.hasProduct) {
      await this.initialFormState();
      this.validateForm();
      this.tax = this.product.tax;
      this.isTaxDefault = this.product.isTaxDefault;
    }
    if (this.isVenue && this.hasManageInventoryPermission) {
      const {
        data: { data },
      } = await this.fetchVenueLocations({ venueId: this.venueId });
      this.inventoryLocations = data;
    }
  },
};
</script>

<template>
  <div>
    <ez-form
      :action="action"
      :form-key="formKey"
      :method="method"
      :additional-headers="{ loadingKey }"
      :additional-data="{
        ...(priceUnits && { ...priceUnits }),
        ...(tax && !isTaxDefault ? { tax } : { tax: null }),
        ...locations,
        parLevel,
        ...(venueInventoryTracked !== null
          ? { venueInventoryTracked }
          : { venueInventoryTracked: null }),
        ...(imgModified && { image: '' }),
      }"
      submit-type="multipart"
      @success="onSubmitSuccess"
      @error="onSubmitError"
      ref="form"
    >
      <ez-image-upload
        :form-key="formKey"
        :preview-url="product.image || defaultImage"
        :is-default-image="product.isDefaultImage"
        column-mode
        name="image"
        @change="imgModified = true"
        @removeImage="onRemoveImage"
        :add-button-data-cy="
          outletCy.ORDERS.PENDING_UPDATES.NEW_PENDING_UPDATE.PRODUCTS.NEW_PRODUCT.BUTTON__ADD_IMAGE
        "
        :remove-button-data-cy="
          outletCy.ORDERS.PENDING_UPDATES.NEW_PENDING_UPDATE.PRODUCTS.NEW_PRODUCT
            .BUTTON__REMOVE_IMAGE
        "
      >
        <template #addImage>
          <span>Add Product Image</span>
        </template>
      </ez-image-upload>
      <ez-input
        :form-key="formKey"
        name="name"
        label="Product name*"
        :value="product.name"
        @onChange="updateFormState('name', $event)"
        placeholder="Enter product name"
        :data-cy="
          outletCy.ORDERS.PENDING_UPDATES.NEW_PENDING_UPDATE.PRODUCTS.NEW_PRODUCT.INPUT__NAME
        "
      />
      <ez-input
        :form-key="formKey"
        name="altName"
        label="Internal product name"
        :value="product.altName"
        placeholder="Enter internal product name"
      />
      <ez-input
        :form-key="formKey"
        name="sku"
        label="SKU number"
        :value="product.sku"
        placeholder="Enter SKU Number"
        :data-cy="
          outletCy.ORDERS.PENDING_UPDATES.NEW_PENDING_UPDATE.PRODUCTS.NEW_PRODUCT.INPUT__SKU
        "
      />

      <ez-category-filter
        :categories="categories"
        :selected="product.categoryId"
        label="Category"
        :is-parent-disabled="true"
        name="categoryId"
        class="category-filter"
        :data-cy="
          outletCy.ORDERS.PENDING_UPDATES.NEW_PENDING_UPDATE.PRODUCTS.NEW_PRODUCT.BUTTON__CATEGORY
        "
      />
      <v-product-group-settings
        ref="productGroup"
        name="productGroupId"
        :selectedId="product.productGroup?.id"
        :selectOnly="isVenue && !$permission.has('manageProductGroups')"
        :updateProductGroups="updateProductGroups"
      />
      <ez-textarea
        :form-key="formKey"
        name="description"
        label="Description"
        :value="product.description"
        placeholder="Enter description"
        :data-cy="
          outletCy.ORDERS.PENDING_UPDATES.NEW_PENDING_UPDATE.PRODUCTS.NEW_PRODUCT
            .TEXTAREA__DESCRIPTION
        "
      />
    </ez-form>
    <v-units-settings
      ref="unitsSettings"
      @unitsUpdated="unitsUpdated"
      @onUnitChange="ev => $emit('onUnitChange', ev)"
      @successDeleteUnit="ev => $emit('successDeleteUnit', ev)"
      @disabled="val => (priceUnitsDisabled = val)"
      :units="units"
      :form-key="formKey"
      :product="product"
      :allow-negative-price="false"
      :data-cy="unitsDataCy"
    />
    <div class="mb-16">
      <p class="section-title">Tax Rate</p>
    </div>
    <div class="tax-wrapper">
      <ez-input
        class="mb-12"
        :formKey="formKey"
        placeholder="Tax Rate"
        label="Tax Rate"
        type="number"
        name="tax"
        step="any"
        :value="product.tax"
        :disabled="isTaxDisabled || isTaxDefault"
        @onChange="ev => (tax = ev)"
      >
        <template #suffix>%</template>
      </ez-input>
      <ez-button
        class="ml-12 mb-12"
        type="secondary"
        v-if="isTaxDisabled"
        @click="goToAccountingIntegration"
      >
        Settings
      </ez-button>
    </div>
    <ez-checkbox
      v-if="!isTaxDisabled"
      :form-key="formKey"
      name="isTaxDefault"
      label="Default Tax Rate"
      @change="onDefaultTaxRateChange"
      :checked="isTaxDefault"
    />
    <div v-if="isVenue && hasManageInventoryPermission" class="mb-16">
      <p class="section-title">Inventory Tracking</p>
    </div>
    <ez-select
      v-if="isVenue && hasManageInventoryPermission"
      class="input-group"
      name="venueInventoryTracked"
      placehoder="Select Tracking"
      :isFullWidth="true"
      :value="
        venueInventoryTracked !== null ? venueInventoryTracked.toString() : venueInventoryTracked
      "
      :selected="
        venueInventoryTracked !== null ? venueInventoryTracked.toString() : venueInventoryTracked
      "
      @change="onTrackingChange"
      label="Track"
      :options="trackingData"
    />
    <ez-mask-input
      v-if="isVenue && hasManageInventoryPermission"
      ref="parLevel"
      label="Par Level"
      name="parLevel"
      :form-key="formKey"
      :precision="0"
      class="mask-input mt-12"
      :value="product.parLevel || 0"
      type="input"
      :price-prefix="false"
      @input="ev => (parLevel = ev)"
    />
    <v-select-search-location
      v-if="isVenue && hasManageInventoryPermission"
      ref="selectLocation"
      class="mt-4"
      :data="inventoryLocations"
      :selectedData="product.venueProductLocations || []"
      isFullWidth
      name="locations"
      label="Locations"
      placeholder="Select Location"
      searchProperty="name"
      @select="event => onSelectProductLocation(event)"
      @addLocation="openLocationModal()"
      @edit="onEdit"
      @remove="onRemove"
      :align-left="true"
    />

    <ez-form-modal ref="modal" @close="onClose">
      <template #title>{{ isEdit ? 'Edit ' : 'Add ' }}Location</template>
      <template #content>
        <ez-input
          name="locationName"
          label="Name"
          :value="locationName"
          @onChange="locationName = $event"
          placeholder="Enter Location Name"
          formKey="create-update-location"
        />
      </template>
      <template #footer>
        <ez-button type="link" formType="button" @click="onClose">Cancel</ez-button>
        <ez-button @click="addOrEditLocation" :disabled="!locationName" type="primary">{{
          isEdit ? 'Save Changes' : 'Add Location'
        }}</ez-button>
      </template>
    </ez-form-modal>

    <ez-confirmation-modal ref="deleteModal" type="red" class="delete-modal">
      <template #title>Remove Location?</template>
      <template #content>
        <p>This location will be deleted from the list.</p>
      </template>
      <template #footer>
        <ez-button @click="closeDeleteModal" type="link">Cancel</ez-button>
        <ez-button @click="confirmDeleteLocation" type="red">Remove Location</ez-button>
      </template>
    </ez-confirmation-modal>
  </div>
</template>

<style scoped lang="scss">
.ez-form {
  :deep() .ez-select {
    width: 100%;
  }

  .ez-image-upload {
    margin-bottom: $spacing-32;
  }

  .input-group + .input-group {
    margin-top: $spacing-12;
  }

  .ez-dropdown {
    max-width: 100%;
  }
}
.category-filter {
  width: 100%;
}

.tax-wrapper {
  :deep() .input-group {
    flex: 1;
  }
  display: flex;
  align-items: flex-end;
  width: 100%;
}

:deep() .mask-input-wrapper .mask-input .mask-input__input {
  font-weight: normal;
}
:deep() .ez-select__display-container {
  height: 36px;
}
.delete-modal {
  :deep() {
    .modal {
      @include z-index('modal', 20);

      .modal__text {
        padding: 0;

        p {
          margin: 0;
        }
      }

      .modal__footer {
        padding: 0;
      }
    }
  }
}
</style>
