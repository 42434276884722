<script>
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import EzConfirmationModal from '@/components/ui/Modal/EzConfirmationModal.vue';
import EzButton from '@/components/ui/Button/EzButton.vue';

/**
 * RemoveGroupModal
 * @version 1.0.0
 * @since 3.29.0
 */
export default {
  name: 'RemoveGroupModal',
  props: {},
  components: {
    EzConfirmationModal,
    EzButton,
  },
  data() {
    return {
      group: {},
      commonCy,
    };
  },
  methods: {
    open(group) {
      this.group = group;
      this.$refs.modal.open();
    },
    close() {
      this.$refs.modal.close();
    },
    async onRemove() {
      this.$emit('remove', this.group.id);
    },
  },
};
</script>

<template>
  <ez-confirmation-modal class="remove-group-modal" ref="modal" type="red">
    <template #title>Remove {{ group.name }} Product Group?</template>
    <template #content>
      <p>You are about to remove {{ group.name }} product group from your list.</p>
    </template>
    <template #footer>
      <ez-button
        @click="close"
        type="link"
        :data-cy="commonCy.PRODUCTS.PRODUCT_GROUP.REMOVE_MODAL.BUTTON__CANCEL"
      >
        Cancel
      </ez-button>
      <ez-button
        @click="onRemove"
        type="red"
        :data-cy="commonCy.PRODUCTS.PRODUCT_GROUP.REMOVE_MODAL.BUTTON__REMOVE"
      >
        Remove Product Group
      </ez-button>
    </template>
  </ez-confirmation-modal>
</template>

<style lang="scss" scoped>
.remove-group-modal {
  @include z-index('modal', 10);

  :deep() {
    .modal {
      @include z-index('modal', 20);

      .modal__text {
        padding: 0;

        p {
          margin: 0;
        }
      }

      .modal__footer {
        padding: 0;
      }
    }
  }
}
</style>
