<script>
import { wizardListenerMixin } from '@/mixins/wizard';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Paper from '@/components/layout/WizardOverviewPaper';
import EzButton from '@/components/ui/Button/EzButton';
import { ONBOARDING_STEP_1 } from '@/views/platform/venue/suppliers/onboarding/steps';
import flash from '@/components/ui/FlashMessage';
import EzLoader from '@/components/ui/Loader/EzLoader';
import TosModal from '@/views/platform/venue/suppliers/onboarding/TosModal';
import { LOADING_KEY } from '@/util/constants';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  mixins: [wizardListenerMixin],
  components: {
    Paper,
    EzButton,
    EzLoader,
    TosModal,
  },
  props: {
    supplierId: {
      type: Number,
    },
  },
  data() {
    return {
      isLoaderShown: false,
      distributor: null,
      buttonType: 'primary',
    };
  },
  computed: {
    ...mapState('entities/users', [
      'contextId',
    ]),
    ...mapState('entities/venues', [
      'onboarding',
      'publicToken',
    ]),
    financeUser() {
      return this.onboarding.finance;
    },
    orderingUser() {
      return this.onboarding.ordering;
    },
    isTermsAccepted() {
      return this.onboarding.termsAndConditions;
    },
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.DOWNLOAD_TOS);
    },
  },
  watch: {
    isTermsAccepted(val) {
      if (val) {
        this.buttonType = 'green';
        this.enableNextStep();
      } else {
        this.disableNextStep();
      }
    },
  },
  methods: {
    editContacts() {
      this.$emit('stepBack', ONBOARDING_STEP_1);
    },
    async fetchSupplier() {
      const { data } = await this.venueFetchSupplierSingle({
        id: this.supplierId,
      });
      this.distributor = data.data;
    },
    async onNextStep() {
      this.isLoaderShown = true;
      const venueDistributorData = {
        venueId: this.contextId,
        distributorId: this.supplierId,
      };
      try {
        const promises = [];
        /**
         * If selected existing finance user assign to distributor
         */
        if (!this.onboarding.isNewFinance) {
          promises.push(this.venueAssignUserToDistributor({
            ...venueDistributorData,
            userId: this.financeUser.id,
            type: 'finance',
          }));
        }
        /**
         * If selected existing ordering user assign to distributor
         */
        if (!this.onboarding.isNewOrdering) {
          promises.push(this.venueAssignUserToDistributor({
            ...venueDistributorData,
            userId: this.orderingUser.id,
            type: 'ordering',
          }));
        }
        if (this.publicToken) { // if user logged in from public pages
          const config = {
            headers: {
              'X-Public-Token': this.publicToken,
            },
          };
          promises.push(this.connectPublicExistingOnboarding({
            ...venueDistributorData,
            data: {
              termsAndConditions: this.onboarding.termsAndConditions,
            },
            config,
          }));
        } else {
          promises.push(this.venueApproveDeclineOnboarding({
            ...venueDistributorData,
            data: {
              status: 'approved',
              termsAndConditions: this.onboarding.termsAndConditions,
            },
          }));
        }
        await Promise.all(promises);
        this.CLEAR_ONBOARDING_CONTACTS();
        await this.$router.push({
          name: this.publicToken ? 'venue-home' : 'venue-all-suppliers',
          params: {
            flash: {
              title: this.$t('venues.onboarding.flash.title'),
              message: this.$t('venues.onboarding.flash.message', {
                distributorName: this.distributor.name,
              }),
              type: 'success',
            },
          },
        });
        this.CLEAR_LOGIN_PUBLIC_TOKEN();
      } catch (e) {
        flash.error({ title: 'Something went wrong' });
      } finally {
        this.isLoaderShown = false;
      }
    },
    readTermsAndConditions() {
      this.$refs.tos.open();
    },
    ...mapActions('entities/venues', [
      'venueApproveDeclineOnboarding',
      'venueAssignUserToDistributor',
      'connectPublicExistingOnboarding',
    ]),
    ...mapMutations('entities/venues', [
      'CLEAR_ONBOARDING_CONTACTS',
      'CLEAR_LOGIN_PUBLIC_TOKEN',
    ]),
    ...mapActions('entities/distributors', [
      'venueFetchSupplierSingle',
    ]),
  },
  mounted() {
    if (this.isTermsAccepted) {
      this.buttonType = 'green';
      this.enableNextStep();
    }
    this.disableFooterActions();
    this.fetchSupplier();
  },
};
</script>
<template>
  <div>
    <ez-loader :show="isLoaderShown || isLoading">
      {{ isLoading ? 'Downloading Terms & Conditions' : $t('venues.onboarding.loaderMessage') }}
    </ez-loader>
    <div class="terms-btn-container mb-24">
      <ez-button
        @click="readTermsAndConditions"
        :type="buttonType"
      >
        <template v-if="isTermsAccepted">
          <font-awesome-icon icon="check"/>
        </template>
        Read Terms & Conditions
      </ez-button>
    </div>
    <paper>
      <template #title>Contacts</template>
      <template #actions>
        <ez-button @click="editContacts" type="link" formType="button">
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <div class="contact-item">
        <div class="contact-item__name">{{ onboarding.finance.name }}</div>
        <div class="contact-item__type">Finance Contact</div>
      </div>
      <div class="contact-item">
        <div class="contact-item__name">{{ onboarding.ordering.name }}</div>
        <div class="contact-item__type">Ordering Contact</div>
      </div>
    </paper>
    <tos-modal
      v-if="distributor"
      :distributor="distributor"
      ref="tos" />
  </div>
</template>
<style lang="scss" scoped>
  .contact-item {
    @extend %flex-center;
    justify-content: space-between;
    @include font-size(14px, 16px);
    padding: 10px 0;
    &__name {
      color: $color-gray-25;
    }
    &__type {
      color: $color-gray-6C;
    }
  }

  .accepted-tos {
    @extend %flex-center;
    &__icon {
      color: $color-primary-green;
    }
    &__text {
      color: $color-gray-6C;
      @include font-size(14px, 16px);
      margin-left: 8px;
    }
  }
  .terms-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  :deep() .loader {
    @extend %whole-screen;
  }
</style>
