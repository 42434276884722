import { render, staticRenderFns } from "./ProductDrawer.vue?vue&type=template&id=6315c5ad&scoped=true&"
import script from "./ProductDrawer.vue?vue&type=script&lang=js&"
export * from "./ProductDrawer.vue?vue&type=script&lang=js&"
import style0 from "./ProductDrawer.vue?vue&type=style&index=0&id=6315c5ad&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6315c5ad",
  null
  
)

export default component.exports