<script>
import { mapActions, mapGetters } from 'vuex';
import { supplier as supplierCy } from '@weareneopix/qa-utils/dist/orderEz/supplier';
import { LOADING_KEY, UNIT_TYPE_FRACTIONAL } from '@/util/constants';
import { clone } from '@/util/utils';

import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import EzSpinner from '@/components/ui/Spinner/EzSpinner';
import EzTable from '@/components/ui/Table';
import EzAlert from '@/components/ui/Alert';
import EmptyState from '@/views/common/empty-state';

/**
 * AdvancedInventoryManagement
 * @version 1.0.0
 * @since 3.18.0
 */

export default {
  name: 'AdvancedInventoryManagement',
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    warehouseId: {
      type: Number,
    },
    showHeader: {
      type: Boolean,
      requied: false,
      default: true,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
    errorDataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  components: {
    EzMaskInputSimple,
    EzEntityInfo,
    EzLoadMore,
    EzCheckbox,
    EzSpinner,
    EzTable,
    EzAlert,
    EmptyState,
  },
  data() {
    return {
      meta: { page: 0 },
      inventoryItems: [],
      selectedItems: [],
      isInvalidQuantity: {},
      orderQuotas: {},
      productQuotas: {},
      availableStock: {},
      supplierCy,
    };
  },
  computed: {
    ...mapGetters('loading', ['isSomeLoading', 'getLoading']),
    loading() {
      return this.isSomeLoading([
        LOADING_KEY.DISTRIBUTOR_FETCH_ORDER_INVENTORY_ITEMS,
        LOADING_KEY.DISTRIBUTOR_FETCH_ORDER_FULFILLMENT_ITEMS,
      ]);
    },
    loadingMore() {
      return this.getLoading(LOADING_KEY.DISTRIBUTOR_FETCH_MORE_ORDER_INVENTORY_ITEMS);
    },
    orderClone() {
      return clone(this.order);
    },
    productQuotasNotMatched() {
      return !Object.values(this.productQuotas).every(e => !e);
    },
    productsMissing() {
      const missingProducts = Object.entries(this.orderQuotas).map(([id, p]) => {
        const isAvailable = Object.keys(this.availableStock).includes(id);
        return !isAvailable ? { id, name: p.name } : null;
      });

      return missingProducts.filter(Boolean);
    },
    productsShortStock() {
      return Object.entries(this.orderQuotas)
        .map(([id, p]) => (p.quantity > this.availableStock[id] ? +id : null))
        .filter(Boolean);
    },
    invalid() {
      if (this.loading) return false;
      return (
        !!this.productsMissing.length ||
        !!this.productsShortStock.length ||
        this.productQuotasNotMatched
      );
    },
  },
  methods: {
    ...mapActions('entities/orders', [
      'distributorFetchOrderFulfillmentItems',
      'distributorFetchOrderInventoryItems',
    ]),
    filterProducts(products) {
      return (
        products?.filter(p => {
          const declined = p.isDeclined ?? false;
          const included = p.isIncluded ?? true;
          return p.inventoryTracked && included && !declined && p.quantity;
        }) || []
      );
    },
    increment(row) {
      return this.isFractional(row) ? 0.01 : 1;
    },
    isInvalid(row) {
      if (this.productsMissing.length) return false;
      return this.productsShortStock.includes(row.product.id) || this.isInvalidQuantity[row.id];
    },
    maxValue(row) {
      const { id, product, currentInventory: inventory } = row;
      const quantity = this.findSelected(id, 'usedInventory');
      const remaining = this.productQuotas[product.id];

      if (!quantity) return Math.min(inventory, remaining);
      if (!remaining) return Math.min(inventory, quantity);

      return Math.min(inventory, remaining + quantity);
    },
    isFractional(row) {
      return row.product.orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    productQuotaReached(id) {
      return !this.productQuotas[id];
    },
    onCheckboxChange(row, checked) {
      if (!checked) {
        this.unselectProduct(row.id);
        return;
      }

      this.selectProduct(row);
      this.updateSelectedProduct(row.id, { usedInventory: this.increment(row) });
    },
    onQuantityChange(row, val) {
      if (!this.isSelected(row.id)) {
        if (val) this.selectProduct(row);
        return;
      }
      if (!val && val !== null) {
        this.unselectProduct(row.id);
        return;
      }

      this.updateSelectedProduct(row.id, { usedInventory: this.fixedDecimalNumber(val) });
    },
    updateSelectedProduct(id, payload) {
      const idx = this.selectedItems.findIndex(p => p.id === id);
      this.selectedItems.splice(idx, 1, { ...this.selectedItems[idx], ...payload });
    },
    onQuantityInvalid(row, val) {
      this.isInvalidQuantity = { ...this.isInvalidQuantity, [row.id]: val };
    },
    selectProduct(row) {
      if (this.isSelected(row.id)) return;
      this.selectedItems.push(row);
    },
    unselectProduct(id) {
      const { [id]: removed, ...rest } = this.isInvalidQuantity;
      this.isInvalidQuantity = rest;

      const idx = this.selectedItems.findIndex(p => p.id === id);
      this.selectedItems.splice(idx, 1);
    },
    isSelected(id) {
      return !!this.findSelected(id);
    },
    findSelected(id, key = '') {
      const item = this.selectedItems.find(p => p.id === id);
      if (!key) return item;

      return item?.[key];
    },
    async fetchFulfillmentItems() {
      const { data } = await this.distributorFetchOrderFulfillmentItems({
        orderId: this.order.id,
        data: {
          warehouseId: this.warehouseId,
          products: this.filterProducts(this.order.orderedProducts)
            .map(p => ({
              productId: p.productId,
              quantity: p.quantity,
            }))
            .reduce((r, { productId, quantity }) => {
              const index = r.findIndex(item => item.productId === productId);
              if (index === -1) {
                r.push({ productId, quantity });
              } else {
                r[index].quantity += quantity;
              }
              return r;
            }, []),
        },
      });

      this.selectedItems = clone(data.data);
      this.availableStock = clone(data.data).reduce((a, c) => {
        const quantity = this.fixedDecimalNumber((a[c.product.id] || 0) + c.usedInventory);
        return { ...a, [c.product.id]: quantity };
      }, {});
    },
    async fetchInventoryItems(
      loadingKey = LOADING_KEY.DISTRIBUTOR_FETCH_ORDER_INVENTORY_ITEMS,
      append = false,
    ) {
      const { data } = await this.distributorFetchOrderInventoryItems({
        orderId: this.order.id,
        loadingKey,
        data: {
          warehouseId: this.warehouseId,
          page: this.meta.page + 1,
          products: this.filterProducts(this.order.orderedProducts).map(p => +p.productId),
        },
      });
      if (append) this.inventoryItems = clone([...this.inventoryItems, ...data.data]);
      else this.inventoryItems = data.data;
      this.meta = data.meta;
    },
    async fetchMoreInventoryItems() {
      await this.fetchInventoryItems(
        LOADING_KEY.DISTRIBUTOR_FETCH_MORE_ORDER_INVENTORY_ITEMS,
        true,
      );
    },
    updateProductQuotas(items) {
      const newQuotas = {};
      const selectedTotals = items.reduce((acc, curr) => {
        const { product: p, usedInventory } = curr;
        return { ...acc, [p.id]: this.fixedDecimalNumber((acc[p.id] || 0) + (usedInventory || 0)) };
      }, {});

      Object.entries(selectedTotals).forEach(([id, total]) => {
        newQuotas[id] = this.fixedDecimalNumber(this.orderQuotas[id].quantity - total);
      });

      Object.entries(this.orderQuotas).forEach(([id, p]) => {
        if (newQuotas[id] !== undefined) return;
        newQuotas[id] = this.fixedDecimalNumber(p.quantity);
      });

      this.productQuotas = { ...newQuotas };
    },
    updateOrderQuotas(order) {
      this.orderQuotas = this.filterProducts(order.orderedProducts).reduce((acc, curr) => {
        const { productId, quantity, name } = curr;
        return {
          ...acc,
          [productId]: { quantity: quantity + (acc[productId]?.quantity || 0), name },
        };
      }, {});
    },
    async refresh() {
      this.inventoryItems = [];
      this.meta = { page: 0 };

      if (!this.warehouseId) return;
      await Promise.all([this.fetchFulfillmentItems(), this.fetchInventoryItems()]);
    },
    fixedDecimalNumber(num) {
      return Number.parseFloat(num.toFixed(2));
    },
    imgTooltip(row) {
      const { TEXT__TOOLTIP } = this.supplierCy.ORDERS.ADVANCED_INVENTORY_MANAGEMENT;
      const { CONTAINER, PRODUCT, LOCATION, BATCH_CODE, COGS } = TEXT__TOOLTIP;

      const span = (dataCy, text) =>
        `<span data-cy="${dataCy}" style="word-break: break-all;">${text}</span>`;

      return {
        placement: 'top-start',
        classes: ['tooltip--reset-margin mb-8'],
        content: `
          <div data-cy="${CONTAINER}">
            ${span(PRODUCT, row.product.name)}<br />
            ${span(LOCATION, `Location: ${row.location ? row.location.code : '-'}`)}<br />
            ${span(BATCH_CODE, `Batch ID: ${row.batchCode ? row.batchCode : '-'}`)}<br />
            ${span(COGS, `COGS: ${this.$helpers.formatPrice(row.cogs || 0)}`)}
          </div>
        `,
      };
    },
  },
  watch: {
    async warehouseId() {
      this.availableStock = {};
      await this.refresh();
    },
    selectedItems: {
      immediate: true,
      deep: true,
      handler(val) {
        this.updateProductQuotas(val);
        this.$emit('onChange', val);
      },
    },
    orderClone: {
      immediate: true,
      deep: true,
      async handler(newVal, oldVal) {
        const newProducts = JSON.stringify(newVal?.orderedProducts);
        const oldProducts = JSON.stringify(oldVal?.orderedProducts);
        if (newProducts === oldProducts) return;

        this.updateOrderQuotas(newVal);
        await this.refresh();
      },
    },
    async show(val) {
      if (!val) return;
      await this.$nextTick();
      this.$el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    },
    invalid: {
      immediate: true,
      handler(val) {
        this.$emit('invalid', val);
      },
    },
  },
};
</script>

<template>
  <div v-if="show" :data-cy="dataCy">
    <div v-if="showHeader" class="help-block">
      <hr />
      <h4 class="help-block__title">Inventory Management</h4>
      <p class="help-block__text">Select products for delivery, and insert their order quantity.</p>
    </div>

    <ez-alert v-if="invalid" type="red" size="inline" variant="disclaimer" :data-cy="errorDataCy">
      <template #icon>
        <font-awesome-icon icon="exclamation-circle" />
      </template>
      <template>
        <template v-if="productsMissing.length">
          These products don’t have stock in the selected warehouse:
          <ul class="missing-products-list m-0 pl-0">
            <li v-for="{ id, name } in productsMissing" :key="id">- {{ name }}</li>
          </ul>
        </template>

        <template v-else-if="productsShortStock.length">
          Some products don’t have enough stock to fulfil from this warehouse.
        </template>

        <template v-else-if="productQuotasNotMatched">
          To confirm the action, exact ordered quantity for all products must be chosen.
        </template>
      </template>
    </ez-alert>

    <div
      :class="[
        'inventory-items-table--container',
        { 'inventory-items-table--container--overflow-visible': inventoryItems.length < 4 },
      ]"
    >
      <ez-table
        class="inventory-items-table"
        :loading="loading"
        :data="inventoryItems"
        :columns="['product', 'expiresSoon', 'usedInventory']"
        :columnProps="{
          product: { class: 'product-cell' },
          expiresSoon: { class: 'indicator-cell extra-small-cell no-side-padding-cell' },
          usedInventory: { class: 'quantity-cell medium-cell' },
        }"
        :headers="{
          expiresSoon: () => '',
          usedInventory: () => 'Quantity',
        }"
        :rowDataCy="
          row => `${supplierCy.ORDERS.ADVANCED_INVENTORY_MANAGEMENT.ROW__PRODUCT}-${row.id}`
        "
      >
        <template #cell-product="{ row, row: { product } }">
          <div class="cell-product-container">
            <div @click.stop>
              <ez-checkbox
                class="cursor-pointer mr-8"
                :checked="isSelected(row.id)"
                @change="onCheckboxChange(row, $event)"
                :data-cy="supplierCy.ORDERS.ADVANCED_INVENTORY_MANAGEMENT.INPUT__CHECKBOX"
              />
            </div>
            <ez-entity-info
              imgWidth="2rem"
              imgHeight="2rem"
              :imgUrl="product.image"
              :imgTooltip="imgTooltip(row)"
              :imgDataCy="supplierCy.ORDERS.ADVANCED_INVENTORY_MANAGEMENT.IMG__PRODUCT"
            >
              <div class="product-info" :title="product.name">
                <span class="product-info__primary">{{ product.name }}</span>
                <span class="product-info__secondary">
                  <span v-if="row.expiryDate" class="product-info__secondary__expiryDate">
                    {{ row.expiryDate | date }}
                  </span>
                  <span v-if="row.expiryDate && product.sku"> &#8226; </span>
                  <span v-if="product.sku">
                    {{ product.sku }}
                  </span>
                </span>
              </div>
            </ez-entity-info>
          </div>
        </template>

        <template #cell-expiresSoon="{ row: { expiresSoon } }">
          <font-awesome-icon
            v-if="expiresSoon"
            v-tooltip="{
              placement: 'start',
              content: 'This batch is about to expire soon.',
              classes: ['tooltip-general'],
            }"
            icon="calendar-alt"
          />
        </template>

        <template #cell-usedInventory="{ row }">
          <ez-mask-input-simple
            formKey=""
            name="usedInventory"
            :minValue="0"
            :value="findSelected(row.id, 'usedInventory')"
            :precision="isFractional(row) ? 2 : 0"
            :isInvalid="isInvalid(row)"
            :maxValue="maxValue(row)"
            @input="onQuantityChange(row, $event)"
            @invalid="onQuantityInvalid(row, $event)"
            @click.stop
            :data-cy="supplierCy.ORDERS.ADVANCED_INVENTORY_MANAGEMENT.INPUT__QUANTITY"
            :has-currency="false"
          />
        </template>
      </ez-table>

      <empty-state v-if="!inventoryItems.length">
        <template #badge><img src="@/assets/no-product-empty-state.svg" alt="" /></template>
        <template #title>No products listed</template>
        <template #info>This warehouse has no products that can fulfill this order.</template>
      </empty-state>
    </div>

    <ez-load-more
      v-if="!loadingMore && meta.lastPage > meta.page"
      @loadMore="fetchMoreInventoryItems"
    />

    <div v-if="loadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.help-block {
  &__text {
    @include font-size(14px, 20px);
  }
}

.inventory-items-table {
  &--container {
    overflow: auto;
    max-height: 510px;

    &--overflow-visible {
      overflow: visible;
    }
  }

  .cell-product-container {
    @extend %flex-center;
    justify-content: flex-start;

    .product-info {
      display: flex;
      flex-direction: column;

      &__primary {
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
      }

      &__secondary {
        @include font-size(12px, 14px);
        font-weight: 500;
        color: $color-gray-6C;

        &__expiryDate {
          color: $color-gray-25;
          font-weight: 500;
        }
      }
    }
  }

  .indicator-cell {
    overflow: visible;
    text-align: center;
    color: $color-primary-orange-red;
  }

  thead th.quantity-cell {
    text-align: left;
  }

  .quantity-cell {
    overflow: visible;
  }
}

.missing-products-list li {
  list-style-type: none;
}

:deep() .ez-empty-state {
  &__image {
    max-width: 256px;

    img {
      width: 256px;
      height: 118px;
    }
  }
}

:deep() .mask-input.mark-input--inline {
  .mask-input__input {
    font-weight: normal;
    color: $color-gray-25;
    text-align: center;
  }
}
</style>
