<script>
/**
 * AllSuppliers
 * @version 1.0.0
 * @since 2.3.0
 */

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import EzTable from '@/components/ui/Table';
import VDistributorEntityInfo from '@/components/v3/patterns/VDistributorEntityInfo/index';
import EzFilterList from '@/components/ui/FilterList/EzFilterList.vue';
import EzInput from '@/components/ui/Input';
import EzButton from '@/components/ui/Button';
import EzAlert from '@/components/ui/Alert';
import { EzFormModal } from '@/components/ui/Modal';
import EzAutocomplete from '@/components/ui/Autocomplete/EzAutocomplete';
import EzPremiumBadge from '@/components/ui/PremiumBadge/PremiumBadge';
import VLocationBadge from '@/components/v3/elements/VLocationBadge';
import EzEntityInfo from '@/components/ui/EntityInfo/EzEntityInfo';
import { EzSelectedDistributor } from '@/components/ui/SelectedItem';
import { venueXeroConnectContact } from '@/api/endpoints/xero';
import flash from '@/components/ui/FlashMessage';
import { PENDING_INVITE_SENT, LOADING_KEY } from '@/util/constants';
import StatusBadge from '@/views/common/status-badge/StatusBadge';
import EzLoader from '@/components/ui/Loader/EzLoader';
import { debounce } from '@/util/utils';
import EzLoadMore from '@/components/ui/LoadMore/EzLoadMore.vue';
import EzSpinner from '@/components/ui/Spinner/EzSpinner.vue';
import EzSelect from '@/components/ui/Select/EzSelect.vue';
import EzCheckbox from '@/components/ui/Checkbox/Checkbox';
import FooterForUpdate from '@/views/platform/venue/xero/settings/FooterForUpdate';
import SelectLocationsModal from './SelectLocationsModal.vue';

export default {
  components: {
    EzSpinner,
    EzLoadMore,
    EzLoader,
    StatusBadge,
    VDistributorEntityInfo,
    EzTable,
    EzFilterList,
    EzInput,
    EzButton,
    EzAlert,
    EzFormModal,
    EzAutocomplete,
    EzPremiumBadge,
    EzEntityInfo,
    EzSelectedDistributor,
    EzSelect,
    VLocationBadge,
    SelectLocationsModal,
    EzCheckbox,
    FooterForUpdate,
  },
  props: {
    orderingMethod: {
      type: Object,
      required: false,
      default: () => ({
        name: 'All Ordering Methods',
        id: null,
      }),
    },
  },
  data() {
    return {
      filters: {
        term: null,
      },
      suppliers: [],
      selectedSupplier: null,
      selected: false,
      listOfSupplierIds: [],
      selectedSupplierLocations: [],
      supplierId: null,
      outletCy,
      meta: {},
      orderingMethods: [
        {
          name: 'All Ordering Methods',
          id: null,
        },
        {
          name: 'Email',
          id: 'email',
        },
        {
          name: 'SMS',
          id: 'sms',
        },
        {
          name: 'WhatsApp',
          id: 'whatsApp',
        },
        {
          name: 'None',
          id: 'none',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('defaultImages', ['getDefaultImage']),
    ...mapGetters('loading', ['getLoading']),
    ...mapState('entities/users', ['context', 'contextId', 'loggedUser']),
    isLoading() {
      return this.getLoading(LOADING_KEY.FETCH_SUPPLIERS);
    },
    isLoadingMore() {
      return this.getLoading(LOADING_KEY.FETCH_SUPPLIERS_LOAD_MORE);
    },
    isLoadingAddNew() {
      return this.getLoading(LOADING_KEY.VENUE_ADD_SUPPLIER);
    },
    canCreateNewDistributor() {
      return true;
    },
    defaultImage() {
      return this.getDefaultImage('venue');
    },
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
    groupViewMode() {
      if (this.loggedUser.context === 'venue-group') return true;
      return false;
    },
    ...mapGetters('entities/users', ['getVenues']),
    venues() {
      return this.getVenues;
    },
  },
  methods: {
    ...mapActions('entities/distributors', [
      'venueFetchConnectedDistributorsNew',
      'venueAddSupplier',
    ]),
    ...mapMutations('entities/distributors', ['UPDATE_DISTRIBUTOR_COUNT']),
    nameCheckbox(h) {
      return h('span', { class: 'u-flex-center select-all-checkbox' }, [
        h(
          'ez-checkbox',
          {
            class: 'mr-8',
            props: {
              label: 'Supplier Name',
              indeterminate:
                this.listOfSupplierIds.length > 0 &&
                this.listOfSupplierIds.length !== this.suppliers.length,
              checked: this.listOfSupplierIds.length === this.suppliers.length,
            },
            on: {
              change: event => {
                this.selectDeselectAll(event);
              },
            },
          },
          [],
        ),
      ]);
    },
    isInvitationSent(venue) {
      return venue.invitationStatus === PENDING_INVITE_SENT;
    },
    tooltipContent(venue) {
      if (!venue) {
        return '';
      }
      const supplierStr = this.$t('global.distributor').toLowerCase();
      const msg = `Can’t connect with a ${supplierStr} that has a different default currency.`;
      return venue.differentCurrency ? msg : '';
    },
    async refresh(query = {}, loadingKey = LOADING_KEY.FETCH_SUPPLIERS) {
      const { nextId, nextValue } = this.meta;
      const combinedQuery = {
        ...query,
        nextId,
        nextValue,
        limit: 40,
      };
      try {
        const {
          data: { data, meta },
        } = await this.venueFetchConnectedDistributorsNew({
          query: combinedQuery,
          loadingKey,
        });
        if (loadingKey === LOADING_KEY.FETCH_SUPPLIERS) {
          this.suppliers = data;
        } else {
          this.suppliers = [...this.suppliers, ...data];
        }
        this.meta = meta;
        this.UPDATE_DISTRIBUTOR_COUNT(this.meta.totalCount);
      } catch (e) {
        console.error(e);
      }
    },
    onSupplierSelect(supplier) {
      if (supplier.first) {
        this.closeConnectModal();
        this.$router.push({
          name: 'buyer-suppliers-new',
          params: {
            prefilledName: supplier.query,
            venueId: this.contextId,
          },
        });
      } else {
        this.selectedSupplier = supplier.selected;
      }
    },
    openConnectModal() {
      this.$refs.connect.open();
    },
    closeConnectModal() {
      this.$refs.connect.close();
      this.selectedSupplier = null;
    },
    removeSelectedSupplier() {
      this.selectedSupplier = null;
    },
    focusOnInput() {
      this.$refs.autocomplete.focus();
    },
    async addSelectedSupplier() {
      try {
        const { id } = this.selectedSupplier;

        if (this.selectedSupplier.xeroId) {
          // Xero Contact
          const res = await venueXeroConnectContact(this.contextId, {
            contactId: this.selectedSupplier.xeroId,
          });
          const { data } = res.data;
          this.$router.push({
            name: 'buyer-suppliers-connect',
            params: {
              id: data.id,
              flash: {
                title: 'Xero supplier successfully created.',
              },
            },
          });
        } else {
          // OrderEZ Distributor
          // Connect to OrderEZ Distributor
          const { data } = await this.venueAddSupplier({
            id: this.contextId,
            distributorId: this.selectedSupplier.id,
          });
          const supplierName = this.selectedSupplier.name;
          if (data.data.status === 'connection') {
            // If connected (freemium)
            this.$router.push({ name: 'buyer-suppliers-connect', params: { id } });
          } else {
            // If request sent (premium)
            this.closeConnectModal();
            flash.success({
              title: this.$t('distributors.successFlashTitleConnectionRequest'),
              message: this.$t('distributors.successFlashMessageConnectionRequest', {
                supplierName,
              }),
            });
          }
        }
      } catch (e) {
        this.closeConnectModal();
        flash.fail({
          title: 'Something went wrong',
          message: e.response.data.error.message,
        });
      }
    },
    resetData() {
      this.suppliers = [];
      this.listOfSupplierIds = [];
      this.supplierId = null;
      this.selectedSupplierLocations = [];
      this.meta = {};
    },
    resetFilters() {
      Object.keys(this.filters).forEach(key => {
        this.filters[key] = null;
      });

      this.resetData();
      this.refresh();
    },
    updateFilters: debounce(async function deb(filterName, event) {
      if (filterName === 'search') {
        if (event.length !== 0 && event.length < 3) return;
        this.filters = { ...this.filters, term: event.length ? event : null };
      } else {
        this.filters = { ...this.filters, [filterName]: event.id };
      }

      this.resetData();
      await this.refresh(this.filters);
    }, 300),
    async onLoadMore() {
      await this.refresh(this.filters, LOADING_KEY.FETCH_SUPPLIERS_LOAD_MORE);
    },
    goToSingleSupplier({ id }) {
      const routeData = this.$router.resolve({ name: 'buyer-single-info', params: { id } });
      window.open(routeData.href, '_blank');
    },
    openContactsPage(id) {
      this.$router.push({ name: 'buyer-single-users', params: { id } });
    },
    openLocationModal(supplier, bulk = false, allSelected = false) {
      if (bulk) {
        if (allSelected) {
          this.selectedSupplierLocations = this.venues.map(item => item.id);
        } else {
          this.selectedSupplierLocations = [];
        }
      } else {
        this.selectedSupplierLocations = supplier.connectedVenueIdsFromGroup;
        this.supplierId = supplier.id;
      }
      this.$refs.locationsModal.open();
    },
    updateSupplierLocations(val) {
      if (this.supplierId) {
        const index = this.suppliers.findIndex(item => item.id === this.supplierId);
        if (index !== -1) {
          this.suppliers[index].connectedVenueIdsFromGroup = val;
          if (val.length === this.venues.length) {
            this.suppliers[index].allConnectedFromGroup = true;
          } else {
            this.suppliers[index].allConnectedFromGroup = false;
          }
        }
        this.supplierId = null;
      } else {
        this.listOfSupplierIds.forEach(id => {
          const index = this.suppliers.findIndex(item => item.id === id);
          if (index !== -1) {
            this.suppliers[index].connectedVenueIdsFromGroup = val;
            if (val.length === this.venues.length) {
              this.suppliers[index].allConnectedFromGroup = true;
            } else {
              this.suppliers[index].allConnectedFromGroup = false;
            }
          }
        });
      }
    },
    onSupplierLocationsClose() {
      this.selectedSupplierLocations = [];
      this.supplierId = null;
    },
    isSupplierSelected(supplier) {
      return this.listOfSupplierIds.findIndex(el => el === supplier.id) >= 0;
    },
    async selectDeselectAll(selected) {
      this.selected = selected;
      if (selected) this.listOfSupplierIds = this.suppliers.map(item => item.id);
      else this.listOfSupplierIds = [];
    },
    onCheckboxChange(supplier, checked) {
      if (checked) this.listOfSupplierIds.push(supplier.id);
      else this.listOfSupplierIds = this.listOfSupplierIds.filter(item => item !== supplier.id);
    },
  },
  created() {
    if (this.orderingMethod.id)
      this.filters = {
        ...this.filters,
        orderingMethod: this.orderingMethod.id,
      };
    this.refresh(this.filters);
    this.$emit('updateHeaderInfo', null);
  },
};
</script>

<template>
  <div class="venue-all-suppliers">
    <div class="filter-container">
      <ez-filter-list :filters="filters" @resetFilter="resetFilters" @filterUpdated="updateFilters">
        <ez-input
          formKey="filters"
          label="search"
          name="search"
          :placeholder="$t('distributors.searchForDistributorPlaceholder')"
          :data-cy="outletCy.SUPPLIERS.CURRENT_SUPPLIERS.INPUT__SEARCH"
        >
          <template #prefix>
            <font-awesome-icon icon="search" />
          </template>
        </ez-input>
        <ez-select
          ref="select"
          class="input-group"
          name="orderingMethod"
          :value="orderingMethod"
          :selected="orderingMethod.id"
          :options="orderingMethods"
        />
      </ez-filter-list>
      <ez-button v-if="canCreateNewDistributor" @click="openConnectModal">
        <template>{{ $t('distributors.addNewDistributor') }}</template>
      </ez-button>
    </div>

    <ez-alert type="orange" v-if="context.invitationsCount > 0">
      <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
      <template>
        {{ $tc('distributors.connectionRequestAlert', context.invitationsCount) }}
      </template>
      <template #cta>
        <router-link :to="{ name: 'venue-connection-requests' }">
          <ez-button type="yellow-link">{{ $t('distributors.viewAllConnections') }}</ez-button>
        </router-link>
      </template>
    </ez-alert>

    <ez-table
      v-if="suppliers.length"
      :data="suppliers"
      :headers="{
        ...(groupViewMode
          ? { supplierName: nameCheckbox }
          : { supplierName: () => $t('tables.distributorName') }),
        orderingMethod: () => 'Ordering Method',
        productCount: () => $t('tables.numberOfProducts'),
      }"
      :columnProps="{
        productCount: { class: 'medium-cell' },
        orderingMethod: { class: 'ordering-method-cell' },
        location: { class: 'location-cell' },
      }"
      :columns="[
        'supplierName',
        ...(groupViewMode ? ['location'] : []),
        'orderingMethod',
        'productCount',
      ]"
      @rowClick="goToSingleSupplier"
    >
      <template #cell-supplierName="{ row }">
        <div class="u-flex-row u-flex-center">
          <ez-checkbox
            v-if="groupViewMode"
            class="cursor-pointer mr-8"
            :key="row.id"
            :checked="isSupplierSelected(row)"
            @change="onCheckboxChange(row, $event)"
            :stopPropagation="true"
          />
          <v-distributor-entity-info
            :distributor="row"
            :data-cy="`${outletCy.SUPPLIERS.CURRENT_SUPPLIERS.TEXT__SUPPLIER_NAME}-${row.id}`"
          />
        </div>
      </template>
      <template v-if="groupViewMode" #cell-location="{ row }">
        <div class="location-wrapper" @click.stop="openLocationModal(row)">
          <v-location-badge v-if="row.allConnectedFromGroup" type="green">All</v-location-badge>
          <v-location-badge v-else-if="row.connectedVenueIdsFromGroup.length" type="yellow"
            >Some</v-location-badge
          >
          <v-location-badge v-else type="red">None</v-location-badge>
          <font-awesome-icon icon="pen" class="location-edit"></font-awesome-icon>
        </div>
      </template>
      <template #cell-orderingMethod="{ row: { orderingMethods, orderingMethodLabel, id } }">
        <div
          class="ordering-method__icons"
          v-if="orderingMethods.length"
          @click.stop="openContactsPage(id)"
        >
          <div
            class="ordering-method__icon"
            v-for="orderingMethod in orderingMethods"
            :key="orderingMethod.type"
          >
            <font-awesome-icon
              v-if="orderingMethod.type === 'sms'"
              :icon="['fas', 'sms']"
              v-tooltip="{
                placement: 'top',
                content: 'SMS',
              }"
            />
            <font-awesome-icon
              v-if="orderingMethod.type === 'email'"
              :icon="['fas', 'envelope']"
              v-tooltip="{
                placement: 'top',
                content: 'Email',
              }"
            />
            <font-awesome-icon
              v-if="orderingMethod.type === 'whatsApp'"
              :icon="['fab', 'whatsapp']"
              v-tooltip="{
                placement: 'top',
                content: 'Whatsapp',
              }"
            />
          </div>
        </div>
        <div v-else @click.stop="openContactsPage(id)">
          <span>{{ orderingMethodLabel }}</span>
        </div>
      </template>
    </ez-table>

    <div v-if="isLoadingMore" class="u-text-center mt-12">
      <ez-spinner />
    </div>

    <ez-load-more v-if="meta.nextId && !isLoadingMore" @loadMore="onLoadMore" />

    <select-locations-modal
      ref="locationsModal"
      :locations="selectedSupplierLocations"
      :supplierId="supplierId"
      :suppliers="listOfSupplierIds"
      @success="updateSupplierLocations"
      @close="onSupplierLocationsClose"
    />

    <ez-form-modal class="new-supplier-modal" ref="connect" @open="focusOnInput">
      <template #title>Add New {{ $t('global.distributor') }}</template>
      <template #content>
        <ez-autocomplete
          ref="autocomplete"
          :source="`/venue/search/distributors`"
          :requestParams="{ connected: false }"
          searchProperty="name"
          @selected="onSupplierSelect"
          :label="`Search for a ${$t('global.distributor')}`"
          disable-result-prop-name="differentCurrency"
          :disable-based-on-status="{
            property: 'invitationStatus',
            statuses: ['invitation_sent', 'invitation_received'],
          }"
        >
          <template #firstResult="{ input }">
            <li>
              <ez-entity-info :imgUrl="defaultImage" :imgHasBorder="true" imgBorderRadius="50%">
                <span>Create New "{{ input }}" {{ $t('global.distributor') }}</span>
              </ez-entity-info>
            </li>
          </template>
          <template #result="{ result }">
            <div
              class="width-100"
              v-tooltip="{
                content: tooltipContent(result),
                classes: ['tooltip-general'],
              }"
            >
              <div class="u-flex-space width-100">
                <div
                  :class="[
                    'venue-result',
                    {
                      'venue-result--disabled':
                        result.differentCurrency || isInvitationSent(result),
                    },
                  ]"
                >
                  <img :src="result.logo" alt="" class="venue-result__image" />
                  <div class="venue-result__info">
                    <div class="venue-result__title">
                      <span :title="result.name">{{ result.name }}</span>
                      <ez-premium-badge v-if="$helpers.isPremium(result.accountType)" />
                    </div>
                    <div class="venue-result__label">
                      <small v-if="result.xeroId">Xero</small>
                      <small v-else>{{ oezPlatformName }}</small>
                    </div>
                  </div>
                </div>
                <div class="u-flex-row">
                  <status-badge v-if="isInvitationSent(result)" status="invitation_sent" />
                  <status-badge v-if="result.differentCurrency" status="currency-no-matching" />
                </div>
              </div>
            </div>
          </template>
        </ez-autocomplete>

        <ez-selected-distributor @remove="removeSelectedSupplier" :distributor="selectedSupplier" />
      </template>
      <template #footer>
        <ez-button type="link" @click="closeConnectModal">Cancel</ez-button>
        <ez-button
          @click="addSelectedSupplier"
          :disabled="!selectedSupplier"
          :is-loading="isLoadingAddNew"
        >
          Add {{ $t('global.distributor') }}</ez-button
        >
      </template>
    </ez-form-modal>
    <ez-loader :show="isLoading">Loading...</ez-loader>
    <footer-for-update
      v-if="listOfSupplierIds.length"
      entity="Suppliers"
      :items-count="listOfSupplierIds.length"
      @footerAction="openLocationModal(null, true)"
    />
  </div>
</template>

<style lang="scss" scoped>
:deep() .loader {
  @extend %whole-screen;
}

.venue-result--disabled {
  opacity: 0.7;
}

:deep() .new-supplier-modal {
  .autocomplete {
    border: unset;
  }
}

.select-all-checkbox {
  .input-group {
    @include font-size(12px);
  }
}

.ordering-method-cell {
  .ordering-method__icons {
    display: flex;
    font-size: 20px;

    .ordering-method__icon + .ordering-method__icon {
      margin-left: 16px;
    }
  }
}

.venue-all-suppliers {
  @include platform-tab-content();
  position: relative;
  min-height: $loading-min-height;
}

.filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: $spacing-24 0;

  .ez-select.input-group {
    height: 36px;
  }

  :deep() .ez-select__display--placeholder + svg {
    color: $color-gray-6C;
  }
}

.autocomplete {
  padding-bottom: $spacing-16;
  margin-bottom: $spacing-16;
  border-bottom: 1px dashed $color-gray-E9;
}

.invite-sent {
  margin-left: auto;
}

.venue-result {
  @extend %flex-center;

  &__image {
    @include size(32px);
    border-radius: 50%;
    border: 1px solid #dee1e4;
  }

  &__info {
    margin-left: 8px;
  }

  &__titele {
    color: #252631;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
  }

  &__label {
    color: #6c7995;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 14px;
  }
}

.table tbody tr td.location-cell {
  padding: 0;
}

.location-cell {
  .location-wrapper {
    padding: 0.75rem 1rem;
  }
  .location-edit {
    display: none;
  }

  &:hover {
    cursor: pointer;
    .location-edit {
      display: inline-block;
    }
    :deep() .location-badge {
      display: none;
    }
  }
}
</style>
