<template>
  <page>
    <template #breadcrumbs>
      <router-link :to="{ name: 'platform-home' }">
        <font-awesome-icon icon="arrow-left" />
        Back
      </router-link>
    </template>
    <template #title>
      <h1>Group Settings</h1>
    </template>
    <template #navigation>
      <ul>
        <li>
          <router-link :to="{ name: 'venue-group-settings' }">Group Info</router-link>
        </li>
        <li v-if="loggedUser.groupViewFeaturesEnabled">
          <router-link :to="{ name: 'venue-group-settings-settings-group' }"
            >Group Settings</router-link
          >
        </li>
        <li>
          <router-link :to="{ name: 'venue-group-settings-venues' }">
            {{ $t('global.venues') }}
          </router-link>
        </li>
        <li v-if="$permission.has('userAdministration')">
          <router-link :to="{ name: 'venue-group-settings-users' }">Users</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'venue-group-settings-settings' }"
            >Product Settings</router-link
          >
        </li>
      </ul>
    </template>
  </page>
</template>

<script>
import { mapState } from 'vuex';
import Page from '@/views/admin/SinglePage.vue';

export default {
  components: {
    Page,
  },
  computed: {
    ...mapState('entities/users', ['loggedUser']),
  },
};
</script>

<style scoped lang="scss">
.admin-single-page {
  h1 {
    @include font-size(24px, 32px);
    font-weight: 500;
    margin: 0 0 0.5rem 0;
    letter-spacing: -1px;
  }
}
</style>
