<script>
import { wizardListenerMixin } from '@/mixins/wizard';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import Paper from '@/components/layout/WizardOverviewPaper';
import EzButton from '@/components/ui/Button/EzButton';
import {
  ONBOARDING_STEP_3,
  ONBOARDING_STEP_2,
} from '@/views/platform/venue/suppliers/onboarding-from-public/steps';
import flash from '@/components/ui/FlashMessage';
import EzLoader from '@/components/ui/Loader/EzLoader';
import VEntityInfo from '@/components/v3/elements/VEntityInfo';
import TosModal from '@/views/platform/venue/suppliers/onboarding-from-public/TosModal';
import { LOADING_KEY } from '@/util/constants';

/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */
export default {
  mixins: [wizardListenerMixin],
  components: {
    Paper,
    EzButton,
    EzLoader,
    VEntityInfo,
    TosModal,
  },
  props: {
    supplierId: {
      type: Number,
    },
  },
  data() {
    return {
      isLoaderShown: false,
      distributor: null,
      buttonType: 'primary',
    };
  },
  computed: {
    ...mapState('entities/users', [
      'contextId',
    ]),
    ...mapState('entities/venues', [
      'publicOnboarding',
      'publicToken',
    ]),
    ...mapGetters('loading', ['getLoading']),
    isLoading() {
      return this.getLoading(LOADING_KEY.DOWNLOAD_TOS);
    },
    venue() {
      return this.publicOnboarding.venue;
    },
    shippingAddress() {
      return this.venue.shippingAddress;
    },
    billingAddress() {
      return this.venue.billingAddress;
    },
    financeUser() {
      return this.publicOnboarding.financeUser;
    },
    orderingUser() {
      return this.publicOnboarding.orderingUser;
    },
    hasOrderingUser() {
      return Object.keys(this.publicOnboarding.orderingUser).length;
    },
    isTermsAccepted() {
      return this.publicOnboarding.termsAndConditions;
    },
  },
  watch: {
    isTermsAccepted(val) {
      if (val) {
        this.buttonType = 'green';
        this.enableNextStep();
      } else {
        this.disableNextStep();
      }
    },
  },
  methods: {
    addressFormat(data) {
      return [
        ...(data.street ? [`${data.street}`] : []),
        ...(data.city ? [`${data.zipCode} ${data.city}`] : []),
        ...(data.country ? [`${data.country}`] : []),
        ...(data.state ? [`${data.state}`] : []),
      ].join(', ');
    },
    readTermsAndConditions() {
      this.$refs.tos.open();
    },
    editContacts() {
      this.$emit('stepBack', ONBOARDING_STEP_3);
    },
    editVenue() {
      this.$emit('stepBack', ONBOARDING_STEP_2);
    },
    async fetchSupplier() {
      const { data } = await this.venueFetchSupplierSingle({
        id: this.supplierId,
      });
      this.distributor = data.data;
    },
    redirectToAppUrl() {
      window.location.href = `${process.env.VUE_APP_REDIRECT_TO_URL}`;
    },
    async onNextStep() {
      this.isLoaderShown = true;
      const venueDistributorData = {
        venueId: this.venue.id,
        distributorId: this.supplierId,
      };
      try {
        const promises = [];
        promises.push(this.venueApproveDeclineOnboardingFromPublicEntry({
          ...venueDistributorData,
          data: {
            status: 'approved',
            termsAndConditions: this.publicOnboarding.termsAndConditions,
          },
        }));
        await Promise.all(promises);
        if (this.publicOnboarding.login) {
          await this.initVenue();
          await this.switchContext(this.venue);
        }
        this.CLEAR_PUBLIC_ONBOARDING();
        flash.success({
          title: this.$t('venues.onboarding.flash.title'),
          message: this.$t('venues.onboarding.flash.message', {
            distributorName: this.distributor.name,
          }),
        });
        this.isLoaderShown = false;
        await this.$nextTick();
        this.CLEAR_LOGIN_PUBLIC_TOKEN();
        this.redirectToAppUrl();
      } catch (e) {
        flash.error({ title: 'Something went wrong' });
      } finally {
        this.isLoaderShown = false;
      }
    },
    switchContext(venue) {
      if (venue.id === this.contextId) {
        return;
      }

      try {
        this.SWITCH_CONTEXT(venue);
        flash.success({
          title: `Switched to ${venue.name}`,
        });
        this.EMPTY_CART();
        this.initVenue();
      } catch (e) {
        flash.fail({
          title: `Unable to switch to ${venue.name}'`,
        });
      }
    },
    ...mapMutations('cart', [
      'EMPTY_CART',
    ]),
    ...mapMutations('entities/users', [
      'SWITCH_CONTEXT',
    ]),
    ...mapActions('entities/venues', [
      'venueApproveDeclineOnboardingFromPublicEntry',
    ]),
    ...mapMutations('entities/venues', [
      'CLEAR_PUBLIC_ONBOARDING',
      'CLEAR_LOGIN_PUBLIC_TOKEN',
    ]),
    ...mapActions('entities/distributors', [
      'venueFetchSupplierSingle',
    ]),
    ...mapActions('entities/users', [
      'initVenue',
    ]),
  },
  mounted() {
    this.disableFooterActions();
    this.fetchSupplier();
    if (this.isTermsAccepted) {
      this.buttonType = 'green';
      this.enableNextStep();
    }
  },
};
</script>
<template>
  <div>
    <ez-loader :show="isLoaderShown || isLoading">
      {{ isLoading ? 'Downloading Terms & Conditions' : $t('venues.onboarding.loaderMessage') }}
    </ez-loader>
    <div class="terms-btn-container mb-24">
      <ez-button
        @click="readTermsAndConditions"
        :type="buttonType"
      >
        <template v-if="isTermsAccepted">
          <font-awesome-icon icon="check"/>
        </template>
        Read Terms & Conditions
      </ez-button>
    </div>
    <paper>
      <div class="venue">
        <VEntityInfo
          class="mb-16"
          imageSize="64px"
          :imageUrl="venue.logo"
          imageBorderRadius="50%"
          imageHasBorder />
        <p class="venue__name">{{ venue.name }}</p>
      </div>
    </paper>
    <paper>
      <template #title>Main Info</template>
      <template #actions>
        <ez-button @click="editVenue" type="link" formType="button">
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <div class="info-segment">
        <p class="info-segment__title">{{ $t('global.venue') }} Name</p>
        <p class="info-segment__info">{{ venue.name }}</p>
      </div>
      <div v-if="venue.legalName" class="info-segment mt-16">
        <p class="info-segment__title">Legal Name</p>
        <p class="info-segment__info">{{ venue.legalName }}</p>
      </div>
      <div v-if="venue.companyRegistrationNumber" class="info-segment mt-16">
        <p class="info-segment__title">Company Registration Number / Tax ID</p>
        <p class="info-segment__info">{{ venue.companyRegistrationNumber }}</p>
      </div>
      <div v-if="venue.customInfo" class="info-segment mt-16">
        <p class="info-segment__title">Additional Info</p>
        <p class="info-segment__info">{{ venue.customInfo }}</p>
      </div>
      <hr>
      <div class="info-segment mt-16">
        <p class="info-segment__title">ADDRESSES</p>
      </div>
      <div class="info-segment mt-16">
        <p class="info-segment__title">Delivery Address</p>
        <p class="info-segment__info">{{ addressFormat(shippingAddress) }}</p>
      </div>
      <div class="info-segment mt-16" v-if="billingAddress">
        <p class="info-segment__title">Billing Address</p>
        <p class="info-segment__info">{{ addressFormat(billingAddress) }}</p>
      </div>
    </paper>
    <paper>
      <template #title>Contacts</template>
      <template #actions>
        <ez-button @click="editContacts" type="link" formType="button">
          <font-awesome-icon icon="pen"/>
          <span>Edit</span>
        </ez-button>
      </template>
      <div class="contact-item">
        <div class="contact-item__name">{{ publicOnboarding.financeUser.name }}</div>
        <div class="contact-item__type">Finance Contact</div>
      </div>
      <div class="contact-item" v-if="hasOrderingUser">
        <div class="contact-item__name">{{ publicOnboarding.orderingUser.name }}</div>
        <div class="contact-item__type">Ordering Contact</div>
      </div>
    </paper>
    <tos-modal
      v-if="distributor"
      :distributor="distributor"
      ref="tos" />
  </div>
</template>
<style lang="scss" scoped>
.venue {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__name {
    margin: 0;
    @include font-size(16px, 19px);
    font-weight: 500;
    color: $color-gray-25;
  }
}
.info-segment {
  &__title {
    @include font-size(12px, 14px);
    color: $color-gray-6C;
    margin: 0 0 6px 0;
    font-weight: 500;
  }
  &__info {
    @include font-size(14px, 16px);
    color: #2B2D3E;
    margin: 0;
  }
}
.contact-item {
  @extend %flex-center;
  justify-content: space-between;
  @include font-size(14px, 16px);
  padding: 10px 0;
  &__name {
    color: $color-gray-25;
  }
  &__type {
    color: $color-gray-6C;
  }
}

.accepted-tos {
  @extend %flex-center;
  &__icon {
    color: $color-primary-green;
  }
  &__text {
    color: $color-gray-6C;
    @include font-size(14px, 16px);
    margin-left: 8px;
  }
}
.terms-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep() .loader {
  @extend %whole-screen;
}
</style>
