<template>
  <ez-simple-dropdown
    placeholder="Export"
    :direction="direction"
    class="export-button"
    :data-cy="dataCy"
  >
    <template #dropdown>
      <div class="btn-wrapper" v-for="type in exportTypesArr" :key="type.id">
        <ez-button @click="exportPdf(type.id)" type="link">
          <font-awesome-icon icon="file-pdf" />
          <span class="btn-txt">{{ type.text }}</span>
        </ez-button>
      </div>
      <div v-if="exportToAccounting" class="btn-wrapper">
        <ez-button @click="exportOrder" type="link">
          <img src="@/assets/icons/ic_export.svg" alt="Export to accounting integration" />
          <span class="btn-txt">Accounting Integration</span>
        </ez-button>
      </div>
    </template>
  </ez-simple-dropdown>
</template>

<script>
import { mapActions } from 'vuex';
import EzButton from '@/components/ui/Button';
import { CaseType, FILE_TYPE_PDF, FILE_TYPE_XLSX } from '@/util/constants';
import EzSimpleDropdown from '@/components/ui/Dropdown/EzSimpleDropdown.vue';
import { convertToSentenceCase } from '@/util/utils';

export default {
  components: {
    EzButton,
    EzSimpleDropdown,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    publicToken: {
      type: String,
    },
    direction: {
      type: String,
      default: 'up',
    },
    type: {
      type: String,
      validator: val => ['venue', 'distributor'].indexOf(val) !== -1,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
    exportToAccounting: {
      type: Boolean,
      required: false,
      default: false,
    },
    openPDF: {
      type: Boolean,
      required: false,
      default: false,
    },
    venueId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    exportTypesArr() {
      const types = this.order?.exportTypes;
      return types.map(ty => ({
        id: ty,
        text: convertToSentenceCase(ty, true, CaseType.SNAKE_CASE),
      }));
    },
  },
  methods: {
    exportPdf(type) {
      this.downloadFile(FILE_TYPE_PDF, type);
    },
    exportCsv() {
      this.downloadFile(FILE_TYPE_XLSX);
    },
    exportOrder() {
      this.$emit('exportOrder');
    },
    downloadFile(fileType, exportType) {
      const { order } = this;

      const config = {
        ...(this.publicToken && { 'X-Public-Token': this.publicToken }),
      };

      this.downloadOrder({
        id: order.id,
        fileType,
        publicOrder: !!this.publicToken,
        config,
        type: this.type,
        exportType,
        ...(this.venueId ? { venueId: this.venueId } : {}),
      }).then(({ data, headers }) => {
        let blob;
        if (fileType === FILE_TYPE_PDF) blob = new Blob([data], { type: 'application/pdf' });
        else blob = new Blob([data]);
        const filename = (headers['content-disposition'] || '').match(/filename="(.+)"/);
        const name = Array.isArray(filename) ? decodeURI(filename[1]) : `Orders.${this.type}`;

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = name;
        link.click();
        if (fileType === FILE_TYPE_PDF && this.openPDF) window.open(url);

        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      });
    },
    ...mapActions('entities/orders', ['downloadOrder']),
  },
};
</script>

<style scoped lang="scss">
$icon-color: #b2b8c4;
$text-color: #252631;

.export-button {
  display: inline-block;
  width: auto;

  .drp-subtitle {
    @include font-size(11px, 14px);
    letter-spacing: 0.15px;
    color: $color-gray-6C;
    font-weight: 600;
    margin-left: 14px;
    text-transform: uppercase;
  }
  :deep() .ez-simple-dropdown {
    &__label {
      padding-right: 15px;
    }

    &__dropdown {
      min-width: 160px;
      width: 100%;
      .btn-wrapper {
        > button {
          box-shadow: none;
          border-left: 0;
          opacity: 0.8;
          width: 100%;
          text-align: left;
          color: $color-gray-6C;

          img {
            width: 14px;
          }

          &:hover {
            opacity: 1;
          }

          .btn-txt {
            margin-left: 0.5rem;
            color: $text-color;
          }
        }
      }
    }
  }
}
</style>
