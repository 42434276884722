<template>
  <ez-user-list
    role="venue"
    :users="users"
    :columns="['name', 'email', 'role', 'accountName']"
    :headers="headers"
    :columnProps="{
      venue: { class: 'venue-cell' },
      role: { class: 'role-cell' },
    }"
    @userClick="onUserClick"
    @addUser="onAddUser"
    @removeUser="onRemoveUser">
    <ez-venue-user-modal
      ref="userModal"
      :roles="roles"
      :formKey="formKey"
      :formAction="formAction"
      :groupId="groupId"
      :userId="selectedUserId"></ez-venue-user-modal>
  </ez-user-list>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import EzUserList, { EzVenueUserModal } from '@/views/common/users';
import User from '@/models/User';

export default {
  components: {
    EzUserList,
    EzVenueUserModal,
  },
  data() {
    return {
      formKey: 'venue-group-user',
      headers: {
        accountName: () => this.$t('global.venue'),
      },
      selectedUserId: null,
      roles: [],
    };
  },
  computed: {
    users() {
      return User.query()
        .with('venue')
        .where('scope', ['venue-group', 'venue'])
        .all();
    },
    formAction() {
      return '/venue-groups/users';
    },
    groupId() {
      return this.loggedUser.group.id;
    },
    ...mapState('entities/users', [
      'loggedUser',
    ]),
  },
  methods: {
    onAddUser() {
      this.selectedUserId = -1;
      this.openModal();
    },
    async onUserClick({ id }) {
      await this.fetchVenueUser({ id, groupId: this.groupId });

      this.selectedUserId = id;
      this.openModal();
    },
    onRemoveUser(user, ownerId, replacementPrimaryUserId) {
      this.removeVenueUser({ id: user.id, replacementPrimaryUserId });
    },
    openModal() {
      this.$refs.userModal.open();
    },
    ...mapActions('entities/venues', [
      'fetchVenuesForGroup',
    ]),
    ...mapActions('entities/users', [
      'fetchVenueUsers',
      'fetchVenueUser',
      'removeVenueUser',
    ]),
    ...mapActions('roles', [
      'getVenueRoles',
    ]),
  },
  async mounted() {
    await this.fetchVenuesForGroup(this.groupId);
    await this.fetchVenueUsers();
    this.roles = await this.getVenueRoles();
  },
};
</script>

<style scoped lang="scss">

</style>
