<template>
  <order-container :editing-mode="editingMode" :is-quote="isQuote" :isPublicOrder="isPublicOrder">
    <template #distributor>
      <slot name="distributor"></slot>
    </template>

    <template #venue>
      <slot name="venue"></slot>
    </template>

    <template #amount> {{ orderTotal | price }}</template>

    <template v-if="!isQuote && !isPublicOrder && !hideOrderDate" #orderDateLabel>
      <div class="label single-order__delivery-label-container">
        <label :for="orderDateId">Order Date</label>
        <font-awesome-icon
          v-if="editingMode && isDistributor"
          :id="orderDateId"
          class="ml-8 cursor-pointer"
          icon="pen"
          @click="editOrderDate"
          :data-cy="''"
        />
      </div>
    </template>
    <template v-if="!isQuote && !isPublicOrder && !hideOrderDate" #requestedDate>{{
      order.orderedAt | date(dateFormat)
    }}</template>

    <template v-if="!isQuote && !hideDeliveryDate" #deliveryLabel>
      <div class="label single-order__delivery-label-container">
        <label :for="deliveryOnId">Delivery Date</label>
        <font-awesome-icon
          v-if="canEditDeliveryOn"
          :id="deliveryOnId"
          class="ml-8 cursor-pointer"
          icon="pen"
          @click="editDeliveryOn"
          :data-cy="commonCy.SINGLE_ORDER.ICON__EDIT_DELIVERY_DATE"
        />
      </div>
    </template>
    <template #deliveryOn v-if="!!deliveryValue && !isQuote && !hideDeliveryDate">
      {{ deliveryValue | date(dateFormat) }}
    </template>

    <template v-if="!isQuote && !isPublicOrder && !hideDueDate" #dueDateLabel>
      <div class="label single-order__delivery-label-container">
        <label :for="dueDateId">Due Date</label>
        <font-awesome-icon
          v-if="editingMode && isDistributor"
          :id="dueDateId"
          class="ml-8 cursor-pointer"
          icon="pen"
          @click="editDueDate"
          :data-cy="''"
        />
      </div>
    </template>
    <template #dueDate v-if="!isQuote && !isPublicOrder && !hideDueDate">
      <template v-if="dueDateValue">{{ dueDateValue | date(dateFormat) }}</template>
      <template v-else>-</template>
    </template>

    <template v-if="!isQuote && !isPublicOrder && showInvoiceDate" #invoiceDateLabel>
      <div class="label single-order__delivery-label-container">
        <label :for="invoiceDateId">Invoice Date</label>
        <font-awesome-icon
          v-if="editingMode && canEditInvoiceDate"
          :id="invoiceDateId"
          class="ml-8 cursor-pointer"
          icon="pen"
          @click="editInvoiceDate"
          :data-cy="''"
        />
      </div>
    </template>
    <template #invoiceDate v-if="!isQuote && !isPublicOrder && showInvoiceDate">
      <template v-if="invoiceDateValue">{{
        invoiceDateValue | dateRefactored(dateFormat)
      }}</template>
      <template v-else>-</template>
    </template>

    <template v-if="!isQuote && isPublicOrder" #orderNumber>{{
      order.orderNumber ?? '-'
    }}</template>
    <template v-if="!isQuote" #invoiceNumberLabel>
      <div class="label single-order__delivery-label-container">
        <label :for="invoiceNumberId">Invoice Number</label>
        <font-awesome-icon
          v-if="canEditInvoiceNumber"
          :id="invoiceNumberId"
          class="ml-8 cursor-pointer"
          icon="pen"
          @click="editInvoiceNumber"
          :data-cy="commonCy.SINGLE_ORDER.ICON__EDIT_DELIVERY_DATE"
        />
      </div>
    </template>
    <template v-if="!isQuote" #invoiceNumber>{{ order.invoiceNumber ?? '-' }}</template>

    <template v-if="isDistributor && !isQuote" #driverLabel>
      <div class="label single-order__delivery-label-container">
        <label :for="driverId">Driver</label>
        <font-awesome-icon
          v-if="editingMode"
          :id="driverId"
          class="ml-8 cursor-pointer"
          icon="pen"
          @click="editDriver"
          :data-cy="''"
        />
      </div>
    </template>
    <template v-if="isDistributor && !isQuote" #driver>
      {{ order.driver?.name ? order.driver?.name : '-' }}
    </template>

    <template v-if="isDistributor && !isQuote && !isPublicOrder" #poNumberLabel>
      <div class="label single-order__delivery-label-container">
        <label :for="venueInvoiceNumberId">Reference Number</label>
        <font-awesome-icon
          v-if="editingMode && isDistributor"
          :id="venueInvoiceNumberId"
          class="ml-8 cursor-pointer"
          icon="pen"
          @click="editVenueInvoiceNumber"
          :data-cy="''"
        />
      </div>
    </template>
    <template v-if="!isQuote && !isPublicOrder" #poNumber>
      {{ order.venueOrderNumber ?? '-' }}
    </template>

    <template
      v-if="isDistributor && order.fulfillmentWarehouse?.name && !isQuote && !isPublicOrder"
      #fulfilmentWarehouse
    >
      {{ order.fulfillmentWarehouse?.name }}
    </template>

    <template v-if="isDistributor && order.distributorAccountOwner?.name && !isPublicOrder" #accRep>
      {{ order.distributorAccountOwner?.name }}
    </template>

    <template #quoteDate>{{ order.quotedAt | date(dateFormat) }}</template>

    <template #quoteDeadline>
      <template v-if="order.quoteEndDate">{{ order.quoteEndDate | date(dateFormat) }}</template>
      <template v-else>-</template>
    </template>

    <template>
      <component
        :is="orderTabs"
        :order="order"
        :enableEdit="isEditable"
        :hasDeclinedIcon="hasDeclinedIcon"
        :isPublicOrder="isPublicOrder"
        :isSimpleOrder="isSimpleOrder"
        :invoiceAction="invoiceAction"
        :editingMode="editingMode"
        :token="token"
        :type="type"
        :is-quote="isQuote"
        @productClick="onProductClick"
        @invoiceUploaded="ev => $emit('invoiceUploaded', ev)"
        @isSomeUnavailable="e => $emit('isSomeUnavailable', e)"
        @productsAdded="e => $emit('productsAdded', e)"
        @productExcluded="e => $emit('productExcluded', e)"
        @productDeleted="e => $emit('productDeleted', e)"
        @productUpdated="e => $emit('productUpdated', e)"
        @imageModalOpened="openDrawerImageModal"
      />
      <ez-drawer-image-modal ref="drawerImageModal" />
    </template>
  </order-container>
</template>

<script>
import OrderContainer from '@/views/common/orders/OrderContainer.vue';
import { DefaultTabs, PartiallyAcceptedTabs } from '@/views/common/orders/tabs';
import { ORDER_STATUS_QUOTE, PARTIALLY_ACCEPTABLE } from '@/util/constants';
import { COMMON as commonCy } from '@weareneopix/qa-utils/dist/orderEz/common';
import { toShowPartiallyAcceptedTabs } from '@/util/utils';
import { EzDrawerImageModal } from '@/components/ui/Modal';
import uuid from 'uuid/v4';
import { mapGetters } from 'vuex';

export default {
  components: {
    OrderContainer,
    EzDrawerImageModal,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    hasDeclinedIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPublicOrder: {
      type: Boolean,
      required: false,
    },
    isSimpleOrder: {
      type: Boolean,
      required: false,
      default: false,
    },
    invoiceAction: {
      required: false,
    },
    token: {
      required: false,
    },
    editingMode: {
      type: Boolean,
      required: false,
    },
    type: {
      type: String,
    },
    canEditDeliveryOn: {
      type: Boolean,
      required: false,
      default: false,
    },
    canEditInvoiceNumber: {
      type: Boolean,
      required: false,
      default: false,
    },
    canEditInvoiceDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideOrderDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDeliveryDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDueDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    showInvoiceDate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      deliveryOnId: uuid(),
      dueDateId: uuid(),
      venueInvoiceNumberId: uuid(),
      driverId: uuid(),
      orderDateId: uuid(),
      invoiceNumberId: uuid(),
      invoiceDateId: uuid(),
      commonCy,
    };
  },
  computed: {
    ...mapGetters('entities/users', ['isDistributor']),
    /**
     * For which status the order is editable.
     * @return {boolean}
     */
    isEditable() {
      return PARTIALLY_ACCEPTABLE.includes(this.order.status);
    },
    orderTabs() {
      const { orderedProducts } = this.order;

      if (toShowPartiallyAcceptedTabs(orderedProducts)) {
        return PartiallyAcceptedTabs;
      }

      return DefaultTabs;
    },
    deliveryValue() {
      return this.order.expectedAt;
    },
    dueDateValue() {
      return this.order.dueDate;
    },
    invoiceDateValue() {
      return this.order.invoiceDate;
    },
    orderTotal() {
      return this.order.amount;
    },
    hasNotMatchingFlag() {
      return this.order.notMatching;
    },
    isOutlet() {
      return !!this.$slots.distributor;
    },
    dateFormat() {
      if (!this.isPublicOrder) return null;
      if (this.isOutlet) return this.order.venue?.dateFormat;
      return this.order.distributor?.dateFormat;
    },
    isQuote() {
      return this.order.status === ORDER_STATUS_QUOTE;
    },
  },
  methods: {
    onProductClick(product) {
      this.$emit('productClick', product);
    },
    editDeliveryOn() {
      this.$emit('editDeliveryOn');
    },
    editInvoiceNumber() {
      this.$emit('editInvoiceNumber');
    },
    editOrderDate() {
      this.$emit('editOrderDate');
    },
    editDueDate() {
      this.$emit('editDueDate');
    },
    editInvoiceDate() {
      this.$emit('editInvoiceDate');
    },
    editVenueInvoiceNumber() {
      this.$emit('editVenueInvoiceNumber');
    },
    editDriver() {
      this.$emit('editDriver');
    },
    openDrawerImageModal(invoice) {
      this.$refs.drawerImageModal.open(invoice);
    },
    closeDrawerImageModal() {
      this.$refs.drawerImageModal.close();
    },
  },
  watch: {
    editingMode(val) {
      if (val && this.hasNotMatchingFlag && this.order.invoices.length && !this.isPublicOrder) {
        this.openDrawerImageModal(this.order.invoices[0]);
      } else {
        this.closeDrawerImageModal();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.single-order__delivery-label-container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
</style>
