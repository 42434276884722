<template>
  <div class="shopping-cart__sidebar">
    <ez-dropdown
      name="type"
      :formKey="formKey"
      label="Type of Order"
      :data="orderTypes"
      @change="changeOrderType"
      isFullWidth
      :data-cy="outletCy.SHOPPING_CART.BUTTON__ORDER_TYPE"
    />

    <template v-if="isStandingOrder">
      <v-date-picker
        class="date-picker mt-8"
        name="standingDate"
        label="Order Start Date"
        v-model="dateStandingScheduled"
        :formKey="formKey"
        :min="minScheduledStanding"
        @dateChange="updateStandingDate"
        :data-cy="outletCy.SHOPPING_CART.BUTTON__STANDING_DATE"
      />
      <ez-input
        class="mt-8"
        :formKey="formKey"
        label="Repeat Interval in Days"
        name="interval"
        @onChange="updateInterval"
        :error-msg="errorMsgInterval"
        :min="1"
        validators="required|integer|min:1"
        type="number"
        :data-cy="outletCy.SHOPPING_CART.INPUT__REPAT_INTERVAL"
      />
    </template>
    <template v-if="isScheduledOrder">
      <v-date-picker
        v-if="selectedScheduled"
        class="date-picker mt-8"
        name="month"
        label="Requested Delivery Date"
        v-model="selectedScheduled"
        :formKey="formKey"
        :min="minScheduled"
        :deliveryDays="deliveryDays"
        @dateChange="updateDate"
        :data-cy="outletCy.SHOPPING_CART.BUTTON__DELIVERY_DATE"
      />
      <p v-if="selectedScheduled && items.length > 1" class="schedule-note mt-8">
        Delivery availability is determined by the most restrictive
        {{ $t('global.distributor') | lowercase }} in your order.
      </p>
    </template>
    <div v-if="items.length">
      <div class="shopping-cart__grand-total">
        <small>Grand total</small>
        <span>
          <v-price
            :price="total"
            :show-market-price-info="false"
            :is-to-be-determined="isTbd"
            :data-cy="outletCy.SHOPPING_CART.TEXT__GRAND_TOTAL"
          />
        </span>
      </div>
      <ez-button
        v-if="isStandingOrder"
        :disabled="disabled || quantityInvalid"
        @click="onSubmitOrderClick"
        is-full-width
        :data-cy="outletCy.SHOPPING_CART.BUTTON__SUBMIT_ORDER_REQUEST"
      >
        Submit Order Request
      </ez-button>
      <ez-button-dropdown
        v-else
        @click="onSubmitOrderClick"
        :disabled="disabled || quantityInvalid"
        is-full-width
        :data-cy="outletCy.SHOPPING_CART.BUTTON__SUBMIT_ORDER_REQUEST"
      >
        <template>Submit Order Request</template>
        <template #icon>
          <font-awesome-icon icon="angle-down" />
        </template>
        <template #dropdown>
          <ez-button @click="onSubmitDraftClick" type="secondary">Save as Draft</ez-button>
        </template>
      </ez-button-dropdown>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import EzDropdown from '@/components/ui/Dropdown';
import EzButton from '@/components/ui/Button';
import {
  DATE_PLACEHOLDER,
  DELIVERY_PERIODS,
  ORDER_TYPE_SCHEDULED,
  orderTypes,
  TIME_PLACEHOLDER,
} from '@/util/constants';
import EzInput from '@/components/ui/Input';
import { formatErrorDate } from '@/util/utils';
import VDatePicker from '@/components/v3/patterns/VDatePicker';
import VPrice from '@/components/v3/elements/VPrice';
import EzButtonDropdown from '@/components/ui/ButtonDropdown/EzButtonDropdown.vue';

/**
 * Shopping cart sidebar
 * @version 1.0.0
 * @since 2.0.0
 */
export default {
  components: {
    EzInput,
    EzDropdown,
    EzButton,
    VDatePicker,
    VPrice,
    EzButtonDropdown,
  },
  props: {
    total: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    intervalError: {
      type: String,
      required: false,
      default: '',
    },
    quantityInvalid: {
      type: Boolean,
      required: false,
      default: false,
    },
    isTbd: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      orderTypes,
      selectedOrderType: ORDER_TYPE_SCHEDULED,
      interval: null,
      time: null,
      date: null,
      dateStandingScheduled: this.todayDate,
      formKey: 'shoppingCart',
      datePlaceholder: DATE_PLACEHOLDER,
      timePlaceholder: TIME_PLACEHOLDER,
      tomorrowDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      todayDate: dayjs().format('YYYY-MM-DD'),
      errorMsgDate: '',
      errorMsgInterval: '',
      minScheduled: dayjs().subtract(1, 'day'),
      minScheduledStanding: dayjs().subtract(1, 'day'),
      selectedScheduled: null,
      periods: DELIVERY_PERIODS,
      disabled: false,
      outletCy,
    };
  },
  computed: {
    ...mapState('entities/users', ['contextId']),
    isStandingOrder() {
      return this.selectedOrderType === 'standing';
    },
    isScheduledOrder() {
      return this.selectedOrderType === 'scheduled' || this.selectedOrderType === 'draft';
    },
    distributors() {
      return this.items.map(item => item.distributor);
    },
    distributorsIds() {
      return this.items.map(item => item.distributor.id);
    },
    deliveryDays() {
      const deliveryDaysSet = [];
      this.distributors.forEach(distributor => {
        if (distributor.deliveryDays?.length) deliveryDaysSet.push(distributor.deliveryDays);
      });
      if (deliveryDaysSet.length)
        return deliveryDaysSet.reduce((a, b) => a.filter(c => b.includes(c)));
      return deliveryDaysSet;
    },
  },
  methods: {
    ...mapMutations('cart', ['UPDATE_CART_TYPE', 'UPDATE_CART_STANDING', 'UPDATE_CART_DATETIME']),
    ...mapActions('entities/venues', ['venueFetchDeliveryDate']),
    onSubmitOrderClick() {
      this.$emit('submitOrder');
    },
    onSubmitDraftClick() {
      this.selectedOrderType = 'draft';
      this.$emit('submitDraftOrder');
    },
    changeOrderType(type) {
      this.disabled = false;
      this.selectedOrderType = type.id;
      if (this.isScheduledOrder) {
        this.setScheduled();
      }
      if (this.isStandingOrder) {
        this.interval = null;
        this.clearStandingDate();
      }
    },
    clearStandingDate() {
      const currentTime = new Date().getTime();
      this.dateStandingScheduled = this.todayDate;
      this.UPDATE_CART_STANDING({
        interval: this.interval,
        date: this.dateStandingScheduled,
        time: currentTime,
      });
    },
    updateStandingDate(val) {
      this.dateStandingScheduled = val.format('YYYY-MM-DD');
      const currentTime = new Date().getTime();
      this.UPDATE_CART_STANDING({
        interval: this.interval,
        date: this.dateStandingScheduled,
        time: currentTime,
      });
    },
    updateInterval(val) {
      this.disabled = !Number.isInteger(Number(val)) || !val || val <= 0;
      if (val >= 0) this.errorMsgInterval = null;
      this.interval = val;
      const currentTime = new Date().getTime();
      this.UPDATE_CART_STANDING({
        interval: this.interval,
        date: this.dateStandingScheduled,
        time: currentTime,
      });
    },
    updateDate(val) {
      this.date = val.format('YYYY-MM-DD');
      const dateObj = dayjs(val);
      const todayObj = dayjs(this.todayDate);
      const todayStr = formatErrorDate(todayObj);

      const errorMsg = `This date must be after ${todayStr}`;
      const showErrorMsg = dateObj.isAfter(todayObj);
      this.errorMsgDate = showErrorMsg ? errorMsg : '';
      const currentTime = new Date().getTime();

      this.UPDATE_CART_DATETIME({ date: this.date, time: currentTime });
    },
    async setScheduled() {
      const currentTime = new Date().getTime();
      const {
        data: { meta },
      } = await this.venueFetchDeliveryDate({
        venueId: this.contextId,
        distributors: this.distributorsIds,
      });
      this.selectedScheduled = dayjs(meta.date);
      this.UPDATE_CART_DATETIME({
        date: this.selectedScheduled.format('YYYY-MM-DD'),
        time: currentTime,
      });
    },
  },
  created() {
    this.setScheduled();
  },
  watch: {
    selectedOrderType: {
      immediate: true,
      handler() {
        this.UPDATE_CART_TYPE(this.selectedOrderType);
      },
    },
    intervalError(val) {
      this.errorMsgInterval = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.shopping-cart {
  &__sidebar {
    flex: 1 1 auto;
    max-width: 280px;
    border: 1px solid $color-gray-E9;
    border-radius: $border-radius;
    box-shadow: 0 1px 1px 0 #dee1e4;
    padding: px-to-rem(16px);
  }
  &__grand-total {
    @extend %flex-center;
    justify-content: space-between;
    border-top: 1px dotted #dee1e4;
    padding-top: px-to-rem(20px);
    margin-bottom: px-to-rem(20px);
    margin-top: px-to-rem(16px);

    small {
      color: $color-gray-6C;
      text-transform: uppercase;
      @include font-size(14px);
      font-weight: 500;
    }
    span {
      color: $color-gray-25;
      @include font-size(20px);
      font-weight: bold;
      :deep() .v-price__price {
        @include font-size(20px);
      }
    }
  }
  .date-picker {
    :deep() .ez-simple-dropdown__display-container {
      width: 100%;
      .placeholder {
        width: 100%;
      }
    }
  }
  .schedule-note {
    @include font-size(12px, 18px);
    color: $color-gray-6C;
  }
  button {
    height: 36px;
  }
}

.input-group + .input-group {
  margin-top: px-to-rem(16px);
}

:deep() .button-dropdown--primary {
  width: 100%;
  .button-dropdown__toggle-container {
    width: 100%;
    .button.button--primary:first-child {
      width: 100%;
    }
  }
}

:deep() .button-dropdown__dropdown {
  .button.button--secondary {
    background-color: #fff;

    &:hover {
      background-color: #f5f6fa;
    }
  }
}
</style>
