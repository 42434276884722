<script>
/**
 * Overview
 * @version 1.0.0
 * @since 2.3.0
 */

import { mapState } from 'vuex';
import EzNav from '@/components/ui/Nav';
import Header from '@/views/platform/venue/orders/Header.vue';

export default {
  data() {
    return {
      stats: {},
    };
  },
  components: {
    EzNav,
    Header,
  },
  computed: {
    ...mapState('entities/users', ['context']),
  },
  methods: {
    updateStats(stats) {
      this.stats = stats;
    },
  },
};
</script>

<template>
  <div class="venue-overview">
    <Header
      noCount
      :amount="stats.totalAmount"
      :to-show-invoices="!$permission.isFree"
      totalIcon="money-bill-wave"
      invoicesIcon="file-invoice-dollar"
      invoicesIconVariant="blue"
      invoicesLabel="Invoice pages remaining"
      :invoicesNumber="stats.pendingInvoicesRemaining"
      showLabel
      totalIconVariant="green"
      :totalIconTooltip="{}"
    >
      <template #title>Overview</template>
      <template #total>Total invoiced this month</template>
    </Header>
    <ez-nav>
      <router-view @updateStats="updateStats" />
    </ez-nav>
  </div>
</template>

<style lang="scss" scoped>
.venue-overview {
  @include platform-tab-content();
}

.venue-overview :deep() .order-header {
  .order-header__stat:not(.order-header__stat--invoices) {
    flex-direction: column-reverse;

    small {
      text-transform: initial;
    }
  }

  .order-header__group {
    display: flex;
    flex-direction: row-reverse;
    .order-header__stats:first-child:not(:last-child) {
      padding-left: 16px;
      border-left: 1px solid #eceef5;
    }
    .order-header__stats + .order-header__stats {
      padding-right: 16px;
    }
  }
}
:deep() .navigation .navigation__nav {
  display: none;
}
</style>
