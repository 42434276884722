<template>
  <div class="layout-tab">
    <slot name="banner"></slot>
    <nav :class="{ 'layout-tab__navigation': true, disabled: disabled }">
      <div class="layout-tab__container">
        <div class="layout-tab__logo">
          <router-link :to="{ name: 'platform-login' }">
            <img
              v-if="oezPlatformName === 'Foodrazor'"
              src="@/assets/Foodrazor-logo.svg"
              alt="Foodrazor logo"
            />
            <img v-else src="@/assets/logo-dark.svg" alt="EZ logo" />
          </router-link>
        </div>
        <slot name="nav"></slot>
        <div class="layout-tab__chart-account">
          <div class="layout-tab__cart-button">
            <slot name="cart"></slot>
          </div>
          <slot name="accountMenu"></slot>
        </div>
      </div>
    </nav>
    <div :class="{ 'layout-tab__content': true, disabled: disabled }">
      <div class="layout-tab__container">
        <ez-alert v-if="isUploadLoading" type="blue">
          <template #icon><ez-spinner /></template>
          <div>Invoice upload is in progress. Please do not close this window.</div>
        </ez-alert>
        <ez-alert v-if="isBulkOrderUploadLoading" type="blue">
          <template #icon><ez-spinner /></template>
          <div>
            Exporting orders to accounting integration is in progress. Please do not close this
            window.
          </div>
        </ez-alert>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LOADING_KEY } from '@/util/constants';
import EzAlert from '../ui/Alert/EzAlert.vue';
import EzSpinner from '../ui/Spinner/EzSpinner.vue';

export default {
  components: {
    EzAlert,
    EzSpinner,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    ...mapGetters('loading', ['getLoading']),
    oezPlatformName() {
      return process.env.VUE_APP_PLATFORM_TITLE;
    },
    isUploadLoading() {
      return this.getLoading(LOADING_KEY.LOG_OFFLINE_ORDER);
    },
    isBulkOrderUploadLoading() {
      return this.getLoading(LOADING_KEY.BULK_EXPORT_ORDERS);
    },
  },
};
</script>

<style scoped lang="scss">
$tab-text-color: $color-gray-6C;
$active-tab-border-color: #4d7cfe;
$active-tab-text-color: $color-gray-25;
$separator-color: #e9ebed;
$item-count-color: #ffffff;
$terms-height: 64px;

.layout-tab {
  .disabled {
    opacity: 0.2;
    pointer-events: none;
    overflow: hidden;
  }
  @include font-size(14px);
  display: flex;
  flex-direction: column;
  min-width: 768px;
  width: 100%;
  height: 100%;

  &__container {
    max-width: 1280px;
    margin: 0 auto;
  }

  &__navigation {
    @extend %flex-center;
    background-color: #fff;
    border-bottom: 1px solid $color-gray-E9;
    padding: 0 3.5rem;
    position: relative;
    min-height: 3.5rem;
    .layout-tab__container {
      display: flex;
      align-items: stretch;
      flex: 1 0 auto;
      width: 100%;
      > a {
        @extend %flex-center;
      }
    }

    .layout-tab__logo {
      @extend %flex-center;
      > a {
        display: block;
        height: 1.5rem;
        padding-right: 1.5rem;
        border-right: 1px solid $separator-color;
        img {
          height: 100%;
          max-width: 100%;
        }
      }
    }

    ul {
      margin: 0 0 0 1.5rem;
      padding: 0;
      list-style: none;
      display: flex;
      position: relative;
      top: 1px;
      height: 54px;

      li {
        @extend %flex-center;
        + li {
          margin-left: 1.5rem;
        }

        a {
          @extend %flex-center;
          height: 100%;
          color: $tab-text-color;
          font-weight: 400;
          text-decoration: none;
          border-top: 0.125rem solid rgba(255, 255, 255, 0);
          border-bottom: 0.125rem solid rgba(255, 255, 255, 0);

          &.router-link-active {
            border-bottom: 0.125rem solid $active-tab-border-color;
            color: $active-tab-text-color;
          }
        }

        .padding-badge {
          width: 8px;
          height: 8px;
          margin-right: 0.5rem;
          border-radius: 50%;
          background-color: #e4b102;
          align-self: center;
        }
      }
    }
  }

  &__cart-button {
    @include font-size(20px);
    color: rgba($color-gray-6C, 0.48);
  }

  &__content {
    flex: 1 1 auto;
    padding: 1.5rem 3.5rem 5.5rem 3.5rem;
    overflow-y: auto;
    .layout-tab__container {
      display: flex;
      flex-direction: column;
      > main {
        flex: 1 0 auto;
      }
    }
  }

  &__chart-account {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__footer {
    @include font-size(16px, 16px);
    @extend %flex-center;
    justify-content: center;
    padding: 1.5rem 0;
    flex: 0 0 auto;
  }
}

@media (max-width: 768px) {
  .layout-tab {
    &__logo {
      margin-right: 12px;
    }
    .layout-tab__content,
    .layout-tab__navigation {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .footer {
    padding-left: 24px;
    padding-right: 24px;
  }
}
</style>
