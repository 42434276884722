<template>
  <div class="shopping-cart">
    <div class="shopping-cart__container">
      <div class="shopping-cart__nav">
        <ez-button type="link" @click="goToProducts">
          <font-awesome-icon icon="arrow-left" />
          Back
        </ez-button>
        <h1 class="u-flex-center">
          Shopping Cart
          <ez-badge
            type="blue"
            :count="itemsCount"
            :data-cy="outletCy.SHOPPING_CART.TEXT__PRODUCTS_IN_CART"
          />
        </h1>
      </div>
      <div class="shopping-cart__content">
        <div class="shopping-cart__distributor" :key="id" v-for="(record, id) in cartItems">
          <ez-entity-info :imgUrl="record.distributor.logo" imgBorderRadius="50%">
            <div class="distributor-info" :data-cy="outletCy.SHOPPING_CART.TEXT__SUPPLIER_NAME">
              {{ record.distributor.name }}
            </div>
          </ez-entity-info>
          <ez-table
            :columns="['product', 'unit', 'quantity', 'amount', 'actions']"
            :columnProps="{
              product: { class: 'product-cell' },
              amount: { class: 'price-cell' },
              unit: { class: 'medium-cell unit-cell' },
              quantity: { class: 'quantity-cell' },
            }"
            :rowCls="getRowClass"
            :headers="headers"
            :data="record.products"
            :loading="updatingQuantity"
          >
            <template #cell-product="{ row }">
              <ez-entity-info :imgUrl="row.product.image">
                <div class="product-info" :title="row.product.name">
                  <span
                    :data-cy="`${outletCy.SHOPPING_CART.TEXT__PRODUCT_NAME}-${record.distributor.id}-${row.product.id}`"
                  >
                    {{ row.product.name }}
                  </span>
                  <span class="product-info-secondary">
                    <v-price
                      class="mr-2"
                      flex-row
                      :price="row.product.price || 0"
                      :currency="row.product.currency"
                      :is-market-price="row.product.marketPrice && !row.isBonus"
                      :show-market-price-long="true"
                      :show-market-price-info="false"
                      :unit="row.product.priceUnit ? getProductUnitLabel(row.product) : null"
                      :type="V_PRICE_TYPE.SECONDARY"
                      :data-cy="`${outletCy.SHOPPING_CART.TEXT__PRODUCT_PRICE_PER_UNIT}-${record.distributor.id}-${row.product.id}`"
                    />
                    <span
                      v-if="row.product.sku"
                      :data-cy="`${outletCy.SHOPPING_CART.TEXT__PRODUCT_SKU}-${record.distributor.id}-${row.product.id}`"
                    >
                      &bull; {{ row.product.sku }}
                    </span>
                  </span>
                </div>
              </ez-entity-info>
            </template>
            <template
              #cell-unit="{
                row: {
                  product: { orderingUnit },
                },
              }"
            >
              <template v-if="orderingUnit">
                <v-data-with-info
                  :info="
                    orderingUnit.abbreviation
                      ? `${orderingUnit.name} ${
                          !!$t(`product.unitInfo.${orderingUnit.type}`) ? '-' : ''
                        }
                      ${$t(`product.unitInfo.${orderingUnit.type}`)}`
                      : ''
                  "
                  :show-underline="
                    !!$t(`product.unitInfo.${orderingUnit.type}`) || !!orderingUnit.abbreviation
                  "
                >
                  {{ orderingUnit.label }}
                </v-data-with-info>
              </template>
            </template>
            <template #cell-quantity="{ row: { quantity, product, internalId, isBonus } }">
              <ez-mask-input-simple
                name="quantity"
                :has-currency="false"
                formKey=""
                :minValue="product.minimumQuantity || 0"
                :maxValue="productQuantityMaxValue(product)"
                :value="quantity"
                :disabled="product.customerInventory.outOfStock || isBonus"
                @input="onQuantityChange($event, record.distributor, product, isBonus)"
                :precision="isFractional(product.orderingUnit) ? 2 : 0"
                @invalid="onQuantityInvalid($event && !isBonus, internalId)"
                :data-cy="`${outletCy.SHOPPING_CART.TEXT__PRODUCT_QUANTITY}-${record.distributor.id}-${product.id}`"
              />
            </template>
            <template #cell-amount="{ row }">
              <v-price
                :price="row.price"
                :currency="row.product.currency"
                :show-market-price-info="false"
                :is-to-be-determined="isToBeDetermined(row.product, row.isBonus)"
                :data-cy="`${outletCy.SHOPPING_CART.TEXT__PRODUCT_AMOUNT}-${record.distributor.id}-${row.product.id}`"
              />
              <span
                v-if="row.isBonus"
                class="additional-info additional-info--bonus"
                v-tooltip="{
                  content: tradeDealTooltipContent(row.product),
                  classes: ['tooltip--reset-margin'],
                }"
                >Bonus</span
              >
            </template>
            <template #cell-actions="{ row, row: { product, internalId, bonusId } }">
              <button
                class="remove-item"
                @click="onRemoveItemClick(record.distributor, product, internalId, bonusId)"
                :data-cy="`${outletCy.SHOPPING_CART.BUTTON__REMOVE_PRODUCT}-${record.distributor.id}-${row.product.id}`"
              >
                <font-awesome-icon icon="times" />
              </button>
            </template>
          </ez-table>
          <div class="shopping-cart__distributor--footer">
            <ShoppingCartNote :record="record" />
            <div class="shopping-cart__distributor--footer-total">
              <div class="shopping-cart__footer-item">
                <strong class="shopping-cart__footer-label">Subtotal</strong>
                <span class="shopping-cart__footer-number">
                  <v-price
                    :price="record.distributorSubtotal"
                    :show-market-price-info="false"
                    :is-to-be-determined="record.showTbdBanner || record.showMpBanner"
                    :data-cy="`${outletCy.SHOPPING_CART.TEXT__SUPPLIER_SUBTOTAL}-${record.distributor.id}`"
                  />
                </span>
              </div>
              <div
                v-if="$helpers.isPremium(record.distributor.accountType)"
                class="shopping-cart__footer-item"
              >
                <strong class="shopping-cart__footer-label"> Delivery Fee </strong>
                <span
                  class="shopping-cart__footer-number"
                  :data-cy="`${outletCy.SHOPPING_CART.TEXT__SUPPLIER_DELIVERY_FEE}-${record.distributor.id}`"
                >
                  +{{ record.deliveryFee | price }}
                </span>
              </div>
              <div class="shopping-cart__footer-item">
                <strong class="shopping-cart__footer-label">
                  Tax{{ record.isTaxUniform ? ` (${record.distributor.tax}%)` : '' }}
                </strong>
                <span
                  class="shopping-cart__footer-number"
                  :data-cy="`${outletCy.SHOPPING_CART.TEXT__SUPPLIER_TAX}-${record.distributor.id}`"
                >
                  +{{
                    (record.showTbdBanner || record.showMpBanner) && record.distributor.tax > 0
                      ? $t('global.tbd')
                      : $helpers.formatPrice(record.distributorTax)
                  }}
                </span>
              </div>
              <div class="shopping-cart__footer-item">
                <strong class="shopping-cart__footer-label shopping-cart__footer-label--big">
                  Total
                </strong>
                <span class="shopping-cart__footer-number shopping-cart__footer-number--big">
                  <v-price
                    :price="record.distributorTotal"
                    :show-market-price-info="false"
                    :is-to-be-determined="record.showTbdBanner || record.showMpBanner"
                    :data-cy="`${outletCy.SHOPPING_CART.TEXT__SUPPLIER_TOTAL}-${record.distributor.id}`"
                  />
                </span>
              </div>
            </div>
          </div>
          <ez-alert
            variant="disclaimer"
            v-if="record.showBanner"
            :data-cy="`${outletCy.SHOPPING_CART.TEXT__WARNING_MOA}-${record.distributor.id}`"
          >
            <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
            <template
              >Minimum order amount is not met. To avoid
              <strong>{{ record.deliveryFee | price }}</strong> delivery fee, increase the order
              amount to <strong>{{ record.distributor.minimumOrderAmount | price }}</strong> or
              above.
            </template>
          </ez-alert>
          <ez-alert
            variant="disclaimer"
            v-if="record.showMpBanner && !record.showTbdBanner"
            :data-cy="`${outletCy.SHOPPING_CART.TEXT__WARNING_MARKET_PRICE}-${record.distributor.id}`"
          >
            <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
            <template>
              Product with market price is included in this order. Market prices affect the total
              amount, subtotal and taxes.
            </template>
          </ez-alert>
          <ez-alert
            variant="disclaimer"
            v-if="record.showTbdBanner && !record.showMpBanner"
            :data-cy="`${outletCy.SHOPPING_CART.TEXT__WARNING_FRACTIONAL_UNIT}-${record.distributor.id}`"
          >
            <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
            <template>
              Product with fractional unit is included in this order. Final total amount may vary
              based on the actual delivered quantity.
            </template>
          </ez-alert>
          <ez-alert
            variant="disclaimer"
            v-if="record.showMergedBanner"
            :data-cy="`${outletCy.SHOPPING_CART.TEXT__WARNING_MP_AND_FU}-${record.distributor.id}`"
          >
            <template #icon><font-awesome-icon icon="exclamation-circle" /></template>
            <template>
              This order contains products with market prices and fractional units which may affect
              the total amount (incl. subtotal and taxes) based on the actual delivered quantity.
            </template>
          </ez-alert>
        </div>
      </div>
      <div v-if="!cartItems.length" class="shopping-cart__empty">
        <div class="shopping-cart__empty--info">
          <img src="@/assets/cart-empty-state.svg" alt="No orders" />
          <div>
            <span>No products in your cart</span>
            <small>Start adding products to create your order.</small>
          </div>
        </div>
        <ez-button @click="goToProducts" :data-cy="outletCy.SHOPPING_CART.BUTTON__GO_TO_PRODUCTS">
          Go to products
        </ez-button>
      </div>
      <ShoppingCartSidebar
        v-if="cartItems.length"
        :total="grandTotal"
        :is-tbd="isSomeTbd"
        :items="cartItems"
        :interval-error="intervalError"
        :quantity-invalid="isSomeQuantityErr"
        @submitOrder="onSubmitOrderClick"
        @submitDraftOrder="onSubmitOrderClick"
      />
    </div>
    <ez-confirmation-modal ref="orderConfirmation">
      <template #title>{{ $t('shoppingCart.confirmationModal.title') }}</template>
      <template #content>
        <p>{{ $t('shoppingCart.confirmationModal.info') }}</p>
      </template>
      <template #footer>
        <ez-button
          @click="onCancelSubmit"
          type="link"
          :data-cy="outletCy.SHOPPING_CART.BUTTON__CONFIRM_ORDER_CANCEL"
        >
          Cancel
        </ez-button>
        <ez-button
          :is-loading="loading"
          @click="onConfirmSubmit"
          :data-cy="outletCy.SHOPPING_CART.BUTTON__CONFIRM_ORDER_SUBMIT"
        >
          Submit
        </ez-button>
      </template>
    </ez-confirmation-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { outlet as outletCy } from '@weareneopix/qa-utils/dist/orderEz/outlet';
import flash from '@/components/ui/FlashMessage';
import EzButton from '@/components/ui/Button';
import { EzConfirmationModal } from '@/components/ui/Modal';
import EzEntityInfo from '@/components/ui/EntityInfo';
import EzTable from '@/components/ui/Table';

import { debounce, formatDate, getCurrency, pluralize } from '@/util/utils';
import EzBadge from '@/components/ui/Badge';
import ShoppingCartSidebar from '@/views/platform/venue/ShoppingCartSidebar.vue';
import ShoppingCartNote from '@/views/platform/venue/ShoppingCartNote.vue';
import EzAlert from '@/components/ui/Alert';
import { calculateDeliveryFee } from '@/util/deliveryFee';
import { getSum, getTaxFee, getTotalWithTax } from '@/util/utilsFinCalculator';
import VPrice from '@/components/v3/elements/VPrice';
import { UNIT_TYPE_FRACTIONAL, V_PRICE_TYPE } from '@/util/constants';
import VDataWithInfo from '@/components/v3/elements/VDataWithInfo';
import EzMaskInputSimple from '@/components/ui/MaskInputSimple/EzMaskInputSimple.vue';

export default {
  components: {
    EzAlert,
    ShoppingCartNote,
    EzBadge,
    EzButton,
    EzConfirmationModal,
    EzEntityInfo,
    EzTable,
    ShoppingCartSidebar,
    VPrice,
    VDataWithInfo,
    EzMaskInputSimple,
  },
  data() {
    return {
      headers: {
        price: () => 'Price',
        actions: () => '',
      },
      loading: false,
      updatingQuantity: false,
      intervalError: '',
      V_PRICE_TYPE,
      productsWithQuantityErr: [],
      isSomeTbd: false,
      outletCy,
    };
  },
  computed: {
    grandTotal() {
      return this.cartItems.reduce((total, item) => item.distributorTotal + total, 0);
    },
    cartItems() {
      const items = [];

      Object.entries(this.cart).forEach(([, products]) => {
        if (!products) {
          return;
        }
        const { distributor, message } = products;
        const distributorProducts = [];

        Object.entries(products).forEach(([id, product]) => {
          if (id !== 'distributor' && id !== 'message') {
            distributorProducts.push({
              ...product,
              internalId: id,
            });
          }
        });

        const { tax, taxCalculation } = distributor;
        let subtotal = getSum({ list: distributorProducts, prop: 'price' });
        if (taxCalculation === 'inclusive') {
          subtotal -= getTaxFee({
            itemList: distributorProducts,
            itemProp: 'price',
            deliveryFee: 0,
            tax,
            taxCalculation,
          });
        }

        // checkTotal: false - skip condition when subtotal is 0, when we have MP and/or TBD products
        const deliveryFee = calculateDeliveryFee(distributor, subtotal, false);
        const taxFee = getTaxFee({
          itemList: distributorProducts,
          itemProp: 'price',
          ...(distributor.deliveryFeeWithoutTax && this.$helpers.isPremium(distributor.accountType)
            ? { deliveryFee }
            : { deliveryFee: 0 }),
          tax,
          taxCalculation,
        });

        const totalWithTax = getTotalWithTax({
          itemList: distributorProducts,
          itemProp: 'price',
          ...(this.$helpers.isPremium(distributor.accountType)
            ? { deliveryFee }
            : { deliveryFee: 0 }),
          tax,
          deliveryFeeTaxable: distributor.deliveryFeeWithoutTax,
          taxCalculation,
        });

        const showBanner = this.shouldShowDeliveryFeeAlert({
          df: deliveryFee,
          moa: distributor.minimumOrderAmount,
          total: subtotal,
        });

        const showMpBanner = this.shouldShowMpBanner(distributorProducts);
        const showTbdBanner = this.shouldShowTbdBanner(distributorProducts);

        let isTaxUniform = true;
        distributorProducts.forEach(item => {
          if (item.tax !== this.tax) isTaxUniform = false;
        });

        items.push({
          distributor,
          distributorSubtotal: subtotal,
          distributorTax: taxFee,
          distributorTotal: totalWithTax,
          products: distributorProducts,
          deliveryFee,
          showBanner,
          showMpBanner,
          showTbdBanner,
          showMergedBanner: showMpBanner && showTbdBanner,
          message,
          isTaxUniform,
        });
      });

      return items;
    },
    isSomeQuantityErr() {
      return this.productsWithQuantityErr.some(pr => pr.value);
    },
    ...mapGetters({
      itemsCount: 'cart/getItemCount',
    }),
    ...mapState('cart', ['cart', 'info']),
  },
  watch: {
    cartItems(val) {
      this.isSomeTbd = val.some(sg => sg.showTbdBanner || sg.showMpBanner);
    },
  },
  methods: {
    ...mapActions('cart', ['pushToCart']),
    /**
     * When to show the fee alert.
     *
     * @param {number} df - Delivery fee
     * @param {number} moa - Minimal Order Amount for the distributor
     * @param {number} total - Order total when tax is applied
     *
     * @returns {boolean}
     */
    shouldShowDeliveryFeeAlert({ df, moa, total }) {
      const isMoaMet = total > moa;

      if (df === 0 || moa === 0) return false;

      return !isMoaMet;
    },
    shouldShowMpBanner(products) {
      return products.some(item => item.product?.marketPrice);
    },
    shouldShowTbdBanner(products) {
      return products.some(item => item.product?.priceUnit && item.product?.price > 0);
    },
    getRowClass(row) {
      return row.isBonus ? 'item-bonus' : null;
    },
    tradeDealTooltipContent(row) {
      return `Buy ${row.deals[0].buy} ${pluralize(row.orderingUnit.label, row.deals[0].buy)}, 
                    get ${row.deals[0].get} ${pluralize(
        row.orderingUnit.label,
        row.deals[0].get,
      )} for ${row.deals[0].price}${(row.currency || getCurrency()).symbol}`;
    },
    goToProducts() {
      this.$router.push({ name: 'venue-orders-products' });
    },
    onConfirmSubmit() {
      this.loading = true;
      const orders = this.cartItems.map(record => {
        const products = record.products.map(({ product, quantity, bonusId, isBonus }) => ({
          id: product.id,
          quantity,
          ...(product.marketPrice && !isBonus ? {} : { price: product.price }),
          internalId: product.internalId,
          bonusId,
          isBonus,
        }));

        return {
          distributorId: record.distributor.id,
          products,
          message: record.message,
        };
      });
      let flashMessage = '';
      switch (this.info.type) {
        case 'standing':
          flashMessage = `Your order will be placed on every ${
            this.info.interval
          } day(s) starting from ${formatDate(this.info.scheduledAt)}`;
          break;
        case 'scheduled':
          flashMessage = `Your order will be delivered on ${formatDate(
            this.info.requestedDeliveryAt,
          )}`;
          break;
        default:
          flashMessage = 'Check out your complete order in Order Details.';
      }
      this.createOrder({
        orders,
        info: {
          ...this.info,
          type: this.info.type === 'scheduled' ? 'regular' : this.info.type,
        },
      })
        .then(() => {
          this.EMPTY_CART();
          this.loading = false;
          this.$router.push({
            name: 'venue-orders',
            params: {
              flash: {
                title: 'Order request successfully sent!',
                message: flashMessage,
              },
            },
          });
        })
        .catch(responseError => {
          this.loading = false;
          this.intervalError = responseError.response.data?.error?.fields?.interval[0] || '';
          flash.error({
            title: responseError.message,
            message: responseError.response.data.error.message,
          });
          this.onCancelSubmit();
        });
    },
    onQuantityInvalid(value, id) {
      const foundedPr = this.productsWithQuantityErr.find(pr => pr.id === id);
      if (!foundedPr) {
        this.productsWithQuantityErr.push({ id, value });
      } else {
        foundedPr.value = value;
      }
    },
    isToBeDetermined(product, isBonus) {
      const { priceUnit, marketPrice, price } = product || {};
      if (isBonus) return false;
      return (!!priceUnit && price > 0) || marketPrice;
    },
    getProductUnitLabel(product) {
      const { priceUnit, orderingUnit } = product || {};
      return priceUnit?.label || orderingUnit?.label || '';
    },
    isFractional(orderingUnit) {
      return orderingUnit?.type === UNIT_TYPE_FRACTIONAL;
    },
    onSubmitOrderClick() {
      this.$refs.orderConfirmation.open();
    },
    onCancelSubmit() {
      this.$refs.orderConfirmation.close();
    },
    onQuantityChange: debounce(async function deb(quantity, distributor, product, isBonus) {
      if (!isBonus) {
        this.updatingQuantity = true;
        await this.fetchProductPriceForQuantity({
          id: product.id,
          distributorId: distributor?.id,
          quantity,
          internalId: product.internalId,
        });
        this.updatingQuantity = false;
      }
    }, 300),
    onRemoveItemClick(distributor, product, internalId, bonusId) {
      this.REMOVE_FROM_CART({ distributorId: distributor?.id, productId: internalId, bonusId });
      const removedIdx = this.productsWithQuantityErr.findIndex(pr => pr.id === internalId);
      if (removedIdx < 0) return;
      this.productsWithQuantityErr.splice(removedIdx, 1);
    },
    ...mapActions('entities/orders', ['createOrder']),
    ...mapActions('cart', ['fetchProductPriceForQuantity', 'fetchDistributorsForVenue']),
    ...mapMutations('cart', ['EMPTY_CART', 'REMOVE_FROM_CART', 'UPDATE_DISTRIBUTORS']),
    productQuantityMaxValue(row) {
      const {
        customerInventory: { orderMoreThanAvailable, inventoryLevel },
      } = row;
      return !orderMoreThanAvailable && inventoryLevel !== null
        ? inventoryLevel
        : Number.MAX_SAFE_INTEGER;
    },
  },
  async created() {
    const {
      data: { data },
    } = await this.fetchDistributorsForVenue({});
    this.UPDATE_DISTRIBUTORS({ distributors: data });
  },
};
</script>

<style scoped lang="scss">
$bg-color: #f5f7fc;
$separator-color: #dee1e4;
$label-color: #8790a3;
$primary-color: #4d7cfe;

.shopping-cart {
  height: 100%;
  padding: 1rem;
  overflow-y: auto;
  :deep() .table {
    .product-cell {
      width: 300px;
    }

    tbody tr.item-bonus {
      background-color: $color-pastel-blue;
      td:first-child {
        padding-left: 8px;
      }
      td:last-child {
        padding-right: 8px;
      }
      .has-border {
        border: 1px solid $color-primary-blue;
      }
      .additional-info--bonus {
        color: $color-gray-6C;
        font-size: 12px;
        font-weight: 500;
        line-height: 14px;
        border-bottom: 2px dotted #a3bbff;
        color: $color-primary-blue;
      }
    }
  }

  &__container {
    max-width: 1024px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__content {
    max-width: 720px;
    width: 100%;
  }
  :deep() .modal .button-primary {
    max-width: 17em;
  }
  &__nav {
    flex: 1 0 100%;
    .button {
      padding: 0;
    }
  }

  &__footer {
    &-label,
    &-number {
      font-weight: bold;
      display: flex;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }

    &-label {
      font-weight: 500;
      color: #6c7995;
      @include font-size(12px);

      &--big {
        @include font-size(14px);
      }
    }
    &-number {
      color: #252631;
      @include font-size(16px);

      &--big {
        :deep() .v-price__price {
          @include font-size(20px);
        }
        @include font-size(20px);
      }
    }
  }

  &__distributor {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px dashed $color-gray-E9;
    > .entity-info {
      margin-bottom: 0.75rem;
    }
    &--footer {
      display: flex;
      justify-content: space-between;
      padding: 1rem 2.5rem 0 0;
    }
    &--footer-total {
      flex: 1;
      padding-left: 40px;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
  &__empty {
    @extend %flex-center;
    flex-direction: column;
    margin-top: px-to-rem(128px);
    width: 100%;
    &--info {
      @extend %flex-center;
      flex-direction: column;
      div {
        @extend %flex-center;
        flex-direction: column;
        padding: 0.6em 1em;
        small {
          @extend %gray-label;
          font-weight: normal;
          text-transform: none;
          @include font-size(14px);
          margin-top: px-to-rem(8px);
        }
        span {
          @include font-size(20px);
          font-weight: 500;
          margin-top: px-to-rem(24px);
        }
      }
    }
    img {
      width: 256px;
      height: 133px;
    }
    .button {
      width: 100%;
      max-width: px-to-rem(128px);
      margin-top: px-to-rem(16px);
    }
  }
  h1 {
    @include font-size(24px);
    font-weight: 500;
    padding: 0;
    margin: 1.5rem auto;
  }
  .product-info {
    @include name-status-group();
    justify-content: center;
    .product-info-secondary {
      display: flex;
      margin-top: 2px;
      span {
        font-weight: 600;
        color: $text-color;
      }
    }
  }
}
</style>

<style lang="scss">
.shopping-cart {
  .table {
    background-color: #ffffff;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    thead {
      th:last-child {
        width: 2em;
      }
    }

    .quantity-cell,
    .price-cell {
      overflow: visible;
    }

    tbody {
      td:last-child {
        padding: 0;
        text-align: center;
        border-radius: 0;
      }
      .unit-cell {
        overflow: visible;
      }
    }

    .remove-item {
      @extend %button-reset;
      outline: none;

      svg {
        color: #c5cad5;
      }

      :hover {
        cursor: pointer;
      }
    }
  }

  .ez-number-input {
    @include font-size(12px);
    height: 2em;

    .input-group__input input {
      @include font-size(12px);
    }

    svg {
      width: 0.7em;
    }
  }
}

.mask-input.mark-input--inline {
  .mask-input__input {
    font-weight: normal;
    color: $color-gray-25;
    text-align: center;
    padding-left: 2px;
  }
}
</style>
