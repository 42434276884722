<template>
  <ez-selected-item-venue
    ref="selectedItem"
    v-on="$listeners"
    :item="item"
    :only-basic-info="onlyBasicInfo"
    :hide-remove-button="hideRemoveButton"
    :data-cy="dataCy"
    :remove-button-data-cy="removeButtonDataCy"
  >
    <template #emptyState>
      <empty-state>
        <template #badge>
          <img src="@/assets/no-venue-empty-state.svg" width="160" alt="empty state image"/>
        </template>
        <template #title>
          No {{ $t('global.venue') }} selected yet
        </template>
        <template #info>
          Search for a {{ $t('global.venue') }} that you would like to add.
        </template>
      </empty-state>
    </template>
  </ez-selected-item-venue>
</template>

<script>
/**
 * SelectedVenue
 * @version 1.0.0
 * @since
 */

import EmptyState from '@/views/common/empty-state';
import EzSelectedItemVenue from './SelectedItemVenue.vue';


export default {
  components: {
    EmptyState,
    EzSelectedItemVenue,
  },
  props: {
    venue: {
      required: true,
    },
    /**
     * true: Display only outlet logo and name, without
     * any additional info
     * false: Display everything
     */
    onlyBasicInfo: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideRemoveButton: {
      required: false,
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      required: false,
      default: '',
    },
    removeButtonDataCy: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    item() {
      if (!this.venue) return {};
      return this.venue;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
