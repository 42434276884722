<script>
/**
 *
 * @version 1.0.0
 * @since 2.4.0
 */

import { mapActions, mapState } from 'vuex';
import EzUserList from '@/views/common/users';
import EzUserModal from '@/views/common/users/EzUserModal.vue';
import flash from '@/components/ui/FlashMessage';
import { wizardListenerMixin } from '@/mixins/wizard';

export default {
  mixins: [wizardListenerMixin],
  components: {
    EzUserList,
    EzUserModal,
  },
  props: {
    supplierId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      formKey: 'venue-connect-user',
      selectedUser: {},
      roles: [],
      users: [],
    };
  },
  computed: {
    ...mapState('entities/users', ['contextId']),
    formAction() {
      return `/venue/distributors/${this.supplierId}/users`;
    },
  },
  methods: {
    async getUsers() {
      const { data } = await this.venueFetchDistributorUsers({
        venueId: this.contextId,
        id: this.supplierId,
      });
      this.users = data.data;
      this.checkToEnableSteps();
    },
    checkToEnableSteps() {
      if (this.users && this.users.length) this.enableNextStep();
      else this.disableNextStep();
    },
    openModal() {
      this.$refs.userModal.open();
    },
    closeModal() {
      this.$refs.userModal.close();
    },
    onCloseModal() {
      this.selectedUser = {};
    },
    onAddUser() {
      this.selectedUser = {};
      this.openModal();
    },
    onUserClick(data) {
      this.selectedUser = data;
      this.openModal();
    },
    onSubmitSuccess({ isUpdate }) {
      this.getUsers();
      flash.success({ title: `User successfully ${isUpdate ? 'updated' : 'added'}.` });
      this.closeModal();
    },
    async onRemoveUser({ id }) {
      await this.venueRemoveDistributorUser({
        venueId: this.contextId,
        id: this.supplierId,
        userId: id,
      });
      await this.getUsers();
    },
    onNextStep() {
      this.$emit('stepCompleted');
    },
    onPreviousStep() {
      this.$emit('stepBack');
    },
    ...mapActions('entities/users', ['removeUser']),
    ...mapActions('entities/distributors', [
      'venueFetchDistributorUsers',
      'venueRemoveDistributorUser',
    ]),
  },
  mounted() {
    this.getUsers();
  },
};
</script>

<template>
  <ez-user-list
    :users="users"
    :columns="['name', 'phone', 'email']"
    :headers="{
      accountName: () => $t('global.venue'),
    }"
    @addUser="onAddUser"
    @userClick="onUserClick"
    @removeUser="onRemoveUser"
  >
    <ez-user-modal
      ref="userModal"
      :formKey="formKey"
      :formAction="formAction"
      :additionalData="{ roleKey: 'distributor_subscriber' }"
      :user="selectedUser"
      :shouldMirror="true"
      @close="onCloseModal"
      @success="onSubmitSuccess"
    />
  </ez-user-list>
</template>
